import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys} from '../../../../types';

type OfferDetectionAlertToastProps = {
    type: string;
};

const ToastText = styled(CoreText)`
    margin-right: 10px;
    color: black;
`;

const OfferDetectionAlertToast = ({type}: OfferDetectionAlertToastProps) => {
    const toastText = useTweekValue(
        MessagingSalesTweekKeys.OfferDetectionAlertToast,
        'Good work on the offer. More offers lead to more sales!'
    );

    return (
        <ToastText data-test-id={`MessagingSales_${type}_Toast`} size="m">
            {toastText}
        </ToastText>
    );
};

export default OfferDetectionAlertToast;
