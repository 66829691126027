import {observable, action, runInAction, computed, makeObservable} from 'mobx';

type Action = 'Scroll' | 'Flash';

class TimelineController {
    // Using Map for rendering efficiency. With Map only the relevant messages
    // will update, Set makes all the observers of any value in it to update.
    messagesToAct: Map<string, Action> = new Map<string, Action>();

    constructor() {
        makeObservable(this, {
            messagesToAct: observable,
            currentForceActMessageId: computed,
            scrollToMessage: action,
            flashMessage: action,
        });
    }

    shouldScrollToMessage(contentId: string) {
        const action = this.messagesToAct.get(contentId);
        return !!action && action === 'Scroll';
    }

    shouldFlashMessage(contentId: string) {
        const action = this.messagesToAct.get(contentId);
        return !!action && action === 'Flash';
    }

    get currentForceActMessageId() {
        return this.messagesToAct.keys().next().value;
    }

    actOnMessage(contentId: string, action: Action) {
        this.messagesToAct.set(contentId, action);
        setTimeout(() => {
            runInAction(() => this.messagesToAct.delete(contentId));
        }, 500);
    }

    scrollToMessage(contentId: string) {
        this.actOnMessage(contentId, 'Scroll');
    }

    flashMessage(contentId: string) {
        this.actOnMessage(contentId, 'Flash');
    }
}

export default new TimelineController();
