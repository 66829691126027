import {
    ExternalEnrollmentProgress,
    OfferType,
    HorizonEnrollmentState,
} from '@soluto-private/smart-home-anywhere-expert';
export * from '@soluto-private/smart-home-anywhere-expert';

export * from './sales-tab';

export enum AttributeType {
    SalesTag = 'salesTag',
}

export enum OfferSelection {
    OfferAccepted = 'offer-accepted',
    OfferDeclined = 'offer-declined',
    NotOffered = 'not-offered',
}

export enum ConclusionState {
    EnrollmentSuccess = 'EnrollmentSuccess',
    EnrollmentFailure = 'EnrollmentFailure',
}

export enum EnrollmentFlowStep {
    AuthorizeCustomer = 'AuthorizeCustomer',
    SendDisclosure = 'SendDisclosure',
    ConfirmEnrollment = 'ConfirmEnrollment',
}

export enum HorizonEnrollmentFlowStep {
    SendPricingInfo = 'SendPricingInfo',
    ConfirmCustomerAccept = 'ConfirmCustomerAccept',
    SendEnrollmentLink = 'SendEnrollmentLink',
    CustomerProgress = 'CustomerProgress',
}

export type OfferTypeTextContent = {
    [key in OfferType]: string;
};

export type OfferStatusContent = {
    [key in OfferType]: {
        src: string;
        text: string;
    };
};

export type ConclusionContent = {
    illustration: any;
    title: string;
    messages: string[];
    dataTestId: string;
    text: string;
};

export type EnrollmentFlowContent = {
    [EnrollmentFlowStep.AuthorizeCustomer]: {
        title: string;
        buttonText: string;
        checkboxLabel: string;
    };
    [EnrollmentFlowStep.SendDisclosure]: {
        title: string;
        buttonText: string;
    };
    [EnrollmentFlowStep.ConfirmEnrollment]: {
        title: string;
        buttonText: string;
        checkboxLabel: string;
    };
};

export type HorizonEnrollmentFlowContent = {
    [HorizonEnrollmentFlowStep.SendPricingInfo]: {
        title: string;
        buttonText: string;
        pricingInfo: string;
        infoText: string;
    };
    [HorizonEnrollmentFlowStep.SendEnrollmentLink]: {
        title: string;
        buttonText: string;
    };
    [HorizonEnrollmentFlowStep.CustomerProgress]: {
        title: string;
    };
    [HorizonEnrollmentFlowStep.ConfirmCustomerAccept]: {
        title: string;
        buttonText: string;
        confirmationText: string;
    };
};

export type ManualOfferContent = {
    text: string;
    buttonText: string;
};

export type HorizonEnrollmentUrlProps = {
    baseUrl: string;
    sessionIdParam: string;
    expertIdParam: string;
};

export type Tips = {
    message: string;
    author: string;
    subtitle: string;
}[];

export enum TimeRange {
    LastWeek = 'last-week',
    ThisWeek = 'this-week',
    Yesterday = 'yesterday',
    Today = 'today',
}

export type MessagingSalesOffer = {
    interactionId: string;
    offerSelection: OfferSelection;
    pool: string;
    sessionId: string;
    offerSessionId?: string;
    atlasId?: string;
    isEligible: boolean;
    enrollmentProgress?: ExternalEnrollmentProgress;
    horizonEnrollmentProgress?: HorizonEnrollmentState;
    enrollmentErrorMessage?: string;
    offerType?: OfferType;
};

interface ByIdRequest {
    id: string;
}

export declare type CreateExpertInteractionPayload = Omit<ExpertInteraction, 'id'>;

export declare type UpdateExpertInteractionPayload = ExpertInteraction & ByIdRequest;

export declare type Metadata = {
    sourceApp?: string;
    anywhereExpertOfferSessionId?: string;
    offerSessionId?: string;
    customerAtlasId?: string;
    glowExpertSessionId?: string;
    glowOfferSessionId?: string;
    shCognitoExpertId?: string;
    enrollmentProgress?: ExternalEnrollmentProgress;
    horizonEnrollmentProgress?: HorizonEnrollmentState;
    offerType?: OfferType;
} & Record<string, string | boolean>;

export interface ExpertStats {
    expertId: string;
    accepts: number;
    offers: number;
    noOffers: number;
    offerRate: number;
    interactions: number;
    updatedAt: string;
}

export interface SalesStatsProps {
    currentWeekStats: ExpertStats;
    lastWeekStats: ExpertStats;
}

export interface AcceptRateProps {
    currentWeekAcceptCount: number;
    lastWeekAcceptCount: number;
}

export interface SP100Props {
    currentWeekSP100: number;
    lastWeekSP100: number;
}

export interface OfferRateProps {
    currentWeekOfferRate: number;
    lastWeekOfferRate: number;
}

export interface ExpertInteraction {
    id: string;
    shCognitoExpertId: string;
    expertId: string;
    customerAtlasId?: string;
    offerSessionId?: string;
    partner: string;
    eligible?: boolean;
    offered: boolean;
    accepted: boolean;
    metadata: Metadata;
    planId?: string;
    productSku?: string;
    isSalesOpportunity?: boolean | null;
    createdAt?: string;
    updatedAt?: string;
}
export declare type ExpertInteractions = ExpertInteraction[];

export interface HorizonEnrollmentProgressOptions {
    enrollmentProgress?: HorizonEnrollmentState;
    accepted?: boolean;
}

export interface ExternalEnrollmentProgressOptions {
    enrollmentProgress?: ExternalEnrollmentProgress;
    accepted?: boolean;
}

export type OfferDetails = Pick<ExpertInteraction, 'offered' | 'accepted'>;

export type OfferRecPreviewData = {
    title: string;
    category?: 'cybersecurity' | 'device coverage';
    text: string;
    isTailored: boolean;
};
