import React, {useEffect, useCallback, useRef} from 'react';
import {SendIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {SuggestiveBehaviour} from '@anywhere-expert/suggestive-behaviour';
import {observer} from 'mobx-react';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {TYPE_ACCEPT_CONVERSATION_TO_INPUT_RENDER, usePerformance} from '@anywhere-expert/performance';
import RichInputIconsPost from './RichInputIconsPost';
import {useRichInputBehaviors} from './behaviours/useRichInputBehaviours';
import {useDefaultInputTextBehavior} from '@anywhere-expert/default-input-text';
import {useTweekValue} from 'react-tweek';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {RichEditor} from '@anywhere-expert/rich-input';
import type {Editor} from 'draft-js';
import TimelineInputStore from './stores/TimelineInputStore';
import {useOnTypingAnalytic} from './webAnalytics';
import {useTyping} from '@anywhere-expert/timeline-behaviours';

type Props = {
    editable?: boolean;
    disableImageUpload: boolean;
    selectedSession: SupportItem;
};

const SendButton = styled(CoreIconButton)`
    opacity: 1;
`;

const SendContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    width: 100%;
    flex: 1;
    margin: 0px 6px;
`;

const iconProps = {size: 'l'};

const ToolsButtonsContainer = styled.div`
    display: flex;
    position: absolute;
    right: 0;
`;

const RichInput = observer(({disableImageUpload, selectedSession, editable, ...rest}: Props) => {
    const {
        customerId: timelineId,
        sessionId,
        sessionSource,
        sessionSourceVersion,
        supportItemDetails: {partner},
    } = selectedSession;
    const analytics = useAnalytics();
    const textInputRef = useRef<Editor>(null);
    const selectedTimeline = selectedSession.timelineModel;
    const textInputValue = TimelineInputStore.richInput?.editorPlainText ?? '';

    const onTyping = useTyping(selectedSession.timelineModel);
    const {onBlur} = useOnTypingAnalytic(textInputValue);

    const onTextChanged = useCallback(
        (newText: string) => {
            if (!newText) return;
            onTyping();
        },
        [onTyping]
    );

    useEffect(() => {
        TimelineInputStore.setTimeline(timelineId);
    }, [timelineId]);

    const {isSendEnabled, sendComplete, setIsSendDisabled, onPaste} = useRichInputBehaviors({
        timelineId,
        sessionId,
        sessionSource,
        sessionSourceVersion,
        partner,
    });
    const {end} = usePerformance();

    useEffect(() => {
        end({tag: TYPE_ACCEPT_CONVERSATION_TO_INPUT_RENDER, identifier: sessionId});
    }, [end, sessionId]);

    useDefaultInputTextBehavior(timelineId, selectedTimeline);

    const isSuggestiveBehaviourEnabled = useTweekValue<boolean>(
        'support/session/during/suggestive_behaviour/is_enabled',
        false
    );
    const inputPlaceholderText = 'Type your message here';

    useEffect(() => {
        textInputRef?.current && TimelineInputStore.richInput.setEditorReference(textInputRef?.current);
    }, [textInputRef?.current]);

    const relevantRichInputStore = React.useMemo(() => TimelineInputStore.getRichInputByTimeline(timelineId), [
        timelineId,
    ]);

    const pasteDetection = useCallback(
        (text: string) => {
            TimelineInputStore.detectPasting();
            onPaste(text);
        },
        [onPaste, TimelineInputStore]
    );

    const onEnterPressed = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (!isSendEnabled || e.key !== 'Enter') {
                e.preventDefault();
            }
            analytics.dispatcher.withExtra('ActionId', 'SendEnter').dispatch('Click');
            sendComplete();
        },
        [analytics, isSendEnabled, textInputValue, timelineId]
    );

    return (
        <RichEditor
            ref={textInputRef}
            richInputStore={relevantRichInputStore}
            onBlur={onBlur}
            disabled={!editable}
            placeholder={inputPlaceholderText}
            onTextChanged={onTextChanged}
            onEnterPressed={onEnterPressed}
            dataTestId={'MessageInput'}
            onPaste={pasteDetection}
            {...rest}
        >
            <ToolsButtonsContainer>
                {isSuggestiveBehaviourEnabled && (
                    <SuggestiveBehaviour
                        inputText={textInputValue}
                        sendComplete={sendComplete}
                        timelineId={timelineId}
                        setIsSendDisabled={setIsSendDisabled}
                    />
                )}

                <RichInputIconsPost
                    disableImageUpload={disableImageUpload}
                    timelineId={timelineId}
                    sessionId={sessionId}
                />
                <SendContainer>
                    <SendButton
                        color="primary"
                        variant="icon"
                        size="s"
                        iconProps={iconProps}
                        onClick={sendComplete}
                        data-test-id="SendButton"
                        icon={SendIcon}
                        disabled={!isSendEnabled}
                    />
                </SendContainer>
            </ToolsButtonsContainer>
        </RichEditor>
    );
});

export default RichInput;
