import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {SalesStatsProps, TimeRange} from '../../types';
import {useMessagingSalesStore} from '../../hooks/useMessagingSales';
import AcceptCount from '../sales-stats/AcceptCount';
import SP100Count from '../sales-stats/SP100Count';
import OfferRate from '../sales-stats/OfferRate';
import {CoreText} from '@anywhere-expert/base-ui';

const Container = styled.div`
    background-color: ${({theme}) => theme.colors.background.secondary};
    padding: 42px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const StatsContainer = styled.div`
    display: flex;
`;

const getSP100 = stats => {
    if (stats.interactions <= 0) {
        return 0;
    }

    return (stats.accepts * 100) / stats.interactions;
};

const SalesStats = observer(({currentWeekStats, lastWeekStats}: SalesStatsProps) => {
    return (
        <StatsContainer>
            <AcceptCount
                currentWeekAcceptCount={currentWeekStats.accepts}
                lastWeekAcceptCount={lastWeekStats.accepts}
            />
            <OfferRate currentWeekOfferRate={currentWeekStats.offerRate} lastWeekOfferRate={lastWeekStats.offerRate} />
            <SP100Count currentWeekSP100={getSP100(currentWeekStats)} lastWeekSP100={getSP100(lastWeekStats)} />
        </StatsContainer>
    );
});

const LoadingText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    margin-top: 70px;
`;

const SalesProfileStats = observer(() => {
    const store = useMessagingSalesStore()!;

    const currentWeekStats = store.salesStats.get(TimeRange.ThisWeek);
    const lastWeekStats = store.salesStats.get(TimeRange.LastWeek);

    const isStatsLoaded = currentWeekStats && lastWeekStats;

    return (
        <Container data-test-id="SalesProfileStats">
            {!isStatsLoaded ? (
                <LoadingText size="xxl">Loading your stats...</LoadingText>
            ) : (
                <SalesStats currentWeekStats={currentWeekStats!} lastWeekStats={lastWeekStats!} />
            )}
        </Container>
    );
});

export default SalesProfileStats;
