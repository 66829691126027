import React from 'react';
import {observer} from 'mobx-react';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {HorizonEnrollmentProgress as EnrollmentProgress} from './HorizonEnrollmentProgress';
import {useCurrentOffer} from '../../../../../hooks';
import {HorizonEnrollmentState, OfferSelection} from '../../../../../types';
import SendEnrollmentLink from './SendEnrollmentLink';
import SendPricingInfo from './SendPricingInfo';
import ConfirmCustomerAccept from './ConfirmCustomerAccept';

const HorizonEnrollmentStep = observer(({selectedSession}: SalesSessionProps) => {
    const currentOffer = useCurrentOffer(selectedSession.sessionId);
    const offerDetails = currentOffer?.offer;

    if (!offerDetails) {
        return null;
    }

    const getHorizonEnrollmentStep = () => {
        if (!offerDetails.horizonEnrollmentProgress) {
            return <SendPricingInfo selectedSession={selectedSession} />;
        } else if (
            offerDetails.offerSelection === OfferSelection.OfferDeclined &&
            offerDetails.horizonEnrollmentProgress === HorizonEnrollmentState.PriceShared
        ) {
            return <ConfirmCustomerAccept selectedSession={selectedSession} />;
        } else if (
            offerDetails.offerSelection === OfferSelection.OfferAccepted &&
            offerDetails.horizonEnrollmentProgress === HorizonEnrollmentState.PriceShared
        ) {
            return <SendEnrollmentLink selectedSession={selectedSession} />;
        } else {
            return <EnrollmentProgress selectedSession={selectedSession} />;
        }
    };

    return <div data-test-id="MessagingSales_EnrollmentFlow_HorizonEnrollment">{getHorizonEnrollmentStep()}</div>;
});

export default HorizonEnrollmentStep;
