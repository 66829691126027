import React from 'react';

export default ({color}: {color: string}) => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.97144 10.9004L3.84521 16.0266L5.25943 17.4408L10.3857 12.3146L15.5119 17.4408L16.9261 16.0266L11.7999 10.9004L16.9261 5.77414L15.5119 4.35992L10.3857 9.48615L5.25943 4.35992L3.84521 5.77414L8.97144 10.9004Z"
            fill={color}
        />
    </svg>
);
