import React from 'react';
import {AlertCircleOutlinedIcon} from '@anywhere-expert/icons';
import DismissibleSnackbar from './DismissibleSnackbar';

export default ({customerName}: {customerName: string}) => {
    return (
        <DismissibleSnackbar
            text={`Remote control is disabled until ${customerName} turns on accessibility on their device`}
            backgroundColor="#333F48"
            textColor="white"
            icon={<AlertCircleOutlinedIcon color="white" />}
        />
    );
};
