import axios, {AxiosInstance, Method, AxiosError} from 'axios';
import logger from '@anywhere-expert/logging';
import {stringify} from 'querystring';
import config from '../../config';
import {ExpertInteraction, ExpertInteractions, ExpertStats} from '../types';
import {getHomeAccessToken} from '@anywhere-expert/auth';
import {
    AccountManagementEnrollmentPayload,
    OfferManuallyPayload,
    VerizonEnrollmentPayload,
    VerizonEnrollmentResponse,
} from '@soluto-private/smart-home-anywhere-expert';

class SmartHomeApiClient {
    private _client: AxiosInstance;

    async enrollVerizonCustomer(enrollment: VerizonEnrollmentPayload): Promise<VerizonEnrollmentResponse> {
        this.prepareClient(config.shaeWebSvcApi.baseUrl);

        return await this.getAxiosResponse('post', 'enroll/verizon', enrollment);
    }

    async enrollAccountManagementCustomer(enrollment: AccountManagementEnrollmentPayload): Promise<void> {
        //tbd
        this.prepareClient(config.shaeWebSvcApi.baseUrl);

        await this.getAxiosResponse('post', 'enroll/account-management', enrollment);
    }

    async manualOffer(request: OfferManuallyPayload): Promise<void> {
        this.prepareClient(config.shaeWebSvcApi.baseUrl);

        return await this.getAxiosResponse('post', 'manual-offer', request);
    }

    async getExpertStats(expertId: string, fromISODate: string, toISODate: string): Promise<ExpertStats> {
        this.prepareClient(config.shExpertInteractionApiBaseUrl);

        const path = `/expert-interactions/stats/${expertId}${this.formatQueryString({fromISODate, toISODate})}`;

        return await this.getAxiosResponse<ExpertStats>('get', path);
    }

    async getInteractionsByIds(ids: string[]): Promise<ExpertInteractions> {
        this.prepareClient(config.shExpertInteractionApiBaseUrl);

        const path = `/expert-interactions/batch-get`;

        return await this.getAxiosResponse<ExpertInteractions>('post', path, {ids});
    }

    async patchInteraction(
        interactionId: string,
        interactionFieldsToUpdate: Partial<ExpertInteraction>
    ): Promise<void> {
        this.prepareClient(config.shExpertInteractionApiBaseUrl);

        await this.getAxiosResponse<ExpertInteraction>(
            'patch',
            `/expert-interactions/${interactionId}`,
            interactionFieldsToUpdate
        );
    }

    private prepareClient(baseUrl: string, apiKey?: string) {
        const authToken = getHomeAccessToken();

        this._client = axios.create({
            baseURL: baseUrl,
            timeout: 35000,
            headers: {
                'X-Api-Client': 'anywhere-expert/messaging-sales',
                'X-Api-Client-version': '0.0.1',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
                'X-Api-Key': apiKey,
            },
        });
    }

    private formatQueryString(params: {[name: string]: any}): string {
        return Object.keys(params).length > 0 ? `?${stringify(params)}` : '';
    }

    private async getAxiosResponse<T>(method: Method, url: string, data?: any): Promise<T> {
        try {
            const response = await this._client.request({
                method,
                url,
                headers: this._client.defaults.headers,
                data,
            });

            return response.data as T;
        } catch (error) {
            logger.warn('Error calling API', {err: error});
            return this.throwResponseError(error);
        }
    }

    protected throwResponseError(error: AxiosError): any {
        if (error.response) {
            console.error(
                `Received error from API at ${error.response.config.url}: ${error.message} with body ${JSON.stringify(
                    error.response.data
                )}`
            );

            throw error;
        } else {
            throw new Error(`Error in client when calling API: ${JSON.stringify(error.message)}`);
        }
    }
}

export default SmartHomeApiClient;
