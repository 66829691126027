import {createClient} from '@expert-feed/feed-api';
import {AnalyticsDispatcher} from 'shisell';
import {createFeedActions} from './feedActions';
import {FeedActions} from './types';

let feedActionsClient: FeedActions | undefined;

interface InitParams {
    expertId: string;
    dispatcher: AnalyticsDispatcher;
}

export function initFeedActionsClient({expertId, dispatcher}: InitParams) {
    if (feedActionsClient) {
        return;
    }

    const client = createClient();
    feedActionsClient = createFeedActions(client, expertId, dispatcher);
}

export function getFeedActionsClient() {
    if (!feedActionsClient) {
        throw new Error('feedActionsClient should be initialized before get');
    }

    return feedActionsClient;
}
