import * as React from 'react';
import {observer} from 'mobx-react';
import styled, {keyframes} from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import WelcomeStore from '../../stores/WelcomeStore';
import {CSSTransition} from 'react-transition-group';
import Bubbles from './Bubbles';

const backgroundAnim = keyframes`
    0% {
        background-color: ${palette.neutral.white};
    }
    100% {
        background-color: ${palette.neutral.grey.lighter};
    }
`;

const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1;
    animation: ${backgroundAnim} 0.25s linear forwards;

    &.out {
        opacity: 0;
        transition: opacity 0.25s linear 0.75s;
    }
`;

const TextContainer = styled.div`
    font-family: Apercu Pro;
    font-size: 20px;
    text-align: center;
`;

export default observer(() => (
    <CSSTransition in={WelcomeStore.show} timeout={{exit: 1500}} mountOnEnter unmountOnExit>
        {state => (
            <Container
                data-test-id="WelcomeAnimation"
                className={state === 'exiting' || state === 'exited' ? 'out' : ''}
            >
                <TextContainer>
                    <b>Hi {WelcomeStore.displayName}!</b>
                    <br />
                    <span>
                        Getting in touch with the
                        <br />
                        people you can help
                    </span>
                </TextContainer>
                <Bubbles />
            </Container>
        )}
    </CSSTransition>
));
