import React from 'react';
import {palette} from '@anywhere-expert/colors';
import type {ContentState} from 'draft-js';

type RenderLinkProps = {
    contentState: ContentState;
    entityKey: string;
    children: React.Component;
};

export const Link = ({contentState, entityKey, children}: RenderLinkProps) => {
    const {url} = contentState.getEntity(entityKey).getData();
    return (
        <a href={url} style={{color: palette.brand.blue}}>
            {children}
        </a>
    );
};
