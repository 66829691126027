import React from 'react';
import styled from 'styled-components';
import {useTweekValue} from 'react-tweek';
import {CoreLink} from '@anywhere-expert/base-ui';
import {MoneyBagIcon} from '@anywhere-expert/icons';
import {useMessagingSalesClickAnalytics, useProfilePayLinkEnablement} from '../../hooks';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import useMessagingSalesColors from '../../hooks/useMessagingSalesColors';

const StyledLink = styled(CoreLink)<{color: string}>`
    color: ${({color}) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-style: normal;
    border-radius: 8px;
    padding: 4px;
    margin-top: 10px;

    &:hover {
        color: ${({color}) => color};
        background-color: ${({theme, color}) => theme.utils.lighten(color, 0.8)};
        text-decoration: none;
    }
`;

const StyledMoneyBagIcon = styled(MoneyBagIcon)`
    margin-left: 5px;
`;

const useLinkColor = (): string => {
    const colors = useMessagingSalesColors();

    return colors.themeName === 'light' ? colors.blue : colors.green;
};

const ProfilePayLink = () => {
    const isProfilePayLinkEnabled = useProfilePayLinkEnablement();
    const profilePayLink = useTweekValue<string>(MessagingSalesTweekKeys.ProfilePayLink, '/');
    const profilePayText = useTweekValue<string>(MessagingSalesTweekKeys.ProfilePayText, 'How much have you made?');
    const clickedWithExtras = useMessagingSalesClickAnalytics('HelixPayLink');
    const linkColor = useLinkColor();

    if (!isProfilePayLinkEnabled) {
        return null;
    }

    return (
        <StyledLink
            color={linkColor}
            size="m"
            linkType="external"
            href={profilePayLink}
            onClick={() => clickedWithExtras({})}
            data-test-id="ProfilePayLink"
        >
            {profilePayText}
            <StyledMoneyBagIcon color={linkColor} />
        </StyledLink>
    );
};

export default ProfilePayLink;
