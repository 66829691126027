import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {useUserProfile} from '@anywhere-expert/auth';
import ExpertAvatar from './ExpertAvatar';
import {useMessagingSalesStore} from '../../hooks';

const Container = styled.div``;

const AvatarContainer = styled.div`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 250px;
        background: ${({theme}) => theme.colors.brand.purple};
        transform: skewY(-4deg);
    }
`;

const InnerContainer = styled.div`
    height: 125px;
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 72px;
`;

const WelcomeText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    font-size: 45px;
    line-height: 100%;
`;

const Name = styled.span`
    text-transform: capitalize;
`;

const LastUpdatedText = styled(CoreText)`
    margin-top: 16px;
`;

const LastUpdatedLabel = styled.span`
    font-weight: bold;
    margin-right: 10px;
`;

const LastUpdatedDate = observer(() => {
    const store = useMessagingSalesStore()!;
    return <>{store.salesStatsLastUpdatedAt || 'N/A'}</>;
});

const Header = () => {
    const user = useUserProfile();

    return (
        <Container data-test-id="SalesProfile_Header">
            <AvatarContainer>
                <InnerContainer>
                    <ExpertAvatar expert={user} />
                </InnerContainer>
            </AvatarContainer>

            <TextContainer>
                <WelcomeText weight="bold">
                    Hey <Name>{user.displayName}</Name>
                </WelcomeText>
                <LastUpdatedText size="m" textType="secondary">
                    <LastUpdatedLabel>Last Updated</LastUpdatedLabel>
                    <LastUpdatedDate />
                </LastUpdatedText>
            </TextContainer>
        </Container>
    );
};

export default Header;
