import {getTweekValue} from '@anywhere-expert/tweek';
import CannedMessagesStoreV2 from './CannedMessagesStoreV2';

export const isDefaultGreetingEnabled = () =>
    getTweekValue<boolean>('support/session/messaging/automatic_first_message/default_greeting/is_enabled', false);

export const getDefaultGreetingMessageText = (): string | undefined => {
    if (!isDefaultGreetingEnabled()) return;

    return CannedMessagesStoreV2.defaultGreetingMessage?.text;
};
