import moment, {Moment} from 'moment';
import {getTweekValue} from '@anywhere-expert/tweek';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';

const ONE_WEEK = 7;
const SATURDAY = 6;

export const getThisMoment = (): Moment => moment();

export const getStartOfPreviousPayPeriodISO = (): string => {
    const startOfThisPayPeriod = moment(getStartOfThisPayPeriodISO());

    return startOfThisPayPeriod.subtract(7, 'days').startOf('day').toISOString();
};

export const getEndOfPreviousPayPeriodISO = (): string => {
    const startOfThisPayPeriod = moment(getStartOfThisPayPeriodISO());

    return startOfThisPayPeriod.subtract(1, 'second').toISOString();
};

export const thisWeeksStartDayIsForNextPayPeriod = (day: number) =>
    getThisMoment().day(day).endOf('day').unix() > getThisMoment().endOf('day').unix();

export const getStartOfThisPayPeriodISO = (): string => {
    let dayPayPeriodStarts = getTweekValue(MessagingSalesTweekKeys.DayPayPeriodStarts, SATURDAY);

    if (thisWeeksStartDayIsForNextPayPeriod(dayPayPeriodStarts)) {
        dayPayPeriodStarts -= ONE_WEEK;
    }

    return getThisMoment().day(dayPayPeriodStarts).startOf('day').toISOString();
};

export const getThisTimeISO = (): string => getThisMoment().toISOString();

export const getThisMomentYesterday = (): Moment => getThisMoment().subtract(1, 'day');

export const getStartOfGivenDayISO = (givenMoment: Moment): string => givenMoment.startOf('day').toISOString();

export const getEndOfGivenDayISO = (givenMoment: Moment): string => givenMoment.endOf('day').toISOString();

export const isGivenMomentFromToday = (givenMoment: Moment): boolean =>
    givenMoment.format('MM DD YYYY') === getThisMoment().format('MM DD YYYY');

export const getFormattedDateTime = (): string => `${getCurrentDate()} ${getCurrentTime()}`;

const getCurrentDate = (): string => new Date().toLocaleString('en-US', {month: 'short', day: '2-digit'});

const getCurrentTime = (): string => new Date().toLocaleString('en-US', {hour: '2-digit', minute: '2-digit'});
