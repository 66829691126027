import {useTweekValue} from 'react-tweek';
import getCustomerMdn from './shared/getCustomerMdn';
import {CustomerPlatform} from '@anywhere-expert/expert-feed-store';
import {useAsync} from 'react-async-hook';

export const useCustomerMdn = (
    customerId: string,
    partner: string,
    customerPlatform: CustomerPlatform = 'device'
): string | undefined => {
    const customerDetailsBaseUrl = useTweekValue<string>('support/feed/support_item/mdn/customer_details_url', '');
    const getAvailableCommandNamesTask = useAsync(getCustomerMdn, [
        customerDetailsBaseUrl,
        customerId,
        partner,
        customerPlatform,
    ]);

    if (getAvailableCommandNamesTask.result) {
        return getAvailableCommandNamesTask.result;
    }

    return undefined;
};
