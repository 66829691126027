import React, {Suspense} from 'react';
import styled, {keyframes} from 'styled-components';
import {CompoundButtonSet, CoreText, CoreButton, CoreActionMenuItem} from '@anywhere-expert/base-ui';
import {DollarCoinIcon} from '@anywhere-expert/icons';

const Container = styled.div`
    position: absolute;
    right: 0;
    bottom: 20px;
`;

const InnerContainerKeyframes = keyframes`
    0% {
        transform: scale(0.5)
    }
    100% {
        transform: scale(1);
    }
`;

const ComponentContainer = styled(CoreActionMenuItem)`
    height: auto;
    width: 340px;
    align-items: center;
    box-sizing: border-box;
    animation-name: ${InnerContainerKeyframes};
    animation-timing-function: easeOutExpo;
    animation-duration: 0.3s;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    transform-origin: bottom right;
    padding: 0;
`;

const FadeInKeyframes = keyframes`
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
`;
const FadeInContainer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    width: 100%;
    height: auto;
    opacity: 0;
    animation: ${FadeInKeyframes} 0.3s linear 0s 1 forwards;
`;

const Title = styled(CoreText)`
    text-align: center;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    align-items: center;
`;

const Illustration = styled.div``;

const MessageText = styled(CoreText)`
    text-align: left;
    margin-left: 24px;
    flex: 1;
`;

const AlignedButtonSet = styled(CompoundButtonSet)`
    justify-content: space-between;
    flex-direction: row-reverse;
    align-self: center;
    margin-bottom: 5px;
`;

const Banner = styled.div`
    width: 100%;
    background: #00b574;
    display: flex;
    justify-content: space-between;
    height: 24px;
`;

const BannerText = styled(CoreText)`
    display: flex;
    align-items: center;
`;

export const SuggestiveBehaviorComponent = ({
    title,
    message,
    dismissButtonText,
    proceedButtonText,
    visual,
    proceedButtonIsVisible,
    onDismissButtonClick,
    onProceedButtonClick,
    shouldShowBanner,
    bannerText,
}) => (
    <Container>
        <ComponentContainer data-test-id="SuggestiveBehaviourContainer">
            {shouldShowBanner && (
                <Banner>
                    <DollarCoinIcon color="white" />
                    <BannerText textType="white">{bannerText}</BannerText>
                    <div style={{width: '24px'}} />
                </Banner>
            )}
            <FadeInContainer>
                <Title size="xl" textType="primary" weight="medium" data-test-id="SuggestiveBehaviourTitle">
                    {title}
                </Title>
                <InnerContainer>
                    <Illustration>
                        <Suspense fallback={<div />}>{visual}</Suspense>
                    </Illustration>
                    <MessageText size="m" textType="primary">
                        {message}
                    </MessageText>
                </InnerContainer>
                <AlignedButtonSet size="m" row>
                    <CoreButton
                        text={dismissButtonText}
                        variant="contained"
                        color="primary"
                        size="s"
                        data-test-id="BackToMsg"
                        onClick={onDismissButtonClick}
                        tabIndex={-1}
                    />
                    {proceedButtonIsVisible && (
                        <CoreButton
                            text={proceedButtonText}
                            variant="outlined"
                            color="primary"
                            size="s"
                            data-test-id="SendMsg"
                            tabIndex={-1}
                            style={{alignSelf: 'flex-start'}}
                            onClick={onProceedButtonClick}
                        />
                    )}
                </AlignedButtonSet>
            </FadeInContainer>
        </ComponentContainer>
    </Container>
);
