import React from 'react';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import styled from 'styled-components';
import {CoreText, CoreLink} from '@anywhere-expert/base-ui';
import {useMessagingSalesClickAnalytics} from '../../../../hooks';
import {ConclusionState} from '../../../../types';

type EnrollmentAlertToastProps = {
    sessionId: string;
    text: string;
    type: ConclusionState;
};

const GetToSessionLink = styled(CoreLink)`
    font-style: normal;
`;

const ToastText = styled(CoreText)`
    margin-right: 10px;
`;

const EnrollmentAlertToast = ({sessionId, text, type}: EnrollmentAlertToastProps) => {
    const clickedWithExtras = useMessagingSalesClickAnalytics(`${type}AlertToast`);

    const onClick = () => {
        clickedWithExtras({});
        ExpertFeedStore.selectSession(sessionId);
    };

    return (
        <ToastText data-test-id={`MessagingSales_${type}_Toast`} size="m">
            {text} &mdash;{' '}
            <GetToSessionLink size="m" onClick={onClick}>
                click here to access this session.
            </GetToSessionLink>
        </ToastText>
    );
};

export default EnrollmentAlertToast;
