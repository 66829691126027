import React from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import {ThumbUpIcon, ThumbDownIcon, CloseCircleIcon} from '@anywhere-expert/icons';
import OfferSelectionItem from './OfferSelectionItem';
import {OfferSelection} from '../../../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {useMessagingSalesClickAnalytics, useCurrentOffer, useEnrollmentFlowFeatureToggle} from '../../../../hooks';
import {celebrateSale} from '../../../../utils/toasts';

type OfferSelectionTitles = {[key in OfferSelection]: string};

const getOfferSelections = (
    titleMap: OfferSelectionTitles
): {icon: React.ComponentType<any>; title: string; key: OfferSelection}[] => [
    {
        icon: ThumbUpIcon,
        title: titleMap[OfferSelection.OfferAccepted],
        key: OfferSelection.OfferAccepted,
    },
    {
        icon: ThumbDownIcon,
        title: titleMap[OfferSelection.OfferDeclined],
        key: OfferSelection.OfferDeclined,
    },
    {
        icon: CloseCircleIcon,
        title: titleMap[OfferSelection.NotOffered],
        key: OfferSelection.NotOffered,
    },
];

const defaultOfferSelectionNameMap: {[key in OfferSelection]: string} = {
    [OfferSelection.OfferAccepted]: 'Offer Accepted',
    [OfferSelection.OfferDeclined]: 'Offer Declined',
    [OfferSelection.NotOffered]: 'Not Offered',
};

const OfferSelections = observer(({selectedSession}: SalesSessionProps) => {
    const clickedWithExtras = useMessagingSalesClickAnalytics('OfferSelection');
    const offerSelectionTitles = useTweekValue(MessagingSalesTweekKeys.OfferSelections, defaultOfferSelectionNameMap);
    const offerSelections = getOfferSelections(offerSelectionTitles);
    const currentOffer = useCurrentOffer(selectedSession.sessionId)!;
    const isEnrollmentFlowEnabled = useEnrollmentFlowFeatureToggle();

    const onClick = (key: OfferSelection) => {
        currentOffer.setOfferSelection(key);
        clickedWithExtras({OfferSelection: key});

        if (!isEnrollmentFlowEnabled) {
            celebrateSale(key);
        }
    };

    return (
        <>
            {offerSelections.map(({icon, title, key}) => (
                <OfferSelectionItem
                    Icon={icon}
                    selected={currentOffer.offer.offerSelection === key}
                    title={title}
                    onClick={() => onClick(key)}
                    dataTestId={`MessagingSalesItem_${key}`}
                    key={key}
                />
            ))}
        </>
    );
});

export default OfferSelections;
