import React from 'react';
import styled from 'styled-components';
import {Category} from '@soluto-private/canned-messages-api-client';
import {useDetectOutsideClick} from '@anywhere-expert/utils';
import {CoreText, getScrollerStyle} from '@anywhere-expert/base-ui';
import useSortedCategories from './useSortedCategories';
import {Action} from '../../../';

const Container = styled.div`
    position: relative;
    display: flex;
`;

const ActionsContainer = styled.div`
    position: absolute;
    top: 4px;
    right: 16px;
    z-index: 5;
    padding: 6px 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 216px;
    overflow: auto;
    background-color: ${({theme}) => theme.colors.neutral.white};
    border-radius: 4px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);

    ${({theme}) => getScrollerStyle(theme)};
`;

interface Props {
    onCancel: () => void;
    open: boolean;
    onClick: (categoryId: string, category: Category) => Promise<void>;
}

const MessageActionsModal = ({onClick, onCancel}: Props) => {
    const wrapperRef = useDetectOutsideClick(onCancel);
    const categories = useSortedCategories();

    return (
        <Container ref={wrapperRef} data-test-id="CannedMessages_MessageOptions">
            <ActionsContainer>
                {categories.map(({id, category}) => (
                    <Action
                        key={id}
                        icon={<CoreText size="l">{category.icon}</CoreText>}
                        text={category.title.length >= 20 ? `${category.title.slice(0, 19)}...` : category.title}
                        onClick={() => onClick(id, category)}
                        data-test-id="CannedMessages_MessageOptions_Button"
                    />
                ))}
            </ActionsContainer>
        </Container>
    );
};

export default MessageActionsModal;
