/**
 * An object containing various configuration values used in the app. Mostly for static things like urls and api keys
 * @module configuration
 * @memberof core
 */
export default {
    tweekBaseUrl: 'https://tweek.mysoluto.com',
    tweekBaseUrlFallback: 'https://tweek-west.mysoluto.com',
    analyticsBaseUrl: 'https://prodhome1analyticslistener.mysoluto.com',
    expertTasksApiUrl: 'https://expert-tasks-api.mysoluto.com/v1',
    mobileAnalyticsBaseUrl: 'https://android-analytics-prodhome1.mysoluto.com',
    monitorUrl: 'https://logging-api-new.mysoluto.com',
    usersApiUrl: 'https://anywhere-expert-users-api.mysoluto.com/v1',
    notesApiUrl: 'https://anywhere-expert-notes-api.mysoluto.com',
    userStorageUrl: 'https://anywhere-expert-user-storage.mysoluto.com',
    partnerValidationApi: 'https://ccare.vzwcorp.com',
    videoSessionApiUrl: 'https://video-session-api.mysoluto.com',
    loggingUrl: 'https://logging-api-new.mysoluto.com/api/v1/log/',
    bugsnag: {
        appKey: '98882babc6e815ab8b23f9e470775f87',
        notifyReleaseStages: ['production'],
    },
    storagePhotoBucketUrl: 'gs://soluto-photos',
    solutoGraphUrl: 'https://graph.mysoluto.com/graphql',
    reportAnIssueUrl:
        'https://expert-issue-reporter.mysoluto.com/api/expert-issue-reporter?code=tXN4ZL/WFwUPYKcxn1czDwzRccV84zhb6kb67pxWldmw/xo7KZTRiA==',
    messagingApi: {
        rest: 'https://messaging-api.mysoluto.com/v2',
        live: 'https://live-messaging-api.mysoluto.com',
        apiKey: '67c776d3-06a7-40fe-bbc2-4df45781e861',
    },
    traceEventApi: {
        rest: 'https://trace-listener.mysoluto.com',
        apiKey: '67c776d3-06a7-40fe-bbc2-4df45781e861',
    },
    mediaServiceApi: {
        baseUrl: 'https://media-server-api.mysoluto.com',
        apiKey: '67c776d3-06a7-40fe-bbc2-4df45781e861',
    },
    helix: {
        searchUrl: 'https://smartsearch-internet.cc-prod.asurion53.com',
        autocompleteUrl: 'https://autocomplete-vzw.contactcenter.prd.aws.asurion.net/',
        articleBaseUrl: 'https://helix-search.cc-prod.asurion53.com',
    },
    appleBusinessChatApi: {
        baseUrl: 'https://abc-api.mysoluto.com',
        apiKey: '67c776d3-06a7-40fe-bbc2-4df45781e861',
    },
    voiceExpertDataApiUrl: 'https://voice-expert-data-api.mysoluto.com/api/v1',
    awTweekBackofficeUrl: 'https://ae-tweek-backoffice.mysoluto.com',
    proactiveSessionApiUrl: 'https://proactive-session-api.mysoluto.com',
    cannedMessageApi: 'https://canned-messages-api.mysoluto.com',
};
