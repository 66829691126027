import React from 'react';

export default ({className}: {className?: string}) => (
    <svg
        className={className}
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.0195 15.7246H20.6445"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.6947 57.1386C22.6237 57.3786 13.0437 57.3146 10.0357 57.1386C7.92274 57.0136 6.45474 55.6286 6.45474 53.4826C6.15774 40.2226 6.22674 21.5236 6.32174 18.9786C6.41574 16.4336 7.88674 15.7246 9.81974 15.7246H11.5487"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.2418 51.8027C28.2308 52.3697 28.2198 52.9297 28.2068 53.4807C28.2068 55.6267 26.7388 57.0117 24.6258 57.1367C21.6178 57.3127 12.0378 57.3767 9.9668 57.1367"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.1113 15.7246H24.8413C26.7743 15.7246 28.2453 16.4336 28.3393 18.9786C28.3603 19.5736 28.3813 19.0496 28.3963 21.1326"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.1106 52.9707C31.0986 53.5267 31.0866 54.0767 31.0726 54.6217C31.0726 57.0257 29.3516 59.8837 26.7186 60.0217C22.9766 60.2187 11.5566 60.2927 8.97858 60.0217C7.24658 59.8397 5.30558 59.2997 4.41158 57.9227L4.39258 58.1037C5.27358 59.6877 7.08458 60.9297 8.79158 60.9297C15.4356 61.3397 19.2076 61.3577 26.0196 60.9297C29.1486 60.3637 30.9086 57.6157 30.9086 56.1447"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.75262 56.1464C3.75262 57.6174 5.51261 60.3654 8.64161 60.9314C15.4536 61.3594 19.2256 61.3414 25.8696 60.9314C27.5766 60.9314 29.3886 59.6894 30.2686 58.1054L30.1796 57.8544C29.2846 59.2314 27.4146 59.8414 25.6826 60.0234C23.1046 60.2944 11.6846 60.2204 7.94261 60.0234C5.30961 59.8844 3.58862 57.0274 3.58862 54.6234C3.21762 39.7504 3.47162 20.1054 3.58862 17.2524C3.70462 14.3984 5.53861 12.8984 7.94261 12.8984H11.4246H23.2376H26.7196C29.1226 12.8984 30.9566 14.3984 31.0736 17.2524C31.1266 18.5454 31.2076 21.2874 31.2456 27.4714"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.65327 31.4731C2.59027 28.4401 2.62627 26.7891 2.70527 23.8711"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8574 54.0566C16.4354 54.2266 17.8654 54.2426 20.3944 54.1976"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.5984 36.7917C44.0544 36.7447 54.3704 36.8767 58.8334 36.7917C60.4224 36.7637 61.7044 35.5627 61.7044 34.0927C61.7044 30.9487 61.7044 25.3917 61.7044 19.6337C61.7044 16.6807 61.7044 9.91369 61.7044 6.12569C61.7044 4.63369 60.3974 3.42969 58.7824 3.42969H14.4474C12.8314 3.42969 11.5234 4.63669 11.5234 6.12569C11.5234 9.47269 11.5234 15.2737 11.5234 19.6337C11.5234 24.1137 11.5234 30.5397 11.5234 34.1047C11.5234 35.5677 12.7964 36.7687 14.3824 36.8017H16.6534"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M38.9714 34.0884C38.9714 34.0884 40.0504 36.8794 40.5114 38.0734C42.1004 42.1864 40.0554 46.8824 35.9314 48.4744C31.8094 50.0674 27.1754 48.0375 25.5864 43.9235C25.4844 43.6615 25.3984 43.4355 25.3294 43.1725L20.4424 30.5234C20.2674 29.5724 20.7744 28.6054 21.7074 28.2454C22.6394 27.8864 23.6644 28.2624 24.1754 29.0834L26.6964 35.6124"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.5091 36.9699L30.4281 34.1709C29.9181 33.3499 28.8931 32.9739 27.9601 33.3339C27.0271 33.6949 26.5221 34.6609 26.6961 35.6119L27.7761 38.4109"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M35.2399 35.5309L34.1589 32.7319C33.6479 31.9109 32.6249 31.5349 31.6909 31.8939C30.7579 32.2539 30.2519 33.2209 30.4269 34.1719L31.5079 36.9709"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.052 36.8871L37.89 31.2901C37.38 30.4691 36.356 30.0931 35.423 30.4531C34.489 30.8121 33.985 31.7801 34.159 32.7311L35.24 35.5301"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.5604 35.385C18.3534 34.73 17.3664 33.657 16.8324 32.279C15.6384 29.186 17.1764 25.714 20.2674 24.52C23.3594 23.326 26.8314 24.863 28.0264 27.955C28.3654 28.831 28.4844 29.739 28.4094 30.617"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.043 30.8027H41.043"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
