import React, {useState, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useCurrentOffer, useMessagingSalesClickAnalytics, useEnrollmentFlowContent} from '../../../../../hooks';
import {CoreText, CoreCheckbox, CoreInputText} from '@anywhere-expert/base-ui';
import {TabProps as ConfirmEnrollmentProps} from '@anywhere-expert/session-sidebar';
import {useCustomerMdn} from '@anywhere-expert/customer-mdn-api';
import {NextButton} from '../../../../styled-components';
import {EnrollmentFlowStep, EnrollmentFlowContent} from '../../../../../types';
import styled from 'styled-components';

const StyledText = styled(CoreText)`
    padding-bottom: 10px;
`;

const StyledInputText = styled(CoreInputText)`
    border: 1px solid #000000;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 8px;
    margin: 5px;
    width: 75%;
`;

const CheckboxContainer = styled.div`
    padding-left: 10px;
    padding-bottom: 10px;
`;

const StyledMdnInputContainer = styled.div``;

const useFormatMdn = (mdn: string) =>
    useMemo(() => {
        if (mdn && mdn.length <= 3) return mdn;
        return mdn
            .replace(/[^\d]+/g, '')
            .replace(/(\d{3})/, '($1) ')
            .replace(/(\d{3})(\d{1})/, '$1-$2');
    }, [mdn]);

const removeFormatMdn = (mdn: string) => {
    return mdn.replace(/\D/g, '');
};

export const ConfirmEnrollment = observer(({selectedSession}: ConfirmEnrollmentProps) => {
    const {
        sessionId,
        customerId,
        deviceDetails: {platform},
        pool: partner,
    } = selectedSession;

    const {title, buttonText, checkboxLabel} = useEnrollmentFlowContent(
        EnrollmentFlowStep.ConfirmEnrollment
    ) as EnrollmentFlowContent[EnrollmentFlowStep.ConfirmEnrollment];
    const mdnFromCustomerDetails = useCustomerMdn(customerId, partner, platform);
    const currentOffer = useCurrentOffer(sessionId)!;
    const clickedWithExtras = useMessagingSalesClickAnalytics('ConfirmEnrollment');
    const [isEnrollmentConfirmed, setIsEnrollmentConfirmed] = useState(false);
    const [mdn, setMdn] = useState('');

    const isMdnInputEnabled = !mdnFromCustomerDetails;
    const validMdn = !isMdnInputEnabled || mdn.match(/\d{10}/gm);
    const nextButtonDisabled = !(isEnrollmentConfirmed && validMdn);

    const formattedMdn = useFormatMdn(mdn);

    React.useEffect(() => {
        if (mdnFromCustomerDetails) {
            setMdn(mdnFromCustomerDetails);
        }
    }, [mdnFromCustomerDetails]);

    const onMdnInputChange = (inputMdn: string) => {
        inputMdn = removeFormatMdn(inputMdn);
        if (inputMdn.length <= 10) {
            setMdn(inputMdn);
        }
    };

    const onNextClick = () => {
        clickedWithExtras({});
        currentOffer.persistEnrollmentProgress({accepted: true});
        currentOffer.enrollCustomer(selectedSession, mdn.replace('+1', ''));
    };

    return (
        <div data-test-id="MessagingSales_EnrollmentFlow_ConfirmEnrollment">
            <StyledText size="l" weight="medium">
                {title}
            </StyledText>
            <CheckboxContainer>
                <CoreCheckbox
                    data-test-id="MessagingSales_VerizonEnrollmentFlow_EnrollmentCheckbox"
                    name={checkboxLabel}
                    checked={isEnrollmentConfirmed}
                    label={checkboxLabel}
                    onChange={() => setIsEnrollmentConfirmed(!isEnrollmentConfirmed)}
                    size="medium"
                />
            </CheckboxContainer>
            {isMdnInputEnabled && (
                <StyledMdnInputContainer>
                    <CoreText size="l" weight="medium">
                        Phone number
                    </CoreText>
                    <StyledInputText
                        size="m"
                        inputTextType="primary"
                        onChange={e => onMdnInputChange(e.target.value)}
                        value={formattedMdn}
                        data-test-id="EnrollmentFlow_MDN_Input"
                    />
                    <CoreText size="s">Confirm the customer’s phone number.</CoreText>
                </StyledMdnInputContainer>
            )}
            <NextButton
                data-test-id="MessagingSales_EnrollmentFlow_NextButton"
                color="primary"
                onClick={onNextClick}
                text={buttonText}
                disabled={nextButtonDisabled}
            />
        </div>
    );
});

export default ConfirmEnrollment;
