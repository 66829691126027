import React, {ReactElement} from 'react';
import styled, {css} from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

const Text = styled(CoreText)`
    margin-left: 8px;
`;

const TextContainer = styled.div<{disabled?: boolean; selected?: boolean}>`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    word-break: initial;
    white-space: nowrap;
    padding: 8px;
    border-radius: 4px;
    transition: opacity 0.3s;
    cursor: pointer;

    ${({disabled}) =>
        disabled &&
        css`
            cursor: default !important;
            opacity: 0.5;
        `};

    ${({theme, selected}) =>
        selected &&
        css`
            cursor: default;
            background: ${theme.colors.contrast.toolboxItemSelected};
        `};

    ${({theme, disabled, selected}) =>
        !disabled &&
        !selected &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

type Props = {
    onClick: () => void;
    text: string;
    icon: ReactElement<{size?: string}>;
    'data-test-id'?: string;
};

export default ({icon, onClick, text, 'data-test-id': testId}: Props) => (
    <TextContainer
        onClick={e => {
            onClick();
            e.stopPropagation();
        }}
        data-test-id={testId}
    >
        {icon}
        <Text textType="primary" size="m">
            {text}
        </Text>
    </TextContainer>
);
