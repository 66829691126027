import {useState, useEffect, useCallback} from 'react';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {useCustomerMdn} from '@anywhere-expert/customer-mdn-api';
import {useAnalytics} from 'react-shisell';
import PhoneNumber from 'awesome-phonenumber';
import logger from '@anywhere-expert/logging';

const getMdnWithoutInternationalCode = (mdn: string | number): undefined | string => {
    try {
        return mdn ? new PhoneNumber(`${mdn}`).getNumber('significant')?.replace(/-/g, '') : undefined;
    } catch (err) {
        logger.warn('Failed parsing mdn', {err, extra: {mdn}});
        return undefined;
    }
};

export const copyMdnHandler = (textToCopy: string, analytics) => {
    const handler = event => {
        event.preventDefault();
        event.clipboardData.setData('text/plain', textToCopy);
        document.removeEventListener('copy', handler, true);
    };
    if (!textToCopy) return;

    document.addEventListener('copy', handler, true);
    document.execCommand('copy');

    const toastText = `${textToCopy}\ncopied to clipboard`;
    setAppMessage({text: toastText, type: 'success'});
    analytics.dispatcher.withExtra('ActionId', 'MDNCopy').dispatch('Click');
};

const useCopyMdn = (customerId, partner, customerPlatform, fallbackCustomerMdn?: string, onDone?: () => void) => {
    const [textToCopy, setTextToCopy] = useState('');

    const mdn = useCustomerMdn(customerId, partner, customerPlatform) || '';
    const mdnWithoutInternationalCode =
        getMdnWithoutInternationalCode(mdn) ??
        getMdnWithoutInternationalCode(fallbackCustomerMdn!) ??
        fallbackCustomerMdn ??
        '';

    useEffect(() => {
        if (mdnWithoutInternationalCode !== textToCopy) setTextToCopy(mdnWithoutInternationalCode);
    }, [mdnWithoutInternationalCode, textToCopy]);

    const analytics = useAnalytics();

    const copyMdn = useCallback(() => {
        copyMdnHandler(textToCopy, analytics);
        onDone?.();
    }, [textToCopy, customerId]);

    return {copyMdn, textToCopy};
};

export default useCopyMdn;
