import {BehaviorSubject} from 'rxjs';
import {AppMessageType, ActionType} from './consts';
import {ToastXPosition} from '@anywhere-expert/base-ui';

export type AppMessageData = {
    text?: string | null;
    textSecondLine?: string;
    textThirdLine?: string;
    type?: AppMessageType | null;
    MessageContent?: () => JSX.Element;
    backgroundColor?: string;
    textColor?: string;
    duration?: number;
    actionable?: boolean;
    actionButtonType?: ActionType;
    actionData?: any;
    onAppMessageClosed?: (wasActionClicked: boolean, actionData?: any) => void;
    xPosition?: ToastXPosition;
};

export const _appMessage$ = new BehaviorSubject<AppMessageData>({text: null});
export const setAppMessage = (message: AppMessageData) => _appMessage$.next(message);
export const resetAppMessages = () => _appMessage$.next({text: null});

export const _isShowingAppMessage$ = new BehaviorSubject<boolean>(false);
export const isShowingAppMessage$ = _isShowingAppMessage$.asObservable().shareReplay(1);
