export type ExpertAvailabilityStatus = {
    statusReason: null | string;
    status: Status;
    source?: StatusChangeSource;
    limitReached?: boolean;
};

export enum Status {
    AVAILABLE = 'available',
    AWAY = 'away',
    UNAVAILABLE = 'unavailable',
}

export enum SwitchStatusReason {
    SWITCH_TO_VOICE = 'switching to voice',
    ON_VOICE = 'on voice',
}

export type StatusChangeSource = 'expert_click' | 'login' | 'neglection' | 'logout' | 'inactivity';

export const defaultUnavailable = Status.UNAVAILABLE;
