import {useRef, useState} from 'react';

export type UseHover = [boolean, () => void, () => void];

export default (delay: number = 300): UseHover => {
    const [isHover, setIsHover] = useState(false);
    const timerRef = useRef<any>(undefined);

    const handleMouseHover = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setIsHover(true), delay);
    };

    const handleMouseOut = () => {
        clearTimeout(timerRef.current);
        setIsHover(false);
    };

    return [isHover, handleMouseHover, handleMouseOut];
};
