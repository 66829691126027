import React, {useCallback} from 'react';
import {useState} from 'react';
import Snackbar, {SnackbarProps} from './Snackbar';
import {useAnalytics} from 'react-shisell';

export default (props: Omit<SnackbarProps, 'onClose'>) => {
    const [open, setOpen] = useState(true);
    const {dispatcher} = useAnalytics();

    const onClickWithAnalytics = useCallback(() => {
        dispatcher
            ?.withExtras({
                SubSection: 'Snackbar',
                Status: 'Active',
                ActionId: 'CloseAccessibilityMessage',
                Cta: 'CloseButton',
            })
            .dispatch('Click');
        setOpen(false);
    }, [dispatcher, props.text]);

    if (!open) return null;

    return <Snackbar onClose={onClickWithAnalytics} {...props} />;
};
