import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CoreIconButton, getBoxShadowStyle, getBorderStyle, CoreButton, CoreText} from '@anywhere-expert/base-ui';
import {ArrowRightIcon, ArrowLeftIcon, RefreshIcon, ChevronDownIcon, ChevronUpIcon} from '@anywhere-expert/icons';
import {Recommendation} from '../types';
import {CARD_WIDTH} from '../dimensions';

const showButtonStyles = {padding: '2px 10px 2px 9px'};

const Controls = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
`;

const Badge = styled.div<{show: boolean}>`
    ${({show}) => !show && `opacity: 0`};
    width: 10px;
    height: 10px;
    margin-left: 6px;
    background-color: ${({theme}) => theme.colors.brand.blue};
    border-radius: 50%;
    border: 2px solid ${({theme}) => theme.colors.background.secondary};
`;

const ToolsContainer = styled.div`
    display: flex;
    background: ${({theme}) => theme.colors.neutral.white};
    ${({theme}) => getBoxShadowStyle(theme)};
    ${({theme}) => getBorderStyle(theme)};
    border-radius: 20px;
    padding: 4px;
    align-items: center;
`;

const ScrollButton = styled(CoreIconButton)`
    &.left {
        margin-right: 4px;
    }
    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
    &:disabled {
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }

    color: ${({theme}) => theme.colors.neutral.black};
`;

const ShowContainer = styled.div`
    display: flex;
    padding: 6px 0px;
    align-items: center;
`;

const Divider = styled.div`
    width: 1px;
    height: 80%;
    background-color: ${({theme}) => theme.colors.neutral.whites.w10};
    margin-right: 6px;
    margin-left: -6px;
`;

const ShowButtonAndBadge = ({canRefresh}) => (
    <ShowContainer>
        <CoreText size="s">Show recommendations</CoreText>
        <Badge data-test-id={canRefresh && 'RecommendationsViewControls_NewBadge'} show={canRefresh} />
    </ShowContainer>
);

type Props = {
    incPage: (p: number) => void;
    decPage: (p: number) => void;
    page: number;
    canRefresh: boolean;
    refresh: () => void;
    recommendationsWidth: number;
    hidden: boolean;
    isHidingEnabled: boolean;
    onHiddenChanged: () => void;
    recommendations: Recommendation[];
};

const RecommendationsViewControls = observer(
    ({
        incPage,
        decPage,
        page,
        isHidingEnabled,
        recommendations,
        canRefresh,
        refresh,
        hidden,
        recommendationsWidth,
        onHiddenChanged,
    }: Props) => {
        const showTools = !hidden;

        const numOfPages = useMemo(() => {
            const visibleRecommendations = Math.floor(recommendationsWidth / CARD_WIDTH);
            return Math.max(0, recommendations.length - visibleRecommendations);
        }, [recommendations.length, recommendationsWidth]);

        const ShowButtonComponent = useCallback(() => <ShowButtonAndBadge canRefresh={canRefresh} />, [canRefresh]);
        return (
            <Controls>
                {hidden && (
                    <ToolsContainer data-test-id="RecommendationsViewControls">
                        <CoreButton
                            icon={ChevronUpIcon}
                            iconProps={{side: 'left'}}
                            Text={ShowButtonComponent}
                            size="s"
                            style={showButtonStyles}
                            variant="text"
                            onClick={onHiddenChanged}
                            data-test-id="RecommendationsViewControls_Button_Show"
                        />
                    </ToolsContainer>
                )}

                {showTools && (
                    <ToolsContainer data-test-id="RecommendationsViewControls">
                        {isHidingEnabled && (
                            <CoreButton
                                text="Hide"
                                icon={ChevronDownIcon}
                                iconProps={{side: 'left'}}
                                size="s"
                                variant="text"
                                onClick={onHiddenChanged}
                                data-test-id="RecommendationsViewControls_Button_Hide"
                            />
                        )}
                        <Divider />
                        <ScrollButton
                            size="s"
                            variant="icon"
                            icon={ArrowLeftIcon}
                            onClick={decPage}
                            disabled={page === 0}
                        />
                        <ScrollButton
                            size="s"
                            variant="icon"
                            icon={ArrowRightIcon}
                            onClick={incPage}
                            disabled={page === numOfPages}
                            data-test-id="RecommendationsViewControls_Button_Next"
                        />
                        <CoreIconButton
                            icon={RefreshIcon}
                            variant={canRefresh ? 'contained' : 'icon'}
                            color="tertiary"
                            size="s"
                            disabled={!canRefresh}
                            onClick={refresh}
                            data-test-id={`RecommendationsViewControls_Button_Refresh_${
                                canRefresh ? 'Enabled' : 'Disabled'
                            }`}
                        />
                    </ToolsContainer>
                )}
            </Controls>
        );
    }
);
export default RecommendationsViewControls;
