import React, {ComponentType, ReactElement} from 'react';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';
import {CoreIcon, CoreText} from '@anywhere-expert/base-ui';
import {IconProps} from '@anywhere-expert/icons';

const StyledTypeIcon = styled(CoreIcon)`
    margin: 0 3px;
`;

type Props = {
    title: string;
    icon: ComponentType<IconProps> | ReactElement<any>;
};

const RecTitle = ({title, icon}: Props) => {
    const theme = useTheme();
    return (
        <>
            <StyledTypeIcon size="s" color={theme.colors.contrast.caption} icon={icon} />
            <CoreText textType="tertiary" size="s">
                {title}
            </CoreText>
        </>
    );
};

export default RecTitle;
