import {useAnalytics} from 'react-shisell';
import {useCallback} from 'react';
import logger from '@anywhere-expert/logging';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';

const useMediaUploadCancel = (contentId: string, timelineId: string, sessionId: string, timestamp: number) => {
    const analytics = useAnalytics();

    return useCallback(async () => {
        try {
            localStorage.setItem(`${contentId}_cancel`, 'true');

            const supportItem = ExpertFeedStore.getSupportItem(sessionId);

            if (!supportItem) return;
            supportItem.timelineModel.removeLocalMessage(contentId);

            const timePassedUntilCancel = Date.now() - timestamp;

            analytics.dispatcher
                .withIdentity('SessionId', sessionId)
                .createScoped('Image')
                .withExtra('secondsUntilClicked', timePassedUntilCancel)
                .dispatch('UploadCancel_Click');
        } catch (err) {
            logger.error('Failed to delete media upload', {err});
        } finally {
            localStorage.removeItem(contentId);
        }
    }, [analytics, contentId, timelineId, sessionId, timestamp]);
};

export default useMediaUploadCancel;
