import {useCallback} from 'react';
import {buildOutgoingTextMessage, buildOutgoingImageItem, ImagePayload} from '@anywhere-expert/messaging';
import {useUserProfile, User} from '@anywhere-expert/auth';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';

export const useSendMessage = (
    timelineId: string,
    sessionId: string,
    partner: string,
    sessionSource?: string,
    isAutoGreeting?: boolean
) => {
    const userProfile: User = useUserProfile();

    return useCallback(
        async (message: string | ImagePayload, messageType: string = 'text_message', messageId?: string) => {
            const supportItem = ExpertFeedStore.getSupportItem(sessionId);

            if (!supportItem) return;

            if (typeof message === 'string' && messageType === 'text_message') {
                await supportItem.timelineModel.createMessage(
                    buildOutgoingTextMessage(
                        message,
                        userProfile,
                        sessionId,
                        partner,
                        messageId,
                        sessionSource,
                        isAutoGreeting
                    )
                );
            }

            if (messageType === 'image_item') {
                await supportItem.timelineModel.createMessage(
                    buildOutgoingImageItem(
                        message as ImagePayload,
                        userProfile,
                        sessionId,
                        partner,
                        messageId,
                        sessionSource
                    )
                );
            }
        },
        [userProfile, timelineId, partner, sessionSource]
    );
};
