import {Observable} from 'rxjs/Observable';
import createActivityDetector from 'activity-detector';
import 'rxjs/add/operator/startWith';
import {BehaviorSubject} from 'rxjs';
import ExpertAvailabilityStore from './store/ExpertAvailabilityStore';

export const createPresence = async (timeToIdle: number) => {
    const observable = Observable.create(observer => {
        const activityDetector = createActivityDetector({timeToIdle: timeToIdle, inactivityEvents: []});

        activityDetector.on('idle', () => {
            observer.next(false);
            ExpertAvailabilityStore.setClientActivness(false);
        });

        activityDetector.on('active', async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            observer.next(true);
            ExpertAvailabilityStore.setClientActivness(true);
        });

        return () => {
            activityDetector.stop();
        };
    })
        .startWith(true)
        .share() as Observable<boolean>;

    const subject = new BehaviorSubject(true);

    observable.subscribe(subject);

    return subject;
};

export default createPresence;
