import React from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import {EyeIcon} from '@anywhere-expert/icons';
import MouseIcon from './icons/MouseIcon';
import {CoreTooltip} from '@anywhere-expert/base-ui';
import {SupportedScreenshareLevel} from '../sharedScreenshareDefinitions';
import ScreenshareSessionManager from '../ScreenshareSessionManager';

const circle = (size: number) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
`;

const IconContainer = styled.div`
    ${circle(32)}
    background-color: ${({theme}) => theme.colors.brand.blue};
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid ${({theme}) => theme.colors.brand.blueBrighter};
    box-sizing: content-box;
`;

const levelToProps: Record<SupportedScreenshareLevel, {tooltip: string; icon: React.ReactNode}> = {
    screenshare: {tooltip: "You're viewing this customer's screen", icon: <EyeIcon color="white" />},
    'remote-control': {tooltip: "You're controlling this customer's device", icon: <MouseIcon />},
};

export default observer(({screenshareSessionManager}: {screenshareSessionManager: ScreenshareSessionManager}) => {
    const screenshareSupportLevel: SupportedScreenshareLevel = screenshareSessionManager.deviceSupportInfo.supportLevel;
    const {icon, tooltip} = levelToProps[screenshareSupportLevel];

    return (
        <CoreTooltip text={tooltip}>
            <IconContainer>{icon}</IconContainer>
        </CoreTooltip>
    );
});
