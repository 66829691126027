import React from 'react';
import styled from 'styled-components';
import {isShowingAppOverlay$} from '../../behaviour/appOverlayObservables';
import {useObservable} from 'rxjs-hooks';

const PageOverlay = styled.div`
    background: ${({theme}) => theme.colors.neutral.grey.deepest};
    opacity: 0.5;
    position: fixed;
    width: 100vw;
    z-index: 1000;
    height: 100vh;
    top: 0;
    left: 0;
`;

const AppOverlay = () => {
    const show = useObservable(() => isShowingAppOverlay$);
    if (!show) return null;
    return <PageOverlay />;
};

export default AppOverlay;
