import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {observer} from 'mobx-react';
import React from 'react';
import {RemoteControlIcon, ScreenshareIcon} from './icons';
import {ScreenshareMessagePayload, SupportedScreenshareLevel} from '../../sharedScreenshareDefinitions';
import BaseComponent, {BaseComponentProps} from './BaseComponent';
import useGetComponentTexts from './useGetComponentTexts';
import {patchTimelineItemStateIfInconsistenciesAreFound} from '../../patchTimelineItemStateIfInconsistenciesAreFound';
import logger from '@anywhere-expert/logging';
import {featureName} from '../../definitions';

type ScreenshareExpertTimelineItemProps = TimelineItemProps<ScreenshareMessagePayload>;

const screenshareSupportLevelToIcon: Record<SupportedScreenshareLevel, BaseComponentProps['Icon']> = {
    screenshare: ScreenshareIcon,
    'remote-control': RemoteControlIcon,
};

const FailedToRenderScreenshareComponent = () => <div>[failed to render screenshare item]</div>;

const ScreenshareExpertTimelineItem = observer(
    ({
        content: {
            state,
            screenshareLevel: screenshareLevelFromTimelineItem,
            cobrowseSessionId,
            customerRejectedFullDeviceScreenSharePrompt,
        },
        sessionId,
        contentId,
    }: ScreenshareExpertTimelineItemProps) => {
        const getTexts = useGetComponentTexts();

        // Get rid of the easy cases to minimize room for bugs.
        if (state === 'ended' || state === 'expired') {
            const texts = getTexts(state, screenshareLevelFromTimelineItem);
            const Icon = screenshareSupportLevelToIcon[screenshareLevelFromTimelineItem];

            return (
                <BaseComponent
                    Icon={Icon}
                    headerText={texts.headerText}
                    subHeaderText={texts.subheaderText}
                    notificationText={
                        customerRejectedFullDeviceScreenSharePrompt
                            ? texts.customerRejectedFullDeviceScreenSharePromptNotification
                            : undefined
                    }
                />
            );
        }

        const session = ExpertFeedStore.getSupportItem(sessionId);

        if (!session) {
            logger.error(
                'No session found for the supplied sessionId when trying to render a screenshare timeline item',
                {extra: {sessionId, featureName}}
            );

            return <FailedToRenderScreenshareComponent />;
        }

        patchTimelineItemStateIfInconsistenciesAreFound(session.timelineModel, state, cobrowseSessionId, contentId); // This will cause problems if the same timeline is open on two different tabs - when starting a screenshare session on one tab, the other tab will kill the started session.

        const deviceSupportInfo = session.timelineModel.deviceScreenshareSupportInfo;

        if (deviceSupportInfo.supportLevel === 'not-supported') {
            logger.error('No device support info found for the supplied session', {extra: {sessionId, featureName}});

            return <FailedToRenderScreenshareComponent />;
        }

        const texts = getTexts(state, deviceSupportInfo.supportLevel);
        const Icon = screenshareSupportLevelToIcon[deviceSupportInfo.supportLevel];

        return (
            <BaseComponent
                Icon={Icon}
                headerText={texts.headerText}
                subHeaderText={texts.subheaderText}
                notificationText={
                    deviceSupportInfo.supportLevel === 'remote-control' && !deviceSupportInfo.accessibilityEnabled
                        ? texts.accessibilityDisabledNotification
                        : ''
                }
            />
        );
    }
);

export default ScreenshareExpertTimelineItem;
