import React from 'react';

const AddIcon = ({color, ...others}) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
        <g clipPath="url(#clip0)">
            <path
                d="M18.0312 7.42464C15.1022 4.49563 10.3536 4.49563 7.42464 7.42464C4.49562 10.3537 4.49562 15.1022 7.42464 18.0312C10.3536 20.9603 15.1022 20.9603 18.0312 18.0312C20.9602 15.1022 20.9597 10.3531 18.0312 7.42464ZM13.4778 16.9706H11.978L11.9775 13.4784L8.48529 13.4778L8.4853 11.9781L11.9775 11.9775V8.48477L13.4768 8.4853L13.4768 11.9781L16.9695 11.9781L16.97 13.4773L13.4778 13.4778V16.9706Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="18" height="18" fill="white" transform="translate(12.7279) rotate(45)" />
            </clipPath>
        </defs>
    </svg>
);

export default AddIcon;
