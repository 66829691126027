import logger from '@anywhere-expert/logging';
import Video from 'twilio-video';
import videoStore, {VideoCallState} from './store';
import {getTwilioAccessToken, completeRoom, VideoChatRoomNotFound} from './videoChatService';
import registerParticipantEvents, {registerParticipantNetworkStatus} from './registerParticipantEvents';
import RoomHandlers from './RoomHandlers';

export const connectCurrentRoom = async () => {
    try {
        const roomId = videoStore.roomId!;
        const roomHandlers = videoStore.roomHandlers!;
        videoStore.setVideoCallState(VideoCallState.connecting);

        const token = await getTwilioAccessToken(roomId);
        const room = await Video.connect(token, {
            name: roomId,
            logLevel: 'debug',
            tracks: videoStore.localTracks,
            video: false,
            audio: true,
            networkQuality: {
                local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
                remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
            },
            preferredAudioCodecs: ['opus', 'PCMU'],
        });

        registerToRoomEvents(room, roomHandlers);

        videoStore.roomConnected(room);
        roomHandlers.roomConnected && roomHandlers.roomConnected(roomId);

        registerParticipantNetworkStatus(room.localParticipant);
    } catch (err) {
        if (err instanceof VideoChatRoomNotFound) {
            videoStore.setError("Video chat room doesn't exist anymore", false);
            logger.error('Error connecting to video room: video chat room not found', {err});
        } else {
            videoStore.setError();
            logger.error('Error connecting to video room', {err});
        }

        throw err;
    }
};

export const connectToNewVideoChatRoom = async (roomHandlers: RoomHandlers, roomId: string) => {
    videoStore.setCallConnecting(roomHandlers, roomId);
    await connectCurrentRoom();
};

export const disconnectVideoCall = async (shouldShowTimelineItem: boolean = true) => {
    const roomName = videoStore.roomId;
    const activeRoom = videoStore.activeRoom;

    activeRoom && activeRoom.disconnect();
    roomName && (await completeRoom(roomName));
    videoStore.callEnded(false, shouldShowTimelineItem);
};

export const toggleMuteMicrophone = () => {
    if (!videoStore.isMicrophoneMuted) {
        videoStore.Mute();
    } else {
        videoStore.Unmute();
    }
};

const registerToRoomEvents = (room, roomHandlers: RoomHandlers) => {
    room.participants.forEach(participant => registerParticipantEvents(participant, roomHandlers));
    room.on('participantConnected', participant => registerParticipantEvents(participant, roomHandlers));

    room.on('participantDisconnected', async function (participant) {
        logger.info(`Participant ${participant.identity} left the room`);
    });

    room.on('disconnected', (room, err) => {
        if (err && err.code !== 53118) {
            videoStore.setError('Video chat disconnected');
            logger.error('Twilio video chat disconnected with error', {err});
        } else {
            logger.info(`You disconnected the room`, {extra: {room}});
        }
        disconnectVideoCall(false);
        roomHandlers.disconnected && roomHandlers.disconnected();
    });
};
