import React from 'react';
import styled from 'styled-components';
import {ActionButtonsContainer} from '../ActionBar';
import GenericActionButton from '../ActionBar/GenericActionButton';
import AndroidBackIcon from './icons/AndroidBackIcon';
import AndroidHomeIcon from './icons/AndroidHomeIcon';

const Container = styled(ActionButtonsContainer)`
    flex-direction: row;
`;

export default ({onClickBack, onClickHome}: {onClickBack: VoidFunction; onClickHome: VoidFunction}) => (
    <Container>
        <GenericActionButton icon={AndroidHomeIcon} onClick={onClickHome} tooltip="Home" analyticsActionId="Home" />
        <GenericActionButton icon={AndroidBackIcon} onClick={onClickBack} tooltip="Back" analyticsActionId="Back" />
    </Container>
);
