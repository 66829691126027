import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {SMALL_WIDTH, MEDIUM_WIDTH} from '../shared/consts';

const Text = styled.span`
    color: ${({theme}) => theme.colors.neutral.black};
    font-size: 24px;
    font-weight: 300;
    line-height: 1.24;
`;

const BoldText = styled.span`
    font-weight: 700;
`;

const Arrow = styled.img`
    position: absolute;
    transform: translate(calc(-100% - 16px), calc(-100% + 24px));
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};

    @media (min-width: ${SMALL_WIDTH}) and (max-width: ${MEDIUM_WIDTH}) {
        transform: translate(0, calc(-100% - 8px));
    }
`;

const WhatsNextMessage = observer(() => {
    const isMediumScreen = useMediaQuery(`(max-width:${MEDIUM_WIDTH})`);
    const isSmallScreen = useMediaQuery(`(max-width:${SMALL_WIDTH})`);

    let message: React.ReactNode = 'Get ready to start helping people';
    let showArrow = false;

    if (!ExpertFeedStore.isFirstItemsLoaded) {
        return null;
    } else if (ExpertFeedStore.waitingForResponse.length === 1) {
        message = (
            <>
                Someone is waiting,
                <br />
                go back and help them
            </>
        );
        showArrow = true;
    } else if (ExpertFeedStore.waitingForResponse.length > 1) {
        message = (
            <>
                <BoldText>{ExpertFeedStore.waitingForResponse.length} people</BoldText> are waiting!
                <br />
                Go back and help them
            </>
        );
        showArrow = true;
    } else if (ExpertFeedStore.myAssignedItems.length === 1) {
        message = (
            <>
                You’ve got an assigned conversation,
                <br />
                try reaching out or start helping someone new
            </>
        );
        showArrow = true;
    } else if (ExpertFeedStore.myAssignedItems.length > 0) {
        message = (
            <>
                You’ve got <BoldText>{ExpertFeedStore.myAssignedItems.length} assigned</BoldText> conversations,
                <br />
                try reaching out or start helping someone new
            </>
        );
        showArrow = true;
    } else if (ExpertFeedStore.unAssignedItems.length >= 10) {
        message = (
            <>
                People are waiting,
                <br />
                go ahead and start helping them
            </>
        );
    }

    return (
        <>
            {showArrow && (
                <Arrow
                    src={
                        isMediumScreen && !isSmallScreen
                            ? require('../resources/arrow_small.png')
                            : require('../resources/arrow.png')
                    }
                />
            )}
            <Text>{message}</Text>
        </>
    );
});

export default WhatsNextMessage;
