import React, {FunctionComponent, useMemo} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CoreHighlightedText} from '@anywhere-expert/base-ui';
import {RichInputStore} from '@anywhere-expert/rich-input';

const TextContainer = styled.div`
    display: flex;
    user-select: none;
    flex-direction: column;
    flex: 1;
    word-break: break-word;
    padding: 14px 14px 14px 0px;
`;

type Props = {
    title?: string;
    text: string;
    searchTerm: string;
};

const MessageText: FunctionComponent<Props> = observer(({text = '', searchTerm}) => {
    const limitedText = useMemo(
        () => RichInputStore.plainTextFromMarkdown(text.length <= 200 ? text : `${text.substring(0, 200)}...`),
        [text]
    );

    return (
        <TextContainer>
            <CoreHighlightedText text={limitedText} searchedText={searchTerm} size="m" />
        </TextContainer>
    );
});

export default MessageText;
