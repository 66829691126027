import {useEffect, useRef, useState, Dispatch, SetStateAction} from 'react';

export default ({
    threshold = 1,
}: {
    threshold: number | number[];
}): [Dispatch<SetStateAction<Element | null>>, IntersectionObserverEntry | null] => {
    const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null);
    const [node, setNode] = useState<Element | null>(null);

    const observer = useRef(new window.IntersectionObserver(([entry]) => updateEntry(entry), {threshold}));

    useEffect(() => {
        const {current: currentObserver} = observer;
        currentObserver.disconnect();

        if (node) {
            currentObserver.observe(node);
        }

        return () => currentObserver.disconnect();
    }, [node]);

    return [setNode, entry];
};
