import styled from 'styled-components';
import {CoreButton} from '@anywhere-expert/base-ui';

export const Line = styled.div`
    width: 100%;
    border-top: ${({theme}) => `1px solid ${theme.colors.contrast.border}`};
    margin-bottom: 15px;
    margin-top: 15px;
`;

export const NextButton = styled(CoreButton)`
    margin-top: 10px;
`;
