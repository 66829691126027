import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {CoreText, CoreTag, TechAvatar} from '@anywhere-expert/base-ui';
import StarComponent from './StarComponent';
import {RecommendationContentProps, SimilarSessionRecommendation} from '../../types';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 68px;

    &.open {
        height: unset;
    }
`;

const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0;
    margin-top: 8px;
`;

const StarsContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const TagsContainer = styled.div`
    display: flex;
    overflow: hidden;
`;
const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
    flex: 0;

    &.open {
        margin-top: 12px;
    }
`;

const TitleText = styled(CoreText)`
    -webkit-line-clamp: 2;
    ${p =>
        p.disableTextOverflow &&
        `
        -webkit-line-clamp: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `};
`;

const StyledTechAvatar = styled(TechAvatar)`
    font-size: 14px;
`;

const SnippetText = styled(CoreText)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

type Props = RecommendationContentProps<SimilarSessionRecommendation>;
export default React.memo(({item, isOpen}: Props) => {
    const {dispatcher} = useAnalytics();
    useEffect(() => {
        const enhancedDispatcher = dispatcher.createScoped('SimilarSessions').withExtras({
            SimilarSessionId: item.data.sessionId,
            SimilarExpertId: item.data.expertId,
            SimilarityScore: item.data.similarityScore,
            SimilarSessionDate: item.data.sessionDate,
            Flow: item.data.flow,
            Source: 'SmartRecommendations',
        });

        // The component is rendered with isOpen=true for hover mode, we don't want to dispatch View event for that
        if (isOpen) {
            enhancedDispatcher.dispatch('Hover');
            return;
        }
        enhancedDispatcher.dispatch('View');
    }, []);

    const expertNameToDisplay = item.data.expertName ? item.data.expertName : 'Expert';

    return (
        <Container
            className={isOpen ? 'open' : ''}
            data-test-id={`SimilarSessionRecommendation_${item.data.sessionId}`}
        >
            <TitleText textType="secondary" size="s" className={isOpen ? 'open' : ''} disableTextOverflow weight="bold">
                {item.data.question ?? 'Some Title'}
            </TitleText>
            <SnippetText textType="secondary" size="s">
                {item.data.snippet}
            </SnippetText>
            {isOpen && (
                <StarsContainer>
                    <StarComponent />
                </StarsContainer>
            )}
            <BottomContainer>
                <TagsContainer>
                    {item.data.devices?.map((device: string) => (
                        <CoreTag key={device} text={device} size="s" />
                    ))}
                </TagsContainer>
            </BottomContainer>
            {isOpen && (
                <AvatarContainer className="open">
                    <StyledTechAvatar
                        size={24}
                        name={expertNameToDisplay}
                        imageUrl={item.data.expertPhoto}
                        tryNameInitialsImageFallback={!!item.data.expertName}
                        borderVisible={false}
                    />
                    <CoreText textType="tertiary" size="s" style={{marginLeft: '4px'}}>
                        {expertNameToDisplay}
                    </CoreText>
                </AvatarContainer>
            )}
        </Container>
    );
});
