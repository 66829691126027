import React, {useEffect, useCallback} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {useAnalytics, enrichAnalytics, AnalyticsProvider} from 'react-shisell';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {ChevronRightIcon} from '@anywhere-expert/icons';
import {shortcutsMap} from '@anywhere-expert/app-shortcuts-consts';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import Tabs from './Tabs';
import SidebarStore from '../SidebarStore';
import {useDetectOutsideClick} from '@anywhere-expert/utils';
import {fullstory} from '@anywhere-expert/fullstory';
import {CSSTransition} from 'react-transition-group';
import clsx from 'clsx';

const tooltipProps = {
    keyboardSequence: shortcutsMap['toggle-sidebar'].key,
    text: 'Toggle sidebar',
};

const iconProps = {size: 's'};

const sidebarTabContainerWidth = '48px';
const sidebarContentSlideAnimationMilliseconds = 200;

const SIDEBAR_TAB_MAX_WIDTH = '304px';
const SIDEBAR_TAB_MIN_WIDTH = '304px';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    overflow: visible;
    position: relative;

    .openCloseButton {
        transition: transform ${sidebarContentSlideAnimationMilliseconds}ms linear;
    }

    &.close {
        .openCloseButton {
            transform: rotate(180deg);
        }

        .tabs-indicator-container {
            visibility: hidden;
        }
    }
`;

const StyledTabs = styled(Tabs)`
    width: 100%;
    flex: 1;
`;

type ContentContainerProps = {
    selectedTabId: string;
    minWidth: string;
    maxWidth: string;
};

const StyledContentContainer = styled.div<ContentContainerProps>`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${({theme}) => theme.colors.background.secondary};
    height: 100%;
    flex: 1;
    position: relative;

    min-width: ${({minWidth}) => minWidth};
    max-width: ${({maxWidth}) => maxWidth};

    transition: all ${sidebarContentSlideAnimationMilliseconds / 4}ms ease-in-out;
    transition-property: min-width, max-width;

    &.content-enter {
        transform: translateX(100%);
        opacity: 0;
    }

    &.content-enter-active {
        transform: translateX(0);
        opacity: 1;
        transition: transform ${sidebarContentSlideAnimationMilliseconds}ms ease-in-out,
            opacity ${sidebarContentSlideAnimationMilliseconds}ms ease-in-out;
    }

    &.content-exit {
        transform: translateX(0);
        opacity: 1;
    }

    &.content-exit-active {
        transform: translateX(100%);
        transition: transform ${sidebarContentSlideAnimationMilliseconds}ms ease-in-out,
            opacity ${sidebarContentSlideAnimationMilliseconds}ms ease-in-out;
        opacity: 0;
    }
`;

const TabsContainer = styled.div`
    background-color: ${({theme}) => theme.colors.background.secondary};
    display: flex;
    flex-direction: column;
    width: ${sidebarTabContainerWidth};
    z-index: 11;
`;

const OpenCloseButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
`;

const BottomGradient = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 100%;
    height: 30px;
    background: linear-gradient(#0000 0%, ${({theme}) => theme.colors.background.secondary} 100%);
    align-self: center;
`;

const useRegisterSidebarTabsIfNeeded = (registerTabsConfig: () => void) =>
    useEffect(() => {
        if (!SidebarStore.selectedTab && registerTabsConfig) {
            registerTabsConfig();
        }
    }, [registerTabsConfig, !!SidebarStore.selectedTab]);

const ContentContainer = enrichAnalytics<ContentContainerProps>((dispatcher, {selectedTabId}) =>
    dispatcher.createScoped(selectedTabId)
)(StyledContentContainer) as React.ComponentType<ContentContainerProps & React.HTMLAttributes<HTMLDivElement>>;

interface SidebarProps {
    selectedSession: SupportItem;
    registerTabsConfig: () => void;
}

const Sidebar = observer(({selectedSession, registerTabsConfig}: SidebarProps) => {
    const analytics = useAnalytics();
    const analyticsDispacher = analytics.dispatcher
        .createScoped('Sidebar')
        .withIdentity('SessionId', selectedSession.sessionId);

    useRegisterSidebarTabsIfNeeded(registerTabsConfig);

    const toggleIsSidebarPinned = useCallback(() => {
        SidebarStore.toggleIsSidebarPinned();
        analyticsDispacher
            .withExtra('ActionId', SidebarStore.isSidebarPinned ? 'OpenSidebar' : 'CloseSidebar')
            .dispatch('Click');
    }, [analyticsDispacher]);
    const unpinSidebar = useCallback(() => {
        SidebarStore.setIsSidebarPinned(false);
    }, []);

    const isSmallScreen = useMediaQuery('(max-width:1180px)');

    useEffect(() => {
        SidebarStore.setIsSidebarPinned(!isSmallScreen);
    }, [isSmallScreen]);

    useEffect(() => {
        analyticsDispacher.withExtras({EnabledOptions: Object.keys(SidebarStore.tabsConfig)}).dispatch('View');
    }, []);

    const containerRef = useDetectOutsideClick(() => isSmallScreen && unpinSidebar());

    const fullStoryUrlForErrors = React.useMemo(() => fullstory.getCurrentSessionURL(), [
        selectedSession,
        SidebarStore.selectedTabId,
    ]);
    if (!SidebarStore.selectedTab) return null;

    const {Content, minWidth: contentMinWidth, maxWidth: contentMaxWidth} = SidebarStore.selectedTab;

    return (
        <AnalyticsProvider dispatcher={analyticsDispacher}>
            <Container ref={containerRef} className={clsx(isSmallScreen && 'smallScreen')}>
                <CSSTransition
                    in={SidebarStore.isSidebarOpen}
                    classNames="content"
                    timeout={sidebarContentSlideAnimationMilliseconds}
                    mountOnEnter
                    unmountOnExit
                    appear
                >
                    <ContentContainer
                        data-test-id={SidebarStore.selectedTabId}
                        selectedTabId={SidebarStore.selectedTabId}
                        className="content"
                        minWidth={contentMinWidth ?? SIDEBAR_TAB_MIN_WIDTH}
                        maxWidth={contentMaxWidth ?? SIDEBAR_TAB_MAX_WIDTH}
                    >
                        <LoggingErrorBoundary
                            boundaryName="sidebar"
                            extraData={{
                                sessionId: selectedSession.sessionId,
                                isAssignedToMe: selectedSession.isAssignedToMe,
                                fullStoryUrl: fullStoryUrlForErrors,
                                tabId: SidebarStore.selectedTabId,
                            }}
                        >
                            <Content selectedSession={selectedSession} />
                        </LoggingErrorBoundary>
                        <BottomGradient />
                    </ContentContainer>
                </CSSTransition>
                <TabsContainer onClick={toggleIsSidebarPinned}>
                    <StyledTabs
                        selected={SidebarStore.selectedTabId}
                        setSelected={SidebarStore.setSelectedTabId}
                        tabs={SidebarStore.viewableTabs}
                        selectedSession={selectedSession}
                    />
                    <OpenCloseButtonContainer>
                        <CoreIconButton
                            size="s"
                            icon={ChevronRightIcon}
                            variant="hovered"
                            tooltipProps={tooltipProps}
                            iconProps={iconProps}
                            className="openCloseButton"
                        />
                    </OpenCloseButtonContainer>
                </TabsContainer>
            </Container>
        </AnalyticsProvider>
    );
});

export default Sidebar;
