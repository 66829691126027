import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CannedMessageSuggestion} from '@soluto-private/canned-messages-api-client';
import Suggestion from './Suggestion';
import useSortedFilteredSuggestions from './useSortedFilteredSuggestions';
import CategoryHeader from '../CategoryHeader';
import {useSuggestionsCategory, CalculatedCategory} from '../../../calculatedCategories';
import {UserStorageStore} from '@anywhere-expert/user-storage-store';
import {useAnalytics} from 'react-shisell';
import {useCannedMessagesNavigationStore} from '../../../useCannedMessagesNavigationStore';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 93%;
    flex: 1;
    word-break: break-word;
    margin-left: 8px;
    margin-bottom: 5px;
`;

interface Props {
    suggestions: {
        [key: string]: CannedMessageSuggestion;
    };
    textFilter?: string;
}

const calculateTitle = (
    suggestionsCategory: React.MutableRefObject<CalculatedCategory | undefined>,
    sortedSuggestions: {id: string; suggestion: CannedMessageSuggestion}[]
): string => {
    if (!suggestionsCategory.current) {
        return '';
    }

    return sortedSuggestions?.length
        ? suggestionsCategory.current.title
        : suggestionsCategory.current.emptyCategoryTitle ?? '';
};

const SuggestionCategory = observer(({suggestions, textFilter}: Props) => {
    const analytics = useAnalytics();
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();
    const [isPastingEnabled, setIsPastingEnabled] = useState<boolean>(
        UserStorageStore.getValue('isCannedMessagesPastingEnabled', 'enabled') === 'enabled'
    );

    let sortedSuggestions = useSortedFilteredSuggestions(suggestions, textFilter);
    sortedSuggestions = !isPastingEnabled ? [] : sortedSuggestions;

    const isEmpty = sortedSuggestions.length === 0;
    const [isCollapsed, setCollpase] = useState(isEmpty);
    const suggestionsCategory = useSuggestionsCategory();

    const onToggle = () => {
        setIsPastingEnabled(isPastingEnabled => !isPastingEnabled);
    };

    useEffect(() => {
        UserStorageStore.setValue('isCannedMessagesPastingEnabled', isPastingEnabled ? 'enabled' : 'disabled');

        analytics.dispatcher
            .createScoped('pasted_toggle')
            .withExtras({
                newState: isPastingEnabled,
            })
            .dispatch('click');
    }, [isPastingEnabled]);

    if (!suggestionsCategory.current) {
        return null;
    }

    const {icon, secondaryTitle, id, color} = suggestionsCategory.current;
    const formattedTitle = calculateTitle(suggestionsCategory, sortedSuggestions);

    return (
        <Container>
            <CategoryHeader
                icon={icon}
                toggleAction={onToggle}
                isDisabled={!isPastingEnabled}
                title={formattedTitle}
                secondaryTitle={secondaryTitle}
                id={id}
                showOnlyCaret={true}
                isCollapsed={isCollapsed}
                setCollapse={setCollpase}
                shouldShowIndicator={!isEmpty}
                isGrayedOut={isEmpty}
                removeFocus={cannedMessagesNavigationStore.removeFocus}
            />
            {!isCollapsed && (
                <MessagesContainer>
                    {sortedSuggestions.map(({id, suggestion}) => (
                        <Suggestion
                            key={id}
                            id={id}
                            suggestion={suggestion}
                            categoryColor={color}
                            searchedText={cannedMessagesNavigationStore.searchedText}
                        />
                    ))}
                </MessagesContainer>
            )}
        </Container>
    );
});

export default SuggestionCategory;
