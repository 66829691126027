import React from 'react';
import {observer} from 'mobx-react';
import TimelineSearchTab from './TimelineSearchTab';
import {TabProps} from '@anywhere-expert/session-sidebar';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {getTweekValue} from '@anywhere-expert/tweek';

interface TimelineSearchTabContainerType extends React.FunctionComponent<TabProps> {
    getDisabledText?: any;
}

const TimelineSearchTabContainer: TimelineSearchTabContainerType = observer(({selectedSession}: TabProps) => {
    return <TimelineSearchTab selectedSession={selectedSession} />;
});

TimelineSearchTabContainer.getDisabledText = (selectedSession: SupportItem) => {
    const isEnabled = getTweekValue<boolean>('support/session/messaging/search/is_enabled', true);
    if (!selectedSession.isAssignedToMe) {
        return 'Available only for your assigned sessions';
    }
    if (!isEnabled) {
        return 'This tool is not available';
    }
    return undefined;
};

export default TimelineSearchTabContainer;
