import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {observer} from 'mobx-react';
import {CoreTag, CoreText} from '@anywhere-expert/base-ui';
import {RecommendationContentProps, TailoredOfferRecommendation} from '../types';
import {palette} from '@anywhere-expert/colors';

const Container = styled.div`
    display: flex;
    height: 60px;
    overflow: visible;

    &.open {
        min-height: 90px;
    }
`;

const Body = styled.div<{isOpen?: boolean}>`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Header = styled(CoreText)`
    margin: 0;
    overflow: hidden;
    float: left;
`;

const HeaderText = styled.div<{hasNewTag: boolean}>`
    ${props =>
        props.hasNewTag
            ? `
        float: left;
        max-width: 136px;`
            : ''};
    overflow: hidden;
    text-overflow: ellipsis;
`;

const KeywordsContainer = styled.div<{isOpen: boolean}>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    ${props => (props.isOpen ? 'max-width: 150px;' : '')};
`;

const TailoredSalesKeywordTag = styled(CoreTag)<{isNew: boolean}>`
    background-color: ${props => (props.isNew ? palette.brand.blue : '#37E7A7')};
    color: ${props => (props.isNew ? palette.neutral.white : palette.neutral.black)};
    display: inline-block;
    font-weight: normal;
    margin: 3px 0 0 0;

    & + & {
        margin-left: 2px;
    }
`;

const NewTag = styled.div`
    color: ${palette.brand.blue};
    float: right;
    font-weight: bold;
`;

const OfferPreviewText = styled(CoreText)`
    margin: 5px 0;
    max-height: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 2;
`;

type TailoredOfferRecProps = RecommendationContentProps<TailoredOfferRecommendation>;
export default observer(({item, isOpen}: TailoredOfferRecProps) => {
    const {dispatcher} = useAnalytics();
    const {sessionId, offerRecommendation, partner, keywords} = item.data;

    const now = new Date();
    const [newestKeywords, setNewestKeywords] = useState(
        keywords?.filter(k => {
            const within10SecondsOf = new Date(k.updatedAt || 0);
            within10SecondsOf.setSeconds(within10SecondsOf.getSeconds() + 10);

            return within10SecondsOf > now;
        })
    );

    useEffect(() => {
        // The component is rendered with isOpen=true for hover mode, we don't want to dispatch View event for that
        if (isOpen) return;

        dispatcher
            .createScoped('TailoredOfferRecommendation')
            .withExtras({
                SessionId: sessionId,
                Source: 'TailoredOffer',
                CarrierName: partner,
            })
            .dispatch('View');
    }, []);

    useEffect(() => {
        const clearNewKeywords = setTimeout(() => setNewestKeywords([]), 10000);

        return () => clearTimeout(clearNewKeywords);
    }, [keywords]);

    const isNewKeyword = (keyword: string | undefined): boolean =>
        newestKeywords?.find(k => k.keyword === keyword) !== undefined;

    const newTag = newestKeywords?.length ? <NewTag data-test-id="TailoredOfferNewTag">NEW</NewTag> : null;
    const offerPreviewText = isOpen ? (
        <OfferPreviewText data-test-id="TailoredOfferText" size="s">
            {offerRecommendation.text}
        </OfferPreviewText>
    ) : null;

    return (
        <Container
            className={isOpen ? 'open' : ''}
            data-test-id={isOpen ? 'TailoredOfferRecommendation_open' : 'TailoredOfferRecommendation'}
        >
            <Body isOpen={isOpen}>
                <Header disabledTextOverflow data-test-id="TailoredOfferTitle" weight="bold" textType="secondary">
                    <HeaderText hasNewTag={newTag !== null}>{offerRecommendation.title}</HeaderText>
                    {newTag}
                </Header>
                {offerPreviewText}
                <KeywordsContainer isOpen={isOpen}>
                    <div>
                        {keywords?.map(({keyword}) => (
                            <TailoredSalesKeywordTag
                                size="s"
                                data-test-id={`${keyword}-tag`}
                                key={keyword}
                                text={keyword}
                                isNew={isNewKeyword(keyword)}
                            />
                        ))}
                    </div>
                </KeywordsContainer>
            </Body>
        </Container>
    );
});
