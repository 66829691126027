import {compose, withProps, withHandlers} from 'recompose';
import {withTweekKeys} from '@anywhere-expert/tweek';
import moment from 'moment';
import * as lscache from 'lscache';

type DosAndDontsOption = {
    title: string;
    text: string;
};

export type DosAndDontsOptionState = {
    option: DosAndDontsOption;
    wasPressed: boolean;
};

type Props = {
    messages: DosAndDontsOption[];
    isPressed: boolean;
    setIsPressed: (boolean) => void;
};

const StorageKey = 'DosAndDontsStore';
const ExpiryDurationInMin = 60 * 18;

export const getCurrentMessage = (): DosAndDontsOptionState => {
    lscache.setBucket(StorageKey);
    return lscache.get('current');
};

export const setCurrentMessage = (message: DosAndDontsOptionState): void => {
    lscache.setBucket(StorageKey);
    lscache.set('current', message, ExpiryDurationInMin);
    lscache.flushExpired();
};

export default compose(
    withTweekKeys({messages: 'support/best_practices/messages'}),
    withProps(({messages}: Props) => {
        let currentMessage = getCurrentMessage();
        if (!currentMessage) {
            currentMessage = {option: messages[moment().dayOfYear() % messages.length], wasPressed: false};
            setCurrentMessage(currentMessage);
        }

        return {message: currentMessage};
    }),
    withHandlers({
        handleUserPressed: () => (currentMessage: DosAndDontsOptionState) => {
            setCurrentMessage({option: currentMessage.option, wasPressed: true});
        },
    })
);
