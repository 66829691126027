import {action, computed, observable, reaction, IReactionDisposer, makeObservable} from 'mobx';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {isItemCurrentlySnoozed, getSnoozeField} from '../utils/snoozeUtils';
import QueueItem from './QueueItem';

export default class QueueItemSnoozeModel {
    parent: QueueItem;
    private updateSnoozeStateHandleId;
    private snoozeReaction: IReactionDisposer;
    private clearSnoozeWhenNeglectedReaction: IReactionDisposer;
    isSnoozed: boolean;

    calculateIsSnoozed = () => {
        this.isSnoozed = isItemCurrentlySnoozed(this.parent.feedItemAttributes);
    };

    get isBackFromSnooze() {
        return this.snoozeWakeUpTime > 0 && !this.isSnoozed;
    }

    get snoozeWakeUpTime() {
        return parseFloat(getSnoozeField(this.parent.feedItemAttributes, 'snoozeWakeUpTime'));
    }

    constructor(parent: QueueItem) {
        makeObservable(this, {
            isSnoozed: observable,
            calculateIsSnoozed: action,
            isBackFromSnooze: computed,
            snoozeWakeUpTime: computed,
        });

        this.parent = parent;

        this.snoozeReaction = reaction(() => this.parent.feedItemAttributes, this.calculateIsSnoozed, {
            fireImmediately: true,
        });

        // if a session is already snoozed and getting neglected - clear snooze state
        this.clearSnoozeWhenNeglectedReaction = reaction(
            () => this.parent.isNeglected && this.isSnoozed,
            shouldClearSnooze => {
                if (shouldClearSnooze) {
                    getFeedActionsClient().clearSnoozeIndication({feedItemId: this.parent.sessionId});
                }
            },
            {fireImmediately: true}
        );
    }

    dtor = () => {
        this.snoozeReaction && this.snoozeReaction();
        this.clearSnoozeWhenNeglectedReaction && this.clearSnoozeWhenNeglectedReaction();
        this.updateSnoozeStateHandleId && clearTimeout(this.updateSnoozeStateHandleId);
    };
}
