import React, {useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {CoreCheckbox, CoreText} from '@anywhere-expert/base-ui';
import {NextButton} from '../../../../styled-components';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {useMessagingSalesClickAnalytics, useCurrentOffer, useHorizonEnrollmentFlowContent} from '../../../../../hooks';
import {HorizonEnrollmentFlowStep, HorizonEnrollmentFlowContent} from '../../../../../types';
import StepLabel from './StepLabel';

const CheckboxContainer = styled.div`
    padding: 10px 0 10px 10px;
`;

export const ConfirmCustomerAccept = observer(({selectedSession}: SalesSessionProps) => {
    const {title, buttonText, confirmationText} = useHorizonEnrollmentFlowContent(
        HorizonEnrollmentFlowStep.ConfirmCustomerAccept
    ) as HorizonEnrollmentFlowContent[HorizonEnrollmentFlowStep.ConfirmCustomerAccept];
    const {sessionId} = selectedSession;
    const currentOffer = useCurrentOffer(sessionId);

    const [hasAccepted, setHasAccepted] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

    const toggledAcceptance = useMessagingSalesClickAnalytics('ConfirmCustomerAccept');
    const onToggleAcceptance = (accepted: boolean) => {
        toggledAcceptance({isClickChecking: accepted});
        setHasAccepted(accepted);
    };

    const clickedContinue = useMessagingSalesClickAnalytics('ConfirmCustomerAcceptContinue');
    const onNextClick = async () => {
        if (!hasAccepted) {
            return;
        }

        try {
            setNextButtonDisabled(true);
            clickedContinue({});
            await currentOffer?.confirmCustomerAccepted();
        } finally {
            setNextButtonDisabled(false);
        }
    };

    return (
        <div data-test-id="MessagingSales_HorizonEnrollmentFlow_ConfirmCustomerAccept">
            <StepLabel selectedSession={selectedSession} />
            <CoreText size="m">{title}</CoreText>
            <CheckboxContainer>
                <CoreCheckbox
                    data-test-id="MessagingSales_HorizonEnrollmentFlow_AcceptanceCheckbox"
                    checked={hasAccepted}
                    onChange={() => onToggleAcceptance(!hasAccepted)}
                    label={confirmationText}
                />
            </CheckboxContainer>
            <NextButton
                data-test-id="MessagingSales_EnrollmentFlow_NextButton"
                color="primary"
                onClick={async () => await onNextClick()}
                text={buttonText}
                disabled={!hasAccepted}
                isInProgress={nextButtonDisabled}
            />
        </div>
    );
});

export default ConfirmCustomerAccept;
