import React from 'react';
import styled from 'styled-components';
import {EditIcon, DeleteIcon, WavingHandIcon} from '@anywhere-expert/icons';
import {Action} from '../';
import {useTheme} from '@anywhere-expert/colors';
import {useDetectOutsideClick} from '@anywhere-expert/utils';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {OptionsIcon} from '../../resources';

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ActionsContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5;
    padding: 6px 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({theme}) => theme.colors.neutral.white};
    border-radius: 4px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
`;

const Button = styled(CoreIconButton)`
    margin-right: 5px;
    display: flex;
    position: absolute;
    bottom: 1px;
    right: 0px;
    color: ${({theme}) => theme.colors.contrast.disabled};

    &:hover {
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:active {
        opacity: 1;
    }
`;

type Props = {
    onEdit: () => void;
    onDelete: () => void;
    onCancel: () => void;
    onSetDefaultGreetingMessage: () => void;
    isDefaultGreetingMessage: boolean;
    open: boolean;
    onClick: (e: any) => void;
};

const MessageActionsModal = ({
    open,
    onClick,
    onEdit,
    onCancel,
    onDelete,
    onSetDefaultGreetingMessage,
    isDefaultGreetingMessage,
}: Props) => {
    const wrapperRef = useDetectOutsideClick(onCancel);

    const theme = useTheme();

    return (
        <Container ref={wrapperRef} data-test-id="CannedMessages_MessageOptions">
            <Button
                size="s"
                icon={OptionsIcon}
                onClick={onClick}
                variant="icon-fade"
                data-test-id="CannedMessages_MessageOptions_Button"
            />
            {open ? (
                <ActionsContainer>
                    <Action
                        onClick={onEdit}
                        icon={<EditIcon size="s" color={theme.colors.neutral.black} />}
                        text="Edit"
                        data-test-id="CannedMessages_MessageActions_Edit"
                    />
                    <Action
                        data-test-id="CannedMessages_MessageActions_Delete"
                        onClick={onDelete}
                        icon={<DeleteIcon size="s" color={theme.colors.neutral.black} />}
                        text="Delete"
                    />
                    {!isDefaultGreetingMessage ? (
                        <Action
                            data-test-id="CannedMessages_MessageActions_SetDefaultGreeting"
                            onClick={onSetDefaultGreetingMessage}
                            icon={<WavingHandIcon size="s" color={theme.colors.neutral.black} />}
                            text="Set default greeting"
                        />
                    ) : null}
                </ActionsContainer>
            ) : null}
        </Container>
    );
};

export default MessageActionsModal;
