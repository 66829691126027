import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import {extend} from 'rx-firebase';
import {Observable} from 'rxjs/Observable';
import configuration from './configuration';

export function initFirebase(firebaseConfig: object = configuration.firebase): firebase.app.App | undefined {
    if (firebase.apps.length > 0) return;
    extend(firebase, Observable);
    return firebase.initializeApp(firebaseConfig);
}
