import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {DosAndDonts} from '@anywhere-expert/suggestive-behaviour';
import {useTweekValue} from 'react-tweek';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {CannedMessagesFullView} from '@anywhere-expert/canned-messages-v2';
import Lobby from '@anywhere-expert/lobby';
import {SalesProfile, MessagingSalesTweekKeys} from '@anywhere-expert/messaging-sales';
import NoSessionSelectedStore from '../NoSessionSelectedStore';
import {useMessagingSalesStatsEnablement} from '@anywhere-expert/messaging-sales';

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
`;

const NoSessionSelected = observer(() => {
    const isSalesProfileEnabled = useTweekValue<boolean>(MessagingSalesTweekKeys.IsSalesProfileEnabled, false);
    const isMessagingSalesStatsEnabled = useMessagingSalesStatsEnablement();

    const onCloseView = React.useCallback(() => NoSessionSelectedStore.resetSelectedView(), []);

    React.useEffect(() => {
        if (isSalesProfileEnabled && ExpertFeedStore.isFirstItemsLoaded) {
            NoSessionSelectedStore.setSelectedViewId('sales-profile');
        } else if (isMessagingSalesStatsEnabled || !ExpertFeedStore.supportItemHasBeenSelected) {
            NoSessionSelectedStore.setSelectedViewId('lobby');
        } else {
            NoSessionSelectedStore.setSelectedViewId('dos-and-donts');
        }
    }, []);

    const currentView = NoSessionSelectedStore.selectedViewId;

    return <Container data-test-id="EmptyTimeline">{getItem(currentView, onCloseView)}</Container>;
});

export default NoSessionSelected;

const getItem = (currentView: string, onCloseView) => {
    switch (currentView) {
        case 'canned-messages':
            return <CannedMessagesFullView onClose={onCloseView} />;
        case 'lobby':
            return <Lobby />;
        case 'dos-and-donts':
            return <DosAndDonts />;
        case 'sales-profile':
            return <SalesProfile />;
        default:
            return null;
    }
};
