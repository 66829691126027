import {useTweekValue} from 'react-tweek';
import {
    EnrollmentFlowStep,
    HorizonEnrollmentFlowStep,
    EnrollmentFlowContent,
    HorizonEnrollmentFlowContent,
} from '../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {defaultEnrollmentFlowMapContent, defaultHorizonEnrollmentFlowMapContent} from '../consts';

export const useEnrollmentFlowContent = (
    enrollmentFlowStep: EnrollmentFlowStep
): EnrollmentFlowContent[EnrollmentFlowStep] => {
    return useTweekValue(MessagingSalesTweekKeys.EnrollmentFlowMap, defaultEnrollmentFlowMapContent)[
        enrollmentFlowStep
    ];
};

export const useHorizonEnrollmentFlowContent = (
    enrollmentFlowStep: HorizonEnrollmentFlowStep
): HorizonEnrollmentFlowContent[HorizonEnrollmentFlowStep] => {
    return useTweekValue(MessagingSalesTweekKeys.HorizonEnrollmentFlowMap, defaultHorizonEnrollmentFlowMapContent)[
        enrollmentFlowStep
    ];
};
