import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {SalesStatsProps, TimeRange} from '../../types';
import {useMessagingSalesStore} from '../../hooks/useMessagingSales';
import AcceptCount from './AcceptCount';
import OfferRate from './OfferRate';
import {CoreText} from '@anywhere-expert/base-ui';

const Container = styled.div`
    background-color: ${({theme}) =>
        theme.utils.split(theme.colors.neutral.grey.lighter, theme.colors.neutral.whites.w80)};
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const Title = styled.span`
    font-size: 30px;
    font-weight: 700;
`;

const StatsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 32px;
    justify-content: space-around;
`;

const SalesStats = observer(({currentWeekStats, lastWeekStats}: SalesStatsProps) => {
    return (
        <>
            <Title>My stats</Title>

            <StatsContainer>
                <AcceptCount
                    currentWeekAcceptCount={currentWeekStats.accepts}
                    lastWeekAcceptCount={lastWeekStats.accepts}
                />
                <OfferRate
                    currentWeekOfferRate={currentWeekStats.offerRate}
                    lastWeekOfferRate={lastWeekStats.offerRate}
                />
            </StatsContainer>
        </>
    );
});

const LoadingText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    margin-top: 70px;
`;

const LobbySalesStats = observer(() => {
    const store = useMessagingSalesStore()!;

    const currentWeekStats = store.salesStats.get(TimeRange.ThisWeek);
    const lastWeekStats = store.salesStats.get(TimeRange.LastWeek);

    const isStatsLoaded = currentWeekStats && lastWeekStats;

    return (
        <Container data-test-id="LobbySalesStats">
            {!isStatsLoaded ? (
                <LoadingText size="xxl">Loading your stats...</LoadingText>
            ) : (
                <SalesStats currentWeekStats={currentWeekStats!} lastWeekStats={lastWeekStats!} />
            )}
        </Container>
    );
});

export default LobbySalesStats;
