import React, {useCallback} from 'react';
import {CoreActionMenu, CoreTooltip} from '@anywhere-expert/base-ui';
import {LinkIcon} from '@anywhere-expert/icons';
import AddLinkMenu from './AddLinkMenu';
import {RichInputStore} from '../stores/RichInputStore';
import {observer} from 'mobx-react';
import {AnalyticsDispatcher} from 'shisell';

const actionMenuStyle = {menuItem: {padding: 0}};

const containerStyle = {marginLeft: '8px'};
const tooltipStyleOverride = {marginLeft: '6px'};

const childStyle = {zIndex: 5};

type LinkToolProps = {
    richInputStore: RichInputStore;
    analyticsDispatcher: AnalyticsDispatcher;
    shadowProps: Object;
    iconProps: Object;
};

const LinkTool = observer(({richInputStore, analyticsDispatcher, shadowProps, iconProps}: LinkToolProps) => {
    const [showAddLink, setShowAddLink] = React.useState(false);
    const onLinkMenuClick = useCallback(() => {
        setShowAddLink(prevShowAddLink => !prevShowAddLink);
        analyticsDispatcher.withExtra('ActionId', `AddLinkTool`).dispatch('Click');
    }, [analyticsDispatcher, setShowAddLink]);

    const onLinkMenuClickOut = useCallback(() => {
        setShowAddLink(false);
    }, [setShowAddLink]);

    return (
        <CoreTooltip styleOverride={tooltipStyleOverride} text="Link" position="right">
            <div>
                <CoreActionMenu
                    directionUp
                    extend={showAddLink}
                    style={shadowProps}
                    onClickOut={onLinkMenuClickOut}
                    role="presentation"
                    onClick={onLinkMenuClick}
                    styles={actionMenuStyle}
                    size="s"
                    iconProps={iconProps}
                    buttonIcon={LinkIcon}
                    containerStyle={containerStyle}
                    childStyle={childStyle}
                >
                    <AddLinkMenu richInputStore={richInputStore} setShowAddLink={setShowAddLink} />
                </CoreActionMenu>
            </div>
        </CoreTooltip>
    );
});

export default LinkTool;
