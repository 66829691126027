import React, {FunctionComponent, useMemo} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CoreText, CoreHighlightedText} from '@anywhere-expert/base-ui';
import {WavingHandIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';
import {RichInputStore} from '@anywhere-expert/rich-input';

const TextContainer = styled.div`
    display: flex;
    user-select: none;
    flex-direction: column;
    flex: 1;
    word-break: break-word;
    padding: 14px 30px 14px 0px;
`;

const Title = styled.div`
    margin-bottom: 4px;
`;

const DefaultGreetingIcon = styled(WavingHandIcon)`
    display: flex;
    position: absolute;
    top: 7px;
    right: 5px;
`;

type Props = {
    categoryId: string;
    messageId: string;
    title?: string;
    text: string;
    isDefaultGreetingMessage: boolean;
    searchedText: string;
};

const MessageText: FunctionComponent<Props> = observer(
    ({categoryId, messageId, title, text = '', isDefaultGreetingMessage, searchedText}) => {
        const theme = useTheme();
        const limitedText = useMemo(() => (text.length <= 200 ? text : `${text.substring(0, 200)}...`), [text]);
        const plainTextFromMarkdown = useMemo(() => RichInputStore.plainTextFromMarkdown(limitedText), [limitedText]);

        return (
            <TextContainer>
                {!!title && (
                    <Title>
                        {!searchedText ? (
                            <CoreText textType="secondary" weight="bold" size="m">
                                {title}
                            </CoreText>
                        ) : (
                            <CoreHighlightedText
                                textType="secondary"
                                weight="bold"
                                size="m"
                                text={title}
                                searchedText={searchedText}
                            />
                        )}
                    </Title>
                )}

                {!searchedText ? (
                    <CoreText textType="secondary">{plainTextFromMarkdown}</CoreText>
                ) : (
                    <CoreHighlightedText textType="secondary" text={text} searchedText={searchedText} />
                )}
                {isDefaultGreetingMessage ? (
                    <DefaultGreetingIcon
                        size="s"
                        color={theme.colors.contrast.caption}
                        data-test-id={`CannedMessages_DefaultGreetingIcon_${categoryId}_${messageId}`}
                    />
                ) : null}
            </TextContainer>
        );
    }
);

export default MessageText;
