import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .react-autosuggest__suggestions-container {
        background-color: ${({theme}) => theme.colors.background.secondary};
        padding: 8px 4px;
        border-radius: 4px;
        border: solid 1px ${({theme}) => theme.colors.neutral.grey.light};
        display: none;
    }

    .react-autosuggest__container {
        position: relative;
        flex-grow: 1;
    }

    .react-autosuggest__input {
        color: ${({theme}) => theme.colors.contrast.primary};
        width: 100%;
        background-color: transparent;
        font-size: 14px;
        border: 0;
        &::placeholder {
            opacity: 0.5;
            font-weight: 500;
        }
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 25px;
        width: 268px;
        font-weight: 300;
        font-size: 14px;
        z-index: 2;
        max-height: 230px;
        overflow-y: auto;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion--highlighted {
        border-radius: 4px;
        background-color: ${({theme}) => theme.colors.contrast.hover};
    }
    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 0;
    }
`;
