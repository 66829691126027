import {computed, action, makeObservable} from 'mobx';
import {isVoiceSession} from '../utils/sessionType';
import {CallChannel} from '../types';
import SupportItem from './SupportItem';
import {VideoCallState, VideoChatStore} from '@anywhere-expert/voice-over-video';

export default class QueueItemCallDetailsModel {
    parent: SupportItem;

    constructor(parent: SupportItem) {
        makeObservable(this, {
            setEndCallLocally: action,
            isCall: computed,
            isWaitingForCustomerVideo: computed,
            isOngoingCall: computed,
            isIncomingCall: computed,
            isVoiceSession: computed,
            isDisplayingStationDeviceCamera: computed,
            callChannel: computed,
        });

        this.parent = parent;
    }

    setEndCallLocally() {
        const localAttributes = {...this.parent.sessionAttributes};
        if (!!localAttributes['voice']?.['lifecycle']?.fields?.['state']) {
            localAttributes['voice']['lifecycle'].fields['state'] = 'end';
            this.parent.updateSessionAttributes(localAttributes);
        }
    }

    get isCall() {
        return this.parent.inFeed && this.isVoiceSession;
    }

    get isVoiceSession() {
        return this.isOngoingCall || this.isIncomingCall;
    }

    get isWaitingForCustomerVideo() {
        const attributes = this.parent.supportItemDetails && this.parent.supportItemDetails.attributes;
        return (
            Array.isArray(attributes) &&
            attributes.some(
                attribute =>
                    attribute.attributeType === 'voice' &&
                    attribute.key === 'waiting-for-customer' &&
                    attribute.fields &&
                    attribute.fields['isEnabled']
            )
        );
    }

    get isOngoingCall() {
        const isVoice = isVoiceSession(this.parent.sessionAttributes);
        const isOngoing =
            VideoChatStore.callState === VideoCallState.connected ||
            VideoChatStore.callState === VideoCallState.connecting;
        return isVoice && isOngoing;
    }

    get isIncomingCall() {
        return (
            this.parent.inFeed &&
            isVoiceSession(this.parent.sessionAttributes) &&
            this.parent.sessionAttributes['voice']?.['lifecycle']?.fields?.['state'] === 'start'
        );
    }

    get isDisplayingStationDeviceCamera() {
        const attributes = this.parent.supportItemDetails && this.parent.supportItemDetails.attributes;
        return (
            Array.isArray(attributes) &&
            attributes.some(
                attribute =>
                    attribute.attributeType === 'voice' &&
                    attribute.key === 'device-facing-camera' &&
                    attribute.fields &&
                    attribute.fields['isEnabled']
            )
        );
    }

    get callChannel(): CallChannel {
        if (this.isOngoingCall || this.isIncomingCall) {
            return 'video-chat';
        }

        return undefined;
    }
}
