import {VIDEO_CHAT_SERVICE_BASE_URL} from './utils';
import logger from '@anywhere-expert/logging';
import {authorizedFetch} from '@anywhere-expert/auth';
import pRetry from 'p-retry';

const withRetry = <T>(promise: Promise<T>) => {
    return pRetry(() => promise, {
        retries: 3,
        maxTimeout: 2000,
    });
};

export class VideoChatRoomNotFound extends Error {
    constructor(message = 'Video chat room not found') {
        super(message);

        Object.setPrototypeOf(this, VideoChatRoomNotFound.prototype);
    }
}
export const getTwilioAccessToken = async (roomId: string) => {
    try {
        const url = `${VIDEO_CHAT_SERVICE_BASE_URL}/api/v1/videoChat/${roomId}/accessToken`;
        const res = await withRetry(
            authorizedFetch(url, {
                method: 'GET',
            })
        );

        if (res.status == 404) {
            throw new VideoChatRoomNotFound();
        }

        return (await res.json()).token;
    } catch (err) {
        logger.error('failed to fetch token from video chat service', {err});
        throw err;
    }
};

export const completeRoom = async (roomId: string) => {
    try {
        const url = `${VIDEO_CHAT_SERVICE_BASE_URL}/api/v1/videoChat/${roomId}/complete`;

        const res = await withRetry(
            authorizedFetch(url, {
                method: 'PUT',
            })
        );

        return (await res.json()).token;
    } catch (err) {
        logger.error('failed to complete room from video chat service', {err});
    }
    return false;
};
