import {CobrowseSessionState, CobrowseToolName} from './cobrowse-agent-sdk_types';
import HandIcon from './components/ScreensharePanel/ActionBar/icons/HandIcon';
import LaserIcon from './components/ScreensharePanel/ActionBar/icons/LaserIcon';
import PenIcon from './components/ScreensharePanel/ActionBar/icons/PenIcon';
import {ScreenshareTimelineItemState} from './sharedScreenshareDefinitions';

export const cobrowseStateToTimelineItemState: Record<CobrowseSessionState, ScreenshareTimelineItemState> = {
    pending: 'pending',
    authorizing: 'pending',
    active: 'ongoing',
    ended: 'ended',
};

export const toolsToProps: Record<CobrowseToolName, {icon: React.ComponentType; tooltip: string}> = {
    laser: {icon: LaserIcon, tooltip: 'Point'},
    drawing: {icon: PenIcon, tooltip: 'Draw'},
    control: {icon: HandIcon, tooltip: 'Control'},
};

export type ToolName = keyof typeof toolsToProps;
export type ToolProps = typeof toolsToProps[ToolName];

export const featureName = 'ae-screen-share';
