import {defaultTo, descend, ascend, partition, path, pipe, sort} from 'ramda';
import {QueueItemAttribute} from '@soluto-private/expert-queue-api-types';
import {getTweekValue} from '@anywhere-expert/tweek';

import {
    getDefinedTags,
    isSpecialTag,
    mapQueueItemAttributesToTags,
    mapSessionAttributesToTags,
    mapSpecialTags,
    Tag,
} from './index';
import {Attribute} from '../../../../types';
import {getRelevantSalesTag} from './salesTag';

const byPriority = pipe(path(['fields', 'priority']), defaultTo(1));
const byCreatedTime = pipe(path(['fields', 'createdTimeInUnix']), defaultTo(1));

const mapAttributesToTags = pipe(sort(descend(byPriority)), mapSessionAttributesToTags);

const mapQueueItemAttributes: (queueItemAttributes: QueueItemAttribute[]) => Tag[] = pipe(
    getDefinedTags,
    sort(ascend(byCreatedTime)),
    mapQueueItemAttributesToTags
);

/*
    If you want to add a new tag type, you have to configure correctly in tweek to make it visible
    and in the right order. Otherwise your tag will not be visible.
    support/feed/support_item/tags contains all the tag configuration.
*/
export const extractTags = (attributes: Attribute[] = [], queueItemAttributes: QueueItemAttribute[] = []): Tag[] => {
    const [special, rest] = partition(isSpecialTag, attributes);
    const isCustomTagsEnabled = getTweekValue('support/expert/custom_tags/is_enabled', false);
    let queueItemTags = mapQueueItemAttributes(queueItemAttributes);

    if (!isCustomTagsEnabled) {
        queueItemTags = queueItemTags.filter(({tagType}) => tagType !== 'customTag');
    }
    const tags = [...special.map(mapSpecialTags), ...mapAttributesToTags(rest), ...queueItemTags];
    return getRelevantSalesTag(tags);
};
