import {observable, action, runInAction, computed, reaction, makeObservable} from 'mobx';
import {ExpertStats, MessagingSalesOffer, TimeRange} from './types';
import MessagingSalesApi from './api/MessagingSalesApi';
import {default as MessagingSales} from './components/sales-tab';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {TabType, SidebarStore} from '@anywhere-expert/session-sidebar';
import {SalesIcon} from '@anywhere-expert/icons';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {FeatureTweekKeys} from './FeatureTweekKeys';
import {isReadyToLoadInteraction, getExpertId} from './utils/helpers';
import {Moment} from 'moment';
import {getTweekValue} from '@anywhere-expert/tweek';
import {SalesOfferModel} from './SalesOfferModel';
import {
    getThisMoment,
    getStartOfPreviousPayPeriodISO,
    getEndOfPreviousPayPeriodISO,
    getStartOfThisPayPeriodISO,
    getThisTimeISO,
    getThisMomentYesterday,
    getStartOfGivenDayISO,
    getEndOfGivenDayISO,
} from './utils/time';
import InteractionTracker from './api/InterationTracker';

const getDebounceForBatchProcessing = () => 400;

export class MessagingSalesStore {
    sessionOffers = new Map<string, SalesOfferModel | null>();
    salesStats = new Map<TimeRange, ExpertStats | null>();

    constructor() {
        makeObservable(this, {
            sessionOffers: observable,
            salesStats: observable,
            fetchPreviousStats: action,
            fetchCurrentStats: action,
            loadedOfferSessionIds: computed,
            salesStatsLastUpdatedAt: computed,
        });

        SidebarStore.setTabsConfig({
            ...SidebarStore.tabsConfig,
            ['messaging-sales']: {
                Content: MessagingSales,
                tooltipText: 'Messaging Sales',
                Icon: SalesIcon,
                type: TabType.Pinned,
                getDisabledText: MessagingSales.getDisabledText,
            },
        });

        this.fetchPreviousStats();
        this.fetchCurrentStats();

        reaction(
            () => ExpertFeedStore.myAssignedSupportItems,
            async myAssignedSupportItems => {
                await Promise.all([
                    this.handleIncomingSessions(myAssignedSupportItems),
                    this.handleCompletedSessions(myAssignedSupportItems),
                ]);
            },
            {
                fireImmediately: true,
                delay: getDebounceForBatchProcessing(),
            }
        );
    }

    get loadedOfferSessionIds(): string[] {
        return Array.from(this.sessionOffers.keys());
    }

    get salesStatsLastUpdatedAt(): string | undefined {
        return this.salesStats.get(TimeRange.ThisWeek)?.updatedAt;
    }

    getOffer(sessionId: string): SalesOfferModel | undefined | null {
        return this.sessionOffers.get(sessionId);
    }

    getStatsByTimeRange(range: TimeRange): ExpertStats | null | undefined {
        return this.salesStats.get(range);
    }

    async fetchPreviousStats() {
        const isSalesStatsEnabled = getTweekValue(MessagingSalesTweekKeys.IsSalesStatsEnabled, false);

        if (!isSalesStatsEnabled) {
            return;
        }

        const yesterdaysStats = await this.getDailyStats(getThisMomentYesterday());
        const lastWeekStats = await this.fetchExpertStats(
            getStartOfPreviousPayPeriodISO(),
            getEndOfPreviousPayPeriodISO()
        );

        runInAction(() => {
            this.salesStats.set(TimeRange.LastWeek, lastWeekStats);
            this.salesStats.set(TimeRange.Yesterday, yesterdaysStats);
        });
    }

    async fetchCurrentStats() {
        const isSalesStatsEnabled = getTweekValue(MessagingSalesTweekKeys.IsSalesStatsEnabled, false);

        if (!isSalesStatsEnabled) {
            return;
        }

        const todaysStats = await this.getDailyStats(getThisMoment());
        const currentWeekStats = await this.fetchExpertStats(getStartOfThisPayPeriodISO(), getThisTimeISO());

        runInAction(() => {
            this.salesStats.set(TimeRange.ThisWeek, currentWeekStats);
            this.salesStats.set(TimeRange.Today, todaysStats);
        });
    }

    private async handleIncomingSessions(myAssignedItems: SupportItem[]) {
        const sessionsWithNewLoadableOffer = myAssignedItems.filter(
            i => !this.loadedOfferSessionIds.includes(i.sessionId) && isReadyToLoadInteraction(i)
        );

        // create placeholder of offer in map so it's only loaded once
        runInAction(() => sessionsWithNewLoadableOffer.forEach(s => this.sessionOffers.set(s.sessionId, null)));
        const offers = await MessagingSalesApi.getOffersByIds(sessionsWithNewLoadableOffer);
        runInAction(() => {
            offers
                .filter((o: MessagingSalesOffer) => o)
                .forEach(offer => {
                    const session = sessionsWithNewLoadableOffer.find(s => s.sessionId === offer.sessionId)!;
                    if (session) {
                        this.sessionOffers.set(offer.sessionId, new SalesOfferModel(offer, session));
                    }
                });
        });
    }

    private async handleCompletedSessions(myAssignedItems: SupportItem[]) {
        let shouldRefreshCurrentStats = false;
        const isExpertInteractionTrackerEnabled = getTweekValue(
            FeatureTweekKeys.IsExpertInteractionTrackerEnabled,
            false
        );
        const myAssignedSessionIds = myAssignedItems.map(item => item.sessionId);
        const completedSessionIds = this.loadedOfferSessionIds.filter(id => !myAssignedSessionIds.includes(id));

        completedSessionIds.forEach(sessionId => {
            const completedOffer = this.sessionOffers.get(sessionId);
            const completedSessionChangesStats = !!completedOffer && completedOffer.offer.isEligible !== false;
            if (completedSessionChangesStats) {
                shouldRefreshCurrentStats = true;
            }
        });

        runInAction(() => {
            completedSessionIds.forEach(id => {
                if (isExpertInteractionTrackerEnabled) {
                    const interactionId = this.sessionOffers.get(id)?.offer.interactionId;
                    if (interactionId) InteractionTracker.getInstance().clearInteractions(interactionId);
                }
                this.sessionOffers.delete(id);
            });
        });

        if (shouldRefreshCurrentStats) {
            await this.fetchCurrentStats();
        }
    }

    private async getDailyStats(givenMoment: Moment): Promise<ExpertStats> {
        const startOfDay = getStartOfGivenDayISO(givenMoment);
        const endOfDay = getEndOfGivenDayISO(givenMoment);

        return await this.fetchExpertStats(startOfDay, endOfDay);
    }

    private async fetchExpertStats(from: string, to: string): Promise<ExpertStats> {
        return await MessagingSalesApi.getExpertStats(getExpertId(), from, to);
    }
}
