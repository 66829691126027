import React from 'react';
import ExpertFeedStore from '../ExpertFeedStore';
import {observer} from 'mobx-react';

export const withSelectedFeedItem = mapFeedItemToProps => (Component: React.ComponentType<any>) =>
    observer(props => {
        const selectedSession = ExpertFeedStore.selectedSupportSession;
        if (!selectedSession) {
            return <Component {...props} />;
        }

        const {supportItemDetails = {}, ...other} = selectedSession;
        const {sessionId, customerId, pool} = other;
        const sessionSource = selectedSession.sessionSource;

        const flat = {
            ...other,
            inFeed: selectedSession.inFeed,
            isAssigned: selectedSession.isAssigned,
            isAssignedToMe: selectedSession.isAssignedToMe,
            ...supportItemDetails,
        };
        const mapped = {...mapFeedItemToProps(flat), sessionId, customerId, pool, sessionSource};

        return <Component {...props} {...mapped} />;
    });
