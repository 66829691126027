import {
    AvailabilityApi,
    UpdateWaitingForSession,
    UpdateAvailabilityRequest,
} from '@soluto-private/expert-api-client-v1';
import {authorizedFetch} from '@anywhere-expert/auth';
import config from './config';
import uuid from 'uuid';

let client: AvailabilityApi | undefined;

const getClient = (): AvailabilityApi => {
    if (!client) {
        client = new AvailabilityApi(undefined, config.expertApiUrl, authorizedFetch);
    }

    return client;
};

export const updateExpertAvailability = async (expertId: string, availability: UpdateAvailabilityRequest) =>
    getClient().update(expertId, {
        meta: {
            correlationId: uuid(),
        },
        payload: availability,
    });

export const updateExpertWaitingForSession = async (expertId: string, status: UpdateWaitingForSession) =>
    getClient().waitForSession(expertId, {
        meta: {
            correlationId: uuid(),
        },
        payload: status,
    });

export default getClient;
