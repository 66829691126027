import {useEffect} from 'react';
import {useTweekValue} from 'react-tweek';
import {useUserProfile} from '@anywhere-expert/auth';
import ExpertAvailabilityStore from './ExpertAvailabilityStore';

let didInit = false;
const useInitExpertAvailabilityStore = () => {
    const user = useUserProfile();
    const isExpertAvailabilityEnabled = useTweekValue<boolean>(
        'support/feed/expert_availability/toggle/is_enabled',
        false
    );

    const isInitiallyAvailable = useTweekValue<boolean>(
        'support/feed/expert_availability/initial_status/is_available',
        false
    );

    useEffect(() => {
        if (didInit) return;
        didInit = true;
        ExpertAvailabilityStore.init(isExpertAvailabilityEnabled, isInitiallyAvailable, user.uid, user.cameFromLogin);
    }, []);
};

export default useInitExpertAvailabilityStore;
