import {observable, action, computed, set, remove, makeObservable} from 'mobx';
import Fuse from 'fuse.js';
import {v4 as uuid} from 'uuid';
import {analytics} from 'react-shisell';
import {User} from '@anywhere-expert/auth';
import {defaultEmoji} from '../consts';
import {getRandomColor} from '../utils';
import {fullstory} from '@anywhere-expert/fullstory';
import {setAppMessage} from '@anywhere-expert/app-messages';
import logger from '@anywhere-expert/logging';
import {getCannedMessagesConfig} from '..';
import expertCannedMessagesApi from '../cannedMessagesApi';
import {
    ExpertCannedMessages,
    CreateCategoryPayload,
    UpdateCategoryPayload,
    CreateCannedMessagePayload,
    UpdateCannedMessagePayload,
    CannedMessage,
    Category,
    CreateCannedMessageSuggestionPayload,
} from '@soluto-private/canned-messages-api-client';
import {CreateMessageData, DraggedMessage} from '../types';
import getInitialCannedMessages from '../getInitialCannedMessages';
import getPredefinedCannedMessages from '../getPredefinedCannedMessages';
import {getSuggestionsCategory, CalculatedCategory, getMostFrequentlyUsedCategoryData} from '../calculatedCategories';

class CannedMessagesStoreV2 {
    private expertId: string;
    private displayName: string;
    private fuzzyIndex: Fuse<string>;
    areActionsEnabled: boolean;

    expertCannedMessages: ExpertCannedMessages;
    isLoading: boolean;
    private suggestionsCategory: CalculatedCategory;
    private mostFrequentlyUsedCategoryData: CalculatedCategory;

    init = async (userProfile: User) => {
        const {isPredefined} = getCannedMessagesConfig();

        this.expertId = userProfile.uid;
        this.displayName = userProfile.displayName;
        this.isLoading = false;

        if (!this.expertCannedMessages) {
            if (isPredefined) {
                this.areActionsEnabled = false;
                this.fetchPredefinedMessages();
                return;
            }

            this.areActionsEnabled = true;
            this.fetchExpertData();
        }

        this.suggestionsCategory = await getSuggestionsCategory();
        this.mostFrequentlyUsedCategoryData = await getMostFrequentlyUsedCategoryData();
    };

    constructor() {
        makeObservable<CannedMessagesStoreV2, 'updateExpertCannedMessages'>(this, {
            expertCannedMessages: observable,
            isLoading: observable,
            init: action,
            mostFrequentlyUsedMessages: computed,
            flatedMostFrequentlyUsedMessages: computed,
            expertCategories: computed,
            defaultGreetingMessageId: computed,
            defaultGreetingMessage: computed,
            updateExpertCannedMessages: action,
            fetchPredefinedMessages: action,
            fetchExpertData: action,
            createExpertCannedMessages: action,
            setDefaultGreetingMessage: action,
            addNewCategory: action,
            editCategory: action,
            deleteCategory: action,
            addMessage: action,
            updateMessage: action,
            deleteMessage: action,
            updateMessageUsage: action,
            nextCategoryDisplayOrder: computed,
            sortedCategories: computed,
        });

        this.fuzzyIndex = new Fuse<string>([], {
            includeScore: true,
            threshold: 0.4,
            distance: 5,
            location: 0,
        });
    }

    get mostFrequentlyUsedMessages(): (CannedMessage & {categoryId: string; messageId: string})[] {
        return Object.entries(this.expertCannedMessages.categories)
            .reduce(
                (sum, [categoryId, category]) => [
                    ...sum,
                    ...Object.entries(category.messages).map(([messageId, message]) => ({
                        messageId,
                        categoryId,
                        ...message,
                    })),
                ],
                []
            )
            .sort((a, b) => b.usage - a.usage)
            .slice(0, 5);
    }

    get flatedMostFrequentlyUsedMessages() {
        return this.mostFrequentlyUsedCategoryData
            ? this.mostFrequentlyUsedMessages.map(message => ({
                  messageId: message.messageId,
                  categoryId: this.mostFrequentlyUsedCategoryData.id,
              }))
            : [];
    }

    get expertCategories(): {[key: string]: Category} {
        return this.expertCannedMessages?.categories || {};
    }

    get defaultGreetingMessageId(): string | undefined {
        return this.expertCannedMessages.metadata.defaultGreeting;
    }

    get defaultGreetingMessage(): CannedMessage | undefined {
        const defaultGreetingId = this.expertCannedMessages?.metadata?.defaultGreeting;

        if (!defaultGreetingId) return;

        const category = Object.values(this.expertCannedMessages.categories).find(
            category => category.messages[defaultGreetingId]
        );

        return category?.messages[defaultGreetingId];
    }

    private updateExpertCannedMessages(expertCannedMessages: ExpertCannedMessages) {
        this.expertCannedMessages = expertCannedMessages;
        this.isLoading = false;

        const collection = Object.values(this.expertCannedMessages.categories).reduce((prev, curr) => {
            const messages = Object.values(curr.messages);
            prev.push.apply(
                prev,
                messages.map(x => x.text)
            );
            return prev;
        }, [] as string[]);

        this.expertCannedMessages.suggestions &&
            collection.push.apply(
                collection,
                Object.values(this.expertCannedMessages.suggestions).map(x => x.text)
            );

        this.fuzzyIndex.setCollection(collection);
    }

    fetchPredefinedMessages = async () => {
        this.isLoading = true;
        const result = await getPredefinedCannedMessages(this.expertId, this.displayName);
        this.updateExpertCannedMessages(result);
    };

    fetchExpertData = async (isLoading: boolean = true) => {
        try {
            this.isLoading = isLoading;
            const result = await expertCannedMessagesApi.get(this.expertId);
            this.updateExpertCannedMessages(result);
            return result;
        } catch (err) {
            if (err.status === 404) {
                await this.createExpertCannedMessages();
            }

            logger.warn('failed to get expert canned messages', {err, extra: {expertId: this.expertId}});
        }
    };

    async createExpertCannedMessages() {
        try {
            const expertCannedMessages = await getInitialCannedMessages(this.expertId, this.displayName);

            await expertCannedMessagesApi.create(this.expertId, expertCannedMessages);
            this.updateExpertCannedMessages(expertCannedMessages);
        } catch (err) {
            logger.error('failed to create canned messages', {err, extra: {expertId: this.expertId}});
        }
    }

    async setDefaultGreetingMessage(id: string) {
        fullstory.sendEvent('SetDefaultGreeting', {id});
        const result = await expertCannedMessagesApi.updateMetadata(this.expertId, {defaultGreeting: id});
        this.updateExpertCannedMessages(result);

        setAppMessage({
            text: 'Your default greeting message has been updated',
            type: 'success',
        });
    }

    async addNewCategory(categoryName: string) {
        try {
            const newCategory: CreateCategoryPayload = {
                title: categoryName,
                displayOrder: this.nextCategoryDisplayOrder,
                icon: defaultEmoji,
                color: getRandomColor(),
            };

            const result = await expertCannedMessagesApi.createCategory(this.expertId, newCategory);
            this.updateExpertCannedMessages(result);

            setAppMessage({
                text: 'Category created successfully',
                type: 'success',
            });
        } catch (err) {
            logger.error(`Failed to create category`, {err, extra: {categoryName}});
            setAppMessage({
                text: 'Sorry, we had a technical problem adding the category. Please try again',
                type: 'warning',
            });
        }
    }

    async editCategory(categoryId: string, updates: UpdateCategoryPayload) {
        try {
            const result = await expertCannedMessagesApi.updateCategory(this.expertId, categoryId, updates);
            this.updateExpertCannedMessages(result);

            setAppMessage({
                text: 'Category updated successfully',
                type: 'success',
            });
        } catch (err) {
            logger.error(`Failed to update category`, {err, extra: {categoryId, updates}});
            setAppMessage({
                text: 'We’re sorry this category could not be updated',
                type: 'warning',
            });
        }
    }

    async deleteCategory(categoryId: string) {
        try {
            const result = await expertCannedMessagesApi.deleteCategory(this.expertId, categoryId);
            this.updateExpertCannedMessages(result);
            setAppMessage({
                text: 'Category deleted successfully',
                type: 'success',
            });
        } catch (err) {
            logger.error(`Failed to delete category`, {err, extra: {categoryId}});
            setAppMessage({
                text: 'We’re sorry this category could not be deleted',
                type: 'warning',
            });
        }
    }

    async addMessageSuggestion(
        messageData: CreateCannedMessageSuggestionPayload,
        sessionId: string,
        timelineId: string
    ): Promise<ExpertCannedMessages | undefined> {
        logger.info('addMessageSuggestion - start', {extra: {messageData}});
        if (messageData.text.length < 22) {
            return;
        }

        const fuzzyMatches = this.fuzzyIndex.search(messageData.text);
        logger.info('addMessageSuggestion - matched canned message', {extra: {messageData, fuzzyMatches}});
        if (fuzzyMatches.length) {
            return;
        }

        this.suggestionsCategory &&
            (await analytics.dispatcher
                .createScoped('AEWeb_Sidebar_canned-messages_Messages')
                .withIdentities({
                    SessionId: sessionId,
                    DeviceId: timelineId,
                    TechId: this.expertId,
                })
                .withExtras({
                    TargetCategoryId: this.suggestionsCategory.id,
                    TargetCategoryName: this.suggestionsCategory.title,
                    AddedBy: 'Automatic',
                })
                .dispatch('AddNew'));

        try {
            const newMessage = {
                ...messageData,
                title: messageData.title,
                displayOrder: this.expertCannedMessages?.suggestions
                    ? Math.max(
                          ...Object.values(this.expertCannedMessages.suggestions).map(message => message.displayOrder),
                          0
                      ) + 1
                    : 1,
            };

            await expertCannedMessagesApi.createCannedMessageSuggestion(this.expertId, newMessage);

            return await this.fetchExpertData(false);
        } catch (err) {
            logger.error('failed to add suggestion', {err, extra: {expertId: this.expertId}});
        }
    }

    async addMessage(categoryId: string, messageData: CreateMessageData): Promise<{addedSuccessfully: boolean}> {
        try {
            const usage = messageData.usage || 0;
            const newMessage: CreateCannedMessagePayload = {
                text: messageData.text,
                displayOrder: messageData?.displayOrder
                    ? this.calculateAvailableDisplayOrder({categoryId, desiredDisplayOrder: messageData?.displayOrder})
                    : this.getNextMessageDisplayOrder(categoryId),
                tags: [],
                usage,
                source: messageData.source,
            };

            if (messageData.title) {
                newMessage.title = messageData.title;
            }

            set(this.expertCannedMessages.categories[categoryId].messages, uuid(), newMessage);

            const result = await expertCannedMessagesApi.createMessage(this.expertId, categoryId, newMessage);
            this.updateExpertCannedMessages(result);

            setAppMessage({
                text: 'Message added successfully',
                type: 'success',
            });

            return {addedSuccessfully: true};
        } catch (err) {
            logger.error(`Failed to add message`, {err, extra: {categoryId, messageData}});
            setAppMessage({
                text:
                    'There was a problem saving this message. Please try again later, or report an issue if this continues.',
                type: 'warning',
            });

            return {addedSuccessfully: false};
        }
    }

    async moveMessage(cannedMessage: DraggedMessage, newCategoryId: string, desiredDisplayOrder?: number) {
        const {categoryId, id: messageId} = cannedMessage;
        return await this.updateMessage(categoryId, messageId, {newCategoryId}, desiredDisplayOrder);
    }

    private calculateAvailableDisplayOrder({
        categoryId,
        desiredDisplayOrder,
        suggestions,
    }: {
        categoryId?: string;
        desiredDisplayOrder: number;
        suggestions?: boolean;
    }) {
        const messages = suggestions
            ? this.expertCannedMessages.suggestions
            : this.expertCannedMessages.categories[categoryId!]?.messages;

        if (!messages) {
            return desiredDisplayOrder;
        }

        const messageWithTheSameOrder = Object.values(messages).find(x => x.displayOrder === desiredDisplayOrder);
        if (!messageWithTheSameOrder) {
            return desiredDisplayOrder;
        }

        const largestDisplayOrderSmallerThanTheDesiredOne = Math.max(
            ...Object.values(messages)
                .map(msg => msg.displayOrder)
                .filter(displayOrder => displayOrder < desiredDisplayOrder),
            0
        );

        return (desiredDisplayOrder + largestDisplayOrderSmallerThanTheDesiredOne) / 2;
    }

    async updateMessage(
        currentCategoryId: string,
        messageId: string,
        updates: Omit<UpdateCannedMessagePayload, 'displayOrder'>,
        desiredDisplayOrder?: number
    ): Promise<{updatedSuccessfully: boolean}> {
        const updatePayload: UpdateCannedMessagePayload = {...updates};

        if (desiredDisplayOrder) {
            updatePayload.displayOrder = this.calculateAvailableDisplayOrder({
                categoryId: currentCategoryId,
                desiredDisplayOrder,
            });
        }

        const newMessage = Object.assign(
            this.expertCannedMessages.categories[currentCategoryId].messages[messageId],
            updatePayload
        );
        set(this.expertCannedMessages.categories[currentCategoryId].messages, messageId, newMessage);

        try {
            const result = await expertCannedMessagesApi.updateMessage(
                this.expertId,
                currentCategoryId,
                messageId,
                updatePayload
            );
            this.updateExpertCannedMessages(result);
            setAppMessage({
                text: 'Message updated successfully',
                type: 'success',
            });

            return {updatedSuccessfully: true};
        } catch (err) {
            logger.error(`Failed to update message`, {
                err,
                extra: {categoryId: currentCategoryId, messageId, updated: updatePayload},
            });
            setAppMessage({
                text:
                    'There was a problem updating this message. Please try again later, or report an issue if this continues.',
                type: 'warning',
            });

            return {updatedSuccessfully: false};
        }
    }

    async deleteMessage(categoryId: string, messageId: string) {
        remove(this.expertCannedMessages.categories[categoryId].messages, messageId);

        const result = await expertCannedMessagesApi.deleteMessage(this.expertId, categoryId, messageId);
        this.updateExpertCannedMessages(result);
    }

    async deleteMessageSuggestion(
        suggestionId: string,
        shouldDisplayToast = true
    ): Promise<{deletedSuccessfully: boolean}> {
        try {
            this.expertCannedMessages.suggestions && remove(this.expertCannedMessages.suggestions!, suggestionId);
            await expertCannedMessagesApi.deleteCannedMessageSuggestion(this.expertId, suggestionId);
            await this.fetchExpertData(false);

            if (shouldDisplayToast) {
                setAppMessage({
                    text: 'Pasted message deleted successfully',
                    type: 'success',
                    xPosition: 'right',
                });
            }

            return {deletedSuccessfully: true};
        } catch (err) {
            logger.error('failed to delete suggestion', {err, extra: {expertId: this.expertId}});

            return {deletedSuccessfully: false};
        }
    }

    async deleteMessageSuggestionsIfAny() {
        if (!this.expertCannedMessages.suggestions || Object.keys(this.expertCannedMessages.suggestions).length === 0)
            return;

        try {
            this.expertCannedMessages.suggestions && remove(this.expertCannedMessages, 'suggestions');
            await expertCannedMessagesApi.deleteCannedMessageSuggestions(this.expertId);
            await this.fetchExpertData(false);
        } catch (err) {
            logger.error('failed to delete suggestions', {err, extra: {expertId: this.expertId}});
        }
    }

    async updateMessageUsage(categoryId: string, messageId: string) {
        const result = await expertCannedMessagesApi.updateMessageUsage(this.expertId, categoryId, messageId);
        this.updateExpertCannedMessages(result);
    }

    get nextCategoryDisplayOrder(): number {
        if (!this.expertCannedMessages?.categories) return 0;

        return (
            Math.max(...Object.values(this.expertCannedMessages.categories).map(category => category.displayOrder), 0) +
            1
        );
    }

    get sortedCategories() {
        return Object.entries(this.expertCategories)
            .sort(([, {displayOrder: a}], [, {displayOrder: b}]) => a - b)
            .map(([categortId, category]) => [
                categortId,
                {
                    ...category,
                    messages: Object.entries(category.messages).sort(
                        ([, {displayOrder: d}], [, {displayOrder: b}]) => d - b
                    ),
                },
            ]) as [string, any][];
    }

    getNextMessageDisplayOrder(categoryId: string): number {
        return (
            Math.max(
                ...Object.values(this.expertCannedMessages?.categories[categoryId].messages).map(
                    message => message.displayOrder
                ),
                0
            ) + 1
        );
    }
}

export default new CannedMessagesStoreV2();

export type CannedMessagesStoreV2Type = CannedMessagesStoreV2;
