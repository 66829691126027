import {
    InStoreDetails,
    SupportItemDetails,
    DeviceDetails,
    CustomerDetails,
    SessionContext,
    AmazonPrePurchaseDetails,
} from '../types';
import {QueueItemAttribute, QueueItemWithoutAttributes} from '@soluto-private/expert-queue-api-types';
import {AttributesByType} from '@soluto-private/session-api-types';

import {extractAttributeFields, extractAttribute} from './attributeUtils';
import {QueueItem, TaskItem, SupportItem} from '../models';
import {Attribute} from '@anywhere-expert/messaging-sales';

declare function unescape(s: string): string;

const getInStoreDetails = (attributes: Attribute[]): InStoreDetails | undefined => {
    const sessionDetails =
        Array.isArray(attributes) &&
        attributes.find(attribute => attribute.attributeType === 'session-details' && attribute.key === 'in-store');
    if (!sessionDetails || !sessionDetails.fields) {
        return undefined;
    }

    const {storeName, services, storeNumber, storeLocation, stationId} = sessionDetails.fields;
    return {
        storeName: (storeName as string) || 'Unknown',
        services: (services as string) || 'Unknown',
        storeNumber: (storeNumber as string) || 'Unknown',
        storeLocation: (storeLocation as string) || 'Unknown',
        stationId: (stationId as string) || 'Unknown',
    };
};

export const getNameFromAttributes = (
    feedItemAttributes: QueueItemAttribute[],
    sessionAttributes: AttributesByType
): string | undefined => {
    const feedNameAttribute = feedItemAttributes.find(({key, type}) => key === 'name' && type === 'meta');
    const sessionNameAttribute = sessionAttributes['meta']?.['name'];
    const amazonCustomerName = sessionAttributes['metadata']?.['amazon_pre_purchase']?.['fields']?.['customerName'];

    const name = feedNameAttribute && unescape(feedNameAttribute.fields.text);
    const sessionNaming = sessionNameAttribute && unescape(sessionNameAttribute?.fields?.text);

    return name && name !== '' ? name : sessionNaming && sessionNaming !== '' ? sessionNaming : amazonCustomerName;
};

export const transformSupportItem = (item: QueueItemWithoutAttributes): SupportItemDetails => {
    const {sessionId, customerId, pool, sessionAttributes, status, assignedExperts} = item;
    const attributes = Object.values(sessionAttributes).reduce(
        (prev, curr) => [...(prev as any[]), ...Object.values(curr as any)],
        []
    ) as Attribute[];
    const customerDetailsFields = extractAttributeFields(sessionAttributes, 'customer-details');
    const deviceDetails = getDeviceDetails(sessionAttributes);
    const inStoreDetails = getInStoreDetails(attributes as Attribute[]);

    return {
        ...deviceDetails,
        sessionId,
        customerId,
        pool,
        assignedTechnicians: assignedExperts.reduce(
            (expertMap, currentExpertId) => ({...expertMap, [currentExpertId]: 'Technician'}),
            {}
        ),
        attributes,
        status,
        formFactor: deviceDetails.type || '',
        partner: customerDetailsFields['partner'] || 'unknown',
        inStoreDetails,
    };
};

export const getProactiveAttribute = (sessionAttributes: AttributesByType) =>
    extractAttribute(sessionAttributes, 'proactive-session');

export const getProactiveSessionAttribute = (sessionAttributes: AttributesByType) =>
    extractAttribute(sessionAttributes, 'proactive');

export const getSessionContext = (sessionAttributes: AttributesByType): SessionContext[] => {
    const attr = sessionAttributes['session-context-item'];
    if (!attr) return [];

    const expectedFields = ['displayName', 'value'];
    return Object.values(attr) // eslint-disable-next-line no-prototype-builtins -- ignore typescript errors for @npmsoluto -> @soluto-private package migration
        .filter(({fields}) => fields && expectedFields.every(field => fields.hasOwnProperty(field)))
        .map(({fields}) => ({
            displayName: fields!.displayName,
            value: fields!.value?.toString(),
        }));
};

export function getDeviceDetails(sessionAttributes: AttributesByType): DeviceDetails {
    const deviceDetailsFields = extractAttributeFields(sessionAttributes, 'device-details');
    const osType: string = deviceDetailsFields['os-type'] || '';

    return {
        deviceModel:
            deviceDetailsFields['model'] ||
            deviceDetailsFields['type'] ||
            !!(deviceDetailsFields['isMobileApp'] as boolean) ||
            deviceDetailsFields['platform']?.type === 'mobile'
                ? 'device'
                : 'unknown',
        osType,
        osVersion: deviceDetailsFields['os-version'] || '',
        platform: ['web', 'unknown', ''].includes(osType.toLowerCase()) ? 'web' : 'device',
        type: deviceDetailsFields['type'],
    };
}

export const getCustomerDetails = (sessionAttributes: AttributesByType): CustomerDetails => {
    const customerDetailsFields = extractAttributeFields(sessionAttributes, 'customer-details');

    return {
        name: customerDetailsFields['name'],
        firstName: customerDetailsFields['first-name'],
        lastName: customerDetailsFields['last-name'],
        partner: customerDetailsFields['partner'],
        email: customerDetailsFields['email'],
        mdn: customerDetailsFields['mdn'],
    };
};

export const getAmazonPrePurchaseDetails = (sessionAttributes: AttributesByType): AmazonPrePurchaseDetails => {
    const customerDetailsFields = extractAttributeFields(sessionAttributes, 'metadata', 'amazon_pre_purchase');

    return {
        prime: customerDetailsFields['prime'],
        postalCode: customerDetailsFields['postalCode'],
        customerName: customerDetailsFields['customerName'],
        searchText: customerDetailsFields['searchText'],
        searchUrl: customerDetailsFields['searchUrl'],
        title: customerDetailsFields['title'],
        productUrl: customerDetailsFields['productUrl'],
        asin: customerDetailsFields['asin'],
    };
};

export const getQuickReplyAttributes = (sessionAttributes: AttributesByType): undefined | string[] => {
    const fields = extractAttributeFields(sessionAttributes, 'quick_reply');
    return fields?.options;
};

export const getSessionFirstMessageId = (sessionAttributes: AttributesByType): string | undefined => {
    const customerDetailsFields = extractAttributeFields(sessionAttributes, 'session-first-message');
    return customerDetailsFields?.id;
};

export const feedPrioritySort = (items: QueueItem[]) => {
    const neglected: QueueItem[] = [];
    const waitingForResponse: SupportItem[] = [];
    const backFromSnooze: QueueItem[] = [];
    const rest: QueueItem[] = [];
    const tasks: TaskItem[] = [];

    items.forEach(item => {
        if (item.isNeglected) neglected.push(item);
        else if (item.isSupport() && item.isWaitingForResponse) waitingForResponse.push(item);
        else if (item.snooze.isBackFromSnooze) backFromSnooze.push(item);
        else if (item.isTask()) tasks.push(item);
        else rest.push(item);
    });

    return [...tasks, ...neglected, ...waitingForResponse, ...backFromSnooze, ...rest];
};
