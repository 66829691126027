export type UneligibleForAssignReason = 'send_message_first' | 'empty_queue' | 'session_limit_reach' | 'not_available';
import type {AssignStoreType} from './store/AssignStore';
import type {AnalyticsDispatcher} from 'shisell';

export enum AssignmentState {
    ERROR = 'internal_error',
    LIMIT_REACH = 'session_limit_reach',
    UNAVAILABLE = 'not_available',
    SHORT_TERM_INACTIVE = 'short_term_inactive',
    AVAILABLE_WAITING_PUSH = 'available_waiting_push',
    SEND_MSG_FIRST = 'send_message_first',
    LOW_VOLUME = 'low_volume',
    AWAITING = 'waiting_for_session',
    ENABLED = 'enabled',
    SWITCH_TO_VOICE = 'switch_to_voice',
    ON_VOICE = 'on_voice',
}

export type MessageData = {
    text: string;
    subText?: string;
    onClick?: (assignStore: AssignStoreType, dispatcher: AnalyticsDispatcher) => void;
    icon: React.ComponentType<{color: string}>;
    name: AssignmentState;
    ButtonComponent?: React.ComponentType<ActionLinkProps>;
};

export type ActionLinkProps = {
    onClickFunction: () => void;
};
