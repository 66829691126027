const map = {
    ['text_message']: 'TextMessage',
    ['timestamp']: 'Timestamp',
    ['add_an_expert']: 'AddAnExpert',
    ['content_card']: 'ContentCard',
    ['image_item']: 'ImageItem',
    ['attachment']: 'Attachment',
    ['video_item']: 'VideoItem',
    ['command']: 'Command',
    ['command_result']: 'CommandResult',
    ['app_link']: 'AppLink',
    ['dynamic_component']: 'DynamicComponent',
    ['home_visit_details']: 'HomeVisitDetails',
    ['auto_assign_assignment_indicator']: 'AutoAssignAssignmentIndicator',
    ['system_message_for_expert']: 'SystemMessageForExpert',
    ['system_message']: 'SystemMessage',
    ['add_another_device_suggestion']: 'AddAnotherDeviceSuggestion',
    ['helix_recommendation']: 'HelixRecommendation',
    ['scroll_to_message_item']: 'ScrollToMessageItem',
    ['call_announcement']: 'CallAnnouncement',
    ['email']: 'Email',
    ['activity']: 'Activity',
    ['amazon_pre_purchase']: 'AmazonPrePurchase',
    ['cobrowsing_approval_item']: 'CobrowsingApprovalItem',
    ['accessories_carousel_item']: 'AccessoriesCarouselItem',
    ['breakdowns_carousel_item']: 'BreakdownsCarouselItem',
    ['follow_up']: 'FollowUp',
    ['article']: 'Article',
    ['screenshare_item']: 'ScreenshareExpertTimelineItem',
};

const lowerDashToUpperCamel = (str: string) =>
    str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join('');

export default (type: string): string => map[type] || lowerDashToUpperCamel(type);
