import {customerMdnCache} from '@anywhere-expert/customer-mdn-api';
import {QueueItem} from '@anywhere-expert/expert-feed-store';
import {copyMdnHandler} from '../behaviour/useCopyMDN';

const useShortcutCopyMdn = (analytics, dispatcher, selectedSession?: QueueItem) => {
    return {
        id: 'copy-mdn',
        handler: (e: KeyboardEvent) => {
            if (!selectedSession || selectedSession.isTask()) return null;

            e.preventDefault();
            const {customerId} = selectedSession.supportItemDetails;
            customerMdnCache.dump();

            const mdn = customerMdnCache.get(customerId);

            if (!mdn) return null;

            copyMdnHandler(mdn, analytics);

            dispatcher.withExtra('ActionId', 'KeyboardShortcut_CopyMDN').dispatch('Click');
        },
    };
};

export default useShortcutCopyMdn;
