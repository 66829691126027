import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys, OfferStatusContent, OfferType} from '../types';
import {defaultOfferStatusTextContent} from '../consts';

export const useOfferStatusContent = (): OfferStatusContent => {
    const offerStatusText = useTweekValue(MessagingSalesTweekKeys.OfferStatus, defaultOfferStatusTextContent);

    return {
        [OfferType.Automatic]: {
            src: require('../components/sales-tab/sales-tools/sales-offer/resources/magic-wand.png'),
            text: offerStatusText.Automatic,
        },
        [OfferType.Manual]: {
            src: require('../components/sales-tab/sales-tools/sales-offer/resources/notepad.png'),
            text: offerStatusText.Manual,
        },
    };
};
