import {MeasurementTag} from './behaviours/types';

export const TYPE_CONVERSATION_CLICK_TO_TIMELINE_LOAD: MeasurementTag = {
    name: 'conversationClick',
    thresholdInMs: 1000,
};
export const TYPE_ACCEPT_CONVERSATION_TO_INPUT_RENDER: MeasurementTag = {
    name: 'acceptConversation',
    thresholdInMs: 1000,
};
export const TYPE_TIMELINE_LOAD: MeasurementTag = {name: 'timelineLoad', thresholdInMs: 1000};
export const TYPE_FIRST_FEED_RENDER: MeasurementTag = {name: 'firstFeedRender', thresholdInMs: 1000};
export const TYPE_FIRST_HOME_PAGE_RENDER: MeasurementTag = {name: 'firstHomePageRender', thresholdInMs: 1000};
export const TYPE_LINK_PREVIEW_SEND: MeasurementTag = {name: 'linkPreviewSend', thresholdInMs: 2000};
export const TYPE_IMAGE_UPLOAD: MeasurementTag = {name: 'imageUpload', thresholdInMs: 2000};
export const TYPE_FIRST_PAINT_WITH_CACHE: MeasurementTag = {name: 'firstPaintWithCache', thresholdInMs: 6000};
export const TYPE_FIRST_PAINT_WITHOUT_CACHE: MeasurementTag = {
    name: 'firstPaintWithoutCache',
    thresholdInMs: 15000,
};
export const TYPE_TIMELINE_SEARCH_RESULTS_LOAD: MeasurementTag = {
    name: 'timelineSearchResultsLoad',
    thresholdInMs: 2000,
};
