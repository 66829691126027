import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {getTextColor, getTextSize, TextSizeProps} from '@anywhere-expert/base-ui/src/CoreText';
import {useTheme} from '@anywhere-expert/colors';

export const AttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
`;

export const AttributeName = styled(CoreText).attrs({
    size: 's',
    textType: 'primary',
    weight: 'bold',
})`
    margin-bottom: 1px;
    cursor: default;
    user-select: none;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
`;

const Link = styled.a<{textSize: TextSizeProps; textColor: string}>`
    font-size: ${({textSize}) => textSize};
    font-family: Roboto;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    display: inline;
    color: ${({textColor}) => textColor};
`;

export type AttributeProps = {
    name: string;
    textValue?: string | null;
    defaultValue: string;
    disableTextOverflow?: boolean;
    dataPii?: boolean;
    url?: string;
};

export default ({name, textValue, defaultValue, dataPii, url}: AttributeProps) => {
    const finalValue = React.useMemo(() => (!!textValue ? textValue : defaultValue), [textValue, defaultValue]);
    const textSize = getTextSize('m');
    const theme = useTheme();
    const textColor = getTextColor(theme, 'secondary');
    const cleanName = React.useMemo(() => name.replace(/ /g, ''), [name]);

    return (
        <AttributeContainer data-test-id={`SessionDetails_Attribute_${cleanName}`}>
            <AttributeName>{name}</AttributeName>
            <Content>
                <CoreText
                    size="m"
                    textType="secondary"
                    title={finalValue}
                    disableTextOverflow={true}
                    data-pii={!!dataPii}
                >
                    <Link href={url} textSize={textSize} textColor={textColor}>
                        {finalValue}
                    </Link>
                </CoreText>
            </Content>
        </AttributeContainer>
    );
};
