import React, {SyntheticEvent, useCallback, useState} from 'react';
import styled, {css} from 'styled-components';
import {CannedMessageSuggestion} from '@soluto-private/canned-messages-api-client';
import DeleteButton from './DeleteButton';
import SaveButton from './SaveButton';
import EditButton from './EditButton';
import {
    AddCannedMessagesModal,
    CannedMessagesStoreV2,
    useSuggestionsCategory,
} from '@anywhere-expert/canned-messages-v2';
import SuggestionText from './SuggestionText';
import useSuggestionsCategoryDispatcher from './useSuggestionsCategoryDispatcher';
import {openModal} from '@anywhere-expert/base-ui';
import {CANNED_MESSAGE_SOURCE_AUTOMATIC} from '../../../../consts';

const Container = styled.div<{selected?: boolean}>`
    display: flex;
    flex-direction: row;
    position: relative;
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-radius: 8px;
    transition: opacity 0.3s;
    width: 100%;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    cursor: pointer;
    border: 2px dashed ${({theme}) => theme.colors.neutral.whites.w10};

    box-sizing: border-box;
    border-radius: 8px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    ${({theme, selected}) =>
        selected &&
        css`
            cursor: default;
            background: ${theme.colors.contrast.toolboxItemSelected};
        `};

    ${({theme, selected}) =>
        !selected &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 12px;
    padding-right: 8px;
`;

const CategoryColor = styled.div<{color: string}>`
    display: flex;
    width: 4px;
    border-radius: 2px;
    margin-left: 6px;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: ${({color}) => color};
`;

const BottomActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TextAndActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 5em; /* So the "edit" icon appearing on hover won't cause jittering */
`;

const SideActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

interface Props {
    id: string;
    suggestion: CannedMessageSuggestion;
    categoryColor: string;
    searchedText: string;
}

async function addCannedMessageAndDeleteSuggestion(
    messageText: string,
    categoryId: string,
    title: string | undefined,
    suggestionId: string
): Promise<{addedSuccessfully: boolean}> {
    const {addedSuccessfully} = await CannedMessagesStoreV2.addMessage(categoryId, {
        text: messageText,
        title: title,
        source: CANNED_MESSAGE_SOURCE_AUTOMATIC,
    });

    if (addedSuccessfully) {
        await CannedMessagesStoreV2.deleteMessageSuggestion(suggestionId, false);
    }

    return {addedSuccessfully};
}

const Suggestion = ({id, suggestion, categoryColor, searchedText}: Props) => {
    const [hover, setHover] = useState(false);
    const dispatcher = useSuggestionsCategoryDispatcher();

    const onEdit = useCallback(
        async (text: string, categoryId: string, title?: string) => {
            const {addedSuccessfully} = await addCannedMessageAndDeleteSuggestion(text, categoryId, title, id);

            return addedSuccessfully;
        },
        [id]
    );
    const suggestionsCategory = useSuggestionsCategory();

    const onOpenEditModalClick = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation();
            if (!suggestionsCategory.current) {
                return;
            }

            dispatcher && dispatcher.withExtras({ActionId: 'OpenModal'}).dispatch('Click');
            openModal(AddCannedMessagesModal, {
                id: 'add-message-modal',
                contentProps: {
                    ...suggestion,
                    id,
                    onCancel: () => {},
                    onSubmit: onEdit,
                },
            });
        },
        [suggestion, onEdit, suggestionsCategory.current, dispatcher]
    );

    const onSave = useCallback(
        async (categoryId: string, categoryName: string) => {
            dispatcher &&
                (await dispatcher
                    .withExtras({ActionId: 'SaveIn', TargetCategoryId: categoryId, TargetCategoryName: categoryName})
                    .dispatch('Click'));

            await addCannedMessageAndDeleteSuggestion(suggestion.text, categoryId, suggestion.title, id);
        },
        [id, suggestion, dispatcher]
    );

    const onDelete = useCallback(
        async (event: React.SyntheticEvent) => {
            event.stopPropagation();
            await CannedMessagesStoreV2.deleteMessageSuggestion(id);
            dispatcher && (await dispatcher.withExtras({ActionId: 'Delete'}).dispatch('Click'));
        },
        [id, dispatcher]
    );

    return (
        <Container
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onOpenEditModalClick}
        >
            <CategoryColor color={categoryColor} />
            <ContentContainer>
                <TextAndActionsContainer>
                    <SuggestionText id={id} suggestion={suggestion} searchedText={searchedText} />
                    <SideActionsContainer>
                        <DeleteButton onClick={onDelete} />
                        <EditButton onClick={onOpenEditModalClick} isVisible={hover} />
                    </SideActionsContainer>
                </TextAndActionsContainer>
                <BottomActionsContainer>
                    <SaveButton onSave={onSave} />
                </BottomActionsContainer>
            </ContentContainer>
        </Container>
    );
};

export default Suggestion;
