import React, {useEffect, useMemo} from 'react';
import {CoreDropdown} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import AvailabilityMenu from './AvailabilityMenu';
import {ExpertAvailabilityStatus, defaultUnavailable, Status} from '../types';
import AvailableIndicator from './AvailabilityIndicator';
import {observer} from 'mobx-react';
import {useAnalytics} from 'react-shisell';
import {useTweekValue} from 'react-tweek';
import createMenu from '../utils/createMenu';
import DropdownText from './DropdownText';
import ExpertAvailabilityStore from '../store/ExpertAvailabilityStore';

const menuStyle = {minWidth: 'unset', textTransform: 'capitalize', paddingLeft: '4px'};
const childStyle = {right: 0, maxHeight: 'unset'};

const Container = styled.div`
    display: flex;
    margin-left: 12px;
    align-items: center;
`;

const useAvailbilityMenuBehaviour = () => {
    const awayReasons = useTweekValue('support/feed/expert_availability/toggle/away_options', [
        'break',
        'meeting',
        'other',
        'logging out',
    ]);
    const [extend, setExtend] = React.useState(false);
    const toggleMenu = React.useCallback(() => setExtend(prev => !prev), []);
    const close = React.useCallback(() => setExtend(false), []);
    const awayOptions = React.useMemo(() => createMenu(awayReasons), [awayReasons]);

    return {
        extend,
        awayOptions,
        toggleMenu,
        close,
    };
};

const useAvailabilityAnalytics = (expertAvailabilityStatus?: ExpertAvailabilityStatus) => {
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('Header_Availability_Status'), []);

    useEffect(() => {
        if (expertAvailabilityStatus) {
            dispatcher
                .withExtras({
                    ExpertStatus:
                        expertAvailabilityStatus?.status === Status.AWAY
                            ? expertAvailabilityStatus?.statusReason
                            : expertAvailabilityStatus?.status,
                    Source: expertAvailabilityStatus?.source,
                })
                .dispatch('View');
        }
    }, [dispatcher, expertAvailabilityStatus]);

    return dispatcher;
};

const ExpertAvailability = observer(() => {
    const dispatcher = useAvailabilityAnalytics(ExpertAvailabilityStore.expertAvailabilityStatus);
    const {extend, toggleMenu, awayOptions, close} = useAvailbilityMenuBehaviour();
    const {expertAvailabilityStatus, changedAt} = ExpertAvailabilityStore;
    const [localStatus, setLocalStatus] = React.useState(expertAvailabilityStatus);

    const onChange = React.useCallback(
        (newStatus: ExpertAvailabilityStatus) => {
            dispatcher
                .withExtras({
                    ActionId: 'StatusSet',
                    ExpertStatusPrevious:
                        expertAvailabilityStatus?.status === Status.AWAY
                            ? expertAvailabilityStatus?.statusReason
                            : expertAvailabilityStatus?.status,
                    ExpertStatusTimestampPrevious: ExpertAvailabilityStore.changedAt,
                    ExpertStatus: newStatus.status === Status.AWAY ? newStatus.statusReason : newStatus.status,
                })
                .dispatch('Click');
            setLocalStatus(newStatus);
            ExpertAvailabilityStore.setExpertAvailability({...newStatus, source: 'expert_click'});
            close();
        },
        [ExpertAvailabilityStore.expertAvailabilityStatus, setLocalStatus]
    );

    React.useEffect(() => {
        setLocalStatus(expertAvailabilityStatus);
    }, [expertAvailabilityStatus]);

    const availableText =
        localStatus.status === Status.AVAILABLE ? 'available' : localStatus.statusReason ?? defaultUnavailable;

    const CustomTextComp = React.useCallback(
        () => (
            <Container data-test-id="ExpertAvailability_Menu">
                <AvailableIndicator
                    status={localStatus.status}
                    onClick={toggleMenu}
                    data-test-id={`ExpertAvailability_Indicator_${
                        localStatus.status === Status.AVAILABLE ? 'On' : 'Off'
                    }`}
                />
                <DropdownText expertAvailabilityStatus={localStatus} changedAt={changedAt} />
            </Container>
        ),
        [localStatus, changedAt, toggleMenu]
    );

    return (
        <CoreDropdown
            size="m"
            variant="text"
            childStyle={childStyle}
            extend={extend}
            onClickOut={close}
            Text={CustomTextComp}
            colorOverride={palette.neutral.white}
            style={menuStyle}
            onClick={toggleMenu}
            data-test-id="ExpertAvailability_Dropdown"
            data-test-selected-id={`ExpertAvailability_Selected_${availableText}`}
        >
            <AvailabilityMenu awayOptions={awayOptions} onChange={onChange} value={localStatus} />
        </CoreDropdown>
    );
});

export default ExpertAvailability;
