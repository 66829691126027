import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';
import EditSuggestionIcon from '../../../../resources/EditSuggestionIcon';

const Container = styled.div`
    display: flex;
    width: 21px;
    height: 21px;
    margin-top: 8px;
    justify-content: center;
`;

const ButtonContainer = styled(CoreIconButton)`
    display: flex;
    align-self: flex-end;
    padding: 0;
    width: 100%;
    height: 100%;
`;

interface Props {
    onClick: (e: React.SyntheticEvent) => void;
    isVisible: boolean;
}

const EditButton = ({onClick, isVisible}: Props) => {
    const theme = useTheme();
    const [color, setColor] = useState(theme.colors.neutral.grey.deep);
    const icon = useMemo(() => () => <EditSuggestionIcon color={color} />, [color]);

    return isVisible ? (
        <Container
            onMouseEnter={() => setColor(theme.colors.neutral.grey.deepest)}
            onMouseLeave={() => setColor(theme.colors.neutral.grey.deep)}
        >
            <ButtonContainer variant="none" icon={icon} onClick={onClick} />
        </Container>
    ) : null;
};
export default EditButton;
