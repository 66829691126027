import {SalesTagText} from '@soluto-private/smart-home-anywhere-expert';

import {CustomerEligibilityStatus, SalesType, Tag, TagSource} from './types';
import {Attribute} from '../../../../types';
import {salesTagMapping} from './salesTagMapping';

export const isSalesAttribute = (value: Attribute) =>
    value?.attributeType === 'enrichment' && value?.key === 'connected-home-sales-eligibility';

export const createNewSalesTag = ({fields}: Attribute) => {
    const status = (fields?.customerEligibilityStatus as unknown) as CustomerEligibilityStatus;
    return status ? salesTagMapping[status] : salesTagMapping[CustomerEligibilityStatus.Unknown];
};

export const getRelevantSalesTag = (tags: Tag[] = []) => {
    const nonSalesTags = tags.filter(({tagType}) => tagType !== SalesType.Sales);
    const salesTags = tags.filter(({tagType}) => tagType === SalesType.Sales);

    const oldSalesTag = salesTags.filter(
        ({tagKey}) => tagKey === SalesTagText.Offered || tagKey === SalesTagText.Enrolled
    );
    const newSalesTag = salesTags.filter(({source}) => source && source === TagSource.AsyncTaggerSales);

    const salesTag = oldSalesTag.length > 0 ? oldSalesTag : newSalesTag;
    return [...nonSalesTags, ...salesTag];
};
