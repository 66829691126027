import React, {useMemo, useCallback, useRef, useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {useTweekValue} from 'react-tweek';
import {observer} from 'mobx-react';
import {Flipper} from 'react-flip-toolkit';
import RecommendationItem from './RecommendationItem';
import RecommendationsStore from '../RecommendationsStore';
import {Recommendation} from '../types';
import RecommendationsViewControls from './RecommendationsViewControls';
import {useAnalytics} from 'react-shisell';
import areRecommendationsEqual from '../areRecommendationsEqual';
import {CARD_WIDTH, CARD_BUFFER} from '../dimensions';

const Container = styled.div<{hidden?: boolean}>`
    max-height: 144px;
    min-height: 96px;
    margin-bottom: 16px;
    width: 100%;
    position: relative;
    display: flex;
    ${({hidden}) =>
        hidden &&
        css`
            min-height: 10px;
            z-index: -1;
            pointer-events: none;
        `};
`;

const ContainerWrapper = styled.div`
    position: relative;
    margin-top: 24px;
    &.empty {
        display: none;
    }
    &.closed {
        height: 24px;
    }
`;

const AbsoluteWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: transform 350ms ease;
`;

const ScrolledContentContainer = styled.div`
    padding: 0 16px;
    display: flex;
`;

const Fader = styled.div`
    position: absolute;
    right: -64px;
    top: -8px;
    bottom: 0;
    width: 84px;
    background: linear-gradient(
        269.81deg,
        ${({theme}) => theme.colors.background.primary} 9.63%,
        rgba(240, 240, 245, 0) 57.11%
    );

    &.left {
        right: unset;
        left: -64px;
        transform: scaleX(-1);
    }
`;

function scroll(el: HTMLDivElement | null, page: number) {
    if (!el) return;

    el.style.transform = `translateX(-${(CARD_WIDTH + CARD_BUFFER) * page}px)`;
}

type Props = {
    store: RecommendationsStore;
    sessionId: string;
};
const RecommendationsView = observer(({store, sessionId}: Props) => {
    const analytics = useAnalytics();
    const recommendationsContainerRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [canRefresh, setCanRefresh] = useState(false);
    const [recommendationsWidth, setRecommendationsWidth] = useState<number>(0);
    const [lastItems, setLastItems] = useState<Recommendation[]>(store.recommendations);
    const isHidingEnabled = useTweekValue('support/session/omnibox/smart_recommendations/hiding/is_enabled', false);
    const isHidden = store.isHidden;

    const enhancedDispatcher = React.useMemo(
        () => analytics.dispatcher.createScoped('SmartSuggestions').withExtra('IsHidingEnabled', isHidingEnabled),
        [isHidingEnabled]
    );

    useEffect(() => {
        if (!!store.recommendations.length) {
            enhancedDispatcher.withExtras({NumberOfRecommendations: store.recommendations.length}).dispatch('View');
        }
    }, [enhancedDispatcher, store.recommendations]);

    useEffect(() => {
        setRecommendationsWidth(recommendationsContainerRef.current?.offsetWidth ?? 0);
    }, [recommendationsContainerRef.current?.offsetWidth]);

    useEffect(() => {
        if (!areRecommendationsEqual(store.recommendations, lastItems)) {
            if (page === 0 && !isHidden) {
                setLastItems(store.recommendations);
                setCanRefresh(false);
            } else {
                setCanRefresh(true);
            }
        }
    }, [store.recommendations, lastItems, canRefresh, page, isHidden]);

    const refresh = useCallback(() => {
        setLastItems(store.recommendations);
        setCanRefresh(false);
    }, [store.recommendations]);

    const onHiddenChangedClick = useCallback(() => {
        enhancedDispatcher
            .withExtras({
                ActionId: isHidden ? 'Show' : 'Hide',
                CanRefresh: canRefresh,
                NumberOfRecommendations: store.recommendations.length,
            })
            .dispatch('Click');
        store.setIsHidden(!isHidden);
        if (canRefresh) refresh();
    }, [isHidden, refresh, canRefresh, enhancedDispatcher, store.recommendations.length]);

    const recommendations = lastItems;
    const flipKey = useMemo(() => {
        return recommendations.map(item => `${item.id}-`).join();
    }, [recommendations]);

    const scrollForward = useCallback(() => {
        setPage(p => {
            scroll(scrollRef.current, p + 1);
            return p + 1;
        });
    }, []);
    const scrollBack = useCallback(() => {
        setPage(p => {
            scroll(scrollRef.current, p - 1);
            return p - 1;
        });
    }, []);
    useEffect(() => {
        setPage(0);
    }, [flipKey]);
    useEffect(() => {
        refresh();
    }, [sessionId]);

    return (
        <Flipper flipKey={flipKey}>
            <ContainerWrapper
                className={recommendations.length === 0 ? 'empty' : ''}
                data-test-id="RecommendationsView"
            >
                <Container ref={recommendationsContainerRef} hidden={isHidden}>
                    {!isHidden && (
                        <>
                            <AbsoluteWrapper ref={scrollRef}>
                                <ScrolledContentContainer>
                                    {recommendations.map((item, index) => (
                                        <RecommendationItem
                                            key={item.id}
                                            dispatcher={enhancedDispatcher}
                                            item={item}
                                            position={index + 1}
                                            store={store}
                                        />
                                    ))}
                                </ScrolledContentContainer>
                            </AbsoluteWrapper>
                            <Fader />
                            <Fader className="left" />
                        </>
                    )}
                </Container>
                <RecommendationsViewControls
                    incPage={scrollForward}
                    decPage={scrollBack}
                    recommendationsWidth={recommendationsWidth}
                    canRefresh={canRefresh}
                    isHidingEnabled={isHidingEnabled}
                    hidden={isHidden}
                    onHiddenChanged={onHiddenChangedClick}
                    refresh={refresh}
                    recommendations={recommendations}
                    page={page}
                />
            </ContainerWrapper>
        </Flipper>
    );
});

export default RecommendationsView;
