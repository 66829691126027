import React, {useEffect} from 'react';
import {useSuggestiveBehaviour} from '../behaviours';
import {useAnalytics} from 'react-shisell';
import {useTweekValue} from 'react-tweek';
import {useTheme} from '@anywhere-expert/colors';
import {SuggestiveBehaviorComponent} from './SuggestiveBehaviorComponent';
import {observer} from 'mobx-react';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';

const CallSupport = React.lazy(() => import('./resources/CallSupport'));
const CuttingOff = React.lazy(() => import('./resources/CuttingOff'));
const FCC = React.lazy(() => import('./resources/FCC'));
const LongResponses = React.lazy(() => import('./resources/LongResponses'));
const ReferToStore = React.lazy(() => import('./resources/ReferToStore'));
const SameExpertReferToCallCenter = React.lazy(() => import('./resources/SameExpertReferToCallCenter'));

type OuterProps = {
    sendComplete: () => void;
    setIsSendDisabled: (isDisabled: boolean) => void;
    inputText: string;
    timelineId?: string;
};

const SuggestiveBehaviour: React.FunctionComponent<OuterProps> = observer(
    ({sendComplete, setIsSendDisabled, timelineId, inputText}) => {
        const {dismissButtonText, proceedButtonText, proceedButtonIsVisible} = useTweekValue<any>(
            'support/session/during/suggestive_behaviour/_',
            {
                dismissButtonText: undefined,
                proceedButtonText: undefined,
                proceedButtonIsVisible: undefined,
            }
        );

        const [suggestiveBehaviour, cleanSuggestiveBehaviour, dismissSuggestiveBehaviour] = useSuggestiveBehaviour(
            timelineId,
            inputText
        );

        useEffect(() => {
            setIsSendDisabled(!!suggestiveBehaviour && !suggestiveBehaviour.isDismissed);
        }, [suggestiveBehaviour]);

        const theme = useTheme();
        const analytics = useAnalytics();

        if (!suggestiveBehaviour || suggestiveBehaviour.isDismissed) {
            return null;
        }

        const dispatcher = analytics.dispatcher
            .withExtras({Behaviour: suggestiveBehaviour.name, SuggestionTrigger: suggestiveBehaviour.matchingRegex})
            .createScoped('SuggestiveBehaviour');

        dispatcher.dispatch('View');

        let visual: React.ReactNode | undefined;

        switch (suggestiveBehaviour.name) {
            case 'FCC':
                visual = <FCC color={theme.colors.neutral.black} />;
                break;
            case 'CallSupport':
                visual = <CallSupport color={theme.colors.neutral.black} />;
                break;
            case 'CuttingOff':
                visual = <CuttingOff color={theme.colors.neutral.black} />;
                break;
            case 'LongResponses':
                visual = <LongResponses color={theme.colors.neutral.black} />;
                break;
            case 'ReferToStore':
                visual = <ReferToStore color={theme.colors.neutral.black} />;
                break;
            case 'SameExpertReferToCallCenter':
                visual = <SameExpertReferToCallCenter color={theme.colors.neutral.black} />;
                break;
            default:
                visual = undefined;
        }

        const sharedProps = {
            title: suggestiveBehaviour.behaviour.title,
            message: suggestiveBehaviour.behaviour.messageText,
            visual: visual,
            suggestiveBehaviourName: suggestiveBehaviour.name,
            timelineId: timelineId,
            shouldShowBanner: suggestiveBehaviour.behaviour.shouldShowBanner,
            bannerText: suggestiveBehaviour.behaviour.bannerText,
        };

        const otherProps = {
            dismissButtonText: dismissButtonText,
            proceedButtonText: proceedButtonText,
            onDismissButtonClick: () => {
                dispatcher.withExtra('ActionId', 'EditMessage').dispatch('Click');

                dismissSuggestiveBehaviour();
                TimelineInputStore.richInput.focus();
            },
            onProceedButtonClick: () => {
                dispatcher.withExtra('ActionId', 'SendAnyway').dispatch('Click');

                cleanSuggestiveBehaviour();
                sendComplete();
            },
            proceedButtonIsVisible: proceedButtonIsVisible,
        };

        return <SuggestiveBehaviorComponent {...sharedProps} {...otherProps} />;
    }
);

export default SuggestiveBehaviour;
