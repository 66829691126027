import React from 'react';

const CloseCategoryIcon = ({color, ...others}) => (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.41 3.43323e-05L6 5.00012L10.59 3.43323e-05L12 1.41003L6 7.41003L0 1.41003L1.41 3.43323e-05Z"
            fill={color}
        />
    </svg>
);

export default CloseCategoryIcon;
