import React from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import styled from 'styled-components';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {CoreText} from '@anywhere-expert/base-ui';
import {useCurrentOffer, useOfferStatusContent} from '../../../../hooks';
import {OfferType, MessagingSalesTweekKeys} from '../../../../types';
import {getStepNumber} from '../enrollment-flow/horizon-enrollment/StepLabel';
import {Line} from '../../../styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 0 15px;
`;

const Blub = styled.img`
    height: 45px;
    padding-right: 10px;
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};
`;

const OfferStatus = observer(({selectedSession}: SalesSessionProps) => {
    const isAutomaticOfferEnabled = useTweekValue(MessagingSalesTweekKeys.IsAutomaticOfferEnabled, false);
    const offerStatusContent = useOfferStatusContent();
    const currentOffer = useCurrentOffer(selectedSession.sessionId);
    const offerType = currentOffer?.offer.offerType;
    const stepNumber = getStepNumber(currentOffer?.offer);

    if (!isAutomaticOfferEnabled || !offerType || stepNumber === 4) {
        return null;
    }

    return (
        <>
            <Container data-test-id={`MessagingSales_OfferStatus_${offerType}`}>
                <Blub src={offerStatusContent[offerType].src} />
                <CoreText weight="bold" size="m">
                    {offerStatusContent[offerType].text}
                </CoreText>
            </Container>
            <Line />
        </>
    );
});

export default OfferStatus;
