import {useCallback} from 'react';
import moment from 'moment';
import * as lscache from 'lscache';
import {useTweekValue} from 'react-tweek';

type DosAndDontsOption = {
    title: string;
    text: string;
};

export type DosAndDontsOptionState = {
    option: DosAndDontsOption;
    wasPressed: boolean;
};

const StorageKey = 'DosAndDontsStore';
const ExpiryDurationInMin = 60 * 18;

const getCurrentMessage = (): DosAndDontsOptionState => {
    lscache.setBucket(StorageKey);
    return lscache.get('current');
};

const setCurrentMessage = (message: DosAndDontsOptionState): void => {
    lscache.setBucket(StorageKey);
    lscache.set('current', message, ExpiryDurationInMin);
    lscache.flushExpired();
};

export default function useDosAndDonts() {
    const messages = useTweekValue<DosAndDontsOption[]>('support/best_practices/messages', []);

    const handleMessagePressed = useCallback((message: DosAndDontsOptionState) => {
        setCurrentMessage({option: message.option, wasPressed: true});
    }, []);

    let message = getCurrentMessage();

    if (!message) {
        const option = messages[moment().dayOfYear() % messages.length];

        if (!option) {
            return {message: undefined, handleMessagePressed: () => {}};
        }

        message = {option, wasPressed: false};
        setCurrentMessage(message);
    }

    return {message, handleMessagePressed};
}
