import {CoreIcon, CoreText} from '@anywhere-expert/base-ui';
import React from 'react';
import styled from 'styled-components';
import {getBorderStyle, getBoxShadowStyle} from '@anywhere-expert/base-ui';
import {WaitingForPush} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';

const AvailableWaitingForPushContainer = styled.div`
    position: absolute;
    bottom: 0px;
    max-width: inherit;
    right: 0;
    left: 0;
    height: fit-content;
    min-height: 73px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex: 1;
    border-radius: 12px;
    margin: 8px 9px 8px 9px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    ${({theme}) => getBorderStyle(theme)};
    ${({theme}) => getBoxShadowStyle(theme)};
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    margin: 16px 16px 16px 0px;
`;

const StyledText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.black};
    line-height: 20px !important;
    margin-bottom: 4px;
`;

const StyledSubText = styled(CoreText)`
    color: ${({theme}) => theme.colors.contrast.secondary};
`;

const WaitingForPushContainer = styled(CoreIcon)`
    margin: 16px 12px 16px 16px;
`;

const AvailableWaitingForPushComponent = () => {
    const theme = useTheme();

    return (
        <AvailableWaitingForPushContainer data-test-id={`AutoAssignMessage_low_volume`}>
            <WaitingForPushContainer icon={WaitingForPush} color={theme.colors.neutral.black} />
            <ContentContainer>
                <StyledText size="l" weight="bold">
                    {'Great! New sessions coming up'}
                </StyledText>
                <StyledSubText size="m">{'We’ll add them to the queue automatically'}</StyledSubText>
            </ContentContainer>
        </AvailableWaitingForPushContainer>
    );
};

export default AvailableWaitingForPushComponent;
