import React from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import {useEnrollmentFlow, useCurrentOffer} from '../../../../hooks';
import {ExternalEnrollmentProgress, MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {Line} from '../../../styled-components';
import styled from 'styled-components';
import AuthorizeCustomer from './verizon-enrollment/AuthorizeCustomer';
import SendDisclosure from './verizon-enrollment/SendDisclosure';
import ConfirmEnrollment from './verizon-enrollment/ConfirmEnrollment';
import HorizonEnrollmentStep from './horizon-enrollment/HorizonEnrollmentStep';

const EnrollmentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const EnrollmentStep = observer(({selectedSession}: SalesSessionProps) => {
    const {sessionId} = selectedSession;
    const currentOffer = useCurrentOffer(sessionId);

    switch (currentOffer?.offer.enrollmentProgress) {
        case undefined:
            return <AuthorizeCustomer sessionId={sessionId} />;
        case ExternalEnrollmentProgress.Disclosure:
            return <SendDisclosure selectedSession={selectedSession} />;
        case ExternalEnrollmentProgress.EnrollmentConfirmation:
            return <ConfirmEnrollment selectedSession={selectedSession} />;
        default:
            return null;
    }
});

const EnrollmentFlow = observer(({selectedSession}: SalesSessionProps) => {
    const {sessionId} = selectedSession;
    const isEnrollmentFlowEnabled = useEnrollmentFlow(sessionId);
    const isHorizonEnrollmentEnabled = useTweekValue(MessagingSalesTweekKeys.IsHorizonEnrollmentEnabled, false);

    if (!isEnrollmentFlowEnabled) {
        return null;
    }

    return (
        <>
            <EnrollmentContainer data-test-id="MessagingSales_EnrollmentFlow">
                {isHorizonEnrollmentEnabled ? (
                    <HorizonEnrollmentStep selectedSession={selectedSession} />
                ) : (
                    <EnrollmentStep selectedSession={selectedSession} />
                )}
            </EnrollmentContainer>
            <Line />
        </>
    );
});

export default EnrollmentFlow;
