import React from 'react';
import {observer} from 'mobx-react';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys} from '../../../../types';

import OfferStatus from './OfferStatus';
import OfferSelections from './OfferSelections';

const OfferControls = observer(({selectedSession}: SalesSessionProps) => {
    const isAutomaticOfferEnabled = useTweekValue(MessagingSalesTweekKeys.IsAutomaticOfferEnabled, false);

    if (isAutomaticOfferEnabled) {
        return <OfferStatus selectedSession={selectedSession} />;
    } else {
        return <OfferSelections selectedSession={selectedSession} />;
    }
});

export default OfferControls;
