import {Message, MessagePayload} from '@soluto-private/messaging-api-client';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import {EnhancedMessage, MessageStatus} from './types';
import logger from '@anywhere-expert/logging';
import messageTypeToTimelineItemType from './messageTypeToTimelineItemType';

export interface SystemMessageIcon {
    uri: string;
    width?: number;
    height?: number;
}

export interface SystemMessagePayloadValue {
    message: {
        text: string;
        hideDefaultIcon?: boolean;
        icon?: SystemMessageIcon;
    };
    subMessage?: {
        text: string;
        icon?: SystemMessageIcon;
    };
}

export interface SystemMessagePayload extends MessagePayload {
    type: 'system_message';
    value: SystemMessagePayloadValue;
}

const getContent = (payload: any): any => {
    switch (payload.type) {
        case 'text_message':
            return payload.value.text || payload.value;
        case 'image_item':
        case 'attachment':
            return payload.value.text;
        case 'system_message':
            return getSystemMessageTimelineItemContent(payload.value as SystemMessagePayloadValue);
        case 'legacy_item':
            return payload.value.content;
        //dynamic_component is deprecated and here by mistake
        case 'dynamic_component':
            const {id, url, extraTimelineItemProps, ...content} = payload.value;
            return content;
        default:
            return payload.value;
    }
};

const getSpreadFields = (message: Message): any => {
    switch (message.payload.type) {
        case 'text_message':
            return {};
        case 'image_item':
        case 'attachment':
            const {text, ...fields} = message.payload.value;
            return fields;
        case 'legacy_item':
            return message.payload.value.extraTimelineItemProps;
        //dynamic_component is deprecated and here by mistake
        case 'dynamic_component':
            return {
                id: message.payload.value.id,
                url: message.payload.value.url,
                ...message.payload.value.extraTimelineItemProps,
            };
        default:
            return {};
    }
};

const getContentType = (message: Message): string => {
    switch (message.payload.type) {
        case 'legacy_item':
            return message.payload.value.subtype;
        default:
            return messageTypeToTimelineItemType(message.payload.type);
    }
};

const getItemType = (message: Message): string => {
    const defaultType = 'ChatMessage';

    switch (message.payload.type) {
        case 'legacy_item':
            return message?.payload?.value?.extraTimelineItemProps?.type ?? defaultType;
        default:
            return defaultType;
    }
};

export const transformMessageToTimelineItem = (message: EnhancedMessage): TimelineItem<any> | null => {
    try {
        return {
            ...getSpreadFields(message),
            contentType: getContentType(message),
            content: getContent(message.payload),
            contentId: message.messageId,
            senderType: message.metadata.senderType,
            type: getItemType(message),
            sendTimestamp: message.sentAt,
            timestamp: message.createdAt,
            senderId: message.senderId,
            seenAt: message.seenAt,
            senderName: message.metadata.senderName ?? '',
            senderThumbUrl: message.metadata.senderThumbUrl ?? '',
            platform: message.metadata.source,
            lastState: message.lastState,
            metadata: message.metadata,
            isFailedSending: message.status === MessageStatus.Failed,
            isCurrentlyInFlight: message.status === MessageStatus.Pending,
        };
    } catch (err) {
        logger.error('could not transform message to timelineItem', {err, extra: {message}});
        return null;
    }
};

const getSystemMessageTimelineItemContent = ({message, subMessage}: SystemMessagePayloadValue) => ({
    message: message.text,
    ...(message.icon
        ? {
              iconUri: message.icon.uri,
              iconProps: {
                  width: message.icon.width,
                  height: message.icon.height,
              },
          }
        : {
              iconUri: message.hideDefaultIcon ? 'false' : undefined,
          }),
    ...(subMessage && {
        subMessage: subMessage.text,
        ...(subMessage.icon && {
            subIconUri: subMessage.icon.uri,
            subIconProps: {
                width: subMessage.icon.width,
                height: subMessage.icon.height,
            },
        }),
    }),
});
