import {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import 'moment-duration-format';
import {useInterval} from '@anywhere-expert/utils';

function customTemplate() {
    return this.duration.asMinutes() < 59 ? 'm[min]' : 'd[d] h[h] m[m]';
}

const useUnavailabilityDuration = (isEnabled: boolean, changedAt?: Date) => {
    const [duration, setDuration] = useState<string>('');

    const calculateDuration = useCallback(
        (date: Date) =>
            setDuration(
                (moment.duration(Date.now() - date.getTime(), 'milliseconds') as any).format(customTemplate, {
                    trim: 'large',
                    minValue: 1,
                    usePlural: false,
                })
            ),
        []
    );

    useEffect(() => {
        if (changedAt) calculateDuration(changedAt);
    }, [changedAt]);

    useInterval(() => {
        if (isEnabled && changedAt) calculateDuration(changedAt);
    }, 30 * 1000);

    return isEnabled && duration;
};

export default useUnavailabilityDuration;
