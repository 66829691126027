import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {useEffect, useMemo} from 'react';
import {useGetNextAssigned} from '../useCases';
import {setPrePopulatedData} from '../..';
import {useAnalytics} from 'react-shisell';
import {when} from 'mobx';
import TimelineModel from '@anywhere-expert/timeline-state/TimelineModel';

export type Options = {
    selectedTimeline: TimelineModel;
};

export interface PrefilledTextUseCase {
    name: string;
    getInputText: (options?: Options) => string;
    getShouldSetInputText: (options: Options) => boolean;
    getIsAutoSend?: () => boolean;
    onFill?: (timelineModel: TimelineModel) => void;
}

/*
 In order to add a use case that adds text to the expert's text-area:
 1. Create a file under the "useCases" folder which implements the PrefilledTextUseCase interface.
 2. Add the obj to the "flows" var below
 3. Add test file
*/

const setDefaultInputText = (inputText: string, timelineId: string) => {
    TimelineInputStore.setEditorText(timelineId, inputText);
};

export const useDefaultInputTextBehavior = (timelineId: string, selectedTimeline?: TimelineModel) => {
    const analytics = useAnalytics();
    const getNextAssigned = useGetNextAssigned();

    const flows: PrefilledTextUseCase[] = useMemo(() => [getNextAssigned], [getNextAssigned]);

    useEffect(() => {
        const disposer = when(
            () => timelineId === selectedTimeline?.timelineId && selectedTimeline?.isInitialLoaded,
            () => {
                const options: Options = {selectedTimeline: selectedTimeline!};
                const flowToApply = flows.find(flow => flow.getShouldSetInputText(options));

                if (!flowToApply) return;

                const {getInputText, name, getIsAutoSend} = flowToApply;
                const isAutoSend = getIsAutoSend?.();
                const text = getInputText(options);

                //If a message was already auto-sent, isAutoSend guarantees that no message will be pre-populated
                if (!name || !text || isAutoSend) {
                    return;
                }

                //this is used for analytics when the expert is sending a message
                setPrePopulatedData(timelineId, text, name);

                setDefaultInputText(text, timelineId);

                flowToApply.onFill?.(selectedTimeline!);

                analytics.dispatcher
                    .createScoped('TextAreaPrepopulated')
                    .withExtras({
                        Reason: name,
                    })
                    .dispatch('View');
            }
        );
        return () => {
            disposer();
        };
    }, [timelineId]);
};
