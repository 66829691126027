import React from 'react';
import {compose} from 'recompose';
import {observer} from 'mobx-react';
import {useImageUpload, withAttachmentUpload} from '@anywhere-expert/timeline-behaviours';
import MediaSelector from './MediaSelector';
import {userProfile$} from '@anywhere-expert/auth';
import {withPropFromObservable} from '@anywhere-expert/utils';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useAnalytics} from 'react-shisell';

type Props = {
    timelineId: string;
    disabled: boolean;
    sessionId: string;
};

type InjectedProps = {
    uploadAttachmentToTimeline: (timelineId: string, selectedFile: File, sessionId: string) => Promise<any>;
};

const TimelineMediaInput = observer(
    ({sessionId, disabled, timelineId, uploadAttachmentToTimeline}: Props & InjectedProps) => {
        const uploadImageToTimeline = useImageUpload();
        const analytics = useAnalytics();

        const getUploadFunc = React.useCallback(
            (
                selectedFileType: string
            ): ((timelineId: string, selectedFile: File, sessionId?: string) => Promise<any>) => {
                if (selectedFileType.includes('image')) {
                    return uploadImageToTimeline;
                }
                return uploadAttachmentToTimeline;
            },
            [uploadAttachmentToTimeline, uploadImageToTimeline]
        );

        const onMediaSelectorChanged = React.useCallback(
            async (selectedFile: File) => {
                analytics.dispatcher
                    .createScoped('MediaUpload')
                    .withIdentity('SessionId', sessionId)
                    .withIdentity('CustomerId', timelineId)
                    .dispatch('Click');

                if (!selectedFile) return;

                await getUploadFunc(selectedFile.type)(timelineId, selectedFile, sessionId);
            },
            [timelineId, sessionId, getUploadFunc]
        );

        return (
            <MediaSelector
                onSelect={onMediaSelectorChanged}
                disabled={disabled}
                selectedSession={ExpertFeedStore.selectedSupportSession}
            />
        );
    }
);

const enhance = compose<Props & InjectedProps, Props>(
    withPropFromObservable({
        fromObservable$: userProfile$,
        toProp: 'user',
    }),
    withAttachmentUpload
);

export default enhance(TimelineMediaInput);
