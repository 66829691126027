import React from 'react';
import {ExpertAvailabilityStatus, Status, defaultUnavailable} from '../types';
import useUnavailabilityDuration from './useUnavailabilityDuration';
import {CoreText} from '@anywhere-expert/base-ui';

type Props = {
    expertAvailabilityStatus: ExpertAvailabilityStatus;
    changedAt?: Date;
};
const DropdownText = ({changedAt, expertAvailabilityStatus}: Props) => {
    const duration = useUnavailabilityDuration(expertAvailabilityStatus?.status !== Status.AVAILABLE, changedAt);

    const availableText =
        expertAvailabilityStatus.status === Status.AVAILABLE
            ? 'available'
            : expertAvailabilityStatus.statusReason ?? defaultUnavailable;

    return (
        <>
            <CoreText size="m" textType="white">
                {availableText}
            </CoreText>
            <CoreText size="s" weight="bold" textType="white" style={{marginLeft: 4, marginTop: 1}}>
                {duration}
            </CoreText>
        </>
    );
};

export default DropdownText;
