import {TimelineModel} from '@anywhere-expert/timeline-state';
import {getFromTweekForSupportItemWithDefault} from '@anywhere-expert/tweek';
import CobrowseAPI from 'cobrowse-agent-sdk';
import {ICobrowseAPI} from './cobrowse-agent-sdk_types';
import {ScreenshareTimelineItemState} from './sharedScreenshareDefinitions';
import {getJwtForCobrowse} from './ScreenshareSessionManager';
import logger from '@anywhere-expert/logging';
import {ScreenShareTimelineItemUpdater} from './TimelineItemUpdater/ScreenShareTimelineItemUpdater';
import {featureName} from './definitions';

export async function patchTimelineItemStateIfInconsistenciesAreFound(
    timelineModel: TimelineModel,
    timelineItemState: ScreenshareTimelineItemState,
    cobrowseSessionId: string,
    messageId: string
): Promise<{wereInconsistenciesFound: boolean} | 'error'> {
    try {
        const killCobrowseSessionJustInCase = async () => {
            const cobrowseUrl = await getFromTweekForSupportItemWithDefault<string | undefined>(
                'support/session/screen_share/cobrowse_url',
                timelineModel.timelineId,
                timelineModel.supportItem.sessionId,
                timelineModel.supportItem.pool,
                undefined,
                timelineModel.supportItem.sessionAttributes
            );

            if (cobrowseUrl === undefined) return;

            const cobrowseApi: ICobrowseAPI = new CobrowseAPI(undefined, {api: cobrowseUrl});

            const getJwtResult = await getJwtForCobrowse();

            if (getJwtResult.failedToGetJwt) return;

            cobrowseApi.token = getJwtResult.jwt;

            let cobrowseSession;

            try {
                cobrowseSession = await cobrowseApi.sessions.get(cobrowseSessionId);
            } catch (e) {
                console.log('failed to kill cobrowse session', e);

                return;
            }

            await cobrowseSession.end();
        };

        const screenShareTimelineItemUpdater = new ScreenShareTimelineItemUpdater(timelineModel, messageId);

        if (timelineModel.screenshareSessionManager) return {wereInconsistenciesFound: false}; // Don't patch anything if there's a screenshare session already, to prevent race conditions.

        if (timelineItemState === 'pending') {
            await screenShareTimelineItemUpdater.expire(undefined);
            await killCobrowseSessionJustInCase();

            logger.info(`Patched screenshare timeline item state to 'expired'. (messageId: ${messageId})`, {
                extra: {featureName},
            });

            return {wereInconsistenciesFound: true};
        } else if (timelineItemState === 'ongoing') {
            await screenShareTimelineItemUpdater.updateScreenshareTimelineItemState('ended');
            await killCobrowseSessionJustInCase();
            logger.info(`Patched screenshare timeline item state to 'ended'. (messageId: ${messageId})`, {
                extra: {featureName},
            });

            return {wereInconsistenciesFound: true};
        }

        return {wereInconsistenciesFound: false};
    } catch (err) {
        logger.error("An error was thrown when trying to patch the timeline item's state", {err, extra: {featureName}});
        return 'error';
    }
}
