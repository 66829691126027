import React from 'react';
import ExtraData, {ExtraDataProps} from './ExtraData';
import {useAnalytics} from 'react-shisell';
import {CoreIconButton, CoreTooltip} from '@anywhere-expert/base-ui';
import {BookmarkFilledIcon, DeleteFilledIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import {StyleSheet, css} from 'aphrodite';
import {openModal} from '@anywhere-expert/base-ui';
import DeleteMessageConfirmationModal from './DeleteMessageConfirmationModal';
import {AddCannedMessagesModal} from '@anywhere-expert/canned-messages-v2';
const iconProps = {
    size: 's',
};

const styles = StyleSheet.create({
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    },
    opacityTransition: {
        transition: 'opacity 0.2s ease',
    },
});

export const fadeClassNames = {
    enter: css(styles.hidden, styles.opacityTransition),
    enterActive: css(styles.visible, styles.opacityTransition),
    exit: css(styles.visible, styles.opacityTransition),
    exitActive: css(styles.hidden, styles.opacityTransition),
};

const Container = styled.div<{isHover: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 64px;
    margin-right: 4px;
`;

const StyledExtraData = styled(ExtraData)`
    position: absolute;
    bottom: 0;
    right: 0;
    transition: opacity 0.2s linear;
`;

export type TechTemplateActionsProps = ExtraDataProps & {
    deleteEnabled: boolean;
    canAddCannedMessageFromTimeline?: boolean;
    deleteButtonVisible: boolean;
    onDelete: () => void;
    content: any;
    contentType: string;
    deleteExtraData: {};
    isHover: boolean;
    sessionId: string;
};

const TechTemplateActions = ({
    deleteEnabled,
    canAddCannedMessageFromTimeline,
    deleteButtonVisible,
    onDelete,
    deleteExtraData,
    isHover,
    content,
    isCurrentlyInFlight,
    sessionId,
}: TechTemplateActionsProps) => {
    const analytics = useAnalytics();
    const actionsAvailable = !isCurrentlyInFlight && (deleteEnabled || canAddCannedMessageFromTimeline);
    const onDeleteClicked = React.useCallback(() => {
        const dispatcher = analytics.dispatcher.createScoped('Delete').withExtras(deleteExtraData);
        dispatcher.dispatch('Click');
        openModal(DeleteMessageConfirmationModal, {size: 's', contentProps: {onDelete, dispatcher}});
    }, [deleteExtraData, onDelete]);

    const onAddToCannedMessageClicked = React.useCallback(() => {
        analytics.dispatcher.withExtra('ActionId', 'AddCannedMessageFromTimeline').dispatch('Click');

        openModal(AddCannedMessagesModal, {
            id: 'add-message-modal',
            contentProps: {
                text: content,
            },
        });
    }, [content, analytics]);

    return (
        <Container isHover={isHover}>
            {actionsAvailable && (
                <CSSTransition
                    in={actionsAvailable && isHover}
                    classNames={fadeClassNames}
                    timeout={200}
                    mountOnEnter
                    unmountOnExit
                >
                    <div>
                        {deleteButtonVisible && deleteEnabled && (
                            <CoreIconButton
                                onClick={onDeleteClicked}
                                variant="icon"
                                size="s"
                                iconProps={iconProps}
                                icon={DeleteFilledIcon}
                            />
                        )}
                        {canAddCannedMessageFromTimeline && (
                            <CoreTooltip text="Add to Canned messages" position="bottom">
                                <CoreIconButton
                                    icon={BookmarkFilledIcon}
                                    data-test-id="AddCannedMessageAction"
                                    variant="icon"
                                    size="s"
                                    iconProps={iconProps}
                                    onClick={onAddToCannedMessageClicked}
                                />
                            </CoreTooltip>
                        )}
                    </div>
                </CSSTransition>
            )}

            <StyledExtraData sessionId={sessionId} isCurrentlyInFlight={isCurrentlyInFlight} />
        </Container>
    );
};

export default TechTemplateActions;
