import logger from '@anywhere-expert/logging';
import {initMetricsReporter} from '@soluto-private/react-metrics-reporter';
import {configuration as config} from '@anywhere-expert/core';

enum MonitorType {
    increment = 'increment',
    gauge = 'updatevalue',
    addTime = 'addtime',
}

/**
 * the monitor object used throughout the app
 * @module monitor
 * @memberof core
 * @example
 *
 * import {monitor} from '@anywhere-expert/monitor';
 *
 * monitor.increment("AddAnExpert_Errors")
 */
class MonitorProvider {
    clientName: string;
    url: string;
    initializeTimestamp: number;

    constructor() {
        this.url = config.monitorUrl + '/api/v1/metrics';
        this.increment = this.increment.bind(this);
        this.gauge = this.gauge.bind(this);
    }

    _validateInitialization = () => {
        if (!this.clientName) {
            throw new Error("monitor client hasn't been initialized");
        }
    };

    _send(type: MonitorType, metricName: String, queryParams?: String) {
        const url = `${this.url}/${type}/${this.clientName}/${metricName}${queryParams ? `?${queryParams}` : ''}`;

        fetch(url, {
            method: 'POST',
        }).catch(err => {
            logger.warn('Error sending monitor value', {err});
        });
    }
    /**
     * initialize the monitor with the provided client name
     * @memberof core.monitor
     * @param {string} clientName the name of the client (web/mobile)
     */
    initialize(clientName) {
        this.clientName = `AnywhereExpert_${clientName}`;
        this.initializeTimestamp = Date.now();

        initMetricsReporter({
            onMeasure: this.gauge,
            onOccurrence: this.increment,
        });
    }

    increment(name) {
        this._validateInitialization();

        logger.debug('Monitor sent', {extra: {type: MonitorType.increment, name}});
        this._send(MonitorType.increment, name);
    }

    addTime(name, value) {
        this._validateInitialization();

        logger.debug('Monitor sent', {extra: {type: MonitorType.addTime, name, value}});
        this._send(MonitorType.addTime, name, `value=${value}`);
    }

    gauge(name, value) {
        this._validateInitialization();

        logger.debug('Monitor sent', {extra: {type: MonitorType.gauge, name, value}});
        this._send(MonitorType.gauge, name, `value=${value}`);
    }
}

export default new MonitorProvider();
