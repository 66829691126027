import {observer} from 'mobx-react';
import React from 'react';
import Attribute from './Attribute';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import {PersonalDetails} from '../../types';

export default observer(({personalDetails}: {personalDetails: PersonalDetails}) => {
    return (
        <LoggingErrorBoundary boundaryName="PersonalCustomerAttributes">
            {personalDetails.name && <Attribute name="Name" value={personalDetails.name} defaultValue="Unknown" />}
            {personalDetails.email && <Attribute name="Email" value={personalDetails.email} defaultValue="Unknown" />}
        </LoggingErrorBoundary>
    );
});
