import * as React from 'react';
import {CoreToast, CoreIconProps} from '@anywhere-expert/base-ui';
import {useObservable} from 'rxjs-hooks';
import {
    _appMessage$,
    resetAppMessages,
    AppMessageData,
    _isShowingAppMessage$,
} from '../../shared/appMessageObservables';
import SuccessIcon from '../resources/SuccessIcon';
import WarningIcon from '../resources/WarningIcon';
import {FollowUpFilledIcon, WheelIcon} from '@anywhere-expert/icons';
import {SHOW_TIME, SHOW_TIME_WITH_ACTION, actionTypeToText, AppMessageType} from '../../shared/consts';

let timer;

const typeToIcon: Record<AppMessageType, CoreIconProps['icon']> = {
    success: SuccessIcon,
    warning: WarningIcon,
    autopilot: WheelIcon,
    followup: FollowUpFilledIcon,
};

const useCleanOnMount = () => React.useEffect(resetAppMessages, []);

const useAppMessageBehaviour = (msg: AppMessageData | null, duration: number) => {
    const [show, setShow] = React.useState(false);

    const onClose = React.useCallback(
        wasActionClicked => {
            msg && msg.onAppMessageClosed && msg.onAppMessageClosed(wasActionClicked, msg.actionData);
            setShow(false);
            _isShowingAppMessage$.next(false);
        },
        [msg]
    );

    const resetTimer = React.useCallback(() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setShow(false);
            msg?.onAppMessageClosed?.(false, msg.actionData);
        }, duration);
    }, [msg, duration]);

    const cancelTimer = React.useCallback(() => {
        clearTimeout(timer);
    }, [msg, duration]);

    React.useEffect(() => {
        if (msg && (msg.text || msg.MessageContent)) {
            resetTimer();
            setShow(true);
            _isShowingAppMessage$.next(true);
        }
    }, [msg]);

    return {
        resetTimer,
        cancelTimer,
        onClose,
        show,
    };
};

const AppMessage = () => {
    useCleanOnMount();
    const msg = useObservable(() => _appMessage$);
    const duration = msg?.duration ?? (msg?.actionable ? SHOW_TIME_WITH_ACTION : SHOW_TIME);
    const {show, cancelTimer, resetTimer, onClose} = useAppMessageBehaviour(msg, duration);
    const actionButtonText =
        (msg && msg.actionable && msg.actionButtonType && actionTypeToText[msg.actionButtonType]) || undefined;

    return (
        <CoreToast
            testId="AppMessage"
            text={msg && msg.text}
            backgroundColor={msg?.backgroundColor}
            textColor={msg?.textColor}
            textSecondLine={msg?.textSecondLine}
            textThirdLine={msg?.textThirdLine}
            onMouseEnter={cancelTimer}
            onMouseLeave={resetTimer}
            actionButtonText={actionButtonText}
            show={show}
            onClose={onClose}
            Icon={(msg && msg.type && typeToIcon[msg.type]) || undefined}
            MessageContent={msg?.MessageContent}
            xPosition={msg?.xPosition}
        />
    );
};

export default AppMessage;
