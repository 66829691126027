import React from 'react';
import styled from 'styled-components';
import {Search} from './components';
import {observer} from 'mobx-react';
import {SidebarTabContainer, ChildrenContainer} from '@anywhere-expert/session-sidebar';
import useCannedMessagesBehaviors from './useCannedMessagesBehaviors';
import CannedMessages from './CannedMessages';
import {TabProps} from '@anywhere-expert/session-sidebar';
import {isDefaultGreetingEnabled} from './stores/defaultGreetingMessage';

const StyledChildrenContainer = styled(ChildrenContainer)`
    position: relative;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const CannedMessagesTab = observer(({selectedSession}: TabProps) => {
    const {onSelectedMessage, analytics} = useCannedMessagesBehaviors(selectedSession);

    React.useEffect(() => {
        if (isDefaultGreetingEnabled()) {
            analytics.dispatcher.createScoped('DefaultGreeting').dispatch('Visibility');
        }
    }, []);

    const SearchBar = <Search />;
    const onClickedOutOfTab = React.useCallback(() => selectedSession.cannedMessagesNavigationStore.removeFocus(), [
        selectedSession,
        selectedSession.cannedMessagesNavigationStore,
    ]);

    return (
        <SidebarTabContainer
            data-test-id="CannedMessages_Tab_V2"
            title="Canned Messages"
            subTitle={SearchBar}
            onClickedOut={onClickedOutOfTab}
            ChildrenContainer={StyledChildrenContainer}
        >
            <CannedMessages onMessageSelection={onSelectedMessage} />
        </SidebarTabContainer>
    );
});

export default CannedMessagesTab;
