// Type definitions for cobrowse-agent-sdk@0.0.15

import {EventEmitter} from 'events';

export const COBROWSE_SESSION_STATES = ['pending', 'authorizing', 'active', 'ended'] as const;
export type CobrowseSessionState = typeof COBROWSE_SESSION_STATES[number];
export const COBROWSE_TOOLS = ['laser', 'drawing', 'control'] as const;
export type CobrowseToolName = typeof COBROWSE_TOOLS[number];

export interface CobrowseSession extends EventEmitter {
    state: CobrowseSessionState;
    code: string;
    id: string;
    full_device: boolean;
    remote_control: 'on' | 'off' | 'requested' | 'rejected';
    created: Date;
    activated: Date;
    updated: Date;
    ended: Date;
    recorded: boolean;
    custom_data: Record<string, any>;
    agent: Object;
    device: Object;
    subscribe(): Promise<void>;
    unsubscribe(): void;
    end(): Promise<void>;
    toJSON(): Object;
    setFullDevice(state: boolean): Promise<void>;
    isActive(): boolean;
    isPending(): boolean;
    isAuthorizing(): boolean;
    isEnded(): boolean;
}

export interface RemoteContext extends EventEmitter {
    destroy(): void;
    setTool(toolName: CobrowseToolName): any;
    clearAnnotations(): any;
    endSession(): any;
    setFullDevice(newState: boolean): Promise<boolean>;
    androidBack(): Promise<boolean>;
    androidHome(): Promise<boolean>;
}

export interface ICobrowseAPI {
    sessions: {
        create(): Promise<CobrowseSession>;
        get(codeOrId: string): Promise<CobrowseSession>;
        list(query?: {state: CobrowseSessionState}): Promise<CobrowseSession[]>;
    };
    token: string;
    api: string;
    attachContext(cobrowseIframeElement: HTMLIFrameElement): Promise<RemoteContext>;
}
