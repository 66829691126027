import {observer} from 'mobx-react';
import React from 'react';
import Attribute from './Attribute';
import styled from 'styled-components';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import {DefaultDetails} from '../../types';
import useCopyMDN from '../../behaviour/useCopyMDN';
import {SupportItem} from '@anywhere-expert/expert-feed-store';

export const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

type Props = {
    defaultDetails: DefaultDetails;
    selectedSession: SupportItem;
};

export default observer(({defaultDetails, selectedSession}: Props) => {
    const {customerId, supportItemDetails, deviceDetails, customerDetails, sessionId} = selectedSession;

    const {textToCopy} = useCopyMDN(
        customerId,
        supportItemDetails.partner,
        deviceDetails.platform,
        customerDetails.mdn
    );

    return (
        <>
            <LoggingErrorBoundary boundaryName="DefaultAttributes">
                <Attribute name="Partner" value={defaultDetails.partner} defaultValue="Unknown" />
                {defaultDetails.firstName && (
                    <Attribute name="First Name" value={defaultDetails.firstName} defaultValue="Unknown" />
                )}
                <Attribute name="Session ID" defaultValue="Unknown" value={sessionId} />
                <Attribute name="Phone number" defaultValue="N/A" value={textToCopy} dataPii={!!textToCopy} />
            </LoggingErrorBoundary>
        </>
    );
});
