import React, {useState, useCallback, useRef, useMemo} from 'react';
import styled from 'styled-components';
import {CoreInputText} from '@anywhere-expert/base-ui';
import {EmojiActionMenu} from '@anywhere-expert/rich-input';
import {useDetectOutsideClick} from '@anywhere-expert/utils';

const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    word-break: break-word;
    padding: 7px 0px 7px 0px;
`;

const CategoryName = styled(CoreInputText)`
    max-width: 120px;
`;

const Button = styled.button`
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border: 0;
    padding: 0;
    outline: none;
    user-select: none;
    background-color: transparent;
`;

const TextContainer = styled.span`
    font-size: 16px;
`;

type Props = {
    text: string;
    icon: string;
    onEdit: (updates: any) => void;
};

const getEmojiComponent = (emoji, ref) => ({isActive, ...others}: any) => (
    <Button isActive={isActive} {...others}>
        <TextContainer ref={ref}>{emoji}</TextContainer>
    </Button>
);

const EditCategory = ({text, icon, onEdit}: Props) => {
    const [title, setTitle] = useState(text);

    const buttonRef = useRef(null);

    const onOutsideClick = useCallback(() => {
        onEdit({title: title || 'Category', icon});
    }, [onEdit, title, icon]);

    const onSubmit = useCallback(
        (e: any) => {
            if (e.key === 'Enter') {
                onEdit({title: title || 'Category', icon});
            }
        },
        [onEdit, title, icon]
    );

    const onFocus = useCallback((e: any) => {
        e.target.select();
    }, []);

    const onChangeTitle = useCallback(
        e => {
            setTitle(e.target.value);
        },
        [text]
    );

    const wrapperRef = useDetectOutsideClick(onOutsideClick);

    const insertEmoji = useCallback(
        (newEmoji: string) => {
            onEdit({title: title || 'Category', icon: newEmoji});
        },
        [onEdit, title]
    );

    const EmojiComponent = useMemo(() => getEmojiComponent(icon, buttonRef), [icon]);

    return (
        <Container ref={wrapperRef} data-test-id="CannedMessages_EditCategory">
            <EmojiActionMenu
                emojiPickerStyle={{width: '230px'}}
                insertEmoji={insertEmoji}
                closeOnEmojiSelect={true}
                customButton={EmojiComponent}
                directionUp={false}
            />
            <CategoryName
                data-test-id="CannedMessages_EditCategory_Text"
                size="m"
                maxLength={35}
                autoFocus={true}
                autoSelect={true}
                value={title}
                onFocus={onFocus}
                onChange={onChangeTitle}
                onKeyDown={onSubmit}
                isTransparent={true}
            />
        </Container>
    );
};

export default EditCategory;
