import logger from '@anywhere-expert/logging';
import pRetry from 'p-retry';
import SupportItem from './SupportItem';
import {computed, observable, action, runInAction, makeObservable} from 'mobx';
import {hasSimilarSessions} from '../utils/sessionType';
import {extractAttributeFields} from '../utils/attributeUtils';
import {SessionTranscriptData, PreviewData} from '@anywhere-expert/similar-sessions';
import {
    getSessionFeedback,
    getSessionTranscript,
} from '@anywhere-expert/similar-sessions/src/shared/similarSessionsApi';

export enum LoadState {
    LOADED = 'loaded',
    LOADING = 'loading',
    FAILED = 'failed',
}

export enum FeedbackStep {
    QUESTION = 'question',
    REASON = 'reason',
    THANK_YOU = 'thank_you',
}

export type Feedback = {
    value: boolean;
    reason: number | undefined;
};

export default class QueueItemSimilarSessionsModel {
    parent: SupportItem;
    private sessionTranscripts = new Map<string, SessionTranscriptData>();
    private similarityScores = new Map<string, number>();
    private feedbacks = new Map<string, Feedback>();
    private userSelectedTranscriptId: string;
    loadState: LoadState = LoadState.LOADING;

    constructor(parent: SupportItem) {
        makeObservable<
            QueueItemSimilarSessionsModel,
            'sessionTranscripts' | 'similarityScores' | 'feedbacks' | 'userSelectedTranscriptId'
        >(this, {
            sessionTranscripts: observable,
            similarityScores: observable,
            feedbacks: observable,
            userSelectedTranscriptId: observable,
            loadState: observable,
            hasSimilarSessions: computed,
            similarSessions: computed,
            selectedTranscriptId: computed,
            setSimilarityScore: action,
            setSelectedTranscript: action,
            setSelectedFeedback: action,
            setSelectedNegativeFeedbackReason: action,
            selectedSessionData: computed,
            selectedFeedbackStep: computed,
            selectedSimilarityScore: computed,
        });

        this.parent = parent;
    }

    get hasSimilarSessions(): boolean {
        return hasSimilarSessions(this.parent.supportItemDetails.attributes);
    }

    get similarSessions(): PreviewData[] {
        const fields = extractAttributeFields(this.parent.sessionAttributes, 'similar-sessions-v2');
        return Object.values(fields);
    }

    private fetchSimilarSessionsTranscript = async (sessionId: string) => {
        const partner = this.parent.supportItemDetails.partner;
        return await pRetry(() => getSessionTranscript(sessionId, partner), {
            retries: 2,
            onFailedAttempt: err => {
                logger.warn('Failed to get session transcript', {
                    err,
                    extra: {sessionId, partner},
                });
            },
        });
    };

    private getOrFetchTranscripts = async () => {
        return await Promise.all(
            this.similarSessions.map(async ({sessionId}) => {
                const transcript = this.sessionTranscripts.has(sessionId)
                    ? this.sessionTranscripts.get(sessionId)
                    : await this.fetchSimilarSessionsTranscript(sessionId);
                return {sessionId, transcript};
            })
        );
    };

    private fetchSimilarSessionsFeedback = async (sessionId: string) => {
        return await pRetry(() => getSessionFeedback(this.parent.sessionId, this.parent.store.user.uid, sessionId), {
            retries: 2,
            onFailedAttempt: err => {
                logger.warn('Failed to get session feedback', {
                    err,
                    extra: {similarSessionId: sessionId, sessionId: this.parent.sessionId},
                });
            },
        });
    };

    private getOrFetchFeedbacks = async () => {
        return await Promise.all(
            this.similarSessions.map(async ({sessionId}) => {
                const feedback = this.sessionTranscripts.has(sessionId)
                    ? this.sessionTranscripts.get(sessionId)
                    : await this.fetchSimilarSessionsFeedback(sessionId);
                return {sessionId, feedback};
            })
        );
    };

    get selectedTranscriptId() {
        if (this.userSelectedTranscriptId) {
            return this.userSelectedTranscriptId;
        }
        if (this.hasSimilarSessions) {
            return this.similarSessions[0].sessionId;
        }
        throw new Error('No similar sessions');
    }

    fetchData = async () => {
        try {
            if (this.loadState !== LoadState.LOADED) {
                const [transcriptsResults, feedbackResults] = await Promise.all([
                    this.getOrFetchTranscripts(),
                    this.getOrFetchFeedbacks(),
                ]);

                runInAction(() => {
                    transcriptsResults.forEach(result => {
                        if (!this.sessionTranscripts.has(result.sessionId) && result.transcript) {
                            this.sessionTranscripts.set(result.sessionId, result.transcript);
                        }
                    });
                    feedbackResults.forEach(result => {
                        if (!this.feedbacks.has(result.sessionId) && result.feedback) {
                            this.feedbacks.set(result.sessionId, result.feedback.rating);
                        }
                    });
                    this.loadState = LoadState.LOADED;
                });
            }
        } catch (err) {
            logger.warn('Failed to load Similar Sessions', {err});
            runInAction(() => {
                this.loadState = LoadState.FAILED;
            });
        }
    };

    setSimilarityScore = (sessionId: string, similarityScore: number) => {
        this.similarityScores.set(sessionId, similarityScore);
    };

    setSelectedTranscript = (transcriptId: string) => {
        this.userSelectedTranscriptId = transcriptId;
    };

    setSelectedFeedback = (value: boolean) => {
        this.feedbacks.set(this.selectedTranscriptId, {value, reason: undefined});
    };

    setSelectedNegativeFeedbackReason = (reason: number) => {
        this.feedbacks.set(this.selectedTranscriptId, {value: false, reason});
    };

    get selectedSessionData(): SessionTranscriptData {
        return this.sessionTranscripts.get(this.selectedTranscriptId)!;
    }

    get selectedFeedbackStep(): FeedbackStep {
        if (this.feedbacks.has(this.selectedTranscriptId)) {
            const feedback = this.feedbacks.get(this.selectedTranscriptId)!;
            if (feedback.value || feedback.reason) {
                return FeedbackStep.THANK_YOU;
            } else {
                return FeedbackStep.REASON;
            }
        }

        return FeedbackStep.QUESTION;
    }

    clearSelectedFeedback = () => {
        this.feedbacks.delete(this.selectedTranscriptId);
    };

    get selectedSimilarityScore(): number {
        // if loaded from sidebar tab (not timeline item) similarity score might be undefined
        return this.similarityScores.get(this.selectedTranscriptId) || 0;
    }

    getTranscriptData = (sessionId: string): SessionTranscriptData | undefined => {
        return this.sessionTranscripts.get(sessionId);
    };
}
