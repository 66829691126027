import {createTweekRepository, getTweekTechnicianContext} from './tweekClient';
import {TweekRepository} from 'tweek-local-cache';
const LRU = require('lru-cache'); // regular import results in failures in some unit tests, this import fixes
import {Context} from 'tweek-client';
import isSubset from 'is-subset';

// @ts-ignore non standard import results in typescript failure
const tweekRepositoryCache = new LRU<string, TweekRepository>(40);

const shouldNotUpdate = (prevContext: Context, newContext: Context) => {
    // should not update the context if the new context is a subset of the new context
    return isSubset(prevContext, newContext);
};

const getCacheKey = (id: string, type: string) => `${type}_${id}`;

export const getTweekRepoForContext = ({id, type, context}: TweekCustomContextConfig): TweekRepository => {
    const cacheKey = getCacheKey(id, type);
    const cachedRepo = tweekRepositoryCache.get(cacheKey);
    if (cachedRepo) {
        cachedRepo.updateContext((prevContext: Context) => {
            const contextWithTechnician = {...context, ...getTweekTechnicianContext()};
            return shouldNotUpdate(prevContext, contextWithTechnician) ? null : contextWithTechnician;
        });
        return cachedRepo;
    }

    const newRepo = createTweekRepository({context});
    tweekRepositoryCache.set(cacheKey, newRepo);
    return newRepo;
};

export const removeRepoFromCache = (id: string, type: string) => {
    const cacheKey = getCacheKey(id, type);
    if (tweekRepositoryCache.has(cacheKey)) {
        tweekRepositoryCache.del(cacheKey);
    }
};

export enum TweekCustomContextType {
    Customer = 'customer',
}

export interface TweekCustomContextConfig {
    id: string;
    type: TweekCustomContextType;
    context: Context;
}

export const getFromTweekForContext = async <T>(
    contextConfig: TweekCustomContextConfig,
    key: string
): Promise<T | undefined> => {
    try {
        const tweekRepository = getTweekRepoForContext(contextConfig);
        return await tweekRepository.getValue(key);
    } catch (e) {
        return;
    }
};
