import React from 'react';
import {Route, RouteProps} from 'react-router-dom';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';

interface ErrorBoundaryRouteProps extends RouteProps {
    boundaryName: string;
}

const ErrorBoundaryRoute = ({boundaryName, ...other}: ErrorBoundaryRouteProps) => (
    <LoggingErrorBoundary boundaryName={boundaryName}>
        <Route {...other} />
    </LoggingErrorBoundary>
);

export default ErrorBoundaryRoute;
