import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import moment from 'moment';

type Props = {
    timestamp?: number;
    sender: string;
    isHovered: boolean;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1px;
`;

const Sender = styled(CoreText)`
    width: 101px;
    height: 16px;
    left: 24px;
    top: 172px;
    font-weight: bold;
`;

const DateSpan = styled(CoreText)`
    line-height: 130%;
`;

export default ({timestamp, sender = 'Customer', isHovered}: Props) => {
    const dateSent = React.useMemo(() => moment.utc(timestamp).format('LL'), [timestamp]);

    return (
        <Container>
            <Sender>{sender}</Sender>
            {isHovered ? (
                <CoreText textType="soluto" size="s">
                    Click to view
                </CoreText>
            ) : (
                <DateSpan>{dateSent}</DateSpan>
            )}
        </Container>
    );
};
