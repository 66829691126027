import {AssignmentState, MessageData} from '../types';
import {GhostIcon, MaxLimitIcon, SayHeyIcon, UnavailableIcon, PhoneRingIcon} from '@anywhere-expert/icons';
import type {AssignStoreType} from '../store/AssignStore';
import type {AnalyticsDispatcher} from 'shisell';

const maxItemsMessage: MessageData = {
    text: 'Maximum number of sessions',
    subText: 'Resolve a session to get a new one',
    name: AssignmentState.LIMIT_REACH,
    icon: MaxLimitIcon,
};

const sendMessageFirstMessage: MessageData = {
    text: 'A new session is waiting for you!',
    subText: 'Answer it to get the next one',
    name: AssignmentState.SEND_MSG_FIRST,
    icon: SayHeyIcon,
    onClick: (assignStore: AssignStoreType, dispatcher: AnalyticsDispatcher) => {
        const {feed} = assignStore;
        const {assignedSession, unengagedSessions} = feed;
        const newlySelectedSessionId = assignedSession?.sessionId;
        const selectSessionId = newlySelectedSessionId ?? unengagedSessions[0]?.sessionId;
        if (selectSessionId) {
            dispatcher
                .withExtras({
                    State: AssignmentState.SEND_MSG_FIRST,
                    ActionId: 'GoToSession',
                    IsRoutedImmediately: !!newlySelectedSessionId,
                })
                .dispatch('Click');
            feed.selectSession(selectSessionId);
        }
    },
};

const expertUnavailableMessage: MessageData = {
    text: `Change your status to ‘Available’`,
    subText: 'to get the next session',
    name: AssignmentState.UNAVAILABLE,
    icon: UnavailableIcon,
};

const expertSwitchingToVoiceMessage: MessageData = {
    text: `You’re currently ‘Unavailable’`,
    subText: 'You can continue helping people by taking calls',
    name: AssignmentState.SWITCH_TO_VOICE,
    icon: PhoneRingIcon,
};

const expertOnVoiceMessage: MessageData = {
    text: `You’re currently ‘Unavailable’`,
    subText: 'You can continue helping people by taking calls',
    name: AssignmentState.ON_VOICE,
    icon: PhoneRingIcon,
};

const internalErrorMessage: MessageData = {
    text: `Sorry, something went wrong`,
    name: AssignmentState.ERROR,
    icon: GhostIcon,
    onClick: (assignStore: AssignStoreType, dispatcher: AnalyticsDispatcher) => {
        dispatcher.withExtras({State: AssignmentState.ERROR, ActionId: 'Skip'}).dispatch('Click');
        assignStore.setUnexpectedErrorIndication(false);
    },
};

export {
    internalErrorMessage,
    maxItemsMessage,
    expertUnavailableMessage,
    sendMessageFirstMessage,
    expertSwitchingToVoiceMessage,
    expertOnVoiceMessage,
};
