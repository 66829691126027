import React from 'react';
import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import {useUserProfile} from '@anywhere-expert/auth';
import {BoxContainer} from './shared/styles';
import CurrentTime from './lobbyHeader/CurrentTime';
import {SMALL_WIDTH, MEDIUM_WIDTH, LARGE_WIDTH} from './shared/consts';

const currentDate = new Date().toLocaleString('en-US', {month: 'short', day: '2-digit'});

const Container = styled(BoxContainer)`
    background-color: ${({theme}) => theme.utils.split(palette.neutral.white, palette.neutral.grey.light)};
    align-items: center;
    padding: 0 100px;
    justify-content: flex-start;

    @media (max-width: ${MEDIUM_WIDTH}) {
        padding: 0 50px;
    }

    @media (max-width: ${SMALL_WIDTH}) {
        padding: 0 100px;
    }

    @media (min-width: ${LARGE_WIDTH}) {
        justify-content: center;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Text = styled.span`
    color: ${palette.neutral.black};
    font-size: 24px;
`;

const CurrentDateContainer = styled(Text)`
    font-size: 20px;
    text-transform: uppercase;
`;

const CurrentDateText = styled(Text)`
    font-weight: 900;
`;

const WelcomeText = styled(Text)`
    font-size: 60px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    margin-top: ${({theme}) => theme.spacing(2)}px;
    margin-bottom: ${({theme}) => theme.spacing(1)}px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 50px;
    }

    z-index: 0;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const SubtitleText = styled(Text)`
    font-weight: 300;
    line-height: 1.24;
`;

const Image = styled.img`
    margin-left: 5%;
    height: 80%;
    max-height: 374px;
    object-fit: contain;

    @media (max-width: ${SMALL_WIDTH}) {
        display: none;
    }
`;

const Name = styled.span`
    color: ${palette.neutral.white};
    text-transform: capitalize;
    position: relative;
    margin-left: 0.25em;

    ::before {
        content: '';
        display: block;
        background-color: ${palette.brand.purple};
        transform: rotate(-1deg);
        position: absolute;
        z-index: -1;
        width: 110%;
        left: -5%;
        height: 1.1em;
        top: -0.1em;
    }
`;

const LobbyHeader = () => {
    const user = useUserProfile();

    return (
        <Container>
            <TextContainer>
                <CurrentDateContainer>
                    <CurrentDateText>{currentDate}</CurrentDateText>&nbsp;
                    <CurrentTime />
                </CurrentDateContainer>
                <WelcomeText>
                    <Row>
                        Hey
                        <Name id="ExpertDisplayName">{user.displayName}</Name>
                    </Row>
                    <Row>Welcome back</Row>
                </WelcomeText>
                <SubtitleText>It’s great to have you on the team!</SubtitleText>
            </TextContainer>
            <Image src={require('./resources/lobby_pic.png')} />
        </Container>
    );
};

export default LobbyHeader;
