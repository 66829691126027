import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

export const MessageButtonContainer = styled.div`
    position: relative;
    cursor: pointer;
    max-height: 84px;
    min-height: 20px;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.border}`};
    border-radius: 8px;
    background-color: transparent;
    margin: 8px 0 0 0;
    display: flex;

    &:hover {
        background-color: ${({theme}) => theme.colors.contrast.border};
    }
    &:focus {
        outline: none;
        background-color: ${({theme}) => theme.colors.contrast.disabled};
    }
`;

export const MessageText = styled(CoreText)`
    overflow: hidden;
    display: -webkit-box;
    font-size: 13px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;
