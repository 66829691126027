import {AnalyticsEventModel} from 'shisell';
import {Subject} from 'rxjs';
import {configuration as config} from '@anywhere-expert/core';
import logger from '@anywhere-expert/logging';
import analyticsHeaders from './analyticsHeaders';
import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 3,
    maxTimeout: 2000,
};

const ANALYTICS_BATCH_EVENTS_API = config.analyticsBaseUrl + '/api/v1/Events/Batch';

const eventsSubject = new Subject<AnalyticsEventModel>();
const analyticsConfig = {bufferTime: 2000, maxBufferItems: 10};

eventsSubject
    .bufferTime(analyticsConfig.bufferTime, -1, analyticsConfig.maxBufferItems)
    .filter(events => events.length > 0)
    .subscribe(sendAnalyticsWhenIdle);

export default (model: AnalyticsEventModel) => eventsSubject.next(model);

export const sendAnalytics = (data: AnalyticsEventModel[]) =>
    pRetry(
        () =>
            fetch(ANALYTICS_BATCH_EVENTS_API, {
                method: 'POST',
                headers: analyticsHeaders,
                body: JSON.stringify(data),
            }),
        retryConfig
    );

function sendAnalyticsWhenIdle(data: AnalyticsEventModel[]) {
    (window as any).requestIdleCallback(() => {
        sendAnalytics(data).catch(err => logger.warn("Couldn't write batch analytic to backend", {err}));
    });
}
