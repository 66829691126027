import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {observer} from 'mobx-react';
import {CoreTag, CoreText} from '@anywhere-expert/base-ui';
import {RecommendationContentProps, OfferOnResolutionRecommendation} from '../types';
import {useTheme} from '@anywhere-expert/colors';
import {CyberSecurityIcon, HomeDevicesIcon} from '@anywhere-expert/icons';
import {palette} from '@anywhere-expert/colors';

const Container = styled.div`
    display: flex;
    height: 60px;
    overflow: visible;

    &.open {
        height: unset;

        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            pointer-events: none;
            width: 100%;
            height: 4rem;
        }
    }
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.hide-title:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 1px;
        left: 1px;
        pointer-events: none;
        width: calc(100% - 1px);
        height: 4rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

const Left = styled.div<{isOpen?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: ${({isOpen}) => (isOpen ? '170px' : '114px')};
`;

const Right = styled.div`
    align-items: center;
    display: flex;
`;

const Header = styled(CoreText)`
    margin: 0;
    margin-bottom: 4px;
    max-width: 136px;
    overflow: hidden;
    float: left;
`;

const Text = styled(CoreText)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

const OfferCategoryTag = styled(CoreTag)<{color: string}>`
    background-color: ${palette.primary.green.bright};
    color: ${palette.neutral.black};
    width: fit-content;
    margin: 0;
    margin-top: 8px;
    float: right;
    text-transform: capitalize;
`;

const IconContainer = styled.div`
    height: 48px;
    width: 48px;
    svg {
        max-height: 43px;
        width: 100%;
    }
`;

type Props = RecommendationContentProps<OfferOnResolutionRecommendation>;
export default observer(({item, isOpen}: Props) => {
    const {sessionId, offerRecommendation, partner} = item.data;
    const {dispatcher} = useAnalytics();
    const theme = useTheme();

    useEffect(() => {
        // The component is rendered with isOpen=true for hover mode, we don't want to dispatch View event for that
        if (isOpen) return;

        dispatcher
            .createScoped('OfferOnResolutionRecommendation')
            .withExtras({
                SessionId: sessionId,
                Source: 'OfferOnResolutionDetection',
                CarrierName: partner,
            })
            .dispatch('View');
    }, []);

    const recommendationCategoryToIconMap = useMemo(
        () => ({
            cybersecurity: (
                <CyberSecurityIcon color={theme.colors.neutral.black} width="64" height="68" viewBox="0 0 64 68" />
            ),
            'device coverage': (
                <HomeDevicesIcon color={theme.colors.neutral.black} width="77" height="55" viewBox="0 0 77 55" />
            ),
            '': <HomeDevicesIcon color={theme.colors.neutral.black} width="77" height="55" viewBox="0 0 77 55" />,
        }),
        [theme]
    );

    return (
        <Container
            className={isOpen ? 'open' : ''}
            data-test-id={isOpen ? 'OfferOnResolutionRecommendation_open' : 'OfferOnResolutionRecommendation'}
        >
            <Body>
                <Left isOpen={isOpen}>
                    <Header
                        disableTextOverflow
                        data-test-id="OfferOnResolutionTitle"
                        weight="bold"
                        textType="secondary"
                    >
                        {offerRecommendation.title}
                    </Header>
                    {offerRecommendation.text && isOpen && (
                        <Text
                            data-test-id="OfferOnResolutionText"
                            disableTextOverflow={!isOpen}
                            textType="secondary"
                            className={isOpen ? 'open' : ''}
                        >
                            {offerRecommendation.text}
                        </Text>
                    )}
                    {offerRecommendation.category && (
                        <OfferCategoryTag
                            text={offerRecommendation.category}
                            size="s"
                            color={theme.colors.neutral.white}
                        />
                    )}
                </Left>
                {!isOpen && offerRecommendation.category !== undefined && (
                    <Right>
                        <IconContainer>{recommendationCategoryToIconMap[offerRecommendation.category]}</IconContainer>
                    </Right>
                )}
            </Body>
        </Container>
    );
});
