import {observable, runInAction, computed, reaction, action, IReactionDisposer, makeObservable} from 'mobx';
import {PreviewData} from '@anywhere-expert/similar-sessions';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {
    TextRecommendation,
    Recommendation,
    SimilarSessionRecommendation,
    OfferOnResolutionRecommendation,
    TailoredOfferRecommendation,
} from './types';
import {
    getEligibility,
    sessionHasSmartHomeFields,
    getOfferRecommendations,
    getOfferDetectionFields,
} from '@anywhere-expert/messaging-sales';
import {Attribute} from '@soluto-private/session-api-types';

const MESSAGES_MODEL_VERSION = 'v2';

export default class RecommendationsStore {
    parent: TimelineModel;
    private similarSessionsReaction?: IReactionDisposer;
    private salesReaction?: IReactionDisposer;
    private messagesReaction?: IReactionDisposer;
    private similarSessionsLoaded = false;
    textRecommendations: TextRecommendation[] = [];
    similarRecommendations: SimilarSessionRecommendation[] = [];
    offerRecommendations: (OfferOnResolutionRecommendation | TailoredOfferRecommendation)[] = [];
    isHidden: boolean = false;

    constructor(parent: TimelineModel) {
        makeObservable<RecommendationsStore, 'updateSimilarSessionsRecommendations' | 'updateSmartSuggestions'>(this, {
            textRecommendations: observable,
            isHidden: observable,
            similarRecommendations: observable,
            offerRecommendations: observable,
            recommendations: computed,
            hasRecommendations: computed,
            updateSimilarSessionsRecommendations: action,
            updateSmartSuggestions: action,
            setIsHidden: action,
        });

        this.parent = parent;
    }

    get recommendations(): Recommendation[] {
        return (this.offerRecommendations as Recommendation[])
            .concat(this.textRecommendations as Recommendation[])
            .concat(this.similarRecommendations);
    }

    setIsHidden = (hidden: boolean) => {
        this.isHidden = hidden;
    };

    async connect() {
        await Promise.all([
            this.connectToMessages(),
            this.connectToSimilarSessions(),
            this.connectToOfferRecommendations(),
        ]);
    }

    private async connectToSimilarSessions() {
        if (this.similarSessionsReaction || this.similarSessionsLoaded) return;

        const similarSessionsStore = this.parent.supportItem.similarSessions;
        this.updateSimilarSessionsRecommendations(similarSessionsStore.similarSessions);

        this.similarSessionsReaction = reaction(
            () => similarSessionsStore.similarSessions,
            similarSessions => {
                this.updateSimilarSessionsRecommendations(similarSessions);
            }
        );
    }

    private updateSimilarSessionsRecommendations = async (data: PreviewData[]) => {
        if (!data.length) return;
        const similarSessionsStore = this.parent.supportItem.similarSessions;
        runInAction(() => {
            this.similarRecommendations = data.map((data: PreviewData) => ({
                type: 'similar-session',
                data,
                id: `similar-session/${data.sessionId}`,
            }));
            this.similarSessionsLoaded = true;
        });

        similarSessionsStore.fetchData().then(() => {
            data.forEach((item: PreviewData) =>
                similarSessionsStore.setSimilarityScore(item.sessionId, item.similarityScore)
            );
        });
    };

    private async connectToOfferRecommendations() {
        if (this.isOfferRecommendationDisabled()) return;

        this.salesReaction = reaction(
            () => ({sessionAttributes: this.parent.supportItem.sessionAttributes}),
            () => this.updateOfferRecommendations(this.parent.supportItem),
            {fireImmediately: true}
        );
    }

    private isOfferRecommendationDisabled() {
        const isEligible = getEligibility(this.parent.supportItem);

        if (!sessionHasSmartHomeFields(this.parent.supportItem) || isEligible === false || this.salesReaction) {
            return true;
        }

        return false;
    }

    private updateOfferRecommendations = (session: SupportItem) => {
        const offerHasBeenMade = Boolean(getOfferDetectionFields(session.sessionAttributes));

        if (offerHasBeenMade) {
            runInAction(() => {
                this.offerRecommendations = [];
            });
            return;
        }

        runInAction(() => {
            this.offerRecommendations = getOfferRecommendations(session);
        });
    };

    private async connectToMessages() {
        const supportItem = this.parent.supportItem;

        this.updateSmartSuggestions(supportItem.smartSuggestionAttributes);
        this.messagesReaction = reaction(
            () => ({
                smartSuggestionAttributes: this.parent.supportItem.smartSuggestionAttributes,
            }),
            ({smartSuggestionAttributes}) => this.updateSmartSuggestions(smartSuggestionAttributes)
        );
    }

    private updateSmartSuggestions = (smartSuggestionAttributes: Attribute[]) => {
        if (!smartSuggestionAttributes.length) {
            runInAction(() => {
                this.textRecommendations = [];
            });
            return;
        }
        const modelAttribute = smartSuggestionAttributes.filter(x => x.key === MESSAGES_MODEL_VERSION)[0];
        if (!modelAttribute) return;
        const suggestions = modelAttribute.fields?.['suggestions'];
        const triggerMessageId = modelAttribute.fields?.['triggeringMessageId'];

        if (!suggestions || !triggerMessageId) return;
        const textRecommendations = (suggestions as string[]).map(
            suggestion =>
                ({
                    type: 'message',
                    data: {text: suggestion, triggerMessageId, modelVersion: MESSAGES_MODEL_VERSION},
                    id: suggestion,
                } as TextRecommendation)
        );
        runInAction(() => {
            this.textRecommendations = textRecommendations;
        });
    };

    get hasRecommendations() {
        return this.parent.isRecommendationsEnabled && !!this.recommendations.length;
    }

    disconnect() {
        this.messagesReaction && this.messagesReaction();
        this.messagesReaction = undefined;

        this.similarSessionsReaction && this.similarSessionsReaction();
        this.similarSessionsReaction = undefined;

        this.salesReaction && this.salesReaction();
        this.salesReaction = undefined;
    }
}
