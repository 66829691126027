export const SHOW_TIME_WITH_ACTION = 7000;
export const SHOW_TIME = 5000;

export type ActionType = 'undo' | 'seeSession';

export const actionTypeToText: Record<ActionType, string> = {
    undo: 'undo',
    seeSession: 'see session',
};

export type AppMessageType = 'success' | 'warning' | 'autopilot' | 'followup';
