import axios from 'axios';
import {HelixSuggestionItem} from '../types';
import {configuration} from '@anywhere-expert/core';

const fetchSuggestions = async (searchTerm: string) => {
    const result = await axios.post(
        configuration.helix.autocompleteUrl,
        {
            string: searchTerm,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    let suggestions = [] as HelixSuggestionItem[];

    if (!result || !result.data) {
        return suggestions;
    }
    if (Array.isArray(result.data)) {
        suggestions = result.data as HelixSuggestionItem[];
    }

    return suggestions;
};

export default fetchSuggestions;
