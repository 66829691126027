import {CobrowseSession} from './cobrowse-agent-sdk_types';

export default class {
    private lastReportedFullDeviceState: boolean | undefined;
    private onRejectionDetected: () => void;

    public constructor(onRejectionDetected: () => void) {
        this.onRejectionDetected = onRejectionDetected;
    }

    handleSessionUpdate = (sessionUpdate: CobrowseSession) => {
        const updatedFullDeviceState = sessionUpdate.full_device;

        const rejectionDetected = this.lastReportedFullDeviceState === true && updatedFullDeviceState === false;

        if (rejectionDetected) {
            this.onRejectionDetected();
        }

        this.lastReportedFullDeviceState = updatedFullDeviceState;
    };
}
