import {getAppMetadata} from '@anywhere-expert/utils';
import {configuration} from '@anywhere-expert/core';
import {create} from '@soluto-private/live-messaging-api-client';
import {getHomeAccessToken$} from '@anywhere-expert/auth';
import {Observable} from 'rxjs';

const {version} = getAppMetadata();

export const createClient = () => {
    const {roomUpdates, connectionStatus$, requestSnapshotOnRoom, socketEvents$} = create({
        url: configuration.messagingApi.live,
        clientVersion: version,
        clientApi: 'AnywhereExpertWeb',
        apiKey: configuration.messagingApi.apiKey,
        accessTokenProvider: () => getHomeAccessToken$() as any,
    });

    return {
        connectionStatus$: Observable.from(connectionStatus$),
        roomUpdates,
        requestSnapshotOnRoom,
        socketEvents$: Observable.from(socketEvents$),
    };
};
