import {IncrementalSource, MouseInteractions} from 'rrweb';
import {CobrowsingDomEventType, Size, Coordinates} from '../types';

type DomEventToCobrowsingDataReturnType = {
    type: CobrowsingDomEventType;
    data: Coordinates | Size;
    timestamp: number;
};

const hasValue = (value: any) => typeof value !== 'undefined';

export default (event: any): DomEventToCobrowsingDataReturnType | undefined => {
    const sourceId = event?.data?.source;
    const timestamp = event?.timestamp || -1;

    if (sourceId === IncrementalSource.Scroll) {
        const {x, y} = event.data;
        if (hasValue(x) && hasValue(y)) {
            return {
                type: 'scroll',
                data: {x, y},
                timestamp,
            };
        }
        return;
    } else if (!!event?.data?.initialOffset) {
        const {top: y, left: x} = event.data.initialOffset;
        if (hasValue(x) && hasValue(y)) {
            return {
                type: 'scroll',
                data: {x, y},
                timestamp,
            };
        }
        return;
    }

    if (sourceId === IncrementalSource.MouseMove) {
        const {positions} = event.data;
        if (positions?.length > 0) {
            const {x, y} = positions[positions.length - 1];
            return {
                type: 'mouse-move',
                data: {x, y},
                timestamp,
            };
        }
        return;
    }

    if (sourceId === IncrementalSource.MouseInteraction && event.data.type === MouseInteractions.Click) {
        const {x, y} = event.data;
        if (hasValue(x) && hasValue(y)) {
            return {
                type: 'click',
                data: {x, y},
                timestamp,
            };
        }
        return;
    }

    if (sourceId === IncrementalSource.ViewportResize || (event.data?.width >= 0 && event.data?.height >= 0)) {
        const {width = 0, height = 0} = event.data;
        return {
            type: 'resize',
            data: {width, height},
            timestamp,
        };
    }
};
