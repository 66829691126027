import React from 'react';
import styled from 'styled-components';
import {
    SalesStatContainer,
    StatContainer,
    Stat,
    Title,
    Subtitle,
    StyledTriangleDown,
    StyledTriangleUp,
} from '../styled-components';
import {useTweekValue} from 'react-tweek';
import {SP100Props} from '../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import useMessagingSalesColors, {MessagingSalesColors} from '../../hooks/useMessagingSalesColors';
import {useTheme} from '@anywhere-expert/colors';
import ProfilePayLink from '../sales-profile/ProfilePayLink';

const StatWithIcon = styled(Stat)`
    position: relative;
`;

const getColor = (colors: MessagingSalesColors, currentWeekSP100: number): string => {
    if (currentWeekSP100 <= 1) {
        return colors.lightRed;
    }

    return colors.tennis;
};

const useIconColor = (colors: MessagingSalesColors): string => {
    const theme = useTheme();

    if (theme.themeName === 'dark') {
        return theme.colors.background.secondary;
    } else {
        return colors.black;
    }
};

const useSP100 = (currentWeekSP100: number): string => {
    if (currentWeekSP100 <= 0) {
        return useTweekValue<string>(MessagingSalesTweekKeys.SP100SubtitleZeroSales, '');
    }

    if (currentWeekSP100 <= 0.5) {
        return useTweekValue<string>(MessagingSalesTweekKeys.SP100SubtitleLessThanHalfASale, '');
    }

    if (currentWeekSP100 <= 1) {
        return useTweekValue<string>(MessagingSalesTweekKeys.SP100SubtitleLessThanOneSale, '');
    }

    if (currentWeekSP100 <= 2) {
        return useTweekValue<string>(MessagingSalesTweekKeys.SP100SubtitleLessThanTwoSales, '');
    }

    return useTweekValue<string>(MessagingSalesTweekKeys.SP100SubtitleMoreThanTwoSales, '');
};

const Icon = ({color, currentWeekSP100, lastWeekSP100, size}) => {
    if (currentWeekSP100 === 0) {
        return null;
    }

    if (currentWeekSP100 === lastWeekSP100) {
        return null;
    }

    return currentWeekSP100 > lastWeekSP100 ? (
        <StyledTriangleUp size={size} color={color} />
    ) : (
        <StyledTriangleDown size={size} color={color} />
    );
};

const SP100 = ({currentWeekSP100, lastWeekSP100}: SP100Props) => {
    const colors = useMessagingSalesColors();
    const SP100Title = useTweekValue(MessagingSalesTweekKeys.SP100Title, 'SP100');
    const SP100SubTitle = useSP100(currentWeekSP100);
    const statColor = getColor(colors, currentWeekSP100);
    const iconColor = useIconColor(colors);

    return (
        <SalesStatContainer>
            <StatContainer>
                <StatWithIcon color={statColor}>
                    {Number(currentWeekSP100.toFixed(2))}
                    <Icon
                        size={'l'}
                        color={iconColor}
                        currentWeekSP100={currentWeekSP100}
                        lastWeekSP100={lastWeekSP100}
                    />
                </StatWithIcon>
            </StatContainer>
            <Title>{SP100Title}</Title>
            <Subtitle>{SP100SubTitle}</Subtitle>
            <ProfilePayLink />
        </SalesStatContainer>
    );
};

export default SP100;
