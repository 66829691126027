import React, {useMemo} from 'react';
import {TweekProvider} from 'react-tweek';
import {customIdToContextConfig} from './tweekClientWithCustomer';
import {getTweekRepoForContext} from './tweekContext';

export const CustomerTweekProvider = ({customerId, children, additionalContext}) => {
    const repo = useMemo(() => getTweekRepoForContext(customIdToContextConfig(customerId, additionalContext)), [
        customerId,
        additionalContext,
    ]);

    return <TweekProvider value={repo}>{children}</TweekProvider>;
};
