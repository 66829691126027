import {AssignmentState, MessageData} from '../types';
import {
    maxItemsMessage,
    internalErrorMessage,
    sendMessageFirstMessage,
    expertUnavailableMessage,
    expertOnVoiceMessage,
    expertSwitchingToVoiceMessage,
} from '../components/messagesComponents';

type MapMessagesType = Partial<Record<AssignmentState, MessageData>>;

export const mapMessages: MapMessagesType = {
    [AssignmentState.LIMIT_REACH]: maxItemsMessage,
    [AssignmentState.SEND_MSG_FIRST]: sendMessageFirstMessage,
    [AssignmentState.UNAVAILABLE]: expertUnavailableMessage,
    [AssignmentState.ERROR]: internalErrorMessage,
    [AssignmentState.SWITCH_TO_VOICE]: expertSwitchingToVoiceMessage,
    [AssignmentState.ON_VOICE]: expertOnVoiceMessage,
};
