import {observable, action, makeObservable} from 'mobx';
import {ViewId} from './types';

const defaultViewId: ViewId = 'dos-and-donts';

class NoSessionSelectedStore {
    selectedViewId: ViewId;

    setSelectedViewId = (viewId: ViewId) => {
        this.selectedViewId = viewId;
    };

    resetSelectedView = () => {
        this.selectedViewId = defaultViewId;
    };

    constructor() {
        makeObservable(this, {
            selectedViewId: observable,
            setSelectedViewId: action,
            resetSelectedView: action,
        });
    }
}

export default new NoSessionSelectedStore();

export type NoSessionSelectedStoreType = NoSessionSelectedStore;
