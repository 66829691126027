import {observable, action, computed, reaction, makeObservable} from 'mobx';
import logger from '@anywhere-expert/logging';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {ExpertAvailabilityStore, SwitchStatusReason} from '@expert-feed/expert-availability';
import {AssignmentState} from '../types';
import mapExpertApiErrorResponse from '../behaviours/mapExpertApiResponse';
import {getTweekValue} from '@anywhere-expert/tweek';
import {LowVolumeStore} from './LowVolumeStore';

export class AssignStore {
    feed: typeof ExpertFeedStore;
    lowVolumeStore?: LowVolumeStore;
    private errorOccurIndicator: boolean = false;
    isPush: boolean;
    availableWaitingForPush: boolean = false;

    getNextFirstLoadingDurationInMs: number;
    shouldSkipLoader: boolean;
    assignmentState: AssignmentState | undefined;

    constructor(feed: typeof ExpertFeedStore) {
        makeObservable<
            AssignStore,
            | 'lowVolumeStore'
            | 'errorOccurIndicator'
            | 'reactOnAssignedItems'
            | 'reactOnExpertUnengagedSessions'
            | 'reactOnExpertAvailable'
            | 'reactOnLowVolumeFlag'
            | 'reactOnShortTermInactive'
            | 'reactOnExpertLimitReached'
            | 'updateAssignmentStateGetNext'
            | 'getAvailableWaitingForPush'
            | 'handleAvailableWaitingForPush'
        >(this, {
            shouldSkipLoader: observable,
            getAvailableWaitingForPush: computed,
            handleAvailableWaitingForPush: action,
            setUnexpectedErrorIndication: action,
            errorOccurIndicator: observable,
            reactOnShortTermInactive: action,
            reactOnExpertLimitReached: action,
            assignmentState: observable,
            lowVolumeStore: observable,
            getNextClick: action,
            reactOnAssignedItems: action,
            reactOnExpertUnengagedSessions: action,
            reactOnExpertAvailable: action,
            reactOnLowVolumeFlag: action,
            updateAssignmentStateGetNext: action,
        });

        this.feed = feed;
        this.isPush = feed.assignMethod === 'push';

        const isLowVolumeEnabled = getTweekValue<boolean>('support/routing/get_next_v2/low_volume/is_enabled', false);

        this.getNextFirstLoadingDurationInMs = getTweekValue<number>(
            'support/routing/get_next_v2/first_loading_duration_in_ms',
            3000
        );

        this.reactOnFirstItemsLoaded();
        this.reactOnExpertAvailable();
        this.reactOnStatusReasonChanged();
        this.reactOnExpertUnengagedSessions();
        this.reactOnExpertWaitingForSession();
        this.reactOnAssignedItems();
        this.reactOnShortTermInactive();
        this.reactOnExpertLimitReached();
        if (isLowVolumeEnabled) {
            this.lowVolumeStore = new LowVolumeStore();
            this.reactOnLowVolumeFlag();
        }
        if (this.isPush) {
            this.reactOnAssignmentState();
        }
    }

    getNextClick = async (shouldSkipLoader: boolean = false) => {
        this.shouldSkipLoader = shouldSkipLoader;
        let assignmentState: AssignmentState | undefined;
        try {
            const error = await ExpertAvailabilityStore.setExpertWaitingForSession(!this.isPush);
            assignmentState = error && mapExpertApiErrorResponse(error);
        } catch (err) {
            assignmentState = AssignmentState.ERROR;
        }

        this.handleExpertApiResponse(assignmentState);
    };

    handleExpertApiResponse = async (assignmentState?: AssignmentState) => {
        if (assignmentState === AssignmentState.LIMIT_REACH) {
            ExpertAvailabilityStore.loadExpertAvailability();
            this.updateAssignmentStateGetNext('handleExpertApiResponse', assignmentState);
        } else if (assignmentState === AssignmentState.ERROR) {
            this.setUnexpectedErrorIndication(true);
            setTimeout(() => {
                if (this.errorOccurIndicator) {
                    this.setUnexpectedErrorIndication(false);
                }
            }, 5000);
            this.updateAssignmentStateGetNext('handleExpertApiResponse');
        }
    };
    setUnexpectedErrorIndication = (error: boolean) => {
        this.errorOccurIndicator = error;
        this.updateAssignmentStateGetNext('setUnexpectedErrorIndication');
    };

    private reactOnExpertLimitReached = () => {
        reaction(
            () => ExpertAvailabilityStore.expertAvailabilityStatus.limitReached,
            () => {
                this.updateAssignmentStateGetNext('reactOnAssignedItems');
            }
        );
    };

    private reactOnExpertWaitingForSession = () => {
        reaction(
            () => ExpertAvailabilityStore.waitingForSession,
            () => this.updateAssignmentStateGetNext('reactOnExpertWaitingForSession')
        );
    };

    private reactOnAssignedItems = () => {
        reaction(
            () => this.feed.myAssignedSupportItems.length,
            async _ => {
                await ExpertAvailabilityStore.loadExpertAvailability();
            }
        );
    };

    private reactOnAssignmentState = () => {
        reaction(
            () => this.assignmentState === AssignmentState.ENABLED,
            async enabled => {
                if (enabled && !ExpertAvailabilityStore.expertAvailabilityStatus.limitReached) {
                    this.getNextClick();
                }
            }
        );
    };

    shouldNavigateToSession = (waitingTime?: number) =>
        waitingTime && waitingTime < this.getNextFirstLoadingDurationInMs;

    private reactOnExpertUnengagedSessions = () => {
        reaction(
            () => this.feed.hasUnengagedSessions,
            async hasUnengagedSessions => {
                if (hasUnengagedSessions) {
                    await ExpertAvailabilityStore.cancelExpertWaitingForSession();
                }
                this.updateAssignmentStateGetNext('reactOnExpertUnengagedSessions');
            }
        );
    };

    private reactOnExpertAvailable = () => {
        reaction(
            () => ExpertAvailabilityStore.isAvailable,
            async isAvailable => {
                if (!isAvailable) {
                    await ExpertAvailabilityStore.cancelExpertWaitingForSession();
                }
                this.updateAssignmentStateGetNext('reactOnExpertAvailable');
            },
            {delay: 1000}
        );
    };

    private reactOnLowVolumeFlag = () => {
        reaction(
            () => this.lowVolumeStore?.lowVolumeFlag,
            () => this.updateAssignmentStateGetNext('reactOnLowVolumeFlag')
        );
    };

    private reactOnShortTermInactive = () => {
        reaction(
            () => ExpertAvailabilityStore.isShortTermInactive,
            () => this.updateAssignmentStateGetNext('reactOnShortTermInactive')
        );
    };

    private reactOnStatusReasonChanged = () => {
        reaction(
            () => ExpertAvailabilityStore.expertAvailabilityStatus.statusReason,
            () => this.updateAssignmentStateGetNext('reactOnStatusReasonChanged')
        );
    };

    private reactOnFirstItemsLoaded = () => {
        reaction(
            () => this.feed.isFirstItemsLoaded,
            () => this.updateAssignmentStateGetNext('reactOnFirstItemsLoaded')
        );
    };

    handleAvailableWaitingForPush = () => {
        this.availableWaitingForPush = true;
        this.updateAssignmentStateGetNext('reactOnAvailableWaitingForPush');
        setTimeout(() => {
            this.availableWaitingForPush = false;
            this.updateAssignmentStateGetNext('reactOnAvailableWaitingForPush');
        }, 8000);
    };

    get getAvailableWaitingForPush() {
        return this.availableWaitingForPush;
    }

    private updateAssignmentStateGetNext(updateSource: string, reportedAssignmentState?: AssignmentState) {
        const previousAssignmentState = this.assignmentState;
        if (this.errorOccurIndicator) {
            this.assignmentState = AssignmentState.ERROR;
        } else if (!ExpertAvailabilityStore.isAvailable || !this.feed.isFirstItemsLoaded) {
            const reason = ExpertAvailabilityStore.expertAvailabilityStatus.statusReason?.toLowerCase();
            switch (reason) {
                case SwitchStatusReason.SWITCH_TO_VOICE:
                    this.assignmentState = AssignmentState.SWITCH_TO_VOICE;
                    break;
                case SwitchStatusReason.ON_VOICE:
                    this.assignmentState = AssignmentState.ON_VOICE;
                    break;
                default:
                    this.assignmentState = AssignmentState.UNAVAILABLE;
                    break;
            }
        } else if (ExpertAvailabilityStore.isShortTermInactive) {
            this.assignmentState = AssignmentState.SHORT_TERM_INACTIVE;
        } else if (this.feed.hasUnengagedSessions) {
            this.assignmentState = AssignmentState.SEND_MSG_FIRST;
        } else if (
            ExpertAvailabilityStore.expertAvailabilityStatus.limitReached ||
            reportedAssignmentState === AssignmentState.LIMIT_REACH
        ) {
            this.assignmentState = AssignmentState.LIMIT_REACH;
        } else if (this.lowVolumeStore?.lowVolumeFlag) {
            this.assignmentState = AssignmentState.LOW_VOLUME;
        } else if (this.isPush && this.availableWaitingForPush) {
            this.assignmentState = AssignmentState.AVAILABLE_WAITING_PUSH;
        } else if (ExpertAvailabilityStore.waitingForSession) {
            this.assignmentState = AssignmentState.AWAITING;
        } else {
            this.assignmentState = AssignmentState.ENABLED;
        }

        if (previousAssignmentState !== this.assignmentState) {
            logger.info('assignment state updated', {
                extra: {updateSource, previousAssignmentState, assignmentState: this.assignmentState},
            });
        }
    }
}

export type AssignStoreType = AssignStore;
