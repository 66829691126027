import * as React from 'react';
import styled from 'styled-components';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {useMessagingSalesClickAnalytics} from '../../../../hooks';
import {CoreTag} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';
import {MessageButtonContainer, MessageText} from '../../../styled-components';
import {OfferRecPreviewData, SendableMessageProps} from '../../../../types';

export const OfferMessageButtonContainer = styled(MessageButtonContainer)`
    max-height: 137px;
`;

const OfferMessageTextContainer = styled.div`
    overflow: hidden;
    display: flex;
    text-align: left;
    flex: 1;
    padding: 12px 16px;

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        width: calc(100% - 1px);
        background-image: linear-gradient(
            to bottom,
            rgba(${({theme}) => (theme.themeName === 'light' ? '240, 240, 245, 0' : '12, 12, 13, 0')}),
            rgba(${({theme}) => (theme.themeName === 'light' ? '255, 255, 255, 1' : '12, 12, 13, 1')}) 90%
        );
        height: 4rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

const HeaderContainer = styled.div`
    width: 100%;
    margin: 0 0 0.5rem 0;
    line-height: 1;
    display: flex;

    * + * {
        margin-left: 0.5rem;
    }
`;

const OfferCategoryTag = styled(CoreTag)<{backgroundColor: string; color: string}>`
    background-color: ${({backgroundColor}) => backgroundColor};
    color: ${({color}) => color};
    margin: 0;
`;

const ColorLine = styled.div`
    background-color: ${({theme}) =>
        theme.themeName === 'dark' ? theme.colors.brand.green : theme.colors.brand.purple};
    border-radius: 20px;
    width: 100%;
    max-height: 174px;
    max-width: 5px;
    margin-right: 0.5rem;
    z-index: 2;
`;

export const SendableOfferMessage = ({
    customerId,
    message: {category, text, isTailored},
}: SendableMessageProps & {message: OfferRecPreviewData}) => {
    const clickedWithExtras = useMessagingSalesClickAnalytics('ComposeCannedMessage');
    const theme = useTheme();

    const setInput = React.useCallback(() => {
        TimelineInputStore.setEditorText(customerId, text);
        if (isTailored) {
            clickedWithExtras({
                Type: 'TailoredOffer',
            });
        } else {
            clickedWithExtras({
                Type: 'OfferOnResolution',
                Category: category,
            });
        }
    }, [customerId, category, text]);

    return (
        <OfferMessageButtonContainer data-test-id={'MessagingSales_message-button'} onClick={setInput}>
            <OfferMessageTextContainer>
                <ColorLine />
                <div>
                    <HeaderContainer>
                        {category && (
                            <OfferCategoryTag
                                text={category[0].toUpperCase() + category.slice(1)}
                                size="s"
                                color={theme.colors.neutral.white}
                                backgroundColor={
                                    theme.themeName === 'dark' ? theme.colors.brand.green : theme.colors.brand.purple
                                }
                            />
                        )}
                    </HeaderContainer>
                    <MessageText size="m">{text}</MessageText>
                </div>
            </OfferMessageTextContainer>
        </OfferMessageButtonContainer>
    );
};

export default SendableOfferMessage;
