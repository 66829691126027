import pica from 'pica';
import {createImageTag} from './mediaUtils';

const Pica = pica();
const resizeOptions = {alpha: true};

const shouldResizeImage = (image, resizeConfiguration) =>
    image.width > resizeConfiguration.width || image.height > resizeConfiguration.height;

const calculateTargetDimensions = (imageDimensions, resizeConfiguration) => {
    let {width, height} = imageDimensions;

    if (width > resizeConfiguration.width) {
        const ratio = resizeConfiguration.width / width;
        width *= ratio;
        height *= ratio;
    }

    if (height > resizeConfiguration.height) {
        const ratio = resizeConfiguration.height / height;
        width *= ratio;
        height *= ratio;
    }

    return {width, height};
};

export const generateResizedImage = async (imageFile: File, resizeConfiguration) => {
    const imageTag = await createImageTag(imageFile);

    if (shouldResizeImage(imageTag, resizeConfiguration)) {
        return resizeImage(imageTag, resizeConfiguration, imageFile.type);
    }

    return {
        imageData: imageFile,
        targetImageDimensions: {
            width: imageTag.width,
            height: imageTag.height,
        },
    };
};

const resizeImage = async (imageTag, resizeConfiguration, mime) => {
    const targetImageDimensions = calculateTargetDimensions(imageTag, resizeConfiguration);
    const offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = targetImageDimensions.width;
    offScreenCanvas.height = targetImageDimensions.height;

    const resizedCanvas = await Pica.resize(imageTag, offScreenCanvas, resizeOptions);
    const imageData = await Pica.toBlob(resizedCanvas, mime);

    return {imageData, targetImageDimensions};
};
