import {action, observable, makeObservable} from 'mobx';
import logger from '@anywhere-expert/logging';
import {getStorage, saveToRemoteStorage} from './userStorageApi';
import {UserStorageKeys, UserStorage} from './types';

class UserStorageStore {
    private storage = new Map<UserStorageKeys, any>();

    init = async () => {
        try {
            await this.fetchStorage();
        } catch (err) {
            logger.error('timed out connecting user storage to User storage service', {err});
        }
    };

    fetchStorage = async () => {
        try {
            const results = await getStorage();
            if (results && Object.keys(results).length > 0) {
                this.updateItems(results);
            }
        } catch (err) {
            logger.warn('Failed to get keys from user storage', {
                err,
            });
        }
    };

    private updateItems = (items?: Partial<UserStorage>) => {
        if (items) {
            Object.keys(items).forEach(k => {
                this.setValue(k as UserStorageKeys, items[k], false);
            });
        }
    };

    constructor() {
        makeObservable<UserStorageStore, 'storage'>(this, {
            storage: observable,
            setValue: action,
        });
    }

    getValue<K extends UserStorageKeys>(key: K, defaultValue: UserStorage[K]): UserStorage[K] {
        if (this.storage.has(key)) {
            return this.storage.get(key);
        }
        return defaultValue;
    }

    async setValue<K extends UserStorageKeys>(key: K, value: UserStorage[K], saveToStorage: boolean = true) {
        if (!key || this.storage.get(key) === value) return;
        this.storage.set(key, value);
        try {
            if (saveToStorage) {
                await saveToRemoteStorage(key, value);
            }
        } catch (err) {
            logger.warn('Failed to save key', {
                err,
                extra: {key},
            });
        }
    }
}

export default new UserStorageStore();
