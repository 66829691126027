import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Search} from './components';
import {SidebarTabContainer, ChildrenContainer} from '@anywhere-expert/session-sidebar';
import SearchResults from './components/SearchResults';
import {TabProps} from '@anywhere-expert/session-sidebar';
import useSearchResultsBehaviors from './useSearchResultsBehaviors';
import {Spinner} from '@anywhere-expert/base-ui';
import {usePerformance, TYPE_TIMELINE_SEARCH_RESULTS_LOAD} from '@anywhere-expert/performance';
import {useTimelineSearchStore} from './useTimelineSearchStore';

const StyledChildrenContainer = styled(ChildrenContainer)`
    padding-bottom: 32px;
`;

const TimelineSearchTab = observer(({selectedSession}: TabProps) => {
    const {onSelectedMessage} = useSearchResultsBehaviors(selectedSession);
    const timelineSearchStore = useTimelineSearchStore();
    const performance = usePerformance();
    const SearchBar = <Search />;

    React.useEffect(() => {
        performance.start({
            tag: TYPE_TIMELINE_SEARCH_RESULTS_LOAD,
        });
    }, [timelineSearchStore, timelineSearchStore.searchTerm]);

    return (
        <SidebarTabContainer
            data-test-id="TimelineSearch_Tab"
            title="Search Messages"
            subTitle={SearchBar}
            ChildrenContainer={StyledChildrenContainer}
        >
            {timelineSearchStore.isLoading ? (
                <Spinner size="l" spinnerColor="inherit" />
            ) : (
                <SearchResults onMessageSelection={onSelectedMessage} />
            )}
        </SidebarTabContainer>
    );
});

export default TimelineSearchTab;
