import React, {SyntheticEvent, useState, useMemo, useCallback} from 'react';
import styled from 'styled-components';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';
import DeleteSuggestionIcon from '../../../../resources/DeleteSuggestionIcon';

const XButton = styled(CoreIconButton)`
    display: flex;
    padding: 0;
    width: 21px;
    height: 21px;
    margin-top: 8px;
`;

interface Props {
    onClick: (event: SyntheticEvent) => Promise<void>;
}

export default ({onClick}: Props) => {
    const theme = useTheme();
    const [color, setColor] = useState(theme.colors.neutral.grey.deep);
    const icon = useMemo(() => () => <DeleteSuggestionIcon color={color} />, [color]);
    const onMouseEnter = useCallback(() => setColor(theme.colors.neutral.grey.deepest), [theme]);
    const onMouseLeave = useCallback(() => setColor(theme.colors.neutral.grey.deep), [theme]);

    return (
        <XButton
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            size="s"
            icon={icon}
            variant="text"
            onClick={onClick}
        />
    );
};
