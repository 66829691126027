import {observable, action, computed, runInAction, makeObservable} from 'mobx';
import {Track, VideoTrack, AudioTrack, LocalVideoTrack, LocalAudioTrack, Room} from 'twilio-video';
export type MediaTrack = VideoTrack | AudioTrack;
export type LocalMediaTrack = LocalVideoTrack | LocalAudioTrack;
import RoomHandlers from './RoomHandlers';
import videoSessionApiClient from './videoSessionApiClient';
import logger from '@anywhere-expert/logging';

export enum VideoCallState {
    disconnected = 'disconnected',
    incomingCall = 'incomingCall',
    connected = 'connected',
    connecting = 'connecting',
    error = 'error',
}

class VideoChat {
    callState: VideoCallState = VideoCallState.disconnected;
    activeRoom?: Room;
    roomId?: string;
    roomStartTime?: number;
    roomDisconnectTime?: number;
    isMicrophoneMuted: boolean = false;
    isMyCameraOn: boolean = true;
    sessionId?: string;
    localTracks?: LocalMediaTrack[];
    externalTracks?: MediaTrack[];
    errorMessage?: string;
    showRetry?: boolean;

    roomHandlers?: RoomHandlers;
    previousCall?: {sessionId?: string; roomId?: string};

    constructor() {
        makeObservable(this, {
            callState: observable,
            activeRoom: observable,
            roomId: observable,
            roomStartTime: observable,
            roomDisconnectTime: observable,
            isMicrophoneMuted: observable,
            isMyCameraOn: observable,
            sessionId: observable,
            localTracks: observable,
            externalTracks: observable,
            errorMessage: observable,
            showRetry: observable,
            isInVideoSession: computed,
            callDuration: computed,
            setVideoCallState: action.bound,
            startMyCamera: action.bound,
            stopMyCamera: action.bound,
            roomConnected: action.bound,
            callEnded: action.bound,
            callDisconnected: action.bound,
            Mute: action.bound,
            Unmute: action.bound,
            setCallIncoming: action.bound,
            setCallConnecting: action.bound,
            isCustomerCameraOn: computed,
            isCustomerMuted: computed,
            setError: action.bound,
            clearLocalTracks: action.bound,
            removeExternalTrack: action.bound,
            addLocalTracks: action.bound,
            addExternalTracks: action.bound,
        });
    }

    get isInVideoSession() {
        return !!this.roomId;
    }

    get callDuration() {
        //TODO: Currently using the time the expert connected to the call. Need to decide if we want the room creation instead.
        if (this.callState == VideoCallState.disconnected) {
            return this.roomDisconnectTime && this.roomStartTime ? this.roomDisconnectTime - this.roomStartTime : -1;
        }
        return this.roomStartTime ? Date.now() - this.roomStartTime : -1;
    }
    setVideoCallState(callState: VideoCallState) {
        this.callState = callState;
    }

    startMyCamera() {
        this.isMyCameraOn = true;
    }

    stopMyCamera() {
        this.isMyCameraOn = false;
    }

    roomConnected(room: any) {
        this.activeRoom = room;
        this.roomStartTime = Date.now();
        this.setVideoCallState(VideoCallState.connected);
    }

    callEnded(declined?: boolean, shouldShowTimelineItem?: boolean) {
        this.callDisconnected(declined, shouldShowTimelineItem);
    }

    async callDisconnected(declined: boolean = false, shouldShowTimelineItem: boolean = true) {
        this.previousCall = {roomId: this.roomId, sessionId: this.sessionId};
        this.roomId = undefined;
        this.roomHandlers = undefined;
        this.Unmute();
        this.startMyCamera();
        this.roomDisconnectTime = Date.now();
        this.clearLocalTracks();
        this.setVideoCallState(VideoCallState.disconnected);
        try {
            await videoSessionApiClient.endVideoSession({
                sessionId: this.sessionId!,
                declined,
                shouldSendTimelineItem: shouldShowTimelineItem,
            });
        } catch (err) {
            logger.warn('Could not cancel video session in video-session-api', {
                err,
                extra: {sessionId: this.sessionId},
            });
        }
        runInAction(() => {
            this.sessionId = undefined;
        });
    }

    Mute() {
        this.activeRoom?.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable();
        });
        this.isMicrophoneMuted = true;
    }

    Unmute() {
        this.activeRoom?.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable();
        });
        this.isMicrophoneMuted = false;
    }

    setCallIncoming(sessionId: string) {
        this.sessionId = sessionId;
        this.setVideoCallState(VideoCallState.incomingCall);
    }

    setCallConnecting(roomHandlers: RoomHandlers, roomId: string) {
        if (!this.sessionId) {
            this.setCallIncoming(roomId);
        }
        this.roomId = this.sessionId;
        this.roomHandlers = roomHandlers;
    }

    get isCustomerCameraOn() {
        return this.externalTracks?.some(track => track.kind === 'video');
    }

    get isCustomerMuted() {
        return this.externalTracks?.every(track => track.kind !== 'audio');
    }

    setError(message = 'Failed to connect to call', canRetry = true) {
        this.errorMessage = message;
        this.showRetry = canRetry;
        this.setVideoCallState(VideoCallState.error);
    }

    clearLocalTracks() {
        if (!this.localTracks) return;

        this.localTracks
            .filter(x => x instanceof LocalVideoTrack || x instanceof LocalAudioTrack)
            .forEach((x: LocalMediaTrack) => x.stop());
        this.localTracks = undefined;
    }

    removeExternalTrack(track: Track) {
        if (!this.externalTracks) return;

        this.externalTracks = this.externalTracks.filter(x => track !== x);
    }

    addLocalTracks(tracks: LocalMediaTrack[]) {
        const currentTracks = this.localTracks || [];
        this.localTracks = [...currentTracks, ...tracks];
    }

    addExternalTracks(tracks: MediaTrack[]) {
        const currentTracks = this.externalTracks || [];
        this.externalTracks = [...currentTracks, ...tracks];
    }
}

export default new VideoChat();
