import styled, {css} from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {TriangleUpIcon, TriangleDownIcon} from '@anywhere-expert/icons';
export const MEDIUM_WIDTH = '1200px';

const CustomText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    line-height: 100%;
    display: block;
`;

export const SalesStatContainer = styled.div`
    flex: 1;
    margin: 0 20px;
    width: 225px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        margin-bottom: 20px;
        width: 20%;
    }
`;

export const StatContainer = styled.div`
    margin-bottom: 10px;
    display: inline-block;
`;

export const Stat = styled.div<{color: string}>`
    font-size: 60px;
    font-weight: 700;
    padding: 0 10px;
    height: 52px;
    background-color: ${({color}) => color};
    ${({theme}) => (theme.themeName === 'dark' ? `color: ${theme.colors.background.secondary};` : '')};

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 48px;
        height: 42px;
    }
`;

const styledIconProps = css`
    float: right;
    height: 15px;
    margin-top: 4px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        height: 12px;
    }
`;

export const StyledTriangleUp = styled(TriangleUpIcon)`
    ${styledIconProps}
`;

export const StyledTriangleDown = styled(TriangleDownIcon)`
    ${styledIconProps}
`;

export const Title = styled(CustomText)`
    font-size: 20px;
    margin-bottom: 12px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 18px;
    }
`;

export const Subtitle = styled(CustomText)`
    font-size: 14px;
`;
