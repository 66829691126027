import React from 'react';

const CollapseAllIcon = ({color, ...others}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33315 11.6095V13.3335H6.66648V9.3335H2.66648V10.6668H4.39048L0.861816 14.1955L1.80448 15.1382L5.33315 11.6095Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3333 5.33364H11.6093L15.1379 1.80497L14.1953 0.862305L10.6666 4.39097V2.66697H9.33325V6.66697H13.3333V5.33364Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6666 11.6095L14.1953 15.1382L15.1379 14.1955L11.6093 10.6668H13.3333V9.3335H9.33325V13.3335H10.6666V11.6095Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66673 5.33364V6.66697H6.66673V2.66697H5.33339V4.39097L1.80473 0.862305L0.862061 1.80497L4.39073 5.33364H2.66673Z"
            fill={color}
        />
    </svg>
);

export default CollapseAllIcon;
