class TweekError extends Error {
    responseText: string;
    status: number;
    url: string;

    constructor(status: number, url: string, responseText: string = '') {
        super('Tweek server returned error');
        this.responseText = responseText;
        this.status = status;
        this.url = url;
    }
}

export async function parseTweekErrorResponse(res: Response) {
    try {
        const text = await res.clone().text();

        return new TweekError(res.status, res.url, text);
    } catch (er) {
        return new TweekError(res.status, res.url);
    }
}
