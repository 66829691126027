import {CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';

export const CoreTextWithMarkdown = styled(CoreText)`
    color: ${({textColor}) => textColor};
    & .text_message_content_link {
        font-weight: normal;
        color: ${({theme}) => theme.colors.primary.blue.brighter};
        &:hover {
            text-decoration: underline;
            font-weight: 500;
            color: ${({theme}) => theme.utils.lighten(theme.colors.brand.blue, 0.2)};
        }
        &:active {
            text-decoration: underline;
            color: ${({theme}) => theme.utils.darken(theme.colors.brand.blue, 0.2)};
        }
    }
`;

export const TechMessageTemplate = styled.div<{withExtra: boolean; isGroupedItem: boolean}>`
    display: flex;
    align-self: flex-end;
    flex-shrink: 0;
    max-width: ${props => (props.withExtra ? '95%' : '90%')};
    margin-top: ${props => (props.isGroupedItem ? '.25em' : '24px')};
    margin-right: ${props => (props.isGroupedItem ? '32px' : '')};
`;

export const CustomerMessageTemplate = styled.div<{showThumbnail: boolean; isGroupedItem: boolean}>`
    max-width: '90%';
    display: 'flex';
    flex-shrink: 0;
    margin-top: ${props => (props.showThumbnail ? '24px' : '.25em')};
    margin-left: ${props => (props.isGroupedItem ? '32px' : '')};
`;
