import styled from 'styled-components';
import React, {useCallback, useMemo} from 'react';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {BoldIcon, ItalicIcon, StrikethroughIcon, UnorderListIcon, OrderedListIcon} from '@anywhere-expert/icons';
import {EditorStyleEnum, ListTypeEnum} from '../stores/RichInputStore';
import {RichInputStore} from '../stores/RichInputStore';
import {useAnalytics} from 'react-shisell';
import {observer} from 'mobx-react';
import getEnumKeyByEnumValue from '../utils/getEnumKeyByValue';
import EmojiActionMenu from './EmojiActionMenu';
import LinkTool from './LinkTool';
import {TOOL_KEY_SHORTCUT} from '../consts/editorKeyShortcuts';

const EditorButtonsContainer = styled.div`
    display: flex;
    align-self: flex-start;
    align-items: center;
    height: 44px;
`;

const iconProps = {size: 'l'};
const shadowProps = {width: '24px', height: '24px'};
const ToolIconButton = styled(CoreIconButton)`
    margin-left: 8px;
`;

const EmojiMenuButton = styled(EmojiActionMenu)`
    margin-left: 8px;
`;

const getTooltipProps = (text: string) => {
    return {
        position: 'right',
        text,
        styleOverride: {marginLeft: '6px'},
        keyboardSequence: TOOL_KEY_SHORTCUT[text] ? `ctrl+${TOOL_KEY_SHORTCUT[text]}` : undefined,
    };
};

type EditorToolbarProps = {
    richInputStore: RichInputStore;
};

const RichEditorToolbar = observer(({richInputStore}: EditorToolbarProps) => {
    const analytics = useAnalytics();
    const enhancedDispatcher = useMemo(() => analytics.dispatcher.createScoped('RichEditorToolbar'), [
        analytics.dispatcher,
    ]);

    const onRichInputInlineStyleClick = useCallback(
        (e, style: EditorStyleEnum) => {
            const isCurrentlyActive = richInputStore.stateHasInlineStyle(style);

            enhancedDispatcher
                .withExtras({
                    ActionId: `${getEnumKeyByEnumValue(EditorStyleEnum, style)}Tool`,
                    Toggle: isCurrentlyActive ? 'Off' : 'On',
                })
                .dispatch('Click');
            richInputStore.addInlineStyle(style);
            e.preventDefault();
        },
        [enhancedDispatcher]
    );

    const onRichInputBlockStyleClick = useCallback(
        (e, style: ListTypeEnum) => {
            const isCurrentlyActive = richInputStore.stateHasBlockStyle(style);

            enhancedDispatcher
                .withExtras({
                    ActionId: `${getEnumKeyByEnumValue(ListTypeEnum, style)}Tool`,
                    Toggle: isCurrentlyActive ? 'Off' : 'On',
                })
                .dispatch('Click');
            richInputStore.addBlockStyle(style);
            e.preventDefault();
        },
        [enhancedDispatcher]
    );

    return (
        <EditorButtonsContainer>
            <ToolIconButton
                variant="icon"
                size="s"
                iconProps={iconProps}
                icon={BoldIcon}
                style={shadowProps}
                tooltipProps={getTooltipProps('Bold')}
                onMouseDown={e => {
                    onRichInputInlineStyleClick(e, EditorStyleEnum.Bold);
                }}
                isActive={richInputStore.stateHasInlineStyle(EditorStyleEnum.Bold)}
            />
            <ToolIconButton
                variant="icon"
                size="s"
                iconProps={iconProps}
                icon={ItalicIcon}
                style={shadowProps}
                tooltipProps={getTooltipProps('Italic')}
                onMouseDown={e => onRichInputInlineStyleClick(e, EditorStyleEnum.Italic)}
                isActive={richInputStore.stateHasInlineStyle(EditorStyleEnum.Italic)}
            />
            <ToolIconButton
                variant="icon"
                size="s"
                iconProps={iconProps}
                icon={StrikethroughIcon}
                style={shadowProps}
                tooltipProps={getTooltipProps('Strikethrough')}
                onMouseDown={e => onRichInputInlineStyleClick(e, EditorStyleEnum.StrikeThrough)}
                isActive={richInputStore.stateHasInlineStyle(EditorStyleEnum.StrikeThrough)}
            />
            <LinkTool
                richInputStore={richInputStore}
                analyticsDispatcher={enhancedDispatcher}
                shadowProps={shadowProps}
                iconProps={iconProps}
            />
            <ToolIconButton
                variant="icon"
                size="s"
                iconProps={iconProps}
                style={shadowProps}
                icon={OrderedListIcon}
                tooltipProps={getTooltipProps('Ordered list')}
                onMouseDown={e => onRichInputBlockStyleClick(e, ListTypeEnum.OrderedList)}
                isActive={richInputStore.stateHasBlockStyle(ListTypeEnum.OrderedList)}
            />
            <ToolIconButton
                variant="icon"
                size="s"
                iconProps={iconProps}
                style={shadowProps}
                icon={UnorderListIcon}
                tooltipProps={getTooltipProps('Bulleted list')}
                onMouseDown={e => onRichInputBlockStyleClick(e, ListTypeEnum.UnOrderList)}
                isActive={richInputStore.stateHasBlockStyle(ListTypeEnum.UnOrderList)}
            />
            <EmojiMenuButton insertEmoji={richInputStore.insertEmoji} />
        </EditorButtonsContainer>
    );
});

export default RichEditorToolbar;
