const MAX_HEIGHT = 500;
const MAX_WIDTH = 350;

export const calculateTargetDimensions = (actualWidth, actualHeight, shouldRotate = false) => {
    let width = actualWidth,
        height = actualHeight;

    if (shouldRotate) {
        width = actualHeight;
        height = actualWidth;
    }

    if (width > MAX_WIDTH) {
        const ratio = MAX_WIDTH / width;
        width *= ratio;
        height *= ratio;
    }

    if (height > MAX_HEIGHT) {
        const ratio = MAX_HEIGHT / height;
        width *= ratio;
        height *= ratio;
    }

    return {width, height};
};
