declare global {
    interface Window {
        FS: any;
    }
}
const fs = window && window.FS;

const identify = (id: string, userVars: Object) => {
    fs?.identify?.(id, userVars);
};

const restart = () => {
    fs && fs.restart && fs.restart();
};
const getCurrentSessionURL = () => fs?.getCurrentSessionURL?.(true);

const isInitialized = () => !!fs;

const initalize = (id: string, userVars: Object) => {
    restart();
    identify(id, userVars);
};

const sendEvent = (name: string, properties: Object = {}) => {
    fs?.event?.(name, properties);
};

export default {
    initalize,
    isInitialized,
    getCurrentSessionURL,
    sendEvent,
};
