import React, {memo, useMemo, CSSProperties, useRef, useEffect, useState, useCallback, FC} from 'react';
import {Tag} from '../common';
import {CoreTag, CoreTooltip, CoreTooltipProps} from '@anywhere-expert/base-ui';
import {useApplyTagsConfiguration} from '@expert-feed/conversation-tags';
import styled from 'styled-components';
import {CloseCircleIcon} from '@anywhere-expert/icons';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {useTheme} from '@anywhere-expert/colors';

const StyledCloseCircleIcon = styled(CloseCircleIcon)`
    opacity: 0.4;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const getEditButtonElement = (onClick: () => void) => () => {
    const theme = useTheme();
    const color = theme.colors.neutral.black;
    return <StyledCloseCircleIcon color={color} size={'s'} onClick={onClick} />;
};

const StyledTag = styled(CoreTag)<{
    backgroundColor?: string;
    foreground?: string;
    isCustomTag: boolean;
    isTagEditable: boolean;
}>`
    background-color: ${({isCustomTag, backgroundColor}) =>
        isCustomTag ? 'unset' : backgroundColor && `${backgroundColor}`};
    ${({isCustomTag, foreground}) => foreground && !isCustomTag && `color: ${foreground};`};
    ${({isCustomTag, backgroundColor}) => isCustomTag && backgroundColor && `border: solid 1px ${backgroundColor};`};
`;

type TagElementProp = {
    tag: Tag;
    size: 's' | 'l';
    onIconClick?: (tag: Tag) => void;
    isEditable?: boolean;
    forceDarkMode?: boolean;
};

const TagElement = memo(({tag, size, onIconClick, isEditable, forceDarkMode, ...other}: TagElementProp) => {
    const {displayText, tagStyle = {}, tagType} = tag;
    const {backgroundColor, textColor} = tagStyle;
    const isCustomTag = tagType === 'customTag';
    const isTagEditable = isEditable && size === 'l' && isCustomTag && !!onIconClick;

    const icon = useMemo(() => isTagEditable && getEditButtonElement(() => onIconClick!(tag)), [
        isTagEditable,
        getEditButtonElement,
        tag,
    ]);

    return (
        <StyledTag
            text={displayText}
            size={size}
            backgroundColor={backgroundColor}
            foreground={textColor}
            isCustomTag={isCustomTag}
            isTagEditable={isTagEditable}
            Icon={icon}
            forceDarkMode={forceDarkMode}
            {...other}
        />
    );
});

const TooltipTag = styled(TagElement)`
    margin-bottom: 5px;
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 190px;
`;

const TagWithAnimation = styled(TagElement)`
    &.tag-enter {
        opacity: 0;
    }
    &.tag-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    &.tag-exit {
        opacity: 1;
    }
    &.tag-exit-active {
        opacity: 0;
        transition: opacity 200ms ease-in;
    }
`;

type TagProps = {
    tags: Tag[];
    size?: 's' | 'l';
    wrap?: boolean;
    onIconClick?: (tag: Tag) => void;
    isEditable?: boolean;
    tooltipPosition?: CoreTooltipProps['position'];
};

const tooltipStyleOverride = {padding: '8px 0 8px 8px'};

const getContainerStyle = (isWrapped: boolean): CSSProperties => {
    let style: CSSProperties = {
        display: 'flex',
        alignItems: 'flex-end',
        flex: '1 1 0%',
    };

    if (isWrapped) {
        style.flexWrap = 'wrap';
    } else {
        style.overflow = 'hidden';
    }

    return style;
};

export const Tags: FC<TagProps> = ({
    tags = [],
    size = 'l',
    wrap = false,
    onIconClick = () => ({}),
    isEditable = false,
    tooltipPosition,
}: TagProps) => {
    const configuredTags = useApplyTagsConfiguration(tags);
    const containerStyle = useMemo(() => getContainerStyle(wrap), [wrap]);
    const ref = useRef<HTMLDivElement>(null);

    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
        let tagsContainerElement = ref?.current?.firstElementChild;
        if (!tagsContainerElement) return;
        const shouldShowTooltip = tagsContainerElement.scrollWidth > tagsContainerElement.clientWidth;
        setShowTooltip(shouldShowTooltip);
    }, [tags]);

    const tooltipTags = configuredTags.map(tag => (
        <TooltipTag key={tag.tagKey} tag={tag} size={'s'} isEditable={false} forceDarkMode={true} />
    ));

    const updateShouldShowTooltip = useCallback(
        node => {
            const container = node.parentElement;
            if (!container) return;
            const shouldShowTooltip = container.scrollWidth > container.clientWidth;
            setShowTooltip(shouldShowTooltip);
        },
        [setShowTooltip]
    );

    const tagsElements = configuredTags.map((tag: Tag) => (
        <CSSTransition key={tag.tagKey} timeout={200} classNames="tag" onExited={updateShouldShowTooltip}>
            <TagWithAnimation tag={tag} size={size} onIconClick={onIconClick} isEditable={isEditable} />
        </CSSTransition>
    ));

    return (
        <CoreTooltip
            disabled={!showTooltip}
            text={<TooltipContainer>{tooltipTags}</TooltipContainer>}
            styleOverride={tooltipStyleOverride}
            enterDelay={900}
            position={tooltipPosition}
        >
            <div ref={ref} style={containerStyle}>
                <TransitionGroup style={containerStyle}>{tagsElements}</TransitionGroup>
            </div>
        </CoreTooltip>
    );
};
