import {useCallback} from 'react';
import {PrefilledTextUseCase, Options} from '../behaviors/useDefaultInputTextBehavior';
import mustache from 'mustache';
import {getUserProfile} from '@anywhere-expert/auth';
import {CannedMessagesStoreV2} from '@anywhere-expert/canned-messages-v2';
import type {AssignmentType} from '@anywhere-expert/expert-feed-store';
import {useTweekValue} from 'react-tweek';

const renderMessage = (template, user) =>
    mustache.render(template, {
        displayName: user && user.displayName,
    });

export const useGetNextAssigned = (): PrefilledTextUseCase => {
    const isDefaultGreetingEnabled = useTweekValue<boolean>(
        'support/session/messaging/automatic_first_message/default_greeting/is_enabled',
        false
    );

    const greetingMessage = useTweekValue<string>(
        'support/session/messaging/accept_conversation_bar/text/default_greeting_message',
        "Hi there, I'm {{displayName}}! I'd be happy to help."
    );

    const assignmentType = useTweekValue<AssignmentType>(
        'support/routing/assignment_strategy/assignment_type',
        'get-next'
    );

    const isAutoGreetingMessage = useTweekValue<boolean>(
        'support/routing/assignment_strategy/auto_assign/auto_greeting_message',
        false
    );

    const getInputText = useCallback(() => {
        const userProfile = getUserProfile();
        const defaultGreetingMessageText = CannedMessagesStoreV2.defaultGreetingMessage?.text;

        if (isDefaultGreetingEnabled && defaultGreetingMessageText) {
            return defaultGreetingMessageText;
        }

        return renderMessage(greetingMessage, userProfile);
    }, [greetingMessage, isDefaultGreetingEnabled]);

    const getShouldSetInputText = useCallback(
        (options: Options) => {
            const isAutoAssign = assignmentType === 'get-next' || assignmentType === 'push';
            const isAssignedToMe = options.selectedTimeline.supportItem.isAssignedToMe;
            const expertSentMessage = options.selectedTimeline.supportItem.didExpertSendMessage;
            return isAutoAssign && isAssignedToMe && !expertSentMessage;
        },
        [assignmentType]
    );

    const getIsAutoSend = useCallback(() => isAutoGreetingMessage, [isAutoGreetingMessage]);

    return {
        name: 'GetNextAssigned',
        getInputText,
        getShouldSetInputText,
        getIsAutoSend,
    };
};
