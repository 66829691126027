import firebase from 'firebase/app';
import {memoize} from 'ramda';
import {getFirebaseTokenFromHomeToken} from './firebaseTokenProvider';
import {userProfile$} from '@anywhere-expert/auth';
import logger from '@anywhere-expert/logging';
import {BehaviorSubject} from 'rxjs';
import {initFirebase as initFirebaseApp} from './initFirebase';

const firebaseProviderInitializationStatus = new BehaviorSubject(false);
let serverOffset: number;
export const firebaseProviderInitializationStatus$ = firebaseProviderInitializationStatus.asObservable();

let hasBeenInitialized: boolean = false;

type FirebaseProvider = {
    app?: firebase.app.App;
    storage?: firebase.storage.Storage;
    messaging?: firebase.messaging.Messaging;
    serverTimestamp(): number;
};

const signInWithCustomToken = async accessToken => {
    let firebaseTokens;
    try {
        firebaseTokens = await getFirebaseTokenFromHomeToken(accessToken);
    } catch (err) {
        logger.error('Error while trying to get firebase token from home token', {
            err,
        });
    }

    try {
        if (firebaseTokens) {
            await firebase.auth!().signInWithCustomToken(firebaseTokens.access_token);
        }
    } catch (err) {
        logger.error('Error while trying to sign in to firebase using firebase token', {
            err,
            extra: {firebaseToken: (firebaseTokens && firebaseTokens.access_Token) || 'no token'},
        });
    }
};

export async function initialize(accessToken: string) {
    (console as any).ignoredYellowBox = ['Setting a timer'];
    if (hasBeenInitialized) return;

    initFirebaseApp();
    await signInWithCustomToken(accessToken);

    userProfile$
        .filter(user => !!user.accessToken && !user.loginRequired)
        .map(user => user.accessToken)
        .subscribe(async token => {
            await signInWithCustomToken(token);
        });
    try {
        hasBeenInitialized = true;
        provider.app = firebase.app ? firebase.app() : undefined;
        provider.storage = firebase.storage ? firebase.storage() : undefined;
        provider.messaging = firebase.messaging ? firebase.messaging() : undefined;
        firebaseProviderInitializationStatus.next(true);
        firebase.database!()
            .ref('/.info/serverTimeOffset')
            .on('value', function (offset: any) {
                serverOffset = offset.val() || 0;
            });
    } catch (error) {
        hasBeenInitialized = false;
        logger.error('error while trying to initialize firebase provider', {err: error});
    }
}

const provider: FirebaseProvider = {
    serverTimestamp: () => {
        return Date.now() + serverOffset;
    },
};

const getApiKeysRef = () => provider.app && provider.app.database!().ref('apiKeys');

export const getApiKey: (name: string) => Promise<string> = memoize(name =>
    (getApiKeysRef() as any)
        .child(name)
        .once('value')
        .then(snapshot => snapshot.val())
);

export default provider;
