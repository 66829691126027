import React from 'react';
import {css} from 'aphrodite';
import {branch, compose} from 'recompose';
import withTechTemplate from '../behaviors/withTechTemplate';
import withCustomerTemplate from '../behaviors/withCustomerTemplate';
import {useMediaUploadCancel} from '@anywhere-expert/timeline-behaviours';
import {calculateTargetDimensions} from './calculateTargetDimensions';
import styles from '../ImageItem/imageItemStyles';
import {UploadImageItemProps} from '../ImageItem/types';
import UploadSpinnerAnimation from './Spinner';
const stopUploadIcon = require('./assets/stopUploadIcon.svg');
import {useTheme} from '@anywhere-expert/colors';

const Media: React.FunctionComponent<UploadImageItemProps> = ({
    imageDimensions: {width, height},
    contentId,
    isSelf: messageInitiatedByExpert,
    isCanceled,
    timestamp,
    timelineId,
    sessionId,
}) => {
    const targetSize = calculateTargetDimensions(width, height);
    const data = React.useMemo(() => localStorage.getItem(contentId), []);
    const theme = useTheme();
    const cancelImageUpload = useMediaUploadCancel(contentId, timelineId, sessionId, timestamp);

    if (isCanceled) {
        return null;
    }

    return (
        <div className={css(styles.imageContainer)} style={{width: targetSize.width, height: targetSize.height}}>
            <div className={css(styles.imageUploadOverlay)}>
                <UploadSpinnerAnimation />
                <div className={css(styles.stopUploadContainer)}>
                    {messageInitiatedByExpert && (
                        <img
                            alt="Click to stop uploading"
                            data-test-id="stopUploadButton"
                            src={stopUploadIcon}
                            onClick={cancelImageUpload}
                            style={{cursor: 'pointer'}}
                        />
                    )}
                </div>
            </div>
            {data && (
                <img
                    alt="Uploading media"
                    src={data}
                    className={css(styles.image, styles.imageUpload)}
                    style={{
                        height: targetSize.height,
                        width: targetSize.width,
                        border: `solid 8px ${theme.colors.neutral.grey.deep}`,
                    }}
                />
            )}
        </div>
    );
};

const enhance = compose(
    branch<{isSelf: boolean}>(props => props.isSelf, withTechTemplate as any, withCustomerTemplate as any)
);

export default enhance(Media as any);
