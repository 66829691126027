import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';

import UnknownEligibilityWarning from './UnknownEligibilityWarning';
import {ProductLink} from './product-link';
import OfferControls from './sales-offer/OfferControls';
import ManualOffer from './sales-offer/ManualOffer';
import {SalesMessages} from './sales-messages';
import EnrollmentFlow from './enrollment-flow/EnrollmentFlow';
import LinkWarning from './enrollment-flow/horizon-enrollment/LinkWarning';

const SalesToolsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
`;

const SalesTools = observer(({selectedSession}: SalesSessionProps) => {
    return (
        <SalesToolsContainer>
            <LinkWarning selectedSession={selectedSession} />
            <UnknownEligibilityWarning selectedSession={selectedSession} />
            <ProductLink selectedSession={selectedSession} />
            <OfferControls selectedSession={selectedSession} />
            <ManualOffer selectedSession={selectedSession} />
            <EnrollmentFlow selectedSession={selectedSession} />
            <SalesMessages selectedSession={selectedSession} />
        </SalesToolsContainer>
    );
});

export default SalesTools;
