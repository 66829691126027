import React from 'react';
import styled from 'styled-components';
import {CollapseAllIcon, CollapseIcon, OptionsIcon} from '../../../../resources';
import {EditIcon, DeleteIcon} from '@anywhere-expert/icons';
import Action from '../Action';
import {useTheme} from '@anywhere-expert/colors';
import {useDetectOutsideClick} from '@anywhere-expert/utils';
import {CoreIconButton} from '@anywhere-expert/base-ui';

const Container = styled.div``;

const ActionsContainer = styled.div`
    position: absolute;
    top: 40px;
    left: 30%;
    z-index: 5;
    width: 60%;
    padding: 6px 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({theme}) => theme.colors.neutral.white};
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
    border-radius: 4px;
`;

const Divider = styled.div`
    display: flex;
    width: 100%;
    height: 1px;
    background-color: ${({theme}) => theme.colors.neutral.grey.lighter};
`;

const Button = styled(CoreIconButton)`
    margin-right: 10px;
    color: ${({theme}) => theme.colors.contrast.disabled};

    &:hover {
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:active {
        opacity: 1;
    }

    &:disabled {
        border-color: ${({theme}) => theme.colors.neutral.grey.deep};
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }
`;

type Props = {
    onEdit: () => void;
    onCollapse: () => void;
    onCollapseAll?: () => void;
    onCancel: () => void;
    onDelete: () => void;
    onClick: (e: any) => void;
    open: boolean;
    isDisabled: boolean;
    areActionsEnabled: boolean;
};

const CategoryActionsModal = ({
    open,
    isDisabled,
    areActionsEnabled,
    onClick,
    onEdit,
    onCollapse,
    onCollapseAll,
    onCancel,
    onDelete,
}: Props) => {
    const wrapperRef = useDetectOutsideClick(onCancel);

    const theme = useTheme();

    return (
        <Container ref={wrapperRef}>
            <Button
                size="s"
                icon={OptionsIcon}
                onClick={onClick}
                disabled={isDisabled}
                variant="icon-fade"
                data-test-id="CannedMessages_CategoryOptionsButton"
            />
            {open && (
                <ActionsContainer>
                    {areActionsEnabled && (
                        <>
                            <Action
                                onClick={onEdit}
                                icon={<EditIcon size="s" color={theme.colors.neutral.black} />}
                                text="Edit"
                                data-test-id="CannedMessages_CategoryActions_Edit"
                            />
                            <Divider />
                        </>
                    )}
                    <Action
                        onClick={onCollapse}
                        icon={<CollapseIcon color={theme.colors.neutral.black} />}
                        text="Collapse"
                        data-test-id="CannedMessages_CategoryActions_Collapse"
                    />
                    {onCollapseAll && (
                        <Action
                            onClick={onCollapseAll}
                            icon={<CollapseAllIcon color={theme.colors.neutral.black} />}
                            text="Collapse all"
                            data-test-id="CannedMessages_CategoryActions_CollapseAll"
                        />
                    )}
                    {areActionsEnabled && (
                        <>
                            <Divider />
                            <Action
                                data-test-id="CannedMessages_CategoryActions_Delete"
                                onClick={onDelete}
                                icon={<DeleteIcon size="s" color={theme.colors.neutral.black} />}
                                text="Delete"
                            />
                        </>
                    )}
                </ActionsContainer>
            )}
        </Container>
    );
};

export default CategoryActionsModal;
