import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 3,
    maxTimeout: 2000,
};

const uploadImageToMediaService = async (
    timelineId: string,
    contentId: string,
    mediaFile: Blob,
    thumbFile: Blob
): Promise<{url: string; thumbnailUrl: string} | null> => {
    const {MediaServiceStore} = await pRetry(() => import('@anywhere-expert/media-service'), retryConfig);

    const uploadResult = await MediaServiceStore.client.uploadImageWithThumbnail(
        timelineId,
        contentId,
        mediaFile,
        thumbFile
    );

    if (!uploadResult) {
        return null;
    }

    return uploadResult;
};

export default uploadImageToMediaService;
