import React, {useCallback, FunctionComponent, useState, useRef, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {useAnalytics} from 'react-shisell';
import ResultText from './ResultText';
import ResultHeader from './ResultHeader';
import {Message} from '@soluto-private/messaging-api-client';
import {useTimelineSearchStore} from '../useTimelineSearchStore';

const Container = styled.div`
    padding-bottom: 12px;
`;
const MessageContainer = styled.div<{disabled?: boolean; selected?: boolean}>`
    flex: 1;
    flex-direction: column;
    padding-left: 16px;
    display: flex;
    position: relative;
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-radius: 8px;
    transition: opacity 0.3s;
    width: 100%;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    border: 1px solid ${({theme}) => theme.colors.contrast.border};
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 12px;
    }

    ${({disabled}) =>
        disabled &&
        css`
            opacity: 0.5;
        `};

    ${({theme, selected}) =>
        selected &&
        css`
            cursor: default;
            background: ${theme.colors.contrast.toolboxItemSelected};
        `};

    ${({theme, disabled, selected}) =>
        !disabled &&
        !selected &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

type Props = {
    id: string;
    message: Message;
    isFocused: boolean;
    onSelectMessage: (messageId: string, text: string) => void;
};

const useAnalyticsWithScope = () => {
    const analytics = useAnalytics();

    return analytics.dispatcher.createScoped('SearchResults');
};

const Result: FunctionComponent<Props> = ({id, message, onSelectMessage, isFocused}: Props) => {
    const [isHovered, setIsHovered] = useState(false);
    const dispatcher = useAnalyticsWithScope();
    const timelineSearchStore = useTimelineSearchStore();
    const {
        payload: {
            value: {text},
        },
        metadata: {senderName},
        createdAt,
    } = message;
    const ref = useRef<HTMLDivElement>(null);
    const onClick = useCallback(
        e => {
            dispatcher.withExtras({ActionId: 'ViewInTimeline'}).dispatch('Click');
            e.preventDefault();
            onSelectMessage(id, text!);
            timelineSearchStore.setFocus(id);
        },
        [id, text, dispatcher, onSelectMessage, timelineSearchStore]
    );

    const onMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    useEffect(() => {
        if (isFocused) {
            ref?.current?.focus();
        }
    }, [isFocused, ref]);

    return (
        <Container>
            <ResultHeader timestamp={createdAt} sender={senderName!} isHovered={isHovered} />
            <MessageContainer
                onClick={onClick}
                selected={isFocused}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={ref}
                tabIndex={0}
                data-test-id={`SearchResult`}
            >
                <ResultText text={text!} searchTerm={timelineSearchStore.searchTerm} />
            </MessageContainer>
        </Container>
    );
};

export default Result;
