import {FeedClient} from '@expert-feed/feed-api';
import {AnalyticsDispatcher} from 'shisell';
import uuid from 'uuid';
import {monitor} from '@anywhere-expert/monitor';
import {feedEvents$, FeedEventType} from './feedEvents';
import {FeedActions} from './types';
import {PartialSession, Session} from '@soluto-private/session-api-types';

export const createFeedActions = (
    feedClient: FeedClient,
    expertId: string,
    dispatcher: AnalyticsDispatcher
): FeedActions => ({
    feedClient,
    updateFeedItemName: async ({feedItemId, name}) => {
        await feedClient.expertQueuesApi.addAttributes(
            expertId,
            feedItemId,
            [
                {
                    type: 'meta',
                    key: 'name',
                    fields: {
                        text: name,
                    },
                },
            ],
            uuid()
        );
    },
    resolveFeedItem: async ({feedItemId}) => {
        const start = Date.now();
        await feedClient.sessionApi.resolve(feedItemId, {
            meta: createMeta(),
        });
        feedEvents$.next({type: FeedEventType.Resolve, feedItemId: feedItemId});

        const elapsed = Date.now() - start;

        dispatcher
            .withIdentity('SessionId', feedItemId)
            .withExtras({ElapsedTime: elapsed})
            .dispatch('SessionResolveComplete');
        monitor.gauge('support_routing_queueView_sessionResolveComplete', elapsed);
    },
    clearSnoozeIndication: async ({feedItemId}) => {
        await feedClient.expertQueuesApi.removeAttributes(
            expertId,
            feedItemId,
            {type: 'snooze', key: 'snooze'},
            uuid()
        );

        return true;
    },
    assignFeedItem: async ({feedItemId}, source = 'select') => {
        const start = Date.now();
        await feedClient.sessionApi.assign(feedItemId, expertId, {
            meta: createMeta(),
        });

        const elapsed = Date.now() - start;
        dispatcher
            .withIdentity('SessionId', feedItemId)
            .withExtras({ElapsedTime: elapsed, Type: source})
            .dispatch('SessionAssignComplete');
        monitor.gauge('support_routing_queueView_sessionAssignComplete', elapsed);
    },
    unassignFeedItem: async ({feedItemId}) => {
        await feedClient.sessionApi.unassign(feedItemId, {
            payload: {},
            meta: createMeta(),
        });
    },
    unassignAll: async ({feedItemId}) => {
        await feedClient.sessionApi.unassign(feedItemId, {
            payload: {},
            meta: createMeta(),
        });
    },
    escalateFeedItem: async ({feedItemId, message, attributes, attributesToRemove}) => {
        await feedClient.sessionApi.unassign(feedItemId, {
            payload: {
                attributes,
                attributesToRemove,
                expertId,
                reason: {
                    escalation: {message},
                },
            },
            meta: createMeta(),
        });
    },
    removeFeedItem: async ({supportItemId}) => {
        await feedClient.expertQueuesApi.deleteQueueItem(expertId, supportItemId);

        return true;
    },
    getSession: sessionId => feedClient.sessionApi.get(sessionId) as Promise<Session>,
    addQueueItemAttributes: async ({attributes, expertId, feedItemId}) => {
        await feedClient.expertQueuesApi.addAttributes(expertId, feedItemId, attributes, uuid());
    },
    removeQueueItemAttributes: async ({expertId, feedItemId, attribute}) => {
        await feedClient.expertQueuesApi.removeAttributes(expertId, feedItemId, attribute, uuid());
    },
    getAssignedItemsCount: async ({expertId}) => {
        const {result} = await feedClient.expertQueuesApi.getAssignedItemsCount(expertId);
        return result;
    },
    getResolvedSessions: ({expertId}) =>
        feedClient.sessionApi.getAll(
            ['resolved'],
            undefined,
            undefined,
            [expertId],
            undefined,
            undefined,
            -1,
            200,
            'statusChangeTime,id'
        ) as Promise<PartialSession[]>,
});

const createMeta = () => ({
    correlationId: uuid(),
    startTimestampByService: {
        'anywhere-expert-web': Date.now(),
    },
});
