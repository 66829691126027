import {useTweekValue} from 'react-tweek';
import {OfferSelection} from '../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {useCurrentOffer} from '.';

export const useEnrollmentFlow = (sessionId: string) => {
    const isEnrollmentFlowEnabled = useEnrollmentFlowFeatureToggle();

    const {
        offer: {offerSelection, offerType},
    } = useCurrentOffer(sessionId)!;

    const isCurrentOfferAccepted = offerSelection === OfferSelection.OfferAccepted;
    const isOfferDetected = offerType !== undefined;

    return isEnrollmentFlowEnabled && (isCurrentOfferAccepted || isOfferDetected);
};

export const useEnrollmentFlowFeatureToggle = () =>
    useTweekValue<boolean>(MessagingSalesTweekKeys.IsSalesToolsEnrollmentFlowEnabled, false);
