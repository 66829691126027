import {AttributesByKey, Attribute, AttributeFields, AttributesByType} from '@soluto-private/session-api-types';

export function extractFirstKeyFromAttributeByKey(attributeByKey?: AttributesByKey): Attribute | undefined {
    if (!attributeByKey) return undefined;

    const attributes = Object.values(attributeByKey);

    return attributes.length > 0 ? attributes[0] : undefined;
}

export function extractAttribute(attributeByType: AttributesByType, type: string, key?: string): Attribute | undefined {
    if (!attributeByType || !attributeByType[type]) return undefined;

    const attributeByKey = attributeByType[type];

    if (key) {
        return attributeByKey && attributeByKey[key];
    }

    return extractFirstKeyFromAttributeByKey(attributeByKey);
}

export function extractAttributeFields(attributeByType: AttributesByType, type: string, key?: string): AttributeFields {
    const attribute = extractAttribute(attributeByType, type, key);

    return attribute && attribute.fields ? attribute.fields : {};
}
