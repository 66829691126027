import React, {SyntheticEvent, useCallback, useState, useMemo} from 'react';
import styled from 'styled-components';
import {CoreButton} from '@anywhere-expert/base-ui';
import {useDetectOutsideClick} from '@anywhere-expert/utils';
import {useTheme} from '@anywhere-expert/colors';
import {Category} from '@soluto-private/canned-messages-api-client';
import MessageActionsModal from './MessageActionsModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import SuggestionsCategoryIcon from '../../../../../resources/SuggestionsCategoryIcon';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    margin-left: auto;
    justify-content: center;
`;

const SaveButtonContainer = styled(CoreButton)`
    display: flex;
    width: fit-content;
    bottom: 1px;
    right: 0px;
    padding: 0;
    padding: 10px;
    color: ${({theme}) => theme.colors.brand.purple};

    &:disabled {
        border-color: ${({theme}) => theme.colors.brand.purple};
        color: ${({theme}) => theme.colors.brand.purple};
    }
`;

interface Props {
    onSave: (categoryId: string, categoryName: string) => Promise<void>;
}

export const sleep = (time: number) => new Promise(res => setTimeout(res, time));

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
`;

const Spinner = () => {
    const theme = useTheme();
    const spinnerStyle = useMemo(() => ({color: theme.colors.brand.purple, opacity: 1}), [theme]);

    return (
        <SpinnerContainer>
            <CircularProgress size={16} thickness={4} variant="indeterminate" style={spinnerStyle} />
        </SpinnerContainer>
    );
};

const SaveButton = ({onSave}: Props) => {
    const [shouldShowOptionsIcons, setShouldShowOptionsIcons] = useState(false);
    const onCancel = useCallback(() => setShouldShowOptionsIcons(false), [setShouldShowOptionsIcons]);
    const wrapperRef = useDetectOutsideClick(onCancel);
    const [isSaving, setIsSaving] = useState(false);
    const [savingCategory, setSavingCategory] = useState<string | undefined>();
    const theme = useTheme();

    const handleOnSave = useCallback(
        async (categoryId: string, category: Category) => {
            try {
                setIsSaving(true);
                setSavingCategory(`${category.title} ${category.icon}`);
                await sleep(1500);
                await onSave(categoryId, category.title);
            } finally {
                setIsSaving(false);
                setShouldShowOptionsIcons(false);
                setSavingCategory(undefined);
            }
        },
        [onSave]
    );

    const onContainerClick = useCallback((ev: SyntheticEvent) => {
        ev.stopPropagation();
        setShouldShowOptionsIcons(true);
    }, []);

    return (
        <Container ref={wrapperRef}>
            <SaveButtonContainer
                text={`Save In ${isSaving ? savingCategory : '...'}`}
                icon={() => (isSaving ? <Spinner /> : <SuggestionsCategoryIcon color={theme.colors.brand.purple} />)}
                iconProps={isSaving ? {side: 'left'} : {side: 'right'}}
                variant="outlined"
                size="s"
                color="primary"
                disabled={isSaving}
                onClick={onContainerClick}
            />
            {shouldShowOptionsIcons && !savingCategory && (
                <MessageActionsModal onClick={handleOnSave} onCancel={onCancel} open={shouldShowOptionsIcons} />
            )}
        </Container>
    );
};
export default SaveButton;
