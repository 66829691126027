import React from 'react';
import styled from 'styled-components';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import {setAppMessage} from '@anywhere-expert/app-messages';

export const AttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
`;

export const AttributeName = styled(CoreText).attrs({
    size: 's',
    textType: 'primary',
    weight: 'bold',
})`
    margin-bottom: 1px;
    cursor: default;
    user-select: none;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
`;

export const SessionAttributesContainer = styled.div`
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 222px);
    grid-row-gap: 16px;
    &:empty {
        display: none;
    }
`;

const CopyButton = styled(CoreButton)`
    min-width: unset;
    padding: 0px 8px;
`;

export type AttributeProps = {
    name: string;
    value?: string | null;
    defaultValue: string;
    disableTextOverflow?: boolean;
    dataPii?: boolean;
    enableCopy?: boolean;
};

export default ({name, value, defaultValue, dataPii, enableCopy = true}: AttributeProps) => {
    const finalValue = React.useMemo(() => (!!value ? value : defaultValue), [value, defaultValue]);
    const usingDefaultValue = !value;
    const [isHover, setIsHover] = React.useState(false);
    const onMouseEnter = React.useCallback(() => setIsHover(true), [setIsHover]);
    const onMouseLeave = React.useCallback(() => setIsHover(false), [setIsHover]);
    const onCopyClick = React.useCallback(async () => {
        await window.navigator?.clipboard.writeText(finalValue);
        setAppMessage({text: 'Copied to clipboard'});
        setIsHover(false);
    }, [finalValue, setIsHover]);
    const cleanName = React.useMemo(() => name.replace(/ /g, ''), [name]);

    return (
        <AttributeContainer
            data-test-id={`SessionDetails_Attribute_${cleanName}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <AttributeName>{name}</AttributeName>
            <Content>
                <CoreText
                    size="m"
                    textType="secondary"
                    title={finalValue}
                    disableTextOverflow={true}
                    data-pii={!!dataPii}
                >
                    {finalValue}
                </CoreText>
                {enableCopy && !usingDefaultValue && isHover && (
                    <CopyButton text="Copy" variant="text" onClick={onCopyClick} color="tertiary" size="s" />
                )}
            </Content>
        </AttributeContainer>
    );
};
