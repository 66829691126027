import * as React from 'react';
import {CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import SalesMessageList from './SalesMessageList';
import {useTweekValue} from 'react-tweek';
import {SalesMessageSectionProps} from '../../../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Title = styled(CoreText)`
    padding-bottom: 5px;
`;

const SalesMessageSection = ({customerId, title, messages, dataTestId}: SalesMessageSectionProps) => {
    const hasMessages = messages.length > 0;
    const selectionFlowEnabled = useTweekValue<boolean>(MessagingSalesTweekKeys.IsSalesOfferEnabled, false);

    if (!hasMessages || !selectionFlowEnabled) {
        return null;
    }

    return (
        <Container data-test-id={`MessagingSales_${dataTestId}-panel`}>
            <Title size="m" weight="medium" data-test-id={`MessagingSales_${dataTestId}_title`}>
                {title}
            </Title>
            <SalesMessageList customerId={customerId} messages={messages} dataTestId={dataTestId} />
        </Container>
    );
};

export default SalesMessageSection;
