import React from 'react';
import {SalesStatContainer, StatContainer, Stat, Title, Subtitle} from '../styled-components';
import {useTweekValue} from 'react-tweek';
import {OfferRateProps} from '../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import useMessagingSalesColors, {MessagingSalesColors} from '../../hooks/useMessagingSalesColors';

const getColor = (colors: MessagingSalesColors, currentWeekOfferRate: number, offerRateThreshold: number): string => {
    if (currentWeekOfferRate >= offerRateThreshold) {
        return colors.tennis;
    }

    return colors.lightRed;
};

const getOfferRateSubtitle = (currentWeekOfferRate: number, offerRateThreshold: number): string => {
    const lobbyOfferRateSubtitleNoOffers = useTweekValue(
        MessagingSalesTweekKeys.LobbyOfferRateSubtitleNoOffers,
        'Help your first customer for the week.'
    );
    const lobbyOfferRateSubtitleUnderThreshold = useTweekValue(
        MessagingSalesTweekKeys.LobbyOfferRateSubtitleUnderThreshold,
        "Your offer percentage is below the goal. Let's offer more."
    );
    const lobbyOfferRateSubtitleAboveThreshold = useTweekValue(
        MessagingSalesTweekKeys.LobbyOfferRateSubtitleAboveThreshold,
        'Great job on the offers! More offers, more sales!'
    );

    if (currentWeekOfferRate === 0) {
        return lobbyOfferRateSubtitleNoOffers;
    }

    if (currentWeekOfferRate < offerRateThreshold) {
        return lobbyOfferRateSubtitleUnderThreshold;
    }

    return lobbyOfferRateSubtitleAboveThreshold;
};

const OfferRate = ({currentWeekOfferRate}: OfferRateProps) => {
    const colors = useMessagingSalesColors();
    const offerRateThreshold = useTweekValue(MessagingSalesTweekKeys.OfferRateThreshold, 50);
    const offerRateTitle = useTweekValue(MessagingSalesTweekKeys.LobbyOfferRateTitle, 'Offers Rate');
    const offerRateSubtitle = getOfferRateSubtitle(currentWeekOfferRate, offerRateThreshold);
    const color = getColor(colors, currentWeekOfferRate, offerRateThreshold);

    return (
        <SalesStatContainer>
            <StatContainer>
                <Stat color={color}>{currentWeekOfferRate}%</Stat>
            </StatContainer>
            <Title>{offerRateTitle}</Title>
            <Subtitle>{offerRateSubtitle}</Subtitle>
        </SalesStatContainer>
    );
};

export default OfferRate;
