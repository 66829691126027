import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {AlertTriangleIcon} from '@anywhere-expert/icons';
import {useTweekValue} from 'react-tweek';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {useMessagingSalesEligibilityEnablement} from '../../../hooks';
import {getEligibility} from '../../../utils/helpers';

const Container = styled.div`
    border: ${({theme}) => `1px solid ${theme.colors.contrast.border}`};
    border-radius: 8px;
    padding: 8px 12px;
    margin-bottom: 10px;
`;

const UnknownEligibilityWarning = observer(({selectedSession}: SalesSessionProps) => {
    const isUnknownEligibilityEnabled = useMessagingSalesEligibilityEnablement();
    const warningText = useTweekValue(MessagingSalesTweekKeys.UnknownEligibilityWarning, '');
    const isEligible = getEligibility(selectedSession);

    if (!isUnknownEligibilityEnabled || isEligible !== null) {
        return null;
    }

    return (
        <Container data-test-id="MessagingSales_unknown-eligibility">
            <AlertTriangleIcon style={{marginRight: '6px'}} size="s" color="#FFC02F" />
            <CoreText style={{display: 'inline'}} size="m">
                {warningText}
            </CoreText>
        </Container>
    );
});

export default UnknownEligibilityWarning;
