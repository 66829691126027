import {observable, action, computed, makeObservable} from 'mobx';
import {createTransformer} from 'mobx-utils';
import CannedMessagesStoreV2 from './CannedMessagesStoreV2';
import {ExpertCannedMessages} from '@soluto-private/canned-messages-api-client';
import escapeRegExp from 'lodash.escaperegexp';
import {getMostFrequentlyUsedCategoryData, CalculatedCategory} from '@anywhere-expert/canned-messages-v2';
export default class CannedMessagesNavigationStore {
    focusedIndex: number = -2;
    searchedText: string = '';
    collapsedCategories: Record<string, boolean> = {};
    private static mostFrequentlyUsedCategoryData: CalculatedCategory;

    static async init() {
        CannedMessagesNavigationStore.mostFrequentlyUsedCategoryData = await getMostFrequentlyUsedCategoryData();
    }

    constructor() {
        makeObservable(this, {
            focusedIndex: observable,
            searchedText: observable,
            collapsedCategories: observable,
            isSearchMode: computed,
            setSearchText: action,
            keydownPressed: action,
            keyupPressed: action,
            reset: action,
            removeFocus: action.bound,
            collapse: action,
            collapseAll: action.bound,
            isFocused: computed,
            filteredMessages: computed,
            filteredMessagesFlat: computed,
            isNoMatches: computed,
        });
    }

    get isSearchMode() {
        return !!this.searchedText;
    }

    setSearchText(text: string) {
        this.searchedText = text;
    }

    keydownPressed() {
        if (this.focusedIndex === -2) return;

        if (this.focusedIndex === -1 && !this.filteredMessagesFlat.length) {
            for (const categoryId of Object.keys(this.collapsedCategories)) {
                this.collapsedCategories[categoryId] = false;
            }
        }
        this.focusedIndex = Math.min(this.focusedIndex + 1, this.filteredMessagesFlat.length - 1);
    }

    keyupPressed() {
        if (this.focusedIndex === -2) return;
        this.focusedIndex = Math.max(this.focusedIndex - 1, -1);
    }

    reset() {
        if (this.focusedIndex !== -1) this.focusedIndex = -1;
    }

    removeFocus() {
        if (this.focusedIndex !== -2) this.focusedIndex = -2;
    }

    collapse(categoryId, isCollapsed) {
        this.collapsedCategories[categoryId] = isCollapsed;
        this.reset();
    }

    collapseAll() {
        for (const categoryId of Object.keys(this.collapsedCategories)) {
            this.collapsedCategories[categoryId] = true;
        }
        this.reset();
    }

    get isFocused() {
        return createTransformer(({messageId, categoryId}) => {
            const message = this.filteredMessagesFlat[this.focusedIndex];
            return this.focusedIndex > -1 && message?.messageId === messageId && message?.categoryId === categoryId;
        });
    }

    get filteredMessages(): ExpertCannedMessages {
        const matchesFilter = new RegExp(escapeRegExp(this.searchedText), 'i');
        return CannedMessagesStoreV2.sortedCategories.reduce((categoryAccumalator, [categoryId, category]) => {
            const filteredMessages = category.messages.filter(
                ([, {title, text}]) => matchesFilter.test(text) || (title && matchesFilter.test(title))
            );

            return filteredMessages.length
                ? {
                      ...categoryAccumalator,
                      [categoryId]: {
                          ...category,
                          messages: filteredMessages.reduce(
                              (messageAccumalator, [messageId, message]) => ({
                                  ...messageAccumalator,
                                  [messageId]: message,
                              }),
                              {}
                          ),
                      },
                  }
                : categoryAccumalator;
        }, {}) as ExpertCannedMessages;
    }

    get filteredMessagesFlat() {
        const flattedMessages = Object.entries(this.filteredMessages)
            .filter(([categoryId]) => !this.collapsedCategories[categoryId])
            .reduce(
                (accumalator, [categoryId, {messages}]) => [
                    ...accumalator,
                    ...Object.keys(messages).map(messageId => ({messageId, categoryId})),
                ],
                []
            );

        if (!CannedMessagesNavigationStore.mostFrequentlyUsedCategoryData) {
            return flattedMessages;
        }

        if (
            !this.collapsedCategories[CannedMessagesNavigationStore.mostFrequentlyUsedCategoryData.id] &&
            !this.searchedText
        ) {
            return [...CannedMessagesStoreV2.flatedMostFrequentlyUsedMessages, ...flattedMessages];
        }

        return flattedMessages;
    }

    get isNoMatches() {
        return this.searchedText && !Object.keys(this.filteredMessages).length;
    }
}
