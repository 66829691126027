import {action, computed, observable, runInAction, makeObservable} from 'mobx';
import {RecommendationsStore} from '@anywhere-expert/recommendations';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {getFromTweekForSupportItemWithDefault} from '@anywhere-expert/tweek';
import {TimelineConnectionStatus} from './types';
import {SessionDetailsModel} from '@anywhere-expert/session-details';
import {CobrowsingStore} from '@anywhere-expert/cobrowsing';
import {MessagingStore, RoomStore} from '@anywhere-expert/messaging-api';
import {CreateMessagePayload, Message} from '@soluto-private/messaging-api-client';
import {EnhancedMessage} from '../messaging-api';
import {ScreenshareSessionManager, extractDeviceScreenshareSupportInfoFromSession} from '@anywhere-expert/screenshare';

export default class TimelineModel {
    supportItem: SupportItem;
    recommendationsStore: RecommendationsStore | undefined;
    timelineId: string;
    roomStore: RoomStore;
    timelineDetails: SessionDetailsModel;
    cobrowsingStore: CobrowsingStore | undefined;
    screenshareSessionManager: ScreenshareSessionManager | undefined;

    constructor(timelineId: string, supportItem: SupportItem) {
        makeObservable<TimelineModel, 'initializeCobrowsing'>(this, {
            cobrowsingStore: observable.ref,
            isInitialLoaded: computed,
            messages: computed,
            sessionExpertMessages: computed,
            isNewlyAssignedSession: computed,
            currentSessionMessages: computed,
            isLastMessageFromExpert: computed,
            lastMessage: computed,
            lastMessageVisibleToExpert: computed,
            initializeCobrowsing: action,
            connect: action,
            disconnect: action,
            connectionStatus: computed,
            deleteMessage: action,
            updateMessage: action,
            createMessage: action,
            createLocalMessage: action,
            removeLocalMessage: action,
            typing: action,
            isTyping: computed,
            screenshareSessionManager: observable.ref,
            deviceScreenshareSupportInfo: computed,
            resendMessage: action,
            requestSnapshot: action,
        });

        this.timelineId = timelineId;
        this.supportItem = supportItem;
        this.timelineDetails = new SessionDetailsModel(supportItem);
        this.timelineId = timelineId;

        this.roomStore = new RoomStore(this.timelineId);
        this.recommendationsStore = new RecommendationsStore(this);

        this.initializeCobrowsing();
    }

    get isInitialLoaded() {
        return this.roomStore.isFirstItemsLoaded;
    }

    get isRecommendationsEnabled() {
        return !!this.recommendationsStore;
    }

    get isRecommendationsHidden() {
        if (this.cobrowsingStore?.isCobrowsingOn) {
            return true;
        }

        return !this.isRecommendationsEnabled;
    }

    get messages(): Message[] {
        return this.roomStore.items.sort((a, b) => a.createdAt - b.createdAt);
    }

    get sessionExpertMessages() {
        return this.currentSessionMessages.filter(
            item => item.senderId === this.supportItem.store.user.uid && item.metadata.senderType === 'Technician'
        );
    }

    get isNewlyAssignedSession() {
        if (!this.supportItem.isAssignedToMe) return false;
        if (this.connectionStatus !== TimelineConnectionStatus.Connected) return false;
        if (!this.roomStore?.isFirstItemsLoaded) return false;
        return (
            !this.sessionExpertMessages.length ||
            this.sessionExpertMessages.every(item => item.metadata?.['autoResponseType'] === 'AutoGreeting')
        );
    }

    get currentSessionMessages(): Message[] {
        return this.messages.filter(item => item.createdAt >= this.supportItem.creationTime.getTime());
    }

    get lastMessage(): Message {
        return this.messages[this.messages.length - 1];
    }

    get lastMessageVisibleToExpert(): Message {
        let i = 1;
        let msg: Message = this.messages[this.messages.length - i];
        while (
            !!msg &&
            !!msg.metadata.visibleOnlyBy &&
            !msg.metadata.visibleOnlyBy.some(item => item.userType === 'Technician') &&
            msg.lastState !== 'hidden'
        ) {
            i++;
            msg = this.messages[this.messages.length - i];
        }
        return msg;
    }

    get isLastMessageFromExpert(): boolean {
        const lastMessage = this.lastMessage;

        if (!lastMessage) return false;

        return (
            lastMessage.senderId === this.supportItem.store.user.uid && lastMessage.metadata.senderType === 'Technician'
        );
    }

    private async initializeCobrowsing() {
        const isCobrowsingEnabled = await getFromTweekForSupportItemWithDefault(
            'support/session/messaging/tools/cobrowsing/is_enabled',
            this.timelineId,
            this.supportItem.sessionId,
            this.supportItem.pool,
            false,
            this.supportItem.sessionAttributes
        );
        if (!isCobrowsingEnabled) return;

        runInAction(() => {
            this.cobrowsingStore = new CobrowsingStore(this);
        });
    }

    connect = async () => {
        this.recommendationsStore?.connect();
    };

    disconnect = () => {
        this.recommendationsStore?.disconnect();
    };

    get connectionStatus() {
        return MessagingStore.connected ? TimelineConnectionStatus.Connected : TimelineConnectionStatus.Disconnected;
    }

    async deleteMessage(messageId: string) {
        return this.roomStore.deleteMessage(messageId);
    }

    async updateMessage(messageId: string, updates: Record<string, any>) {
        return this.roomStore.updateMessage(messageId, updates);
    }

    async createMessage(message: CreateMessagePayload) {
        return this.roomStore.createMessage(message);
    }

    createLocalMessage(
        message: Partial<EnhancedMessage> & Pick<EnhancedMessage, 'payload' | 'messageId' | 'senderId' | 'metadata'>
    ) {
        return this.roomStore.createLocalMessage(message);
    }

    removeLocalMessage(messageId: string) {
        return this.roomStore.removeLocalMessage(messageId);
    }

    async typing(typing: boolean) {
        await this.roomStore.typing(typing);
    }

    get isTyping() {
        return this.roomStore.isTyping;
    }

    get deviceScreenshareSupportInfo() {
        return (
            this.screenshareSessionManager?.deviceSupportInfo ??
            extractDeviceScreenshareSupportInfoFromSession(this.supportItem)
        );
    }

    resendMessage(messageId: string) {
        return this.roomStore.resendMessage(messageId);
    }

    requestSnapshot() {
        return this.roomStore.requestInitialSnapshot();
    }

    dtor() {
        this.disconnect();
        this.roomStore.dispose();
        this.cobrowsingStore?.dispose();
        this.screenshareSessionManager?.dispose();
    }
}
