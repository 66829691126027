import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import {EnrollmentFlowStep} from '../../../../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {useCurrentOffer, useMessagingSalesClickAnalytics, useEnrollmentFlowContent} from '../../../../../hooks';
import {CoreTextArea} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {TabProps as SendDisclosureProps} from '@anywhere-expert/session-sidebar';
import {useSendMessage} from '@anywhere-expert/timeline-behaviours';
import {CoreText} from '@anywhere-expert/base-ui';
import {NextButton} from '../../../../styled-components';

const DisclosureContainer = styled(CoreTextArea)`
    width: 95%;
`;

export const SendDisclosure = observer(({selectedSession}: SendDisclosureProps) => {
    const disclosure = useTweekValue(MessagingSalesTweekKeys.Conclusions, [''])[0];
    const {title, buttonText} = useEnrollmentFlowContent(EnrollmentFlowStep.SendDisclosure);

    const {sessionId, customerId, pool: partner} = selectedSession;
    const currentOffer = useCurrentOffer(sessionId);
    const sendMessage = useSendMessage(customerId, sessionId, partner);
    const clickedWithExtras = useMessagingSalesClickAnalytics('SendDisclosure');
    const [isSendingDisclosure, setIsSendingDisclosure] = useState(false);

    const onNextClick = async () => {
        clickedWithExtras({});
        setIsSendingDisclosure(true);
        await currentOffer?.submitDisclosure(async () => {
            await sendMessage(disclosure);
            setIsSendingDisclosure(false);
        });
    };

    return (
        <div data-test-id="MessagingSales_EnrollmentFlow_SendDisclosure">
            <CoreText size="l" weight="medium">
                {title}
            </CoreText>
            <DisclosureContainer data-test-id="DisclosureArea" value={disclosure} isInputValid={true} disabled={true} />
            <NextButton
                data-test-id="MessagingSales_EnrollmentFlow_NextButton"
                color="primary"
                onClick={onNextClick}
                text={buttonText}
                isInProgress={isSendingDisclosure}
            />
        </div>
    );
});

export default SendDisclosure;
