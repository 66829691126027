import {
    ExpertCannedMessages,
    Category,
    CannedMessage,
    ExpertCannedMessagesMetadata,
} from '@soluto-private/canned-messages-api-client';
import {v4 as uuid} from 'uuid';
import {getFromTweek} from '@anywhere-expert/tweek';
import {buildMessage} from './utils';

type DefaultMessage = Omit<CannedMessage, 'usage'>;

type DefaultCategory = Omit<Category, 'messages'> & {
    messages: DefaultMessage[];
};

const getDefaultsFromTweek = async (): Promise<DefaultCategory[]> => {
    const defaultsLink: string = await getFromTweek('support/session/canned_message/defaults_link');

    if (!defaultsLink) {
        return [];
    }

    const response = await fetch(defaultsLink);

    return response.json();
};

export default async (expertId: string, displayName: string): Promise<ExpertCannedMessages> => {
    const defaults = await getDefaultsFromTweek();

    let metadata: ExpertCannedMessagesMetadata = {};

    const newCategories: Record<string, Category> = Object.fromEntries(
        defaults.map(({messages, ...rest}) => {
            const category: Category = {
                ...rest,
                messages: Object.fromEntries(
                    messages.map(message => {
                        const messageId = uuid();

                        if (message.tags.find(tag => tag === 'default')) {
                            metadata.defaultGreeting = messageId;
                        }

                        const cannedMessage: CannedMessage = {
                            ...message,
                            text: buildMessage(message.text, displayName),
                            usage: 0,
                        };
                        return [messageId, cannedMessage];
                    })
                ),
            };

            return [uuid(), category];
        })
    );

    return {
        expertId,
        metadata,
        categories: newCategories,
    };
};
