import {CoreIconButton, CoreText} from '@anywhere-expert/base-ui';
import {CloseIcon} from '@anywhere-expert/icons';
import React from 'react';
import styled from 'styled-components';

const SnackbarContainer = styled.div<{color: string}>`
    position: relative;
    width: 100%;
    background: ${({color}) => color};
    border-radius: 8px;
    padding: 16px;

    display: flex;
    align-items: center;
    gap: 12px;
`;

const SnackbarText = styled(CoreText)<{color: string}>`
    color: ${({color}) => color};
`;

const IconContainer = styled.div`
    min-width: fit-content;
    display: flex;
`;

export interface SnackbarProps {
    text: string;
    icon: JSX.Element;
    textColor: string;
    backgroundColor: string;
    onClose?: VoidFunction;
}

const Snackbar = ({text, icon, textColor, backgroundColor, onClose}: SnackbarProps) => {
    return (
        <SnackbarContainer color={backgroundColor}>
            <IconContainer>{icon}</IconContainer>
            <SnackbarText color={textColor}>{text}</SnackbarText>
            {onClose && (
                <CoreIconButton
                    icon={CloseIcon}
                    onClick={onClose}
                    variant="icon"
                    size="xs"
                    style={{color: textColor, alignSelf: 'start'}}
                />
            )}
        </SnackbarContainer>
    );
};

export default Snackbar;
