import React, {memo} from 'react';
import {CoreText} from '@anywhere-expert/base-ui';
import {UserIcon, HelixIcon, IconProps} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';
import styled from 'styled-components';
import {ArticleType as HelixArticleType} from '../types';

type ArticleType = {
    title: string;
    Icon: React.ComponentType<IconProps>;
};

export const getArticleType: (type: HelixArticleType) => ArticleType = (type: HelixArticleType) => {
    return type === 'content' ? {title: 'Helix', Icon: HelixIcon} : {title: 'Community', Icon: UserIcon};
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
`;

const Title = styled(CoreText)`
    margin-left: 4px;
`;

const ArticleTypeComp = memo(({type}: {type: HelixArticleType}) => {
    const theme = useTheme();
    const {title, Icon} = getArticleType(type);

    return (
        <Container>
            <Icon color={theme.colors.contrast.caption} size="s" />
            <Title textType="tertiary">{title}</Title>
        </Container>
    );
});

export default ArticleTypeComp;
