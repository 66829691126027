import QueueItem from '../models/QueueItem';
import {partition} from 'ramda';

export const sortFeedItemsByRank = (feedItems: QueueItem[]) => feedItems.sort(compareByRank);

export const compareByRank = (a: QueueItem, b: QueueItem) => (a.rank > b.rank ? -1 : 1);

export const orderGroupsByPredicates = (feedItems: QueueItem[], predicates: ((model: QueueItem) => boolean)[]) => {
    let tempFeedItems = feedItems;
    let res: QueueItem[] = [];
    predicates.forEach(predicate => {
        const partitionedItems = partition(predicate, tempFeedItems);
        res = res.concat(partitionedItems[0]);
        tempFeedItems = partitionedItems[1];
    });
    res = res.concat(tempFeedItems);
    return res;
};
