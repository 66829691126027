import React, {useCallback} from 'react';
import styled from 'styled-components';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {CoreText} from '@anywhere-expert/base-ui';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {useMessagingSalesClickAnalytics} from '../../../../hooks';
import {useTweekValue} from 'react-tweek';

export const LabeledLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const Label = styled(CoreText)`
    margin-bottom: 1px;
    cursor: default;
    user-select: none;
`;

const Container = styled.div`
    font-size: 14px;
    font-family: Roboto;
    cursor: pointer;
    text-decoration: underline;
    color: ${({theme}) => theme.colors.contrast.secondary};
    line-height: 20px;
    &:hover {
        text-decoration: underline;
        font-weight: 500;
    }
`;

type CopyProductLinkProps = {
    url: string;
    label: string;
};

const getCopiedTextToast = (whatWasCopied: string) => `${whatWasCopied} copied to clipboard!`;

export const copyProductLinkHandler = (url: string, label: string) => {
    const handler = (event: ClipboardEvent) => {
        event.preventDefault();
        event.clipboardData?.setData('text/plain', url);
        document.removeEventListener('copy', handler, true);
    };

    document.addEventListener('copy', handler, true);
    document.execCommand('copy');

    setAppMessage({text: getCopiedTextToast(label), type: 'success'});
};

const CopyProductLink = ({url, label}: CopyProductLinkProps) => {
    const copyButtonText = 'Copy Website';
    const clickWithExtra = useMessagingSalesClickAnalytics('ProductLink');
    const copyProductLink = useCallback(() => {
        copyProductLinkHandler(url, label);
        clickWithExtra({ActionId: 'ProductLinkCopied'});
    }, [url, label, clickWithExtra]);

    return (
        <Container onClick={copyProductLink} data-test-id="CopyProductLink_button">
            {copyButtonText}
        </Container>
    );
};

const CopyProductLinkLabel = () => {
    const url = useTweekValue(MessagingSalesTweekKeys.ProductWebsite, '');
    const productName = useTweekValue(MessagingSalesTweekKeys.ProductName, '');
    const websiteLabel = `${productName} Website`;

    return (
        <LabeledLinkContainer>
            <Label size="s" textType="tertiary">
                {websiteLabel}
            </Label>
            <CopyProductLink url={url} label={websiteLabel} />
        </LabeledLinkContainer>
    );
};

export default CopyProductLinkLabel;
