import {AnalyticsDispatcher} from 'shisell';
import {SimilarIcon, ChatRoundIcon, OpenIcon, SalesIcon, EditIconAlt} from '@anywhere-expert/icons';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {SidebarStore} from '@anywhere-expert/session-sidebar';
import TextRec from './components/TextRec';
import SimilarSessionRec from './components/SimilarSessions/SimilarSessionRec';
import OfferOnResolutionRec from './components/OfferOnResolutionRec';
import TailoredOfferRec from './components/TailoredOfferRec';
import {
    RecommendationType,
    Recommendation,
    TextRecommendation,
    SimilarSessionRecommendation,
    OfferOnResolutionRecommendation,
    TailoredOfferRecommendation,
} from './types';

export const recommendationTypes: {[name: string]: RecommendationType} = {
    message: {
        type: 'message',
        title: 'Send a Message',
        icon: ChatRoundIcon,
        actionIcon: EditIconAlt,
        content: TextRec,
        openBodyStyle: {paddingBottom: '40px'},
        onActionClick: (item: TextRecommendation, {dispatcher, supportItem, position}) => {
            enrichTextSuggestionDispatcher(dispatcher, item, position).dispatch('Click');
            TimelineInputStore.setEditorText(supportItem.id, item.data.text);
        },
        onVisibilityChange: (isVisible, item: TextRecommendation, {dispatcher, position}) => {
            enrichTextSuggestionDispatcher(dispatcher, item, position).dispatch(isVisible ? 'Visible' : 'Hidden');
        },
    },
    'similar-session': {
        type: 'similar-session',
        title: 'Similar Session',
        icon: SimilarIcon,
        actionIcon: OpenIcon,
        content: SimilarSessionRec,
        onActionClick: (item: SimilarSessionRecommendation, {dispatcher, supportItem, position}) => {
            const {sessionId} = item.data;
            SidebarStore.setSelectedTabId('similar-sessions');
            supportItem.similarSessions.setSelectedTranscript(sessionId);

            enrichSimilarSessionDispatcher(dispatcher, item, position).dispatch('Click');
        },
        onVisibilityChange: (isVisible, item: SimilarSessionRecommendation, {dispatcher, position}) => {
            enrichSimilarSessionDispatcher(dispatcher, item, position).dispatch(isVisible ? 'Visible' : 'Hidden');
        },
    },
    'offer-on-resolution': {
        type: 'offer-on-resolution',
        title: 'Make an Offer',
        icon: SalesIcon,
        actionIcon: EditIconAlt,
        content: OfferOnResolutionRec,
        onActionClick: (item: OfferOnResolutionRecommendation, {dispatcher, supportItem, position}) => {
            enrichOfferOnResolutionDispatcher(dispatcher, item, position).dispatch('Click');
            TimelineInputStore.setEditorText(supportItem.id, item.data.offerRecommendation.text, false);
        },
        onVisibilityChange: (isVisible, item: OfferOnResolutionRecommendation, {dispatcher, position}) => {
            enrichOfferOnResolutionDispatcher(dispatcher, item, position).dispatch(isVisible ? 'Visible' : 'Hidden');
        },
    },
    'tailored-offer': {
        type: 'tailored-offer',
        title: 'Make an Offer',
        icon: SalesIcon,
        actionIcon: EditIconAlt,
        content: TailoredOfferRec,
        onActionClick: (item: TailoredOfferRecommendation, {dispatcher, supportItem, position}) => {
            enrichTailoredOfferDispatcher(dispatcher, item, position).dispatch('Click');
            TimelineInputStore.setEditorText(supportItem.id, item.data.offerRecommendation.text, false);
        },
        onVisibilityChange: (isVisible, item: TailoredOfferRecommendation, {dispatcher, position}) => {
            enrichTailoredOfferDispatcher(dispatcher, item, position).dispatch(isVisible ? 'Visible' : 'Hidden');
        },
    },
};

const enrichSmartSuggestionDispatcher = (
    dispatcher: AnalyticsDispatcher,
    recommendation: Recommendation,
    position: number
) => {
    return dispatcher.withExtras({
        SuggestionId: recommendation.id,
        Source: 'SmartRecommendations',
        Position: position,
    });
};

const enrichTextSuggestionDispatcher = (
    dispatcher: AnalyticsDispatcher,
    recommendation: TextRecommendation,
    position: number
) => {
    const {text, triggerMessageId, modelVersion} = recommendation.data;
    return enrichSmartSuggestionDispatcher(dispatcher, recommendation, position).withExtras({
        Type: 'TextSuggestion',
        text,
        triggerMessageId,
        modelVersion,
    });
};

const enrichSimilarSessionDispatcher = (
    dispatcher: AnalyticsDispatcher,
    recommendation: SimilarSessionRecommendation,
    position: number
) => {
    const {sessionId, expertId, similarityScore, sessionDate} = recommendation.data;
    return enrichSmartSuggestionDispatcher(dispatcher, recommendation, position).withExtras({
        Type: 'SimilarSession',
        SimilarSessionId: sessionId,
        SimilarExpertId: expertId,
        SimilarityScore: similarityScore,
        SimilarSessionDate: sessionDate,
    });
};

const enrichOfferOnResolutionDispatcher = (
    dispatcher: AnalyticsDispatcher,
    recommendation: OfferOnResolutionRecommendation,
    position: number
) => {
    const {
        sessionId,
        partner,
        offerRecommendation: {category},
    } = recommendation.data;
    return enrichSmartSuggestionDispatcher(dispatcher, recommendation, position).withExtras({
        Type: 'OfferOnResolution',
        SessionId: sessionId,
        Category: category,
        CarrierName: partner,
    });
};

const enrichTailoredOfferDispatcher = (
    dispatcher: AnalyticsDispatcher,
    recommendation: TailoredOfferRecommendation,
    position: number
) => {
    const {sessionId, partner} = recommendation.data;
    return enrichSmartSuggestionDispatcher(dispatcher, recommendation, position).withExtras({
        Type: 'TailoredOffer',
        SessionId: sessionId,
        CarrierName: partner,
    });
};
