import styled from 'styled-components';
import {Status} from '../types';

const AvailableIndicator = styled.div<{status: Status}>`
    box-sizing: border-box;
    cursor: pointer;
    background: ${({theme, status}) =>
        status === Status.AVAILABLE
            ? theme.colors.primary.green.deep
            : status === Status.UNAVAILABLE
            ? theme.colors.semantic.yellow.normal
            : theme.colors.semantic.red.bright};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    margin-right: 4px;
`;

export default AvailableIndicator;
