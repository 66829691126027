import {useMemo, createContext, useContext} from 'react';
import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {MessagingSalesStore} from '../MessagingSalesStore';
import {SalesOfferModel} from '../SalesOfferModel';

export const MessagingSalesContext = createContext<MessagingSalesStore | null>(null);

export const useMessagingSalesEnablement = () => {
    const isMessagingSalesEnabled = useTweekValue(MessagingSalesTweekKeys.IsMessagingSalesEnabled, false);
    const isSalesExpert = useTweekValue(MessagingSalesTweekKeys.IsSalesExpert, false);

    return isMessagingSalesEnabled && isSalesExpert;
};

export const useMessagingSalesStatsEnablement = () => {
    const isSalesStatsEnabled = useTweekValue(MessagingSalesTweekKeys.IsSalesStatsEnabled, false);

    return useMessagingSalesEnablement() && isSalesStatsEnabled;
};

export const useMessagingSalesEligibilityEnablement = () => {
    const isUnknownEligibilityEnabled = useTweekValue(MessagingSalesTweekKeys.IsUnknownEligibilityEnabled, false);

    return useMessagingSalesEnablement() && isUnknownEligibilityEnabled;
};

export const useProfilePayLinkEnablement = () => {
    return useTweekValue<boolean>(MessagingSalesTweekKeys.IsProfilePayLinkEnabled, false);
};

export const useInitMessagingSalesStore = () => {
    const isEnabled = useMessagingSalesEnablement();

    return useMemo(() => (isEnabled ? new MessagingSalesStore() : null), [isEnabled]);
};

export const useMessagingSalesStore = () => useContext(MessagingSalesContext);

export const useCurrentOffer = (sessionId: string): SalesOfferModel | null | undefined => {
    const store = useMessagingSalesStore();
    const offer = store?.getOffer(sessionId);
    return offer;
};
