export default {
    shExpertInteractionApiBaseUrl:
        process.env.NODE_ENV !== 'production'
            ? 'https://sh-expert-interactions-api.soluto.npr.aws.asurion.net'
            : 'https://sh-expert-interactions-api.anywhereexpert.us',
    shaeWebSvcApi: {
        baseUrl:
            process.env.NODE_ENV !== 'production'
                ? 'https://shae-web-svc-api.soluto.npr.aws.asurion.net'
                : 'https://shae-web-svc-api.anywhereexpert.us',
    },
};
