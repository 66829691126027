import styled from 'styled-components';
import React, {useEffect} from 'react';
import {useCurrentSupportItem} from '@anywhere-expert/timeline-state';
import {useTheme} from '@anywhere-expert/colors';
import {AlertCircleIcon} from '@anywhere-expert/icons';
import {useAnalytics} from 'react-shisell';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {CoreText} from '@anywhere-expert/base-ui';
import {useIsOnline} from '@anywhere-expert/utils';

const RetryContainer = styled.div`
    display: flex;
    transition: opacity 100ms ease-in;
    align-items: center;
    justify-content: end;
    height: 16px;
    margin-top: 4px;
    margin-bottom: 16px;
    margin-right: 32px;
    gap: 4px;
`;

const RetryLink = styled(CoreText)`
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
`;

const Text = styled.span`
    font-size: 0.875rem;
    align-self: center;
`;

type RetryIndicatorProps = {
    contentId: string;
};

export function RetryIndicator({contentId}: RetryIndicatorProps) {
    const analytics = useAnalytics();
    const {assignMethod} = ExpertFeedStore;
    const theme = useTheme();
    const supportItem = useCurrentSupportItem();
    const timelineId = supportItem.timelineModel.timelineId;
    const isOnline = useIsOnline();

    const resendMessageHandler = async () => {
        analytics.dispatcher
            .withIdentities({CustomerId: timelineId})
            .withExtras({ActionId: 'Retry', ContentId: contentId, Experience: assignMethod})
            .dispatch('Click');

        await supportItem.timelineModel.resendMessage(contentId);
    };

    useEffect(() => {
        analytics.dispatcher
            .createScoped('ResendIndicator')
            .withIdentities({CustomerId: timelineId})
            .withExtras({
                ActionId: 'Retry',
                ContentId: contentId,
                Experience: assignMethod,
            })
            .dispatch('View');
    }, [analytics, contentId, assignMethod, timelineId]);

    return (
        <RetryContainer>
            <AlertCircleIcon color={theme.colors.semantic.red.bright} size="s" />
            <Text>Failed to send.</Text>
            {isOnline && (
                <RetryLink
                    textType="primary"
                    size="m"
                    variant="contained"
                    color={theme.colors.neutral.black}
                    onClick={resendMessageHandler}
                >
                    Try again
                </RetryLink>
            )}
        </RetryContainer>
    );
}
