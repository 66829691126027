import debounce from 'lodash.debounce';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {useCallback} from 'react';

let timeoutId: number;

export const useTyping = (timelineModel: TimelineModel, timeInMilliSeconds?: number) => {
    const clearTyping = useCallback(async () => {
        await timelineModel.typing(false);
    }, [timelineModel]);

    const debouncedTyping = useCallback(
        debounce(
            async () => {
                await timelineModel.typing(true);
            },
            500,
            {trailing: false, leading: true}
        ),
        [timelineModel]
    );

    return useCallback(async () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newId = setTimeout(clearTyping, timeInMilliSeconds ?? 3000);

        timeoutId = newId;

        await debouncedTyping();
    }, [clearTyping, timelineModel, timeInMilliSeconds]);
};
