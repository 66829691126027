import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Tip from './Tip';
import SalesProfileStats from './SalesProfileStats';

const Container = styled.div`
    font-family: Apercu Pro, sans-serif;
    background-color: ${({theme}) => theme.colors.background.secondary};
    color: ${({theme}) => theme.colors.neutral.black};
    width: 100%;
    height: 100%;
`;

const SalesProfile = () => {
    return (
        <Container data-test-id="SalesProfile">
            <Header />
            <SalesProfileStats />
            <Tip />
        </Container>
    );
};

export default SalesProfile;
