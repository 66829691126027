import {VisualGuide, GuideType} from '@anywhere-expert/visual-guides/src/types';
import {SupportItem} from '../models';
import {observable, action, computed, makeObservable} from 'mobx';

export default class QueueItemGifsStore {
    timelineId: string;
    parent: SupportItem;
    gifRepository: VisualGuide[];
    searchedText: string;
    suggestedGuideType?: GuideType;

    constructor(timelineId: string, parent: SupportItem) {
        makeObservable(this, {
            gifRepository: observable,
            searchedText: observable,
            suggestedGuideType: observable,
            setGifsRpository: action,
            filteredGifRepository: computed,
            setSearchText: action,
        });

        this.timelineId = timelineId;
        this.parent = parent;
        this.gifRepository = [];
        this.searchedText = '';
    }

    setGifsRpository = (gifsRpository: VisualGuide[]) => {
        this.gifRepository = gifsRpository;
    };

    get filteredGifRepository(): VisualGuide[] {
        const searchTextLowerCase = this.searchedText?.toLowerCase().trim();
        return this.searchedText
            ? this.gifRepository.filter(
                  (gif: VisualGuide) =>
                      gif.title.toLowerCase().includes(searchTextLowerCase) ||
                      gif.tag?.toLowerCase().includes(searchTextLowerCase)
              )
            : this.gifRepository;
    }

    setSearchText = (text: string) => {
        this.searchedText = text;
    };
}

export type QueueItemGifsStoreType = QueueItemGifsStore;
