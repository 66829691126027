/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {BaseIcon, IconProps} from '@anywhere-expert/icons';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <g fill={color}>
                <path d="M15 3v2h6v2H3V5h6V3h6zM11 12H9v6h2v-6zM13 12h2v6h-2v-6z" />
                <path
                    d="M7 22c-1.103 0-2-.897-2-2V8h14v12c0 1.103-.897 2-2 2H7zm10-12H7v10h10.001L17 10z"
                    fillRule="evenodd"
                />
            </g>
        </BaseIcon>
    ))
);

export default Icon;
