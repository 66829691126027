import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 3,
    maxTimeout: 2000,
};

export default async (timelineId: string, contentId: string, attachment: File): Promise<string> => {
    const {MediaServiceStore} = await pRetry(() => import('@anywhere-expert/media-service'), retryConfig);

    return MediaServiceStore.client.uploadAttachment(timelineId, contentId, attachment.name, attachment);
};
