import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    image: {
        borderRadius: 5,
        marginBottom: -3,
        boxSizing: 'border-box',
    },
    fullSize: {
        position: 'absolute',
        objectFit: 'cover',
        transition: 'opacity 400ms linear',
    },
    imageContainer: {
        position: 'relative',
        borderRadius: 5,
    },
    imageOverlay: {
        position: 'absolute',
        borderRadius: 5,
    },
    imageUploadOverlay: {
        position: 'absolute',
        background: 'rgba(0,0,0,0.3)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    imageUpload: {
        backgroundColor: '#e6e6e6',
    },
    animationOverlay: {
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.8)',
    },
    stopUploadContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default styles;
