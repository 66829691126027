import {SidebarStore} from '@anywhere-expert/session-sidebar';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {NoSessionSelectedStore} from '@anywhere-expert/no-session-selected';
import {AnalyticsDispatcher} from 'shisell';

const ID = 'canned-messages';

const openCannedMessagesTab = () => {
    ExpertFeedStore.selectedSupportSession
        ? SidebarStore.setSelectedTabId(ID)
        : NoSessionSelectedStore.setSelectedViewId(ID);
};

const useShortcutCannedMessages = (dispatcher: AnalyticsDispatcher) => {
    return {
        id: 'open-canned-messages',
        handler: (e: KeyboardEvent) => {
            e.preventDefault();
            dispatcher.withExtra('ActionId', 'KeyboardShortcut_CannedMessage').dispatch('Click');
            openCannedMessagesTab();
        },
    };
};

export default useShortcutCannedMessages;
