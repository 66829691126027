import React, {useCallback} from 'react';
import {CoreInputText, CoreIconButton} from '@anywhere-expert/base-ui';
import {CloseIcon, SearchIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useDebounce} from 'react-use/lib';
import {useAnalytics} from 'react-shisell';
import {useTimelineSearchStore} from '../useTimelineSearchStore';

const Container = styled.div`
    display: flex;
    margin-top: 16px;
    padding-right: 20px;
    flex-direction: column;
`;

const SearchContainer = styled.div`
    display: flex;
`;

const StyledCoreIconButton = styled(CoreIconButton)`
    color: ${({theme}) => theme.colors.neutral.grey.deep};

    &:disabled {
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }

    &:hover,
    &:active {
        background-color: transparent;
    }
`;

const InputUnderline = styled.div`
    border: 0;
    height: 1px;
    width: 100%;
    background-color: ${({theme}) => theme.colors.contrast.border};
`;

const SearchComp = observer(() => {
    const timelineSearchStore = useTimelineSearchStore();
    const shouldFocus = timelineSearchStore.focusedIndex === -1;
    const ref = React.useRef<HTMLElement>(null);
    const analytics = useAnalytics();

    const resetCursor = React.useCallback(() => {
        if (!shouldFocus) {
            timelineSearchStore.reset();
        }
    }, [shouldFocus, timelineSearchStore]);

    React.useEffect(() => {
        if (shouldFocus) ref?.current?.focus();

        return () => ref?.current?.blur();
    }, [shouldFocus]);

    const throttleSearchText = useCallback(() => {
        if (!timelineSearchStore.searchTerm) {
            analytics.dispatcher.dispatch('Typing');
        }
        analytics.dispatcher.withExtras({SearchTerm: timelineSearchStore.searchTerm}).dispatch('Typing');
        timelineSearchStore.search();
    }, [analytics, timelineSearchStore, timelineSearchStore.searchTerm]);

    const handleInputChange = useCallback(
        e => {
            timelineSearchStore.setSearchTerm(e.target.value);
        },
        [timelineSearchStore, timelineSearchStore.setSearchTerm]
    );

    const clearSearch = React.useCallback(() => {
        timelineSearchStore.clearSearch();
        resetCursor();
        ref?.current?.focus();
        analytics.dispatcher.withExtras({ActionId: 'ClearSearch'}).dispatch('Click');
    }, [timelineSearchStore]);

    useDebounce(throttleSearchText, 300, [timelineSearchStore, timelineSearchStore.searchTerm]);

    return (
        <Container>
            <SearchContainer>
                <CoreInputText
                    autoFocus
                    size="m"
                    ref={ref}
                    inputTextType="primary"
                    autoComplete="off"
                    value={timelineSearchStore.searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    data-test-id="TimelineSearch_Search_Input"
                    onFocus={resetCursor}
                    isTransparent
                />
                <StyledCoreIconButton
                    disabled={!timelineSearchStore.searchTerm}
                    onClick={clearSearch}
                    icon={timelineSearchStore.searchTerm ? CloseIcon : SearchIcon}
                    size="s"
                    variant="icon"
                />
            </SearchContainer>
            <InputUnderline />
        </Container>
    );
});

export default SearchComp;
