import {useTweekValue} from 'react-tweek';
import {SupportedScreenshareLevel, ScreenshareTimelineItemState} from '../../sharedScreenshareDefinitions';

type HeaderDefinitionConfig = Record<SupportedScreenshareLevel, Record<ScreenshareTimelineItemState, string>>;

const HEADERS: HeaderDefinitionConfig = {
    'remote-control': {
        ended: 'Remote control ended',
        expired: 'Remote control request expired',
        pending: 'Sent request for remote control',
        ongoing: 'Sent request for remote control',
    },
    screenshare: {
        ended: 'Screen share ended',
        expired: 'Screen share request expired',
        pending: 'Sent request to screen share',
        ongoing: 'Sent request to screen share',
    },
};

const SUBHEADERS: HeaderDefinitionConfig = {
    'remote-control': {
        ended: 'You’re no longer observing and controlling this customer’s screen',
        expired: 'The request is no longer available. Resend it from your Toolbox.',
        pending: 'Waiting for your customer to approve',
        ongoing: 'Waiting for your customer to approve',
    },
    screenshare: {
        ended: 'You’re no longer observing this customer’s screen',
        expired: 'The request is no longer available. Resend it from your Toolbox.',
        pending: 'Waiting for your customer to approve',
        ongoing: 'Waiting for your customer to approve',
    },
};

const NOTIFICATION =
    'We’ve asked them to turn on **screen sharing and accessibility** in order to proceed with remote control.';

const CUSTOMER_REJECTED_FULL_DEVICE_SCREEN_SHARE_PROMPT_NOTIFICATION =
    'The **request to screen share was rejected**. Ask the customer to approve your request before sending a new one.';
interface Texts {
    headerText: string;
    subheaderText: string;
    customerRejectedFullDeviceScreenSharePromptNotification: string;
    accessibilityDisabledNotification: string;
}

const useGetComponentTexts = (): ((
    status: ScreenshareTimelineItemState,
    level: SupportedScreenshareLevel
) => Texts) => {
    // can't fetch support/session/screen_share/screenshare_item/expert/subheaders/_ because of tweek caching issues
    const subheaders = useTweekValue<HeaderDefinitionConfig>(
        'support/session/screen_share/screenshare_item/expert/subheaders',
        SUBHEADERS
    );
    const headers = useTweekValue<HeaderDefinitionConfig>(
        'support/session/screen_share/screenshare_item/expert/headers',
        HEADERS
    );
    const accessibilityDisabledNotification = useTweekValue<any>(
        'support/session/screen_share/screenshare_item/expert/notification',
        NOTIFICATION
    );
    const customerRejectedFullDeviceScreenSharePromptNotification = useTweekValue<any>(
        'support/session/screen_share/screenshare_item/expert/customer_rejected_full_device_screen_share_prompt_notification',
        CUSTOMER_REJECTED_FULL_DEVICE_SCREEN_SHARE_PROMPT_NOTIFICATION
    );

    return (status, level) => ({
        headerText: headers[level]?.[status],
        subheaderText: subheaders[level]?.[status],
        customerRejectedFullDeviceScreenSharePromptNotification,
        accessibilityDisabledNotification,
    });
};

export default useGetComponentTexts;
