import React from 'react';
import styled from 'styled-components';
import {useTweekValue} from 'react-tweek';
import {observer} from 'mobx-react';
import {CoreText} from '@anywhere-expert/base-ui';
import {HorizonEnrollmentProgress as HorizonEnrollmentProgressType} from '@soluto-private/smart-home-anywhere-expert';
import {useHorizonEnrollmentFlowContent} from '../../../../../hooks';
import {HorizonEnrollmentFlowStep, HorizonEnrollmentFlowContent} from '../../../../../types';
import {HorizonEnrollmentState, MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import StarIcon from './resources/StarIcon';
import LightbulbIcon from './resources/LightbulbIcon';
import WandIcon from './resources/WandIcon';
import EnrollmentProgressItem from './EnrollmentProgressItem';
import StepLabel from './StepLabel';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';

const CustomerProgressContainer = styled.div`
    position: relative;
`;

const VerticalLine = styled.div`
    height: 100%;
    position: absolute;
    border-left: ${({theme}) => `1px solid ${theme.colors.contrast.primary}`};
    left: 30px;
    z-index: 0;
`;

type EnrollmentProgressTitles = {[key in HorizonEnrollmentState]: string};
type EnrollmentProgressStatusSteps = Omit<EnrollmentProgressTitles, 'PriceShared' | 'LinkReceived'>;

const getProgressItems = (
    titleMap: EnrollmentProgressStatusSteps
): {key: HorizonEnrollmentState; title: string; icon: React.ComponentType<any>}[] => [
    {
        key: HorizonEnrollmentState.LinkSent,
        title: titleMap[HorizonEnrollmentState.LinkSent],
        icon: StarIcon,
    },
    {
        key: HorizonEnrollmentState.LinkOpened,
        title: titleMap[HorizonEnrollmentState.LinkOpened],
        icon: LightbulbIcon,
    },
    {
        key: HorizonEnrollmentState.Enrolled,
        title: titleMap[HorizonEnrollmentState.Enrolled],
        icon: WandIcon,
    },
];

const defaultHorizonEnrollmentProgressNameMap: EnrollmentProgressStatusSteps = {
    [HorizonEnrollmentState.LinkSent]: 'Link sent',
    [HorizonEnrollmentState.LinkOpened]: 'Customer opened link',
    [HorizonEnrollmentState.Enrolled]: 'Enrollment complete',
    [HorizonEnrollmentState.Failed]: 'Enrollment Failed',
};

export const HorizonEnrollmentProgress = observer(({selectedSession}: SalesSessionProps) => {
    const enrollmentAttributes = selectedSession.feedItemAttributes.filter(
        attr => attr.type === HorizonEnrollmentProgressType
    );
    const {title} = useHorizonEnrollmentFlowContent(
        HorizonEnrollmentFlowStep.CustomerProgress
    ) as HorizonEnrollmentFlowContent[HorizonEnrollmentFlowStep.CustomerProgress];
    const enrollmentProgressTitles = useTweekValue(
        MessagingSalesTweekKeys.HorizonEnrollmentProgressItems,
        defaultHorizonEnrollmentProgressNameMap
    );
    const progressItems = getProgressItems(enrollmentProgressTitles);

    return (
        <div data-test-id="MessagingSales_EnrollmentFlow_HorizonEnrollmentProgress">
            <StepLabel selectedSession={selectedSession} />
            <CoreText size="m">{title}</CoreText>
            <CustomerProgressContainer>
                <VerticalLine />
                {progressItems.map(({key, title, icon}) => {
                    const attribute = enrollmentAttributes.find(attr => attr.key === key);

                    return (
                        <EnrollmentProgressItem
                            title={title}
                            icon={icon}
                            attribute={attribute}
                            dataTestId={`HorizonEnrollmentProgress_${key}_${attribute ? 'complete' : 'incomplete'}`}
                            key={key}
                        />
                    );
                })}
            </CustomerProgressContainer>
        </div>
    );
});

export default HorizonEnrollmentProgress;
