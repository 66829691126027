import {User} from '@anywhere-expert/auth';
import {v4 as uuid} from 'uuid';
import {extractFirstName} from '@anywhere-expert/utils';
import {ImagePayload, AttachmentPayload} from '../types';
import {CreateMessagePayload} from '@soluto-private/messaging-api-client';

export const buildOutgoingTextMessage = (
    message: string,
    userProfile: User,
    sessionId: string,
    partner: string,
    messageId?: string,
    sessionSource?: string,
    isAutoGreeting?: boolean
): CreateMessagePayload => ({
    payload: {
        type: 'text_message',
        value: {
            text: message,
        },
    },
    senderId: userProfile.uid,
    messageId: messageId || uuid(),
    metadata: {
        senderName: extractFirstName(userProfile.displayName),
        senderThumbUrl: userProfile.imageUrl,
        senderType: 'Technician',
        source: 'web',
        partner: partner.toLowerCase(),
        sessionSource,
        ...(isAutoGreeting && {autoResponseType: 'AutoGreeting'}),
    },
    sessionId,
});

export const buildOutgoingImageItem = (
    payload: ImagePayload,
    userProfile: User,
    sessionId: string,
    partner: string,
    messageId?: string,
    sessionSource?: string
): CreateMessagePayload => {
    if (!payload.text) payload.text = '📷 photo';
    if (!payload.imageType) payload.imageType = 'image';

    return {
        payload: {
            type: 'image_item',
            value: payload,
        },
        senderId: userProfile.uid,
        messageId: messageId || uuid(),
        metadata: {
            senderName: extractFirstName(userProfile.displayName),
            senderThumbUrl: userProfile.imageUrl,
            senderType: 'Technician',
            source: 'web',
            partner: partner.toLowerCase(),
            sessionSource,
        },
        sessionId,
    };
};

export const buildOutgoingAttachment = (
    payload: AttachmentPayload,
    userProfile: User,
    sessionId: string,
    partner: string,
    messageId?: string,
    sessionSource?: string
): CreateMessagePayload => {
    if (!payload.text) payload.text = 'file';

    return {
        payload: {
            type: 'attachment',
            value: payload,
        },
        senderId: userProfile.uid,
        messageId: messageId || uuid(),
        metadata: {
            senderName: extractFirstName(userProfile.displayName),
            senderThumbUrl: userProfile.imageUrl,
            senderType: 'Technician',
            source: 'web',
            partner: partner.toLowerCase(),
            sessionSource,
        },
        sessionId,
    };
};

export type VideoItem = {
    content: string;
    updatedAt: number;
    timelineId: string;
    contentId: string;
    senderType: string;
    senderName: string;
    senderThumbUrl?: string;
    senderId: string;
    contentType: string;
    imageDimensions: {width: number; height: number};
    state: 'Uploading' | 'Uploaded';
    url: string;
    videoUrl?: string;
    thumbnailUrl?: string;
};
