import React, {useEffect} from 'react';
import VideoItem from '@soluto-private/video-item';
import withCustomerTemplate from '@anywhere-expert/timeline-item/src/behaviors/withCustomerTemplate';
import withTechTemplate from '@anywhere-expert/timeline-item/src/behaviors/withTechTemplate';
import {calculateTargetDimensions} from '@anywhere-expert/timeline-item/src/MediaHelpers/calculateTargetDimensions';
import {useAnalytics} from 'react-shisell';
import UploadMediaItem from '@anywhere-expert/timeline-item/src/MediaHelpers/UploadMediaItem';
import {CoreTimelineTextBubble} from '@anywhere-expert/base-ui';
import {branch} from 'recompose';

type SizeProps = {
    height: number;
    width: number;
};

type VideoItemProps = {
    videoUrl: string;
    thumbnailUrl: string;
    imageDimensions: SizeProps;
    borderColor: string;
    alt: string;
    contentId: string;
    state: string;
    timestampValue: string;
    isSelf: boolean;
};

const withRelevantTemplate = branch<{isSelf: boolean}>(
    props => props.isSelf,
    withTechTemplate as any,
    withCustomerTemplate as any
);

const VideoComponent = (props: VideoItemProps) => {
    const {videoUrl, thumbnailUrl, imageDimensions, borderColor, alt, contentId, state, timestampValue, isSelf} = props;
    const analytics = useAnalytics();
    const targetSize = calculateTargetDimensions(imageDimensions.width, imageDimensions.height);

    useEffect(() => {
        analytics.dispatcher.createScoped('VideoItem').withExtra('contentId', contentId).dispatch('View');
    }, []);

    if (state === 'Uploading') {
        return <UploadMediaItem {...props} />;
    }

    return (
        <CoreTimelineTextBubble textBubbleType={isSelf ? 'expert' : 'customer'} size="m" timestamp={timestampValue}>
            <VideoItem
                isSelf={isSelf}
                onPlay={() => {
                    analytics.dispatcher
                        .createScoped('VideoItem')
                        .withExtra('contentId', contentId)
                        .dispatch('VideoPlayed');
                }}
                videoDimensions={targetSize}
                videoUrl={videoUrl}
                thumbnailUrl={thumbnailUrl}
                loaded={true}
                borderColor={borderColor}
                alt={alt}
            />
        </CoreTimelineTextBubble>
    );
};

export default withRelevantTemplate(VideoComponent);
