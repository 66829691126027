const partitionQueuesAndExpertise = (expertise: string[]) => {
    const sets = expertise.reduce(
        (all, ex) => {
            const split = ex.split(':');
            if (split.length === 2) {
                all[0].add(split[0]);
                all[1].add(split[1]);
            }
            all[1].add(ex);
            return all;
        },
        [new Set<string>(), new Set<string>()]
    );

    return sets.map(set => Array.from(set));
};

export default partitionQueuesAndExpertise;
