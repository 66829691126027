import React from 'react';
import {observer} from 'mobx-react';
import {useCurrentOffer} from '../../hooks';
import {SidebarTabContainer} from '@anywhere-expert/session-sidebar';
import {ConclusionState} from '../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {TabProps} from '@anywhere-expert/session-sidebar';
import {useTweekValue} from 'react-tweek';
import {SalesToolsLoading, SalesToolsConcluded} from './tab-states';
import {SalesTools} from './sales-tools';
import {getMessagingSalesDisabledText} from '../../utils';
import {
    HorizonEnrollmentState,
    HorizonEnrollmentProgress,
    ExternalEnrollmentProgress,
} from '@soluto-private/smart-home-anywhere-expert';

interface MessagingSalesTabType extends React.FunctionComponent<TabProps> {
    getDisabledText?: any;
}

const SalesTabView = observer(({selectedSession}: TabProps) => {
    const currentOffer = useCurrentOffer(selectedSession.sessionId);
    const enrollmentProgress = currentOffer?.offer.enrollmentProgress;

    const horizonEnrollmentProgressIsEnrolled = selectedSession.feedItemAttributes.find(
        attr => attr.type === HorizonEnrollmentProgress && attr.key === HorizonEnrollmentState.Enrolled
    );

    if (!currentOffer || enrollmentProgress === ExternalEnrollmentProgress.Loading) {
        return <SalesToolsLoading selectedSession={selectedSession} />;
    }

    if (enrollmentProgress === ExternalEnrollmentProgress.Success || horizonEnrollmentProgressIsEnrolled) {
        return (
            <SalesToolsConcluded
                selectedSession={selectedSession}
                conclusionState={ConclusionState.EnrollmentSuccess}
                dataTestId="MessagingSales_EnrollmentSuccess"
            />
        );
    }

    if (enrollmentProgress === ExternalEnrollmentProgress.Error) {
        return (
            <SalesToolsConcluded
                selectedSession={selectedSession}
                conclusionState={ConclusionState.EnrollmentFailure}
                dataTestId="MessagingSales_EnrollmentFailure"
            />
        );
    }

    return <SalesTools selectedSession={selectedSession} />;
});

const MessagingSalesTab: MessagingSalesTabType = observer(({selectedSession}: TabProps) => {
    const tabTitle = useTweekValue(MessagingSalesTweekKeys.ProductName, 'Sales Tools');
    return (
        <SidebarTabContainer data-test-id="MessagingSales_Tab" title={tabTitle}>
            <SalesTabView selectedSession={selectedSession} />
        </SidebarTabContainer>
    );
});

MessagingSalesTab.getDisabledText = getMessagingSalesDisabledText;

export default MessagingSalesTab;
