import * as React from 'react';
import Bubble from './Bubble';

const NUMBER_OF_IMAGES = 8;

const items = shuffle(
    new Array(NUMBER_OF_IMAGES).fill(0).map((_, index) => ({
        degree: index * (360 / NUMBER_OF_IMAGES),
        radius: Math.floor(Math.random() * 50),
        enterDelay: 0,
    }))
).map((item, index) => ({...item, enterDelay: index === 0 ? 0 : index * 400}));

export default () => (
    <>
        <Bubble radius={-60} degree={10} enterDelay={0} animationDuration={12}>
            <img src={require(`./resources/circle1.png`)} />
        </Bubble>
        <Bubble radius={-50} degree={140} enterDelay={200} animationDuration={12}>
            <img src={require(`./resources/circle2.png`)} />
        </Bubble>
        <Bubble radius={-50} degree={220} enterDelay={100} animationDuration={12}>
            <img src={require(`./resources/circle3.png`)} />
        </Bubble>
        {items.map((item, index) => (
            <Bubble
                key={index}
                radius={item.radius}
                degree={item.degree}
                enterDelay={item.enterDelay}
                animationDuration={20}
            >
                <img src={require(`./resources/${index + 1}.png`)} />
            </Bubble>
        ))}
    </>
);

function shuffle(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
}
