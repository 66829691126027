import React, {ReactNode} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import useScrollToSelfBehavior from '../useScrollToSelfBehavior';
import {TechAvatar, CoreText} from '@anywhere-expert/base-ui';

const styles = {flex: 1};

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledTechAvatar = styled(TechAvatar)`
    margin-right: 8px;
`;

const CustomerName = styled(CoreText)`
    margin-bottom: 4px;
`;

const Content = styled.div<{showAvatar: boolean}>`
    display: flex;
    ${({showAvatar}) =>
        showAvatar &&
        `
    margin-right: 37px;
    flex-direction: column;
    margin-top: 3px;
    `};
`;

export type CommonProps = TimelineItemProps & {
    isCustomerProfileAvatarEnabled?: boolean;
};

export type CustomerTemplateChildren = {
    children: ReactNode | ((isHover: boolean) => ReactNode);
};

export type CustomerTemplateProps = CommonProps & {
    isHover: boolean;
    handleMouseOut: () => void;
    handleMouseHover: () => void;
};

export const CustomerTemplate = observer(
    ({
        contentId,
        senderName,
        senderThumbUrl,
        showThumbnail,
        children,
        handleMouseOut,
        handleMouseHover,
        isHover,
        isCustomerProfileAvatarEnabled,
    }: CustomerTemplateProps & CustomerTemplateChildren) => {
        const containerRef = React.useRef<HTMLDivElement>(null);
        const showAvatar = showThumbnail && isCustomerProfileAvatarEnabled;
        useScrollToSelfBehavior(contentId, containerRef);

        return (
            <Row onMouseLeave={handleMouseOut}>
                {showAvatar && <StyledTechAvatar size={24} imageUrl={senderThumbUrl} name={senderName} />}
                <Content showAvatar={!!showAvatar}>
                    {showAvatar && (
                        <CustomerName size="m" textType="secondary" italic>
                            {senderName}
                        </CustomerName>
                    )}
                    <div style={styles} onMouseEnter={handleMouseHover}>
                        {typeof children === 'function' ? children(isHover) : children}
                    </div>
                </Content>
            </Row>
        );
    }
);
