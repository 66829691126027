import {QueueItemAttribute} from '@soluto-private/expert-queue-api-types';

export const isItemSnoozed = (feedItemAttributes: QueueItemAttribute[] = []) =>
    !!feedItemAttributes.find(x => x.type === 'snooze');

export const getSnoozeField = (feedItemAttributes: QueueItemAttribute[] = [], fieldName) => {
    const snoozeAttribute = feedItemAttributes.find(({type}) => type === 'snooze');
    if (!snoozeAttribute || !snoozeAttribute.fields) {
        return '0';
    }
    return snoozeAttribute.fields[fieldName];
};

export const isItemCurrentlySnoozed = (feedItemAttributes: QueueItemAttribute[] = []) => {
    if (!isItemSnoozed(feedItemAttributes)) return false;
    const snoozeWakeUpTime = parseFloat(getSnoozeField(feedItemAttributes, 'snoozeWakeUpTime'));
    return snoozeWakeUpTime >= Date.now();
};
