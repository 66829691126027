import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const rootEl = document.getElementById('root');

let render = () => {
    ReactDOM.render(<App />, rootEl);
};

if (module.hot) {
    const renderApp = render;

    render = () => {
        try {
            renderApp();
        } catch (error) {
            console.log('WOOOHOOOPS ', error);
        }
    };

    module.hot.accept('./App', () => render());
}

render();
