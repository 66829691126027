import {ExpertQueueUpdate} from '@soluto-private/live-queue-api-client';
import {SessionEvent} from '@soluto-private/session-api-types';
import {AnalyticsDispatcher} from 'shisell';

export default (analyticsDispatcher: AnalyticsDispatcher, queueUpdate: ExpertQueueUpdate) => {
    const {meta} = queueUpdate;
    const correlationId = meta?.correlationId;
    const sessionEvent = meta?.sessionEvent;

    if (sessionEvent === SessionEvent.AttributeChanged) {
        return;
    }

    const executionTime = Date.now() - meta?.startTimestampByService?.['anywhere-expert-web'] || undefined;

    analyticsDispatcher
        .createScoped('SessionLifecycle')
        .withExtra('SessionEvent', sessionEvent)
        .withExtra('ExpertQueueUpdateType', queueUpdate.eventType)
        .withExtra('CorrelationId', correlationId)
        .withExtra('ExecutionTime', executionTime)
        .dispatch('ExpertQueueUpdateReceived');
};
