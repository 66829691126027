import React from 'react';
import Markdown from '@soluto-private/soluto-markdown';
import Container from './Container';
import Header from './Header';
import Subheader from './Subheader';
import Notification from './Notification';

export interface BaseComponentProps {
    Icon: ({className}: {className?: string}) => JSX.Element;
    headerText: string;
    subHeaderText: string;
    notificationText?: string;
}

const BaseComponent = ({Icon, headerText, subHeaderText, notificationText}: BaseComponentProps) => {
    return (
        <Container>
            <Icon />
            <Header>{headerText}</Header>
            <Subheader>{subHeaderText}</Subheader>
            {notificationText && (
                <Notification>
                    <Markdown content={notificationText} />
                </Notification>
            )}
        </Container>
    );
};

export default BaseComponent;
