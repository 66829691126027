import React, {useCallback} from 'react';
import {
    CoreButton,
    ModalTitle,
    ModalContent,
    CoreText,
    ModalActions,
    closeModal,
    ModalContentComponentProps,
} from '@anywhere-expert/base-ui';
import styled from 'styled-components';

const Title = styled<any>(ModalTitle)`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
`;

const Text = styled(CoreText)`
    width: 261px;
`;

const closeModalAddCategory = () => closeModal('delete-confirmation-modal');

interface DeleteMessageConfirmationModalProps extends ModalContentComponentProps {
    onDelete: () => void;
    title: string;
    text: string;
}

export const DeleteConfirmationModel = ({onDelete, title, text}: DeleteMessageConfirmationModalProps) => {
    const onDeleteClick = useCallback(() => {
        closeModalAddCategory();
        onDelete();
    }, []);

    return (
        <>
            <Title modalSize="s" showCloseButton={true}>
                {title}
            </Title>
            <ModalContent>
                <Text size="s">{text}</Text>
            </ModalContent>
            <ModalActions>
                <CoreButton variant="outlined" text="Cancel" size="s" onClick={closeModalAddCategory} />
                <CoreButton
                    data-test-id="CannedMessage_DeleteConfirmationButton"
                    variant="contained"
                    color="danger"
                    onClick={onDeleteClick}
                    size="s"
                    text="Delete"
                />
            </ModalActions>
        </>
    );
};
