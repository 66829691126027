import React from 'react';

const CollapseIcon = ({color, ...others}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6663 11.609L14.195 15.1377L15.1377 14.195L11.609 10.6663H13.333V9.33301H9.33301V13.333H10.6663V11.609Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66673 5.33315V6.66648H6.66673V2.66648H5.33339V4.39048L1.80473 0.861816L0.862061 1.80448L4.39073 5.33315H2.66673Z"
            fill={color}
        />
    </svg>
);

export default CollapseIcon;
