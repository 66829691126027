import React, {useMemo} from 'react';

export default (ref: React.RefObject<any>) => {
    const isOverflowing = useMemo(() => {
        if (!ref.current) {
            return false;
        }

        const {scrollWidth, clientWidth} = ref.current;
        return scrollWidth > clientWidth;
    }, [ref, ref.current]);

    return isOverflowing;
};
