import {noPreviewImageBase64} from './noPreviewAvailable';
import {ItemState} from './mediaConsts';
import {generateResizedImage} from './timelineMediaResizer.web';
import {User} from '@anywhere-expert/auth';
import {buildOutgoingImageItem, buildOutgoingAttachment} from '@anywhere-expert/messaging';
import {AttachmentPayload} from '@anywhere-expert/messaging/types';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {CreateMessagePayload} from '@soluto-private/messaging-api-client';

interface ResizeConfiguration {
    width: number;
    height: number;
}

export const getBase64 = blob =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

export const createVideoTag = file =>
    new Promise<HTMLVideoElement>((resolve, reject) => {
        getBase64(file)
            .then((base64: string) => {
                const video = document.createElement('video');
                video.onloadedmetadata = () => resolve(video);
                video.onerror = reject;
                video.src = base64;
            })
            .catch(err => reject(err));
    });

export const createImageTag = file =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        getBase64(file)
            .then((base64: string) => {
                const img = document.createElement('img');
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = base64;
            })
            .catch(err => reject(err));
    });

export const getMediaTagDimensions = (mediaTag: HTMLVideoElement | HTMLImageElement) =>
    mediaTag instanceof HTMLVideoElement
        ? {
              width: mediaTag.videoWidth,
              height: mediaTag.videoHeight,
          }
        : {
              width: mediaTag.width,
              height: mediaTag.height,
          };

export const tryToSaveToLocalStorage = async (contentId: string, mediaData: any) => {
    try {
        localStorage.setItem(contentId, mediaData);
    } catch (err) {
        localStorage.setItem(contentId, noPreviewImageBase64);
    }
};

export const buildMediaItem = async (
    user: User,
    mediaTag: any,
    partner: string,
    sessionId: string,
    contentId: string,
    state: string,
    thumbnailUrl?: string,
    mediaUrl?: string,
    fullSizeMediaDimensions?: {width: number; height: number}
) => {
    if (!fullSizeMediaDimensions) {
        fullSizeMediaDimensions = {
            width: mediaTag.width,
            height: mediaTag.height,
        };
    }

    return buildOutgoingImageItem(
        state === ItemState.uploading
            ? {
                  imageDimensions: fullSizeMediaDimensions,
                  state: ItemState.uploading,
                  imageType: 'image',
              }
            : {
                  imageDimensions: fullSizeMediaDimensions,
                  state: ItemState.uploaded,
                  imageType: 'image',
                  imageUrl: mediaUrl,
                  thumbnailUrl: thumbnailUrl,
              },
        user,
        sessionId,
        partner,
        contentId
    );
};

export const buildAttachment = (
    user: User,
    contentId: string,
    state: string,
    sessionId: string,
    partner: string,
    url?: string
): CreateMessagePayload => {
    const payload: AttachmentPayload = {};
    if (state !== ItemState.uploading) {
        payload.url = url;
    }

    return buildOutgoingAttachment(payload, user, sessionId, partner, contentId);
};

export const postItem = async (supportItem: SupportItem, item: any, status: ItemState) =>
    status === ItemState.uploading
        ? supportItem.timelineModel.createLocalMessage(item)
        : supportItem.timelineModel.createMessage(item);

export const resizeMedia = async (
    mediaFile: File,
    imageMaxWidth: number,
    imageMaxHeight: number,
    thumbnailMaxWidth: number,
    thumbnailMaxHeight: number
) => {
    const mediaUploadConfig: ResizeConfiguration = {
        width: imageMaxWidth,
        height: imageMaxHeight,
    };
    const thumbConfig: ResizeConfiguration = {
        width: thumbnailMaxWidth,
        height: thumbnailMaxHeight,
    };
    const {imageData: fullSizeMediaData, targetImageDimensions: fullSizeMediaDimensions} = await generateResizedImage(
        mediaFile,
        mediaUploadConfig
    );

    const {imageData: thumbSizeMediaData} = await generateResizedImage(mediaFile, thumbConfig);

    return {fullSizeMediaData, fullSizeMediaDimensions, thumbSizeMediaData};
};
