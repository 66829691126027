import React from 'react';
import styled from 'styled-components';
import {CannedMessageSuggestion} from '@soluto-private/canned-messages-api-client';
import MessageText from '../../../message/MessageText';

const MessageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 5px;
    padding-right: 5px;
`;

type Props = {
    id: string;
    suggestion: CannedMessageSuggestion;
    searchedText: string;
};

export default ({id, suggestion, searchedText}: Props) => (
    <MessageContainer>
        <MessageText
            categoryId={'suggestions'}
            messageId={id}
            title={suggestion.title}
            text={suggestion.text}
            isDefaultGreetingMessage={false}
            searchedText={searchedText}
        />
    </MessageContainer>
);
