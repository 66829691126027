import {observable, action, computed, reaction, makeObservable} from 'mobx';
import logger from '@anywhere-expert/logging';
import {Tab, TabType, TabsConfig, TabId} from './types';
import ExpertFeedStore from '@anywhere-expert/expert-feed-store/ExpertFeedStore';
import {UserStorageStore} from '@anywhere-expert/user-storage-store';

class SidebarStore {
    private defaultTabId: TabId = 'toolbox';
    tabsConfig: TabsConfig;
    selectedTabId: TabId = this.defaultTabId;
    isSidebarOpen: boolean = true;
    isSidebarPinned: boolean = true;

    constructor() {
        makeObservable<SidebarStore, 'defaultTabId'>(this, {
            defaultTabId: observable,
            tabsConfig: observable,
            selectedTabId: observable,
            isSidebarOpen: observable,
            isSidebarPinned: observable,
            viewableNonConstantTabIds: computed,
            selectedTab: computed,
            setTabsConfig: action,
            setDefaultTabId: action,
            setSelectedTabId: action,
            setIsSidebarOpen: action,
            setIsSidebarPinned: action,
            viewableTabs: computed,
            closeTab: action,
        });

        reaction(
            () => ExpertFeedStore?.selectedSupportSession,
            selectedSession => {
                if (!selectedSession) {
                    return;
                }

                if (selectedSession.selectedTabId) {
                    const isTabViewable = Object.keys(this.viewableTabs).some(
                        viewableTabId => viewableTabId === selectedSession.selectedTabId
                    );

                    const targetTab = this.tabsConfig[selectedSession.selectedTabId];
                    const isTabDisabled =
                        !targetTab || (targetTab.getDisabledText && targetTab.getDisabledText(selectedSession));

                    if (isTabViewable && !isTabDisabled) {
                        this.setSelectedTabId(selectedSession.selectedTabId, false);

                        return;
                    }
                }
                this.setSelectedTabId(this.defaultTabId, false);
            }
        );
    }

    get viewableNonConstantTabIds(): TabId[] {
        return UserStorageStore.getValue('sidebarOpenTabs', []).filter(tabId => this.tabsConfig[tabId]);
    }

    get selectedTab(): Tab {
        return this.tabsConfig[this.selectedTabId];
    }

    setTabsConfig(tabsConfig: TabsConfig) {
        this.tabsConfig = tabsConfig;
        this.selectedTabId = this.defaultTabId;
    }

    setDefaultTabId(tabId: TabId) {
        this.defaultTabId = tabId;
    }

    setSelectedTabId = (tabId: TabId, forceOpen: boolean = true) => {
        this.selectedTabId = tabId;

        const tab = this.tabsConfig[tabId];
        if (!tab) {
            logger.error('SidebarStore - there is no tab configured with this tabId', {extra: {tabId}});
            return;
        }

        forceOpen && this.setIsSidebarPinned(true);
        ExpertFeedStore.selectedSupportSession?.setSelectedTabId(tabId);

        if (tab.type === TabType.Pinned && !this.viewableNonConstantTabIds.includes(tabId)) {
            UserStorageStore.setValue('sidebarOpenTabs', [...this.viewableNonConstantTabIds, tabId]);
        }
    };

    setIsSidebarOpen = (isOpen: boolean) => {
        this.isSidebarOpen = isOpen;
    };

    setIsSidebarPinned = (isPinned: boolean) => {
        this.isSidebarPinned = isPinned;
        this.setIsSidebarOpen(isPinned);
    };

    toggleIsSidebarPinned = () => {
        this.setIsSidebarPinned(!this.isSidebarPinned);
    };

    get viewableTabs(): TabsConfig {
        const tabs = Object.entries(this.tabsConfig) as [TabId, Tab][];
        const constantTabs = tabs.filter(([, tabA]) => tabA.type === TabType.Constant);
        const disposableTabs = tabs.filter(([, tabA]) => tabA.type === TabType.Disposable);
        const otherTabs = this.viewableNonConstantTabIds.map(tabId => [tabId, this.tabsConfig[tabId]]) as [
            TabId,
            Tab
        ][];
        // Made to ensure the tabs will be sorted with respect to the order in viewableNonConstantTabIds
        const sortedTabs = [...constantTabs, ...otherTabs, ...disposableTabs];

        return sortedTabs.reduce((viewableTabs, [id, tab]) => {
            switch (tab.type) {
                case TabType.Constant:
                    viewableTabs[id] = tab;
                    break;
                case TabType.Pinned:
                    if (this.viewableNonConstantTabIds.includes(id)) viewableTabs[id] = tab;
                    break;
                case TabType.Disposable:
                    if (this.selectedTabId === id) viewableTabs[id] = tab;
                    break;
                default:
                    throw new Error('Sidebar tab must have a type');
            }
            return viewableTabs;
        }, {}) as TabsConfig;
    }

    closeTab(tabId: TabId, tab: Tab) {
        if (tab.type === TabType.Constant) return;
        const tabs = [...this.viewableNonConstantTabIds];
        tabs.splice(this.viewableNonConstantTabIds.indexOf(tabId), 1);

        UserStorageStore.setValue('sidebarOpenTabs', tabs);

        if (tabId === this.selectedTabId) {
            this.setSelectedTabId(this.defaultTabId);
        }
    }
}

export default new SidebarStore();

export type SidebarStoreType = SidebarStore;
