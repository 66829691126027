import React, {useState} from 'react';
import styled from 'styled-components';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {CloseCategoryIcon, OpenCategoryIcon} from '../../../../resources';
import {useEventListener} from '@anywhere-expert/utils';

const Button = styled(CoreIconButton)`
    margin-right: 10px;
    color: ${({theme}) => theme.colors.contrast.disabled};

    &:hover {
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:active,
    &[data-is-active='true'] {
        opacity: 1;
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:disabled {
        border-color: ${({theme}) => theme.colors.neutral.grey.deep};
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }
`;

const CategoryIconsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
`;

const CollapseCaretButton = ({
    isCollapsed,
    targetRef,
}: {
    isCollapsed: boolean;
    targetRef: React.RefObject<HTMLElement>;
}) => {
    const [isHover, setIsHover] = useState(false);

    useEventListener('mouseenter', () => setIsHover(true), targetRef?.current, true);
    useEventListener('mouseleave', () => setIsHover(false), targetRef?.current, true);

    return !isCollapsed || isHover ? (
        <CategoryIconsContainer>
            {isCollapsed ? (
                <Button icon={OpenCategoryIcon} size="s" variant="icon-fade" />
            ) : (
                <Button icon={CloseCategoryIcon} size="s" variant="icon-fade" data-is-active={true} />
            )}
        </CategoryIconsContainer>
    ) : null;
};

export default CollapseCaretButton;
