import {useMemo} from 'react';
import {CannedMessageSuggestion} from '@soluto-private/canned-messages-api-client';

export default (suggestions: {[key: string]: CannedMessageSuggestion}, textFilter?: string) => {
    const sortedSuggestions = useMemo(() => {
        let entries = Object.entries(suggestions).sort((a, b) => b[1].displayOrder - a[1].displayOrder);
        if (textFilter) {
            entries = entries.filter(x => x[1].text.includes(textFilter));
        }

        return entries?.map(([id, suggestion]) => ({
            id,
            suggestion,
        }));
    }, [suggestions]);

    return sortedSuggestions;
};
