import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {Messages} from '../';
import CannedMessagesStoreV2 from '../../stores/CannedMessagesStoreV2';
import {observer} from 'mobx-react';
import {useAnalytics} from 'react-shisell';
import {DropTargetMonitor, useDrop} from 'react-dnd';
import {Category, CannedMessage} from '@soluto-private/canned-messages-api-client';
import {DraggedMessage} from '../../types';
import CategoryHeader from './CategoryHeader';
import {useCannedMessagesNavigationStore} from '../../useCannedMessagesNavigationStore';

export const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const CategoryEmojiContainer = styled(CoreText)`
    margin-right: 12px;
`;

type Props = {
    id: string;
    category: Category;
    onMessageSelection: (categoryTitle: string, categoryId: string, messageId: string, message: CannedMessage) => void;
    collapseAll: () => void;
};

const Category = observer(({id, category, collapseAll, onMessageSelection}: Props) => {
    const analytics = useAnalytics();
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();

    const isCollapsed = cannedMessagesNavigationStore.collapsedCategories[id];
    const {title, color, messages} = category;

    const [, dropRef] = useDrop<DraggedMessage, Promise<void>, void>({
        accept: 'Message',
        drop: async (cannedMessage: DraggedMessage, monitor: DropTargetMonitor) => {
            if (!CannedMessagesStoreV2.areActionsEnabled) {
                return;
            }

            const didDrop = monitor.didDrop();
            if (didDrop) {
                return;
            }

            await CannedMessagesStoreV2.moveMessage(cannedMessage, id);

            analytics.dispatcher
                .createScoped('Categories')
                .withExtras({
                    MessageId: cannedMessage.id,
                    SourceCategoryId: cannedMessage.categoryId,
                    TargetCategoryId: id,
                    TargetCategoryName: category.title,
                })
                .dispatch('DragAndDrop');
        },
    });

    const onSelectMessage = useCallback(
        (categoryId: string, messageId: string, message: CannedMessage) => {
            onMessageSelection(title, categoryId, messageId, message);
        },
        [title, onMessageSelection]
    );

    const setCollapse = useCallback(
        (collapsed: boolean) => {
            cannedMessagesNavigationStore.collapse(id, collapsed);
        },
        [id, cannedMessagesNavigationStore]
    );

    useEffect(() => {
        setCollapse(!cannedMessagesNavigationStore.searchedText);
    }, [setCollapse, cannedMessagesNavigationStore.searchedText]);

    return (
        <CategoryContainer ref={dropRef}>
            <CategoryHeader
                id={id}
                icon={category.icon}
                title={category.title}
                collapseAll={collapseAll}
                isCollapsed={isCollapsed}
                setCollapse={setCollapse}
                shouldShowIndicator={false}
                removeFocus={cannedMessagesNavigationStore.removeFocus}
            />
            {!isCollapsed && (
                <Messages
                    categoryId={id}
                    categoryName={title}
                    messages={messages}
                    color={color}
                    onSelectMessage={onSelectMessage}
                />
            )}
        </CategoryContainer>
    );
});

export default Category;
