import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import Search from './components/Search';
import {CoreIconButton, CoreText} from '@anywhere-expert/base-ui';
import CannedMessages from './CannedMessages';
import {CloseIcon} from '@anywhere-expert/icons';
import {useCannedMessagesNavigationStore} from './useCannedMessagesNavigationStore';

const Header = styled.div`
    margin-bottom: 24px;
    position: relative;
    flex: none;
`;

const ChildrenContainer = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden auto;
    padding-bottom: 32px;
    padding-right: 20px;
`;

const Container = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 32px 14px 0 32px;
    flex: 1;
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: ${({theme}) => theme.colors.background.secondary};
    width: 60vw;
    border-radius: 4px;
`;

const CloseButton = styled(CoreIconButton)`
    align-self: flex-end;
    z-index: 2;
    position: absolute;
`;

type Props = {
    onClose: () => void;
};

const CannedMessagesFullView = observer(({onClose}: Props) => {
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();
    const onMessageSelection = React.useCallback(() => {}, []);

    React.useEffect(() => {
        cannedMessagesNavigationStore.setSearchText('');

        return () => cannedMessagesNavigationStore.setSearchText('');
    }, [cannedMessagesNavigationStore]);

    return (
        <Container data-test-id="CannedMessages_FullView_V2">
            <CloseButton
                data-test-id="CannedMessages_FullView_V2_CloseButton"
                icon={CloseIcon}
                onClick={onClose}
                variant="icon"
                size="m"
            />
            <Header>
                <CoreText color="primary" size="xxl">
                    Canned Messages
                </CoreText>
                <Search />
            </Header>
            <ChildrenContainer>
                <CannedMessages onMessageSelection={onMessageSelection} />
            </ChildrenContainer>
        </Container>
    );
});

const CannedMessagesFullViewDecider = ({onClose}: Props) => <CannedMessagesFullView onClose={onClose} />;

export default CannedMessagesFullViewDecider;
