import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Message} from './';
import {CannedMessage} from '@soluto-private/canned-messages-api-client';
import {observer} from 'mobx-react';
import {useCannedMessagesNavigationStore} from '../../useCannedMessagesNavigationStore';

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 93%;
    word-break: break-word;
    margin-left: 8px;
    margin-bottom: 5px;
`;

type Props = {
    categoryId: string;
    categoryName: string;
    messages: {[key: string]: CannedMessage};
    color: string;
    onSelectMessage: (categoryId: string, messageId: string, message: CannedMessage) => void;
};

export default observer(({categoryId, categoryName, messages, color, onSelectMessage}: Props) => {
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();
    const entries = useMemo(
        () => Object.entries(messages).sort(([, {displayOrder: a}], [, {displayOrder: b}]) => a - b),
        [messages]
    );

    return !entries.length ? null : (
        <MessagesContainer>
            {entries.map(([id, message]) => (
                <Message
                    key={id}
                    id={id}
                    isFocused={cannedMessagesNavigationStore.isFocused({messageId: id, categoryId})}
                    categoryId={categoryId}
                    categoryName={categoryName}
                    message={message}
                    categoryColor={color}
                    onSelectMessage={onSelectMessage}
                    removeFocus={cannedMessagesNavigationStore.removeFocus}
                    searchedText={cannedMessagesNavigationStore.searchedText}
                />
            ))}
        </MessagesContainer>
    );
});
