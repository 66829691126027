import {reaction, observable, makeObservable} from 'mobx';
import {ExpertAvailabilityStore} from '@expert-feed/expert-availability';
import {getTweekValue} from '@anywhere-expert/tweek';

const DEFAULT_LOW_VOLUME_AWAITING_DURATION_IN_MINS = 40;

export class LowVolumeStore {
    private lowVolumeTimer?: number;
    private lowVolumeAwaitingDurationInMs: number;

    lowVolumeFlag: boolean = false;

    constructor() {
        makeObservable<LowVolumeStore, 'lowVolumeFlag'>(this, {
            lowVolumeFlag: observable,
        });

        this.lowVolumeAwaitingDurationInMs =
            60 *
            1000 *
            getTweekValue<number>(
                'support/routing/get_next_v2/low_volume_awaiting_duration_in_min',
                DEFAULT_LOW_VOLUME_AWAITING_DURATION_IN_MINS
            );

        this.reactOnExpertWaitingForSessionTimestamp();
    }

    private turnOffTimer = () => {
        this.lowVolumeFlag = false;
        clearTimeout(this.lowVolumeTimer);
    };

    private turnOnTimer = (manually = false) => {
        const {waitingForSession, waitingForSessionTimestamp} = ExpertAvailabilityStore;

        const lowVolumeStateCountDown = manually
            ? this.lowVolumeAwaitingDurationInMs
            : this.lowVolumeAwaitingDurationInMs - (Date.now() - waitingForSessionTimestamp!.getTime());

        if (lowVolumeStateCountDown > 0) {
            this.turnOffTimer();

            this.lowVolumeTimer = setTimeout(() => {
                if (!!waitingForSession) {
                    this.lowVolumeFlag = true;
                }
            }, lowVolumeStateCountDown);
        } else {
            this.lowVolumeFlag = true;
        }
    };

    manualTimer = () => {
        if (!ExpertAvailabilityStore.waitingForSession) return this.turnOffTimer();
        return this.turnOnTimer(true);
    };

    private reactOnExpertWaitingForSessionTimestamp = () => {
        reaction(
            () => ExpertAvailabilityStore.waitingForSessionTimestamp,
            timestamp => {
                if (timestamp) return this.turnOnTimer();
                return this.turnOffTimer();
            }
        );
    };
}

export type LowVolumeStoreType = LowVolumeStore;
