import {ITweekStore, TweekStoredKeys} from 'tweek-local-cache';
import logger from '@anywhere-expert/logging';

const STORAGE_KEY = 'tweek_keys_cache';

export class CachedTweekStore implements ITweekStore {
    private storage: Storage = window.sessionStorage;

    save(keys: TweekStoredKeys) {
        this.storage[STORAGE_KEY] = JSON.stringify(keys);
        return Promise.resolve();
    }

    load() {
        const cachedKeys = this.storage[STORAGE_KEY];

        if (!cachedKeys) return Promise.resolve({});

        try {
            return Promise.resolve(JSON.parse(cachedKeys));
        } catch (error) {
            logger.warn('Failed parsing tweek cache', {err: error});
            delete this.storage[STORAGE_KEY];
            return Promise.resolve({});
        }
    }
}
