import React, {useState, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {Category, AddCategoryButton, CreateCategory} from './';
import CannedMessagesStoreV2 from '../../stores/CannedMessagesStoreV2';
import {useAnalytics} from 'react-shisell';
import {CannedMessage} from '@soluto-private/canned-messages-api-client';
import MostFrequentUsedCategory from './MostFrequentUsedCategory';
import EmptyStateIcon from '../../resources/EmptyStateIcon';
import {CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import DragAndDrop from '../DNDProvider';
import {useTweekValue} from 'react-tweek';
import SuggestionCategory from './SuggestionCategory';
import {useCannedMessagesNavigationStore} from '../../useCannedMessagesNavigationStore';

const CategoriesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
`;

const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    align-items: center;
`;

const CircularProgressStyled = styled(CircularProgress)`
    color: ${({theme}) => theme.colors.contrast.primary};
`;

const Text = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.black};
    margin-top: 12px;
`;

type Props = {
    onMessageSelection: (categoryTitle: string, categoryId: string, messageId: string, message: CannedMessage) => void;
};

const CategoriesList = observer(({onMessageSelection}: Props) => {
    const analytics = useAnalytics();
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();
    const categories = useMemo(
        () =>
            Object.entries(
                !cannedMessagesNavigationStore.searchedText
                    ? CannedMessagesStoreV2.expertCategories
                    : cannedMessagesNavigationStore.filteredMessages
            ).sort(([, {displayOrder: a}], [, {displayOrder: b}]) => a - b),
        [
            CannedMessagesStoreV2.expertCategories,
            cannedMessagesNavigationStore,
            cannedMessagesNavigationStore.filteredMessages,
            cannedMessagesNavigationStore.searchedText,
        ]
    );

    const [addCategoryMode, setAddCategoryMode] = useState<boolean>(false);
    const isPasteToCanned = useTweekValue('support/session/canned_messages/allow_saving_pasted_messages', false);

    const onAddCategory = useCallback(() => {
        cannedMessagesNavigationStore.removeFocus();
        setAddCategoryMode(true);
    }, [cannedMessagesNavigationStore]);

    const onCreateCategory = useCallback(
        async (categoryName: string) => {
            analytics.dispatcher.createScoped('Categories').withExtras({CategoryName: categoryName}).dispatch('AddNew');

            await CannedMessagesStoreV2.addNewCategory(categoryName);
            setAddCategoryMode(false);
        },
        [analytics]
    );

    const onCancelCreate = useCallback(() => {
        setAddCategoryMode(false);
    }, []);

    if (CannedMessagesStoreV2.isLoading) {
        return (
            <EmptyContainer>
                <CircularProgressStyled size={24} thickness={2} variant="indeterminate" />
            </EmptyContainer>
        );
    }

    if (cannedMessagesNavigationStore.isNoMatches) {
        return (
            <EmptyContainer data-test-id="CannedMessages_No_Match">
                <EmptyStateIcon />
                <Text size="s">No matching results</Text>
            </EmptyContainer>
        );
    }

    const {suggestions} = CannedMessagesStoreV2.expertCannedMessages;
    return (
        <DragAndDrop>
            {categories?.length ? (
                <CategoriesContainer>
                    {isPasteToCanned && <SuggestionCategory suggestions={suggestions || {}} />}
                    {!cannedMessagesNavigationStore.isSearchMode && (
                        <MostFrequentUsedCategory
                            messages={CannedMessagesStoreV2.mostFrequentlyUsedMessages}
                            onMessageSelection={onMessageSelection}
                        />
                    )}
                    {categories.map(([id, category]) => (
                        <Category
                            key={id}
                            id={id}
                            category={category}
                            onMessageSelection={onMessageSelection}
                            collapseAll={cannedMessagesNavigationStore.collapseAll}
                        />
                    ))}
                </CategoriesContainer>
            ) : null}
            {addCategoryMode ? <CreateCategory onCreate={onCreateCategory} onCancel={onCancelCreate} /> : null}
            {CannedMessagesStoreV2.areActionsEnabled && (
                <AddCategoryButton onClick={onAddCategory} disabled={addCategoryMode} />
            )}
        </DragAndDrop>
    );
});

export default CategoriesList;
