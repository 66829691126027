import {ExpertApi, PartialExpert} from '@soluto-private/expert-api-client-v1';
import {authorizedFetch} from '@anywhere-expert/auth';
import config from './config';
import uuid from 'uuid';

let client: ExpertApi | undefined;

const getClient = (): ExpertApi => {
    if (!client) {
        client = new ExpertApi(undefined, config.expertApiUrl, authorizedFetch);
    }

    return client;
};

export const getExpert = async (expertId: string) => getClient().get(expertId, uuid());

export const updateExpert = async (expertId: string, expertData: PartialExpert) =>
    getClient().update(expertId, {
        meta: {
            correlationId: uuid(),
        },
        payload: expertData,
    });
