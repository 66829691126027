import React from 'react';
import {compose, withStateHandlers, lifecycle} from 'recompose';
import {CoreText, CoreButton, CoreButtonProps, CoreIcon} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';
import {withDosAndDonts, DosAndDontsOptionState} from './behaviours';
import {CheckMarkIcon, StarIcon} from '@anywhere-expert/icons';
import {withAnalytics, WithAnalyticsProps, enrichAnalytics, withAnalyticOnView} from 'react-shisell';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ItalicText = styled(CoreText)`
    font-style: italic;
    text-align: center;
    width: 20vw;
    line-height: 22px;
`;

const StarContainer = styled.div`
    margin-bottom: 15px;
`;

const GotItButton = styled(({isPressed, ...rest}: {isPressed: boolean} & CoreButtonProps) => <CoreButton {...rest} />)`
    margin-top: 20px;
    visibility: ${props => (props.isPressed ? 'collapse' : 'visible')};
`;

type DosAndDontsProps = {
    message: DosAndDontsOptionState;
    handleUserPressed: (currentMessage: DosAndDontsOptionState) => void;
    isPressed: boolean;
    setIsPressed: () => void;
    mountTime: number;
    setMountTime: (time: number) => void;
} & WithAnalyticsProps;

const DosAndDonts = ({message, setIsPressed, isPressed}: DosAndDontsProps) => {
    const theme = useTheme();
    const isButtonPressed = isPressed || message.wasPressed;
    return (
        <Container data-test-id="DosAndDonts">
            <StarContainer>
                <CoreIcon
                    icon={StarIcon}
                    color={isButtonPressed ? theme.colors.semantic.yellow.normal : theme.colors.neutral.grey.deep}
                    size="l"
                />
            </StarContainer>
            <ItalicText textType="secondary" size="l" weight="bold">
                {message.option.title}
            </ItalicText>
            <ItalicText textType="tertiary" size="l">
                {message.option.text}
            </ItalicText>
            <GotItButton
                variant="outlined"
                isPressed={isButtonPressed}
                icon={CheckMarkIcon}
                text="Got it"
                onClick={setIsPressed}
                size="s"
            />
        </Container>
    );
};

interface DosAndDontsState {
    isPressed: boolean;
    mountTime: number;
}

type StateHandlers = {
    setIsPressed: () => Partial<DosAndDontsState>;
    setMountTime: (mountTime: number) => Partial<DosAndDontsState>;
};

const enhance = compose<DosAndDontsProps, {}>(
    withDosAndDonts,
    withAnalytics,
    enrichAnalytics(dispatcher => dispatcher.createScoped('BestPractices')),
    withStateHandlers<DosAndDontsState, StateHandlers, DosAndDontsProps>(
        {isPressed: false, mountTime: 0},
        {
            setIsPressed: ({mountTime}, {message, analytics, handleUserPressed}) => () => {
                analytics.dispatcher
                    .createScoped('BestPractices')
                    .withExtra('Headline', message.option.title)
                    .withExtra('Message', message.option.text)
                    .withExtra('ViewDuration', Math.round((Date.now() - mountTime) / 1000))
                    .dispatch('Click');
                handleUserPressed(message);

                return {isPressed: true};
            },
            setMountTime: () => (mountTime: number) => {
                return {mountTime};
            },
        }
    ),
    lifecycle<DosAndDontsProps, {}>({
        componentDidMount() {
            this.props.setMountTime(Date.now());
        },
    }),
    withAnalyticOnView({
        analyticName: 'View',
        mapPropsToExtras: ({message}: {message: DosAndDontsOptionState}) => ({
            Headline: message.option.title,
            Message: message.option.text,
        }),
    })
);

export default enhance(DosAndDonts);
