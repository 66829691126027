import {useMemo} from 'react';
import {CannedMessagesStoreV2} from '@anywhere-expert/canned-messages-v2';

const useSortedCategories = () => {
    const categories = useMemo(
        () =>
            Object.entries(CannedMessagesStoreV2.expertCategories)
                .sort(([, {displayOrder: a}], [, {displayOrder: b}]) => a - b)
                .map(([id, category]) => ({
                    id,
                    category,
                })),

        [CannedMessagesStoreV2.expertCategories]
    );

    return categories;
};

export default useSortedCategories;
