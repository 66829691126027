import {compose, withHandlers} from 'recompose';
import uuid from 'uuid';
import {buildAttachment, postItem} from './mediaUtils';
import {UploadStatus} from '../storageApi';
import {User} from '@anywhere-expert/auth';
import {ItemState} from './mediaConsts';
import uploadAttachment from './uploadAttachment';
import {withAnalytics, WithAnalyticsProps} from 'react-shisell';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';

interface uploadAttachmentToTimelineProps {
    user: User;
    maxCacheAgeInSeconds: number;
    timeToCancel: number;
}

export default compose(
    withAnalytics,
    withHandlers<any, any>({
        uploadAttachmentToTimeline: (props: uploadAttachmentToTimelineProps & WithAnalyticsProps) => async (
            timelineId: string,
            mediaFile: File,
            sessionId: string
        ) => {
            const contentId = uuid();
            const supportItem = ExpertFeedStore.getSupportItem(sessionId);
            const {user, analytics} = props;

            if (!supportItem) return;

            const uploadingItem = await buildAttachment(
                user,
                contentId,
                ItemState.uploading,
                sessionId,
                supportItem.supportItemDetails.partner
            );
            await postItem(supportItem, uploadingItem, ItemState.uploading);
            try {
                const url = await uploadAttachment(timelineId, contentId, mediaFile);

                if (localStorage.getItem(`${contentId}_cancel`) !== 'true') {
                    const uploadedItem = await buildAttachment(
                        user,
                        contentId,
                        ItemState.uploaded,
                        sessionId,
                        supportItem.supportItemDetails.partner,
                        url
                    );
                    await postItem(supportItem, uploadedItem, ItemState.uploaded);

                    analytics.dispatcher
                        .withIdentity('SessionId', sessionId)
                        .withIdentity('CustomerId', timelineId)
                        .withExtra('AttachmentUrl', url)
                        .dispatch('ExpertAttachmentSent');

                    return {
                        status: UploadStatus.completed,
                        url,
                    };
                }

                supportItem.timelineModel.removeLocalMessage(contentId);

                return {
                    status: UploadStatus.canceled,
                    url: null,
                };
            } catch (err) {
                return {
                    status: UploadStatus.canceled,
                    url: null,
                };
            }
        },
    })
);
