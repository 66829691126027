import {generateMarkName} from './performanceMarkNameGenerator';

const start = (pathname: string) => {
    window.performance.mark(generateMarkName(pathname).startMark);
};

const isMeasurementOnGoing = (pathname: string): boolean =>
    window.performance.getEntriesByName(generateMarkName(pathname).startMark).length > 0;

const end = (pathname: string): PerformanceEntry | null => {
    const markName = generateMarkName(pathname);

    if (!getLastMeasurement(markName.startMark)) {
        return null;
    }

    window.performance.mark(markName.endMark);
    window.performance.measure(pathname, markName.startMark, markName.endMark);

    return getLastMeasurement(pathname);
};

const getLastMeasurement = (entryName: string) => {
    const pageMeasurements: PerformanceEntry[] = window.performance.getEntriesByName(entryName);

    return pageMeasurements.length > 0 ? pageMeasurements[pageMeasurements.length - 1] : null;
};

export default {
    start,
    end,
    getLastMeasurement,
    isMeasurementOnGoing,
};
