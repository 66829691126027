import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {Flipped, spring} from 'react-flip-toolkit';
import RecBody from './RecBody';
import {Recommendation} from '../types';
import {recommendationTypes} from '../consts';
import RecommendationsStore from '../RecommendationsStore';
import useIntersection from '../useIntersection';
import {AnalyticsDispatcher} from 'shisell';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin-right: 16px;
    height: 96px;
    width: 210px;
    will-change: transform, opacity;
`;

function onAppear(el: HTMLDivElement) {
    spring({
        values: {
            opacity: [0, 1],
        },
        onUpdate: values => {
            el.style.opacity = values['opacity'];
        },
    });
}
function onExit(el: HTMLDivElement, _: number, removeElement: () => void) {
    spring({
        values: {
            opacity: [1, 0],
        },
        onUpdate: values => {
            el.style.opacity = values['opacity'];
        },
        onComplete: removeElement,
    });
}

type Props = {
    item: Recommendation;
    position: number;
    onActionClick?: (item: Recommendation) => void;
    dispatcher: AnalyticsDispatcher;
    store: RecommendationsStore;
};

const RecommendationItem = React.memo(({store, item, position, dispatcher, onActionClick}: Props) => {
    const type = recommendationTypes[item.type.toString()];

    const [ref, entry] = useIntersection({threshold: 0.3});
    useEffect(() => {
        type.onVisibilityChange(!!entry?.isIntersecting, item, {dispatcher, position});
    }, [item, type, dispatcher, position, entry]);

    const onActionClickCallback = useCallback(() => {
        type.onActionClick(item, {dispatcher, supportItem: store.parent.supportItem, position});
        onActionClick && onActionClick(item);
    }, [item, onActionClick, type, dispatcher, store, position]);

    return (
        <Flipped flipId={`rec-container-${item.id}`} translate onAppear={onAppear} onExit={onExit}>
            <Container className="rec-item" data-id={item.id} ref={ref}>
                <RecBody item={item} onActionClick={onActionClickCallback} itemType={type} />
            </Container>
        </Flipped>
    );
});

export default RecommendationItem;
