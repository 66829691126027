import React from 'react';
import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import {BoxContainer, Title} from './shared/styles';
import WhatsNextMessage from './whatsNext/WhatsNextMessage';
import {SMALL_WIDTH, MEDIUM_WIDTH} from './shared/consts';

const Container = styled(BoxContainer)`
    background-color: ${({theme}) => theme.utils.split(theme.colors.neutral.grey.lightest, palette.neutral.black)};
`;

const TextContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 50px;

    @media (min-width: ${SMALL_WIDTH}) and (max-width: ${MEDIUM_WIDTH}) {
        padding-left: 0px;
    }
`;

const WhatsNext = () => (
    <Container>
        <TextContainer>
            <Title>What's next?</Title>
            <WhatsNextMessage />
        </TextContainer>
    </Container>
);

export default WhatsNext;
