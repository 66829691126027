import React, {useState, useCallback, useMemo, useRef} from 'react';
import styled, {css} from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {CoreSwitch, CoreText, openModal} from '@anywhere-expert/base-ui';
import {CannedMessagesStoreV2, useSuggestionsCategory} from '@anywhere-expert/canned-messages-v2';
import {CategoryActions} from '../actions';
import {AddCannedMessagesModal} from '../../message/AddCannedMessagesModal';
import {UpdateCategoryPayload} from '@soluto-private/canned-messages-api-client';
import CategoryTitle from './CategoryTitle';
import palette from '@anywhere-expert/colors/palette';

const Container = styled.div<{collapsed: boolean}>`
    display: flex;
    flex-direction: column;
    width: 98%;
    margin-bottom: ${({collapsed}) => (!collapsed ? '8px' : '0')};
`;

const CategorySecondaryTitleText = styled(CoreText)<{isGrayedOut?: boolean}>`
    max-width: 220px;
    margin-left: 10px;
    color: ${({isGrayedOut, theme}) => isGrayedOut && theme.colors.neutral.grey.deep};
`;

const AlignRightItems = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const MainTitleContainer = styled.div<{
    collapsed: boolean;
    disabled: boolean;
    editMessageMode: boolean;
    shouldPad: boolean;
}>`
    user-select: none;
    position: relative;
    display: flex;
    flex: 1 1 0%;
    word-break: break-word;
    border-radius: 8px;
    transition: opacity 0.3s;
    cursor: ${({disabled}) => (disabled ? 'default !important' : 'pointer')};
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${({shouldPad, collapsed}) => (shouldPad || collapsed ? '10px 10px 10px 10px' : '10px 10px 0px 10px')};

    ${({theme, editMessageMode}) =>
        editMessageMode &&
        css`
            background: ${theme.colors.contrast.queuePanelHover};
        `};

    ${({theme, disabled, collapsed}) =>
        !disabled &&
        collapsed &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

interface Props {
    id: string;
    icon: string;
    title: string;
    secondaryTitle?: string;
    collapseAll?: () => void;
    isCollapsed: boolean;
    setCollapse: (newVal: boolean) => void;
    showOnlyCaret?: boolean;
    shouldShowIndicator?: boolean;
    isGrayedOut?: boolean;
    isDisabled?: boolean;
    toggleAction?: () => void;
    removeFocus: () => void;
}

const CategoryHeader = ({
    id,
    icon,
    title,
    secondaryTitle,
    collapseAll,
    isCollapsed,
    setCollapse,
    showOnlyCaret,
    shouldShowIndicator,
    isGrayedOut,
    isDisabled,
    toggleAction,
    removeFocus,
}: Props) => {
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('Categories'), []);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const suggestionsCategory = useSuggestionsCategory();

    const onEditSubmit = useCallback(
        async (updates: UpdateCategoryPayload) => {
            await CannedMessagesStoreV2.editCategory(id, updates);
            dispatcher.withExtras({...updates, CategoryId: id}).dispatch('Edit');
            setEditMode(false);
        },
        [id, dispatcher]
    );

    const onSubmitDeleteCategory = useCallback(() => {
        dispatcher.withExtras({CategoryId: id}).dispatch('Delete');
        CannedMessagesStoreV2.deleteCategory(id);
    }, [id, dispatcher]);

    const toggleCollapse = useCallback(() => {
        setCollapse(!isCollapsed);
    }, [setCollapse, isCollapsed]);

    const onAddMessage = useCallback(() => {
        suggestionsCategory.current &&
            analytics.dispatcher
                .createScoped('Messages')
                .withExtras({
                    TargetCategoryId: id,
                    TargetCategoryName: title,
                    AddedBy: id === suggestionsCategory.current.id ? 'Automatic' : 'Manual',
                })
                .dispatch('AddNew');

        openModal(AddCannedMessagesModal, {
            id: 'add-message-modal',
            contentProps: {text: '', sourceCategoryId: id},
        });
    }, [suggestionsCategory.current, analytics, id]);

    const onMouseEnter = useCallback(() => {
        setIsHover(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setIsHover(false);
    }, []);

    const targetRef = useRef(null);

    return (
        <Container collapsed={isCollapsed}>
            <MainTitleContainer
                data-test-id={`CannedMessages_${id}`}
                disabled={editMode || !!isDisabled}
                collapsed={isCollapsed}
                editMessageMode={editMode}
                onClick={editMode ? undefined : toggleCollapse}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={targetRef}
                shouldPad={!secondaryTitle}
            >
                <CategoryTitle
                    icon={icon}
                    editMode={editMode}
                    onEditSubmit={onEditSubmit}
                    isCollapsed={isCollapsed}
                    title={title}
                    shouldShowIndicator={shouldShowIndicator}
                    isGrayedOut={isGrayedOut}
                />
                <AlignRightItems>
                    {toggleAction && (
                        <CoreSwitch
                            data-test-id={`canned-messages-toggle`}
                            checked={!isDisabled}
                            title={`Turn this category ${isDisabled ? 'on' : 'off'}`}
                            onChange={toggleAction}
                            checkedColor={palette.brand.blue}
                        />
                    )}
                    <CategoryActions
                        isHover={isHover}
                        areActionsEnabled={CannedMessagesStoreV2.areActionsEnabled}
                        showOnlyCaret={showOnlyCaret}
                        onAddMessage={onAddMessage}
                        isDisabled={editMode}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setCollapse}
                        setIsHover={setIsHover}
                        collapseAll={collapseAll}
                        setEditMode={setEditMode}
                        onDelete={onSubmitDeleteCategory}
                        categoryName={title}
                        targetRef={targetRef}
                        removeFocus={removeFocus}
                    />
                </AlignRightItems>
            </MainTitleContainer>
            {!isCollapsed && (
                <CategorySecondaryTitleText textType="secondary" size="s" isGrayedOut={isGrayedOut}>
                    {secondaryTitle}
                </CategorySecondaryTitleText>
            )}
        </Container>
    );
};

export default CategoryHeader;
