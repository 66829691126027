import React, {CSSProperties} from 'react';
import {CoreActionMenu, CoreTooltip, CoreActionMenuProps} from '@anywhere-expert/base-ui';
import {EmojiIcon} from '@anywhere-expert/icons';

const EmojiPickerView = React.lazy(() => import(/* webpackChunkName: "EmojiPickerView" */ './EmojiPickerView'));

const iconSize = {size: 's'};
const tooltipStyleOverride = {marginLeft: '6px'};
const actionMenuStyle = {
    menuItem: {padding: 0},
};
const shadowProps = {width: '24px', height: '24px'};
type Props = {
    insertEmoji: (string) => void;
    closeOnEmojiSelect?: boolean;
    emojiPickerStyle?: CSSProperties;
} & Partial<CoreActionMenuProps>;

const EmojiActionMenu = ({
    insertEmoji,
    closeOnEmojiSelect,
    buttonIcon,
    customButton,
    emojiPickerStyle,
    ...others
}: Props) => {
    const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);

    const toggleEmojiPicker = React.useCallback(() => {
        setShowEmojiPicker(prev => !prev);
    }, []);

    const onEmojiSelect = React.useCallback(
        (data: string) => {
            insertEmoji(data);
            if (closeOnEmojiSelect) toggleEmojiPicker();
        },
        [closeOnEmojiSelect, insertEmoji]
    );

    const icons = buttonIcon ? {buttonIcon} : customButton ? {customButton} : {buttonIcon: EmojiIcon};

    return (
        <CoreTooltip text="Emoji" styleOverride={tooltipStyleOverride} position="right">
            <div>
                <CoreActionMenu
                    directionUp={true}
                    extend={showEmojiPicker}
                    onClickOut={toggleEmojiPicker}
                    role="presentation"
                    onClick={toggleEmojiPicker}
                    styles={actionMenuStyle}
                    style={shadowProps}
                    size="s"
                    iconProps={iconSize}
                    {...icons}
                    {...others}
                    childStyle={{zIndex: 5}}
                >
                    <React.Suspense fallback={null}>
                        <EmojiPickerView style={emojiPickerStyle} onEmojiSelect={onEmojiSelect} />
                    </React.Suspense>
                </CoreActionMenu>
            </div>
        </CoreTooltip>
    );
};

export default EmojiActionMenu;
