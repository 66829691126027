import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const SpinnerContainer = styled.div<{color: string}>`
    color: ${({color}) => color};
`;

const Spinner = ({color}: {color: string}) => (
    <SpinnerContainer color={color}>
        <CircularProgress size={40} color="inherit" />
    </SpinnerContainer>
);

export default Spinner;
