import * as React from 'react';
import styled from 'styled-components';
import {CoreText, CoreIcon, CoreIconProps} from '@anywhere-expert/base-ui';
import {CheckCircleOutlineIcon} from '@anywhere-expert/icons';
import {QueueItemAttribute} from '@soluto-private/expert-queue-api-types';
import {useMessagingSalesColors} from '../../../../../hooks';
import moment from 'moment';

const ProgressItemContainer = styled.div`
    display: flex;
    margin: 20px 0 50px 0;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const ProgressItemIcon = styled(CoreIcon)`
    min-height: 40px;
    background-color: ${({theme}) => theme.colors.background.secondary};
    z-index: 1;

    svg {
        height: 24px;
    }
`;

const ProgressTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProgressTitle = styled(CoreText)`
    display: flex;
    align-items: center;
`;

const Checkmark = styled(CheckCircleOutlineIcon)`
    margin-left: 5px;
`;

interface EnrollmentProgressItemProps {
    title: string;
    icon: CoreIconProps['icon'];
    attribute?: QueueItemAttribute;
    dataTestId?: string;
}

const EnrollmentProgressItem = ({title, icon, attribute, dataTestId}: EnrollmentProgressItemProps) => {
    const colors = useMessagingSalesColors();

    return (
        <ProgressItemContainer data-test-id={dataTestId}>
            <ProgressItemIcon icon={icon} color={attribute ? colors.black : colors.grey} />
            <ProgressTextContainer>
                <ProgressTitle size="l" textType={attribute ? 'primary' : 'tertiary'}>
                    {title} {attribute ? <Checkmark size="m" color={colors.green} /> : ''}
                </ProgressTitle>
                {attribute ? (
                    <CoreText size="s" textType="tertiary">
                        {moment(attribute.fields.createdAt).format('h:mma')}
                    </CoreText>
                ) : null}
            </ProgressTextContainer>
        </ProgressItemContainer>
    );
};

export default EnrollmentProgressItem;
