import {init, loggers, updateContext, LogLevel, LogFunc} from '@anywhere-expert/logging';

const defaultContext = {
    source: 'anywhere-expert-app',
    platform: 'web',
};

export function initLogger() {
    const isDev = process.env.NODE_ENV === 'development';
    const isBehaviourTest = process.env.IS_BEHAVIOUR_TEST === 'true';

    init(getLoggers(isDev, isBehaviourTest), defaultContext, getLogLevel(isDev, isBehaviourTest));
}

export function updateLoggerContext(context) {
    updateContext({
        ...defaultContext,
        ...context,
    });
}

function getLoggers(isDev: boolean, isBehaviourTest: boolean): LogFunc[] {
    if (isDev) {
        return [loggers.logConsole];
    }

    if (isBehaviourTest) {
        console.log('isBehaviourTest', isBehaviourTest);
        return [loggers.logConsole, loggers.loggingApi];
    }

    return [loggers.loggingApi, loggers.logBugsnag, loggers.logMonitor, loggers.errorLogConsole];
}

function getLogLevel(isDev: boolean, isBehaviourTest: boolean): LogLevel {
    if (isDev) {
        return 'debug';
    }

    if (isBehaviourTest) {
        return 'trace';
    }

    return 'warn';
}
