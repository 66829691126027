import React from 'react';

const OpenCategoryIcon = ({color, ...others}) => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.43323e-05 1.41L5.00012 6L3.43323e-05 10.59L1.41003 12L7.41003 6L1.41003 0L3.43323e-05 1.41Z"
            fill={color}
        />
    </svg>
);

export default OpenCategoryIcon;
