import React from 'react';
import styled from 'styled-components';
import StarIcon from './assets/StarIcon';
import {CoreText} from '@anywhere-expert/base-ui';

const Container = styled.div`
    position: relative;
`;

const CustomCoreText = styled(CoreText)`
    position: absolute;
    top: 15%;
    right: 47%;
`;

const StarComponent = () => (
    <Container>
        <CustomCoreText size="xs" textType="black">
            5
        </CustomCoreText>
        <StarIcon />
    </Container>
);

export default StarComponent;
