import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {CoreText} from '@anywhere-expert/base-ui';
import {ConclusionState} from '../../../types';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import SalesMessageSection from '../sales-tools/sales-messages/SalesMessageSection';
import {useConclusionContent} from '../../../hooks';
import {Line} from '../../styled-components';

type SalesToolsConcludedProps = SalesSessionProps & {
    conclusionState: ConclusionState;
    dataTestId?: string;
};

const Container = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Blub = styled.img`
    height: 175px;
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};
`;

const ConclusionText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    margin-top: 20px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
`;

const SalesToolsConcluded = observer(({selectedSession, conclusionState, dataTestId}: SalesToolsConcludedProps) => {
    const {customerId} = selectedSession;
    const conclusionContent = useConclusionContent(conclusionState);

    return (
        <Container data-test-id={dataTestId}>
            <Blub src={conclusionContent.illustration} />
            <ConclusionText size="m">{conclusionContent.text}</ConclusionText>
            <Line />
            <SalesMessageSection
                customerId={customerId}
                title={conclusionContent.title}
                messages={conclusionContent.messages}
                dataTestId={conclusionContent.dataTestId}
            />
        </Container>
    );
});

export default SalesToolsConcluded;
