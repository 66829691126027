import React, {useEffect, memo, useCallback} from 'react';
import {HelixArticleItem} from '../types';
import {CoreText} from '@anywhere-expert/base-ui';
import {getArticleType} from './ArticleType';
import {getHelixUrl} from './shared';
import {AnalyticsDispatcher} from 'shisell';
import styled from 'styled-components';
import {CoreIcon, CoreLink} from '@anywhere-expert/base-ui';
import {palette} from '@anywhere-expert/colors';

const StyledCoreIcon = styled(CoreIcon)`
    color: ${({theme}) => theme.colors.contrast.caption};
    margin-right: 4px;
`;

const StyledCoreLink = styled(CoreLink)`
    font-style: unset;
    color: ${({theme}) => theme.colors.brand.blue};
    text-decoration: unset;
    &:hover {
        font-style: unset;
        font-weight: unset;
        color: ${palette.brand.blue};
    }
    &:active {
        font-style: unset;
        color: ${palette.primary.blue.deeper};
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
`;

const ArticleContainer = styled.div`
    margin: 0 24px 8px 8px;
`;

const ArticlePreview = memo(
    ({
        title,
        article_id,
        type,
        date_modified,
        dispatcher,
    }: HelixArticleItem & {
        dispatcher: AnalyticsDispatcher;
    }) => {
        useEffect(() => {
            dispatcher.dispatch('View');
        }, []);

        const onClickCallback = useCallback(() => {
            dispatcher.withExtra('Action', 'open').dispatch('Click');
        }, [dispatcher]);

        const articleType = getArticleType(type);

        return (
            <ArticleContainer key={article_id}>
                <StyledCoreLink
                    size="s"
                    linkType="external"
                    href={getHelixUrl(article_id, type)}
                    onClick={onClickCallback}
                >
                    {title}
                </StyledCoreLink>
                <Footer>
                    <StyledCoreIcon icon={articleType.Icon} size="s" />
                    <CoreText size="s" textType="tertiary">
                        {articleType.title}, {date_modified}
                    </CoreText>
                </Footer>
            </ArticleContainer>
        );
    }
);

export default ArticlePreview;
