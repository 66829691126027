import React from 'react';
import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 5,
    maxTimeout: 2000,
};

export const Login = React.lazy(() => pRetry(() => import(/* webpackChunkName: "Login" */ './Login'), retryConfig));

export const AppLayout = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "AppLayout" */ '../components/Layout'), retryConfig)
);

export const MonitorFlow = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "MonitorFlow" */ './MonitorFlow'), retryConfig)
);

export const Home = React.lazy(() => pRetry(() => import(/* webpackChunkName: "Home" */ './Home'), retryConfig));
export const UserManagement = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "UserManagement" */ './UserManagement'), retryConfig)
);
export const RecordingsManagement = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "RecordingsManagement" */ './RecordingsManagement'), retryConfig)
);
export const Supervisor = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "Supervisor" */ '@anywhere-expert/supervisor-page'), retryConfig)
);

export {default as Logout} from './Logout';
