import React from 'react';
import styled from 'styled-components';
import {BoxContainer, Title} from './shared/styles';
import {useDosAndDonts} from '@anywhere-expert/suggestive-behaviour';
import {SMALL_WIDTH} from './shared/consts';
import {palette} from '@anywhere-expert/colors';
import {Bulb} from './resources/bulb';

const Container = styled(BoxContainer)`
    background-color: ${palette.brand.purple};
    color: ${palette.neutral.white};

    @media (max-width: ${SMALL_WIDTH}) {
        padding-left: 100px;
    }
`;

const TextContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Text = styled.span`
    font-size: 16px;
    font-weight: 300;
    line-height: 1.24;
    max-width: 280px;
`;

const StyledBulb = styled(Bulb)`
    position: absolute;
    transform: translate(-8px, calc(-100% - 8px));
`;

const Tip = () => {
    const {message} = useDosAndDonts();

    return (
        <Container data-test-id="Tip">
            <TextContainer>
                <StyledBulb />
                <Title>Tip time</Title>
                <Text>{message ? message.option.text : ''}</Text>
            </TextContainer>
        </Container>
    );
};

export default Tip;
