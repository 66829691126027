import {action, observable, makeObservable} from 'mobx';
import {ConnectionStatus, RoomUpdates, SocketEvent} from '@soluto-private/live-messaging-api-client';
import {createClient} from './roomClient';
import logger from '@anywhere-expert/logging';
import debounceConnectionStatus from './debounceConnectionStatus';
import {Observable} from 'rxjs';

class MessagingStore {
    connected: boolean = false;
    connectionStatus: Observable<ConnectionStatus>;
    roomUpdates: (roomId: string) => Observable<RoomUpdates>;
    requestSnapshotOnRoom: (roomId: string, limit?: number) => Promise<any>;

    constructor() {
        makeObservable(this, {
            connected: observable,
            setConnectionStatus: action,
        });

        const {connectionStatus$, roomUpdates, requestSnapshotOnRoom, socketEvents$} = createClient();

        // @ts-ignore
        this.roomUpdates = roomUpdates;
        this.requestSnapshotOnRoom = requestSnapshotOnRoom;
        this.connectionStatus = connectionStatus$;
        this.registerSocketEvents(socketEvents$);

        debounceConnectionStatus(connectionStatus$).subscribe(
            this.setConnectionStatus,
            this.handleError,
            this.handleComplete
        );
    }

    requestSnapshot = (roomId: string, limit?: number) => this.requestSnapshotOnRoom(roomId, limit);
    getRoomUpdates = (roomId: string) => this.roomUpdates(roomId);

    setConnectionStatus = ({reason, status, connectionEvent}: ConnectionStatus) => {
        this.connected = status;
        if (reason) {
            logger.warn('Live messaging client errored', {extra: {reason, connectionEvent, status}});
        }
    };

    registerSocketEvents(socketEvents$: Observable<SocketEvent>) {
        socketEvents$.subscribe(({name, extra}) => {
            logger.debug('Socket Event Received', {extra: {name, ...extra}});
        });
    }

    handleError = (connectionStatus: ConnectionStatus) => {
        this.setConnectionStatus(connectionStatus);
        logger.warn('Live messaging client errored', {extra: {connectionStatus}});
    };

    handleComplete = () => {
        logger.info('Live messaging client completed');
    };
}

export default new MessagingStore();
