import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 112px;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
`;

const Spinner = () => (
    <SpinnerContainer>
        <CircularProgress size={30} color="inherit" />
    </SpinnerContainer>
);

export default Spinner;
