import {ExpertFeedStore} from '../ExpertFeedStore';
import {QueueItemAttribute, QueueItemWithoutAttributes} from '@soluto-private/expert-queue-api-types';
import QueueItem from './QueueItem';

export default class TaskItem extends QueueItem {
    taskRegistryId: string;
    taskType: string;
    taskSubtype: string;
    taskDisplayName: string;

    constructor(store: ExpertFeedStore, item: QueueItemWithoutAttributes, attributes: QueueItemAttribute[] = []) {
        super(store, item, attributes);

        const taskTypeAttribute = this.supportItemDetails.attributes.filter(x => x.attributeType === 'task-type')?.[0];
        if (!taskTypeAttribute) {
            throw new Error(
                `Could not create QueueItemTaskDetailsModel, missing task-type attribute, received \n ${JSON.stringify(
                    this.supportItemDetails.attributes
                )}`
            );
        }

        this.taskRegistryId = taskTypeAttribute.key;
        this.taskType = taskTypeAttribute.fields?.['type'];
        this.taskSubtype = taskTypeAttribute.fields?.['subtype'];
        this.taskDisplayName = taskTypeAttribute.fields?.['displayName'];
    }

    isTask(): this is TaskItem {
        return true;
    }
}
