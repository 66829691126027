import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';

export const useHorizonEnrollment = () => useTweekValue(MessagingSalesTweekKeys.IsHorizonEnrollmentEnabled, false);

export const useHorizonEnrollmentClickToSend = () =>
    useTweekValue(MessagingSalesTweekKeys.IsHorizonEnrollmentClickToSendEnabled, false);

export const useHorizonEnrollmentProductButton = () =>
    useTweekValue(MessagingSalesTweekKeys.IsHorizonEnrollmentProductButtonEnabled, false);
