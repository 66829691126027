import {useEffect} from 'react';
import {useTweekValue} from 'react-tweek';
import {useUserProfile} from '@anywhere-expert/auth';
import fullstory from '../fullstory';

const useFullstory = () => {
    const isEnabled = useTweekValue('support/session/fullstory/is_enabled', false);
    const user = useUserProfile();

    useEffect(() => {
        if (isEnabled && user?.uid) {
            fullstory.initalize(user.uid, {
                displayName: user.displayName,
                email: user.email,
                uid: user.uid,
                roles: user.roles,
                queues: user.queues,
                groups: user.groups,
                isLoggedIn: user.isLoggedIn,
                expertise: user.expertise,
            });
        }
    }, [isEnabled, user]);
};

export default useFullstory;
