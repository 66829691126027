import {CoreIconButton, CoreIconButtonProps, CoreTooltip} from '@anywhere-expert/base-ui';
import React, {useCallback} from 'react';
import {useAnalytics} from 'react-shisell';
import styled from 'styled-components';

const IconButton = styled(CoreIconButton)`
    margin: 4px 0;
`;

type ActionButtonProps = {
    onClick: VoidFunction;
    isActive?: boolean;
    isDisabled?: boolean;
    icon: CoreIconButtonProps['icon'];
    tooltip: string;
    analyticsActionId: string;
    color?: string;
};

export default ({
    icon,
    onClick,
    tooltip,
    isActive,
    analyticsActionId: actionId,
    isDisabled,
    color,
}: ActionButtonProps) => {
    const analytics = useAnalytics();
    const dispatchClickAnalytic = useCallback(
        () =>
            analytics.dispatcher
                ?.withExtras({
                    SubSection: 'ActionBar',
                    Status: 'Active',
                    ActionId: actionId,
                    Cta: `${actionId}Button`,
                })
                .dispatch('Click'),
        [analytics, actionId]
    );

    const enhancedOnClick = useCallback(() => {
        dispatchClickAnalytic();
        onClick();
    }, [dispatchClickAnalytic, onClick]);

    return (
        <CoreTooltip text={tooltip}>
            <span>
                <IconButton
                    variant="icon"
                    icon={icon}
                    onClick={enhancedOnClick}
                    isActive={isActive}
                    disabled={isDisabled}
                    colorOverride={color}
                    size="s"
                />
            </span>
        </CoreTooltip>
    );
};
