import {OfferSelection, OfferDetails, MessagingSalesOffer, ExpertInteraction} from '../types';
import {userProfile$} from '@anywhere-expert/auth';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {
    getEnvironmentAttributeKey,
    SalesEligibility,
    HorizonEnrollmentUrlProps,
    AttributeTypes,
    AttributeKeys,
    SalesInteractionFields,
    OfferDetectionFields,
    ResolutionDetectionFields,
    SalesKeywordMetadata,
    HorizonEnrollmentState,
} from '@soluto-private/smart-home-anywhere-expert';
import {AttributesByType} from '@soluto-private/session-api-types';

type TailoredOfferRecommendationsFields = any;

export const getEnrollmentUrlFromSession = (session: SupportItem): string | undefined => {
    return session?.sessionAttributes?.[AttributeTypes.SmartHome]?.[AttributeKeys.Sales]?.fields?.enrollmentUrl;
};

export const getProductUrlFromSession = (session: SupportItem): string | undefined => {
    return session?.sessionAttributes?.[AttributeTypes.SmartHome]?.[AttributeKeys.Sales]?.fields?.productUrl;
};

const getUrlManually = (longUrlProps: HorizonEnrollmentUrlProps, sessionId: string): string => {
    const {baseUrl, sessionIdParam, expertIdParam} = longUrlProps;

    return `${baseUrl}?${sessionIdParam}=${sessionId}&${expertIdParam}=${getExpertId()}`;
};

export const getEnrollmentUrl = (session: SupportItem, longUrlProps: HorizonEnrollmentUrlProps): string => {
    const upsertedUrl = getEnrollmentUrlFromSession(session);
    return upsertedUrl || getUrlManually(longUrlProps, session.sessionId);
};

export const sessionHasSmartHomeFields = (session: SupportItem): boolean =>
    session?.sessionAttributes?.[AttributeTypes.SmartHome]?.[AttributeKeys.CustomerGraph]?.fields !== undefined;

export const getAtlasId = (session: SupportItem): string | undefined =>
    session?.sessionAttributes?.[AttributeTypes.SmartHome]?.[AttributeKeys.CustomerGraph]?.fields?.atlasId;

export const getSalesInteractionFields = (sessionAttributes: AttributesByType): SalesInteractionFields | undefined => {
    return sessionAttributes?.[getEnvironmentAttributeKey(AttributeTypes.SalesInteractions)]?.[getExpertId()]
        ?.fields as SalesInteractionFields;
};

export const getAmEnrollmentProgress = (sessionAttributes: AttributesByType): HorizonEnrollmentState | undefined =>
    sessionAttributes?.['dev-am-enrollment-progress']?.[getExpertId()].fields?.status;

export const getOfferDetectionFields = (sessionAttributes: AttributesByType): OfferDetectionFields | undefined =>
    sessionAttributes?.[getEnvironmentAttributeKey(AttributeTypes.OfferDetection)]?.[getExpertId()]
        ?.fields as OfferDetectionFields;

export const getTailoredOfferRecommendationsFields = (
    sessionAttributes: AttributesByType
): TailoredOfferRecommendationsFields | undefined =>
    sessionAttributes?.[getEnvironmentAttributeKey(AttributeTypes.TailoredSales)]?.[AttributeKeys.Recommendations]
        ?.fields as TailoredOfferRecommendationsFields;

export const getTailoredSalesKeywordFrequencyMap = (
    sessionAttributes: AttributesByType
): SalesKeywordMetadata[] | undefined =>
    sessionAttributes?.[getEnvironmentAttributeKey(AttributeTypes.TailoredSales)]?.[AttributeKeys.Recommendations]
        ?.fields?.prioritizedKeywords as SalesKeywordMetadata[];

export const getResolutionDetectionFields = (
    sessionAttributes: AttributesByType
): ResolutionDetectionFields | undefined =>
    sessionAttributes?.[getEnvironmentAttributeKey(AttributeTypes.ResolutionDetection)]?.[getExpertId()]
        ?.fields as ResolutionDetectionFields;

export const hasSalesInteractionFields = (session: SupportItem): boolean => {
    return !!getSalesInteractionFields(session.sessionAttributes);
};

export const isSalesInteractionCreated = (session: SupportItem): boolean => {
    return getSalesInteractionFields(session.sessionAttributes)?.status === 'SUCCESS';
};

export const getInteractionIdFromSession = (session: SupportItem): string | undefined => {
    return getSalesInteractionFields(session.sessionAttributes)?.interactionId;
};

export const isReadyToLoadInteraction = (session: SupportItem): boolean => {
    if (hasSalesInteractionFields(session)) {
        return isSalesInteractionCreated(session);
    }

    return false;
};

export const getEligibility = (session: SupportItem): SalesEligibility =>
    session?.sessionAttributes?.[AttributeTypes.SmartHome]?.[AttributeKeys.CustomerGraph]?.fields?.isEligible;

export const getEnrollmentEligibilityId = (session: SupportItem): string | undefined =>
    session?.sessionAttributes?.[AttributeTypes.SmartHome]?.[AttributeKeys.CustomerGraph]?.fields
        ?.enrollmentEligibilityId;

const getEligibilityDisabledText = (selectedSession: SupportItem) => {
    const ineligibleMessage = 'Customer ineligible for Sales';

    if (sessionHasSmartHomeFields(selectedSession)) {
        const isEligible = getEligibility(selectedSession);
        if (isEligible === false) {
            return ineligibleMessage;
        } else {
            return undefined;
        }
    }

    return 'Sales not yet available for session.';
};

export const getMessagingSalesDisabledText = (selectedSession: SupportItem) => {
    if (!selectedSession) {
        return 'Select a session to sell';
    }

    if (!selectedSession.isAssignedToMe) {
        return 'Available only for your assigned sessions';
    }

    return getEligibilityDisabledText(selectedSession);
};

export const getOfferFromInteraction = (interaction: ExpertInteraction): MessagingSalesOffer => {
    const {offered, accepted} = interaction;

    const offer: MessagingSalesOffer = {
        offerSelection: OfferSelection.NotOffered,
        interactionId: interaction.id,
        pool: interaction.partner,
        sessionId: interaction.metadata.anywhereExpertOfferSessionId!,
        offerSessionId: interaction.offerSessionId,
        atlasId: interaction.customerAtlasId,
        isEligible: interaction.eligible!,
        enrollmentProgress: interaction.metadata.enrollmentProgress,
        horizonEnrollmentProgress: interaction.metadata.horizonEnrollmentProgress,
        offerType: interaction.metadata.offerType,
    };

    if (offered && accepted) {
        offer.offerSelection = OfferSelection.OfferAccepted;
    } else if (offered === true && accepted === false) {
        offer.offerSelection = OfferSelection.OfferDeclined;
    }

    return offer;
};

export const getOfferDetailsFromSelection = (selection: OfferSelection): OfferDetails => {
    switch (selection) {
        case OfferSelection.OfferAccepted:
            return {
                offered: true,
                accepted: true,
            };
        case OfferSelection.OfferDeclined:
            return {
                offered: true,
                accepted: false,
            };
        default:
            return {
                offered: false,
                accepted: false,
            };
    }
};

export const getExpertId = (): string => {
    return userProfile$ && userProfile$.value && userProfile$.value.uid;
};

export const getHashCode = (string: string) => {
    if (!string) {
        return 0;
    }

    let hash = 0;
    if (string.length == 0) return hash;
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }

    return hash;
};
