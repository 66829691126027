import React, {useState} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';

import {CoreText, CoreTextArea} from '@anywhere-expert/base-ui';
import {InfoIcon as Icon} from '@anywhere-expert/icons';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {useSendMessage} from '@anywhere-expert/timeline-behaviours';

import {useMessagingSalesClickAnalytics, useCurrentOffer, useHorizonEnrollmentFlowContent} from '../../../../../hooks';
import {HorizonEnrollmentFlowStep, HorizonEnrollmentFlowContent} from '../../../../../types';
import {NextButton} from '../../../../styled-components';
import StepLabel from './StepLabel';

const PricingInfoContainer = styled(CoreTextArea)`
    width: 90%;
`;

const InfoText = styled(CoreText)`
    display: flex;
    align-items: center;
`;

const InfoIcon = styled(Icon)`
    margin-right: 5px;
`;

export const SendPricingInfo = observer(({selectedSession}: SalesSessionProps) => {
    const {title, buttonText, pricingInfo, infoText} = useHorizonEnrollmentFlowContent(
        HorizonEnrollmentFlowStep.SendPricingInfo
    ) as HorizonEnrollmentFlowContent[HorizonEnrollmentFlowStep.SendPricingInfo];
    const {sessionId, customerId, pool: partner} = selectedSession;
    const currentOffer = useCurrentOffer(sessionId);

    const clickedWithExtras = useMessagingSalesClickAnalytics('SharePricingInfoContinue');
    const sendMessage = useSendMessage(customerId, sessionId, partner);

    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

    const onNextClick = async () => {
        setNextButtonDisabled(true);
        clickedWithExtras({});
        try {
            await currentOffer?.submitPricingInfo(async () => {
                await sendMessage(pricingInfo);
            });
        } finally {
            setNextButtonDisabled(false);
        }
    };

    return (
        <div data-test-id="MessagingSales_HorizonEnrollmentFlow_SendPricingInfo">
            <StepLabel selectedSession={selectedSession} />
            <CoreText size="m">{title}</CoreText>
            <PricingInfoContainer
                data-test-id="PricingInfoArea"
                value={pricingInfo}
                isInputValid={true}
                disabled={true}
            />
            <InfoText size="s">
                <InfoIcon size="s" /> {infoText}
            </InfoText>
            <NextButton
                data-test-id="MessagingSales_EnrollmentFlow_NextButton"
                color="primary"
                onClick={onNextClick}
                text={buttonText}
                isInProgress={nextButtonDisabled}
            />
        </div>
    );
});

export default SendPricingInfo;
