import {setAppMessage} from '@anywhere-expert/app-messages';
import {Analytics} from 'react-shisell';

const copySessionIdHandler = (sessionId: string, analytics?: Analytics, onDone?: () => void) => {
    const handler = event => {
        event.preventDefault();
        event.clipboardData.setData('text/plain', sessionId);
        document.removeEventListener('copy', handler, true);
    };

    document.addEventListener('copy', handler, true);
    document.execCommand('copy');

    const toastText = `Session ID copied to clipboard`;
    setAppMessage({text: toastText, type: 'success'});
    onDone?.();
    analytics?.dispatcher.withExtra('ActionId', 'SessionIDCopy').dispatch('Click');
};

export default copySessionIdHandler;
