import {action, computed, observable, makeObservable, runInAction} from 'mobx';
import {
    MessagingUpdates,
    MessageUpdateValue,
    MessageDeletedValue,
    MessageHideValue,
    MessageSawValue,
    RoomTypingValue,
} from '@soluto-private/live-messaging-api-client';
import {
    Message,
    MessageEventType,
    RoomEventType,
    CreateMessagePayload,
    SeenData,
} from '@soluto-private/messaging-api-client';
import logger from '@anywhere-expert/logging';
import {Subscription} from 'rxjs';
import MessagingStore from './MessagingStore';
import {getRoomApi} from './';
import {getUserProfile} from '@anywhere-expert/auth';
import {EnhancedMessage, MessageStatus} from './types';

export default class RoomStore {
    roomId: string;
    expertId?: string;
    typingTimeoutId: number | undefined;
    isFirstItemsLoaded = false;
    messages = observable.map<string, EnhancedMessage>(undefined, {deep: false});
    isTyping = false;
    subscription: Subscription;

    constructor(roomId: string) {
        makeObservable<
            RoomStore,
            | 'handleSnapshot'
            | 'handleCreate'
            | 'handleDelete'
            | 'handleSaw'
            | 'handleHide'
            | 'handleUpdate'
            | 'handleTyping'
            | 'setMessage'
        >(this, {
            isFirstItemsLoaded: observable,
            isTyping: observable,
            handleSnapshot: action,
            handleCreate: action,
            handleDelete: action,
            handleSaw: action,
            handleHide: action,
            handleUpdate: action,
            clearTypingTimeout: action,
            setTyping: action,
            handleTyping: action,
            deleteMessage: action,
            setMessage: action,
            removeLocalMessage: action,
            createLocalMessage: action,
            createMessage: action,
            markMessageAsFailed: action,
            updateMessage: action,
            typing: action,
            items: computed,
        });

        this.roomId = roomId;
        this.expertId = getUserProfile()?.uid;
        this.connect();
        MessagingStore.connectionStatus
            .filter(e => e.status)
            .skip(1)
            .subscribe(() => {
                if (!this.isFirstItemsLoaded) return;
                logger.debug('Getting snapshot on reconnect in RoomStore', {extra: {roomId: this.roomId}});
                return this.requestSnapshot();
            });
    }

    dispose() {
        this.clearTypingTimeout();
        this.subscription.unsubscribe();
    }

    private async connect() {
        const connection = MessagingStore.getRoomUpdates(this.roomId);

        this.subscription = connection.subscribe(this.handleRoomEvents, this.handleError);
    }

    async requestInitialSnapshot(limit?: number) {
        if (this.isFirstItemsLoaded) return;
        return this.requestSnapshot(limit);
    }

    private async requestSnapshot(limit?: number) {
        await MessagingStore.requestSnapshot(this.roomId, limit);
        runInAction(() => {
            this.isFirstItemsLoaded = true;
        });
    }

    handleError = (err: any) => {
        logger.warn('RoomStore unexpected error', {err, extra: {roomId: this.roomId}});
    };

    async reconnect() {
        this.subscription.unsubscribe();
        await this.connect();
        logger.warn('Unexpected reconnection on RoomStore', {extra: {roomId: this.roomId}});
    }

    handleRoomEvents = (messagingUpdate: MessagingUpdates) => {
        try {
            switch (messagingUpdate.eventType) {
                case RoomEventType.Snapshot: {
                    return this.handleSnapshot(messagingUpdate.value as Message[]);
                }
                case MessageEventType.Created: {
                    return this.handleCreate(messagingUpdate.value as Message);
                }
                case MessageEventType.Deleted: {
                    const {messageId, lastState} = messagingUpdate.value as MessageDeletedValue;

                    return this.handleDelete(messageId, lastState);
                }
                case MessageEventType.Updated: {
                    const {messageId, payload} = messagingUpdate.value as MessageUpdateValue;

                    return this.handleUpdate(messageId, payload);
                }
                case MessageEventType.Hide: {
                    const {messageId, lastState} = messagingUpdate.value as MessageHideValue;

                    return this.handleHide(messageId, lastState);
                }
                case MessageEventType.Saw: {
                    const {messageId, seenAt} = messagingUpdate.value as MessageSawValue;

                    return this.handleSaw(messageId, seenAt);
                }
                case RoomEventType.Typing: {
                    const {senderId, typing} = messagingUpdate.value as RoomTypingValue;

                    return this.handleTyping(senderId, typing);
                }
            }
        } catch (err) {
            logger.error('Fail to handle message event', {
                err,
                extra: {roomId: this.roomId, eventType: messagingUpdate.eventType},
            });
        }
    };

    private handleSnapshot = (messages: Message[]) => {
        messages.forEach(message => {
            if (!message.messageId) return;

            if (!message.payload) {
                logger.error('received message event without a message payload', {
                    extra: {messageId: message.messageId, messageEvent: 'snapshot', message},
                });
                return;
            }

            this.messages.set(message.messageId, message);
        });
    };

    private handleCreate = (message: Message) => {
        if (!message.messageId || this.messages.get(message.messageId)) return;

        if (!message.payload) {
            logger.error('received message event without a message payload', {
                extra: {messageId: message.messageId, message, messageEvent: 'create'},
            });
            return;
        }

        this.messages.set(message.messageId, message);
    };

    private handleDelete = (messageId: string, lastState: string) => {
        const item = this.messages.get(messageId);

        if (!item) {
            return;
        }

        this.messages.set(messageId, {...item, lastState});
    };

    private handleSaw = (messageId: string, seenAt: SeenData) => {
        const item = this.messages.get(messageId);

        if (!item) {
            return;
        }

        this.messages.set(messageId, {...item, seenAt});
    };

    private handleHide = (messageId: string, lastState: string) => {
        const item = this.messages.get(messageId);

        if (!item) {
            return;
        }

        this.messages.set(messageId, {...item, lastState});
    };

    private handleUpdate = (messageId: string, payload: any) => {
        const item = this.messages.get(messageId);

        if (!item) {
            return;
        }

        if (!payload) {
            logger.warn('received message event without a message payload', {
                extra: {messageId, messageEvent: 'update', message: item},
            });
            return;
        }

        this.messages.set(messageId, {...item, payload});
    };

    clearTypingTimeout() {
        if (this.typingTimeoutId) clearTimeout(this.typingTimeoutId);
        this.typingTimeoutId = undefined;
    }

    setTyping(value: boolean) {
        this.isTyping = value;
    }

    private handleTyping = async (senderId: string, typing: boolean) => {
        if (senderId === this.expertId) return;

        this.clearTypingTimeout();
        this.setTyping(typing);

        this.typingTimeoutId = setTimeout(() => {
            this.setTyping(false);
        }, 3000);
    };

    async deleteMessage(messageId: string) {
        const item = this.messages.get(messageId);

        if (!item) return;

        this.messages.set(messageId, {...item, lastState: 'deleted'});

        return getRoomApi().markDeletedMessage(this.roomId, messageId);
    }

    private setMessage(message: Message) {
        this.messages.set(message.messageId, message);
    }

    removeLocalMessage(messageId: string) {
        this.messages.delete(messageId);
    }

    createLocalMessage(
        message: Partial<EnhancedMessage> & Pick<EnhancedMessage, 'payload' | 'messageId' | 'senderId' | 'metadata'>
    ) {
        const localMessage: EnhancedMessage = {
            recipientId: this.roomId,
            serverCreatedAt: Date.now(),
            createdAt: Date.now(),
            sentAt: Date.now(),
            status: MessageStatus.Pending,
            ...message,
        };

        this.setMessage(localMessage);
    }

    async createMessage(message: CreateMessagePayload) {
        if (!message.messageId) return;

        const localMessage: EnhancedMessage = {
            recipientId: this.roomId,
            messageId: message.messageId,
            serverCreatedAt: Date.now(),
            sessionId: message.sessionId,
            senderId: message.senderId,
            createdAt: Date.now(),
            metadata: message.metadata,
            sentAt: Date.now(),
            payload: message.payload,
        };

        this.setMessage(localMessage);

        try {
            const createdMessage = await getRoomApi().createMessage(this.roomId, {payload: message});
            this.setMessage(createdMessage);
        } catch (err) {
            logger.warn('Failed sending message', {err, extra: {message}});

            this.markMessageAsFailed(message.messageId);
        }
    }

    markMessageAsFailed(id: string) {
        const item = this.messages.get(id);

        if (!item) {
            return;
        }

        this.messages.set(id, {...item, status: MessageStatus.Failed});
    }

    async updateMessage(messageId: string, updates: Record<string, any>) {
        await getRoomApi().updateMessagePayload(this.roomId, messageId, {
            payload: {
                payloadValue: updates,
            },
        });
    }

    async typing(typing: boolean) {
        await getRoomApi().typing(this.roomId, {payload: {roomId: this.roomId, typing, senderId: this.expertId!}});
    }

    async resendMessage(messageId: string) {
        const prev = this.messages.get(messageId);
        if (!prev) return;

        return this.createMessage(prev);
    }

    get items(): EnhancedMessage[] {
        return Array.from(this.messages.values());
    }
}
