import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys, HorizonEnrollmentUrlProps} from '@soluto-private/smart-home-anywhere-expert';
import {getEnrollmentUrl} from '../utils/helpers';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {useHorizonEnrollment} from './useHorizonEnrollment';

const defaultHorizonEnrollmentProps: HorizonEnrollmentUrlProps = {
    baseUrl: '',
    sessionIdParam: '',
    expertIdParam: '',
};

export const useInterpolatedConclusions = (selectedSession: SupportItem): string[] => {
    const conclusions = useTweekValue(MessagingSalesTweekKeys.Conclusions, ['']) as string[];

    const isHorizonEnrollmentEnabled = useHorizonEnrollment();
    const enrollmentUrlProps = useTweekValue(
        MessagingSalesTweekKeys.HorizonEnrollmentUrlProps,
        defaultHorizonEnrollmentProps
    );

    if (isHorizonEnrollmentEnabled) {
        const enrollmentUrl = getEnrollmentUrl(selectedSession, enrollmentUrlProps);
        return conclusions.map(message => message.replace('<enrollmentUrl>', enrollmentUrl));
    }

    return conclusions;
};
