import {observable, action, makeObservable} from 'mobx';

class WelcomeStore {
    show = false;
    displayName: string | undefined = undefined;

    constructor() {
        makeObservable(this, {
            show: observable,
            displayName: observable,
            setShow: action,
        });
    }

    setShow(show: boolean, displayName?: string) {
        this.show = show;

        if (displayName) {
            this.displayName = displayName;
        }
    }
}

export default new WelcomeStore();
