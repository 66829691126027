const shortcutsMap = {
    'open-techview': {
        name: 'Open TechView',
        key: 'ctrl+shift+t',
    },
    'get-next': {
        name: 'Get Next Conversation',
        key: 'ctrl+shift+enter',
    },
    'show-shortcuts': {
        name: 'Show Available Shortcuts',
        key: 'ctrl+shift+?',
    },
    'session-notes': {
        name: 'Open Session Notes',
        key: 'ctrl+shift+o',
    },
    'open-canned-messages': {
        name: 'Open Canned Messages',
        key: 'ctrl+shift+c',
    },
    'input-focus': {
        name: 'Write a message',
        key: 'ctrl+shift+m',
    },
    'copy-mdn': {
        name: 'Copy Phone Number',
        key: 'ctrl+shift+p',
    },
    'copy-session-id': {
        name: 'Copy Session ID',
        key: 'ctrl+shift+s',
    },
    'toggle-sidebar': {
        name: 'Open/Close the sidebar',
        key: 'ctrl+shift+b',
    },
    'select-top-item': {
        name: 'Select top active conversation',
        key: 'ctrl+shift+1',
    },
    'find-in-page': {
        name: 'Search messages',
        key: 'ctrl+f',
    },
    'shift-paste': {
        name: 'Paste without formatting',
        key: 'ctrl+shift+v',
    },
} as const;

export default shortcutsMap;
