import * as React from 'react';
import styled from 'styled-components';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {useMessagingSalesClickAnalytics} from '../../../../hooks';
import {MessageButtonContainer, MessageText} from '../../../styled-components';
import {SendableMessageProps} from '../../../../types';

const MessageTextContainer = styled.span`
    overflow: hidden;
    display: flex;
    text-align: left;
    flex: 1;
    padding: 12px 16px;
`;

const SendableMessage = ({customerId, dataTestId, message = ''}: SendableMessageProps) => {
    const clickedWithExtras = useMessagingSalesClickAnalytics('ComposeCannedMessage');

    const setInput = React.useCallback(() => {
        TimelineInputStore.setEditorText(customerId, message);
        clickedWithExtras({
            Template: message,
            TemplateId: dataTestId,
        });
    }, [customerId, message]);

    return (
        <MessageButtonContainer data-test-id="MessagingSales_message-button" onClick={setInput}>
            <MessageTextContainer>
                <MessageText size="m">{message}</MessageText>
            </MessageTextContainer>
        </MessageButtonContainer>
    );
};

export default SendableMessage;
