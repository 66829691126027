import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {CoreText, CoreTooltip, CoreIcon, CoreIconProps, CoreTooltipProps} from '@anywhere-expert/base-ui';
import logger from '@anywhere-expert/logging';
import {setAppMessage} from '@anywhere-expert/app-messages';

const Container = styled.div<{disabled?: boolean; selected?: boolean}>`
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    transition: opacity 0.3s;
    width: 100%;
    padding: 12px 12px 12px 8px;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    cursor: pointer;
    user-select: none;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    ${({disabled}) =>
        disabled &&
        css`
            cursor: default !important;
            opacity: 0.5;
        `};

    ${({theme, selected}) =>
        selected &&
        css`
            cursor: default;
            background: ${theme.colors.contrast.toolboxItemSelected};
        `};

    ${({theme, disabled, selected}) =>
        !disabled &&
        !selected &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

const SubtitleText = styled(CoreText)<{grayedOut: boolean}>`
    ${({grayedOut}) =>
        grayedOut &&
        css`
            color: rgba(0, 0, 0, 0.2);
        `}
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    word-break: break-word;
    margin-left: 8px;
`;

interface ContentItemProps {
    Icon: CoreIconProps['icon'];
    title: string;
    subtitle?: string;
    subtitleGrayedOut?: boolean;
    onClick: () => void | Promise<void>;
    disabled?: boolean;
    selected?: boolean;
    tooltipText?: string;
    tooltipProps?: Partial<CoreTooltipProps>;
    dataTestId?: string;
}

const ContentItem = ({
    Icon,
    title,
    subtitle,
    subtitleGrayedOut,
    onClick,
    disabled,
    selected,
    tooltipText,
    dataTestId,
    tooltipProps,
}: ContentItemProps) => {
    const [isActionInProgress, setActionInProgress] = useState(false);

    const handleClick = async () => {
        setActionInProgress(true);
        try {
            await onClick();
        } catch (e) {
            logger.error("An error was thrown when running a toolboxItem's onClick callback", {
                err: e,
                extra: {
                    theProblematicToolboxItem: {title, subtitle},
                },
            });

            setAppMessage({
                text: 'Something went wrong, please try again later or report the issue if it persists.',
                type: 'warning',
            });
        } finally {
            setActionInProgress(false); // If React prints an error about this line changing state in an unmounted component, memory leak, etc., you can ignore it. https://github.com/facebook/react/pull/22114
        }
    };

    const content = (
        <Container
            onClick={disabled || isActionInProgress ? undefined : handleClick}
            disabled={disabled}
            selected={!disabled && selected}
            data-test-id={dataTestId}
        >
            <CoreIcon size="m" icon={Icon} variant="icon" />
            <TextContainer>
                <CoreText textType="primary" size="m">
                    {title}
                </CoreText>
                <SubtitleText
                    textType="secondary"
                    size="s"
                    grayedOut={!disabled /*To avoid double-opacity*/ && subtitleGrayedOut}
                >
                    {subtitle}
                </SubtitleText>
            </TextContainer>
        </Container>
    );

    if (tooltipText) {
        return (
            <CoreTooltip {...tooltipProps} text={tooltipText}>
                {content}
            </CoreTooltip>
        );
    }

    return content;
};

export default ContentItem;
