import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {MegaphoneIcon} from '@anywhere-expert/icons';
import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {observer} from 'mobx-react';
import {TabProps} from '@anywhere-expert/session-sidebar';
import {getStepNumber} from './StepLabel';
import {useCurrentOffer} from '../../../../../hooks';

const WarningBox = styled.div`
    align-items: center;
    min-height: 56px;
    border-radius: 8px;
    background-color: ${({theme}) => theme.colors.semantic.yellow.normal};
    display: flex;
`;

const LinkWarningContainer = styled.div`
    padding-bottom: 15px;
`;

const LinkWarningIcon = styled(MegaphoneIcon)`
    padding: 0px 5px;
    width: 75px;
    height: 40px;
`;

const LinkWarningBox = () => {
    const linkWarningText = useTweekValue<string>(
        MessagingSalesTweekKeys.LinkWarningText,
        'Continue selling to access your one-time link and get sales credit.'
    );

    return (
        <WarningBox>
            <LinkWarningIcon fill="none" viewBox="24 24" />
            <CoreText>{linkWarningText}</CoreText>
        </WarningBox>
    );
};

const LinkWarning = observer(({selectedSession}: TabProps) => {
    const currentOffer = useCurrentOffer(selectedSession.sessionId);
    const offerDetails = currentOffer?.offer;

    const stepNumber = getStepNumber(offerDetails);
    const isHorizonEnrollmentEnabled = useTweekValue(MessagingSalesTweekKeys.IsHorizonEnrollmentEnabled, false);

    if (!isHorizonEnrollmentEnabled || stepNumber === undefined || stepNumber === 4) {
        return null;
    }

    return (
        <LinkWarningContainer data-test-id="MessagingSales_HorizonEnrollmentFlow_LinkWarning">
            <LinkWarningBox />
        </LinkWarningContainer>
    );
});

export default LinkWarning;
