import {useAnalytics} from 'react-shisell';
import {useCallback, useEffect, useState} from 'react';

export const useOnTypingAnalytic = (value?: string) => {
    const [shouldTriggerTypingAnalytics, setShouldTriggerTypingAnalytics] = useState(true);
    const onBlur = useCallback(() => {
        setShouldTriggerTypingAnalytics(true);
    }, []);
    const analytics = useAnalytics();
    useEffect(() => {
        if (!shouldTriggerTypingAnalytics || value === '') {
            return;
        }

        analytics.dispatcher.withExtra('ActionId', 'Typing').dispatch('Click');
        setShouldTriggerTypingAnalytics(false);
    }, [analytics, value]);

    return {onBlur};
};
