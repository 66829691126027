import {VerizonEnrollmentResponse} from '@soluto-private/smart-home-anywhere-expert';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import InteractionTracker from './InterationTracker';
import SmartHomeApiClient from './SmartHomeApiClient';
import {MessagingSalesOffer, ExpertInteraction, ExpertStats} from '../types';
import {getOfferFromInteraction, getInteractionIdFromSession} from '../utils/helpers';
import {getFormattedDateTime} from '../utils/time';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {getTweekValue} from '@anywhere-expert/tweek';
import {FeatureTweekKeys} from '../FeatureTweekKeys';

export class MessagingSalesApi {
    private _client: SmartHomeApiClient = new SmartHomeApiClient();

    async enrollAccountManagementCustomer(
        expertId: string,
        {interactionId, pool: partner, atlasId, sessionId}: MessagingSalesOffer,
        enrollmentEligibilityId: string,
        mdn: string
    ): Promise<void> {
        await this._client.enrollAccountManagementCustomer({
            sessionId,
            expertId,
            interactionId,
            partner,
            atlasId,
            enrollmentEligibilityId,
            mdn,
        });
    }

    async enrollVerizonCustomer(
        mdn: string,
        expertId: string,
        offer: MessagingSalesOffer
    ): Promise<VerizonEnrollmentResponse> {
        const enrollmentPayload = {
            mdn,
            expertId,
            sessionId: offer.sessionId,
            interactionId: offer.interactionId,
            partner: offer.pool,
            atlasId: offer.atlasId,
        };

        return await this._client.enrollVerizonCustomer(enrollmentPayload);
    }

    async manualOffer(offer: MessagingSalesOffer, expertId: string): Promise<void> {
        await this._client.manualOffer({
            expertId,
            sessionId: offer.sessionId,
            interactionId: offer.interactionId,
            partner: offer.pool,
        });
    }

    async getExpertStats(expertId: string, fromISODate: string, toISODate: string): Promise<ExpertStats> {
        const expertStats = await this._client.getExpertStats(expertId, fromISODate, toISODate);

        return this.getExpertStatsWithRoundedRate(expertStats);
    }

    private getExpertStatsWithRoundedRate(expertStats: ExpertStats): ExpertStats {
        const roundedOfferRate = Math.floor(expertStats.offerRate * 100);
        const formattedDateTime = getFormattedDateTime();

        return {
            ...expertStats,
            offerRate: roundedOfferRate,
            updatedAt: formattedDateTime,
        };
    }

    async getOffersByIds(sessions: SupportItem[]): Promise<MessagingSalesOffer[]> {
        if (!sessions.length) {
            return [];
        }

        const interactionIds = sessions.map(session => getInteractionIdFromSession(session)!);
        const interactions = await this._client.getInteractionsByIds(interactionIds.filter(i => i));
        return interactions.map(interaction => getOfferFromInteraction(interaction));
    }

    async patchInteraction(
        interactionFieldsToUpdate: Partial<ExpertInteraction>,
        interactionId: string
    ): Promise<void> {
        const isExpertInteractionTrackerEnabled = getTweekValue(
            FeatureTweekKeys.IsExpertInteractionTrackerEnabled,
            false
        );
        if (isExpertInteractionTrackerEnabled) {
            InteractionTracker.getInstance().push(interactionId, interactionFieldsToUpdate);
            await this._client.patchInteraction(interactionId, InteractionTracker.getInstance().reduce(interactionId));
        } else {
            await this._client.patchInteraction(interactionId, interactionFieldsToUpdate);
        }
    }
}

export default new MessagingSalesApi();
