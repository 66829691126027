import * as React from 'react';
import {observer} from 'mobx-react';
import {CustomerMessageTemplate} from '../timelineItemStyle';
import useScrollToSelfBehavior from './useScrollToSelfBehavior';
import useHover from './withTechTemplate/useHover';
import {CustomerTemplate} from './customerTemplate/CustomerTemplate';
import {useTweekValue} from 'react-tweek';

export default Component =>
    observer(props => {
        const {showThumbnail, isGroupedItem, contentId} = props;
        const containerRef = React.useRef<HTMLDivElement>(null);
        const [isHover, handleMouseHover, handleMouseOut] = useHover();
        const isCustomerProfileAvatarEnabled = useTweekValue<Boolean>(
            'support/session/messaging/customer_profile_avatar/is_enabled',
            false
        );

        useScrollToSelfBehavior(contentId, containerRef);

        return (
            <CustomerMessageTemplate
                data-test-id="CustomerMessageTemplate"
                ref={containerRef}
                showThumbnail={showThumbnail}
                isGroupedItem={isCustomerProfileAvatarEnabled && isGroupedItem}
            >
                <CustomerTemplate
                    isHover={isHover}
                    handleMouseHover={handleMouseHover}
                    handleMouseOut={handleMouseOut}
                    showThumbnail={showThumbnail}
                    isCustomerProfileAvatarEnabled={isCustomerProfileAvatarEnabled}
                    {...props}
                >
                    <Component {...props} />
                </CustomerTemplate>
            </CustomerMessageTemplate>
        );
    });
