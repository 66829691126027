import React from 'react';
import styled from 'styled-components';
import {
    SalesStatContainer,
    StatContainer,
    Stat,
    Title,
    Subtitle,
    StyledTriangleUp,
    StyledTriangleDown,
} from '../styled-components';
import {useTweekValue} from 'react-tweek';
import {AcceptRateProps} from '../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import useMessagingSalesColors, {MessagingSalesColors} from '../../hooks/useMessagingSalesColors';
import {useTheme} from '@anywhere-expert/colors';

const StatWithIcon = styled(Stat)`
    position: relative;
`;

const getColor = (
    colors: MessagingSalesColors,
    currentWeekAcceptCount: number,
    lastWeekAcceptCount: number
): string => {
    if (currentWeekAcceptCount === 0 || currentWeekAcceptCount < lastWeekAcceptCount) {
        return colors.lightRed;
    }

    if (currentWeekAcceptCount > lastWeekAcceptCount) {
        return colors.tennis;
    }

    return colors.grey;
};

const useIconColor = (colors: MessagingSalesColors): string => {
    const theme = useTheme();

    if (theme.themeName === 'dark') {
        return theme.colors.background.secondary;
    } else {
        return colors.black;
    }
};

const getAcceptsSubtitle = (currentWeekAcceptCount: number, lastWeekAcceptCount: number): string => {
    if (currentWeekAcceptCount === 0) {
        return getInterpolatedSubtitleFromTweek(
            MessagingSalesTweekKeys.LobbyAcceptsSubtitleNoAccepts,
            'You got <accepts> Accepts last week.',
            lastWeekAcceptCount
        );
    }

    if (currentWeekAcceptCount < lastWeekAcceptCount) {
        return getInterpolatedSubtitleFromTweek(
            MessagingSalesTweekKeys.LobbyAcceptsSubtitleLessThanLastWeek,
            'You have <accepts> Accepts until you beat last week!',
            lastWeekAcceptCount - currentWeekAcceptCount
        );
    }

    if (currentWeekAcceptCount > lastWeekAcceptCount) {
        return getInterpolatedSubtitleFromTweek(
            MessagingSalesTweekKeys.LobbyAcceptsSubtitleMoreThanLastWeek,
            'Fantastic work! You are beating last week by <accepts> Accepts.',
            currentWeekAcceptCount - lastWeekAcceptCount
        );
    }

    return getInterpolatedSubtitleFromTweek(
        MessagingSalesTweekKeys.LobbyAcceptsSubtitleSameAsLastWeek,
        'Great job! You have <accepts> Accepts, same as this time last week.',
        currentWeekAcceptCount
    );
};

const getInterpolatedSubtitleFromTweek = (tweekKey: string, defaultText: string, acceptCount: number): string => {
    const tweekValue = useTweekValue<string>(tweekKey, defaultText);
    return tweekValue.replace('<accepts>', acceptCount.toString());
};

const Icon = ({color, currentWeekAcceptCount, lastWeekAcceptCount, size}) => {
    if (currentWeekAcceptCount === 0) {
        return null;
    }

    if (currentWeekAcceptCount === lastWeekAcceptCount) {
        return null;
    }

    return currentWeekAcceptCount > lastWeekAcceptCount ? (
        <StyledTriangleUp size={size} color={color} />
    ) : (
        <StyledTriangleDown size={size} color={color} />
    );
};

const AcceptCount = ({currentWeekAcceptCount, lastWeekAcceptCount}: AcceptRateProps) => {
    const colors = useMessagingSalesColors();
    const acceptsTitle = useTweekValue(MessagingSalesTweekKeys.LobbyAcceptsTitle, 'Sales');
    const acceptsSubtitle = getAcceptsSubtitle(currentWeekAcceptCount, lastWeekAcceptCount);
    const statColor = getColor(colors, currentWeekAcceptCount, lastWeekAcceptCount);
    const iconColor = useIconColor(colors);

    return (
        <SalesStatContainer>
            <StatContainer>
                <StatWithIcon color={statColor}>
                    {currentWeekAcceptCount}
                    <Icon
                        size={'l'}
                        color={iconColor}
                        currentWeekAcceptCount={currentWeekAcceptCount}
                        lastWeekAcceptCount={lastWeekAcceptCount}
                    />
                </StatWithIcon>
            </StatContainer>
            <Title>{acceptsTitle}</Title>
            <Subtitle>{acceptsSubtitle}</Subtitle>
        </SalesStatContainer>
    );
};

export default AcceptCount;
