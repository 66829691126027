import React from 'react';

const LightbulbIcon = ({color}) => (
    <svg width="60" height="65" viewBox="0 0 55 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M36.2215 55.2058C33.4368 55.3979 25.8511 56.2621 22.2983 56.166L36.2215 55.2058ZM35.9334 48.1962C36.7976 47.5241 36.2215 47.8121 37.0857 47.332C40.6385 45.0275 43.5191 41.7628 45.0555 37.8259C46.7839 33.4089 46.5918 28.5118 44.3833 24.2868C42.0788 19.8698 35.1652 15.1647 26.3312 16.317C20.1858 17.0852 13.7524 20.83 11.8319 26.9754C11.0638 29.376 10.9677 31.9685 11.4478 34.4651C12.12 38.21 14.0404 41.8588 16.9211 44.4514C17.6893 45.1235 18.5535 45.7957 19.4177 46.3718C20.858 47.236 22.4904 49.1564 22.9705 50.7888C23.2585 51.749 22.1063 51.3649 22.1063 52.3252C22.1063 53.3814 22.2023 56.166 22.2023 57.2223C22.2023 59.0467 23.5466 61.0632 25.275 62.2154C27.0034 63.3677 31.5164 63.7518 33.4369 62.2154C34.8772 61.0632 36.1255 58.2785 36.1255 56.2621C36.1255 54.3416 36.7016 53.1894 36.5095 51.3649L35.9334 48.1962ZM36.6056 51.3649C35.1652 51.3649 28.1557 51.0769 23.1625 51.653L36.6056 51.3649Z"
            stroke={color || 'black'}
            strokeWidth="2.45"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27.9636 51.365C29.5 46.7559 25.179 43.2031 22.3943 40.4185C20.858 38.8821 18.9375 36.3856 19.6097 33.985C20.1858 31.7765 22.6824 30.5282 24.7949 31.0084C27.2915 31.5845 28.8278 32.6407 29.692 33.697C30.3641 34.5612 31.1323 36.0975 30.6522 37.1537C30.2681 38.0179 28.6358 38.5941 27.4835 37.4418C26.7153 36.6736 26.8113 35.1373 27.1954 34.1771C28.0596 32.1606 29.0198 28.3197 32.1886 27.6476C33.8209 27.1675 36.2215 28.0317 36.9897 29.7601C38.1419 32.4487 37.5658 35.0413 36.5095 37.0577C35.4533 39.0742 34.109 40.9946 33.1488 41.8588"
            stroke={color || 'black'}
            strokeWidth="2.45"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M2.03772 17.9497C3.67009 19.102 4.63031 20.0622 6.83881 21.0224L2.03772 17.9497Z" fill="black" />
        <path
            d="M2.03772 17.9497C3.67009 19.102 4.63031 20.0622 6.83881 21.0224"
            stroke={color || 'black'}
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M37.4698 1.52979C37.1817 3.73829 36.0294 8.73142 35.5493 9.9797L37.4698 1.52979Z" fill="black" />
        <path
            d="M37.4698 1.52979C37.1817 3.73829 36.0294 8.73142 35.5493 9.97971"
            stroke={color || 'black'}
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M14.3285 5.17822C14.8086 6.90662 17.6893 11.2276 18.1694 12.3799L14.3285 5.17822Z" fill="black" />
        <path
            d="M14.3285 5.17822C14.8086 6.90662 17.6893 11.2276 18.1694 12.3799"
            stroke={color || 'black'}
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M52.8333 15.0688C49.9526 18.1415 48.7043 18.4296 48.2242 18.9097L52.8333 15.0688Z" fill="black" />
        <path
            d="M52.8333 15.0688C49.5685 17.2773 48.7043 18.4296 48.2242 18.9097"
            stroke={color || 'black'}
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LightbulbIcon;
