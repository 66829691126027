import React, {useCallback, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import {getBoxShadowStyle} from '@anywhere-expert/base-ui';
import ScreenshareSessionManager from '../../ScreenshareSessionManager';
import ActionBar from './ActionBar';
import CobrowseIframeManager from '../../CobrowseIframeManager';
import ReportActualScreenShareLevelSnackbar from './snackbars/ReportActualScreenShareLevelSnackbar';
import AccessibilityDisabledSnackbar from './snackbars/AccessibilityDisabledSnackbar';
import AccessibilityTurnedOnSnackbar from './snackbars/AccessibilityTurnedOnSnackbar';
import AndroidNavigationButtons from './AndroidNavigationButtons';
import {enrichAnalytics, useAnalytics} from 'react-shisell';
import logger, {LoggingErrorBoundary} from '@anywhere-expert/logging';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {featureName} from '../../definitions';
const SCREENSHARE_IFRAME_ZINDEX = 5;

const ScreensharePanelContainer = styled.div<{hidden: boolean}>`
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid ${({theme}) => theme.colors.brand.blue};
    background-color: ${({theme}) => theme.colors.neutral.whites.w80};
    box-shadow: ${({theme}) => getBoxShadowStyle(theme)};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 55vh;
    ${({hidden}) =>
        hidden &&
        css`
            display: none;
        `}
`;

const StyledIframe = styled.iframe`
    border: none;
    z-index: ${SCREENSHARE_IFRAME_ZINDEX};
`;

const ActionBarContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: ${SCREENSHARE_IFRAME_ZINDEX + 1};
`;

const SnackbarsContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 16px;
    width: 248px;
    z-index: ${SCREENSHARE_IFRAME_ZINDEX + 1};
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
`;

const NavigationButtonsContainer = styled.div`
    transform: translateY(-12px);
`;

type ScreenSharePanelProps = {
    screenshareSessionManager: ScreenshareSessionManager;
    customerName: string;
};

const ScreenSharePanel = observer(({screenshareSessionManager, customerName}: ScreenSharePanelProps) => {
    const [isPanelHidden, setIsPanelHidden] = useState(true);
    const {dispatcher} = useAnalytics();
    const [iframeManager] = useState<CobrowseIframeManager>(screenshareSessionManager.createIframeManager());

    // Product wanted the screenshare panel to appear only when the customer's device joined the session, but the expert must join the session by loading the iframe in order to receive updates about the cobrowse session, so we hide the screenshare panel until the expert joins the session.
    // We do this by listening for the first "active" report that arrives after loading the iframe.
    // If instead we were to simply use `screenshareSessionManager.lastReportedSessionState`, we would get `active` when returning to the screenshare panel from another AE session even though the iframe hasn't finished loading.
    useEffect(() => {
        const handleScreenshareSessionUpdate = async () => {
            setIsPanelHidden(false);

            if (screenshareSessionManager.areToolsAvailableRightNow) {
                await iframeManager.setTool('laser'); // This is performed to sync the state of this._selectedTool with the state of the session.
            }

            dispatcher
                .withExtras({
                    Status: 'Active',
                })
                .dispatch('View');
        };

        screenshareSessionManager.once('sessionUpdate.state.active', handleScreenshareSessionUpdate);

        return () => {
            screenshareSessionManager.off('sessionUpdate.state.active', handleScreenshareSessionUpdate);
        };
    }, [screenshareSessionManager, iframeManager]);

    const onIframeRef = useCallback(
        async (iframeElement: HTMLIFrameElement | null) => {
            if (iframeElement !== null) await iframeManager.connectToIframe(iframeElement);
        },
        [iframeManager]
    );

    useEffect(() => {
        return () => {
            iframeManager.disconnectFromIframe();
        };
    }, [iframeManager]);

    const {iframeUrl, androidBack, androidHome, activeTool} = iframeManager;
    const {areToolsAvailableRightNow, isRemoteControlAvailableRightNow, deviceSupportInfo} = screenshareSessionManager;

    return (
        <ScreensharePanelContainer hidden={isPanelHidden}>
            <StyledIframe ref={onIframeRef} title="Screenshare" src={iframeUrl} width="100%" height="100%" data-pii />
            {activeTool === 'control' && (
                <NavigationButtonsContainer>
                    <AndroidNavigationButtons onClickBack={androidBack} onClickHome={androidHome} />
                </NavigationButtonsContainer>
            )}
            <ActionBarContainer>
                <ActionBar
                    iframeManager={iframeManager}
                    shouldDisableTools={!areToolsAvailableRightNow}
                    screenshareSessionManager={screenshareSessionManager}
                />
            </ActionBarContainer>
            <SnackbarsContainer>
                <ReportActualScreenShareLevelSnackbar
                    level={isRemoteControlAvailableRightNow ? 'remote-control' : 'screenshare'}
                    customerName={customerName}
                />
                {
                    deviceSupportInfo.supportLevel === 'remote-control' && deviceSupportInfo.accessibilityEnabled && (
                        <AccessibilityTurnedOnSnackbar customerName={customerName} />
                    ) /*TODO Have Gabriella approve how the X close button is laid out and the text overflow etc.*/
                }
                {deviceSupportInfo.supportLevel === 'remote-control' && !deviceSupportInfo.accessibilityEnabled && (
                    <AccessibilityDisabledSnackbar customerName={customerName} />
                )}
            </SnackbarsContainer>
        </ScreensharePanelContainer>
    );
});

const ScreenSharePanelWithErrorBoundary = (props: ScreenSharePanelProps) => {
    const onError = () => {
        setAppMessage({
            text:
                'Something went wrong with your Screen Share session, please try again. If the problem persists, please contact support.',
            type: 'warning',
        });

        try {
            props.screenshareSessionManager.dispose();
        } catch (err) {
            logger.error(
                'An error was thrown when trying to dispose of a ScreenshareSessionManager after another error was thrown while rendering ScreenSharePanel.',
                {err, extra: {featureName}}
            );
        }
    };
    return (
        <LoggingErrorBoundary boundaryName="ScreensharePanel" onError={onError}>
            <ScreenSharePanel {...props} />
        </LoggingErrorBoundary>
    );
};

export default enrichAnalytics<ScreenSharePanelProps>((dispatcher, {screenshareSessionManager}) => {
    const deviceSupportInfo = screenshareSessionManager?.deviceSupportInfo;
    return dispatcher.createScoped('TimelineSection').withExtras({
        Section: 'ScreenShare',
        ...(deviceSupportInfo?.supportLevel === 'remote-control' && {
            AccessibilityStatus: deviceSupportInfo?.accessibilityEnabled ? 'Enabled' : 'Disabled',
        }),
    });
})(ScreenSharePanelWithErrorBoundary);
