import {CoreButton, CoreText, CoreIcon, CompoundButtonSet} from '@anywhere-expert/base-ui';
import React, {useCallback, useState, useMemo} from 'react';
import styled from 'styled-components';
import {AnalyticsDispatcher} from 'shisell';
import {getBorderStyle, getBoxShadowStyle} from '@anywhere-expert/base-ui';
import {AssignStore} from '..';
import {ExpertAvailabilityStore} from '@expert-feed/expert-availability';
import moment from 'moment';
import {TimerIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';
import {useInterval} from '../../utils';

type CancelLinkProps = {
    cancelFunc: () => void;
};

type InactiveProps = CancelLinkProps & {
    cancelFunc: () => void;
    dispatcher: AnalyticsDispatcher;
    getWaitingTimeInSeconds: () => undefined | number;
    assignStore: AssignStore;
};

const CancelBtn = styled(CoreButton)`
    padding-left: unset;
`;

const InactiveContainer = styled.div`
    position: absolute;
    bottom: 0px;
    max-width: inherit;
    right: 0;
    left: 0;
    height: fit-content;
    min-height: 73px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex: 1;
    border-radius: 12px;
    margin: 8px 9px 8px 9px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    ${({theme}) => getBorderStyle(theme)};
    ${({theme}) => getBoxShadowStyle(theme)};
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    margin: 16px 8px 16px 16px;
`;

const TopTextContainer = styled.div`
    margin-bottom: 8px;
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    flex: 1;
`;

const WaitingConfirmBtn = styled(CoreButton)`
    border-radius: 40px;
    margin-right: 16px;
    width: 110px;
`;

const InactiveButtonContainer = styled(CompoundButtonSet)`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    margin-left: -4px;
    margin-top: 12px;
`;

const NotNowBtnContainer = styled.div`
    margin-left: 16px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

const StyledText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.black};
    line-height: 20px !important;
    margin-bottom: 4px;
`;

const StyledSubText = styled(CoreText)`
    color: ${({theme}) => theme.colors.contrast.secondary};
`;

const StyledTopText = styled(CoreText)`
    margin-left: 5px;
    color: ${({theme}) => theme.colors.contrast.secondary};
`;

const NotNowComponent = ({cancelFunc}: CancelLinkProps) => (
    <CancelBtn variant="text" color="secondary" size="s" text="No, I’ll check later" onClick={cancelFunc} />
);

const InactiveComponent = ({cancelFunc, assignStore, getWaitingTimeInSeconds, dispatcher}: InactiveProps) => {
    const {assignmentState} = assignStore;
    const {idleTimeout} = ExpertAvailabilityStore;
    const isPush = assignStore.isPush;
    const theme = useTheme();
    const [timeRemaining, setTimeRemaining] = useState(idleTimeout);
    const timeFormat = useMemo(() => moment(timeRemaining).format('mm:ss'), [timeRemaining]);

    const approveFunc = useCallback(() => {
        const actionId = isPush ? 'KeepWaitingForSessionPush' : 'KeepWaitingForSession';

        dispatcher
            ?.withExtras({
                ActionId: actionId,
                WaitingTime: getWaitingTimeInSeconds(),
                State: assignmentState,
            })
            .dispatch('Click');
        isPush && assignStore.handleAvailableWaitingForPush();
    }, [isPush, assignStore, assignmentState, getWaitingTimeInSeconds]);

    useInterval(() => {
        if (timeRemaining <= 0) {
            setTimeRemaining(0);

            return;
        }

        setTimeRemaining(prev => prev - 1000);
    }, 1000);

    return (
        <InactiveContainer data-test-id={`AutoAssignMessage_short_term_inactive`}>
            <ContentContainer>
                <TopTextContainer>
                    <CoreIcon color={theme.colors.neutral.black} size={'l'} icon={TimerIcon} />
                    <StyledTopText size="s">{`${
                        isPush ? 'Unavailable' : 'Search Expires'
                    } in ${timeFormat}`}</StyledTopText>
                </TopTextContainer>
                <StyledText size="l" weight="bold">
                    {'Are you still here?'}
                </StyledText>
                <>
                    <StyledSubText size="m">
                        {"We noticed you're inactive. Should we keep looking for a new session?"}
                    </StyledSubText>
                    <InactiveButtonContainer responsive size="m">
                        <WaitingConfirmBtn
                            onClick={approveFunc}
                            size="s"
                            variant="contained"
                            color="primary"
                            text="Yes, I’ll wait"
                            data-test-id="ShortTermInactive_KeepWaitingForSessionLink"
                        />
                        <NotNowBtnContainer data-test-id="ShortTermInactive_CancelWaitingForSessionLink">
                            <NotNowComponent cancelFunc={cancelFunc} />
                        </NotNowBtnContainer>
                    </InactiveButtonContainer>{' '}
                </>
            </ContentContainer>
        </InactiveContainer>
    );
};

export default InactiveComponent;
