import camelcase from 'camelcase';
import {useMemo} from 'react';
import * as R from 'ramda';
import {useTweekValue} from 'react-tweek';
import {withProps} from 'recompose';
import {Tag} from './types';

const pushNotFoundToEnd = index => (index >= 0 ? index : 999999);

type ConfigByType = {[key: string]: {visibleLimit: number}};
type TagsConfiguration = {tagsOrder?: string} & ConfigByType;

const applyTagsConfig = (tags: Tag[] = [], tagsOrder: string[] = [], configByType: ConfigByType = {}): Tag[] => {
    const groupByTags = R.groupBy(tag => tag.tagType)(tags);

    const groupedLimitedTags = R.mapObjIndexed((tags, type) => {
        const {visibleLimit = 0} = configByType[camelcase(type)] || {};
        return R.take(visibleLimit)(tags);
    }, groupByTags);
    const flattenLimitedTags = R.flatten(Object.values(groupedLimitedTags));

    const sortByType = R.compose(
        pushNotFoundToEnd,
        R.indexOf(R.__, tagsOrder),
        R.prop('tagType')
    );
    const sort = R.sortBy(sortByType);
    return sort(flattenLimitedTags);
};

type ApplyTagsConfigurationParams = {
    fromProp: (t: any) => Tag[];
    toProp: (tags: Tag[]) => {};
    configurationExtractor: (t: any) => TagsConfiguration;
};

export const useApplyTagsConfiguration = (tags: Tag[]) => {
    const tagsConfig = useTweekValue<TagsConfiguration>('support/feed/support_item/tags/_', {});

    return useMemo(
        () => {
            const {tagsOrder = '', ...configByType} = tagsConfig;
            return applyTagsConfig(tags, tagsOrder.split(','), configByType);
        },
        [tags, tagsConfig]
    );
};

export const applyTagsConfiguration = ({fromProp, toProp, configurationExtractor}: ApplyTagsConfigurationParams) =>
    withProps(props => {
        const {tagsOrder = '', ...configByType} = configurationExtractor(props) || {};
        const sourceTags = fromProp(props);
        const tags = applyTagsConfig(sourceTags, tagsOrder.split(','), configByType);
        return toProp(tags);
    });
