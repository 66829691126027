import ExpertFeedStore from '../ExpertFeedStore';

const useShortcutSelectTopItem = dispatcher => ({
    id: 'select-top-item',
    handler: (e: KeyboardEvent) => {
        const items = ExpertFeedStore.activeQueueItems;

        if (!items?.length) return null;

        e.preventDefault();
        const [{sessionId}] = items;

        ExpertFeedStore.selectSession(sessionId);
        dispatcher.withExtra('ActionId', 'KeyboardShortcut_SelectTopItem').dispatch('Click');
    },
});

export default useShortcutSelectTopItem;
