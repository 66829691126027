import SupportItem from '@anywhere-expert/expert-feed-store/models/SupportItem';
import {createContext, useContext} from 'react';

export const SupportItemContext = createContext<SupportItem | undefined>(undefined);
export const useCurrentSupportItem = (): SupportItem => {
    const supportItem = useContext(SupportItemContext);
    if (!supportItem) {
        throw new Error('SupportItemContext not injected');
    }

    return supportItem;
};
