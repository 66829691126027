import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {InStoreDetails} from '../../types';

const getInStoreDetails = (selectedSession: SupportItem): InStoreDetails => {
    const {storeName, services, storeNumber, storeLocation} =
        selectedSession?.supportItemDetails.inStoreDetails || ({} as any);
    return {
        storeLocation,
        storeName,
        storeNumber,
        services,
    };
};

export default getInStoreDetails;
