import {getBoxShadowStyle} from '@anywhere-expert/base-ui';
import {observer} from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import CobrowseIframeManager from '../../../CobrowseIframeManager';
import {ToolName, ToolProps, toolsToProps} from '../../../definitions';
import GenericActionButton from './GenericActionButton';
import AudioControlStopIcon from './icons/AudioControlStopIcon';
import BinIcon from './icons/BinIcon';
import ScreenshareSessionManager from '../../../ScreenshareSessionManager';

export const ActionButtonsContainer = styled.div`
    transition: opacity 0.3s, visibility 0.3s;

    overflow: hidden;
    background-color: #fff;
    border-radius: 9999px;
    padding: 0 4px;
    ${({theme}) => getBoxShadowStyle(theme)};

    display: flex;
    flex-direction: column;
`;

interface ActionBarProps {
    iframeManager: CobrowseIframeManager;
    shouldDisableTools: boolean;
    screenshareSessionManager: ScreenshareSessionManager;
}

export default observer(({iframeManager, shouldDisableTools, screenshareSessionManager}: ActionBarProps) => {
    const {setTool, activeTool, clearDrawings} = iframeManager;

    const handleEndSessionClick = async () => {
        await screenshareSessionManager.endSessionIfRunning(); // Not using iframeManager.endSession() because the iframe disconnects when the following fakeSessionEndedEventIfNotEndedYet() is called.
        screenshareSessionManager.fakeSessionEndedEventIfNotEndedYet();
    };

    return (
        <ActionButtonsContainer>
            {Object.entries(toolsToProps).map((toolEntry: [ToolName, ToolProps]) => {
                const [toolName, {icon, tooltip}] = toolEntry;

                return (
                    <GenericActionButton
                        key={toolName}
                        icon={icon}
                        tooltip={shouldDisableTools ? 'Tools are only available in remote-control mode' : tooltip}
                        analyticsActionId={toolName}
                        onClick={() => setTool(toolName)}
                        isActive={!shouldDisableTools && activeTool === toolName}
                        isDisabled={shouldDisableTools}
                    />
                );
            })}
            <GenericActionButton
                icon={BinIcon}
                tooltip="Clear drawings"
                analyticsActionId="ClearDrawings"
                isDisabled={shouldDisableTools}
                onClick={clearDrawings}
            />
            <GenericActionButton
                icon={AudioControlStopIcon}
                tooltip="End the session"
                onClick={handleEndSessionClick}
                analyticsActionId="EndSession"
                color="#F00"
            />
        </ActionButtonsContainer>
    );
});
