import {useTweekValue} from 'react-tweek';
import {ConclusionContent, ConclusionState} from '../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {defaultEnrollmentConclusionContent} from '../consts';

export const useConclusionContent = (conclusionState: ConclusionState): ConclusionContent => {
    const conclusionContent = useTweekValue(
        MessagingSalesTweekKeys.EnrollmentConclusionContent,
        defaultEnrollmentConclusionContent
    );

    const {title: successTitle, messages: successMessages, text: successText} = conclusionContent[
        ConclusionState.EnrollmentSuccess
    ];
    const {title: failureTitle, messages: failureMessages, text: failureText} = conclusionContent[
        ConclusionState.EnrollmentFailure
    ];

    return {
        [ConclusionState.EnrollmentSuccess]: {
            illustration: require('../resources/union.png'),
            title: successTitle,
            messages: successMessages,
            dataTestId: 'enrollment_success_messages',
            text: successText,
        },
        [ConclusionState.EnrollmentFailure]: {
            illustration: require('../resources/broken.png'),
            title: failureTitle,
            messages: failureMessages,
            dataTestId: 'enrollment_failure_messages',
            text: failureText,
        },
    }[conclusionState];
};
