/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {BaseIcon, IconProps} from '@anywhere-expert/icons';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M11.292.687a1.568 1.568 0 00-2.594 0L6.025 4.62a1.568 1.568 0 01-.914.639l-3.925.987A1.568 1.568 0 00.381 8.79l2.268 2.633c.245.285.38.648.38 1.024v4.983c0 1.162 1.22 1.92 2.262 1.406l4.01-1.978c.437-.216.95-.216 1.388 0l4.01 1.978a1.568 1.568 0 002.262-1.406v-4.983c0-.376.135-.74.38-1.024l2.268-2.633a1.568 1.568 0 00-.806-2.544L14.88 5.26a1.568 1.568 0 01-.914-.64L11.292.688z"
                fill="#F9D51C"
            />
        </BaseIcon>
    ))
);

export default Icon;
