import React from 'react';
import {CoreIcon} from '@anywhere-expert/base-ui';
import styled, {css, keyframes} from 'styled-components';
import {GetNextAwaitingIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';

const spin = keyframes`
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
`;

const spinWithScale = keyframes`
    from {transform:rotate(0deg) scale(1.4)  ;}
    to {transform:rotate(360deg) scale(1) ;}
`;

const animationSpinWithScale = css`
    ${spinWithScale}
`;
const animationSpin = css`
    ${spin}
`;

const IconDiv = styled(CoreIcon)<{duration: number; shouldScale: boolean}>`
    margin: 16px 12px 16px 16px;
    animation: ${({duration, scale}) =>
        css`
            ${scale && animationSpinWithScale} ${duration}ms linear,
            ${animationSpin} 1s ${scale ? duration : 0}ms infinite linear
        `};
`;

type Props = {
    duration?: number;
    scale?: boolean;
};

const SpinningIcon = ({duration = 700, scale = false}: Props) => {
    const theme = useTheme();
    return (
        <IconDiv
            icon={GetNextAwaitingIcon}
            color={theme.colors.neutral.black}
            duration={duration}
            shouldScale={!!scale}
        />
    );
};

export default SpinningIcon;
