import {CoreIconButton, CoreTooltipProps, CoreTooltipStyleOverride} from '@anywhere-expert/base-ui';
import {BookmarkIcon} from '@anywhere-expert/icons';
import {shortcutsMap} from '@anywhere-expert/app-shortcuts-consts';
import {SidebarStore} from '@anywhere-expert/session-sidebar';
import React, {useCallback} from 'react';
import {useAnalytics} from 'react-shisell';
import {useTweekValue} from 'react-tweek';
import styled from 'styled-components';
import TimelineMediaInput from './TimelineMediaInput';
import VisualGuideButton from './VisualGuideButton';

const iconProps = {size: 's'};
const tooltipStyleOverride = {marginLeft: '6px'};
const tooltipProps = {
    text: 'Canned Messages',
    position: 'right',
    keyboardSequence: shortcutsMap['open-canned-messages'].key,
    styleOverride: tooltipStyleOverride as CoreTooltipStyleOverride,
} as CoreTooltipProps;

const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;

    > * {
        margin-left: 4px;
    }
`;

type Props = {
    disableImageUpload: boolean;
    timelineId?: string;
    sessionId: string;
};

const RichInputIconsPost = ({timelineId, disableImageUpload, sessionId}: Props) => {
    const analytics = useAnalytics();

    const openCannedMessagesTab = useCallback(() => {
        SidebarStore.setSelectedTabId('canned-messages');
        analytics.dispatcher.withExtra('ActionId', 'CannedMessagesShortcut').dispatch('Click');
    }, [analytics]);

    const openGifsTab = useCallback(() => {
        SidebarStore.setSelectedTabId('visual-guide');
        analytics.dispatcher.withExtra('ActionId', 'VisualGuideTool').dispatch('Click');
    }, []);

    const showImageUpload = useTweekValue<boolean>('support/session/messaging/rich_messaging/image/show_picker', false);
    const shouldDisplayCannedMessagePicker = useTweekValue<boolean>(
        'support/session/omnibox/canned_messages/is_enabled',
        false
    );

    const isGifsToolEnabled = useTweekValue<boolean>('support/session/gifs_tool/is_enabled', true);
    return (
        <Icons>
            {isGifsToolEnabled && (
                <VisualGuideButton
                    tooltipProps={{
                        text: 'GIF guides',
                        position: 'right',
                        styleOverride: tooltipStyleOverride,
                    }}
                    onClick={openGifsTab}
                />
            )}
            {showImageUpload && (
                <TimelineMediaInput timelineId={timelineId!} disabled={disableImageUpload} sessionId={sessionId} />
            )}

            {shouldDisplayCannedMessagePicker && (
                <CoreIconButton
                    variant="icon"
                    size="s"
                    iconProps={iconProps}
                    data-test-id="CannedMessagePicker"
                    tooltipProps={tooltipProps}
                    icon={BookmarkIcon}
                    onClick={openCannedMessagesTab}
                />
            )}
        </Icons>
    );
};

export default RichInputIconsPost;
