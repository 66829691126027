import React from 'react';
import {Moment} from 'moment';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {useMessagingSalesStore} from '../../hooks';
import {TimeRange} from '../../types';
import {isGivenMomentFromToday} from '../../utils/time';
import {useTweekValue} from 'react-tweek';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import useMessagingSalesColors from '../../hooks/useMessagingSalesColors';

export interface RecentSalesStats {
    accepts: number;
    offerRate: number;
    total: number;
}

const Row = styled.div`
    justify-content: space-around;
    display: flex;
    width: 54%;
`;

const Dot = styled.div`
    height: 10px;
    width: 10px;
    background: ${props => props.color};
    border-radius: 100%;
`;

const StatsDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StatsText = styled(CoreText)`
    margin-left: 3px;
`;

const getTimeRangeFromDate = (givenMoment: Moment) => {
    if (isGivenMomentFromToday(givenMoment)) {
        return TimeRange.Today;
    }

    return TimeRange.Yesterday;
};

const DailySalesStats = observer(({date}: {date: Moment}) => {
    const store = useMessagingSalesStore()!;
    const colors = useMessagingSalesColors();
    const offerThreshold = useTweekValue(MessagingSalesTweekKeys.OfferRateThreshold, 50);

    const timeRange = React.useMemo(() => getTimeRangeFromDate(date), [date]);

    const stats = store.getStatsByTimeRange(timeRange);

    if (!stats) {
        return null;
    }

    const acceptColor = stats.accepts > 0 ? colors.green : colors.red;
    const offerColor = stats.offerRate >= offerThreshold ? colors.green : colors.red;

    return (
        <Row data-test-id="DailySalesStats">
            <StatsDiv>
                <Dot color={acceptColor} />
                <StatsText data-test-id="DailySalesStats_accepts">Accepts {stats.accepts}</StatsText>
            </StatsDiv>
            <StatsDiv>
                <Dot color={offerColor} />
                <StatsText data-test-id="DailySalesStats_offerRate">Offers {stats.offerRate}%</StatsText>
            </StatsDiv>
        </Row>
    );
});

export default DailySalesStats;
