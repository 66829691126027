import React from 'react';
import logout from '../services/logout';
import {useUserProfile, User} from '@anywhere-expert/auth';

const runLogout = async (user: User) => {
    await logout(user);
    (window.location as any) = window.location.origin;
};

const Logout = () => {
    const user = useUserProfile();
    React.useEffect(() => {
        runLogout(user);
    }, []);

    return <span>Logging you out...</span>;
};

export default Logout;
