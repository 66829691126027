import React from 'react';
import styled from 'styled-components';
import MessageSpinner from './MessageSpinner';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;
`;

export type ExtraDataProps = {
    sessionId: string;
    isCurrentlyInFlight: boolean;
};

const ExtraData = ({isCurrentlyInFlight}: ExtraDataProps) => (
    <Container>
        <MessageSpinner isCurrentlyInFlight={isCurrentlyInFlight} />
    </Container>
);

export default ExtraData;
