import * as React from 'react';
import {ErrorOverlay} from '@anywhere-expert/error-overlay-web';
import {withAnalyticOnView} from 'react-shisell';
import {CoreText} from '@anywhere-expert/base-ui';

const LoginRequired = () => (
    <ErrorOverlay>
        <CoreText>
            <strong> You are no longer logged in. </strong>
        </CoreText>
        <br />
        <CoreText>Please refresh and log in to continue.</CoreText>
    </ErrorOverlay>
);

const EnhancedLoginRequired = withAnalyticOnView({
    analyticName: 'LoginRequiredError',
})(LoginRequired);

export default EnhancedLoginRequired;
