import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {CoreText} from '@anywhere-expert/base-ui';
import {TabProps} from '@anywhere-expert/session-sidebar';
import {useCurrentOffer} from '../../../../../hooks';
import {HorizonEnrollmentState, MessagingSalesOffer, OfferSelection} from '../../../../../types';

const Label = styled(CoreText)``;

export function getStepNumber(offer: MessagingSalesOffer | undefined): number {
    if (!offer) {
        return 0;
    }

    const {horizonEnrollmentProgress: progress} = offer;

    if (progress === HorizonEnrollmentState.LinkSent || progress === HorizonEnrollmentState.LinkOpened) {
        return 4;
    } else if (offer.offerSelection === OfferSelection.OfferAccepted) {
        return 3;
    } else if (progress === HorizonEnrollmentState.PriceShared) {
        return 2;
    } else if (offer.offerType) {
        return 1;
    } else {
        return 0;
    }
}

export const StepLabel = observer(({selectedSession}: TabProps) => {
    const currentOffer = useCurrentOffer(selectedSession.sessionId);
    const offerDetails = currentOffer?.offer;

    const stepNumber = React.useMemo(() => getStepNumber(offerDetails), [offerDetails]);

    if (!stepNumber) {
        return null;
    }

    return (
        <div data-test-id="MessagingSales_HorizonEnrollmentFlow_StepLabel">
            <Label size="m" weight="medium">
                Step {stepNumber} of 4
            </Label>
        </div>
    );
});

export default StepLabel;
