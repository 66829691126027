import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import CategoryActionsModal from './CategoryActionsModal';
import {AddIcon} from '../../../../resources';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {openModal} from '@anywhere-expert/base-ui';
import {DeleteConfirmationModel} from '../../../DeleteConfirmationModal';
import CollapseCaretButton from './CollapseCaretButton';

const tooltipProps = {text: 'Add a new message'};

const CategoryOptionsIconsContainer = styled.div`
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-end;
    align-items: center;
`;

const Button = styled(CoreIconButton)`
    margin-right: 10px;
    color: ${({theme}) => theme.colors.contrast.disabled};

    &:hover {
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:active,
    &[data-is-active='true'] {
        opacity: 1;
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:disabled {
        border-color: ${({theme}) => theme.colors.neutral.grey.deep};
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }
`;

type Props = {
    isHover: boolean;
    isDisabled: boolean;
    showOnlyCaret?: boolean;
    isCollapsed?: boolean;
    areActionsEnabled: boolean;
    collapseAll?: () => void;
    setIsCollapsed: (value: boolean) => void;
    setIsHover: (value: boolean) => void;
    setEditMode: (value: boolean) => void;
    onAddMessage: () => void;
    onDelete: () => void;
    categoryName: string;
    targetRef: React.RefObject<HTMLElement>;
    removeFocus: () => void;
};

export default ({
    isDisabled,
    showOnlyCaret,
    isCollapsed,
    isHover,
    setIsCollapsed,
    setIsHover,
    collapseAll,
    setEditMode,
    onAddMessage,
    onDelete,
    categoryName,
    areActionsEnabled,
    targetRef,
    removeFocus,
}: Props) => {
    const [isOptionsSelected, setIsOptionsSelected] = useState<boolean>(false);

    const onActionClick = useCallback(() => {
        setIsOptionsSelected(false);
        setIsHover(false);
    }, [setIsHover]);

    const onCancel = useCallback(() => {
        setIsOptionsSelected(false);
    }, []);

    const onCollapse = useCallback(() => {
        setIsCollapsed(true);
        onActionClick();
    }, [onActionClick]);

    const onCollapseAll = useCallback(() => {
        collapseAll?.();
        onActionClick();
    }, [collapseAll, onActionClick]);

    const onEdit = useCallback(() => {
        removeFocus();
        setEditMode(true);
        onActionClick();
    }, [removeFocus, setEditMode, onActionClick]);

    const deleteAction = useCallback(async () => {
        const text = `Deleting the category ${categoryName} will delete all the messages under it. Are you sure you want to delete this category?`;
        const title = 'Delete a category';

        openModal(DeleteConfirmationModel, {
            size: 's',
            id: 'delete-confirmation-modal',
            contentProps: {onDelete, title, text},
        });
        onActionClick();
    }, [onDelete, categoryName, onActionClick]);

    const onClick = useCallback(() => {
        setIsOptionsSelected(prev => !prev);
    }, [isDisabled]);

    const addMessage = useCallback(
        e => {
            onAddMessage();
            e.stopPropagation();
        },
        [onAddMessage]
    );

    const shouldShowOptions = isHover || isOptionsSelected;

    return (
        <CategoryOptionsIconsContainer data-test-id="CannedMessages_CategoryOptions">
            {shouldShowOptions && !showOnlyCaret && (
                <>
                    {areActionsEnabled && (
                        <Button
                            data-test-id="CannedMessages_AddMessageButton"
                            icon={AddIcon}
                            size="s"
                            color="currentColor"
                            disabled={isDisabled}
                            onClick={addMessage}
                            tooltipProps={tooltipProps}
                            variant="icon-fade"
                        />
                    )}
                    <CategoryActionsModal
                        areActionsEnabled={areActionsEnabled}
                        isDisabled={isDisabled}
                        onClick={onClick}
                        open={isOptionsSelected}
                        onEdit={onEdit}
                        onCollapse={onCollapse}
                        onCollapseAll={collapseAll && onCollapseAll}
                        onCancel={onCancel}
                        onDelete={deleteAction}
                    />
                </>
            )}
            <CollapseCaretButton targetRef={targetRef} isCollapsed={!!isCollapsed} />
        </CategoryOptionsIconsContainer>
    );
};
