import {observable, computed, makeObservable} from 'mobx';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {DefaultDetails, PersonalDetails} from '../types';
import {getInStoreDetails} from './detailsFetchers';

export class SessionDetailsModel {
    private queueItem: SupportItem;

    constructor(queueItem: SupportItem) {
        makeObservable<SessionDetailsModel, 'queueItem'>(this, {
            queueItem: observable,
            loadInStoreDetails: computed,
            loadDefaultDetails: computed,
            loadCustomerPersonalDetails: computed,
            hasData: computed,
        });

        this.queueItem = queueItem;
    }

    get loadInStoreDetails() {
        const {isInStore} = this.queueItem;
        if (!isInStore) return;
        return getInStoreDetails(this.queueItem);
    }

    get loadDefaultDetails(): DefaultDetails | undefined {
        const {partner, firstName} = this.queueItem.customerDetails;
        if (!partner) return;
        return {partner, firstName};
    }

    get loadCustomerPersonalDetails(): PersonalDetails | undefined {
        const {name, email, mdn} = this.queueItem.customerDetails;
        return {name, email, mdn};
    }

    get hasData(): boolean {
        return !!this.loadDefaultDetails || !!this.loadCustomerPersonalDetails || !!this.loadInStoreDetails;
    }
}
