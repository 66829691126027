import {
    getExpertId,
    getOfferDetailsFromSelection,
    getEnrollmentEligibilityId,
    getAmEnrollmentProgress,
} from './utils/helpers';
import {observable, action, runInAction, intercept, makeObservable} from 'mobx';
import MessagingSalesApi from './api/MessagingSalesApi';
import {
    HorizonEnrollmentState,
    HorizonEnrollmentProgress,
    ExternalEnrollmentProgress,
} from '@soluto-private/smart-home-anywhere-expert';
import {
    MessagingSalesOffer,
    OfferSelection,
    OfferType,
    HorizonEnrollmentProgressOptions,
    ExternalEnrollmentProgressOptions,
} from './types';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {
    notifyExpertOfSuccessfulEnrollment,
    notifyExpertOfFailedEnrollment,
    notifyExpertOfOfferDetectionIfSalesTabClosed,
} from './utils/toasts';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {unselectedSessionJustEnrolled, wasOfferJustDetected} from './utils';

export class SalesOfferModel {
    supportItem: SupportItem;
    timeoutHandle?: any;
    offer: MessagingSalesOffer;

    constructor(offer: MessagingSalesOffer, supportItem: SupportItem) {
        makeObservable(this, {
            offer: observable,
            setOfferSelection: action,
            persistEnrollmentProgress: action,
            submitDisclosure: action,
            submitEnrollmentLink: action,
            enrollCustomer: action,
            setExternalEnrollmentProgressTemporarilyToLoading: action,
            setManualOfferSelection: action,
            confirmCustomerAccepted: action,
            submitPricingInfo: action,
            persistHorizonEnrollmentProgress: action,
            enrollAccountManagementCustomer: action,
            enrollVerizonCustomer: action,
        });

        this.offer = offer;
        this.supportItem = supportItem;

        this.willToastOnceEnrolledWhileUnselected();
        this.onOfferDetection();
        this.watchAmEnrollmentProgress();
    }

    setOfferSelection(newOfferSelection: OfferSelection) {
        if (this.offer.offerSelection !== newOfferSelection) {
            this.offer.offerSelection = newOfferSelection;

            const offerDetails = getOfferDetailsFromSelection(newOfferSelection);
            MessagingSalesApi.patchInteraction(offerDetails, this.offer.interactionId);
        }
    }

    persistEnrollmentProgress({enrollmentProgress, accepted}: ExternalEnrollmentProgressOptions) {
        if (enrollmentProgress) {
            this.offer.enrollmentProgress = enrollmentProgress;
        }
        const interactionUpdate = {
            ...(enrollmentProgress
                ? {
                      metadata: {
                          enrollmentProgress,
                      },
                  }
                : {}),
            ...(accepted !== undefined ? {accepted} : {}),
        };

        MessagingSalesApi.patchInteraction(interactionUpdate, this.offer.interactionId);
    }

    async persistHorizonEnrollmentProgress({enrollmentProgress, accepted}: HorizonEnrollmentProgressOptions) {
        const interactionUpdate = {
            ...(enrollmentProgress
                ? {
                      metadata: {
                          horizonEnrollmentProgress: enrollmentProgress,
                      },
                  }
                : {}),
            ...(accepted !== undefined ? {accepted} : {}),
        };

        await MessagingSalesApi.patchInteraction(interactionUpdate, this.offer.interactionId);

        runInAction(() => {
            if (enrollmentProgress) {
                this.offer.horizonEnrollmentProgress = enrollmentProgress;
            }

            if (accepted) {
                this.offer.offerSelection = OfferSelection.OfferAccepted;
            }
        });
    }

    async submitDisclosure(sendDisclosure: () => Promise<void>) {
        await sendDisclosure();
        this.persistEnrollmentProgress({enrollmentProgress: ExternalEnrollmentProgress.EnrollmentConfirmation});
    }

    async submitEnrollmentLink(sendEnrollmentLink: () => Promise<void>) {
        await sendEnrollmentLink();

        await getFeedActionsClient().addQueueItemAttributes({
            expertId: getExpertId(),
            feedItemId: this.offer.sessionId,
            attributes: [
                {
                    type: HorizonEnrollmentProgress,
                    key: HorizonEnrollmentState.LinkSent,
                    fields: {
                        createdAt: new Date().toISOString(),
                    },
                },
            ],
        });

        await this.persistHorizonEnrollmentProgress({enrollmentProgress: HorizonEnrollmentState.LinkSent});
    }

    async confirmCustomerAccepted() {
        await this.persistHorizonEnrollmentProgress({accepted: true});
    }

    async submitPricingInfo(sendPricingInfo: () => Promise<void>) {
        await sendPricingInfo();

        await this.persistHorizonEnrollmentProgress({enrollmentProgress: HorizonEnrollmentState.PriceShared});
    }

    async enrollCustomer(selectedSession: SupportItem, mdn: string) {
        this.setExternalEnrollmentProgressTemporarilyToLoading();

        if (this.offer.pool === 'att') {
            await this.enrollAccountManagementCustomer(selectedSession, mdn);
        } else {
            await this.enrollVerizonCustomer(selectedSession, mdn);
        }
    }

    async setExternalEnrollmentProgressTemporarilyToLoading() {
        this.offer.enrollmentProgress = ExternalEnrollmentProgress.Loading;

        this.timeoutHandle = setTimeout(() => {
            runInAction(() => {
                console.log('timed out to error status');
                this.offer.enrollmentProgress = ExternalEnrollmentProgress.Error;
            });
        }, 30000);
    }

    async enrollAccountManagementCustomer(selectedSession: SupportItem, mdn: string): Promise<void> {
        try {
            const expertId = getExpertId();
            const eligibilityId = getEnrollmentEligibilityId(selectedSession);
            await MessagingSalesApi.enrollAccountManagementCustomer(expertId, this.offer, eligibilityId!, mdn);
        } catch (e) {
            runInAction(() => {
                this.offer.enrollmentProgress = ExternalEnrollmentProgress.Error;
            });
        }
    }
    async enrollVerizonCustomer(selectedSession: SupportItem, mdn: string): Promise<void> {
        const expertId = getExpertId();
        const {enrolled} = await MessagingSalesApi.enrollVerizonCustomer(mdn, expertId, this.offer);
        if (enrolled) {
            runInAction(() => {
                this.offer.enrollmentProgress = ExternalEnrollmentProgress.Success;
            });

            notifyExpertOfSuccessfulEnrollment(selectedSession);
        } else {
            runInAction(() => {
                this.offer.enrollmentProgress = ExternalEnrollmentProgress.Error;
            });

            notifyExpertOfFailedEnrollment(selectedSession);
        }
    }

    async setManualOfferSelection() {
        await MessagingSalesApi.manualOffer(this.offer, getExpertId());

        runInAction(() => {
            this.offer.offerType = OfferType.Manual;
            this.offer.offerSelection = OfferSelection.OfferDeclined;
        });
    }

    private watchAmEnrollmentProgress(): void {
        const stopWatchingThisSession = intercept(this.supportItem, 'sessionAttributes', change => {
            const amEnrollmentProgress = getAmEnrollmentProgress(change.newValue);
            if (!!amEnrollmentProgress) {
                if (amEnrollmentProgress === HorizonEnrollmentState.Enrolled) {
                    runInAction(() => {
                        this.offer.enrollmentProgress = ExternalEnrollmentProgress.Success;
                    });

                    notifyExpertOfSuccessfulEnrollment(change.newValue);
                } else if (amEnrollmentProgress === HorizonEnrollmentState.Failed) {
                    runInAction(() => {
                        this.offer.enrollmentProgress = ExternalEnrollmentProgress.Error;
                    });

                    notifyExpertOfFailedEnrollment(change.newValue);
                }
                if (!!this.timeoutHandle) {
                    clearTimeout(this.timeoutHandle);
                }
                stopWatchingThisSession();
            }

            return change;
        });
    }

    private willToastOnceEnrolledWhileUnselected(): void {
        const stopToastingForThisSession = intercept(this.supportItem, 'feedItemAttributes', change => {
            const wasUnselectedSessionJustEnrolled = unselectedSessionJustEnrolled(this.supportItem, change.newValue);

            if (wasUnselectedSessionJustEnrolled) {
                notifyExpertOfSuccessfulEnrollment(this.supportItem);

                stopToastingForThisSession();
            }

            return change;
        });
    }

    private onOfferDetection(): void {
        const stopWatchingThisSession = intercept(this.supportItem, 'sessionAttributes', change => {
            const wasOfferDetected = wasOfferJustDetected(this.supportItem.sessionAttributes, change.newValue);
            if (wasOfferDetected) {
                this.offer.offerType = OfferType.Automatic;
                this.offer.offerSelection = OfferSelection.OfferDeclined;

                notifyExpertOfOfferDetectionIfSalesTabClosed();

                stopWatchingThisSession();
            }

            return change;
        });
    }
}
