import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {CoreText} from '@anywhere-expert/base-ui';
import {RecommendationContentProps, TextRecommendation} from '../types';

const Container = styled.div``;

const Text = styled(CoreText)<{isOpen: boolean}>`
    ${({isOpen}) => isOpen && `padding-bottom: 8px`};
    ${p =>
        p.disableTextOverflow &&
        `
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    `};
`;

type Props = RecommendationContentProps<TextRecommendation>;
export default React.memo(
    ({
        item: {
            data: {text, triggerMessageId, modelVersion},
        },
        isOpen,
    }: Props) => {
        const {dispatcher} = useAnalytics();
        const size = useMemo(() => (text.length < 15 ? 'xl' : 's'), [text]);
        useEffect(() => {
            // The component is rendered  with isOpen=true for hover mode, we don't want to dispatch these events in that case
            if (isOpen) return;
            dispatcher
                .createScoped('OmniBoxDrawer_ConversationSuggestions_Suggestion')
                .withExtras({text, triggerMessageId, modelVersion, Source: 'SmartRecommendations'})
                .dispatch('View');
            dispatcher
                .createScoped('OmniBoxDrawer_ConversationSuggestions')
                .withExtras({variant: modelVersion, Source: 'SmartRecommendations'})
                .dispatch('Visibility');
        }, [dispatcher]);

        return (
            <Container>
                <Text
                    textType="secondary"
                    size={size}
                    isOpen={isOpen}
                    disableTextOverflow={!isOpen}
                    data-test-id={`TextRecommendation_${triggerMessageId}`}
                >
                    {text}
                </Text>
            </Container>
        );
    }
);
