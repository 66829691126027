import React, {useCallback, useState} from 'react';
import {CategoryContainer, CategoryEmojiContainer} from './Category';
import styled from 'styled-components';
import {defaultEmoji} from '../../consts';
import {CoreInputText} from '@anywhere-expert/base-ui';
import {useDetectOutsideClick} from '@anywhere-expert/utils';

const TextContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    width: 100%;
    word-break: break-word;
    padding: 12px 12px 12px 8px;
    border-radius: 8px;
    transition: opacity 0.3s;
    background: ${({theme}) => theme.colors.contrast.queuePanelHover};
`;

type Props = {
    onCreate: (categoryName: string) => void;
    onCancel: () => void;
};

const CreateCategory = ({onCreate, onCancel}: Props) => {
    const [title, setTitle] = useState('');

    const onClickOutside = useCallback(() => {
        onCreate(title || 'New Category');
    }, [title]);

    const wrapperRef = useDetectOutsideClick(onClickOutside);

    const onSubmit = useCallback(
        (e: any) => {
            if (e.key === 'Enter') {
                onCreate(title || 'New Category');
            } else if (e.key == 'Escape') {
                onCancel();
            }
        },
        [title]
    );

    const onChange = useCallback(e => {
        setTitle(e.target.value);
    }, []);

    return (
        <CategoryContainer ref={wrapperRef} data-test-id="CannedMessages_CreateCategory">
            <TextContainer>
                <CategoryEmojiContainer size="l">{defaultEmoji}</CategoryEmojiContainer>
                <CoreInputText
                    data-test-id="CannedMessages_CreateCategory_Text"
                    isTransparent={true}
                    size="m"
                    value={title}
                    onChange={onChange}
                    autoFocus={true}
                    placeholder="New Category"
                    onKeyDown={onSubmit}
                />
            </TextContainer>
        </CategoryContainer>
    );
};

export default CreateCategory;
