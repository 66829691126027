import React, {FunctionComponent, useCallback} from 'react';
import styled from 'styled-components';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {observer} from 'mobx-react';
import {ArrowDownIcon, ArrowUpIcon} from '@anywhere-expert/icons';
import {CoreText} from '@anywhere-expert/base-ui';
import {useTimelineSearchStore} from '../useTimelineSearchStore';

const CustomCoreText = styled(CoreText)`
    margin-right: 4px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-bottom: 16px;
    padding-top: 16px;
`;

const ButtonsContainer = styled.span`
    position: relative;
    left: 1px;
`;

const ResultsNavigator: FunctionComponent = observer(() => {
    const timelineSearchStore = useTimelineSearchStore();

    const resultsNumber = timelineSearchStore.messages ? timelineSearchStore.messages.length : 0;
    const onDownClick = useCallback(() => {
        timelineSearchStore.keydownPressed();
    }, [timelineSearchStore, timelineSearchStore.keydownPressed]);
    const onUpClick = useCallback(() => {
        timelineSearchStore.keyupPressed();
    }, [timelineSearchStore, timelineSearchStore.keyupPressed]);

    return (
        <Container>
            <div>
                <CustomCoreText size="m">Found</CustomCoreText>
                <CoreText size="m" weight="bold">
                    {' '}
                    {resultsNumber} {resultsNumber === 1 ? 'result' : 'results'}{' '}
                </CoreText>
            </div>
            <ButtonsContainer>
                <CoreIconButton variant="icon" size={'s'} onClick={onDownClick} icon={ArrowDownIcon} />
                <CoreIconButton variant="icon" size={'s'} onClick={onUpClick} icon={ArrowUpIcon} />
            </ButtonsContainer>
        </Container>
    );
});

export default ResultsNavigator;
