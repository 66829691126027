import {
    ConclusionState,
    EnrollmentFlowStep,
    HorizonEnrollmentFlowStep,
    EnrollmentFlowContent,
    HorizonEnrollmentFlowContent,
    ManualOfferContent,
    OfferTypeTextContent,
    OfferType,
} from './types';

export const defaultOfferStatusTextContent: OfferTypeTextContent = {
    [OfferType.Automatic]: 'Offer detected',
    [OfferType.Manual]: 'Offer reported',
};

export const defaultEnrollmentConclusionContent = {
    [ConclusionState.EnrollmentSuccess]: {
        title: 'Enrollment Messages',
        messages: [''],
        text: 'Congrats! You completed the customer enrollment.',
        toastText: 'Congrats ️🎉 <sessionAlias> is enrolled in Verizon Protect Home!',
    },
    [ConclusionState.EnrollmentFailure]: {
        title: 'Let the customer know',
        messages: [''],
        text: 'Good job on getting the accept! Unfortunately, we could not complete the enrollment.',
        toastText: "We're unable to process the enrollment for <sessionAlias>",
    },
};

export const defaultEnrollmentFlowMapContent: EnrollmentFlowContent = {
    [EnrollmentFlowStep.AuthorizeCustomer]: {
        title: 'Authorize Customer',
        buttonText: 'Next',
        checkboxLabel: 'The customer is an account owner/authorized user.',
    },
    [EnrollmentFlowStep.SendDisclosure]: {
        title: 'Legal Disclosure',
        buttonText: 'Send and Continue',
    },
    [EnrollmentFlowStep.ConfirmEnrollment]: {
        title: 'Confirm Enrollment',
        buttonText: 'Complete Enrollment',
        checkboxLabel:
            'By checking this box, I understand that this will add an additional charge to the customers bill.',
    },
};

export const defaultHorizonEnrollmentFlowMapContent: HorizonEnrollmentFlowContent = {
    [HorizonEnrollmentFlowStep.SendPricingInfo]: {
        title: 'Share the pricing info',
        buttonText: 'Send and Continue',
        pricingInfo: 'Its only a $25/month subscription.',
        infoText: 'Its okay if you already sent the price.',
    },
    [HorizonEnrollmentFlowStep.SendEnrollmentLink]: {
        title: 'Enrollment Assistance',
        buttonText: 'Send and Continue',
    },
    [HorizonEnrollmentFlowStep.CustomerProgress]: {
        title: 'Customer Progress',
    },
    [HorizonEnrollmentFlowStep.ConfirmCustomerAccept]: {
        title: 'Confirm Acceptance',
        buttonText: 'Continue',
        confirmationText: 'The customer wants to purchase the product',
    },
};

export const defaultManualOfferContent: ManualOfferContent = {
    text: 'We try to find offers for you. Did we miss yours?',
    buttonText: 'I Made An Offer',
};
