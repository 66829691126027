import {useEffect, RefObject} from 'react';
import {TimelineController} from '@anywhere-expert/timeline-state';
import scrollIntoView from 'scroll-into-view';

export default function useScrollToSelfBehavior(contentId: string, ref: RefObject<HTMLElement>) {
    const shouldScrollToSelf = TimelineController.shouldScrollToMessage(contentId);
    useEffect(() => {
        if (shouldScrollToSelf) {
            ref.current &&
                scrollIntoView(ref.current, {align: {top: 0.5}}, () => {
                    TimelineController.flashMessage(contentId);
                });
        }
    }, [shouldScrollToSelf, contentId]);
}
