import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useCurrentOffer, useEnrollmentFlowContent, useMessagingSalesClickAnalytics} from '../../../../../hooks';
import {EnrollmentFlowStep, EnrollmentFlowContent} from '../../../../../types';
import {CoreText, CoreCheckbox} from '@anywhere-expert/base-ui';
import {ExternalEnrollmentProgress} from '@soluto-private/smart-home-anywhere-expert';
import {NextButton} from '../../../../styled-components';
import styled from 'styled-components';

const StyledText = styled(CoreText)`
    padding-bottom: 10px;
`;

const CheckboxContainer = styled.div`
    padding-left: 10px;
`;

export type AuthorizeCustomerProps = {
    sessionId: string;
};

export const AuthorizeCustomer = observer(({sessionId}: AuthorizeCustomerProps) => {
    const {title, buttonText, checkboxLabel} = useEnrollmentFlowContent(
        EnrollmentFlowStep.AuthorizeCustomer
    ) as EnrollmentFlowContent[EnrollmentFlowStep.AuthorizeCustomer];

    const currentOffer = useCurrentOffer(sessionId);
    const [isChecked, setIsChecked] = useState(false);
    const clickedWithExtras = useMessagingSalesClickAnalytics('AuthorizeCustomer');

    const onNextClick = () => {
        clickedWithExtras({});
        currentOffer?.persistEnrollmentProgress({enrollmentProgress: ExternalEnrollmentProgress.Disclosure});
    };

    return (
        <div data-test-id="MessagingSales_EnrollmentFlow_AuthorizeCustomer">
            <StyledText size="l" weight="medium">
                {title}
            </StyledText>
            <CheckboxContainer>
                <CoreCheckbox
                    data-test-id="MessagingSales_VerizonEnrollmentFlow_AuthorizationCheckbox"
                    name={checkboxLabel}
                    checked={isChecked}
                    label={checkboxLabel}
                    onChange={() => setIsChecked(!isChecked)}
                    size="medium"
                />
            </CheckboxContainer>
            <NextButton
                data-test-id="MessagingSales_EnrollmentFlow_NextButton"
                color="primary"
                onClick={onNextClick}
                text={buttonText}
                disabled={!isChecked}
            />
        </div>
    );
});

export default AuthorizeCustomer;
