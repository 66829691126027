import {Reference} from '@firebase/database-types';
import {firebaseProvider} from '@anywhere-expert/firebase-provider';
import {observable, action, runInAction, makeObservable} from 'mobx';
import {CobrowsingSessionState, CobrowsingExpertDrawing, Coordinates} from '../types';

const COBROWSING_FIREBASE_NODE_NAME = 'coBrowseTimelines';

class CobrowsingDataProviderStore {
    public sessionState?: CobrowsingSessionState;
    public expertDrawings: CobrowsingExpertDrawing[] = [];
    public idleTimestamp?: number;
    public lastDomEvent?: any;

    private firebaseRef?: Reference;
    private sessionStateFirebaseRef?: Reference;
    private expertCursorPositionFirebaseRef?: Reference;
    private expertDrawingsFirebaseRef?: Reference;
    private lastDomEventFirebaseRef?: Reference;
    private idleTimestampFirebaseRef?: Reference;
    private expertActivityTimestampFirebaseRef?: Reference;
    private messageIdRef?: Reference;
    private timelineId: string;
    private firebaseInitialized: boolean = false;

    constructor(timelineId: string) {
        makeObservable<CobrowsingDataProviderStore, 'timelineId' | 'firebaseInitialized'>(this, {
            sessionState: observable,
            initFirebase: action,
            firebaseInitialized: observable,
            timelineId: observable,
            expertDrawings: observable,
            idleTimestamp: observable,
            lastDomEvent: observable.ref,
            setExpertDrawings: action,
            setMessageId: action,
            setExpertCursorPosition: action,
            setSessionState: action,
            updateExpertActivityTimestamp: action,
            liveUpdatesOn: action,
            liveUpdatesOff: action,
            resetDataValues: action,
            getCurrentTimestamp: action,
            dispose: action,
        });

        this.timelineId = timelineId;
    }

    initFirebase = () => {
        this.firebaseInitialized = true;
        this.firebaseRef = firebaseProvider.app?.database?.().ref(COBROWSING_FIREBASE_NODE_NAME).child(this.timelineId);

        this.sessionStateFirebaseRef = this.firebaseRef?.child('sessionState');
        this.idleTimestampFirebaseRef = this.firebaseRef?.child('idleTimestamp');
        this.lastDomEventFirebaseRef = this.firebaseRef?.child('lastDomEvent');
        this.expertCursorPositionFirebaseRef = this.firebaseRef?.child('expertCursorPosition');
        this.expertDrawingsFirebaseRef = this.firebaseRef?.child('expertDrawings');
        this.expertActivityTimestampFirebaseRef = this.firebaseRef?.child('expertActivityTimestamp');
        this.messageIdRef = this.firebaseRef?.child('messageId');

        this.sessionStateFirebaseRef?.on('value', snapshot => {
            if (!snapshot?.exists() || this.sessionState === snapshot.val()) return;
            runInAction(() => {
                this.sessionState = snapshot.val();
            });
        });

        this.idleTimestampFirebaseRef?.on('value', snapshot => {
            runInAction(() => {
                this.idleTimestamp = snapshot?.val() || undefined;
            });
        });

        this.expertDrawingsFirebaseRef?.once('value', snapshot => {
            if (!snapshot.exists()) return;
            runInAction(() => {
                this.expertDrawings = snapshot.val();
            });
        });
    };

    public setExpertDrawings = (drawings: CobrowsingExpertDrawing[]) => {
        if (!this.firebaseInitialized) return;
        this.expertDrawings = drawings;
        this.expertDrawingsFirebaseRef?.set(drawings);
    };

    public setMessageId = (messageId: String) => {
        if (!this.firebaseInitialized) return;
        this.messageIdRef?.set(messageId);
    };

    public setExpertCursorPosition = (position: Coordinates) => {
        if (!this.firebaseInitialized) return;
        this.expertCursorPositionFirebaseRef?.set(position);
    };

    public setSessionState = (state: CobrowsingSessionState) => {
        if (!this.firebaseInitialized) return;
        this.sessionState = state;
        this.sessionStateFirebaseRef?.set(state);
    };

    public updateExpertActivityTimestamp = () => {
        if (!this.firebaseInitialized) return;
        this.expertActivityTimestampFirebaseRef?.set(this.getCurrentTimestamp());
    };

    public liveUpdatesOn = () => {
        if (!this.firebaseInitialized) return;
        this.lastDomEventFirebaseRef?.on('value', snapshot => {
            if (!snapshot?.exists()) return;
            runInAction(() => {
                this.lastDomEvent = JSON.parse(snapshot.val());
            });
        });
    };

    public liveUpdatesOff = () => {
        if (!this.firebaseInitialized) return;
        this.lastDomEventFirebaseRef?.off();
    };

    public resetDataValues() {
        if (!this.firebaseInitialized) return;
        this.idleTimestampFirebaseRef?.set(null);
        this.expertDrawingsFirebaseRef?.set(null);
        this.expertCursorPositionFirebaseRef?.set(null);
        this.lastDomEventFirebaseRef?.set(null);
    }

    public getCurrentTimestamp = () => (this.firebaseInitialized ? firebaseProvider.serverTimestamp() : -1);

    public dispose = () => {
        if (!this.firebaseInitialized) return;
        this.liveUpdatesOff();
        this.sessionStateFirebaseRef?.off();
        this.idleTimestampFirebaseRef?.off();
        this.firebaseRef?.off();
    };
}

export default CobrowsingDataProviderStore;
