import {Configuration, RoomApi} from '@soluto-private/messaging-api-client';
import {authorizedFetch} from '@anywhere-expert/auth';
import {configuration} from '@anywhere-expert/core';
import {getTweekValue} from '@anywhere-expert/tweek';

export {default as RoomStore} from './RoomStore';
export {default as MessagingStore} from './MessagingStore';
export {transformMessageToTimelineItem} from './transformers';
export * from './types';

export const getRoomApi = () =>
    new RoomApi(
        new Configuration({
            fetchApi: authorizedFetch,
            basePath: getTweekValue('support/session/messaging/message_url', configuration.messagingApi.rest),
            headers: {'x-api-key': configuration.messagingApi.apiKey},
        })
    );
