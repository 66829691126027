import {action, observable, runInAction, makeObservable} from 'mobx';
import {HelixArticleItem} from './types';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import fetchArticlesFromHelixApi from './api/fetchArticles';
import {setAppMessage} from '@anywhere-expert/app-messages';

const getDeviceModelFromSession = (selectedSession: SupportItem) => {
    const tempDeviceModel = selectedSession.supportItemDetails.deviceModel;
    return tempDeviceModel?.toLowerCase() !== 'unknown' ? tempDeviceModel : undefined;
};

export class HelixStore {
    searchTerm: string;
    articles: HelixArticleItem[] | undefined;
    articlesPreview: HelixArticleItem[] | undefined;
    inProgress: boolean = false;
    deviceModel: string | undefined;
    parent: SupportItem;
    sessionId: string;
    carrier: string;

    constructor(parent: SupportItem) {
        makeObservable(this, {
            articles: observable.ref,
            articlesPreview: observable.ref,
            inProgress: observable,
            fetchArticles: action,
            searchTerm: observable,
            setSearchTerm: action,
        });

        this.parent = parent;
        this.sessionId = parent.sessionId;
        this.deviceModel = getDeviceModelFromSession(parent);
        this.searchTerm = '';
    }

    init = (carrier: string) => {
        this.carrier = carrier;
    };

    setSearchTerm = (searchTerm: string) => {
        this.searchTerm = searchTerm;
    };

    fetchArticles = async (value: string, searchForPreview: boolean) => {
        this.inProgress = true;
        try {
            const articles = await fetchArticlesFromHelixApi(this.carrier, value);
            runInAction(() => {
                if (searchForPreview) {
                    this.articlesPreview = articles;
                } else {
                    this.articles = articles;
                }
            });
        } catch (e) {
            setAppMessage({text: 'Failed to search for helix articles', type: 'warning'});

            throw e;
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    };
}
