import {CoreLink, CoreText} from '@anywhere-expert/base-ui';
import React from 'react';
import styled, {keyframes, css} from 'styled-components';
import {Theme} from '@anywhere-expert/colors';
import {getBorderStyle, getBoxShadowStyle} from '@anywhere-expert/base-ui';
import SpinningIcon from './SpinningIcon';

type CancelLinkProps = {
    cancelFunc: () => void;
};

type WaitingForSessionProps = CancelLinkProps & {
    loadingDuration: number;
    skipTransition: boolean;
    hide?: boolean;
};

const borderBoxShadow = (theme: Theme) => keyframes`
    from { 
       border: unset;
       box-shadow: unset;
       background-color: unset;
    }
    to {
        background-color: ${theme.colors.neutral.white};
        ${getBorderStyle(theme)};
        ${getBoxShadowStyle(theme)};
    }
`;

const translate = keyframes`
    from {transform:translateX(50%) translateX(-44px); ;}
    to {transform: translateX(0)}
`;

const opacity = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
`;

const animationTranslate = css`
    ${translate}
`;

const animationOpacity = css`
    ${opacity}
`;

const animateBoxShadowBorder = (theme: Theme) =>
    css`
        ${borderBoxShadow(theme)}
    `;

const WaitingForSessionContainer = styled.div<{delay: number; duration: number; hide?: boolean}>`
    display: flex;
    flex-direction: row;
    flex: 1;
    max-width: inherit;
    justify-content: flex-start;
    height: fit-content;
    min-height: 73px;
    border-radius: 12px;
    margin: 8px 9px 8px 9px;
    align-items: flex-end;
    animation-name: ${({theme}) => animateBoxShadowBorder(theme)};
    animation-delay: ${({delay}) => `${delay}ms`};
    animation-duration: ${({duration}) => `${duration}ms`};
    animation-fill-mode: forwards;
    ${({hide}) =>
        hide &&
        `
        display: none;
    `};
`;

const ContentContainer = styled.div<{duration: number; delay: number}>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    max-height: 80px;
    opacity: 0;
    margin: 16px 16px 16px 0px;
    animation: ${({duration, delay}) =>
        css`
            ${animationOpacity} ${duration}ms ${delay}ms linear forwards;
        `};
`;

const StyledText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.black};
    line-height: 20px !important;
    margin-bottom: 4px;
`;

const CancelLinkComp = styled(CoreLink)`
    font-style: unset !important;
    font-weight: 500;
    margin-top: 4px;
`;

const MovingDiv = styled.div<{duration: number; delay: number}>`
    display: flex;
    transform: translateX(50%) translateX(-44px);
    animation: ${({duration, delay}) =>
        css`
            ${animationTranslate} ${duration}ms ${delay}ms linear forwards;
        `};
`;

const CancelLink = ({cancelFunc}: CancelLinkProps) => (
    <CancelLinkComp
        data-test-id="WaitingForSession_CancelWaitingForSessionLink"
        size="s"
        weight="medium"
        onClick={cancelFunc}
    >
        I’m not ready for another session
    </CancelLinkComp>
);

const WaitingForSessionComponent = ({
    cancelFunc,
    loadingDuration,
    hide = false,
    skipTransition = false,
}: WaitingForSessionProps) => {
    const getDuration = React.useCallback((defaultDuration: number) => (skipTransition || hide ? 0 : defaultDuration), [
        skipTransition,
        hide,
    ]);
    const movingDuration = React.useMemo(() => getDuration(300), [getDuration]);
    const movingDelay = React.useMemo(() => getDuration(loadingDuration - 400), [getDuration]);
    const textAndBorderDuration = React.useMemo(() => getDuration(200), [getDuration]);
    const endOfMovingAnimation = React.useMemo(() => movingDuration + movingDelay, [movingDuration, movingDelay]);

    return (
        <WaitingForSessionContainer
            data-test-id={`AutoAssignMessage_waiting_for_session`}
            delay={endOfMovingAnimation}
            duration={textAndBorderDuration}
            hide={hide}
        >
            <MovingDiv duration={movingDuration} delay={movingDelay}>
                <SpinningIcon duration={getDuration(700)} />
                <ContentContainer duration={textAndBorderDuration} delay={endOfMovingAnimation}>
                    <StyledText size="l" weight="bold">
                        {'Your next session will be added to the queue automatically'}
                    </StyledText>
                    <CancelLink cancelFunc={cancelFunc} />
                </ContentContainer>
            </MovingDiv>
        </WaitingForSessionContainer>
    );
};

export default WaitingForSessionComponent;
