import React, {ComponentType, FunctionComponent} from 'react';
import {wrapDisplayName} from 'recompose';
import useHover from './useHover';
import {TechTemplate, CommonProps} from './TechTemplate';

const withTechTemplate = <TProps extends CommonProps>(Component: ComponentType<TProps>) => {
    const EnhancedComponent: FunctionComponent<TProps> = props => {
        const [isHover, handleMouseHover, handleMouseOut] = useHover();

        return (
            <TechTemplate
                isHover={isHover}
                handleMouseHover={handleMouseHover}
                handleMouseOut={handleMouseOut}
                {...props}
            >
                {
                    // @ts-ignore
                    <Component {...props} isHover={isHover} />
                }
            </TechTemplate>
        );
    };

    EnhancedComponent.displayName = wrapDisplayName(Component, 'withTechTemplate');

    return EnhancedComponent;
};

export default withTechTemplate;
