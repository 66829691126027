import React from 'react';

export const Bulb = props => (
    <svg {...props} width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.1869 40.2734C28.1758 39.8397 28.0802 39.1541 27.9707 38.4931C27.7716 37.2918 27.0216 36.2676 26.0367 35.5515C25.1985 34.942 24.2062 34.1402 23.5318 33.3356C22.0112 31.5214 20.8577 27.8873 24.9886 27.6142C27.5807 27.4428 29.2925 29.2312 29.6707 30.7744C29.9365 31.8588 28.7239 32.5145 27.7509 31.9668C27.3191 31.7237 26.93 31.3812 26.7416 30.9295C25.8657 28.8292 27.0587 24.9651 31.1882 25.5374C34.6139 26.0121 34.5036 28.5644 34.4104 29.7433C34.3172 30.9222 31.3823 34.9204 31.3823 34.9204"
            stroke="white"
            strokeWidth="1.3125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27.1874 47.9826L35.0351 44.4597"
            stroke="white"
            strokeWidth="1.3125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.1917 45.982L35.2654 41.3835"
            stroke="white"
            strokeWidth="1.3125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.0127 43.4325L31.1391 40.0595"
            stroke="white"
            strokeWidth="1.3125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.2252 14.4994C18.5118 15.5378 13.3353 21.4559 14.2889 27.6211C14.7861 30.8359 16.9336 33.7671 19.6168 35.4377C21.8719 36.6465 24.1703 38.781 24.6386 40.9497L34.0578 39.4928C33.8065 37.8679 34.7747 35.0056 36.7265 32.7913C38.7795 30.3881 39.9414 26.945 39.4441 23.7302C38.4906 17.565 33.4037 13.2344 25.2252 14.4994Z"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.9059 45.5957C35.7187 43.6807 35.582 41.8939 35.1837 39.3183L23.2695 41.1611C23.8094 43.693 24.343 45.2331 25.3047 46.5754C26.2665 47.9177 27.962 48.8249 30.6481 48.4094C32.771 48.0811 34.0931 47.5107 34.9059 45.5957Z"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.0726 48.7481C31.7759 49.2627 31.3616 49.4603 30.8295 49.5426C30.3062 49.6235 29.8364 49.4385 29.4192 49.0635"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.4209 48.3459C32.0124 48.8777 31.4663 49.0957 30.7727 49.203C30.0905 49.3085 29.4898 49.1437 28.9661 48.7852"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="35.414"
            y1="12.0409"
            x2="38.0368"
            y2="8.41509"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="42.937"
            y1="18.4763"
            x2="46.0323"
            y2="17.0713"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="44.1103"
            y1="28.8466"
            x2="48.0086"
            y2="29.6868"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="40.1171"
            y1="37.2052"
            x2="43.0808"
            y2="39.2143"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.875"
            y1="-0.875"
            x2="5.34998"
            y2="-0.875"
            transform="matrix(-0.803499 -0.595306 -0.595306 0.8035 15.2319 16.1006)"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.875"
            y1="-0.875"
            x2="4.27423"
            y2="-0.875"
            transform="matrix(-0.992905 -0.118909 -0.118909 0.992905 10.6353 24.5635)"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.875"
            y1="-0.875"
            x2="4.86286"
            y2="-0.875"
            transform="matrix(-0.868214 0.49619 0.49619 0.868214 13.0791 34.1562)"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.875"
            y1="-0.875"
            x2="4.45548"
            y2="-0.875"
            transform="matrix(-0.619522 0.78498 0.78498 0.619522 19.446 40.4473)"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="24.9825"
            y1="9.71923"
            x2="24.4137"
            y2="6.04184"
            stroke="white"
            strokeWidth="1.75"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
