import React from 'react';

export default () => (
    <svg
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{transform: 'translateX(-0.7px)'}}
    >
        <path
            d="M7.43229 3.62826H8.26562C9.64396 3.62826 10.7656 2.50659 10.7656 1.12826V0.294922H9.09896V1.12826C9.09896 1.58742 8.72479 1.96159 8.26562 1.96159H7.43229C6.05396 1.96159 4.93229 3.08326 4.93229 4.46159V5.29492H2.43229C1.51312 5.29492 0.765625 6.04159 0.765625 6.96159V12.7949C0.765625 15.5524 3.00812 17.7949 5.76562 17.7949C8.52312 17.7949 10.7656 15.5524 10.7656 12.7949V6.96159C10.7656 6.04159 10.0181 5.29492 9.09896 5.29492H6.59896V4.46159C6.59896 4.00242 6.97312 3.62826 7.43229 3.62826ZM4.93229 6.96159V9.46159H2.43229V6.96159H4.93229ZM5.76562 16.1283C3.92729 16.1283 2.43229 14.6324 2.43229 12.7949V11.1283H9.09896V12.7949C9.09896 14.6324 7.60396 16.1283 5.76562 16.1283ZM9.09896 6.96159V9.46159H6.59896V6.96159H9.09896Z"
            fill="white"
        />
    </svg>
);
