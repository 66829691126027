import {withRouter} from 'react-router-dom';
import {compose, withProps} from 'recompose';
import queryString from 'query-string';

type Props = {
    location: {
        search: any;
    };
};

export const withUrlQueryParams = compose(
    withRouter,
    withProps((props: Props) => {
        const parsed = queryString.parse(props.location.search);
        return {queryParams: parsed};
    })
);
