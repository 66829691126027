import React, {ReactNode, useCallback, useRef} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {TechAvatar} from '@anywhere-expert/base-ui';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import useScrollToSelfBehavior from '../useScrollToSelfBehavior';
import {TechMessageTemplate} from '../../timelineItemStyle';
import TechTemplateActions from './TechTemplateActions';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useUserProfile} from '../../../../auth/shared';
import {useTweekValue} from 'react-tweek';
import {RetryIndicator} from '../../RetryIndicator';

const teamIcon = require('./assets/teamIcon.png');

const templateStyles = {flex: 1};

const StyledTechAvatar = styled(TechAvatar)`
    margin-left: 8px;
`;

export type CommonProps = TimelineItemProps & {
    showCannedMessageForm?: boolean;
    canAddCannedMessageFromTimeline?: boolean;
};

export type TechTemplateChildren = {
    children: ReactNode | ((isHover: boolean) => ReactNode);
};

export type TechTemplateProps = CommonProps & {
    isHover: boolean;
    handleMouseOut: () => void;
    handleMouseHover: () => void;
};

const useDeletable = (senderId?: string) => {
    const user = useUserProfile();
    const isDeleteEnabled = useTweekValue('support/session/messaging/rich_messaging/deletion/is_enabled', false);

    return isDeleteEnabled && user.uid === senderId;
};

export const TechTemplate = observer(
    ({
        isGroupedItem,
        contentId,
        contentType,
        senderName,
        senderType,
        senderThumbUrl,
        showThumbnail,
        showTimestamp,
        seenAtValue,
        isCurrentlyInFlight = false,
        isCurrentlyInDelete,
        isFailedSending,
        isCanceled,
        children,
        handleMouseOut,
        handleMouseHover,
        isHover,
        canAddCannedMessageFromTimeline,
        content,
        senderId,
        sessionId,
    }: TechTemplateProps & TechTemplateChildren) => {
        const containerRef = useRef<HTMLDivElement>(null);
        useScrollToSelfBehavior(contentId, containerRef);
        const deletable = useDeletable(senderId);
        const supportItem = ExpertFeedStore.getSupportItem(sessionId);
        const isMessageFromExpert = senderType === 'Technician';

        const onDelete = useCallback(() => {
            supportItem?.timelineModel.deleteMessage(contentId);
        }, [contentId, supportItem]);

        if (isCanceled) return null;
        const techImage = senderType === 'Team' || senderThumbUrl === 'teamIcon' ? teamIcon : senderThumbUrl;
        const showSendError = !!isFailedSending;

        const shouldShowRetry = isMessageFromExpert && contentType !== 'Timestamp' && isFailedSending;

        return (
            <>
                {shouldShowRetry && <RetryIndicator contentId={contentId} />}
                <TechMessageTemplate
                    ref={containerRef}
                    data-test-id={`MessageWithThumbnail_${contentId}`}
                    withExtra={!!(seenAtValue && showTimestamp)}
                    isGroupedItem={isGroupedItem}
                    onMouseLeave={handleMouseOut}
                >
                    <TechTemplateActions
                        deleteEnabled={Boolean(deletable)}
                        deleteButtonVisible={!isCurrentlyInFlight && !isCurrentlyInDelete}
                        deleteExtraData={{MessageType: contentType, contentId}}
                        onDelete={onDelete}
                        canAddCannedMessageFromTimeline={canAddCannedMessageFromTimeline}
                        content={content}
                        contentType={contentType}
                        isHover={isHover}
                        isCurrentlyInFlight={isCurrentlyInFlight}
                        sessionId={sessionId}
                    />

                    <div style={templateStyles} onMouseEnter={handleMouseHover}>
                        {typeof children === 'function' ? children(isHover && !showSendError) : children}
                    </div>
                    {showThumbnail && <StyledTechAvatar size={24} imageUrl={techImage} name={senderName} />}
                </TechMessageTemplate>
            </>
        );
    }
);
