import {TimelineModel} from '@anywhere-expert/timeline-state';
import {cobrowseStateToTimelineItemState} from '../definitions';
import {
    DeviceSupportInfo,
    ScreenshareMessagePayload,
    ScreenshareTimelineItemState,
    SupportedScreenshareLevel,
} from '../sharedScreenshareDefinitions';
import {CobrowseSessionState} from '../cobrowse-agent-sdk_types';
import {analytics} from 'react-shisell';
import logger from '@anywhere-expert/logging';
import {featureName} from '../definitions';
const ALREADY_EXPIRED_ERROR_MESSAGE =
    "Tried to update a screenshare timeline item that's already expired. This probably indicates some logic error or race condition.";

export class ScreenShareTimelineItemUpdater {
    private timelineModel: TimelineModel;

    public readonly messageId: string;

    public get currentState(): ScreenshareTimelineItemState | undefined {
        const messageFound = this.timelineModel.messages.find(
            timelineMessage => timelineMessage.messageId === this.messageId
        );

        if (!messageFound) return undefined;

        const payload = messageFound.payload.value as ScreenshareMessagePayload;

        return payload.state;
    }

    constructor(timelineModel: TimelineModel, messageId: string) {
        this.timelineModel = timelineModel;
        this.messageId = messageId;
    }

    public updateScreenshareTimelineItemState = async (newState: CobrowseSessionState) => {
        if (this.currentState === 'expired') {
            logger.error(ALREADY_EXPIRED_ERROR_MESSAGE, {extra: {featureName}});
            return;
        }

        const newTimelineItemState = cobrowseStateToTimelineItemState[newState];
        await this.internalUpdateTimelineItemState(newTimelineItemState);
    };

    public updateScreenshareTimelineItemLevel = async (newScreenshareLevel: SupportedScreenshareLevel) => {
        await this._updateMessageTypeSafe({screenshareLevel: newScreenshareLevel});
    };

    public async reportCustomerRejectedFullDeviceScreenSharePrompt() {
        if (this.currentState === 'expired') {
            logger.error(ALREADY_EXPIRED_ERROR_MESSAGE, {extra: {featureName}});
            return;
        }

        await this._updateMessageTypeSafe({customerRejectedFullDeviceScreenSharePrompt: true});
    }

    public async expire(deviceSupportInfoForAnalytics: DeviceSupportInfo | undefined): Promise<void> {
        if (this.currentState === 'expired') {
            logger.error(ALREADY_EXPIRED_ERROR_MESSAGE, {extra: {featureName}});
            return;
        }

        await this.internalUpdateTimelineItemState('expired');

        analytics.dispatcher
            .createScoped('TimelineSection')
            .withExtras({
                Status: 'Expired',
                ...(deviceSupportInfoForAnalytics?.supportLevel === 'remote-control' && {
                    AccessibilityStatus: deviceSupportInfoForAnalytics?.accessibilityEnabled ? 'Enabled' : 'Disabled',
                }),
            })
            .dispatch('View');
    }

    private async internalUpdateTimelineItemState(newState: ScreenshareTimelineItemState) {
        await this._updateMessageTypeSafe({state: newState});
    }

    private async _updateMessageTypeSafe(update: Partial<ScreenshareMessagePayload>) {
        await this.timelineModel.updateMessage(this.messageId, update);
    }
}
