import bowser from 'bowser';
import {useAnalytics} from 'react-shisell';
import {useTheme} from '@anywhere-expert/colors';
import {getAppMetadata, UIStore} from '@anywhere-expert/utils';
import {useMemo} from 'react';

const appVersion = getAppMetadata().version;

export const useEnrichedAnalytics = () => {
    const {themeName} = useTheme();
    const {dispatcher} = useAnalytics();

    return useMemo(
        () =>
            dispatcher.createScoped('AEWeb').withExtras({
                IsTest: process.env.NODE_ENV !== 'production' || process.env.IS_LIVE_PR === 'true',
                AppName: 'TechTalkWeb',
                Platform: 'Web',
                DeviceOsType: bowser.osname,
                DeviceOsVersion: bowser.osversion,
                DeviceBrowserType: bowser.name,
                DeviceBrowserVersion: bowser.version,
                DeviceScreenWidth: window.screen.width,
                DeviceScreenHeight: window.screen.height,
                Theme: themeName,
                AppVersion: appVersion,
                BrowserWidth: UIStore.pageDimensions.width,
                BrowserHeight: UIStore.pageDimensions.height,
            }),
        [dispatcher, themeName]
    );
};
