import React, {useState, useCallback, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {Recommendation, RecommendationType, RecommendationTypeId} from '../types';
import {CSSTransition} from 'react-transition-group';
import RecTitle from './RecTitle';
import {CARD_WIDTH} from '../dimensions';

const BodyContainer = styled.div`
    max-height: 96px;
    min-height: 96px;
    overflow: hidden;
    width: ${CARD_WIDTH}px;
    border: solid 1px ${({theme}) => theme.colors.contrast.border};
    background-color: ${({theme}) => theme.colors.background.primary};
    border-radius: 12px;
    padding: 12px 16px;
    display: flex;
    user-select: none;
    flex-direction: column;
    padding-top: 18px;

    &.open {
        padding-top: 12px;
        max-height: 200px;
        background-color: ${({theme}) => theme.colors.neutral.white};
        box-shadow: ${({theme}) => theme.themeName === 'light' && `0px 0px 18px ${theme.colors.contrast.border}`};
        border: solid 1px ${({theme}) => (theme.themeName === 'dark' ? theme.colors.contrast.border : '#fff')};
        position: absolute;

        opacity: 0;
        transition: opacity 100ms ease-in-out;
        &.entered {
            opacity: 1;
        }
    }
`;

const HoverStateContainer = ({children}) => ReactDOM.createPortal(children, document.body);

const ContentWrapper = styled.div``;

const ActionButton = styled(CoreIconButton)`
    position: absolute;
    z-index: 100;
    bottom: 8px;
    right: 8px;
`;

const ClosedTitleContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: ${({theme}) => theme.colors.background.primary};
`;

const OpenTitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    margin-left: -4px;
`;

const unmountStates = ['unmounted', 'exited'];

type OpenState = {
    isOpen: boolean;
    left?: string;
    bottom?: string;
};

type BodyProps = {
    item: Recommendation;
    onActionClick: () => void;
    itemType: RecommendationType;
};
const RecBody = ({item, onActionClick, itemType}: BodyProps) => {
    const bodyRef = useRef<HTMLDivElement | null>(null);
    const [openState, setOpenState] = useState<OpenState>({isOpen: false});
    const onMouseEnter = useCallback(() => {
        if (!bodyRef.current) return;
        const rect = bodyRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        setOpenState({
            isOpen: true,
            left: `${rect.left}px`,
            bottom: `${windowHeight - rect.top - rect.height}px`,
        });
    }, []);

    const onMouseLeave = useCallback(() => {
        setOpenState(state => ({...state, isOpen: false}));
    }, []);

    const Content = itemType.content;
    const extraStyle = itemType.openBodyStyle || {};

    return (
        <>
            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <BodyContainer ref={bodyRef}>
                    <ClosedTitleContainer>
                        <RecTitle title={itemType.title} icon={itemType.icon} />
                    </ClosedTitleContainer>
                    <ContentWrapper>
                        <Content isOpen={false} item={item} />
                    </ContentWrapper>
                </BodyContainer>
                <HoverStateContainer>
                    <CSSTransition in={openState.isOpen} timeout={150} mountOnEnter unmountOnExit>
                        {state =>
                            unmountStates.includes(state) ? null : (
                                <BodyContainer
                                    style={{left: openState.left, bottom: openState.bottom, ...extraStyle}}
                                    className={`open ${state}`}
                                >
                                    <OpenTitleContainer>
                                        <RecTitle title={itemType.title} icon={itemType.icon} />
                                    </OpenTitleContainer>
                                    <ContentWrapper>
                                        <Content isOpen item={item} />
                                    </ContentWrapper>
                                    <ActionButton
                                        icon={itemType.actionIcon}
                                        size="s"
                                        variant="contained"
                                        color="primary"
                                        onClick={onActionClick}
                                        data-test-id={`Recommendations_Item_${item.type}_Action`}
                                    />
                                </BodyContainer>
                            )
                        }
                    </CSSTransition>
                </HoverStateContainer>
            </div>
        </>
    );
};

export default RecBody;
