import {observable, action, reaction, computed, IReactionDisposer, makeObservable} from 'mobx';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {CobrowsingExpertDrawing, Coordinates, Size, CobrowsingSessionState} from '../types';
import CobrowsingDataProviderStore from './CobrowsingDataProviderStore';
import domEventToCobrowsingData from '../utils/domEventToCobrowsingData';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {findLast, minutesToMilliseconds} from '@anywhere-expert/utils';
import {createContext} from 'react';
import {initializeFirebaseProvider} from '@anywhere-expert/firebase-provider';
import logger from '@anywhere-expert/logging';

export const CobrowsingStoreContext = createContext<CobrowsingStore>(undefined as any);

class CobrowsingStore {
    private parent: TimelineModel;
    private reactionDisposers: IReactionDisposer[];
    private idleTimestampTimeout?: number;
    private maxCustomerIdleTime = minutesToMilliseconds(2);
    private cobrowsingDataProviderStore: CobrowsingDataProviderStore;

    customerPageClickPosition?: Coordinates;
    customerCursorPosition: Coordinates = {x: 0, y: 0};
    customerScrollPosition: Coordinates = {x: 0, y: 0};
    customerViewportSize: Size = {width: 0, height: 0};
    isDrawing: boolean;
    lastDomEventTimestamp?: number;

    constructor(parent: TimelineModel) {
        makeObservable<
            CobrowsingStore,
            | 'cobrowsingDataProviderStore'
            | 'updateApprovalItem'
            | 'handleIsCobrowsingOnChange'
            | 'handleSessionStateChange'
            | 'handleIdleTimestampChange'
            | 'handleDomEventChange'
        >(this, {
            cobrowsingDataProviderStore: observable,
            customerPageClickPosition: observable,
            customerCursorPosition: observable,
            customerScrollPosition: observable,
            customerViewportSize: observable,
            isDrawing: observable,
            lastDomEventTimestamp: observable,
            supportItem: computed,
            customerName: computed,
            currentCobrowsingContentId: computed,
            sessionState: computed,
            isCobrowsingOn: computed,
            isCobrowsingSharing: computed,
            isRequestingCobrowsing: computed,
            expertDrawings: computed,
            lastDomEvent: computed,
            stopCobrowsing: action,
            timeoutCobrowsing: action,
            requestCobrowsing: action,
            updateExpertActivityTimestamp: action,
            setExpertCursorPosition: action,
            addExpertDrawing: action,
            undoLastExpertDrawing: action,
            setIsDrawing: action,
            dispose: action,
            updateApprovalItem: action,
            handleIsCobrowsingOnChange: action,
            handleSessionStateChange: action,
            handleIdleTimestampChange: action,
            handleDomEventChange: action,
        });

        this.parent = parent;
        this.initFirebase();
        this.cobrowsingDataProviderStore = new CobrowsingDataProviderStore(this.supportItem.customerId);

        const isCobrowsingOnReactionDisposer = reaction(() => this.isCobrowsingOn, this.handleIsCobrowsingOnChange, {
            fireImmediately: true,
        });
        const lastDomEventReactionDisposer = reaction(() => this.lastDomEvent, this.handleDomEventChange);
        const sessionStateReactionDisposer = reaction(() => this.sessionState, this.handleSessionStateChange);
        const idleTimestampReactionDisposer = reaction(
            () => this.cobrowsingDataProviderStore.idleTimestamp,
            this.handleIdleTimestampChange
        );
        const currentCobrowsingContentIdReactionDisposer = reaction(
            () => this.currentCobrowsingContentId,
            contentId => {
                if (!contentId) return;

                this.cobrowsingDataProviderStore.setMessageId(contentId);
            }
        );

        this.reactionDisposers = [
            isCobrowsingOnReactionDisposer,
            lastDomEventReactionDisposer,
            sessionStateReactionDisposer,
            idleTimestampReactionDisposer,
            currentCobrowsingContentIdReactionDisposer,
        ];
    }

    get supportItem(): SupportItem {
        return this.parent.supportItem;
    }

    get customerName(): string | undefined {
        return this.parent.supportItem.customerDetails.firstName || undefined;
    }

    get currentCobrowsingContentId(): string | undefined {
        const {messages} = this.parent;
        if (!messages.length) return;

        const lastCobrowsingTimelineItem = findLast(messages, item => item.payload.type === 'cobrowsing_approval_item');
        return lastCobrowsingTimelineItem?.messageId;
    }

    get sessionState(): CobrowsingSessionState | undefined {
        return this.cobrowsingDataProviderStore.sessionState;
    }

    get isCobrowsingOn(): boolean {
        return this.sessionState === 'approved' || this.sessionState === 'requesting';
    }

    get isCobrowsingSharing(): boolean {
        return this.sessionState === 'approved';
    }

    get isRequestingCobrowsing() {
        return this.sessionState === 'requesting';
    }

    get expertDrawings(): CobrowsingExpertDrawing[] {
        return this.cobrowsingDataProviderStore.expertDrawings || [];
    }

    get lastDomEvent(): any {
        return this.cobrowsingDataProviderStore.lastDomEvent;
    }

    public stopCobrowsing = () => {
        this.cobrowsingDataProviderStore.setSessionState('ended');
        this.cobrowsingDataProviderStore.resetDataValues();
    };

    public timeoutCobrowsing = () => {
        this.cobrowsingDataProviderStore.setSessionState('timeout');
        this.cobrowsingDataProviderStore.resetDataValues();
    };

    public requestCobrowsing = () => {
        this.cobrowsingDataProviderStore.setSessionState('requesting');
    };

    public updateExpertActivityTimestamp = () => {
        this.cobrowsingDataProviderStore.updateExpertActivityTimestamp();
    };

    public setExpertCursorPosition = (position: Coordinates) => {
        if (!this.isCobrowsingOn || !position) return;

        this.cobrowsingDataProviderStore.setExpertCursorPosition(position);
    };

    public addExpertDrawing = (drawing: CobrowsingExpertDrawing) => {
        const {expertDrawings, setExpertDrawings} = this.cobrowsingDataProviderStore;
        setExpertDrawings([...expertDrawings, drawing]);
    };

    public undoLastExpertDrawing = () => {
        const {expertDrawings, setExpertDrawings} = this.cobrowsingDataProviderStore;
        setExpertDrawings(expertDrawings.filter((_, index) => index !== expertDrawings.length - 1));
    };

    public setIsDrawing = (isDrawing: boolean) => {
        this.isDrawing = isDrawing;
    };

    public dispose = () => {
        this.cobrowsingDataProviderStore.dispose();
        this.reactionDisposers.forEach(disposer => {
            disposer?.();
        });

        if (this.idleTimestampTimeout) {
            clearTimeout(this.idleTimestampTimeout);
        }
    };

    private initFirebase = async () => {
        const token = this.parent.supportItem.store.user.accessToken;
        if (!token) return;
        try {
            await initializeFirebaseProvider(token);
            this.cobrowsingDataProviderStore.initFirebase();
        } catch (err) {
            logger.error('could not initialize firebase for cobrowsing', {err});
        }
    };

    private updateApprovalItem = (state: CobrowsingSessionState) => {
        if (!this.supportItem?.customerId || !state || !this.currentCobrowsingContentId) return;

        this.parent.updateMessage(this.currentCobrowsingContentId, {
            state,
            previewMessage: `Co-browsing ${state}`,
        });
    };

    private handleIsCobrowsingOnChange = (isCobrowsingOn: boolean) => {
        isCobrowsingOn
            ? this.cobrowsingDataProviderStore.liveUpdatesOn()
            : this.cobrowsingDataProviderStore.liveUpdatesOff();
    };

    private handleSessionStateChange = (sessionState: CobrowsingSessionState) => {
        if (!sessionState || sessionState === 'requesting') return;

        this.updateApprovalItem(sessionState);
    };

    private handleIdleTimestampChange = (idleTimestamp: number) => {
        if (!idleTimestamp || !this.isCobrowsingOn) {
            if (this.idleTimestampTimeout) {
                clearTimeout(this.idleTimestampTimeout);
                this.idleTimestampTimeout = undefined;
            }
            return;
        }

        const idleTime = this.cobrowsingDataProviderStore.getCurrentTimestamp() - idleTimestamp;

        this.idleTimestampTimeout = setTimeout(() => {
            if (!this.isCobrowsingOn) return;

            this.stopCobrowsing();
        }, Math.min(this.maxCustomerIdleTime - idleTime, this.maxCustomerIdleTime));
    };

    private handleDomEventChange = (event: any) => {
        const eventData = domEventToCobrowsingData(event);
        if (!eventData) return;

        const {type, data, timestamp} = eventData;
        this.lastDomEventTimestamp = timestamp;

        if (type === 'mouse-move') {
            const {x, y} = data as Coordinates;
            this.customerCursorPosition = {x, y};
            return;
        }

        if (type === 'scroll') {
            const {x, y} = data as Coordinates;
            this.customerScrollPosition = {x, y};
            return;
        }

        if (type === 'click') {
            const {x, y} = data as Coordinates;
            this.customerPageClickPosition = {x, y};
            return;
        }

        if (type === 'resize') {
            const {width, height} = data as Size;

            if (this.customerViewportSize) {
                const {width: previousWidth, height: previousHeight} = this.customerViewportSize;
                if (width !== previousWidth || height !== previousHeight) {
                    this.cobrowsingDataProviderStore.setExpertDrawings([]);
                }
            }

            this.customerViewportSize = {width, height};
            return;
        }
    };
}

export default CobrowsingStore;
