import React, {useCallback, useEffect, useState} from 'react';
import {CoreInputText, CoreIconButton} from '@anywhere-expert/base-ui';
import {CloseIcon, SearchIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useDebounce} from 'react-use/lib';
import {useAnalytics} from 'react-shisell';
import {useCannedMessagesNavigationStore} from '../useCannedMessagesNavigationStore';

const Container = styled.div`
    display: flex;
    margin-top: 16px;
    padding-right: 20px;
    flex-direction: column;
`;

const SearchContainer = styled.div`
    display: flex;
`;

const StyledCoreIconButton = styled(CoreIconButton)`
    color: ${({theme}) => theme.colors.neutral.grey.deep};

    &:disabled {
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }

    &:hover,
    &:active {
        background-color: transparent;
    }
`;

const InputUnderline = styled.div`
    border: 0;
    height: 1px;
    width: 100%;
    background-color: ${({theme}) => theme.colors.contrast.border};
`;

const SearchComp = observer(() => {
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();
    const [search, setSearch] = useState('');

    useEffect(() => {
        setSearch(cannedMessagesNavigationStore.searchedText || '');
    }, [cannedMessagesNavigationStore]);
    const shouldFocus = cannedMessagesNavigationStore.focusedIndex === -1;
    const ref = React.useRef<HTMLElement>(null);
    const analytics = useAnalytics();

    const resetCursor = React.useCallback(() => {
        if (!shouldFocus) {
            cannedMessagesNavigationStore.reset();
        }
    }, [shouldFocus, cannedMessagesNavigationStore]);

    React.useEffect(() => {
        if (shouldFocus) ref?.current?.focus();
    }, [shouldFocus]);

    const throttleSearchText = useCallback(() => {
        if (!cannedMessagesNavigationStore.searchedText && !!search) {
            analytics?.dispatcher
                .createScoped('Search')
                .withExtras({
                    FeatureVersion: 'v2',
                })
                .dispatch('Typing');
        }
        cannedMessagesNavigationStore.setSearchText(search);
    }, [analytics, cannedMessagesNavigationStore, cannedMessagesNavigationStore.searchedText, search]);

    const handleInputChange = useCallback(
        e => {
            setSearch(e.target.value);
        },
        [setSearch]
    );

    const clearSearch = React.useCallback(() => {
        setSearch('');
        resetCursor();
        ref?.current?.focus();
    }, []);

    useDebounce(throttleSearchText, 300, [search]);

    return (
        <Container>
            <SearchContainer>
                <CoreInputText
                    size="m"
                    ref={ref}
                    inputTextType="primary"
                    autoComplete="off"
                    value={search}
                    onChange={handleInputChange}
                    placeholder="Search or use  ↑ ↓  keys..."
                    data-test-id="CannedMessages_Search_Input"
                    autoFocus
                    onFocus={resetCursor}
                    isTransparent
                />
                <StyledCoreIconButton
                    disabled={!search}
                    onClick={clearSearch}
                    icon={search ? CloseIcon : SearchIcon}
                    size="s"
                    variant="icon"
                />
            </SearchContainer>
            <InputUnderline />
        </Container>
    );
});

export default SearchComp;
