import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {SupportItem} from '@anywhere-expert/expert-feed-store';

import InStoreSessionAttributes from './AttributeSections/InStoreSessionAttributes';
import DefaultAttributes from './AttributeSections/DefaultAttributes';
import {SessionAttributesContainer} from './AttributeSections/Attribute';
import {SessionDetailsModel} from '../store/SessionDetailsModel';
import PersonalCustomerAttributes from './AttributeSections/PersonalCustomerAttributes';
import AmazonPrePurchaseDetails from './AttributeSections/AmazonPrePurchaseDetails';

type Props = {
    sessionDetails: SessionDetailsModel;
    selectedSession: SupportItem;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const SessionDetails = observer(({sessionDetails, selectedSession}: Props) => {
    if (!sessionDetails) return null;

    const {loadInStoreDetails, loadDefaultDetails, loadCustomerPersonalDetails} = sessionDetails;

    return (
        <Container>
            <SessionAttributesContainer>
                {loadDefaultDetails && (
                    <DefaultAttributes selectedSession={selectedSession} defaultDetails={loadDefaultDetails} />
                )}
                {loadCustomerPersonalDetails && (
                    <PersonalCustomerAttributes personalDetails={loadCustomerPersonalDetails} />
                )}
                {loadInStoreDetails && <InStoreSessionAttributes inStoreDetails={loadInStoreDetails} />}
            </SessionAttributesContainer>
            {selectedSession.amazonPrePurchaseDetails && (
                <SessionAttributesContainer>
                    <AmazonPrePurchaseDetails amazonPrePurchaseDetails={selectedSession.amazonPrePurchaseDetails} />
                </SessionAttributesContainer>
            )}
        </Container>
    );
});

export default SessionDetails;
