import React from 'react';
import {CoreIconButton, CoreTooltipProps} from '@anywhere-expert/base-ui';
import {GifIcon} from '@anywhere-expert/icons';

const iconProps = {size: 's'};

type Props = {
    onClick: () => void;
    tooltipProps: Omit<CoreTooltipProps, 'children'>;
};

const VisualGuideButton = ({onClick, tooltipProps}: Props) => {
    return (
        <CoreIconButton
            variant="icon"
            size="s"
            iconProps={iconProps}
            data-test-id="VisualGuidePicker"
            icon={GifIcon}
            tooltipProps={tooltipProps}
            onClick={onClick}
        />
    );
};

export default VisualGuideButton;
