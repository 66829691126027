import {BehaviorSubject} from 'rxjs';

type NewUnreadMessage = {
    sessionId: string;
    customerId: string;
    text?: string;
    conversationName?: string;
    customerName?: string;
};

const newUnreadSessionMessage = new BehaviorSubject<NewUnreadMessage | undefined>(undefined);

export const newUnreadSessionMessage$ = newUnreadSessionMessage.asObservable();

export const setNewUnreadSessionMessage = (
    sessionId: string,
    customerId: string,
    text?: string,
    conversationName?: string,
    customerName?: string
) => newUnreadSessionMessage.next({sessionId, customerId, text, conversationName, customerName});
