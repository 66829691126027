import {action, observable, computed, runInAction, when, makeObservable} from 'mobx';
import {
    QueueItemWithoutAttributes,
    ExpertQueueEventType,
    QueueItemsBySessionId,
    QueueItem as QueueItemNativeType,
    QueueItemAttribute,
} from '@soluto-private/expert-queue-api-types';
import {ExpertQueueUpdate, ConnectionStatus} from '@soluto-private/live-queue-api-client';
import logger from '@anywhere-expert/logging';
import {getFeedActionsClient, initFeedActionsClient, FeedActions} from '@anywhere-expert/expert-feed-actions';
import {compareByRank} from './utils/feedSortStrategies';
import {feedPrioritySort} from './utils/transformers';
import QueueItem, {isSupport, isTask} from './models/QueueItem';
import SupportItem from './models/SupportItem';
import TaskItem from './models/TaskItem';
import {isHomeVisitSession} from './utils/sessionType';
import {addedSupportItemsSubject, deletedSupportItemsSubject} from './utils/storeEvents';
import debounceConnectionStatus from './utils/debounceConnectionStatus';
import monitorExpertQueueUpdateReceived from './utils/monitorExpertQueueUpdateReceived';
import {AssignStore} from '@anywhere-expert/assign-panel';
import {User} from '@anywhere-expert/auth';
import {AnalyticsDispatcher} from 'shisell';
import {AssignmentType} from './types';
import {SessionEvent, SessionType, SessionStatus} from '@soluto-private/session-api-types';
import {ExpertAvailabilityStore} from '@expert-feed/expert-availability';

type InitParams = {
    user: User;
    cameFromLogin?: boolean;
    analyticsDispatcher: AnalyticsDispatcher;
    assignmentType: AssignmentType;
};

export class ExpertFeedStore {
    isInitialized = false;
    assignMethod: AssignmentType;
    isAutoAssignMethod: boolean;
    private analyticsDispatcher: AnalyticsDispatcher;
    user: User;
    isFirstItemsLoaded = false;
    supportItemHasBeenSelected = false;
    queueItemsDetailsMap = new Map<string, QueueItem>();
    assignStore?: AssignStore;
    sessionsInFeed = new Set<string>();
    selectedSessionId: string | undefined;
    connected: boolean = false;
    assignedSession: SupportItem | null;
    timeUntilSessionWasAssigned?: number;
    feedActions: FeedActions;

    constructor() {
        makeObservable<
            ExpertFeedStore,
            'handleUpsert' | 'handleUpsertSelect' | 'handleAttributeChanged' | 'handleRefresh'
        >(this, {
            isFirstItemsLoaded: observable,
            timeUntilSessionWasAssigned: observable,
            supportItemHasBeenSelected: observable,
            queueItemsDetailsMap: observable,
            assignStore: observable,
            sessionsInFeed: observable,
            selectedSessionId: observable,
            assignedSession: observable,
            connected: observable,
            setConnectionStatus: action,
            handleRemove: action,
            handleUpsertSelect: action,
            handleUpsert: action,
            handleAttributeChanged: action,
            handleRefresh: action,
            queueItemsDetails: computed,
            supportItems: computed,
            taskItems: computed,
            activeQueueItems: computed,
            myAssignedItems: computed,
            myAssignedSupportItems: computed,
            mySnoozedItems: computed,
            hasUnengagedSessions: computed,
            unengagedSessions: computed,
            myAutopilotItems: computed,
            myNeglectedItems: computed,
            callItems: computed,
            waitingForResponse: computed,
            unAssignedItems: computed,
            unassignedSupportItems: computed,
            selectedSession: computed,
            selectedSupportSession: computed,
            selectedTaskSession: computed,
            attentionRequiredCounts: computed,
            selectSession: action,
            getSupportItem: action,
        });
    }

    init({user, assignmentType, analyticsDispatcher}: InitParams) {
        if (this.isInitialized) return;
        this.isInitialized = true;
        this.user = user;
        this.assignMethod = assignmentType;
        this.isAutoAssignMethod = assignmentType === 'get-next' || assignmentType === 'push';
        this.assignStore = this.isAutoAssignMethod ? new AssignStore(this) : undefined;
        this.analyticsDispatcher = analyticsDispatcher;
        initFeedActionsClient({expertId: user.uid, dispatcher: analyticsDispatcher});
        this.feedActions = getFeedActionsClient();

        const {queueUpdates, connectionStatus$} = this.feedActions.feedClient;

        const connection = queueUpdates(user.uid);

        setInterval(
            action(() => {
                this.queueItemsDetails.forEach(item => {
                    item.snooze.calculateIsSnoozed();
                });
            }),
            30 * 1000
        );

        debounceConnectionStatus(connectionStatus$).subscribe(this.setConnectionStatus);

        connection.subscribe(this.handleQueueEvents, this.handleQueueErrors);
    }

    setConnectionStatus = (connectionStatus: ConnectionStatus) => {
        this.connected = connectionStatus.status;
        if (!this.connected) {
            logger.info('live queue socket disconnected', {
                extra: {reason: connectionStatus.reason, connectionEvent: connectionStatus.connectionEvent},
            });
        }
    };

    private handleQueueErrors = (err: any) => {
        logger.warn('live queue socket error', {err});
    };

    handleQueueEvents = (queueUpdate: ExpertQueueUpdate) => {
        try {
            monitorExpertQueueUpdateReceived(this.analyticsDispatcher, queueUpdate);
            switch (queueUpdate.eventType) {
                case ExpertQueueEventType.refresh: {
                    return this.handleRefresh(queueUpdate.value.items);
                }
                case ExpertQueueEventType.remove: {
                    return this.handleRemove(queueUpdate.value.sessionId);
                }
                case ExpertQueueEventType.upsert: {
                    if (!this.isAutoAssignMethod) return this.handleUpsertSelect(queueUpdate.value);
                    return this.handleUpsert(queueUpdate.value, queueUpdate.sessionEvent!);
                }
                case ExpertQueueEventType.attributeChanged: {
                    return this.handleAttributeChanged(queueUpdate.value);
                }
            }
        } catch (err) {
            logger.error('Fail to handle queue event', {err, extra: {eventType: queueUpdate.eventType}});
        }
    };

    handleRemove = (sessionId: string) => {
        this.sessionsInFeed.delete(sessionId);

        const item = this.getQueueItem(sessionId);

        if (item === undefined) {
            return;
        }

        if (sessionId !== this.selectedSessionId) {
            item.dtor();
            this.queueItemsDetailsMap.delete(item.sessionId);

            item.isSupport() && deletedSupportItemsSubject.next([item]);
        } else if (
            item.wasUnassignedByNeglection ||
            (item instanceof SupportItem && item.autopilot.hadAutopilotAttributes)
        ) {
            this.selectSession(undefined);
        }
    };

    private handleUpsert = (queueItem: QueueItemWithoutAttributes, sessionEvent: SessionEvent) => {
        let item = this.getQueueItem(queueItem.sessionId);
        const {waitingForSession, waitingForSessionTimestamp} = ExpertAvailabilityStore;
        const {status, assignedExperts} = queueItem;
        const assignedToMe =
            status === SessionStatus.Assigned &&
            assignedExperts.includes(this.user.uid) &&
            sessionEvent === SessionEvent.Assigned;

        this.sessionsInFeed.add(queueItem.sessionId);

        try {
            if (item) {
                item.upsert(queueItem);
                return;
            }

            if (!assignedToMe) return;

            item = this.createItem(queueItem);
            this.queueItemsDetailsMap.set(item.sessionId, item);
            if (item.isSupport()) {
                if (this.assignedSession?.sessionId !== item.sessionId) {
                    this.timeUntilSessionWasAssigned =
                        waitingForSessionTimestamp && Date.now() - waitingForSessionTimestamp.getTime();
                    this.assignedSession = item;
                }
                addedSupportItemsSubject.next([item]);
            }
            if (!waitingForSession) {
                logger.error('Session was assigned while expert is not waiting for session', {
                    extra: {sessionId: item.sessionId},
                });
            }
        } catch (err) {
            logger.error('Fail to handle handleUpsert queue event, ignoring item', {
                err,
                extra: {sessionId: queueItem.sessionId},
            });
        }
    };

    private handleUpsertSelect = (queueItem: QueueItemWithoutAttributes) => {
        let item = this.getQueueItem(queueItem.sessionId);

        try {
            if (item) {
                item.upsert(queueItem);
            } else {
                item = this.createItem(queueItem);
                this.queueItemsDetailsMap.set(item.sessionId, item);
                item.isSupport() && addedSupportItemsSubject.next([item]);
            }

            this.sessionsInFeed.add(queueItem.sessionId);
        } catch (err) {
            logger.error('Fail to handle handleUpsert select queue event, ignoring item', {
                err,
                extra: {sessionId: queueItem.sessionId},
            });
        }
    };

    private handleAttributeChanged = (queueItem: QueueItemNativeType) => {
        const item = this.getQueueItem(queueItem.sessionId);

        if (item) {
            item.updateFeedItemAttributes(queueItem.attributes);
        } else {
            logger.warn('handleAttributeChanged: item not found in map', {extra: {sessionId: queueItem.sessionId}});
        }
    };

    private handleRefresh = (items: QueueItemsBySessionId) => {
        this.sessionsInFeed.clear();
        const itemsAdded: SupportItem[] = [];
        const itemsDeleted: SupportItem[] = [];

        Object.values(items).forEach(item => {
            this.sessionsInFeed.add(item.sessionId);

            const {attributes, ...queueItem} = item;

            const currentItem = this.queueItemsDetailsMap.get(item.sessionId);
            if (currentItem) {
                currentItem.upsert(queueItem);
                currentItem.updateFeedItemAttributes(attributes);
            } else {
                const {status, assignedExperts} = item;
                const assignedToMe = status === SessionStatus.Assigned && assignedExperts.includes(this.user.uid);
                if (!this.isAutoAssignMethod || assignedToMe) {
                    try {
                        const newItemModel = this.createItem(queueItem, attributes);

                        newItemModel.isSupport() && itemsAdded.push(newItemModel);
                        this.queueItemsDetailsMap.set(newItemModel.sessionId, newItemModel);
                    } catch (err) {
                        logger.error('Fail to handle handleRefresh queue event, ignoring item', {
                            err,
                            extra: {sessionId: item.sessionId},
                        });
                    }
                }
            }
        });

        // Remove items from the map who don't exist in the current updated items snapshot
        Array.from(this.queueItemsDetailsMap.values()).forEach(item => {
            if (!this.sessionsInFeed.has(item.sessionId) && item.sessionId !== this.selectedSessionId) {
                item.isSupport() && itemsDeleted.push(item);
                item.dtor();
                this.queueItemsDetailsMap.delete(item.sessionId);
            }
        });

        if (itemsAdded.length > 0) addedSupportItemsSubject.next(itemsAdded);
        if (itemsDeleted.length > 0) deletedSupportItemsSubject.next(itemsDeleted);

        this.isFirstItemsLoaded = true;
    };

    private createItem(queueItem: QueueItemWithoutAttributes, attributes: QueueItemAttribute[] = []) {
        if (queueItem.type === SessionType.Task) {
            return new TaskItem(this, queueItem, attributes);
        }
        return new SupportItem(this, queueItem, attributes);
    }

    get queueItemsDetails() {
        return Array.from(this.queueItemsDetailsMap.values())
            .filter(item => !isHomeVisitSession(item.sessionAttributes))
            .sort(compareByRank);
    }

    get supportItems() {
        return this.queueItemsDetails.filter(isSupport);
    }

    getSupportItem(sessionId?: string) {
        if (!sessionId) return;

        const queueItem = this.getQueueItem(sessionId);

        if (!queueItem || !isSupport(queueItem)) return;

        return queueItem;
    }

    get taskItems() {
        return this.queueItemsDetails.filter(isTask);
    }

    get activeQueueItems() {
        let activeItems: QueueItem[] = [];
        const unAssignedItems: QueueItem[] = [];

        this.queueItemsDetails.forEach(item => {
            if (item.snooze.isSnoozed || (item.isSupport() && item.isAutopilot)) {
                return;
            } else if (item.isAssignedToMe) {
                activeItems.push(item);
            } else {
                unAssignedItems.push(item);
            }
        });

        const sortedActiveItems = feedPrioritySort(activeItems);

        if (this.isAutoAssignMethod) {
            return sortedActiveItems;
        } else {
            return [...sortedActiveItems, ...unAssignedItems];
        }
    }

    get myAssignedItems() {
        return this.queueItemsDetails.filter(item => this.sessionsInFeed.has(item.sessionId) && item.isAssignedToMe);
    }

    get myAssignedSupportItems() {
        return this.myAssignedItems.filter(isSupport);
    }

    get mySnoozedItems() {
        return this.queueItemsDetails.filter(item => {
            if (!item.snooze.isSnoozed) {
                return false;
            }

            if (item instanceof SupportItem) {
                return !item.isAutopilot;
            }

            return true;
        });
    }

    get hasUnengagedSessions() {
        return this.myAssignedSupportItems.some(item => !item.didExpertSendMessage);
    }

    get unengagedSessions() {
        return this.myAssignedSupportItems.filter(item => !item.didExpertSendMessage);
    }

    get myAutopilotItems() {
        return this.supportItems.filter(item => item.isAutopilot);
    }

    get myNeglectedItems() {
        return this.queueItemsDetails.filter(item => item.isNeglected);
    }

    get callItems() {
        return this.supportItems.filter(item => item.callDetails.isCall && item.inFeed);
    }

    get waitingForResponse() {
        return this.unassignedSupportItems.filter(item => item.isWaitingForResponse);
    }

    get unAssignedItems() {
        return this.queueItemsDetails.filter(item => !item.isAssigned && item.inFeed && !item.isSkipFailedAssign);
    }

    get unassignedSupportItems() {
        return this.unAssignedItems.filter(isSupport);
    }

    get selectedSession() {
        if (!this.selectedSessionId) {
            return undefined;
        }

        return this.getQueueItem(this.selectedSessionId);
    }

    get selectedSupportSession() {
        return this.selectedSession?.isSupport() ? this.selectedSession : undefined;
    }
    get selectedTaskSession() {
        return this.selectedSession?.isTask() ? this.selectedSession : undefined;
    }

    get attentionRequiredCounts() {
        return this.myAssignedItems.reduce(
            (accumulator, assignedItem) => {
                if (assignedItem.isNeglected) {
                    accumulator.neglectedCount += 1;
                } else if (assignedItem.snooze.isBackFromSnooze) {
                    accumulator.backFromSnoozeCount += 1;
                } else if (assignedItem.isSupport() && assignedItem.isWaitingForResponse) {
                    accumulator.respondedCount += 1;
                }
                return accumulator;
            },
            {neglectedCount: 0, backFromSnoozeCount: 0, respondedCount: 0}
        );
    }

    getQueueItem = (sessionId: string) => {
        return this.queueItemsDetailsMap.get(sessionId);
    };

    private async handleLegacyTimelineChange(sessionId?: string) {
        if (!sessionId) {
            if (this.selectedSessionId) {
                this.getSupportItem(this.selectedSessionId)?.timelineModel.disconnect();
            }
            return;
        }

        if (this.selectedSessionId === sessionId) {
            return;
        }

        this.getSupportItem(this.selectedSessionId)?.timelineModel.disconnect();
        await this.getSupportItem(sessionId)?.timelineModel.connect();
    }

    selectSession = async (sessionId?: string) => {
        this.supportItemHasBeenSelected = true;
        // The selection action is expecting the queue items list to be loaded
        // Its behaving differently when a queue item is missing on the list
        await when(() => this.isFirstItemsLoaded);

        await this.handleLegacyTimelineChange(sessionId);

        if (this.selectedSessionId === sessionId) {
            return;
        }

        runInAction(() => {
            if (this.selectedSessionId) {
                const item = this.getQueueItem(this.selectedSessionId);

                if (item && !item.inFeed) {
                    this.queueItemsDetailsMap.delete(this.selectedSessionId);
                }
            }

            this.selectedSessionId = sessionId;
        });

        if (!this.selectedSessionId) {
            return;
        }

        // If already in feed, no need to fetch
        if (this.getQueueItem(this.selectedSessionId)) {
            return;
        }

        try {
            const {id, attributesByType, ...rest} = await getFeedActionsClient().getSession(this.selectedSessionId);

            runInAction(async () => {
                const model = this.createItem({
                    ...rest,
                    sessionId: this.selectedSessionId!,
                    rank: 0,
                    sessionAttributes: attributesByType,
                    expertId: this.user.uid,
                });

                this.queueItemsDetailsMap.set(this.selectedSessionId!, model);
                await this.getSupportItem(this.selectedSessionId)?.timelineModel.connect();
            });
        } catch (err) {
            logger.warn('Failed to get feed item', {err, extra: {sessionId: this.selectedSessionId}});
            this.selectSession(undefined);
        }
    };
}

export default new ExpertFeedStore();
