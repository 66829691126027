import {QueueItemAttribute} from '@soluto-private/expert-queue-api-types';
import {pick} from 'ramda';

import {Tag, TagStyle} from './types';
import {Attribute} from '../../../../types';
import {createNewSalesTag, isSalesAttribute} from './salesTag';

export const mapQueueItemAttributesToTags = (queueItemAttributes: QueueItemAttribute[]): Tag[] =>
    queueItemAttributes.map(
        (attribute): Tag => {
            return createTagFromQueueItemAttribute(attribute);
        }
    );

export const mapSessionAttributesToTags = (attributes: Attribute[]): Tag[] =>
    attributes.filter(shouldCreateTag).map(
        (attribute): Tag => {
            if (isSalesAttribute(attribute)) {
                return createNewSalesTag(attribute);
            }
            return createTagFromSessionAttribute(attribute);
        }
    );

export const isSpecialTag = (attr: Attribute) => attr.attributeType === 'partnerTag' || attr.key === 'percentage';

export const getDefinedTags = (queueItemAttributes: QueueItemAttribute[]) =>
    queueItemAttributes.filter(({type, key}) => !!type || !!key);

export const mapSpecialTags = (attribute: Attribute): Tag | null => {
    switch (attribute.attributeType) {
        case 'partnerTag':
            return partnerToTag(attribute);
        default:
            return null;
    }
};
const SecurityAdvisorStyleTag: TagStyle = {
    backgroundColor: '#433CE2',
    textColor: '#fff',
};

const shouldCreateTag = (attribute: Attribute) =>
    (attribute.displayName && attribute.isViewable) || isSalesAttribute(attribute);

const isSecurityAdvisorAttribute = (value?: string, type = '') =>
    value && value === 'Security Advisor' && type === 'groupTag';

const partnerToTag = (attribute: Attribute): Tag => ({
    tagType: attribute.attributeType!,
    tagKey: attribute.key,
    displayText: attribute.displayName,
    tagStyle: attribute.fields?.iconColor && {
        backgroundColor: (attribute.fields.iconColor as unknown) as string,
        textColor: (attribute.fields.foregroundColor as unknown) as string,
    },
});

const createTagFromSessionAttribute = (attribute: Attribute) => ({
    tagType: attribute.attributeType!,
    tagKey: attribute.displayName!,
    displayText: attribute.displayName,
    tagStyle: isSecurityAdvisorAttribute(attribute.displayName, attribute.attributeType)
        ? SecurityAdvisorStyleTag
        : pick(['backgroundColor', 'textColor', 'fontWeight'], attribute.fields || {}),
});

const createTagFromQueueItemAttribute = ({type, key, fields}: QueueItemAttribute) => ({
    tagType: type,
    tagKey: key,
    displayText: key,
    tagStyle: isSecurityAdvisorAttribute(key, type)
        ? SecurityAdvisorStyleTag
        : pick(['backgroundColor', 'textColor'], fields || {}),
});
