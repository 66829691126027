import styled from 'styled-components';
import {MEDIUM_WIDTH} from './consts';

export const BoxContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 96px 20px 20px 50px;
`;

export const Title = styled.span`
    font-size: 40px;
    font-weight: 700;
    margin-bottom: ${({theme}) => theme.spacing(1)}px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 30px;
    }
`;
