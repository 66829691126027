import React from 'react';

export default ({color}: {color: string}) => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.0816 2.43252C16.7324 1.78162 17.7877 1.7816 18.4386 2.43249L18.5816 2.57547C19.2325 3.22633 19.2325 4.28158 18.5816 4.93246L11.4272 12.0873L8.92717 9.58732L16.0816 2.43252Z"
            fill={color}
            fillOpacity="1"
        />
        <path d="M7.67676 10.8371L10.1766 13.3373L7.67676 13.3371V10.8371Z" fill={color} fillOpacity="1" />
        <path
            d="M14.3434 16.6706V11.6706L16.0101 10.004V16.6706C16.0101 17.5911 15.2639 18.3373 14.3434 18.3373H4.34342C3.42295 18.3373 2.67676 17.5911 2.67676 16.6706V6.67062C2.67676 5.75015 3.42295 5.00395 4.34342 5.00395H11.0101L9.34342 6.67062H4.34342V16.6706H14.3434Z"
            fill={color}
            fillOpacity="1"
        />
    </svg>
);
