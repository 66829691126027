import {Message} from '@soluto-private/messaging-api-client';

export enum MessageStatus {
    Pending,
    Success,
    Failed,
}

export interface EnhancedMessage extends Message {
    status?: MessageStatus;
}
