import * as React from 'react';
import styled from 'styled-components';
import {ImageIcon, PaperclipIcon} from '@anywhere-expert/icons';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {setAppOverlay} from '@anywhere-expert/app-overlay';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';

const iconProps = {size: 's'};
const tooltipStyleOverride = {marginLeft: '6px'};
const ImageUploadInput = styled.input`
    display: none;
`;

let oldBodyFocus;

type Props = {
    onSelect: (selectedFile: File) => Promise<void>;
    disabled: boolean;
    selectedSession?: SupportItem;
};

const MediaSelector = observer(({onSelect, disabled, selectedSession}: Props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const showOverlay = React.useCallback(() => setAppOverlay(true), []);
    const hideOverlay = React.useCallback(() => setAppOverlay(false), []);

    const handleIconClick = React.useCallback(() => {
        inputRef.current?.click();
    }, []);

    const onFileSelected = React.useCallback(
        e => {
            if (e.target.files && e.target.files[0]) {
                onSelect(e.target.files[0]);
            }

            e.target.value = '';
            hideOverlay();
        },
        [hideOverlay, onSelect]
    );

    const videoUploadSettings = useTweekValue<{
        isEnabled: boolean;
        videoFormats: string[];
    }>('support/session/messaging/rich_messaging/video_display/_', {isEnabled: false, videoFormats: []});

    const imageFormats = useTweekValue<string[]>(
        'support/session/messaging/rich_messaging/image_display/image_formats',
        []
    );
    const attachmentFormats = useTweekValue<string[]>(
        'support/session/messaging/rich_messaging/attachment_display/attachment_formats',
        []
    );

    const validMediaTypes = React.useMemo(() => {
        let validFormats: string[] = [];
        validFormats = validFormats.concat(imageFormats);
        if (selectedSession?.isAppleBusinessChat) {
            validFormats = validFormats.concat(attachmentFormats);
        }
        if (videoUploadSettings.isEnabled) {
            validFormats = validFormats.concat(videoUploadSettings.videoFormats);
        }

        return validFormats.join(', ');
    }, [imageFormats, attachmentFormats, videoUploadSettings, selectedSession?.isAppleBusinessChat]);

    React.useEffect(() => {
        oldBodyFocus = document.body.onfocus;

        document.body.onfocus = e => {
            oldBodyFocus && oldBodyFocus(e);
            hideOverlay();
        };

        return () => (document.body.onfocus = oldBodyFocus);
    }, [hideOverlay]);

    const icon = selectedSession?.isAppleBusinessChat ? PaperclipIcon : ImageIcon;

    return (
        <div>
            <label htmlFor="image-upload">
                <CoreIconButton
                    icon={icon}
                    disabled={disabled}
                    variant="icon"
                    size="s"
                    iconProps={iconProps}
                    onClick={handleIconClick}
                    tooltipProps={{
                        text: 'Upload media',
                        styleOverride: tooltipStyleOverride,
                        position: 'right',
                    }}
                />
            </label>
            <ImageUploadInput
                type="file"
                id="image-upload"
                accept={validMediaTypes}
                onChange={onFileSelected}
                onClick={showOverlay}
                disabled={disabled}
                ref={inputRef}
            />
        </div>
    );
});

export default MediaSelector;
