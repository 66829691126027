import {authorizedFetch, getUserProfile} from '@anywhere-expert/auth';
import logger from '@anywhere-expert/logging';
import CobrowseAPI from 'cobrowse-agent-sdk';
import {TypedEventEmitter} from 'minimal-typed-event-emitter';
import {makeAutoObservable, runInAction, observable} from 'mobx';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import CobrowseIframeManager from './CobrowseIframeManager';
import {CobrowseSession, CobrowseSessionState, COBROWSE_SESSION_STATES, ICobrowseAPI} from './cobrowse-agent-sdk_types';
import extractDeviceScreenshareSupportInfoFromSession from './extractDeviceScreenshareSupportInfoFromSession';
import {SupportedScreenshareLevel, SupportingDeviceSupportInfo} from './sharedScreenshareDefinitions';
import {monitor} from '@anywhere-expert/monitor';
import {featureName} from './definitions';

type EventsOfScreenshareSessionManager = {
    [key in `sessionUpdate.state.${CobrowseSessionState}`]: [];
} & {
    'sessionUpdate.state': [updatedSessionState: CobrowseSessionState];
    sessionUpdate: [sessionUpdateReportedFromCobrowse: CobrowseSession];
    beforeDispose: [];
    afterDispose: [];
};

export default class ScreenshareSessionManager {
    // #region Static stuff

    private static runningSessions = observable(new Map<string, ScreenshareSessionManager>());

    public static runningScreenshareSessionsExceptFor = (aeSessionId: string) =>
        Array.from(ScreenshareSessionManager.runningSessions.entries())
            .filter(([key]) => key !== aeSessionId)
            .map(([_, value]) => value);

    // #endregion

    private readonly cobrowseApi: ICobrowseAPI;

    private session: CobrowseSession | undefined;

    private _disposed: boolean = false;
    public get wasDisposed() {
        return this._disposed;
    }

    private deviceSupportInfoWhenStarted: SupportingDeviceSupportInfo;
    private deviceSupportInfoWhenJoined: SupportingDeviceSupportInfo | undefined;

    private _lastReportedSessionState: CobrowseSessionState | undefined;
    public get lastReportedSessionState(): CobrowseSessionState | undefined {
        return this._lastReportedSessionState;
    }

    private lastReportedFullDeviceControlAvailable: boolean | undefined;

    private eventEmitter = new TypedEventEmitter<EventsOfScreenshareSessionManager>();

    private readonly aeSession: SupportItem;

    constructor(
        aeSession: SupportItem,
        cobrowseUrl: string,
        deviceSupportInfoWhenStartingTheSession: SupportingDeviceSupportInfo
    ) {
        this.cobrowseApi = new CobrowseAPI(undefined, {api: cobrowseUrl});
        this.aeSession = aeSession;
        this.deviceSupportInfoWhenStarted = {...deviceSupportInfoWhenStartingTheSession};

        makeAutoObservable(this);
    }

    public get wasStarted() {
        return this.session !== undefined;
    }

    public get startedButCustomerHasntJoinedYet() {
        return this.lastReportedSessionState === 'pending';
    }

    public get deviceSupportInfo(): SupportingDeviceSupportInfo {
        const fallbackDeviceSupportInfo = this.deviceSupportInfoWhenStarted;

        const supportLevelWhenJoined = this.deviceSupportInfoWhenJoined?.supportLevel;

        if (supportLevelWhenJoined === undefined) {
            const deviceSupportInfoFromTheSessionAttribute = extractDeviceScreenshareSupportInfoFromSession(
                this.aeSession
            );

            if (deviceSupportInfoFromTheSessionAttribute.supportLevel === 'not-supported') {
                return fallbackDeviceSupportInfo;
            }

            return deviceSupportInfoFromTheSessionAttribute;
        } else if (supportLevelWhenJoined === 'screenshare') {
            return {
                supportLevel: 'screenshare',
            };
        } else if (supportLevelWhenJoined === 'remote-control') {
            return {
                supportLevel: 'remote-control',
                accessibilityEnabled: !!this.lastReportedFullDeviceControlAvailable,
            };
        } else {
            assertNever(supportLevelWhenJoined);
            logger.error(
                `ScreenshareSessionManager.deviceSupportInfo: supportLevelWhenJoined has an unsupported value: ${supportLevelWhenJoined}`,
                {extra: {featureName}}
            );

            return fallbackDeviceSupportInfo;
        }
    }

    public get isStartedButNotEndedOrDisposed() {
        return this.wasStarted && this.lastReportedSessionState !== 'ended' && !this._disposed;
    }

    // A cobrowse session  state lifecycle is pending > authorizing > active > ended, unless it is "ended" earlier.
    public get isAuthorizingOrActive() {
        return this.lastReportedSessionState === 'authorizing' || this.lastReportedSessionState === 'active';
    }

    public get isRemoteControlAvailableRightNow() {
        return (
            this.lastReportedSessionState === 'active' &&
            this.deviceSupportInfo.supportLevel === 'remote-control' &&
            this.lastReportedFullDeviceControlAvailable
        );
    }

    public get areToolsAvailableRightNow() {
        return this.isRemoteControlAvailableRightNow;
    }

    public get sessionIdForPatchyPurposes() {
        return this.session?.id;
    }

    /**
     * This function is NOT thread safe.
     */
    public async startScreenshareSession(): Promise<
        | {
              createdCobrowseSessionId: string;
              error?: never;
          }
        | {createdCobrowseSessionId?: never; error: 'sessionLimitReached' | 'unknownError' | 'couldNotGetJwt'}
    > {
        if (this.wasStarted) {
            logger.error('Invalid usage, attempted to start the session multiple times.', {extra: {featureName}});
            return {error: 'unknownError'};
        }

        const {failedToGetJwt} = await this.loadJwtForCobrowse();

        if (failedToGetJwt) {
            return {error: 'couldNotGetJwt'};
        }

        let theNewCobrowseSession: CobrowseSession; // Without the temp variable mobx would bitch about changing an observable outside of an action

        try {
            theNewCobrowseSession = await this.cobrowseApi.sessions.create();
        } catch (e) {
            if (e.message.includes('concurrent session limit')) {
                logger.error('Screenshare: Cobrowse concurrent session limit reached', {
                    err: e.message,
                    extra: {featureName},
                });
                let numberOfActiveSessionsWhenErrorWasReceived: number | undefined;

                try {
                    numberOfActiveSessionsWhenErrorWasReceived = (
                        await this.cobrowseApi.sessions.list({state: 'active'})
                    ).length;
                } catch {
                    // If this fails, it's not so bad, this is only retrieved for the log.
                }

                logger.error(
                    'Screenshare: failed to start a new Cobrowse session because the concurrent session limit was reached.',
                    {
                        err: e,
                        extra: {numberOfActiveSessionsWhenErrorWasReceived, featureName},
                    }
                );

                return {error: 'sessionLimitReached'};
            } else {
                logger.error('Screenshare: failed to start a new Cobrowse session for an unknown reason', {
                    err: e,
                    extra: {featureName},
                });

                return {error: 'unknownError'};
            }
        }

        runInAction(() => (this.session = theNewCobrowseSession));

        if (this.session === undefined) {
            logger.warn(
                'ScreenshareSessionManager: this.session is undefined after starting the session. This should never happen.',
                {extra: {featureName}}
            );

            return {error: 'unknownError'};
        }

        runInAction(() => {
            ScreenshareSessionManager.runningSessions.set(this.aeSession.id, this);
            this._lastReportedSessionState = this.session!.state;
        });

        this.on('sessionUpdate', logSessionUpdate);
        this.on('sessionUpdate.state', this.loadSessionStateUpdate);
        this.on('sessionUpdate', this.updateFullDeviceControlAvailability);
        this.once('sessionUpdate.state.active', this.saveDeviceSupportInfoWhenJoined);
        this.once('sessionUpdate.state.active', this.monitorSessionCreation);
        this.once('sessionUpdate.state.active', this.turnOnFullDeviceCapabilities); // Turning on full device capabilities only works once the session is active
        this.once('sessionUpdate.state.ended', this.dispose);

        await this.session.subscribe();
        this.session.on('updated', this.handleSessionUpdateEvent); // Important: events will only start firing when the iframe is loaded

        logger.info('Started screenshare session', {extra: {cobrowseSessionId: this.session.id, featureName}});

        return {createdCobrowseSessionId: this.session.id};
    }

    public createIframeManager(): CobrowseIframeManager {
        if (!this.wasStarted || this.session === undefined) {
            throw new Error('Invalid usage, attempted to connect to the iframe before the session was started.');
        }

        return new CobrowseIframeManager(this.cobrowseApi, this.session.id);
    }

    public endSessionIfRunning() {
        return this.session?.end();
    }

    //#region EventEmitter management

    /**
     * The callback triggers for any state update received from the cobrowse server, even if the session state didn't actually change.
     */
    public on: typeof this.eventEmitter.on = this.eventEmitter.on.bind(this.eventEmitter);

    /**
     * The callback triggers for any state update received from the cobrowse server, even if the session state didn't actually change.
     */
    public once: typeof this.eventEmitter.once = this.eventEmitter.once.bind(this.eventEmitter);

    public off: typeof this.eventEmitter.removeListener = this.eventEmitter.removeListener.bind(this.eventEmitter);

    //#endregion

    public dispose = () => {
        if (this._disposed) {
            logger.warn('ScreenshareSessionManager: tried to dispose a session manager that was already disposed.', {
                extra: {featureName},
            });
            return;
        }

        this.eventEmitter.emit('beforeDispose');

        this.session?.removeAllListeners(); // This is important because this.session.unsubscribe() doesn't prevent future cobrowse events from firing.
        this.session?.unsubscribe();
        ScreenshareSessionManager.runningSessions.delete(this.aeSession.id);

        if (this.lastReportedSessionState !== 'ended') {
            this.session?.end();
        }

        this._disposed = true;
        logger.debug('ScreenshareSessionManager disposed.', {extra: {featureName}});
        this.eventEmitter.emit('afterDispose');
    };

    /**
     * Should be used to solve two cobrowse issues:
     * 1. Sometimes cobrowse servers take a while to send the "session ended" event (up to a few seconds), which causes a delay between the expert clicking "end session" and the screenshare panel closing.
     * 2. On rare occasions the "session ended" event isn't received from cobrowse at all, and then the screenshare panel never closes.
     */
    public fakeSessionEndedEventIfNotEndedYet() {
        if (this.lastReportedSessionState === 'ended') return;
        if (!this.session) return;

        const fakeSessionEndedUpdate = this.session.toJSON();
        fakeSessionEndedUpdate['state'] = 'ended';
        fakeSessionEndedUpdate['ended'] = new Date();

        const fieldsCopy = {...fakeSessionEndedUpdate};
        fakeSessionEndedUpdate['toJSON'] = () => fieldsCopy;

        this.handleSessionUpdateEvent(fakeSessionEndedUpdate as CobrowseSession);
    }

    private async loadJwtForCobrowse(): Promise<{failedToGetJwt: boolean}> {
        const result = await getJwtForCobrowse();
        if (result.failedToGetJwt) {
            return {failedToGetJwt: result.failedToGetJwt};
        } else {
            this.cobrowseApi.token = result.jwt;

            return {failedToGetJwt: result.failedToGetJwt};
        }
    }

    private handleSessionUpdateEvent = (updatedSession: CobrowseSession) => {
        this.eventEmitter.emit('sessionUpdate', updatedSession);

        if (updatedSession.state !== undefined) {
            this.eventEmitter.emit('sessionUpdate.state', updatedSession.state);
            this.eventEmitter.emit(`sessionUpdate.state.${updatedSession.state}`);
        }
    };

    private loadSessionStateUpdate = (state: CobrowseSessionState) => {
        runInAction(() => (this._lastReportedSessionState = state));
    };

    private turnOnFullDeviceCapabilities = async () => {
        await this.session?.setFullDevice(true);
    };

    private monitorSessionCreation = async () => {
        try {
            const currentSessions = await this.cobrowseApi.sessions.list({state: 'active'});
            monitor.gauge('screen-share_cobrowse-concurrent-sessions-count', currentSessions.length);
        } catch (error) {
            logger.error('failed to write monitor value', {err: error, extra: {featureName}});
        }
    };

    private updateFullDeviceControlAvailability = (updatedSession: CobrowseSession) => {
        this.lastReportedFullDeviceControlAvailable = updatedSession.device['full_device_control'];
    };

    private saveDeviceSupportInfoWhenJoined = () => {
        let supportLevel: SupportedScreenshareLevel;

        switch (this.session?.device['platform']) {
            case 'ios':
                supportLevel = 'screenshare';
                break;
            case 'android':
                supportLevel = 'remote-control';
                break;
            default:
                logger.error(
                    'Screenshare: Unsupported platform detected when extracting the screenshare support level from the cobrowse session the customer has just joined to.',
                    {extra: {platform: this.session?.device['platform'], featureName}}
                );
                supportLevel = 'screenshare'; // Allow minimal capabilities, just to be on the safe side.
                break;
        }

        if (supportLevel === 'remote-control') {
            this.deviceSupportInfoWhenJoined = {
                supportLevel,
                accessibilityEnabled: !!this.session?.device['full_device_control'],
            };
        } else {
            this.deviceSupportInfoWhenJoined = {
                supportLevel,
            };
        }
    };
}

function logSessionUpdate(updatedSession: CobrowseSession) {
    const updateData = updatedSession.toJSON();

    if (updateData['device']?.['app_installation_id']) {
        updateData['device']['app_installation_id'] = 'REDACTED';
    }

    const piiRedactedUpdateData = updateData;

    logger.info(`screenshare session state update: ${updatedSession.state}`, {
        extra: {session: piiRedactedUpdateData, featureName},
    });

    if (!COBROWSE_SESSION_STATES.includes(updatedSession.state)) {
        logger.warn(`Unknown session state: ${updatedSession.state}`, {
            extra: {updatedSession: piiRedactedUpdateData, featureName},
        });
    }
}

export async function getJwtForCobrowse(): Promise<
    | {
          failedToGetJwt: true;
          jwt?: never;
      }
    | {
          failedToGetJwt: false;
          jwt: string;
      }
> {
    const {email, displayName} = getUserProfile();

    let response: Response;

    try {
        response = await authorizedFetch(
            `https://cobrowse-auth.mysoluto.com/api/v1/generate-token/${email}/${displayName}`
        );
    } catch (error) {
        logger.error('Failed to get JWT for cobrowse', {err: error, extra: {featureName}});
        return {failedToGetJwt: true};
    }

    if (!response.ok) {
        logger.error('Failed to get JWT for cobrowse', {extra: {response, featureName}});
        return {failedToGetJwt: true};
    }

    const token = await response.text();

    return {failedToGetJwt: false, jwt: token};
}

/**
 * This function is meant to raise a compilation error if the type of the parameter passed to it is not `never`.
 */
function assertNever(_: never) {}
