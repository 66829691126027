import {useCallback} from 'react';
import uuid from 'uuid';
import {buildMediaItem, createImageTag, postItem, resizeMedia, tryToSaveToLocalStorage} from './mediaUtils';
import {UploadStatus} from '../storageApi';
import {ItemState, StorageError} from './mediaConsts';
import uploadImage from './uploadImage';
import {useTweekValue} from 'react-tweek';
import {useUserProfile} from '@anywhere-expert/auth';
import {useAnalytics} from 'react-shisell';
import {TYPE_IMAGE_UPLOAD, usePerformance} from '@anywhere-expert/performance';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';

const useImageUpload = () => {
    const performance = usePerformance();
    const user = useUserProfile();
    const analytics = useAnalytics();
    const richMessagingImage = useTweekValue('support/session/messaging/rich_messaging/image/_', {
        timeToCancel: 5,
        imageMaxWidth: 1500,
        imageMaxHeight: 1500,
        thumbnailMaxHeight: 220,
        thumbnailMaxWidth: 220,
        maxCacheAgeInSeconds: 21600,
    });
    const {imageMaxWidth, imageMaxHeight, thumbnailMaxWidth, thumbnailMaxHeight} = richMessagingImage;

    return useCallback(
        async (timelineId: string, mediaFile: File, sessionId: string) => {
            performance.start({
                tag: TYPE_IMAGE_UPLOAD,
                identifier: sessionId,
            });
            const contentId = uuid();
            const mediaTag = await createImageTag(mediaFile);
            const supportItem = ExpertFeedStore.getSupportItem(sessionId);

            if (!supportItem) return;

            if (mediaTag.src) {
                tryToSaveToLocalStorage(contentId, mediaTag.src);
            }

            const uploadingItem = await buildMediaItem(
                user,
                mediaTag,
                supportItem.supportItemDetails.partner,
                sessionId,
                contentId,
                ItemState.uploading
            );

            await postItem(supportItem, uploadingItem, ItemState.uploading);

            const {fullSizeMediaData, fullSizeMediaDimensions, thumbSizeMediaData} = await resizeMedia(
                mediaFile,
                imageMaxWidth,
                imageMaxHeight,
                thumbnailMaxWidth,
                thumbnailMaxHeight
            );

            try {
                const uploadResult = await uploadImage(timelineId, contentId, fullSizeMediaData, thumbSizeMediaData);
                if (!uploadResult) {
                    return {status: UploadStatus.canceled};
                }

                if (localStorage.getItem(`${contentId}_cancel`) !== 'true') {
                    const {width, height} = fullSizeMediaDimensions;

                    const uploadedItem = await buildMediaItem(
                        user,
                        mediaTag,
                        supportItem.supportItemDetails.partner,
                        sessionId,
                        contentId,
                        ItemState.uploaded,
                        uploadResult.thumbnailUrl,
                        uploadResult.url,
                        {
                            width,
                            height,
                        }
                    );
                    await postItem(supportItem, uploadedItem, ItemState.uploaded);

                    analytics.dispatcher
                        .withIdentity('SessionId', sessionId)
                        .withIdentity('CustomerId', timelineId)
                        .withExtra('ImageUrl', uploadResult.url)
                        .dispatch('ExpertImageSent');

                    return {
                        status: UploadStatus.completed,
                        imageUrl: uploadResult.url,
                    };
                }
                localStorage.removeItem(contentId);

                return {
                    status: UploadStatus.canceled,
                    imageUrl: null,
                };
            } catch (err) {
                if (err.code !== StorageError.canceled) {
                    supportItem.timelineModel.removeLocalMessage(contentId);
                    localStorage.removeItem(contentId);
                }

                return {
                    status: UploadStatus.canceled,
                    imageUrl: null,
                };
            } finally {
                performance.end({
                    tag: TYPE_IMAGE_UPLOAD,
                    identifier: sessionId,
                    extraData: {fullSizeMediaDimensions},
                });
            }
        },
        [user, analytics, richMessagingImage, performance]
    );
};

export default useImageUpload;
