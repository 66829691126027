import {useEffect} from 'react';
import {useTweekValue} from 'react-tweek';
import {useUserProfile} from '@anywhere-expert/auth';
import ExpertFeedStore from '../ExpertFeedStore';
import {AssignmentType} from '../types';
import {AnalyticsDispatcher} from 'shisell';

let didInit = false;
export const useInitFeed = (dispatcher: AnalyticsDispatcher) => {
    const user = useUserProfile();
    const assignmentType = useTweekValue<AssignmentType>(
        'support/routing/assignment_strategy/assignment_type',
        'get-next'
    );

    useEffect(() => {
        if (didInit) return;

        didInit = true;

        ExpertFeedStore.init({
            user,
            assignmentType,
            analyticsDispatcher: dispatcher,
        });
    }, [dispatcher, user]);
};
