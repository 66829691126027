import {compose, lifecycle} from 'recompose';
import {equals} from 'ramda';
import {withTweekValues, WithTweekValues} from 'react-tweek';

const pure = lifecycle({
    shouldComponentUpdate(prevProps) {
        return !equals(prevProps, this.props);
    },
});

export const withTweekKeys: WithTweekValues = (mapping, options) =>
    compose(
        pure,
        withTweekValues(mapping, options)
    ) as any;
