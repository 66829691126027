import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {OfferOnResolutionRecommendation, TailoredOfferRecommendation} from '@anywhere-expert/recommendations';
import {getTweekValue} from '@anywhere-expert/tweek';
import {
    getResolutionDetectionFields,
    getTailoredOfferRecommendationsFields,
    getTailoredSalesKeywordFrequencyMap,
} from './helpers';
import {OfferRecPreviewData} from '../types';

export const getOfferRecommendations = (
    session: SupportItem
): (OfferOnResolutionRecommendation | TailoredOfferRecommendation)[] => {
    const {sessionId, pool: partner, sessionAttributes} = session;
    const tailoredOfferPreviewData = getTailoredOfferPreviewData(session);

    if (tailoredOfferPreviewData.length) {
        const keywords = getTailoredSalesKeywordFrequencyMap(sessionAttributes)?.slice(0, 3);
        return tailoredOfferPreviewData.map(previewData => ({
            type: 'tailored-offer',
            data: {
                sessionId,
                offerRecommendation: previewData,
                partner,
                keywords,
            },
            id: `tailored-offer-${keywords?.map(({keyword, frequency}) => `${keyword}-${frequency}`).join('-')}`,
        }));
    }

    return getResolutionOfferPreviewData(session).map((previewData, id) => ({
        type: 'offer-on-resolution',
        data: {
            sessionId,
            offerRecommendation: previewData,
            partner,
        },
        id: `offer-on-resolution-${id}`,
    }));
};

const getTailoredOfferPreviewData = (session: SupportItem): OfferRecPreviewData[] => {
    const isTailoredSalesEnabled = getTweekValue(MessagingSalesTweekKeys.IsTailoredSalesUIEnabled, false);
    if (!isTailoredSalesEnabled) {
        return [];
    }

    const recommendations = getTailoredOfferRecommendationsFields(session.sessionAttributes)?.recommendations || [];
    const [text] = recommendations;

    if (!text) {
        return [];
    }

    const {firstName} = session.customerDetails;
    let title = '✨ Tailored Offer';
    if (firstName) {
        title = `✨ ${firstName}'s Offer`;
    }

    return [
        {
            title,
            text,
            isTailored: true,
        },
    ];
};

const getResolutionOfferPreviewData = (session: SupportItem): OfferRecPreviewData[] => {
    const isResolutionDetectionEnabled = getTweekValue(
        MessagingSalesTweekKeys.IsResolutionBasedOfferRecommendationEnabled,
        false
    );

    if (!isResolutionDetectionEnabled) {
        return [];
    }

    const isResolutionDetected =
        getResolutionDetectionFields(session.sessionAttributes)?.resolutionDetectedDateTime !== undefined;

    if (!isResolutionDetected) {
        return [];
    }

    const recommendations = getTweekValue(
        'support/messaging_sales/offer_recommendation/recommendations',
        [] as OfferRecPreviewData[]
    );

    return recommendations.map(({category, title, text}) => ({
        category,
        title,
        text,
        isTailored: false,
    }));
};
