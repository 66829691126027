import {useEffect, useState, useCallback} from 'react';
import logger from '@anywhere-expert/logging';
import {tryLoginWithExistingTokens} from '@anywhere-expert/auth';

const useAccessToLoggedInRoutes = (isLoggedIn: boolean) => {
    const [asyncLoginInProgress, setAsyncLoginInProgress] = useState(false);
    const [loggingInState, setLoggingInState] = useState<'idle' | 'success' | 'failed'>('idle');

    const tryToLogin = useCallback(async () => {
        setAsyncLoginInProgress(true);
        try {
            const {userProfile, loggedIn} = await tryLoginWithExistingTokens();
            if (loggedIn && userProfile) {
                const PostLoginSteps = await import('../../services/authentication/loginBehavior');
                await PostLoginSteps.postLoginSteps(userProfile);
            } else {
                setLoggingInState('failed');
            }
        } catch (err) {
            logger.warn('error authenticating user with previous tokens', {err});
            setLoggingInState('failed');
        } finally {
            setAsyncLoginInProgress(false);
        }
    }, [setLoggingInState]);

    useEffect(() => {
        if (asyncLoginInProgress) return;
        if (isLoggedIn) {
            setLoggingInState('success');
        } else {
            tryToLogin();
        }
    }, [tryToLogin, asyncLoginInProgress, isLoggedIn]);

    return {loggingInState, asyncLoginInProgress};
};

export default useAccessToLoggedInRoutes;
