import {observable, action, computed, runInAction, makeObservable} from 'mobx';
import {EditorState} from 'draft-js';
import {RichInputStore} from '@anywhere-expert/rich-input';
import {Subscription} from 'rxjs';
import {deletedSupportItems$} from '@anywhere-expert/expert-feed-store';

class TimelineInputStore {
    richInputStateMap: Map<string, RichInputStore> = new Map();
    private timelineId: string;
    private deletedSubscription: Subscription | undefined;
    pastingIndication: boolean = false;

    constructor() {
        makeObservable<
            TimelineInputStore,
            | 'richInputStateMap'
            | 'timelineId'
            | 'pastingIndication'
            | 'richInput'
            | 'hasTimeline'
            | 'reactOnRemovedTimeline'
        >(this, {
            richInputStateMap: observable,
            timelineId: observable,
            pastingIndication: observable,
            richInput: computed,
            setTimeline: action,
            hasTimeline: computed,
            clearTimeline: action,
            reactOnRemovedTimeline: action,
            init: action,
        });
    }

    init() {
        this.reactOnRemovedTimeline();
    }

    private reactOnRemovedTimeline = () => {
        this.deletedSubscription = deletedSupportItems$.subscribe(deletedItems => {
            deletedItems.some(item => {
                if (this.richInputStateMap.has(item.timelineModel.timelineId)) {
                    this.removeTimelineRichInput(item.timelineModel.timelineId);
                }
            });
        });
    };

    detectPasting = () => {
        runInAction(() => {
            this.pastingIndication = true;
        });
    };

    setTimeline = (timelineId: string) => {
        !!this.timelineId && this.richInputStateMap.set(this.timelineId, this.richInput);
        this.timelineId = timelineId;
        this.richInput.focus();
        this.setEditorState(this.richInput.moveFocusToEnd(this.richInput.editorState));
    };

    getRichInputByTimeline = (timelineId: string): RichInputStore => {
        if (!this.richInputStateMap.has(timelineId))
            runInAction(() => this.richInputStateMap.set(timelineId, new RichInputStore()));
        return this.richInputStateMap.get(timelineId)!;
    };

    private get hasTimeline(): boolean {
        return this.richInputStateMap.has(this.timelineId);
    }
    get richInput(): RichInputStore {
        if (!this.hasTimeline) {
            runInAction(() => this.richInputStateMap.set(this.timelineId, new RichInputStore()));
        }
        return this.richInputStateMap.get(this.timelineId)!;
    }

    setEditorText = (timelineId, text, shouldFocus = true) => {
        runInAction(() => {
            const richInputState = this.getRichInputByTimeline(timelineId);
            richInputState.setEditorStateFromText(text);
            if (shouldFocus) {
                this.richInput.focusPosition();
            }
        });
    };

    setEditorState = (newEditorState?: EditorState) => {
        runInAction(() => {
            this.richInput.setEditorState(newEditorState ?? EditorState.createEmpty());
        });
    };

    private removeTimelineRichInput = (timelineId: string) => {
        this.richInputStateMap.delete(timelineId);
    };

    clearTimeline = (timelineId: string) => {
        this.setEditorText(timelineId, '', false);
    };

    dtor = () => {
        !!this.deletedSubscription && this.deletedSubscription.unsubscribe();
    };
}

export default new TimelineInputStore();
export type TimelineInputStoreType = TimelineInputStore;
