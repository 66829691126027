import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {CoreText} from '@anywhere-expert/base-ui';
import {useCurrentOffer} from '../../../hooks';
import useMessagingSalesColors from '../../../hooks/useMessagingSalesColors';
import {ExternalEnrollmentProgress} from '@soluto-private/smart-home-anywhere-expert';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import Spinner from '../../Spinner';

const Container = styled.div`
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LoadingText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    margin-top: 40px;
`;

const SalesToolsLoading = observer(({selectedSession}: SalesSessionProps) => {
    const currentOffer = useCurrentOffer(selectedSession.sessionId);
    const enrollmentProgress = currentOffer?.offer.enrollmentProgress;
    const colors = useMessagingSalesColors();
    const color = colors.themeName === 'light' ? colors.blue : colors.green;

    return (
        <Container>
            <Spinner color={color} />
            <LoadingText size="l">
                {enrollmentProgress === ExternalEnrollmentProgress.Loading
                    ? 'Working on the enrollment...'
                    : 'Starting your sales session...'}
            </LoadingText>
        </Container>
    );
});

export default SalesToolsLoading;
