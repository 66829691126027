import * as React from 'react';
import styled, {keyframes} from 'styled-components';

const circle = keyframes`
    0%{
        transform: rotate(var(--startDegree)) translate(var(--radius)) rotate(calc(var(--startDegree) * -1));
    
    }
    100%{
        transform: rotate(var(--finishDegree)) translate(var(--radius)) rotate(calc(var(--finishDegree) * -1));
    }
`;

const scaleIn = keyframes`
    0%{
        transform: scale(0);
    
    }
    100%{
        transform: scale(1);
    }
`;

const BaseBubble = styled.div`
    position: absolute;
    overflow: hidden;
    margin: auto;
    animation: ${circle} var(--animationDuration) linear infinite;
    img {
        transform: scale(0);
        animation: ${scaleIn} 0.5s linear var(--enterDelay) forwards;
        max-width: 100%;
    }
`;

interface BubbleProps {
    radius: number;
    degree: number;
    enterDelay: number;
    animationDuration?: number;
    children: React.ReactNode;
}

const Bubble = styled(({radius, degree, enterDelay, animationDuration, ...rest}: BubbleProps) => (
    <BaseBubble {...rest} />
))`
    --radius: ${({radius}) => radius + 210}px;
    --startDegree: ${({degree}) => degree}deg;
    --finishDegree: ${({degree}) => degree + 360}deg;
    --enterDelay: ${({enterDelay}) => enterDelay}ms;
    --animationDuration: ${({animationDuration}) => animationDuration}s;
`;

export default Bubble;
