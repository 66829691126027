import {getFromTweekForContext, TweekCustomContextConfig, TweekCustomContextType} from './tweekContext';
import {Context} from 'tweek-client';

const customerIdToContext = (customerId: string): Context => ({
    device: {
        id: customerId,
    },
    home_user: {
        id: customerId,
    },
    messaging_group: {
        id: customerId,
    },
});

export const customIdToContextConfig = (customerId: string, additionalContext?: Context): TweekCustomContextConfig => ({
    type: TweekCustomContextType.Customer,
    id: customerId,
    context: {
        ...customerIdToContext(customerId),
        ...additionalContext,
    },
});

export const getFromTweekForCustomer = async <T>(customerId: string, key: string): Promise<T | undefined> =>
    getFromTweekForContext<T>(customIdToContextConfig(customerId), key);
