import React from 'react';
import styled from 'styled-components';
import LobbyHeader from './LobbyHeader';
import WhatsNext from './WhatsNext';
import Tip from './Tip';
import {SalesStats} from '@anywhere-expert/messaging-sales';
import {useMessagingSalesStatsEnablement} from '@anywhere-expert/messaging-sales';
import {SMALL_WIDTH, LARGE_WIDTH} from './shared/consts';
import {palette} from '@anywhere-expert/colors';

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    display: grid;
    grid-template-columns: minmax(420px, 67%) minmax(420px, 33%);
    grid-template-rows: minmax(350px, 67%) minmax(280px, 33%);
    font-family: Apercu Pro, sans-serif;
    color: ${({theme}) => theme.colors.neutral.black};
    background-color: ${palette.neutral.white};

    @media (max-width: ${SMALL_WIDTH}) {
        grid-template-columns: 1fr;
        grid-template-rows: 350px auto auto;
    }

    @media (min-width: ${LARGE_WIDTH}) {
        grid-template-columns: minmax(420px, 60%) minmax(420px, 40%);
    }
`;

const Header = styled.div`
    @media (min-width: ${SMALL_WIDTH}) {
        grid-column: 2 span;
    }
`;

const Lobby = () => {
    const isSalesStatsEnabled = useMessagingSalesStatsEnablement();

    return (
        <Container data-test-id="Lobby">
            <Header>
                <LobbyHeader />
            </Header>
            <WhatsNext />
            {isSalesStatsEnabled ? <SalesStats /> : <Tip />}
        </Container>
    );
};

export default Lobby;
