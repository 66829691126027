import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import CannedMessagesTab from './CannedMessagesTab';
import {TabProps} from '@anywhere-expert/session-sidebar';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {getTweekValue} from '@anywhere-expert/tweek';
import {initCalculatedCategories} from './calculatedCategories';

interface CannedMessagesTabContainerType extends React.FunctionComponent<TabProps> {
    getDisabledText?: any;
}

const CannedMessagesTabContainer: CannedMessagesTabContainerType = observer(({selectedSession}: TabProps) => {
    useEffect(() => {
        initCalculatedCategories();
    }, []);

    return <CannedMessagesTab selectedSession={selectedSession} />;
});

CannedMessagesTabContainer.getDisabledText = (selectedSession: SupportItem) => {
    const isEnabled = getTweekValue<boolean>('support/session/omnibox/canned_messages/is_enabled', true);
    if (!selectedSession.isAssignedToMe) {
        return 'Available only for your assigned sessions';
    }
    if (selectedSession.snooze.isSnoozed) {
        return 'Available only for active sessions';
    }
    if (!isEnabled) {
        return 'This tool is not available';
    }
    return undefined;
};

export const getCannedMessagesConfig = () => {
    const predefinedCategoriesLink = getTweekValue<string>(
        'support/session/canned_message/predefined_categories_link',
        ''
    );

    return {isPredefined: !!predefinedCategoriesLink};
};

export default CannedMessagesTabContainer;
