import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {Message} from '../message';
import {observer} from 'mobx-react';
import {CannedMessage} from '@soluto-private/canned-messages-api-client';
import {MostFrequentMessages} from '../../types';
import CategoryHeader from './CategoryHeader';
import {useMostFrequentlyUsedCategoryData} from '../../calculatedCategories';
import {useCannedMessagesNavigationStore} from '../../useCannedMessagesNavigationStore';

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 93%;
    word-break: break-word;
    margin-left: 8px;
    margin-bottom: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
`;

type Props = {
    messages: MostFrequentMessages;
    onMessageSelection: (categoryTitle: string, categoryId: string, messageId: string, message: CannedMessage) => void;
};

const MostFrequentUsedCategory = observer(({messages, onMessageSelection}: Props) => {
    const mostFrequentlyUsedCategoryData = useMostFrequentlyUsedCategoryData();
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();

    const setCollapse = useCallback(
        (collapsed: boolean) => {
            mostFrequentlyUsedCategoryData.current &&
                cannedMessagesNavigationStore.collapse(mostFrequentlyUsedCategoryData.current.id, collapsed);
        },
        [mostFrequentlyUsedCategoryData.current, cannedMessagesNavigationStore]
    );

    const onSelectMessage = useCallback(
        (categoryId: string, messageId: string, message: CannedMessage) => {
            mostFrequentlyUsedCategoryData.current &&
                onMessageSelection(mostFrequentlyUsedCategoryData.current.title, categoryId, messageId, message);
        },
        [onMessageSelection, mostFrequentlyUsedCategoryData.current]
    );

    useEffect(() => {
        setCollapse(!cannedMessagesNavigationStore.searchedText);
    }, [setCollapse, cannedMessagesNavigationStore.searchedText]);

    if (!mostFrequentlyUsedCategoryData.current) {
        return null;
    }
    const {icon, title, id, color} = mostFrequentlyUsedCategoryData.current;
    const isCollapsed = cannedMessagesNavigationStore.collapsedCategories[id];

    return (
        <Container data-test-id={`CannedMessages_Most_Frequently_Used`}>
            <CategoryHeader
                icon={icon}
                id={id}
                isCollapsed={isCollapsed}
                setCollapse={setCollapse}
                title={title}
                showOnlyCaret={true}
                removeFocus={cannedMessagesNavigationStore.removeFocus}
            />
            {!isCollapsed ? (
                <MessagesContainer>
                    {messages.map(message => (
                        <Message
                            isFocused={cannedMessagesNavigationStore.isFocused({
                                messageId: message.messageId,
                                categoryId: id,
                            })}
                            key={message.messageId}
                            id={message.messageId}
                            categoryId={message.categoryId}
                            categoryName={title}
                            message={message}
                            categoryColor={color}
                            onSelectMessage={onSelectMessage}
                            removeFocus={cannedMessagesNavigationStore.removeFocus}
                            searchedText={cannedMessagesNavigationStore.searchedText}
                        />
                    ))}
                </MessagesContainer>
            ) : null}
        </Container>
    );
});

export default MostFrequentUsedCategory;
