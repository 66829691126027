import React from 'react';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import CopyProductLinkLabel from './CopyProductLink';
import SendProductLinkButton from './SendProductLink';
import {useHorizonEnrollmentClickToSend, useHorizonEnrollmentProductButton} from '../../../../hooks';

interface ProductLinkProps {
    selectedSession: SupportItem;
}

const ProductLink = ({selectedSession}: ProductLinkProps) => {
    const isHorizonEnrollmentClickToSendEnabled = useHorizonEnrollmentClickToSend();
    const isHorizonEnrollmentProductButtonEnabled = useHorizonEnrollmentProductButton();

    if (isHorizonEnrollmentClickToSendEnabled) {
        if (isHorizonEnrollmentProductButtonEnabled) {
            return <SendProductLinkButton selectedSession={selectedSession} />;
        }
        return null;
    }

    return <CopyProductLinkLabel />;
};

export default ProductLink;
