import React from 'react';
import RichInput from './RichInput';
import styled from 'styled-components';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {getBoxShadowStyle, getBorderStyle} from '@anywhere-expert/base-ui';
import {useTweekValue} from 'react-tweek';
import {observer} from 'mobx-react';

const StyledInput = styled(RichInput)`
    z-index: 1;
    min-height: 44px;
    ${({theme}) => getBoxShadowStyle(theme)};
    ${({theme}) => getBorderStyle(theme)};
`;

type Props = {
    isAssignedToMe: boolean;
    editable: boolean;
    selectedSession: SupportItem;
};

const InputSection = observer(({editable = true, selectedSession}: Props) => {
    const customerSupportImageDisplay = useTweekValue<boolean>(
        'support/session/messaging/rich_messaging/image_display/is_enabled',
        false
    );

    return (
        <StyledInput
            editable={editable}
            disableImageUpload={!selectedSession.isAssignedToMe || !customerSupportImageDisplay}
            selectedSession={selectedSession}
        />
    );
});

export default InputSection;
