import {userProfile$} from '@anywhere-expert/auth';
import {UserStorage} from './types';
import {UserStorageApi, Configuration} from '@soluto-private/anywhere-expert-user-storage-client';
import {configuration} from '@anywhere-expert/core';
import {authorizedFetch} from '@anywhere-expert/auth';

const storageApi = new UserStorageApi(
    new Configuration({basePath: configuration.userStorageUrl, fetchApi: authorizedFetch})
);

const getUserId = () => userProfile$ && userProfile$.value && userProfile$.value.uid;

export const getStorage = (): Promise<UserStorage> =>
    (storageApi.getUserStorage(getUserId()) as unknown) as Promise<UserStorage>;

export const saveToRemoteStorage = async (key: string, value: any) => {
    return storageApi.upsertUserStorage({
        expertId: getUserId(),
        [key]: value,
    });
};
