import {Context} from 'tweek-client';
import {AttributesByType} from '@soluto-private/session-api-types';
import {getFromTweekForContext} from './tweekContext';
import {customIdToContextConfig} from './tweekClientWithCustomer';
import logger from '@anywhere-expert/logging';

const mandatoryExpertise = (sessionAttributes?: AttributesByType) =>
    sessionAttributes?.expertise
        ? (Object.keys(sessionAttributes.expertise).filter(x => sessionAttributes.expertise[x].isMandatory) as any)
        : [];

const extractSdkAppKeyFromAttributes = (attributes: AttributesByType): string | undefined => {
    const attribute = attributes?.metadata?.source;
    if (!attribute?.fields || attribute.fields.name !== 'AE_SDK') return;

    return attribute.fields.appKey;
};

export const createSupportItemTweekContext = (
    sessionId: string,
    pool: string,
    sessionAttributes?: AttributesByType
): Context => ({
    support_item: {
        id: sessionId,
        pool,
        mandatory_expertise_array: mandatoryExpertise(sessionAttributes),
        source: sessionAttributes?.metadata?.source?.fields?.name,
    },
    sdk_app: {
        id: extractSdkAppKeyFromAttributes(sessionAttributes!) ?? '',
    },
});

export const getFromTweekForSupportItem = async <T>(
    key: string,
    id: string,
    sessionId: string,
    pool: string,
    sessionAttributes?: AttributesByType
): Promise<T | undefined> =>
    getFromTweekForContext<T>(
        customIdToContextConfig(id, createSupportItemTweekContext(sessionId, pool, sessionAttributes)),
        key
    );

export const getFromTweekForSupportItemWithDefault = async <T>(
    key: string,
    id: string,
    sessionId: string,
    pool: string,
    defaultValue: T,
    sessionAttributes?: AttributesByType
): Promise<T> => {
    try {
        return (await getFromTweekForSupportItem(key, id, sessionId, pool, sessionAttributes)) ?? defaultValue;
    } catch (err) {
        logger.error('failed getFromTweekForSupportItemWithDefault', {
            err,
            extra: {key, defaultValue, timelineId: id, sessionId},
        });
        return defaultValue;
    }
};
