import {action, observable, computed, makeObservable} from 'mobx';
import {isNeglectionWarning, wasUnassignedByNeglection, getNeglectionPrevExpertName} from '../utils/sessionType';
import {getNameFromAttributes} from '../utils/transformers';
import {SupportItemDetails} from '../types';
import {ExpertFeedStore} from '../ExpertFeedStore';
import QueueItemSnoozeModel from './QueueItemSnoozeModel';
import {QueueItemAttribute, QueueItemWithoutAttributes} from '@soluto-private/expert-queue-api-types';
import {AttributesByType, SessionStatus, SessionType} from '@soluto-private/session-api-types';
import {transformSupportItem} from '../utils/transformers';
import SupportItem from './SupportItem';
import TaskItem from './TaskItem';
import {Tag} from '@expert-feed/conversation-tags';
import {extractTags} from '@expert-feed/conversation-tags';
export const isSupport = (item: QueueItem): item is SupportItem => item.isSupport();
export const isTask = (item: QueueItem): item is TaskItem => item.isTask();

export default abstract class QueueItem {
    store: ExpertFeedStore;
    id: string;
    type: SessionType;
    sessionId: string;
    creationTime: Date;
    pool: string;
    snooze: QueueItemSnoozeModel;

    // In case the item was updated locally but did not receive an update from the server
    isStale: boolean = false;

    assignedExperts: string[] = [];
    feedItemAttributes: QueueItemAttribute[] = [];
    sessionAttributes: AttributesByType = {};
    supportItemDetails: SupportItemDetails;
    rank: number;
    isSkipFailedAssign: boolean;

    get isAssigned() {
        return this.supportItemDetails.status === SessionStatus.Assigned && this.assignedExperts.length > 0;
    }

    get isAssignedToMe() {
        return this.isAssigned && this.assignedExperts.includes(this.store.user.uid);
    }

    isTask(): this is TaskItem {
        return false;
    }

    isSupport(): this is SupportItem {
        return false;
    }

    get queueNaming() {
        return getNameFromAttributes(this.feedItemAttributes, this.sessionAttributes);
    }

    get isNeglected() {
        return isNeglectionWarning(this.sessionAttributes);
    }

    get wasUnassignedByNeglection() {
        return wasUnassignedByNeglection(this.sessionAttributes);
    }

    get prevExpertName() {
        return getNeglectionPrevExpertName(this.sessionAttributes);
    }

    get inFeed() {
        return this.store.sessionsInFeed.has(this.sessionId);
    }

    get mandatoryExpertise(): string[] {
        return this.sessionAttributes.expertise
            ? Object.entries(this.sessionAttributes.expertise)
                  .filter(([, {isMandatory}]) => isMandatory)
                  .map(([name]) => name)
            : [];
    }

    /*
        If you want to add a new tag type, you have to configure correctly in tweek to make it visible
        and in the right order. Otherwise your tag will not be visible.
        support/feed/support_item/tags contains all the tag configuration.
    */
    get tags(): Tag[] {
        return this.prepareTags();
    }

    get sessionSource(): string | undefined {
        return this.sessionAttributes?.metadata?.source?.fields?.name;
    }

    get sessionSourceVersion(): string | undefined {
        return this.sessionAttributes?.metadata?.source?.fields?.appVersion;
    }

    protected prepareTags(): Tag[] {
        return extractTags(this.supportItemDetails.attributes);
    }

    constructor(store: ExpertFeedStore, item: QueueItemWithoutAttributes, attributes: QueueItemAttribute[] = []) {
        makeObservable(this, {
            isStale: observable,
            assignedExperts: observable.ref,
            feedItemAttributes: observable.ref,
            sessionAttributes: observable.ref,
            supportItemDetails: observable.ref,
            rank: observable,
            isSkipFailedAssign: observable,
            isAssigned: computed,
            isAssignedToMe: computed,
            queueNaming: computed,
            isNeglected: computed,
            wasUnassignedByNeglection: computed,
            prevExpertName: computed,
            inFeed: computed,
            mandatoryExpertise: computed,
            tags: computed,
            sessionSource: computed,
            sessionSourceVersion: computed,
            upsert: action,
            updateSessionAttributes: action,
            updateFeedItemAttributes: action,
            setIsStale: action,
            setIsSkipFailedAssign: action,
        });

        this.store = store;
        this.id = item.customerId; // TODO: should be session id? maybe remove?
        this.sessionId = item.sessionId;
        this.pool = item.pool;
        this.type = item.type;
        this.creationTime = new Date(item.creationTime);

        this.upsert(item);
        this.updateFeedItemAttributes(attributes);

        this.snooze = new QueueItemSnoozeModel(this);
    }

    upsert = (item: QueueItemWithoutAttributes) => {
        this.sessionAttributes = item.sessionAttributes || {};
        this.rank = item.rank || Number.MIN_VALUE;
        this.assignedExperts = item.assignedExperts || [];
        this.setIsStale(false);

        this.supportItemDetails = transformSupportItem(item);
    };

    updateSessionAttributes = (sessionAttributes: AttributesByType) => {
        this.sessionAttributes = sessionAttributes || {};
    };

    updateFeedItemAttributes = (attributes: QueueItemAttribute[] = []) => {
        this.feedItemAttributes = attributes;
    };

    setIsStale = (isStale: boolean) => (this.isStale = isStale);
    setIsSkipFailedAssign = (isSkipFailedAssign: boolean) => (this.isSkipFailedAssign = isSkipFailedAssign);

    dtor() {
        this.setIsStale(false);
        this.snooze && this.snooze.dtor();
    }
}
