import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {DeviceSupportInfo, ScreenshareSessionAttribute} from './sharedScreenshareDefinitions';

export default function extractDeviceScreenshareSupportInfoFromSession(session: SupportItem): DeviceSupportInfo {
    const screenshareAttribute = session?.sessionAttributes?.screenshare;

    if (!screenshareAttribute) {
        return {supportLevel: 'not-supported'};
    }

    const castedScreenshareAttribute = (screenshareAttribute as unknown) as ScreenshareSessionAttribute;

    const {accessibilityEnabled, supportLevel} = castedScreenshareAttribute;

    return {
        accessibilityEnabled: accessibilityEnabled.fields.value,
        supportLevel: supportLevel.fields.value,
    };
}
