import React from 'react';

const SvgComponent = ({color}) => (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" fill={color || '#4C5764'}>
        <g id="Icons/16/alert-triangle-fill" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M14.7210487,11.0334737 L9.83856733,3.00042105 C9.45736213,2.37389474 8.77004724,2 8,2 C7.22995276,2 6.54263787,2.37389474 6.16143267,3.00042105 L1.27895135,11.0334737 C0.917474631,11.6290526 0.906655785,12.3452632 1.25031323,12.9490526 C1.61879038,13.5976842 2.33474339,14 3.11751868,14 L12.8824813,14 C13.6652566,14 14.3812096,13.5976842 14.7496868,12.9490526 C15.0933442,12.3452632 15.0825254,11.6290526 14.7210487,11.0334737 L14.7210487,11.0334737 Z M8,5 L8,5 C8.55228475,5 9,5.44771525 9,6 L9,8 C9,8.55228475 8.55228475,9 8,9 L8,9 C7.44771525,9 7,8.55228475 7,8 L7,6 C7,5.44771525 7.44771525,5 8,5 Z M8,12 C7.448,12 7,11.552 7,11 C7,10.448 7.448,10 8,10 C8.552,10 9,10.448 9,11 C9,11.552 8.552,12 8,12 L8,12 Z"
                id="Mask"
            />
        </g>
    </svg>
);

export default SvgComponent;
