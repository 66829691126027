import logger from '@anywhere-expert/logging';
import RoomHandlers from './RoomHandlers';
import videoStore from './store';
import {Participant, TrackPublication} from 'twilio-video';

enum NetworkStatusBars {
    '▃' = 1,
    '▃▄' = 2,
    '▃▄▅' = 3,
    '▃▄▅▆' = 4,
    '▃▄▅▆▇' = 5,
}

const trackPublication = (participant: Participant, publication: TrackPublication, roomHandlers: RoomHandlers) => {
    publication.on('subscribed', function (track) {
        logger.info(`${participant.identity} added a track: ${track.kind}`);
        videoStore.addExternalTracks([track]);
        roomHandlers.trackAdded && roomHandlers.trackAdded(track, participant);
    });

    // When a Participant removes a Track, detach it from the DOM.
    publication.on('unsubscribed', function (track) {
        logger.info(`${participant.identity} removed a track: ${track.kind}`);
        videoStore.removeExternalTrack(track);
        roomHandlers.trackRemoved && roomHandlers.trackRemoved(track, participant);
    });
};

export const registerParticipantNetworkStatus = (participant: Participant) => {
    const printNetworkQualityStats = (networkQualityLevel, networkQualityStats) => {
        // Print in console the networkQualityLevel using bars
        logger.info('participant Network Status', {
            extra: {
                quality: NetworkStatusBars[networkQualityLevel],
                participant,
                networkQualityStats,
            },
        });
    };

    printNetworkQualityStats(participant.networkQualityLevel, participant.networkQualityStats);
    participant.on('networkQualityLevelChanged', printNetworkQualityStats);
};

export default (participant: Participant, roomHandlers: RoomHandlers) => {
    logger.info(`Participant connected the queue: ${participant.identity}`);

    roomHandlers.participantConnected && roomHandlers.participantConnected(participant);
    registerParticipantNetworkStatus(participant);
    participant.tracks.forEach(publication => trackPublication(participant, publication, roomHandlers));
    participant.on('trackPublished', publication => trackPublication(participant, publication, roomHandlers));
    participant.on('trackUnpublished', publication =>
        logger.info(`RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`)
    );
    participant.on('trackDisabled', ({track}) => {
        logger.info(`${participant.identity} removed a track: ${track.kind}`);
        videoStore.removeExternalTrack(track);
        roomHandlers.trackRemoved && roomHandlers.trackRemoved(track, participant);
    });

    participant.on('trackEnabled', ({track}) => {
        logger.info(`${participant.identity} added a track: ${track.kind}`);
        videoStore.addExternalTracks([track]);
        roomHandlers.trackAdded && roomHandlers.trackAdded(track, participant);
    });
};
