import performance from '../performance';
import {compose, withProps, pure, setDisplayName} from 'recompose';
import {withTweekKeys} from '@anywhere-expert/tweek';
import omitProps from '@hocs/omit-props';
import {extractMetricTagFromTweek, generatePathName, writeManualMetric, writeCustomMetric} from './utils';
import {TweekMetricTag, PerformanceType} from './types';

type OutterProps = {
    performance: PerformanceType;
};

const withPerformance = compose<OutterProps, any>(
    withTweekKeys({metricTags: 'support/performance/metrics/stories/_'}),
    withProps<{performance: PerformanceType}, {metricTags: TweekMetricTag}>(({metricTags}) => ({
        performance: {
            start: ({tag, identifier}) => {
                performance.start(generatePathName(tag.name, identifier));
            },
            end: ({tag, identifier, extraData}) => {
                const metric = extractMetricTagFromTweek(metricTags, tag.name);
                const threshold = metric?.threshold ?? tag.thresholdInMs;
                writeManualMetric(tag, threshold, identifier, extraData);
            },
            mark: ({tag, identifier, value}) => {
                const metric = extractMetricTagFromTweek(metricTags, tag.name);
                const threshold = metric?.threshold ?? tag.thresholdInMs;
                writeCustomMetric(tag, threshold, value, identifier);
            },
        },
    })),
    omitProps('metricTags'),
    setDisplayName('withPerformance'),
    pure
);

export default withPerformance;
