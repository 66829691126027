import axios from 'axios';
import {CustomerPlatform} from '@anywhere-expert/expert-feed-store';
import logger from '@anywhere-expert/logging';
import mdnCache from './mdnCache';
import {getAuthHeaders} from '@anywhere-expert/auth';

const getCustomerMdnData = async (
    baseUrl: string,
    customerId: string,
    partner: string,
    customerPlatform: CustomerPlatform
) => {
    const {
        data: mdn,
    } = await axios.get(
        `${baseUrl}/api/v1/customer/${customerId}/mdn?partner=${partner}&customerType=${customerPlatform}`,
        {headers: {...getAuthHeaders()}}
    );
    return mdn;
};

const getCustomerMdn = async (
    customerDetailsBaseUrl: string,
    customerId: string,
    partner: string,
    customerPlatform: CustomerPlatform = 'device'
): Promise<string> => {
    const cachedCustomerMdn = mdnCache.get(customerId);
    if (cachedCustomerMdn) {
        return cachedCustomerMdn;
    }
    try {
        const customerPartner = partner === 'verizon-qa' ? 'verizon' : partner; //temporary hack for my-vzw demo

        const customerMdn = await getCustomerMdnData(
            customerDetailsBaseUrl,
            customerId,
            customerPartner,
            customerPlatform
        );
        mdnCache.set(customerId, customerMdn);

        return customerMdn;
    } catch (err) {
        if (err?.response?.status !== 404) {
            logger.warn('Failed to fetch customer mdn', {err, extra: {customerPlatform, partner, customerId}});
        }

        return '';
    }
};

export default getCustomerMdn;
