const homeGrantTokenRequestSettings = {
    grant_type: 'home-token',
    scope: 'expert-resource expert-identity',
    client_id: 'tech-app-android-home-grant',
    client_secret: '837f363e-3f7a-44bf-a833-2cc8cc78be57',
};

export const getFirebaseTokenFromHomeToken = async access_token => {
    const params = {...homeGrantTokenRequestSettings, token: access_token};
    return await fetchToken(process.env.AUTH_API_BASE_URL + '/openid/connect/token', params);
};

const fetchToken = async (url, params) => {
    const body = Object.keys(params)
        .map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        })
        .join('&');

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
    });

    return response.json();
};
