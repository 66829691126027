import styled from 'styled-components';
import React, {useCallback} from 'react';
import {CoreText, getBoxShadowStyle, CoreButton, CoreIconButton, CoreTextArea} from '@anywhere-expert/base-ui';
import {CloseIcon} from '@anywhere-expert/icons';
import {useEventListener} from '@anywhere-expert/utils';
import {observer} from 'mobx-react';
import {RichInputStore} from '../stores/RichInputStore';

const Container = styled.div`
    display: flex;
    width: 356px;
    background: ${({theme}) => theme.colors.neutral.white};
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    ${({theme}) => getBoxShadowStyle(theme)};
`;

const SaveContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;
`;

const ErrorDiv = styled.div`
    align-self: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 4px;
`;
const ErrorText = styled(CoreText)`
    align-self: flex-start;
`;

const SaveButton = styled(CoreButton)`
    justify-content: center;
    align-content: center;
    align-self: flex-end;
    width: 40px;
`;

const InputText = styled(CoreTextArea)`
    border-radius: 8px;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.caption}`};
    height: 42px;
    overflow: unset !important;
`;

const CloseButton = styled(CoreIconButton)`
    position: absolute;
    top: 4px;
    right: 4px;
`;

const htmlRegex = /http[s]?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const useKeyboardSaving = onSave => {
    const handleKeyPress = React.useCallback(
        event => {
            if (event.key === 'Enter') {
                onSave();
                event.preventDefault();
            }
        },
        [onSave]
    );
    useEventListener('keydown', handleKeyPress);
};
type LinkMenuProps = {
    setShowAddLink: any;
    richInputStore: RichInputStore;
};
const AddLinkTooltip = observer(({setShowAddLink, richInputStore}: LinkMenuProps) => {
    const selectedTextForInput = richInputStore.selectedText;
    const [textInput, setTextInput] = React.useState<string>(selectedTextForInput ?? '');
    const [linkInput, setLinkInput] = React.useState<string>('');
    const [errMessage, setErrMessage] = React.useState<string>('');

    const validUrl = React.useMemo(() => linkInput?.toLowerCase().match(htmlRegex), [linkInput]);
    const doesTitleExist = React.useMemo(() => !!selectedTextForInput && selectedTextForInput != '', [
        selectedTextForInput,
    ]);

    const closeLinkMenu = useCallback(() => {
        setShowAddLink(false);
    }, [setShowAddLink]);

    const onSave = useCallback(() => {
        if (!textInput || !linkInput) {
            setErrMessage('One of the fields seems to be empty');
            return;
        } else if (!validUrl) {
            setErrMessage('Make sure the URL starts with “http://”');
            return;
        }
        const additionalOffset = textInput.length - selectedTextForInput?.length;
        richInputStore.addLinkEntityState(textInput, linkInput, additionalOffset);
        closeLinkMenu();
    }, [validUrl, closeLinkMenu, textInput, linkInput, setErrMessage, selectedTextForInput]);

    useKeyboardSaving(onSave);

    return (
        <Container>
            <CloseButton size="s" onClick={closeLinkMenu} variant="text" color="default" icon={CloseIcon} />
            <CoreText size="s" weight="bold">
                Add link
            </CoreText>
            <InputText
                value={textInput}
                onChange={e => setTextInput(e.target.value)}
                autoFocus={!doesTitleExist}
                placeholder="Text"
            />
            <InputText
                value={linkInput}
                onChange={e => setLinkInput(e.target.value)}
                autoFocus={doesTitleExist}
                placeholder="Insert URL"
            />
            <SaveContainer>
                <ErrorDiv>
                    <ErrorText size="s" textType="error">
                        {errMessage}
                    </ErrorText>
                </ErrorDiv>
                <SaveButton variant="outlined" disabled={!validUrl} text="Save" size="s" onClick={onSave} />
            </SaveContainer>
        </Container>
    );
});

export default AddLinkTooltip;
