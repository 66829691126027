import {monitor} from '@anywhere-expert/monitor';
import logger from '@anywhere-expert/logging';
import {MeasurementTag} from './behaviours/types';

export const writeMetrics = (metricName: string, duration: number): void => {
    monitor.addTime(metricName, duration);
};

export const writeThresholdLog = (tag: MeasurementTag, identifier?: string, extraData?: any) => {
    logger.warn(`Performance mark exceeded threshold`, {
        extra: {
            story: tag.name,
            threshold: tag.thresholdInMs,
            identifier,
            //@ts-ignore
            memory: window.performance && window.performance.memory,
            ...extraData,
        },
    });
    monitor.increment(tag.name);
};
