import {observable, action, computed, makeObservable, runInAction} from 'mobx';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {TimelineController} from '@anywhere-expert/timeline-state';
import {Message} from '@soluto-private/messaging-api-client';
import {createTransformer} from 'mobx-utils';

export default class TimelineSearchStore {
    focusedIndex: number = -2;
    messages?: Message[] = [];
    searchTerm: string = '';
    isLoading: boolean = false;
    parent: SupportItem;

    constructor(parent: SupportItem) {
        makeObservable(this, {
            messages: observable,
            searchTerm: observable,
            focusedIndex: observable,
            isLoading: observable,
            setFocus: action,
            clearSearch: action,
            setSearchTerm: action,
            search: action,
            isFocused: computed,
            keydownPressed: action,
            keyupPressed: action,
            reset: action,
        });
        this.parent = parent;
    }

    setSearchTerm = (term: string) => {
        this.isLoading = true;
        this.searchTerm = term;
    };

    async search() {
        this.isLoading = true;
        this.messages = [];
        let term = this.searchTerm;
        term = term.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const matchesFilter = new RegExp(term, 'i');
        const filterTimelineMessages = () => {
            const messages = this.parent.timelineModel.messages;
            return messages?.filter(
                x =>
                    x.payload.type === 'text_message' &&
                    typeof x.payload.value.text === 'string' &&
                    matchesFilter.test(x.payload.value.text)
            );
        };

        setTimeout(() => {
            const results = term ? filterTimelineMessages() : [];

            runInAction(() => {
                this.isLoading = false;
                this.messages = results!.reverse();
            });
        }, 0);
    }

    clearSearch() {
        this.messages = [];
        this.searchTerm = '';
    }

    keydownPressed() {
        if (this.focusedIndex === -2) return;
        this.focusedIndex = Math.min(this.focusedIndex + 1, this.messages!.length - 1);
        TimelineController.scrollToMessage(this.messages![this.focusedIndex].messageId);
    }

    keyupPressed() {
        if (this.focusedIndex === -2) return;
        this.focusedIndex = Math.max(this.focusedIndex - 1, -1);
        this.messages && TimelineController.scrollToMessage(this.messages[this.focusedIndex].messageId);
    }

    setFocus(id) {
        const currentMessage = this.messages?.find(message => message.messageId === id);
        const position = currentMessage && this.messages?.indexOf(currentMessage);
        this.focusedIndex = position ?? -1;
    }

    reset() {
        if (this.focusedIndex !== -1) this.focusedIndex = -1;
    }

    get isFocused() {
        return createTransformer(({messageId}) => {
            const message = this.messages![this.focusedIndex];
            return this.focusedIndex > -1 && message.messageId === messageId;
        });
    }
}
