import React from 'react';

export default ({className}: {className?: string}) => (
    <svg
        className={className}
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M15.0928 7.99573C15.0928 6.57573 16.2438 5.42773 17.6668 5.42773H56.6998C58.1218 5.42773 59.2718 6.57573 59.2718 7.99573C59.2718 11.6047 59.2718 18.0507 59.2718 20.8647C59.2718 26.3487 59.2718 31.6427 59.2718 34.6397C59.2718 36.0377 58.1448 37.1827 56.7448 37.2097C39.1118 37.5667 32.6088 37.5687 17.6098 37.2197C16.2138 37.1867 15.0918 36.0437 15.0918 34.6497C15.0918 31.2537 15.0918 25.1327 15.0918 20.8647C15.0928 16.7107 15.0928 11.1837 15.0928 7.99573Z"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.1797 32.4434C27.1587 32.4434 46.6637 32.6834 58.8357 32.4434"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.6321 38.5373C34.5811 46.3923 34.5091 53.2523 34.5091 53.2523C34.5091 56.0513 32.1141 57.7583 29.5361 58.0273C26.9581 58.2983 15.5381 58.2243 11.7961 58.0273C9.16313 57.8883 7.44213 55.0313 7.44213 52.6273C7.07113 37.7543 7.32513 18.1093 7.44213 15.2563C7.55813 12.4023 9.39213 10.9023 11.7961 10.9023H14.2781"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.4023 13.9551H25.0273"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.7272 37.8715C31.7062 45.6235 31.7212 48.3065 31.7272 51.7835C31.7312 54.2775 30.6232 54.9315 28.5502 55.1745C26.4792 55.4145 16.8992 55.5965 13.8912 55.4205C11.7782 55.2955 10.3102 53.6335 10.3102 51.4865C10.0132 38.2265 10.0822 19.5275 10.1772 16.9825C10.2712 14.4375 11.7422 13.7285 13.6752 13.7285H14.4032"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.6719 34.5321V31.7031"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M21.7148 10.9023L26.6258 10.9023Z" fill="url(#pattern0)" />
        <path
            d="M21.7148 10.9023L26.6258 10.9023"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M13.9688 10.9023L18.8797 10.9023Z" fill="url(#pattern1)" />
        <path
            d="M13.9688 10.9023L18.8797 10.9023"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29.3867 10.9023H31.1167C33.0497 10.9023 34.5207 11.6113 34.6147 14.1563C34.6357 14.7513 34.6567 14.2273 34.6717 16.3103"
            fill="url(#pattern2)"
        />
        <path
            d="M29.3867 10.9023H31.1167C33.0497 10.9023 34.5207 11.6113 34.6147 14.1563C34.6357 14.7513 34.6567 14.2273 34.6717 16.3103"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.60742 54.1502C7.60742 55.6212 9.36542 58.3692 12.4954 58.9352C19.3074 59.3632 23.0794 59.3452 29.7234 58.9352C32.0974 58.9352 34.6744 56.5332 34.6744 54.1502V38.2032V37.5312"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.50678 29.477C6.44378 26.444 6.47978 24.793 6.55878 21.875"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.7109 52.6895C20.2889 52.8595 21.7189 52.8775 24.2479 52.8325"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.4023 42.0312H26.4023"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.4023 46.0312H26.4023"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M36.5741 16.457C31.2691 16.457 27.9731 19.484 26.8691 22.607C28.0771 24.961 31.0031 28.455 36.5741 28.455C42.1401 28.455 44.8311 25.502 46.0671 22.607C45.0031 19.765 41.8741 16.457 36.5741 16.457Z"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="6.9818"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M36.5584 25.8731C38.4434 25.8731 39.9694 24.3441 39.9694 22.4571C39.9694 20.5681 38.4434 19.0391 36.5584 19.0391C34.6744 19.0391 33.1484 20.5681 33.1484 22.4571C33.1484 24.3441 34.6744 25.8731 36.5584 25.8731Z"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="6.9818"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_3016_118517" transform="matrix(0.0166667 0 0 0 0 0.5)" />
            </pattern>
            <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_3016_118517" transform="matrix(0.0166667 0 0 0 0 0.5)" />
            </pattern>
            <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image0_3016_118517" transform="translate(0 -0.31438) scale(0.0166667 0.0162876)" />
            </pattern>
            <image
                id="image0_3016_118517"
                width="60"
                height="100"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABkCAYAAADaIVPoAAACwElEQVR4Ae2cgTXEQBCGRwd0QAd0QAdUgArQgQ7ogArogA7ogA7oAO+7l703N5fLReTes39m3lvJ5m5z+eeb2WwkWbN+tm1m+2Z2ZmYXZnbdUo777ep/fwuRt2b2aWbfPQqOqNZuegiMTnivVe3dALGIp111toosYY0gQrwth8nt6uywhSxhynZJew6CEbsrqbQRFjshWbFAvAx0oS1tj0EwDpC2mL+yHVWhODnB9Mi+05LusKCcgkusqy6TsCrZoite83LRL22+h2Zd3lKwOuIknITFPJAhLQZ0QQ6DDE+YQYi0cSnoBTOulrYULI235V+0GdJqxDOH1YhGPUk4ekStnoTViEY9STh6RK2ehNWIRj1JOHpErZ6E1YhGPUk4ekStnoTViEY9STh6RK2ehNWIRj1JOHpEoA7UuSkT3nOPVb4VxcqCT8Kd0ZlmZcHnKVj87uHkCF9NLaR5i84/ziHfaaVgECuflpJwEm7GnrPeTOBPhvQkQxrR/uTMuoq1hjTiUrAI4iRcQGZIF09UvpxUSG+Z2b3rkL88PMWQ7hSs+P5wCvYhnYS9Nypdz5D24CYX0q/ufMUpivnwarfOkFacmqZTcJxtiSkdazcEe10LIy3muvOjLeq1G4J95C4IjvPhKbxSy81wD3FBMO8Qx8lLjipHHK+U5k8AFF0xrDlV7ZQPK1u20V2aBS7eciEcmMuSXKjJDtxzHSWkoYu+JWubyJOeDo/9d+GkZQxjBJO7p0tKmw00egnJTiPCm5Anr/EgDqAQ8jiib1n1u7/dDi0KAyRmTQVK7IMKXZzQaeyIECgNhiz5cZw0VlklZt2xrRVbPDGG6HUHs8nPATZo5kY8RGPyYJMHONa+uSbgmEnNwUZjhpoPZvbU5DhOoHw0zsAhfcpYwkrKIJAzCXk8iOhgr4SGOKl0LGMs/0SsHNsPcwftd1g9aCQAAAAASUVORK5CYII="
            />
        </defs>
    </svg>
);
