import {ExpertInteraction} from '../types';

class InteractionTracker {
    private static instance: InteractionTracker;
    private history: Map<string, Partial<ExpertInteraction>[]>;

    private constructor() {
        this.history = new Map<string, Partial<ExpertInteraction>[]>();
    }

    public static getInstance() {
        if (!InteractionTracker.instance) {
            InteractionTracker.instance = new InteractionTracker();
        }

        return InteractionTracker.instance;
    }

    public reset() {
        this.history.clear();
    }

    public clearInteractions(interactionId: string) {
        if (interactionId) this.history.set(interactionId, []);
    }

    public push(interactionId: string, interaction: Partial<ExpertInteraction>) {
        const interactions = this.history.get(interactionId) || [];
        if (interaction) {
            interactions.push(interaction);
            this.history.set(interactionId, interactions);
        }
    }

    public reduce(interactionId: string): Partial<ExpertInteraction> {
        const interactions = this.history.get(interactionId) || [];
        const result = interactions.reduce((merged, current) => {
            const pinned_accepted = merged.accepted === true || current.accepted === true;
            return {...merged, ...current, ...{accepted: pinned_accepted}};
        }, {});
        return result;
    }
}

export default InteractionTracker;
