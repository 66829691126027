import React, {useCallback, useEffect} from 'react';
import {TabProps, SidebarTabContainer} from '@anywhere-expert/session-sidebar';
import Articles from './articles/Articles';
import {observer} from 'mobx-react';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import HelixSearchInput from './SearchInput';
import Spinner from './Spinner';
import NoResults from './NoResults';
import InitialState from './InitialState';
import ArticlesForDevicePreview from './articles/ArticlesForDevicePreview';
import {useAnalytics} from 'react-shisell';
import {useTweekValue} from 'react-tweek';

interface HelixSearchTabContainerType extends React.FunctionComponent<TabProps> {
    getDisabledText?: any;
}

const HelixSearch: HelixSearchTabContainerType = observer(({selectedSession}: {selectedSession: SupportItem}) => {
    const carrier = useTweekValue(
        'support/session/messaging/tools/helix_search/carrier_name',
        selectedSession.supportItemDetails.partner
    );

    const helixStore = selectedSession.helixStore;
    const searchTerm = helixStore.searchTerm;

    const {dispatcher} = useAnalytics();

    useEffect(() => {
        helixStore.init(carrier);
    }, [helixStore, carrier]);

    const {articles, articlesPreview, fetchArticles, inProgress, deviceModel} = helixStore;

    useEffect(() => {
        if (!deviceModel) return;
        fetchArticles(deviceModel!, true).then(() => {
            dispatcher
                .withExtras({ActionId: 'Search', SearchTerms: deviceModel, Source: 'initial_state'})
                .dispatch('Click');
        });
    }, [deviceModel, helixStore]);

    const getArticlesCallback = useCallback(
        async (value: string, isAutocompleteUsed: boolean) => {
            const searchSource = isAutocompleteUsed ? 'search_autocomplete' : 'search_enter';
            await fetchArticles(value, false);
            dispatcher.withExtras({ActionId: 'Search', SearchTerms: value, Source: searchSource}).dispatch('Click');
        },
        [fetchArticles, dispatcher]
    );
    const shouldShowArticlesForDevice = !articles && !!articlesPreview && articlesPreview.length > 0;
    const shouldShowInitialState = !articles && (!articlesPreview || articlesPreview.length === 0);
    const SearchInput = (
        <HelixSearchInput
            placeholder="Search Helix..."
            searchTerm={searchTerm}
            setSearchTerm={helixStore.setSearchTerm}
            onSearch={getArticlesCallback}
        />
    );
    return (
        <SidebarTabContainer
            title="Search Helix"
            subTitle={SearchInput}
            data-test-id="helix-search"
            addChildrenSpace={false}
        >
            {(() => {
                if (inProgress) return <Spinner />;
                if (articles && articles.length !== 0)
                    return <Articles articles={articles} isUserSearch={true} searchTerms={searchTerm} />;
                if (shouldShowArticlesForDevice)
                    return (
                        <ArticlesForDevicePreview
                            deviceModel={deviceModel}
                            articlesPreview={articlesPreview!}
                            searchTerm={searchTerm}
                        />
                    );
                if (shouldShowInitialState) return <InitialState />;
                return <NoResults />;
            })()}
        </SidebarTabContainer>
    );
});

export default HelixSearch;
