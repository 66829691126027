import * as React from 'react';
import {ReactProgressiveList} from '@anywhere-expert/utils';
import SendableMessage from './SalesMessage';
import SendableOfferMessage from './OfferMessage';
import {SalesMessageListProps} from '../../../../types';
import styled from 'styled-components';
import {getScrollerStyle} from '@anywhere-expert/base-ui';

const Container = styled.div`
    overflow: auto;
    ${({theme}) => getScrollerStyle(theme)}
`;

const SalesMessageList = ({customerId, messages, dataTestId}: SalesMessageListProps) => {
    const renderRow = React.useCallback(
        (data, index) => {
            if (data[index].text) {
                return (
                    <SendableOfferMessage
                        key={`sendable-message-${index}`}
                        dataTestId={`MessagingSales_${dataTestId}-item-${index}`}
                        message={data[index]}
                        customerId={customerId}
                    />
                );
            }

            return (
                <SendableMessage
                    key={`sendable-message-${index}`}
                    dataTestId={`MessagingSales_${dataTestId}-item-${index}`}
                    message={data[index]}
                    customerId={customerId}
                />
            );
        },
        [customerId, messages]
    );

    return (
        <Container>
            <ReactProgressiveList
                initialAmount={Math.min(messages.length, 30)}
                data={messages}
                scrollToTopOnSizeChange={true}
                progressiveAmount={15}
                renderItem={renderRow}
                scrollBuffer={100}
            />
        </Container>
    );
};

export default SalesMessageList;
