import {create, ExpertQueueUpdate, ConnectionStatus} from '@soluto-private/live-queue-api-client';
import {
    ExpertQueuesApi,
    Configuration as ExpertQueuesApiConfiguration,
} from '@soluto-private/expert-queue-api-client-v1';
import {SessionApi, Configuration as SessionApiConfiguration} from '@soluto-private/session-api-client-v1';
import feedClientConfig from './feedClientConfig';
import {getAppMetadata} from '@anywhere-expert/utils';
import {Observable} from 'rxjs';
import {authorizedFetch, getHomeAccessToken$} from '@anywhere-expert/auth';

export const createClient = (): FeedClient => {
    const {sessionApiUrl, expertQueuesApiUrl, liveQueueApiUrl} = feedClientConfig;
    const {client, version} = getAppMetadata();

    const expertQueuesApi = new ExpertQueuesApi(
        new ExpertQueuesApiConfiguration({basePath: expertQueuesApiUrl, fetchApi: authorizedFetch})
    );
    const sessionApi = new SessionApi(
        new SessionApiConfiguration({basePath: sessionApiUrl, fetchApi: authorizedFetch})
    );

    const {queueUpdates, connectionStatus$} = create({
        url: liveQueueApiUrl,
        clientVersion: version,
        clientApi: client,
        accessTokenProvider: () => getHomeAccessToken$() as any,
    });

    return {
        expertQueuesApi,
        sessionApi,
        connectionStatus$: Observable.from(connectionStatus$),
        queueUpdates: queueUpdates as any,
    };
};

export type FeedClient = {
    expertQueuesApi: ExpertQueuesApi;
    sessionApi: SessionApi;
    connectionStatus$: Observable<ConnectionStatus>;
    queueUpdates: (expertId: string) => Observable<ExpertQueueUpdate>;
};
