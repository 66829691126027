import React, {useState, useCallback, memo, useMemo} from 'react';
import {HelixSuggestionItem} from './types';
import AutoSuggest from 'react-autosuggest';
import AutoSuggestContainer from './AutoSuggestContainer';
import fetchSuggestions from './api/fetchSuggestions';
import {CoreIconButton, CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {CloseIcon, SearchIcon} from '@anywhere-expert/icons';
import {useAnalytics} from 'react-shisell';
import {AnalyticsDispatcher} from 'shisell';

const createFetchAndSetSuggestionsFunc = (
    setSuggestions: (suggestions: HelixSuggestionItem[]) => void,
    dispatcher: AnalyticsDispatcher
) => async ({value}: {value: string}) => {
    const suggestions = await fetchSuggestions(value);
    if (suggestions.length === 0) {
        dispatcher.withExtra('SearchTerms', value).dispatch('NoResults');
    }
    setSuggestions(suggestions);
};

const InputUnderline = styled.div`
    border: 0;
    height: 1px;
    background-color: ${({theme}) => theme.colors.contrast.border};
`;

const StyledCoreIconButton = styled(CoreIconButton)`
    color: ${({theme}) => theme.colors.contrast.caption};
    &:disabled {
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }

    &:hover,
    &:active {
        background-color: transparent;
    }
`;

const Form = styled.form`
    padding-right: 24px;
`;

const SuggestionText = styled(CoreText)`
    padding: 8px;
`;

type Props = {
    placeholder: string | undefined;
    searchTerm: string;
    setSearchTerm: any;
    onSearch: (value: string, isAutocompleteUsed: boolean) => void;
};

const Suggestion = suggestion => (
    <SuggestionText textType="primary" size="m">
        {suggestion.title}
    </SuggestionText>
);
const getSuggestionValue = suggestion => suggestion.title;

const HelixSearchInput = memo(({placeholder, searchTerm, setSearchTerm, onSearch}: Props) => {
    const [suggestions, setSuggestions] = useState<HelixSuggestionItem[]>([]);
    const [lastType, setLastType] = useState('');
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('AutoComplete'), [analytics]);
    const getSuggestionCallback = useCallback(createFetchAndSetSuggestionsFunc(setSuggestions, dispatcher), [
        setSuggestions,
        dispatcher,
    ]);

    const onSuggestionSelected = useCallback(
        (e, {suggestion, suggestionValue, method}) => {
            e.preventDefault();
            const extraData = {
                SearchTerms: lastType,
                Rank: suggestion.rank,
                SuggestionValue: suggestionValue,
                SuggestionClicked: method,
            };
            onSearch(suggestionValue, true);
            dispatcher.withExtras(extraData).dispatch('Click');
            setSearchTerm(suggestionValue);
        },
        [onSearch, dispatcher, setSearchTerm]
    );

    const submit = useCallback(
        e => {
            e.preventDefault();
            onSearch(searchTerm, false);
        },
        [searchTerm, onSearch]
    );

    const inputProps = useMemo(
        () => ({
            placeholder,
            onChange: (_, {newValue, method}) => {
                setSearchTerm(newValue);
                if (method === 'type') {
                    setLastType(newValue);
                }
            },
            value: searchTerm,
            'data-test-id': 'helix-input',
            autoFocus: true,
        }),
        [placeholder, searchTerm]
    );

    const clearSuggestions = useCallback(() => {
        setSuggestions([]);
    }, [setSuggestions]);

    const onResetClick = useCallback(() => {
        setSearchTerm('');
        setLastType('');
    }, [setSearchTerm, setLastType]);

    return (
        <Form onSubmit={submit}>
            <AutoSuggestContainer>
                <AutoSuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={getSuggestionCallback}
                    onSuggestionsClearRequested={clearSuggestions}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={onSuggestionSelected}
                    renderSuggestion={Suggestion}
                    inputProps={inputProps}
                />
                <StyledCoreIconButton
                    type="reset"
                    disabled={!searchTerm}
                    onClick={onResetClick}
                    icon={searchTerm ? CloseIcon : SearchIcon}
                    size="s"
                    variant="icon"
                />
            </AutoSuggestContainer>
            <InputUnderline />
        </Form>
    );
});

export default HelixSearchInput;
