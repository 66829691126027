import {observable, action, computed, runInAction, makeObservable} from 'mobx';
import {
    Editor,
    EditorState,
    CompositeDecorator,
    Modifier,
    RichUtils,
    SelectionState,
    DraftHandleValue,
    convertToRaw,
    convertFromRaw,
    ContentState,
} from 'draft-js';
import {Link} from '../components/LinkEntityComponent';
import {findLinkEntities} from '../utils/findLinkEntity';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';

export enum ListTypeEnum {
    UnOrderList = 'unordered-list-item',
    OrderedList = 'ordered-list-item',
}
export enum EditorStyleEnum {
    Bold = 'BOLD',
    Italic = 'ITALIC',
    StrikeThrough = 'STRIKETHROUGH',
}

const markdownToDraftExtraStyle = {
    blockStyles: {
        del_open: 'STRIKETHROUGH',
    },
};

export class RichInputStore {
    editorRef: Editor;
    editorState: EditorState = EditorState.createEmpty();
    focusAtPosition: number | null;
    focusBlockKey: string | undefined;
    richInputModifier = Modifier;

    constructor(initialText?: string) {
        makeObservable<
            RichInputStore,
            'editorState' | 'editorRef' | 'selectedText' | 'focusAtPosition' | 'focusBlockKey' | 'editorMarkdownText'
        >(this, {
            focusAtPosition: observable,
            focusBlockKey: observable,
            editorRef: observable,
            editorState: observable,
            selectedText: computed,
            hasBlockStyle: computed,
            hasInlineStyle: computed,
            hasAnyStyle: computed,
            editorMarkdownText: computed,
            setEditorState: action,
            setEditorStateFromText: action,
            focus: action,
            focusPosition: action,
            setEditorReference: action,
            handleKeyCommand: action,
        });
        if (initialText) {
            this.setEditorStateFromText(initialText);
        }
    }

    setEditorState = (editorState?: EditorState) => {
        runInAction(() => {
            this.editorState = EditorState.set(editorState ?? EditorState.createEmpty(), {
                decorator: new CompositeDecorator([
                    {
                        strategy: findLinkEntities,
                        component: Link,
                    },
                ]),
            });
        });
    };

    get hasBlockStyle(): boolean {
        return (
            RichUtils.getCurrentBlockType(this.editorState) == ListTypeEnum.OrderedList ||
            RichUtils.getCurrentBlockType(this.editorState) == ListTypeEnum.UnOrderList
        );
    }

    get hasInlineStyle(): boolean {
        return (
            this.editorState.getCurrentInlineStyle().has(EditorStyleEnum.Bold) ||
            this.editorState.getCurrentInlineStyle().has(EditorStyleEnum.Italic) ||
            this.editorState.getCurrentInlineStyle().has(EditorStyleEnum.StrikeThrough)
        );
    }

    get hasAnyStyle(): boolean {
        return this.hasInlineStyle || this.hasBlockStyle;
    }

    get editorPlainText() {
        return this.editorState?.getCurrentContent().getPlainText('') ?? '';
    }

    get editorMarkdownText() {
        return this.draftContentToMarkdown(this.editorState?.getCurrentContent());
    }

    get selectedText(): string {
        const state = this.editorState;
        const selection = state.getSelection();
        const currentBlock = state.getCurrentContent().getBlockForKey(selection.getAnchorKey());

        return currentBlock.getText().slice(selection.getStartOffset(), selection.getEndOffset());
    }

    setEditorStateFromText = (text: string) => {
        const currentContent = this.editorState.getCurrentContent();

        if (!this.focusBlockKey) {
            const newDraftContentState = RichInputStore.markdownToDraftContent(text);
            runInAction(() => {
                const newState = EditorState.push(this.editorState, newDraftContentState, 'insert-characters');
                this.setEditorState(this.moveFocusToEnd(newState));
            });
            return;
        }
        const blockExist = !!currentContent.getBlockForKey(this.focusBlockKey);
        const selection = blockExist
            ? this.editorState.getSelection().merge({
                  anchorKey: this.editorState.getSelection().getAnchorKey(),
                  focusKey: this.focusBlockKey,
                  isBackward: false,
                  anchorOffset: 0,
                  focusOffset: this.editorPlainText?.length,
              })
            : this.editorState.getSelection();

        const newContentState = this.richInputModifier.replaceText(
            currentContent,
            selection,
            text,
            this.editorState!.getCurrentInlineStyle()
        );

        const newState = text == '' ? EditorState.createEmpty() : EditorState.createWithContent(newContentState);
        runInAction(() => {
            this.setEditorState(newState);
        });
    };

    setEditorReference = (editorRef: Editor) => {
        this.editorRef = editorRef;
    };

    addInlineStyle = (style: EditorStyleEnum) => {
        const currentSelection = this.editorState.getSelection();
        this.focus();

        let newState = EditorState.forceSelection(this.editorState, currentSelection);
        newState = RichUtils.toggleInlineStyle(newState, style);
        this.setEditorState(newState);
    };

    addBlockStyle = (style: ListTypeEnum) => {
        this.focus();
        const newState = RichUtils.toggleBlockType(this.editorState, style);
        this.setEditorState(newState);
    };

    stateHasInlineStyle = (inlineStyle: EditorStyleEnum) => {
        return this.editorState.getCurrentInlineStyle().has(inlineStyle);
    };

    stateHasBlockStyle = (blockStyle: ListTypeEnum) => {
        return RichUtils.getCurrentBlockType(this.editorState) == blockStyle;
    };

    focus = () => {
        this.editorRef?.focus();
        this.setEditorState(this.moveFocusToEnd(this.editorState));
    };

    focusPosition = () => {
        const state: EditorState = this.editorState;
        const finalPos = this.focusAtPosition ?? this.editorPlainText?.length ?? null;
        const newSelection = state.getSelection().merge({
            isBackward: false,
            focusKey: this.focusBlockKey ?? state.getSelection().getFocusKey(),
            anchorKey: this.focusBlockKey ?? state.getSelection().getAnchorKey(),
            focusOffset: finalPos,
            anchorOffset: finalPos,
        });

        const newState = EditorState.forceSelection(this.editorState, newSelection);
        this.focus();
        this.setEditorState(newState);
        this.focusAtPosition = null;
        this.focusBlockKey = undefined;
    };

    addLinkEntityState = (textInput: string, linkInput: string, additionalInputLength: number) => {
        const contentState = this.editorState.getCurrentContent();
        const selection: SelectionState = this.editorState.getSelection();

        const newContent = this.richInputModifier.replaceText(contentState, selection, textInput);
        const newContentWithEntity = newContent.createEntity('LINK', 'IMMUTABLE', {url: linkInput});
        const additionalOffset = !!additionalInputLength && additionalInputLength != 0 ? additionalInputLength : 0;

        const newSelection = selection.merge({focusOffset: selection.getFocusOffset() + additionalOffset});

        const newContentWithLink = this.richInputModifier.applyEntity(
            newContentWithEntity,
            newSelection,
            newContentWithEntity.getLastCreatedEntityKey()
        );

        const linkTextState = EditorState.push(this.editorState, newContentWithLink, 'insert-characters');

        const finalState = EditorState.forceSelection(linkTextState, newContentWithLink.getSelectionAfter());
        this.setEditorState(finalState);
    };

    moveFocusToEnd = (state: EditorState) => {
        return EditorState.moveFocusToEnd(state);
    };

    insertEmoji = (emoji: string) => {
        const editorState = this.editorState;
        const selectionState = editorState.getSelection();
        const selectionStart = selectionState.getStartOffset();
        const selectionEnd = selectionState.getEndOffset();
        const block = editorState.getCurrentContent().getBlockForKey(selectionState.getAnchorKey());
        const blockText = block.getText();
        const newText = blockText.slice(0, selectionStart!) + emoji + blockText.slice(selectionEnd!, blockText.length);
        const pos = selectionStart! + emoji.length;

        this.focusBlockKey = selectionState.getAnchorKey();
        this.focusAtPosition = pos;
        this.setEditorStateFromText(newText);
        this.focusPosition();
    };

    handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.setEditorState(newState);
            return 'handled';
        } else if (command === 'STRIKETHROUGH') {
            this.setEditorState(RichUtils.toggleInlineStyle(editorState, command));
            return 'handled';
        }
        return 'not-handled';
    };

    private draftContentToMarkdown = (contentState: ContentState): string => {
        const raw = convertToRaw(contentState);
        const markdown = draftToMarkdown(raw);
        return markdown;
    };

    private static markdownToDraftContent = (text: string): ContentState => {
        const rawData = markdownToDraft(text, markdownToDraftExtraStyle);
        return convertFromRaw(rawData);
    };

    static plainTextFromMarkdown = (text: string): string => {
        return RichInputStore.markdownToDraftContent(text).getPlainText();
    };
}

export type RichInputStoreType = RichInputStore;
