import {AnalyticsDispatcher} from 'shisell';
import TimelineInputStore from '../stores/TimelineInputStore';

const useShortcutFocusInput = (dispatcher: AnalyticsDispatcher) => {
    return {
        id: 'input-focus',
        handler: (e: KeyboardEvent) => {
            e.preventDefault();
            dispatcher.withExtra('ActionId', 'KeyboardShortcut_MessageInputFocus').dispatch('Click');
            TimelineInputStore.richInput.focusPosition();
        },
    };
};

export default useShortcutFocusInput;
