import {QueueItemAttribute} from '@soluto-private/expert-queue-api-types';
import {AttributesByType} from '@soluto-private/session-api-types';

import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {HorizonEnrollmentState} from '@soluto-private/smart-home-anywhere-expert';
import {getOfferDetectionFields, getSalesInteractionFields} from './helpers';

export const hasEnrolledAttribute = (queueItemAttributes: QueueItemAttribute[]): boolean =>
    queueItemAttributes.some((attr: QueueItemAttribute) => attr.key === HorizonEnrollmentState.Enrolled);

export const unselectedSessionJustEnrolled = (
    session: SupportItem,
    newFeedItemAttributes: QueueItemAttribute[]
): boolean => {
    const wasUnselectedSession = session.sessionId !== ExpertFeedStore.selectedSessionId;
    const wasPreviouslyUnenrolled = !hasEnrolledAttribute(session.feedItemAttributes);
    const wasJustEnrolled = hasEnrolledAttribute(newFeedItemAttributes);

    return wasPreviouslyUnenrolled && wasJustEnrolled && wasUnselectedSession;
};

export const wasOfferJustDetected = (
    oldSessionAttributes: AttributesByType,
    newSessionAttributes: AttributesByType
): boolean => {
    const legacyWasOfferPreviouslyDetected = (getSalesInteractionFields(oldSessionAttributes) as any)?.autoOffer;
    const newWasOfferPreviouslyDetected = getOfferDetectionFields(oldSessionAttributes)?.offerDetected;
    const wasOfferPreviouslyDetected = legacyWasOfferPreviouslyDetected || newWasOfferPreviouslyDetected;

    const legacyWasOfferJustDetected = (getSalesInteractionFields(newSessionAttributes) as any)?.autoOffer;
    const newWasOfferJustDetected = getOfferDetectionFields(newSessionAttributes)?.offerDetected;
    const wasOfferJustDetected = legacyWasOfferJustDetected || newWasOfferJustDetected;

    return wasOfferPreviouslyDetected === undefined && wasOfferJustDetected !== undefined;
};
