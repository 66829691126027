import {QueueItem} from '@anywhere-expert/expert-feed-store';
import copySessionId from '../behaviour/copySessionId';

const useShortcutCopySessionId = (analytics, dispatcher, selectedSession?: QueueItem) => {
    return {
        id: 'copy-session-id',
        handler: (e: KeyboardEvent) => {
            if (!selectedSession) return null;

            e.preventDefault();
            const {sessionId} = selectedSession;
            dispatcher.withExtra('ActionId', 'KeyboardShortcut_CopySessionId').dispatch('Click');
            copySessionId(sessionId, analytics);
        },
    };
};

export default useShortcutCopySessionId;
