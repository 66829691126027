import React from 'react';
import {useAnalytics, Analytics} from 'react-shisell';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {useEventListener} from '@anywhere-expert/utils';
import {TimelineController} from '@anywhere-expert/timeline-state';
import {useTimelineSearchStore} from './useTimelineSearchStore';

export const setSelectedResult = (contentId: string) => {
    TimelineController.scrollToMessage(contentId);
};

const onItemSelected = (analytics: Analytics, customerId?: string) => (messageId: string, text: string) => {
    setSelectedResult(messageId);

    analytics?.dispatcher
        .withIdentity('CustomerId', customerId)
        .withExtras({
            ActionId: 'MessageSelected',
            MessageId: messageId,
            Text: text,
        })
        .dispatch('Click');
};

export const useKeyboardNavigation = () => {
    const analytics = useAnalytics();
    const timelineSearchStore = useTimelineSearchStore();

    const handleKeyPress = React.useCallback(
        event => {
            if (timelineSearchStore.focusedIndex === -2) return;
            if (event.key === 'ArrowDown') {
                analytics.dispatcher.withExtras({ActionId: 'NavigateDown'}).dispatch('Click');
                timelineSearchStore.keydownPressed();
                event.preventDefault();
            } else if (event.key === 'ArrowUp') {
                analytics.dispatcher.withExtras({ActionId: 'NavigateUp'}).dispatch('Click');
                timelineSearchStore.keyupPressed();
                event.preventDefault();
            }
        },
        [timelineSearchStore]
    );
    useEventListener('keydown', handleKeyPress);
};

const useSearchResultsBehaviors = (selectedSession?: SupportItem) => {
    const customerId = selectedSession && selectedSession.supportItemDetails.customerId;

    const analytics = useAnalytics();

    const onSelectedMessage = React.useMemo(() => {
        return onItemSelected(analytics, customerId);
    }, [analytics, customerId]);

    return {
        onSelectedMessage,
        analytics,
    };
};

export default useSearchResultsBehaviors;
