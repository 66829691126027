import React, {useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {CoreText, CoreTextArea} from '@anywhere-expert/base-ui';
import {NextButton} from '../../../../styled-components';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {
    useInterpolatedConclusions,
    useMessagingSalesClickAnalytics,
    useCurrentOffer,
    useHorizonEnrollmentFlowContent,
} from '../../../../../hooks';
import {useSendMessage} from '@anywhere-expert/timeline-behaviours';
import {HorizonEnrollmentFlowStep, HorizonEnrollmentFlowContent} from '../../../../../types';
import StepLabel from './StepLabel';

const EnrollmentLinkContainer = styled(CoreTextArea)`
    width: 90%;
`;

export const SendEnrollmentLink = observer(({selectedSession}: SalesSessionProps) => {
    const {title, buttonText} = useHorizonEnrollmentFlowContent(
        HorizonEnrollmentFlowStep.SendEnrollmentLink
    ) as HorizonEnrollmentFlowContent[HorizonEnrollmentFlowStep.SendEnrollmentLink];
    const enrollmentLinkText = useInterpolatedConclusions(selectedSession)[0];
    const {sessionId, customerId, pool: partner} = selectedSession;
    const currentOffer = useCurrentOffer(sessionId);

    const clickedWithExtras = useMessagingSalesClickAnalytics('SendCustomerEnrollURLContinue');
    const sendMessage = useSendMessage(customerId, sessionId, partner);

    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

    const onNextClick = async () => {
        setNextButtonDisabled(true);
        clickedWithExtras({});

        try {
            await currentOffer?.submitEnrollmentLink(async () => {
                await sendMessage(enrollmentLinkText);
            });
        } finally {
            setNextButtonDisabled(false);
        }
    };

    return (
        <div data-test-id="MessagingSales_HorizonEnrollmentFlow_SendEnrollmentLink">
            <StepLabel selectedSession={selectedSession} />
            <CoreText size="m">{title}</CoreText>
            <EnrollmentLinkContainer
                data-test-id="EnrollmentLinkArea"
                value={enrollmentLinkText}
                isInputValid={true}
                disabled={true}
            />
            <NextButton
                data-test-id="MessagingSales_EnrollmentFlow_NextButton"
                color="primary"
                onClick={onNextClick}
                text={buttonText}
                isInProgress={nextButtonDisabled}
            />
        </div>
    );
});

export default SendEnrollmentLink;
