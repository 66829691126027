import React from 'react';
import {useAnalytics} from 'react-shisell';
import {useUserProfile} from '@anywhere-expert/auth';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {useEventListener} from '@anywhere-expert/utils';
import CannedMessagesStoreV2 from './stores/CannedMessagesStoreV2';
import {useTweekValue} from 'react-tweek';
import {setPrePopulatedData} from '@anywhere-expert/default-input-text';
import logger from '@anywhere-expert/logging';
import {CannedMessage} from '@soluto-private/canned-messages-api-client';
import {useCannedMessagesNavigationStore} from './useCannedMessagesNavigationStore';

export const setSelectedSuggestion = conversationId => suggestion => {
    TimelineInputStore.setEditorText(conversationId, suggestion);
};

const updateUsage = async (categoryId: string, messageId: string) => {
    try {
        await CannedMessagesStoreV2.updateMessageUsage(categoryId, messageId);
    } catch (err) {
        logger.warn('unable to update canned messages usage', {err, extra: {messageId}});
    }
};

const onItemSelected = (customerId, analytics) => (
    categoryTitle: string,
    categoryId: string,
    messageId: string,
    message: CannedMessage
) => {
    const onMessageSelection = setSelectedSuggestion(customerId);
    onMessageSelection(message.text);

    if (CannedMessagesStoreV2.areActionsEnabled) {
        updateUsage(categoryId, messageId);
    }

    analytics?.dispatcher
        .withIdentity('DeviceId', customerId)
        .withExtras({
            ActionId: 'MessageSelected',
            MessageId: messageId,
            Category: categoryId,
            CategoryName: categoryTitle,
            IndexInCategory: message.displayOrder,
            Text: message.text,
            FeatureVersion: 'v2',
        })
        .dispatch('Click');

    setPrePopulatedData(customerId, message.text, 'CannedMessage');
};

export const useKeyboardNavigation = () => {
    const cannedMessagesNavigationStore = useCannedMessagesNavigationStore();
    const handleKeyPress = React.useCallback(
        event => {
            if (cannedMessagesNavigationStore.focusedIndex === -2) return;
            if (event.key === 'ArrowDown') {
                cannedMessagesNavigationStore.keydownPressed();
                event.preventDefault();
            } else if (event.key === 'ArrowUp') {
                cannedMessagesNavigationStore.keyupPressed();
                event.preventDefault();
            }
        },
        [cannedMessagesNavigationStore]
    );
    useEventListener('keydown', handleKeyPress);
};

const useCannedMessagesBehaviors = (selectedSession?: SupportItem) => {
    const customerId = selectedSession && selectedSession.supportItemDetails.customerId;

    const analytics = useAnalytics();
    const userProfile = useUserProfile();

    const areCannedMessageActionsEnabled = useTweekValue('support/session/canned_message/actions/is_enabled', true);

    const onSelectedMessage = React.useMemo(() => {
        return onItemSelected(customerId, analytics);
    }, [analytics, customerId, areCannedMessageActionsEnabled]);

    return {
        onSelectedMessage,
        userProfile,
        analytics,
    };
};

export default useCannedMessagesBehaviors;
