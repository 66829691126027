import {NotifyOptions} from '../types';
import {Howl} from 'howler';
import {analytics} from 'react-shisell';
import {AnalyticsDispatcher} from 'shisell';
import {getUserProfile} from '@anywhere-expert/auth';
import logger from '@anywhere-expert/logging';

const notificationSound = new Howl({
    src: [require('../sounds/slow-spring-board.mp3')],
});

const onShow = (options: NotifyOptions, dispatcher: AnalyticsDispatcher) => {
    options.sound && notificationSound.play();
    dispatcher.dispatch('View');
    options.onShow?.();
};

const onClick = (notification: Notification, options: NotifyOptions, dispatcher: AnalyticsDispatcher) => {
    options.closeOnClick && notification.close();
    dispatcher.dispatch('Click');
    try {
        options.onClick?.();
    } catch (err) {
        logger.error('clicking on notification failed', {err, extra: {options}});
    }
};

const notify = (title: string, options: NotifyOptions) => {
    const dispatcher = analytics.dispatcher
        .createScoped('AEWeb_Notification')
        .withIdentity('TechId', getUserProfile()?.uid)
        .withExtras({
            Type: options.analyticsTag ?? options.tag,
            Sound: !!options.sound,
            ShowInForeground: options.showInForeground,
            IsInForeground: window.document.visibilityState === 'visible',
            Title: title,
            Body: options.body,
        });

    const sendNotification = () => {
        dispatcher.dispatch('Send');
        const notif = new Notification(title, {lang: 'en', dir: 'ltr', ...options});
        notif.addEventListener('click', () => onClick(notif, options, dispatcher));
        notif.addEventListener('show', () => onShow(options, dispatcher));
        options.onClose && notif.addEventListener('close', options.onClose);
    };

    if (!options.showInForeground && window.document.visibilityState === 'visible') return false;

    requestPermissionAndSend(sendNotification);
    return true;
};

const requestPermissionAndSend = async (sendNotification: () => void) => {
    if (Notification.permission === 'granted') {
        sendNotification();
        return;
    }
    if (Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            sendNotification();
        }
    }
};

export default notify;
