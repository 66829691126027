import {useTheme} from '@anywhere-expert/colors';
import {EyeIcon} from '@anywhere-expert/icons';
import React from 'react';
import {SupportedScreenshareLevel} from '../../../sharedScreenshareDefinitions';
import MouseIcon from '../../icons/MouseIcon';
import Snackbar, {SnackbarProps} from './Snackbar';

const levelToProps: Record<
    SupportedScreenshareLevel,
    {
        generateText: (customerName: string) => string;
        icon: SnackbarProps['icon'];
    }
> = {
    screenshare: {
        generateText: customerName => `You're observing ${customerName}'s screen`,
        icon: <EyeIcon color="white" />,
    },
    'remote-control': {
        generateText: customerName => `You're controlling ${customerName}'s screen`,
        icon: <MouseIcon />,
    },
};

export default ({level, customerName}: {level: SupportedScreenshareLevel; customerName: string}) => {
    const theme = useTheme();

    const {generateText, icon} = levelToProps[level];

    return (
        <Snackbar
            text={generateText(customerName)}
            icon={icon}
            backgroundColor={theme.colors.brand.blue}
            textColor="white"
        />
    );
};
