import styled from 'styled-components';
export default styled.div`
    .DraftEditor-root {
        flex-grow: 1;
        width: 100%;
        min-height: 40px;
        background-color: transparent;
    }

    .rdw-editor-main {
        display: flex;
        align-items: center;
        background-color: transparent;
        flex-grow: 1;
        padding: 10px 0 0 10px;
        margin: 8px 0 0 8px;
        border-width: 0;
        resize: none;
        align-self: center;
        line-height: 20px;
        color: ${({theme}) => theme.colors.neutral.black};
        text-align: left;
        width: 100%;
        flex-grow: 1;
    }

    .DraftEditor-editorContainer {
        background-color: rgba(255, 255, 255, 0);
        border-left: 0.1px solid transparent;
        z-index: 1;
        padding: 8px 8px;
    }

    .public-DraftEditorPlaceholder-root {
        color: rgb(186, 186, 193);
        padding-top: 6px;
        padding-left: 3px;
        margin: 5px 0;
        margin-bottom: -20px;
        color: ${({theme}) => theme.colors.neutral.black};
    }

    .public-DraftEditorPlaceholder-inner {
        color: rgb(186, 186, 193);
        padding-left: 6px;
        padding-top: 2px;
    }
    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-ol {
        padding: 0px 15px !important;
        margin: 0px !important;
    }

    .public-DraftEditor-content {
        overflow-wrap: anywhere !important;
        color: ${({theme}) => theme.colors.neutral.black};
        padding: 4px 0px;
    }

    position: relative;
    z-index: 1;
    align-self: flex-start;
    width: 100%;
    max-height: 100px;
    overflow-y: scroll;
    flex-grow: 1;
`;
