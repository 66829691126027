import {useState, useEffect, useCallback} from 'react';
import {useObservable} from 'rxjs-hooks';
import {debounceTime, map} from 'rxjs/operators';
import {useAnalytics} from 'react-shisell';
import {useTweekValue} from 'react-tweek';
import logger from '@anywhere-expert/logging';
import {SuggestedBehaviourRepository, SuggestedBehaviourState} from './types';
import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 5,
    maxTimeout: 2000,
};

export default function useSuggestiveBehaviour(
    timelineId: string | undefined,
    inputText: string
): [SuggestedBehaviourState | undefined, () => void, () => void] {
    const [suggestiveBehaviour, setSuggestiveBehaviour] = useState<SuggestedBehaviourState | undefined>(undefined);
    const analytics = useAnalytics();
    const repository = useTweekValue<SuggestedBehaviourRepository>(
        'support/session/during/suggestive_behaviour/repository',
        {}
    );
    const cleanSuggestiveBehaviour = useCallback(() => setSuggestiveBehaviour(undefined), []);
    const dismissSuggestiveBehaviour = useCallback(
        () => suggestiveBehaviour && setSuggestiveBehaviour({...suggestiveBehaviour, isDismissed: true}),
        [suggestiveBehaviour]
    );

    const debouncedInputText = useObservable<string, [string]>(
        inputs$ =>
            inputs$.pipe(
                debounceTime(200),
                map(inputs$ => inputs$[0])
            ),
        inputText,
        [inputText]
    );

    useEffect(() => {
        (async () => {
            try {
                const findBadBehaviour = (
                    await pRetry(
                        () => import(/* webpackChunkName: "findBadBehaviour" */ './findBadBehaviour'),
                        retryConfig
                    )
                ).default;
                const foundBehaviour = findBadBehaviour(repository, debouncedInputText);

                // Show only one time suggestive behaviour as long the expert dont switch conversations
                if (foundBehaviour && !suggestiveBehaviour) {
                    setSuggestiveBehaviour(foundBehaviour);
                } else if (suggestiveBehaviour && !foundBehaviour && !suggestiveBehaviour.isReverted) {
                    analytics.dispatcher.dispatch('ResponseChange');
                    setSuggestiveBehaviour({...suggestiveBehaviour, isReverted: true});
                }
            } catch (err) {
                logger.warn('Failed to load findBadBehaviour', {err});
            }
        })();
    }, [debouncedInputText]);

    useEffect(() => {
        cleanSuggestiveBehaviour();
    }, [timelineId]);

    return [suggestiveBehaviour, cleanSuggestiveBehaviour, dismissSuggestiveBehaviour];
}
