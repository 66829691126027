import {makeAutoObservable, runInAction} from 'mobx';
import logger from '@anywhere-expert/logging';
import {ICobrowseAPI, RemoteContext} from './cobrowse-agent-sdk_types';
import {ToolName, featureName} from './definitions';

export default class CobrowseIframeManager {
    private _activeTool: ToolName | undefined;
    cobrowseApi: ICobrowseAPI;
    public get activeTool() {
        return this._activeTool;
    }

    private ctx: RemoteContext | undefined;

    private readonly cobrowseSessionId: string;

    public get iframeUrl() {
        if (!this.cobrowseSessionId) return undefined;

        return `${this.cobrowseApi.api}/session/${this.cobrowseSessionId}?agent_tools=none&device_controls=none&nochrome=true&allow_popout=false&end_action=none&token=${this.cobrowseApi.token}`;
    }

    public constructor(cobrowseApi: ICobrowseAPI, cobrowseSessionId: string) {
        this.cobrowseApi = cobrowseApi;
        this.cobrowseSessionId = cobrowseSessionId;

        makeAutoObservable(this);
    }

    public async connectToIframe(cobrowseIframeElement: HTMLIFrameElement) {
        this.ctx = await this.cobrowseApi.attachContext(cobrowseIframeElement);
    }

    public disconnectFromIframe() {
        logger.info('Screenshare: disconnect from iframe', {extra: {featureName}});

        if (this.ctx) {
            this.ctx.destroy();
            this.ctx = undefined;
        }
    }

    public setTool = async (toolName: ToolName) => {
        await this.ctx?.setTool(toolName);
        runInAction(() => {
            // runInAction is needed because the function is async
            this._activeTool = toolName;
        });
    };

    public clearDrawings = async () => {
        await this.ctx?.clearAnnotations();
    };

    public androidBack = async () => {
        return await this.ctx?.androidBack();
    };

    public androidHome = async () => {
        return await this.ctx?.androidHome();
    };
}
