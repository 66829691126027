import {getPrePopulatedData} from '@anywhere-expert/default-input-text';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {getMarkdownUsageInformation} from '@soluto-private/soluto-markdown';
import {useCallback} from 'react';
import {useAnalytics} from 'react-shisell';

interface Props {
    sessionId: string;
    timelineId: string;
}

export const useDispatchMessageSentAnalytics = ({sessionId, timelineId}: Props) => {
    const analytics = useAnalytics();

    return useCallback(
        (text: string, messageId: string, sessionSource?: string, sessionSourceVersion?: string) => {
            const {
                containsBold,
                containsItalics,
                containsLink,
                containsOrderedList,
                containsUnorderedList,
                containsPhone,
            } = getMarkdownUsageInformation(text);

            const data = getPrePopulatedData(timelineId);

            analytics.dispatcher
                .withIdentities({
                    SessionId: sessionId,
                    MessageId: messageId,
                })
                .withExtras({
                    containsBold,
                    sessionSource,
                    sessionSourceVersion,
                    containsItalics,
                    containsLink,
                    containsOrderedList,
                    containsUnorderedList,
                    containsPhoneNumber: containsPhone,
                    PrepopulateReason: data && data.name && data.text === text ? data.name : '',
                    Experience: ExpertFeedStore.assignMethod,
                })
                .dispatch('ExpertMessageSent');
        },
        [analytics, sessionId, timelineId]
    );
};
