import {CoreButton, CoreIcon, CoreText, CompoundButtonSet} from '@anywhere-expert/base-ui';
import React, {useCallback} from 'react';
import styled from 'styled-components';
import {AnalyticsDispatcher} from 'shisell';
import {AssignStore} from '..';
import {getBorderStyle, getBoxShadowStyle} from '@anywhere-expert/base-ui';
import SpinningIcon from './SpinningIcon';
import {LookingIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';

type CancelLinkProps = {
    cancelFunc: () => void;
};

type LowVolumeProps = CancelLinkProps & {
    cancelFunc: () => void;
    assignStore: AssignStore;
    dispatcher: AnalyticsDispatcher;
    getWaitingTimeInSeconds: () => undefined | number;
};

const CancelBtn = styled(CoreButton)`
    padding-left: unset;
`;

const LowVolumeContainer = styled.div`
    position: absolute;
    bottom: 0px;
    max-width: inherit;
    right: 0;
    left: 0;
    height: fit-content;
    min-height: 73px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex: 1;
    border-radius: 12px;
    margin: 8px 9px 8px 9px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    ${({theme}) => getBorderStyle(theme)};
    ${({theme}) => getBoxShadowStyle(theme)};
`;

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    margin: 16px 16px 16px 0px;
`;

const WaitingConfirmBtn = styled(CoreButton)`
    border-radius: 40px;
    margin-right: 16px;
    width: 110px;
`;

const LowVolumeButtonContainer = styled(CompoundButtonSet)`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    margin-left: -4px;
    margin-top: 12px;
`;

const NotNowBtnContainer = styled.div`
    margin-left: 16px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

const StyledText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.black};
    line-height: 20px !important;
    margin-bottom: 4px;
`;

const StyledSubText = styled(CoreText)`
    color: ${({theme}) => theme.colors.contrast.secondary};
`;

const LookingIconContainer = styled(CoreIcon)`
    margin: 16px 12px 16px 16px;
`;

const NotNowComponent = ({cancelFunc}: CancelLinkProps) => (
    <CancelBtn variant="text" color="secondary" size="s" text="No, I’ll check later" onClick={cancelFunc} />
);

const LowVolumeComponent = ({cancelFunc, assignStore, getWaitingTimeInSeconds, dispatcher}: LowVolumeProps) => {
    const {assignmentState} = assignStore;
    const hideForPush = assignStore.isPush;
    const theme = useTheme();

    const approveFunc = useCallback(() => {
        dispatcher
            ?.withExtras({
                ActionId: 'KeepWaitingForSession',
                WaitingTime: getWaitingTimeInSeconds(),
                State: assignmentState,
            })
            .dispatch('Click');
        assignStore.lowVolumeStore?.manualTimer();
    }, [assignStore, assignmentState, getWaitingTimeInSeconds]);

    return (
        <LowVolumeContainer data-test-id={`AutoAssignMessage_low_volume`}>
            {hideForPush ? (
                <LookingIconContainer icon={LookingIcon} color={theme.colors.neutral.black} />
            ) : (
                <SpinningIcon />
            )}
            <ContentContainer>
                <StyledText size="l" weight="bold">
                    {'Not a lot of sessions right now'}
                </StyledText>
                {hideForPush ? (
                    <StyledSubText size="m">{'We’re still looking for your next session'}</StyledSubText>
                ) : (
                    <>
                        <StyledSubText size="m">{'Do you want to keep waiting?'}</StyledSubText>
                        <LowVolumeButtonContainer responsive size="m">
                            <WaitingConfirmBtn
                                onClick={approveFunc}
                                size="s"
                                variant="contained"
                                color="primary"
                                text="Yes, I’ll wait"
                                data-test-id="LowVolume_KeepWaitingForSessionLink"
                            />
                            <NotNowBtnContainer data-test-id="LowVolume_CancelWaitingForSessionLink">
                                <NotNowComponent cancelFunc={cancelFunc} />
                            </NotNowBtnContainer>
                        </LowVolumeButtonContainer>{' '}
                    </>
                )}
            </ContentContainer>
        </LowVolumeContainer>
    );
};

export default LowVolumeComponent;
