import axios from 'axios';
import {HelixArticleItem} from '../types';
import {configuration} from '@anywhere-expert/core';

const fetchArticles = async (carrier: string, term: string) => {
    const result = await axios.post(
        `${configuration.helix.searchUrl}/${carrier}`,
        {
            searchTerm: term,
            type: 'keyword',
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    let articles = [] as HelixArticleItem[];

    if (!result || !result.data) {
        return articles;
    }
    if (Array.isArray(result.data)) {
        const temp_articles = result.data;
        articles = temp_articles.filter(({type}) => type === 'content' || type === 'topic') as HelixArticleItem[];
    }

    return articles;
};

export default fetchArticles;
