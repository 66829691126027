import logger from '@anywhere-expert/logging';
import axios, {AxiosInstance} from 'axios';
import {createAxiosAuthHeadersInterceptor} from '@anywhere-expert/auth';
import configuration from '../config/config';

let axiosClient: AxiosInstance | undefined;

const getClient = () => {
    if (!axiosClient) {
        axiosClient = axios.create({
            baseURL: configuration.similarSessionApiUrl,
            timeout: 8000,
        });

        axiosClient.interceptors.request.use(createAxiosAuthHeadersInterceptor());
    }

    return axiosClient;
};

export const getSessionTranscript = async (similarSessionId: string, partner: string | undefined) => {
    const partnerToSend = partner || '';
    const response = await getClient().get(
        `/api/similar-sessions/${similarSessionId}?partner=${partnerToSend}&queue=${partnerToSend}`
    );
    return response.data;
};

export const getSessionFeedback = async (sessionId: string, expertId: string, similarSessionId: string) =>
    (
        await getClient().get(
            `/api/similar-sessions/sessionFeedbackRating/${sessionId}?expertId=${expertId}&similarSessionId=${similarSessionId}`
        )
    ).data;

export const getSessionRating = async (similarSessionId: string) =>
    (await getClient().get(`/api/similar-sessions/rating/${similarSessionId}`)).data;

export const setSessionFeedback = async (
    sessionId: string,
    expertId: string,
    similarSessionId: string,
    value: boolean,
    reason: number | undefined
) => {
    try {
        const body = {
            sessionId,
            expertId,
            similarSessionId,
            rating: {
                value,
                reason,
            },
        };

        await getClient().post(`/api/similar-sessions/sessionFeedbackRating`, body);
    } catch (error) {
        logger.warn(`Failed to set similar sessions feedback rating on api`, {
            err: error,
            extra: {sessionId, expertId, similarSessionId, value, reason},
        });
        throw error;
    }
};
