import React, {FunctionComponent} from 'react';
import {CategoriesList} from './components';
import {observer} from 'mobx-react';
import {useKeyboardNavigation} from './useCannedMessagesBehaviors';
import {CannedMessage} from '@soluto-private/canned-messages-api-client';

type Props = {
    onMessageSelection: (categoryTitle: string, categoryId: string, messageId: string, message: CannedMessage) => void;
};

const CannedMessages: FunctionComponent<Props> = observer(({onMessageSelection}: Props) => {
    useKeyboardNavigation();

    return <CategoriesList onMessageSelection={onMessageSelection} />;
});

export default CannedMessages;
