import {useTheme} from '@anywhere-expert/colors';

export interface MessagingSalesColors {
    themeName: string;
    red: string;
    lightRed: string;
    tennis: string;
    green: string;
    grey: string;
    black: string;
    blue: string;
}

export const useMessagingSalesColors = (): MessagingSalesColors => {
    const theme = useTheme();
    const green = theme.colors.primary.green.bright;
    const tennis = theme.colors.brand.tennis;
    const black = theme.colors.neutral.black;
    const red = theme.colors.semantic.red.normal;
    const lightRed = '#F08989';
    const grey = theme.colors.neutral.grey.deep;
    const blue = theme.colors.primary.blue.brighter;

    return {
        themeName: theme.themeName,
        green,
        tennis,
        red,
        lightRed,
        grey,
        black,
        blue,
    };
};

export default useMessagingSalesColors;
