import {logout as authLogout} from '@anywhere-expert/auth';
import {ExpertAvailabilityStore, Status} from '@expert-feed/expert-availability';
import {sendAnalytics} from '../services/analytics/analyticsWriter';
import {CannedMessagesStoreV2} from '@anywhere-expert/canned-messages-v2';
import {User} from '@anywhere-expert/auth';

const sendLogoutAnalytics = (user: User) =>
    sendAnalytics([
        {
            Name: 'Logout',
            Scope: 'AEWeb',
            MetaData: {},
            ExtraData: {CarrierName: 'CarrierName'},
            Identities: {TechId: user.uid, ExpertExpertise: user.expertise.sort().join("','")},
        },
    ]);

export default async function logout(user: User) {
    await Promise.all([
        sendLogoutAnalytics(user),
        CannedMessagesStoreV2.deleteMessageSuggestionsIfAny(),
        ExpertAvailabilityStore.setExpertAvailability({
            status: Status.AWAY,
            statusReason: 'logging out',
            source: 'logout',
        }),
    ]);
    authLogout();
}
