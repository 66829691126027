import {CheckCircleOutlineIcon} from '@anywhere-expert/icons';
import React from 'react';
import DismissibleSnackbar from './DismissibleSnackbar';

export default ({customerName}: {customerName: string}) => {
    return (
        <DismissibleSnackbar
            text={`${customerName} turned on accessibility`}
            backgroundColor="#333F48"
            textColor="white"
            icon={<CheckCircleOutlineIcon color="white" />}
        />
    );
};
