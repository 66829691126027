import {observable, action, reaction, computed, runInAction, makeObservable} from 'mobx';
import notesApiClient from '../utils/notesApiClient';
import {SupportItem} from '.';
import {v4 as uuid} from 'uuid';
import {Note} from '@soluto-private/anywhere-expert-notes-api-client';
import logger from '@anywhere-expert/logging';

const shouldShowPreviousNote = (note: Note, myUserId: string) => !note.isPrivate || note.senderId === myUserId;

const hasNoteChanged = (myNote?: Note, title?: string, body?: string) =>
    (myNote?.body ?? '') !== body || (myNote?.title ?? '') !== title;

export class QueueItemNotesStore {
    timelineId: string;
    parent: SupportItem;
    body: string;
    title?: string;
    updatedAt: string;
    noteId: string;
    isPrivate: boolean = false;
    notes: Note[] | undefined;
    seenPreviousNotes: boolean = false;
    loading = false;

    constructor(timelineId: string, parent: SupportItem) {
        makeObservable<QueueItemNotesStore, 'fetchNotes' | 'createEditableNote' | 'myNote'>(this, {
            body: observable,
            title: observable,
            updatedAt: observable,
            noteId: observable,
            isPrivate: observable,
            notes: observable,
            seenPreviousNotes: observable,
            loading: observable,
            setSeenPreviousNotes: action,
            setBody: action,
            setTitle: action,
            setIsPrivate: action,
            fetchNotes: action,
            createEditableNote: action,
            myNote: computed,
            previousNotes: computed,
            hasUnseenPreviousNotes: computed,
            upsertNote: action,
        });

        this.timelineId = timelineId;
        this.parent = parent;

        if (this.parent.isSelected) {
            this.fetchNotes();
        }

        reaction(
            () => this.parent.isSelected,
            isSelected => {
                if (isSelected) {
                    this.fetchNotes();
                }
            }
        );
    }

    setSeenPreviousNotes = (s: boolean = true) => (this.seenPreviousNotes = s);

    setBody = (b: string) => (this.body = b);

    setTitle = (t: string) => (this.title = t);

    setIsPrivate = (p: boolean) => (this.isPrivate = p);

    private fetchNotes = async () => {
        if (this.notes) return;
        let notes: Note[] = [];
        try {
            this.loading = true;
            notes = await notesApiClient.getNotes(this.timelineId);
            runInAction(() => {
                this.loading = false;
                this.notes = notes;
                this.createEditableNote();
            });
        } catch (error) {
            logger.warn('Failed fetching expert notes', {
                err: error,
                extra: {customerId: this.timelineId, sessionId: this.parent.sessionId},
            });
            runInAction(() => {
                this.notes = [];
                this.loading = false;
                this.createEditableNote();
            });
        }
    };

    private createEditableNote = () => {
        this.isPrivate = !!this.myNote?.isPrivate;
        this.body = this.myNote?.body ?? '';
        this.title = this.myNote?.title ?? '';
        this.noteId = this.myNote?.id ?? uuid();
        this.updatedAt = this.myNote?.updatedAt ?? new Date().toISOString();
    };

    private get myNote(): Note | undefined {
        const {uid} = this.parent.store.user;
        const {sessionId} = this.parent;

        return this.notes?.find(x => x.senderId == uid && x.sessionId == sessionId);
    }

    get previousNotes(): Note[] {
        const {uid} = this.parent.store.user;
        if (!this.notes) return [];
        return this.notes?.filter(x => x.id !== this.myNote?.id && !!x.body && shouldShowPreviousNote(x, uid));
    }

    get hasUnseenPreviousNotes() {
        return !!this.previousNotes.length && !this.seenPreviousNotes;
    }

    upsertNote = () => {
        const {uid, imageUrl = '', displayName} = this.parent.store.user;
        const {sessionId} = this.parent;

        if (!hasNoteChanged(this.myNote, this.title, this.body)) return;
        notesApiClient.upsertNote(this.timelineId, {
            id: this.noteId,
            body: this.body,
            title: this.title,
            isPrivate: this.isPrivate,
            sessionId,
            imageUrl,
            senderId: uid,
            senderName: displayName,
        });
    };
}

export type QueueItemNotesStoreType = QueueItemNotesStore;
