import React, {useCallback, useMemo, SyntheticEvent} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Tab, TabsConfig, TabType, TabId} from '../types';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {useAnalytics} from 'react-shisell';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {CloseCircleIcon} from '@anywhere-expert/icons';
import SidebarStore from '../SidebarStore';

const separatorHeight = 25;

const TabsContainer = styled.div`
    align-self: flex-start;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
    background: ${({theme}) => theme.colors.background.secondary};
`;

const TabContainer = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    text-align: center;

    &:hover > .closeBtn {
        opacity: 1;
    }
`;

type IndicatorContainerProps = {tabsNum: number; hasSeparator: boolean};
const getIndicatorContainerHeight = ({tabsNum, hasSeparator}: IndicatorContainerProps) => {
    const height = tabsNum * 48;
    return hasSeparator ? separatorHeight + height : height;
};
const IndicatorContainer = styled.div<IndicatorContainerProps>`
    width: 2px;
    background-color: ${({theme}) => fade(theme.colors.neutral.black, 0.2)};
    height: ${props => `${getIndicatorContainerHeight(props)}px`};
    position: absolute;
    top: 32px;
    left: 0;
`;

type IndicatorProps = {selectedIndex: number; numOfConstantTabs: number};
const getIndicatorTop = ({selectedIndex, numOfConstantTabs}: IndicatorProps) => {
    const height = selectedIndex * 48;
    return selectedIndex > numOfConstantTabs - 1 ? height + separatorHeight : height;
};
const Indicator = styled.span<{selectedIndex: number; numOfConstantTabs: number}>`
    position: absolute;
    width: 2px;
    height: 48px;
    transition: transform 0.2s ease-in-out;
    margin: 0;
    top: 0;
    transform: translateY(${props => `${getIndicatorTop(props)}px`});
    background-color: ${({theme}) => theme.colors.neutral.black};
`;

const Separator = styled.div`
    width: 32px;
    background-color: ${({theme}) => theme.colors.neutral.grey.light};
    height: 1px;
    margin: 12px 0 12px 0;
`;

const CloseButton = styled(({selected, ...rest}) => <CoreIconButton {...rest} />)`
    position: absolute;
    top: -4px;
    right: -4px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    background-color: transparent;
    &:hover {
        color: ${({theme}) => theme.colors.neutral.grey.deepest};
        background-color: transparent;
    }
`;

const useTabsForRendering = (tabs: TabsConfig) => {
    const tabEntries = useMemo(() => {
        const tabEntries = Object.entries(tabs);
        const sorted = tabEntries.sort(([, tabA], [, tabB]) =>
            tabA.type < tabB.type ? -1 : tabA.type === tabB.type ? 0 : 1
        );
        const separatorIndex = sorted.findIndex(([, tab]) => tab.type > TabType.Constant);
        const constantTabs = separatorIndex > 0 ? sorted.slice(0, separatorIndex) : sorted;
        const otherTabs = separatorIndex > 0 ? sorted.slice(separatorIndex, sorted.length) : [];

        return {tabs: sorted, constantTabs, otherTabs};
    }, [tabs]);

    return tabEntries;
};

type Props = {
    selected: string;
    setSelected: React.Dispatch<React.SetStateAction<string>>;
    tabs: TabsConfig;
    selectedSession: SupportItem;
};

const Tabs = observer(({selected, setSelected, tabs, selectedSession, ...props}: Props) => {
    const analytics = useAnalytics();
    const onTabClick = useCallback((key: string) => {
        setSelected(key);
        analytics.dispatcher.withExtra('ActionId', key).dispatch('Click');
    }, []);

    const renderTab = useCallback(
        ([key, tab]: [TabId, Tab]) => {
            const {Icon, getDisabledText, tooltipText, tooltipProps} = tab;

            const disabledText = getDisabledText && getDisabledText(selectedSession);
            const onCloseClick = (e: SyntheticEvent) => {
                SidebarStore.closeTab(key, tab);
                e.stopPropagation();
            };
            const onTabButtonClick = (e: SyntheticEvent, disabledText: string | undefined) => {
                !disabledText && onTabClick(key);
                e.stopPropagation();
            };
            return (
                <TabContainer data-test-id={key} key={key} onClick={e => e.stopPropagation()}>
                    <CoreIconButton
                        icon={Icon}
                        variant="icon"
                        isActive={selected === key}
                        disabled={!!disabledText}
                        onClick={e => onTabButtonClick(e, disabledText)}
                        tooltipProps={{
                            text: disabledText || tooltipText,
                            position: 'left',
                            ...tooltipProps,
                        }}
                        size="s"
                        iconProps={{size: 's'}}
                    />
                    {tab.IndicatorIcon && <tab.IndicatorIcon />}
                    {tab.type !== TabType.Constant ? (
                        <CloseButton
                            variant="icon"
                            className="closeBtn"
                            onClick={onCloseClick}
                            selected={selected === key}
                            icon={CloseCircleIcon}
                            size="xs"
                            iconProps={{size: 's'}}
                        />
                    ) : null}
                </TabContainer>
            );
        },
        [selected, selectedSession]
    );

    const tabsForRendering = useTabsForRendering(tabs);
    const selectedIndex = tabsForRendering.tabs.findIndex(([id]) => id === selected);

    return (
        <TabsContainer {...props}>
            {tabsForRendering.constantTabs.map(renderTab)}
            {tabsForRendering.otherTabs.length > 0 ? <Separator /> : null}
            {tabsForRendering.otherTabs.map(renderTab)}
            <IndicatorContainer
                className="tabs-indicator-container"
                tabsNum={tabsForRendering.tabs.length}
                hasSeparator={!!tabsForRendering.otherTabs.length}
            >
                <Indicator selectedIndex={selectedIndex} numOfConstantTabs={tabsForRendering.constantTabs.length} />
            </IndicatorContainer>
        </TabsContainer>
    );
});

export default Tabs;
