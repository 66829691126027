import React from 'react';

export default ({color}: {color: string}) => (
    <svg
        style={{marginLeft: '4px'}}
        width="13"
        height="9"
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1252 0.900547L6.53516 5.90063L1.94516 0.900547L0.535157 2.31055L6.53516 8.31055L12.5352 2.31055L11.1252 0.900547Z"
            fill={color}
        />
    </svg>
);
