import React from 'react';
import styled, {css} from 'styled-components';
import {observer} from 'mobx-react';
import {AddIcon} from '../../resources';
import {CoreText} from '@anywhere-expert/base-ui';
import {CoreIconButton} from '@anywhere-expert/base-ui';

const Container = styled.div<{disabled?: boolean; selected?: boolean}>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: opacity 0.3s;
    width: 95%;
    padding: 12px 12px 12px 4px;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    ${({disabled}) =>
        disabled &&
        css`
            cursor: default !important;
            opacity: 0.5;
        `};

    ${({theme, selected}) =>
        selected &&
        css`
            cursor: default;
            background: ${theme.colors.contrast.toolboxItemSelected};
        `};

    ${({theme, disabled, selected}) =>
        !disabled &&
        !selected &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    flex: 1;
    word-break: break-word;
    margin-left: 8px;
`;

const Button = styled(CoreIconButton)`
    margin-left: 6px;
    color: ${({theme}) => theme.colors.contrast.disabled};

    &:hover {
        color: ${({theme}) => theme.colors.contrast.primary};
    }

    &:active {
        opacity: 1;
    }
`;

type Props = {
    onClick: () => void;
    disabled: boolean;
};

const AddCategoryButton = observer(({onClick, disabled}: Props) => {
    return (
        <Container onClick={onClick} disabled={disabled} data-test-id="CannedMessages_AddCategory">
            <Button icon={AddIcon} size="s" color="currentColor" variant="icon-fade" />
            <TextContainer>
                <CoreText textType="secondary" size="m">
                    Add Category
                </CoreText>
            </TextContainer>
        </Container>
    );
});

export default AddCategoryButton;
