/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {BaseIcon, IconProps} from '@anywhere-expert/icons';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <g fill={color}>
                <path d="M9 9h6v6H9V9z" />
                <path
                    d="M2 12C2 6.486 6.486 2 12 2s10 4.486 10 10c0 5.515-4.486 10-10 10S2 17.515 2 12zm2 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8z"
                    fillRule="evenodd"
                />
            </g>
        </BaseIcon>
    ))
);

export default Icon;
