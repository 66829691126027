import {useEffect} from 'react';
import {palette} from '@anywhere-expert/colors';
import {getTweekValue} from '@anywhere-expert/tweek';
import useRefState from '@anywhere-expert/utils/hooks/useRefState';

export type CalculatedCategory = {
    id: string;
    icon: string;
    color: string;
    title: string;
    secondaryTitle?: string;
    emptyCategoryTitle?: string;
};

type CalculatedCategories = {suggestionsCategory: CalculatedCategory; frequentlyUsedCategory: CalculatedCategory};

let calculatedCategories: CalculatedCategories;

const DEFAULT_VALUES = {
    frequentlyUsedCategory: {
        icon: '⛳',
        color: palette.categorical.lightRed,
        title: 'Most Frequently Used',
        id: 'frequency',
    },
    suggestionsCategory: {
        icon: '📌',
        color: palette.neutral.grey.light,
        title: 'Unsaved messages',
        emptyCategoryTitle: 'No unsaved messages',
        secondaryTitle:
            'All the messages you’ve pasted are right here. Save them before you log out!',
        id: 'suggestions',
    },
};

export const initCalculatedCategories = async () => {
    if (calculatedCategories) {
        return;
    }

    calculatedCategories = await getTweekValue(
        'support/session/canned_message/calculated_categories/_',
        DEFAULT_VALUES
    );
};

const getCalculatedCategory = async (categoryName: keyof CalculatedCategories) => {
    if (!calculatedCategories) {
        await initCalculatedCategories();
    }

    return calculatedCategories[categoryName];
};

export const getMostFrequentlyUsedCategoryData = async () => getCalculatedCategory('frequentlyUsedCategory');
export const getSuggestionsCategory = async () => getCalculatedCategory('suggestionsCategory');

const useCalculatedCategory = (getter: () => Promise<CalculatedCategory>) => {
    const [category, setCategory] = useRefState<CalculatedCategory | undefined>(undefined);

    useEffect(() => {
        getter().then(setCategory);
    }, []);

    return category;
};

export const useSuggestionsCategory = () => useCalculatedCategory(getSuggestionsCategory);
export const useMostFrequentlyUsedCategoryData = () => useCalculatedCategory(getMostFrequentlyUsedCategoryData);
