import {notify} from '@anywhere-expert/notifications';

const sendInactiveNotification = (sound: boolean) =>
    notify('Are you still here?', {
        body: "Click here if you're still available to receive new sessions",
        tag: 'expert-becoming-inactive',
        closeOnClick: true,
        requireInteraction: true,
        showInForeground: true,
        sound,
        icon: 'https://anywhereexpert-static.mysoluto.com/media/notifications/inactive-notification.png',
        onClick: () => {
            window.focus();
        },
    });

export default sendInactiveNotification;
