import React from 'react';
import {css, StyleSheet} from 'aphrodite';
import {Animation} from '@anywhere-expert/base-ui';
import styles from '../ImageItem/imageItemStyles';

const spinner = require('./assets/spinner.json');
const animationOptions = {
    animationData: spinner,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    rendererSettings: {
        scaleMode: 'noScale',
    },
};

const style = StyleSheet.create({
    animation: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const UploadSpinnerAnimation = () => (
    <div className={css(styles.animationOverlay)}>
        <Animation animationOptions={animationOptions} style={style.animation} />
    </div>
);

export default UploadSpinnerAnimation;
