const firebaseConfig = {
    dev: {
        apiKey: 'AIzaSyCYLq8k7hW5DNKvgsCY4Bg315L0V-uIamM',
        authDomain: 'homedev-26419.firebaseapp.com',
        databaseURL: 'https://homedev-26419.firebaseio.com',
        storageBucket: 'homedev-26419.appspot.com',
        projectId: 'homedev-26419',
        messagingSenderId: '361926804367',
    },
    test: {
        apiKey: 'AIzaSyCjJq5nBqADWL5RiEDAhSvSu7L8Au-h-sw',
        authDomain: 'hometests-72416.firebaseapp.com',
        databaseURL: 'https://hometests-72416.firebaseio.com',
        storageBucket: 'hometests-72416.appspot.com',
        projectId: 'hometests-72416',
        messagingSenderId: '31371192880',
    },
    prod: {
        apiKey: 'AIzaSyAReYraPpN7jmSdfCJ1C_gYd0F-na5qJUY',
        authDomain: 'sizzling-heat-9792.firebaseapp.com',
        projectId: 'sizzling-heat-9792',
        databaseURL: 'https://sizzling-heat-9792.firebaseio.com',
        storageBucket: 'sizzling-heat-9792.appspot.com',
        messagingSenderId: '238230473389',
    },
};

export default {
    firebase: firebaseConfig.prod,
};
