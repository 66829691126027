import * as React from 'react';
import styled from 'styled-components';
import {CoreText, CoreIcon, CoreIconProps} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';

const Container = styled.div<{selected?: boolean}>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: opacity 0.3s;
    width: 100%;
    padding: 12px 12px 12px 8px;
    margin: 4px 0;
    color: ${({theme}) => theme.colors.neutral.grey.deep};

    ${({theme, selected}) =>
        selected
            ? `background: ${theme.colors.contrast.border};`
            : `&:hover {
                cursor: pointer;
                background: ${theme.colors.contrast.hover};
            }
        `};

    &:hover {
        cursor: pointer;
        background: ${({theme}) => theme.colors.neutral.grey.lightest};
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    word-break: break-word;
    margin-left: 8px;
`;

interface OfferSelectionItemProps {
    Icon: CoreIconProps['icon'];
    title: string;
    onClick: () => void;
    selected?: boolean;
    dataTestId?: string;
}

const OfferSelectionItem = ({Icon, title, onClick, dataTestId, selected}: OfferSelectionItemProps) => {
    const theme = useTheme();

    const content = (
        <Container onClick={onClick} data-test-id={dataTestId} selected={selected}>
            <CoreIcon icon={Icon} color={selected ? theme.colors.neutral.black : undefined} variant="bordered" />
            <TextContainer>
                <CoreText textType="primary" size="m" weight="bold">
                    {title}
                </CoreText>
            </TextContainer>
        </Container>
    );

    return content;
};

export default OfferSelectionItem;
