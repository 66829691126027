import React from 'react';
import styled from 'styled-components';
import {hexToRgba} from '@anywhere-expert/colors';
import {CannedMessagesStoreV2} from '@anywhere-expert/canned-messages-v2';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import {UserStorageStore} from '@anywhere-expert/user-storage-store';

const Badge = styled.div`
    width: 16px;
    height: 16px;
    background-color: ${({theme}) => hexToRgba(theme.colors.brand.blue, 1)};
    border-radius: 50%;
    position: absolute;
    color: ${({theme}) => theme.colors.neutral.white};
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function checkForCannedMessageSuggestions() {
    const suggestions = CannedMessagesStoreV2.expertCannedMessages?.suggestions;
    const numberOfItems = suggestions ? Object.keys(suggestions).length : 0;

    return {doAnySuggestionsExist: numberOfItems > 0, numberOfSuggestions: numberOfItems};
}

export const IndicatorBadge = observer(({className}: {className?: string}) => {
    const isPasteToCannedEnabledInTweek = useTweekValue(
        'support/session/canned_messages/allow_saving_pasted_messages',
        false
    );
    const isPasteToCannedEnabledInUserStorage =
        UserStorageStore.getValue('isCannedMessagesPastingEnabled', 'enabled') === 'enabled';

    if (!isPasteToCannedEnabledInTweek || !isPasteToCannedEnabledInUserStorage) {
        return null;
    }

    const {doAnySuggestionsExist: isBadgeNecessary, numberOfSuggestions} = checkForCannedMessageSuggestions();

    if (!isBadgeNecessary) {
        return null;
    }

    return <Badge className={className}>{numberOfSuggestions}</Badge>;
});
