import {SalesStatusToTag, CustomerEligibilityStatus, SalesType, TagSource, SalesStatusToText} from './types';

export const salesStatusToText: SalesStatusToText = {
    [CustomerEligibilityStatus.Eligible]: 'Eligible for sales',
    [CustomerEligibilityStatus.Ineligible]: 'Ineligible for sales',
    [CustomerEligibilityStatus.Unknown]: '⚠ Check eligibility status',
};

export const salesTagMapping: SalesStatusToTag = {
    [CustomerEligibilityStatus.Unknown]: {
        tagKey: salesStatusToText[CustomerEligibilityStatus.Unknown],
        tagType: SalesType.Sales,
        displayText: salesStatusToText[CustomerEligibilityStatus.Unknown],
        tagStyle: {
            backgroundColor: '#FFCB05',
            textColor: 'black',
        },
        source: TagSource.AsyncTaggerSales,
    },
    [CustomerEligibilityStatus.Eligible]: {
        tagKey: salesStatusToText[CustomerEligibilityStatus.Eligible],
        tagType: SalesType.Sales,
        displayText: salesStatusToText[CustomerEligibilityStatus.Eligible],
        tagStyle: {
            backgroundColor: '#68DC72',
            textColor: 'black',
        },
        source: TagSource.AsyncTaggerSales,
    },
    [CustomerEligibilityStatus.Ineligible]: {
        tagKey: salesStatusToText[CustomerEligibilityStatus.Ineligible],
        tagType: SalesType.Sales,
        displayText: salesStatusToText[CustomerEligibilityStatus.Ineligible],
        tagStyle: {
            backgroundColor: '#EA3661',
            textColor: 'black',
        },
        source: TagSource.AsyncTaggerSales,
    },
};
