import React from 'react';
import styled from 'styled-components';
import {
    ModalContentComponentProps,
    ModalContent,
    ModalActions,
    ModalTitle,
    CoreButton,
    CoreText,
} from '@anywhere-expert/base-ui';
import {AnalyticsDispatcher} from 'shisell';

const SmallModalTitle = styled(ModalTitle)`
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
`;

interface DeleteMessageConfirmationModalProps extends ModalContentComponentProps {
    onDelete: () => void;
    dispatcher: AnalyticsDispatcher;
}

const DeleteMessageConfirmationModal = ({onDelete, dispatcher, close}: DeleteMessageConfirmationModalProps) => {
    const handleDelete = React.useCallback(() => {
        onDelete();
        dispatcher.dispatch('Success');
        close();
    }, [onDelete, dispatcher]);

    const handleCancel = React.useCallback(() => {
        dispatcher.dispatch('Undo');
        close();
    }, [dispatcher]);
    return (
        <>
            <SmallModalTitle modalSize="s">Delete Message</SmallModalTitle>
            <ModalContent>
                <CoreText size="s">Are you sure you want to delete this message for everyone?</CoreText>
            </ModalContent>
            <ModalActions>
                <CoreButton variant="outlined" onClick={handleCancel} text="Cancel" size="s" />
                <CoreButton variant="contained" color="danger" onClick={handleDelete} size="s" text="Delete" />
            </ModalActions>
        </>
    );
};

export default DeleteMessageConfirmationModal;
