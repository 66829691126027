import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import {MEDIUM_WIDTH} from '../styled-components/sales-stats';
import {CoreText, CoreIconButton} from '@anywhere-expert/base-ui';
import {useTweekValue} from 'react-tweek';
import {Tips} from '../../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {ChevronLeftIcon, ChevronRightIcon} from '@anywhere-expert/icons';
import useMessagingSalesColors from '../../hooks/useMessagingSalesColors';
import {useMessagingSalesClickAnalytics} from '../../hooks';

const CustomText = styled(CoreText)`
    font-family: Apercu Pro, sans-serif;
    line-height: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({theme}) => theme.colors.background.secondary};
`;

const Blub = styled.img`
    height: 45px;
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};
`;

const Title = styled(CustomText)`
    margin-top: ${({theme}) => theme.spacing(2)}px;
    margin-bottom: ${({theme}) => theme.spacing(4)}px;
    font-weight: 700;
    font-size: 34px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 28px;
    }
`;

const TipContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
`;

const ToggleButton = styled(CoreIconButton)`
    width: 70px;
    height: 70px;
    margin: 20px 35px;

    svg {
        width: 70px;
        height: 70px;
    }
`;

const TipDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 700px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        width: 400px;
    }
`;

const Message = styled(CustomText)`
    font-weight: 300;
    font-size: 28px;
    padding-bottom: 20px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 22px;
    }
`;

const Author = styled(CustomText)`
    font-weight: 300;
    font-size: 20px;
    padding-bottom: 10px;
`;

const Subtitle = styled(CustomText)`
    font-weight: 300;
    font-size: 16px;
    margin-left: 22px;
`;

const Pagination = styled(CustomText)`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;

    @media (max-width: ${MEDIUM_WIDTH}) {
        font-size: 22px;
    }
`;

const useButtonColor = (): string => {
    const colors = useMessagingSalesColors();

    return colors.themeName === 'light' ? colors.blue : colors.green;
};

const shuffle = (array: Tips): Tips => {
    return array
        .map(a => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map(a => a.value);
};

const Tip = () => {
    const buttonColor = useButtonColor();
    const clickedWithExtras = useMessagingSalesClickAnalytics('ToggleTip');
    const [index, setIndex] = useState(0);
    const tips: Tips = useTweekValue(MessagingSalesTweekKeys.ProfileTip, [{message: '', author: '', subtitle: ''}]);
    const shuffledTips = useMemo(() => {
        return shuffle(tips);
    }, [tips]);

    const onLastTipClick = () => {
        clickedWithExtras({ActionId: 'LastTip'});
        let newIndex = index - 1;

        if (newIndex < 0) {
            setIndex(tips.length - 1);
        } else {
            setIndex(newIndex);
        }
    };

    const onNextTipClick = () => {
        clickedWithExtras({ActionId: 'NextTip'});
        let newIndex = index + 1;

        if (newIndex >= tips.length) {
            setIndex(0);
        } else {
            setIndex(newIndex);
        }
    };

    return (
        <Container data-test-id="SalesProfile_Tip">
            <Blub src={require('./resources/blub.png')} />
            <Title>Tip time</Title>

            <TipContainer>
                <ToggleButton
                    icon={ChevronLeftIcon}
                    variant="icon"
                    colorOverride={buttonColor}
                    onClick={onLastTipClick}
                    data-test-id="Chevron_Left"
                />
                <TipDetails>
                    <Message data-test-id="Tip_Message">{shuffledTips[index].message}</Message>
                    {shuffledTips[index].author && <Author>— {shuffledTips[index].author}</Author>}
                    {shuffledTips[index].subtitle && <Subtitle>{shuffledTips[index].subtitle}</Subtitle>}
                </TipDetails>
                <ToggleButton
                    icon={ChevronRightIcon}
                    variant="icon"
                    colorOverride={buttonColor}
                    onClick={onNextTipClick}
                    data-test-id="Chevron_Right"
                />
            </TipContainer>

            <Pagination>
                {index + 1} of {tips.length}
            </Pagination>
        </Container>
    );
};

export default Tip;
