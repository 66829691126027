import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import Result from './Result';
import {observer} from 'mobx-react';
import {useAnalytics} from 'react-shisell';
import {useTimelineSearchStore} from '../useTimelineSearchStore';

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 93%;
    word-break: break-word;
    margin-bottom: 5px;
`;

type Props = {
    onSelectMessage: (messageId: string, text: string) => void;
};

const useAnalyticsWithScope = () => {
    const analytics = useAnalytics();

    return analytics.dispatcher.createScoped('SearchResults');
};

const Results: FunctionComponent<Props> = observer(({onSelectMessage}: Props) => {
    const dispatcher = useAnalyticsWithScope();
    const timelineSearchStore = useTimelineSearchStore();

    React.useEffect(() => {
        dispatcher.withExtras({ResultsCount: timelineSearchStore.messages?.length}).dispatch('View');
    }, [timelineSearchStore]);

    return (
        <MessagesContainer>
            {timelineSearchStore.messages &&
                timelineSearchStore.messages?.map(message => (
                    <Result
                        key={message.messageId}
                        id={message.messageId}
                        isFocused={timelineSearchStore.isFocused({messageId: message.messageId})}
                        message={message}
                        onSelectMessage={onSelectMessage}
                    />
                ))}
        </MessagesContainer>
    );
});

export default Results;
