import {observer} from 'mobx-react';
import React from 'react';
import Attribute, {AttributeContainer, AttributeName} from './Attribute';
import {LocationIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import {InStoreDetails} from '../../types';

export const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const StoreLocationAttribute = ({value = 'Unknown'}: {value: string}) => (
    <AttributeContainer>
        <AttributeName size="s" textType="black">
            Store Location
        </AttributeName>
        <HorizontalContainer>
            <LocationIcon width={30} style={{marginRight: 7}} />
            <CoreText size="m" textType="primary">
                {value.split('\n').map((x, index) => (
                    <span key={index}>
                        {x}
                        <br />
                    </span>
                ))}
            </CoreText>
        </HorizontalContainer>
    </AttributeContainer>
);

export default observer(({inStoreDetails}: {inStoreDetails: InStoreDetails}) => {
    const {storeName, services, storeNumber, storeLocation} = inStoreDetails || ({} as any);

    return (
        <>
            <LoggingErrorBoundary boundaryName="InStoreSessionAttributes" extraData={{details: inStoreDetails}}>
                <Attribute name="Store Name" value={storeName} defaultValue="Unknown" />
                <Attribute name="Services" value={services} defaultValue="Unknown" />
                <Attribute name="Store Number" value={storeNumber} defaultValue="Unknown" />
                <StoreLocationAttribute value={storeLocation} />
            </LoggingErrorBoundary>
        </>
    );
});
