import {Observable} from 'rxjs';
import {timer} from 'rxjs/observable/timer';
import {empty} from 'rxjs/observable/empty';
import {ConnectionStatus} from '@soluto-private/live-messaging-api-client';

const BUFFER = 5000;

const debounceConnectionStatus = (connectionStatus$: Observable<ConnectionStatus>) => {
    return connectionStatus$
        .distinctUntilChanged()
        .debounce(connection => (connection.status ? empty() : timer(BUFFER)))
        .distinctUntilChanged()
        .startWith({status: true});
};

export default debounceConnectionStatus;
