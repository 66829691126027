import React from 'react';
import {CoreList, CoreListItem} from '@anywhere-expert/base-ui';
import styled, {keyframes} from 'styled-components';
import {ExpertAvailabilityStatus, defaultUnavailable, Status} from '../types';
import AvailabilityIndicator from './AvailabilityIndicator';

const slideInKeyFrames = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

type Props = {
    onChange: (options: ExpertAvailabilityStatus) => void;
    value: ExpertAvailabilityStatus;
    awayOptions: ExpertAvailabilityStatus[];
};

const StyledCoreList = styled(CoreList)`
    animation: ${slideInKeyFrames} 200ms linear;
    min-width: 200px;
` as typeof CoreList;

const AvailabilityMenu = ({onChange, value, awayOptions}: Props) => {
    return (
        <StyledCoreList hasIcons>
            {awayOptions.map(({statusReason, status}: ExpertAvailabilityStatus) => (
                <CoreListItem
                    onClick={() => onChange({statusReason, status})}
                    style={{textTransform: 'capitalize'}}
                    Icon={() => <AvailabilityIndicator status={status} />}
                    key={statusReason}
                    text={statusReason}
                    hasSelectedIcon={
                        value.status === Status.AVAILABLE
                            ? status === Status.AVAILABLE
                            : (value.statusReason ?? defaultUnavailable) === statusReason
                    }
                    data-test-id={`AvailabilityMenu_Option_${statusReason}`}
                />
            ))}
        </StyledCoreList>
    );
};

export default AvailabilityMenu;
