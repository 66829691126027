import {observable, action, runInAction, reaction, computed, makeObservable} from 'mobx';
import {ExpertAvailabilityStatus, Status, StatusChangeSource} from '../types';
import {updateExpertAvailability, updateExpertWaitingForSession} from '../availabilityApiClient';
import {getTweekValue} from '@anywhere-expert/tweek';
import {AssignmentType, deletedSupportItems$} from '@anywhere-expert/expert-feed-store';
import {UpdateWaitingForSessionError} from '@soluto-private/expert-api-types';
import {getExpert} from '@anywhere-expert/lifecycle-expert-api-client';
import sendInactiveNotification from '../sendInactiveNotification';
import {Subscription, Observable, BehaviorSubject} from 'rxjs';
import {createPresence} from '../createPresence';
import uuid from 'uuid';
import {userProfile$} from '@anywhere-expert/auth';
import getAvailabilityClient from '../availabilityApiClient';
import logger from '@anywhere-expert/logging';

let intervalId: number;
const POLLING_INTERVAL = 30 * 1 * 1000;

class ExpertAvailabilityStore {
    changedAt?: Date;
    isInitialized: boolean;
    waitingForSession?: boolean = false;
    waitingForSessionTimestamp?: Date;
    private isActive?: boolean = true;
    private isFrontEndActive?: boolean = true;
    private isTogglingEnabled: boolean = false;
    private status: Status;
    private shortTermInactive: boolean = false;
    private awayReason: string | null;
    private statusChangeSource?: StatusChangeSource;
    private longTermPresence: BehaviorSubject<boolean>;
    private shortTermPresence: BehaviorSubject<boolean>;
    private shouldSendPings: boolean = true;
    private stopPingsTimeout: number;
    private expertId: string;
    private deletedItemsSubscription: Subscription | undefined;
    private pingSubscription: Subscription | undefined;
    private timeToIdle: number;
    private timeToPriorIdle: number;
    private limitReached: boolean | undefined;

    constructor() {
        makeObservable<
            ExpertAvailabilityStore,
            | 'isActive'
            | 'isFrontEndActive'
            | 'status'
            | 'shortTermInactive'
            | 'awayReason'
            | 'statusChangeSource'
            | 'limitReached'
            | 'reactToNeglectedItems'
            | 'reactToExpertBecameInactive'
            | 'reactToExpertBecameActiveInClient'
            | 'reactToExpertReachLimit'
            | 'loadExpertAvailability'
            | 'pollForExpertAvailability'
            | 'longTermPresence'
            | 'shortTermPresence'
            | 'handlePresenceDetector'
            | 'idleTimeout'
        >(this, {
            changedAt: observable,
            isInitialized: observable,
            isActive: observable,
            isFrontEndActive: observable,
            setExpertWaitingForSession: action,
            cancelExpertWaitingForSession: action,
            status: observable,
            limitReached: observable,
            shortTermInactive: observable,
            waitingForSession: observable,
            awayReason: observable,
            statusChangeSource: observable,
            waitingForSessionTimestamp: observable,
            longTermPresence: observable,
            shortTermPresence: observable,
            init: action,
            reactToNeglectedItems: action,
            reactToExpertBecameInactive: action,
            reactToExpertBecameActiveInClient: action,
            reactToExpertReachLimit: action,
            setExpertAvailability: action,
            setClientActivness: action,
            expertAvailabilityStatus: computed,
            isAvailable: computed,
            idleTimeout: computed,
            isShortTermInactive: computed,
            loadExpertAvailability: action,
            pollForExpertAvailability: action,
            handlePresenceDetector: action,
        });
    }

    async init(isTogglingEnabled: boolean, isInitiallyAvailable: boolean, expertId: string, cameFromLogin?: boolean) {
        if (this.isInitialized) return;
        this.isInitialized = true;
        this.isTogglingEnabled = isTogglingEnabled;
        this.expertId = expertId;

        const assignmentType = getTweekValue<AssignmentType>(
            'support/routing/assignment_strategy/assignment_type',
            'get-next'
        );

        this.timeToIdle = getTweekValue<number>('support/app/time_to_idle', 1800000);

        this.timeToPriorIdle = getTweekValue<number>('support/app/time_to_prior_idle', 1500000);

        this.longTermPresence = await createPresence(this.timeToIdle);
        this.shortTermPresence = await createPresence(this.timeToPriorIdle);

        this.stopPingsTimeout = getTweekValue<number>(
            'support/app/stop_ping_timeout',
            180000 // 3 min
        );
        const isAutoAssign = assignmentType === 'get-next' || assignmentType === 'push';

        Observable.combineLatest(this.longTermPresence, this.shortTermPresence).subscribe(this.handlePresenceDetector);
        this.sendPings();
        this.reactToExpertBecameInactive();

        if (!isTogglingEnabled) {
            this.status = Status.AVAILABLE;
            updateExpertAvailability(this.expertId, {
                isAvailable: true,
                unavailableReason: '',
            });
            if (isAutoAssign) {
                this.loadExpertAvailability();
                this.pollForExpertAvailability();
            }
            return;
        }

        this.reactToNeglectedItems();
        if (cameFromLogin) {
            await this.setExpertAvailability({
                status: isInitiallyAvailable ? Status.AVAILABLE : Status.UNAVAILABLE,
                statusReason: null,
                source: 'login',
            });
        }
        this.loadExpertAvailability();
        this.pollForExpertAvailability();
        this.reactToExpertBecameActiveInClient();
        this.reactToExpertReachLimit();
    }

    private reactToExpertBecameActiveInClient = () => {
        reaction(
            () => this.isFrontEndActive,
            isActive => {
                if (isActive) {
                    this.loadExpertAvailability();
                }
            }
        );
    };

    private reactToExpertReachLimit = () => {
        reaction(
            () => this.limitReached,
            limitReached => {
                if (limitReached) {
                    this.cancelExpertWaitingForSession();
                }
            }
        );
    };

    private reactToNeglectedItems = () => {
        this.deletedItemsSubscription = deletedSupportItems$.subscribe(deletedItems => {
            deletedItems.some(item => {
                if (item.wasUnassignedByNeglection && item.isAssignedToMe) {
                    this.setExpertAvailability({
                        status: Status.UNAVAILABLE,
                        statusReason: null,
                        source: 'neglection',
                    });
                }
            });
        });
    };

    setClientActivness = isActive => {
        runInAction(() => {
            this.isFrontEndActive = isActive;
        });
    };
    private handlePresenceDetector = async (presences: boolean[]) => {
        const [longTermPresence, shortTermPresence] = presences;

        if (this.waitingForSession && !shortTermPresence && longTermPresence) {
            this.shortTermInactive = true;

            setTimeout(() => {
                this.shouldSendPings = false;
            }, this.stopPingsTimeout);

            return;
        }

        this.shortTermInactive = false;

        if (!longTermPresence && this.waitingForSession) {
            return this.cancelExpertWaitingForSession();
        }

        this.shouldSendPings = true;
    };

    private sendPings = () => {
        const pingInterval = getTweekValue('support/feed/expert_availability/ping_interval', 60000);

        this.pingSubscription = Observable.interval(pingInterval)
            .startWith(null)
            .combineLatest(this.longTermPresence, this.shortTermPresence, userProfile$)
            .filter(([, longTermActive, , user]) => longTermActive && user.isLoggedIn && !user.loginRequired)
            .throttleTime(5000)
            .subscribe(async ([, , , user]) => {
                try {
                    if (!this.shouldSendPings) return;
                    await getAvailabilityClient().ping(user.uid, undefined, uuid());
                } catch (err) {
                    logger.warn('failed pinging', {err});
                }
            });
    };

    private reactToExpertBecameInactive = () => {
        const inactiveNotificationHasSound = getTweekValue(
            'support/feed/expert_availability/inactive_notification/sound',
            false
        );

        const makeExpertUnavailableOnInactivity = getTweekValue(
            'support/feed/expert_availability/set_expert_unavailable_on_inactive/is_enabled',
            false
        );
        reaction(
            () => this.isActive,
            isActive => {
                if (isActive) return;
                sendInactiveNotification(inactiveNotificationHasSound);
                if (makeExpertUnavailableOnInactivity) {
                    logger.info('expert is inactive, setting to unavailable', {
                        extra: {waitingForSession: this.waitingForSession, availabilityStatus: this.status},
                    });
                    if (this.status === Status.AVAILABLE) {
                        this.setExpertAvailability({
                            status: Status.UNAVAILABLE,
                            statusReason: null,
                            source: 'inactivity',
                        });
                    }
                }
            }
        );
    };

    setExpertWaitingForSession = async (
        optimistic: boolean = true
    ): Promise<UpdateWaitingForSessionError | undefined> => {
        // Clear + restart interval to avoid race condition between polling and updating
        this.pollForExpertAvailability();

        const previousWaitingForSession = this.waitingForSession;
        const previousWaitingForSessionTimestamp = this.waitingForSessionTimestamp;

        if (optimistic) {
            this.waitingForSession = true;
            this.waitingForSessionTimestamp = new Date();
        }
        try {
            await updateExpertWaitingForSession(this.expertId, {status: true});
            runInAction(() => {
                this.waitingForSession = true;
                this.waitingForSessionTimestamp = new Date();
            });
        } catch (err) {
            runInAction(() => {
                this.waitingForSession = previousWaitingForSession;
                this.waitingForSessionTimestamp = previousWaitingForSessionTimestamp;
            });
            const error = await (err as Response).json();
            if (err.status === 409) {
                const err = error.message as UpdateWaitingForSessionError;
                this.handleConflictFromExpertApi(err);
                return err;
            }
            logger.error('failed setting expert WaitingForSession', {
                err: error,
                extra: {status: true, previousStatus: previousWaitingForSession},
            });
            throw err;
        }
    };

    private handleConflictFromExpertApi = (err: UpdateWaitingForSessionError) => {
        switch (err) {
            case UpdateWaitingForSessionError.unavailable:
                if (this.status === Status.AVAILABLE) {
                    logger.error('expert-api returned unavailable while expert is available');
                    this.loadExpertAvailability();
                }
            case UpdateWaitingForSessionError.inactive:
                if (this.isActive) {
                    logger.error('expert-api returned inactive while expert is active');
                }
        }
    };

    cancelExpertWaitingForSession = async (throwError: boolean = false) => {
        // Clear + restart interval to avoid race condition between polling and updating
        this.pollForExpertAvailability();
        const previousWaitingForSession = this.waitingForSession;
        const previousWaitingForSessionTimestamp = this.waitingForSessionTimestamp;
        this.waitingForSession = false;
        this.waitingForSessionTimestamp = undefined;
        try {
            await updateExpertWaitingForSession(this.expertId, {status: false});
        } catch (err) {
            runInAction(() => {
                this.waitingForSession = previousWaitingForSession;
                this.waitingForSessionTimestamp = previousWaitingForSessionTimestamp;
            });
            logger.error('failed cancelling expert WaitingForSession', {err, extra: {status: false}});
            if (throwError) {
                throw err;
            }
        }
    };

    setExpertAvailability = async ({status, statusReason, source}: ExpertAvailabilityStatus) => {
        if (!this.isTogglingEnabled) return;

        const awayReason = status === Status.AVAILABLE || status === Status.UNAVAILABLE ? null : statusReason;

        if (status === this.status && this.awayReason === awayReason) {
            return;
        }

        // Clear + restart interval to avoid race condition between polling and updating
        this.pollForExpertAvailability();

        try {
            await updateExpertAvailability(this.expertId, {
                isAvailable: status === Status.AVAILABLE,
                unavailableReason: awayReason || '',
            });
        } catch (err) {
            logger.error('could not update expert availability', {err, extra: {status, statusReason, source}});
        }

        runInAction(() => {
            this.status = status;
            this.changedAt = new Date();
            this.statusChangeSource = source;
            this.awayReason = awayReason;
        });
    };

    get expertAvailabilityStatus(): ExpertAvailabilityStatus {
        return {
            status: this.status,
            source: this.statusChangeSource,
            statusReason: this.status === Status.AVAILABLE ? null : this.awayReason,
            limitReached: this.limitReached ?? false,
        };
    }

    get isAvailable() {
        return this.status === Status.AVAILABLE;
    }

    get idleTimeout() {
        return this.timeToIdle - this.timeToPriorIdle;
    }

    get isShortTermInactive() {
        return this.shortTermInactive;
    }

    loadExpertAvailability = async () => {
        try {
            const {availability, isActive, waitingForSession, waitingForSessionTimestamp} = await getExpert(
                this.expertId
            );

            if (this.waitingForSession && !waitingForSession) {
                logger.error('local waitingForSession is not equal to remote waitingForSession', {
                    extra: {localWaitingForSession: this.waitingForSession, remoteWaitingForSession: waitingForSession},
                });
            }

            runInAction(() => {
                this.changedAt = availability?.changedAt ? new Date(availability.changedAt) : new Date();
                this.awayReason = availability?.unavailableReason || null;
                this.isActive = isActive;
                this.waitingForSession = waitingForSession;
                this.waitingForSessionTimestamp =
                    waitingForSessionTimestamp && !!waitingForSession
                        ? new Date(waitingForSessionTimestamp)
                        : undefined;
                this.status = availability?.isAvailable
                    ? Status.AVAILABLE
                    : availability?.unavailableReason
                    ? Status.AWAY
                    : Status.UNAVAILABLE;
                this.limitReached = availability?.limitReached;
            });
        } catch (err) {
            if (err.status === 403) {
                logger.warn('failed fetching expert availability, unauthorized', {err});
                return;
            }
            logger.error('failed fetching expert availability, unexpected error', {err});
        }
    };

    private pollForExpertAvailability = () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        intervalId = setInterval(this.loadExpertAvailability, POLLING_INTERVAL);
    };

    dtor() {
        if (intervalId) {
            clearInterval(intervalId);
        }
        this.deletedItemsSubscription && this.deletedItemsSubscription.unsubscribe();
        this.pingSubscription && this.pingSubscription.unsubscribe();
    }
}
export default new ExpertAvailabilityStore();

export type ExpertAvailabilityStoreType = ExpertAvailabilityStore;
