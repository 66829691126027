import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

const initialStateImgSrc = require('./resources/initialState.png');

const Image = styled.img`
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};
    width: 150px;
    height: 150px;
    margin-bottom: 24px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
`;

const Text = styled(CoreText)`
    width: 185px;
    text-align: center;
    color: ${({theme}) => theme.colors.neutral.black};
`;

const InitialState = () => (
    <Container data-test-id="helix-initial">
        <Image src={initialStateImgSrc} />
        <Text size="s">Search Helix for relevant articles and help your customer</Text>
    </Container>
);

export default InitialState;
