import React from 'react';
import styled from 'styled-components';
import IndicatorIcon from '../../../../resources/IndicatorIcon';

const IndicatorContainer = styled.div`
    margin-left: 0.3em;
    display: flex;
    align-items: center;
    justify-items: center;
`;

const Indicator = () => (
    <IndicatorContainer>
        <IndicatorIcon color="#4A68F9" />
    </IndicatorContainer>
);

export default Indicator;
