import React from 'react';
import styled from 'styled-components';
import {getScrollerStyle, CoreText} from '@anywhere-expert/base-ui';
import {useDetectOutsideClick} from '@anywhere-expert/utils';

const Container = styled.div`
    position: relative;
    height: 100%;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-direction: column;
`;

const SubtitleContainer = styled.div`
    margin-top: 16px;
`;

const DefaultChildrenContainer = styled.div<{addChildrenSpace: boolean}>`
    position: relative;
    flex: 1;
    overflow: hidden overlay;
    ${({theme}) => getScrollerStyle(theme)};
    ${({addChildrenSpace}) => (addChildrenSpace ? 'margin-left: 8px;' : '')};
`;

const Header = styled.div<{addTitleSpace: boolean}>`
    position: relative;
    flex: none;
    ${({addTitleSpace}) => (addTitleSpace ? 'margin-bottom: 24px;' : '')};
`;

const Text = styled(CoreText)`
    font-family: Apercu Pro;
    font-weight: 300;
    letter-spacing: -0.5px;
    color: ${({theme}) => theme.colors.neutral.black};
    font-size: 24px;
    line-height: initial;
`;
type Props = {
    title?: string;
    subTitle?: string | JSX.Element;
    addTitleSpace?: boolean;
    addChildrenSpace?: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    ChildrenContainer?: React.ComponentType<any>;
    onClickedOut?: () => void;
    'data-test-id'?: string;
};

const SidebarTabContainer = ({
    title,
    subTitle,
    onClickedOut,
    addTitleSpace = false,
    addChildrenSpace = false,
    children,
    style,
    className,
    ChildrenContainer = DefaultChildrenContainer,
    'data-test-id': testId,
}: Props) => {
    const tabRef = useDetectOutsideClick(onClickedOut);

    return (
        <Container ref={tabRef} style={style} className={className} data-test-id={testId}>
            {(title || subTitle) && (
                <Header addTitleSpace={addTitleSpace}>
                    <Text>{title}</Text>
                    {subTitle && <SubtitleContainer>{subTitle}</SubtitleContainer>}
                </Header>
            )}
            <ChildrenContainer addChildrenSpace={addChildrenSpace}>{children}</ChildrenContainer>
        </Container>
    );
};

export default SidebarTabContainer;

export {DefaultChildrenContainer as ChildrenContainer};
