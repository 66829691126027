import {CoreTooltipProps} from '@anywhere-expert/base-ui';

export type TagIconType = 'dollar-icon' | 'partner-icon';

export type TagStyle = {
    backgroundColor?: string;
    textColor?: string;
    iconColor?: string;
    fontWeight?: 'normal' | 'medium' | 'bold'; // https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight (use "Common weight" names)
};

export type Tag = {
    tagKey: string;
    tagType: string;
    iconType?: TagIconType;
    displayText?: string;
    tagStyle?: TagStyle;
    source?: string;
};

export type RenderPropsType = {tags: Tag[]; sessionId: string; style?: any};

export type TagsPropsType = RenderPropsType & {
    wrap?: boolean;
    configuration?: {
        tagsOrder: string[];
    } & any;
    onIconClick: (tag: Tag) => void;
    isEditable: boolean;
    tooltipPosition?: CoreTooltipProps['position'];
};

export enum CustomerEligibilityStatus {
    Eligible = 'ELIGIBLE',
    Unknown = 'UNKNOWN',
    Ineligible = 'INELIGIBLE',
}
export enum SalesType {
    Sales = 'salesTag',
}
export enum TagSource {
    AsyncTaggerSales = 'AsyncTaggerSales',
}

export type SalesStatusToTag = Record<CustomerEligibilityStatus, Tag>;
export type SalesStatusToText = Record<CustomerEligibilityStatus, string>;
