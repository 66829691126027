import {observer} from 'mobx-react';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import Attribute from './Attribute';
import React from 'react';
import {AmazonPrePurchaseDetails} from '@anywhere-expert/expert-feed-store';
import LinkAttribute from './LinkAttribute';

export default observer(({amazonPrePurchaseDetails}: {amazonPrePurchaseDetails: AmazonPrePurchaseDetails}) => {
    return (
        <LoggingErrorBoundary boundaryName="AmazonPrePurchaseAttributes">
            {amazonPrePurchaseDetails.searchText && (
                <Attribute name="Search Query" value={amazonPrePurchaseDetails.searchText} defaultValue="Unknown" />
            )}
            {amazonPrePurchaseDetails.searchUrl && (
                //TODO: New iframe
                <LinkAttribute
                    name="Search results"
                    textValue={'See results on Amazon'}
                    defaultValue="Unknown"
                    url={amazonPrePurchaseDetails.searchUrl}
                />
            )}
            {amazonPrePurchaseDetails.prime !== undefined && (
                <Attribute
                    name="Prime status"
                    value={amazonPrePurchaseDetails.prime ? 'Prime' : 'Non-prime'}
                    defaultValue="Unknown"
                    enableCopy={false}
                />
            )}
            {amazonPrePurchaseDetails.postalCode && (
                <Attribute name="Postal code" value={amazonPrePurchaseDetails.postalCode} defaultValue="Unknown" />
            )}
        </LoggingErrorBoundary>
    );
});
