import React, {useState, useEffect} from 'react';

const CurrentTime = () => {
    const [time, setTime] = useState(getCurrentTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getCurrentTime());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return <>{time}</>;
};

export default CurrentTime;

function getCurrentTime() {
    return new Date().toLocaleString('en-US', {hour: '2-digit', minute: '2-digit'});
}
