import SidebarStore from '../SidebarStore';

const useShortcutToggleSidebar = dispatcher => {
    return {
        id: 'toggle-sidebar',
        handler: (e: KeyboardEvent) => {
            e.preventDefault();
            dispatcher.withExtra('ActionId', 'KeyboardShortcut_ToggleSidebar').dispatch('Click');
            SidebarStore.toggleIsSidebarPinned();
        },
    };
};

export default useShortcutToggleSidebar;
