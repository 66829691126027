import {useCallback} from 'react';
import performance from '../performance';
import {useTweekValue} from 'react-tweek';
import {extractMetricTagFromTweek, generatePathName, writeManualMetric, writeCustomMetric} from './utils';
import {TweekMetricTag, PerformanceType, Start, End, Mark} from './types';

export default function usePerformance(): PerformanceType {
    const metricTags = useTweekValue<TweekMetricTag>('support/performance/metrics/stories/_', {});

    const start = useCallback(({tag, identifier}: Start) => {
        performance.start(generatePathName(tag.name, identifier));
    }, []);

    const end = useCallback(
        ({tag, identifier, extraData}: End) => {
            const metric = extractMetricTagFromTweek(metricTags, tag.name);
            const threshold = metric?.threshold ?? tag.thresholdInMs;
            writeManualMetric(tag, threshold, identifier, extraData);
        },
        [metricTags]
    );

    const mark = useCallback(
        ({tag, identifier, value}: Mark) => {
            const metric = extractMetricTagFromTweek(metricTags, tag.name);
            const threshold = metric?.threshold ?? tag.thresholdInMs;
            writeCustomMetric(tag, threshold, value, identifier);
        },
        [metricTags]
    );

    return {start, end, mark};
}
