import performance from '../performance';
import {writeMetrics, writeThresholdLog} from '../writers';
import {fullstory} from '@anywhere-expert/fullstory';
import {TweekMetricTag, MeasurementTag} from './types';

export const generatePathName = (tagName: string, id?: string) => (id ? `${tagName}_${id}` : tagName);

export const writeManualMetric = (
    tag: MeasurementTag,
    threshold: number,
    identifier?: string,
    extraData?: any
): void => {
    const pathName = generatePathName(tag.name, identifier);
    const hasMeasurementStarted = performance.isMeasurementOnGoing(pathName);

    if (hasMeasurementStarted) {
        const measurement = performance.end(pathName);
        if (!measurement) {
            return;
        }
        writeMetrics(tag.name, measurement.duration);

        if (measurement.duration > threshold) {
            const fullStoryUrl = fullstory.getCurrentSessionURL();
            writeThresholdLog(tag, identifier, {
                fullStoryUrl,
                threshold,
                duration: measurement.duration,
                ...extraData,
            });
        }
    }
};

export const writeCustomMetric = (tag: MeasurementTag, threshold: number, value: number, identifier?: string) => {
    writeMetrics(tag.name, value);

    if (value > threshold) {
        const fullStoryUrl = fullstory.getCurrentSessionURL();
        writeThresholdLog(tag, identifier, {
            fullStoryUrl,
            threshold,
            duration: value,
        });
    }
};

export const extractMetricTagFromTweek = (metricTags: TweekMetricTag, tagName: string) =>
    metricTags && metricTags[tagName];
