import React, {useMemo, useCallback} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {useTheme} from '@anywhere-expert/colors';
import {CoreIcon, CoreText} from '@anywhere-expert/base-ui';
import {AssignmentState} from '../types';
import {mapMessages} from '../behaviours/mapMessages';
import {AssignStore} from '../store/AssignStore';
import {getBorderStyle} from '@anywhere-expert/base-ui';

const ContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    margin: 16px 16px 16px 0px;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex: 1;
    position: absolute;
    bottom: 0px;
    max-width: inherit;
    right: 0;
    left: 0;
    height: fit-content;
    min-height: 73px;
    border-radius: 12px;
    margin: 8px 9px 8px 9px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    ${({theme}) => getBorderStyle(theme)};
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
`;

const IconContainer = styled(CoreIcon)`
    margin: 16px 12px 16px 16px;
`;

const StyledText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.black};
    line-height: 20px !important;
    margin-bottom: 4px;
`;

const StyledSubText = styled(CoreText)`
    color: ${({theme}) => theme.colors.contrast.secondary};
`;

type Props = {
    assignStore: AssignStore;
    assignmentState: AssignmentState;
};

const BaseAssignMessage = ({assignStore, assignmentState}: Props) => {
    const analytics = useAnalytics();
    const theme = useTheme();
    const messageData = useMemo(() => mapMessages[assignmentState], [assignmentState]);

    const Icon = useMemo(() => messageData?.icon, [messageData]);
    const onClick = useCallback(() => messageData?.onClick?.(assignStore, analytics.dispatcher), [
        assignStore,
        messageData,
    ]);
    if (!messageData) return null;
    return (
        <Container onClick={onClick} data-test-id={`AutoAssignMessage_${assignmentState}`}>
            {!!Icon && <IconContainer icon={Icon} color={theme.colors.neutral.black} />}
            <ContentContainer>
                <StyledText size="l" weight="bold">
                    {messageData.text}
                </StyledText>
                {messageData.subText && <StyledSubText size="m">{messageData.subText}</StyledSubText>}
            </ContentContainer>
        </Container>
    );
};

export default BaseAssignMessage;
