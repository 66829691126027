import {observable, action, computed, reaction, makeObservable} from 'mobx';
import {SupportItem} from '..';
import {setAppMessage} from '@anywhere-expert/app-messages';

class AutopilotAttributeFields {
    hasMessageSendingStarted: boolean;
    timeToStartSendingMessages: number;
    timeToResolve: number;
}

export function isAutopilotAttributeFields(object: any): object is AutopilotAttributeFields {
    if (!object) {
        return false;
    }
    return (
        typeof object.hasMessageSendingStarted === 'boolean' &&
        typeof object.timeToStartSendingMessages === 'number' &&
        typeof object.timeToResolve === 'number'
    );
}

export class QueueItemAutopilotModel {
    isCanceling: boolean = false;
    isLearnMoreModalOpen: boolean = false;

    hadAutopilotAttributes: boolean = false;

    private readonly supportItem: SupportItem;

    constructor(parent: SupportItem) {
        makeObservable(this, {
            isCanceling: observable,
            isLearnMoreModalOpen: observable,
            setIsCanceling: action,
            attributes: computed,
        });

        this.supportItem = parent;
        if (this.supportItem.isAutopilot) {
            this.hadAutopilotAttributes = true;
        }

        reaction(
            () => parent.isAutopilot,
            (newValue, oldValue) => {
                if (parent.isAssignedToMe && oldValue === false && newValue === true) {
                    this.hadAutopilotAttributes = true;
                    setAppMessage({
                        text: 'An unresponsive customer was moved to Autopilot.',
                        actionable: true,
                        actionButtonType: 'seeSession',
                        type: 'autopilot',
                        onAppMessageClosed: wasShowSessionButtonClicked => {
                            if (wasShowSessionButtonClicked) {
                                parent.store.selectSession(parent.sessionId);
                            }
                        },
                    });
                }
            }
        );
    }

    setIsCanceling(value: boolean) {
        this.isCanceling = value;
    }

    get attributes() {
        return this.supportItem.sessionAttributes['autopilot']?.['autopilot']?.fields as
            | AutopilotAttributeFields
            | undefined;
    }
}
