import React, {useCallback} from 'react';
import {AssignmentState} from '../types';
import {AssignStore} from '..';
import {ExpertAvailabilityStore, Status} from '@expert-feed/expert-availability';
import {useAnalytics} from 'react-shisell';
import {setAppMessage} from '@anywhere-expert/app-messages';
import LowVolumeComponent from './LowVolumeComponent';
import WaitingForSessionComponent from './WaitingForSessionComponent';
import {observer} from 'mobx-react';
import BaseAssignMessage from './BaseAssignMessage';
import type {AssignmentType} from '@anywhere-expert/expert-feed-store';
import InactiveComponent from './InactiveComponent';
import AvailableWaitingForPush from './AvailableWaitingForPush';

type Props = {
    assignmentState: AssignmentState;
    assignStore: AssignStore;
    assignMethod: AssignmentType;
};

const AssignMessage = observer(({assignmentState, assignStore, assignMethod}: Props) => {
    const analytics = useAnalytics();
    const {waitingForSessionTimestamp} = ExpertAvailabilityStore;

    const getWaitingTimeInSeconds = useCallback(() => {
        return waitingForSessionTimestamp && (Date.now() - waitingForSessionTimestamp!.getTime()) / 1000;
    }, [waitingForSessionTimestamp]);

    const cancelFunc = useCallback(async () => {
        analytics.dispatcher
            ?.withExtras({
                ActionId: 'StopWaitingForSession',
                WaitingTime: getWaitingTimeInSeconds(),
                State: assignmentState,
            })
            .dispatch('Click');

        try {
            if (assignStore.isPush) {
                await ExpertAvailabilityStore.setExpertAvailability({
                    status: Status.UNAVAILABLE,
                    statusReason: null,
                    source: 'inactivity',
                });

                return;
            }

            await ExpertAvailabilityStore.cancelExpertWaitingForSession(true);
        } catch (err) {
            setAppMessage({text: 'could not cancel, try again', type: 'warning', duration: 5000});
        }
    }, [assignmentState, getWaitingTimeInSeconds, assignMethod]);

    switch (assignmentState) {
        case AssignmentState.SHORT_TERM_INACTIVE:
            return (
                <InactiveComponent
                    cancelFunc={cancelFunc}
                    assignStore={assignStore}
                    getWaitingTimeInSeconds={getWaitingTimeInSeconds}
                    dispatcher={analytics.dispatcher}
                />
            );
        case AssignmentState.AVAILABLE_WAITING_PUSH:
            return <AvailableWaitingForPush />;
        case AssignmentState.LOW_VOLUME:
            return (
                <LowVolumeComponent
                    cancelFunc={cancelFunc}
                    assignStore={assignStore}
                    getWaitingTimeInSeconds={getWaitingTimeInSeconds}
                    dispatcher={analytics.dispatcher}
                />
            );
        case AssignmentState.AWAITING:
            return (
                <WaitingForSessionComponent
                    skipTransition={assignStore.shouldSkipLoader}
                    hide={assignMethod === 'push'}
                    loadingDuration={assignStore.getNextFirstLoadingDurationInMs}
                    cancelFunc={cancelFunc}
                />
            );
        default:
            return <BaseAssignMessage assignmentState={assignmentState} assignStore={assignStore} />;
    }
});

export default AssignMessage;
