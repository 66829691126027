import React, {forwardRef, useCallback, ReactElement} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Editor, EditorState, getDefaultKeyBinding} from 'draft-js';
import RichEditorToolbar from './RichEditorToolbar';
import {RichInputStore} from '../stores/RichInputStore';
import RichEditorContainer from './RichEditorContainer';
import 'draft-js/dist/Draft.css';
import {useAnalytics} from 'react-shisell';
import {TOOL_KEY_SHORTCUT} from '../consts/editorKeyShortcuts';

const RichEditorComponent = forwardRef<Editor, any>(({className, ...props}, editorRef) => {
    return <Editor className={className} ref={editorRef} {...props} />;
});
const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
`;

const Container = styled.div<{disabled?: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 8px;
    border-radius: 8px;
    outline: none;
    background-color: ${({theme}) => theme.colors.neutral.white};
    width: 100%;
    ${({disabled}) =>
        disabled &&
        `
        opacity: 0.2;
        pointerEvents: none;
    `};
`;

type RichInputProps = {
    placeholder: string;
    children?: ReactElement;
    onBlur?: () => void;
    onEnterPressed?: (e: React.KeyboardEvent) => void;
    onTextChanged?: (s: string) => void;
    dataTestId?: string;
    disabled?: boolean;
    richInputStore: RichInputStore;
    onPaste?: (text: string) => void;
};

const RichEditor = observer(
    forwardRef(
        (
            {
                placeholder,
                richInputStore,
                onBlur,
                onEnterPressed,
                onTextChanged,
                dataTestId,
                disabled,
                children,
                onPaste: handleOnPaste,
                ...rest
            }: RichInputProps,
            editorRef
        ) => {
            const analytics = useAnalytics();

            const onEditorClick = useCallback(() => {
                analytics.dispatcher.withExtras({ActionId: 'TextBox'}).dispatch('Click');
            }, [analytics]);

            const onEditorChange = useCallback(
                (state: EditorState) => {
                    richInputStore.setEditorState(state);
                    const plainText = state.getCurrentContent().getPlainText();
                    onTextChanged?.(plainText);
                },
                [richInputStore, onTextChanged]
            );

            const onPaste = useCallback(
                (text: string) => {
                    analytics.dispatcher.withExtra('Value', text).dispatch('Paste');
                    handleOnPaste?.(text);
                },
                [analytics]
            );

            const keyBindingFn = useCallback(
                (e: React.KeyboardEvent) => {
                    if (e.key === TOOL_KEY_SHORTCUT['Strikethrough']) {
                        if (e.nativeEvent.metaKey || e.ctrlKey) {
                            return 'STRIKETHROUGH';
                        }
                    }

                    if (e.key !== 'Enter' || e.shiftKey) {
                        return getDefaultKeyBinding(e);
                    }

                    if (!!onEnterPressed) {
                        onEnterPressed(e);
                        return;
                    }
                    return getDefaultKeyBinding(e);
                },
                [onEnterPressed]
            );

            return (
                <Container disabled={disabled} {...rest}>
                    <RichEditorContainer>
                        <RichEditorComponent
                            editorState={richInputStore.editorState}
                            onChange={onEditorChange}
                            toolbarHidden
                            spellCheck
                            editorRef={editorRef}
                            onBlur={onBlur}
                            readOnly={disabled}
                            onFocus={onEditorClick}
                            handlePastedText={onPaste}
                            handleKeyCommand={richInputStore.handleKeyCommand}
                            keyBindingFn={keyBindingFn}
                            placeholder={richInputStore.hasAnyStyle ? '' : placeholder}
                            webDriverTestID={dataTestId}
                        />
                    </RichEditorContainer>
                    <ButtonsContainer>
                        <RichEditorToolbar richInputStore={richInputStore} />
                        {children}
                    </ButtonsContainer>
                </Container>
            );
        }
    )
);

export default RichEditor;
