import React, {useEffect, memo, useCallback} from 'react';
import {HelixArticleItem} from '../types';
import {CoreText, CoreButton, CoreHighlightedText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import ArticleType from './ArticleType';
import {ArrowRightIcon} from '@anywhere-expert/icons';
import {getHelixUrl} from './shared';
import {AnalyticsDispatcher} from 'shisell';

const Header = styled.div`
    display: block;
    margin-bottom: 8px;
`;

const Content = styled.div`
    margin-bottom: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
`;

const ArticleContainer = styled.div``;

const Separator = styled.hr`
    border: 0;
    height: 1px;
    background-color: ${({theme}) => theme.colors.contrast.border};
    margin: 0;
    margin-top: 10px;
    margin-bottom: 24px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const StyledCoreButton = styled(CoreButton)`
    padding-left: 0;
    text-transform: none;
`;

const Article = memo(
    ({
        title,
        article_id,
        snippet,
        type,
        date_modified,
        shouldShowSeparator,
        dispatcher,
        searchedText,
    }: HelixArticleItem & {
        shouldShowSeparator: boolean;
        dispatcher: AnalyticsDispatcher;
        searchedText: string;
    }) => {
        useEffect(() => {
            dispatcher.dispatch('View');
        }, []);

        const onClickCallback = useCallback(() => {
            window.open(getHelixUrl(article_id, type));
            dispatcher.withExtra('Action', 'open').dispatch('Click');
        }, [getHelixUrl, dispatcher, type]);

        return (
            <>
                <ArticleContainer>
                    <ArticleType type={type} />
                    <Header>
                        <CoreHighlightedText size="l" weight="bold" text={title} searchedText={searchedText} />
                    </Header>
                    <Content>
                        <CoreHighlightedText textType="secondary" size="m" text={snippet} searchedText={searchedText} />
                    </Content>
                    <Footer>
                        <StyledCoreButton
                            variant="text"
                            icon={ArrowRightIcon}
                            color="tertiary"
                            size="s"
                            onClick={onClickCallback}
                            text="Go to article"
                        />
                        <CoreText size="s" textType="tertiary">
                            {date_modified}
                        </CoreText>
                    </Footer>
                </ArticleContainer>
                {shouldShowSeparator && <Separator />}
            </>
        );
    }
);

export default Article;
