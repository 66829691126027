import React, {FunctionComponent, lazy, Suspense} from 'react';
import CobrowsingStore from './stores/CobrowsingStore';

type CobrowsingSectionRendererProps = {
    cobrowsingStore: CobrowsingStore;
};

const LazyCobrowsingSection = lazy(() => import('./CobrowsingSection'));

const CobrowsingSectionRenderer: FunctionComponent<CobrowsingSectionRendererProps> = ({cobrowsingStore}) => (
    <Suspense fallback={null}>
        <LazyCobrowsingSection cobrowsingStore={cobrowsingStore} />
    </Suspense>
);

export default CobrowsingSectionRenderer;
