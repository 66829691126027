/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {BaseIcon, IconProps} from '@anywhere-expert/icons';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M4.274 10.814a6.707 6.707 0 0113.414 0h1.917a8.624 8.624 0 10-8.624 8.624v-1.916a6.707 6.707 0 01-6.707-6.708zm6.706-3.832a3.833 3.833 0 100 7.666v1.916a5.75 5.75 0 115.749-5.75h-1.916a3.834 3.834 0 00-3.833-3.833zm1.616 4.028a1.918 1.918 0 10-3.768-.497 1.916 1.916 0 002.413 1.852l3.617 3.617-.678.678 5.42 5.42 2.71-2.71-5.42-5.42-.677.676-3.617-3.616z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
