/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {BaseIcon, IconProps} from '@anywhere-expert/icons';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M5.875 11.784l9-5.197a.25.25 0 01.375.217v10.392a.25.25 0 01-.375.217l-9-5.197a.25.25 0 010-.432z"
                stroke={color}
                fill="none"
                strokeWidth="1.5"
            />
        </BaseIcon>
    ))
);

export default Icon;
