import React from 'react';

const StarIcon = ({color}) => (
    <svg width="60" height="45" viewBox="0 0 60 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.3113 34.0181C23.7308 39.6766 29.8608 43.2711 36.0062 43.4875C49.4412 43.9513 61.0595 31.4285 57.4032 18.0632C55.2851 10.3253 48.1811 4.12578 40.5747 2"
            stroke={color || 'black'}
            strokeWidth="2.45"
            strokeMiterlimit="1.2063"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.1173 25.074C42.2437 29.0214 52.8164 17.6911 43.469 8.33496"
            stroke={color || 'black'}
            strokeWidth="2.45"
            strokeMiterlimit="1.2063"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.088 31.6292C19.0156 29.975 19.7886 28.3208 20.4302 27.0685C20.4611 27.0066 21.3965 27.3313 21.5279 27.3931C26.6375 28.3594 32.1336 30.7094 37.3128 30.9181C37.0422 29.9132 35.8286 29.2097 35.1097 28.4444C32.5974 25.8162 30.9509 24.1929 28.5314 21.526C28.4231 21.441 27.7197 20.954 27.8511 20.552C28.1758 19.6167 28.2763 19.3461 29.3044 17.545C30.3325 15.7439 31.3606 13.1775 32.0022 11.8865C32.6438 10.5956 32.868 9.45929 32.8061 9.42837C32.3732 9.2042 30.7576 10.1937 30.3789 10.3096C28.485 11.2295 23.9629 13.4944 22.2313 13.9428C21.5975 14.0974 19.6495 11.5928 19.2166 11.214C18.4668 10.5183 16.2869 7.65044 15.2742 8.08333C14.5476 8.42345 14.4084 10.2323 14.3002 10.8971C13.9988 12.5591 13.9833 17.0425 12.0894 17.8078C9.78584 18.7586 7.51319 19.578 5.1787 20.4283C4.58348 20.6679 2.16396 21.3095 2.00162 21.9357C1.93205 22.2217 4.11967 23.4972 4.30519 23.5977C6.11404 24.6799 6.99528 24.9041 8.98964 25.6152C9.46118 25.7776 11.1232 26.079 11.4324 26.5506C11.6411 27.0607 11.5328 29.6426 11.4324 30.1528C11.1773 32.982 11.27 34.4198 11.0922 36.9553C11.0922 37.2645 11.0227 37.5428 11.1154 37.8288C12.4141 39.2357 16.3564 34.6904 18.088 31.6292Z"
            stroke={color || 'black'}
            strokeWidth="2.45"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M43.2003 33.4259C46.5659 32.202 47.0759 31.7941 49.2172 29.8981C51.3585 28.0022 52.0619 25.3219 52.5566 22.4463"
            stroke={color || 'black'}
            strokeWidth="2.45"
            strokeMiterlimit="1.2063"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default StarIcon;
