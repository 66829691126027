import React, {FunctionComponent, Suspense} from 'react';
import {observer} from 'mobx-react';
import {useKeyboardNavigation} from '../useSearchResultsBehaviors';
import Results from './Results';
import ResultsNavigator from './ResultsNavigator';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {usePerformance, TYPE_TIMELINE_SEARCH_RESULTS_LOAD} from '@anywhere-expert/performance';
import {useTheme} from '@anywhere-expert/colors';
import {Spinner} from '@anywhere-expert/base-ui';
import {useTimelineSearchStore} from '../useTimelineSearchStore';

const InitialStateIcon = React.lazy(() => import('./InitialStateIcon'));
const EmptyStateIcon = React.lazy(() => import('./EmptyStateIcon'));

type Props = {
    onMessageSelection: (messageId: string, text: string) => void;
};

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    align-items: center;
`;

const SearchResults: FunctionComponent<Props> = observer(({onMessageSelection}: Props) => {
    const theme = useTheme();
    const performance = usePerformance();
    const timelineSearchStore = useTimelineSearchStore();
    const iconColor = theme.colors.neutral.black;
    useKeyboardNavigation();

    React.useEffect(() => {
        window.requestAnimationFrame(() => {
            performance.end({
                tag: TYPE_TIMELINE_SEARCH_RESULTS_LOAD,
                extraData: {numOfItems: timelineSearchStore.messages?.length},
            });
        });
    }, [timelineSearchStore]);

    if (!timelineSearchStore.messages?.length) {
        return (
            <IconContainer>
                {!timelineSearchStore.searchTerm ? (
                    <Suspense fallback={<Spinner size="l" spinnerColor="inherit" />}>
                        <InitialStateIcon color={iconColor} />
                    </Suspense>
                ) : (
                    <Suspense fallback={<Spinner size="l" spinnerColor="inherit" />}>
                        <EmptyStateIcon color={iconColor} />
                        <CoreText style={{marginTop: '14px'}} size="s">
                            No matching results
                        </CoreText>
                    </Suspense>
                )}
            </IconContainer>
        );
    } else {
        return (
            <>
                <ResultsNavigator />
                <Results onSelectMessage={onMessageSelection} />
            </>
        );
    }
});

export default SearchResults;
