import {IconProps} from '@anywhere-expert/icons';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {CoreTooltipProps} from '@anywhere-expert/base-ui';

export interface TabProps {
    selectedSession: SupportItem;
}

export enum TabType {
    // Constant tab - will appear above the separator. Always visible and cannot be closed.
    Constant,
    // Pinned tab - will appear below the separator. Visible if previously opened and not closed since. Can be closed.
    Pinned,
    // Disposable tab - will appear at the bottom of the tab icons. Visible only while the tab is selected.
    // Once another tab is selected or the tab action was completed, tab is closed.
    Disposable,
}

export type TabId =
    | 'toolbox'
    | 'notes'
    | 'device-tools'
    | 'canned-messages'
    | 'messaging-sales'
    | 'helix-search'
    | 'tech-view'
    | 'view-as'
    | 'similar-sessions'
    | 'visual-guide'
    | 'timeline-search';

export type Tab = {
    Content: React.ComponentType<TabProps>;
    Icon: React.ComponentType<IconProps>;
    IndicatorIcon?: React.ComponentType;
    type: TabType;
    tooltipText: string;
    tooltipProps?: Partial<CoreTooltipProps>;
    getDisabledText?: (selectedSession: SupportItem) => string | undefined;
    minWidth?: string;
    maxWidth?: string;
};

export type TabsConfig = {[key: string]: Tab};
