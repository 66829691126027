import React, {memo, useMemo} from 'react';
import styled from 'styled-components';
import {HelixArticleItem} from '../types';
import Articles from './Articles';
import {CoreText} from '@anywhere-expert/base-ui';

const RelevantForText = styled(CoreText)`
    margin-top: 16px;
    margin-left: 8px;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
`;

type Props = {
    deviceModel: string | undefined;
    articlesPreview: HelixArticleItem[];
    searchTerm: string;
};

const RelevantFor = memo(({deviceModel, articlesPreview, searchTerm}: Props) => {
    const articles = useMemo(() => articlesPreview.slice(0, 5), [articlesPreview]);
    return (
        <>
            <RelevantForText size="s" weight="bold">
                Relevant for {deviceModel}
            </RelevantForText>
            <Articles articles={articles} isUserSearch={false} searchTerms={searchTerm} />
        </>
    );
});

export default RelevantFor;
