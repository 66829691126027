import {palette} from '@anywhere-expert/colors';
import {OfferSelection, ConclusionState} from '../types';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {setAppMessage, AppMessageData} from '@anywhere-expert/app-messages';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import EnrollmentAlertToast from '../components/sales-tab/sales-tools/enrollment-flow/EnrollmentAlertToast';
import {getTweekValue} from '@anywhere-expert/tweek';
import {defaultEnrollmentConclusionContent} from '../consts';
import {getNameFromAttributes} from '@anywhere-expert/expert-feed-store';
import OfferDetectionAlertToast from '../components/sales-tab/sales-tools/sales-offer/OfferDetectionAlertToast';
import {SidebarStore} from '@anywhere-expert/session-sidebar';

export const getRandomArrayElement = <T>(array: T[]): T | undefined => {
    if (!array.length) {
        return undefined;
    }

    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};

export const celebrateSale = (offerSelection: OfferSelection) => {
    if (offerSelection !== OfferSelection.OfferAccepted) {
        return;
    }

    const celebratoryMessages = getTweekValue(MessagingSalesTweekKeys.CelebratoryMessages, []);
    const randomCelebration = getRandomArrayElement(celebratoryMessages);

    if (randomCelebration) {
        setAppMessage({text: randomCelebration, type: 'success'});
    }
};

const getSessionAlias = (sessionName: string | undefined, enrollingSessionId: string) =>
    sessionName || enrollingSessionId;

const getToastText = (session: SupportItem, conclusionState: ConclusionState): string => {
    const {feedItemAttributes, sessionAttributes, sessionId} = session;
    const sessionName = getNameFromAttributes(feedItemAttributes, sessionAttributes);
    const sessionAlias = getSessionAlias(sessionName, sessionId);

    let {toastText} = getTweekValue(
        MessagingSalesTweekKeys.EnrollmentConclusionContent,
        defaultEnrollmentConclusionContent
    )[conclusionState];

    return toastText.replace('<sessionAlias>', sessionAlias);
};

export const notifyExpertOfOfferDetectionIfSalesTabClosed = () => {
    const messageData: AppMessageData = {
        MessageContent: () => OfferDetectionAlertToast({type: 'OfferDetected'}),
        backgroundColor: palette.primary.green.bright,
        textColor: 'black',
    };

    notifyExpertIfSalesTabClosed(messageData);
};

export const notifyExpertOfSuccessfulEnrollment = (session: SupportItem) => {
    const {sessionId} = session;
    const toastText = getToastText(session, ConclusionState.EnrollmentSuccess);

    const messageData: AppMessageData = {
        MessageContent: () =>
            EnrollmentAlertToast({sessionId, text: toastText, type: ConclusionState.EnrollmentSuccess}),
        backgroundColor: palette.primary.green.bright,
        textColor: 'black',
    };

    notifyExpertOfEnrollmentResponse(sessionId, messageData);
};

export const notifyExpertOfFailedEnrollment = (session: SupportItem) => {
    const {sessionId} = session;
    const toastText = getToastText(session, ConclusionState.EnrollmentFailure);

    const messageData: AppMessageData = {
        MessageContent: () =>
            EnrollmentAlertToast({sessionId, text: toastText, type: ConclusionState.EnrollmentFailure}),
        backgroundColor: palette.semantic.red.bright,
        textColor: 'black',
    };

    notifyExpertOfEnrollmentResponse(sessionId, messageData);
};

const notifyExpertOfEnrollmentResponse = (enrollingSessionId: string, messageData: AppMessageData): void => {
    const currentSessionId = ExpertFeedStore.selectedSessionId;

    if (enrollingSessionId !== currentSessionId) {
        setAppMessage(messageData);
    }
};

const notifyExpertIfSalesTabClosed = (messageData: AppMessageData): void => {
    if (SidebarStore.selectedTabId !== 'messaging-sales') {
        setAppMessage(messageData);
    }
};
