import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useAnalytics} from 'react-shisell';

export const useMessagingSalesClickAnalytics = (scope: string): ((extras: object) => void) => {
    const analytics = useAnalytics();

    const selectedSession = ExpertFeedStore.selectedSession;

    const scoped = analytics.dispatcher.createScoped(scope);

    const dispatch = (extras: object) => {
        let enrichedDispatcher = scoped.withExtras(extras);

        if (selectedSession) {
            enrichedDispatcher = enrichedDispatcher.withExtra('CarrierName', selectedSession.pool);
        }

        return enrichedDispatcher.dispatch('Click');
    };

    return dispatch;
};
