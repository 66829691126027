import React from 'react';

const SvgComponent = ({color}) => (
    <svg width="16px" height="16px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.522 0 10 0ZM9.25 15.75L4.25 12L5.75 10L8.75 12.25L14 5.25L16 6.75L9.25 15.75Z"
            fill={color || '#4C5764'}
        />
    </svg>
);

export default SvgComponent;
