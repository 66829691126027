import React, {useMemo, FunctionComponent} from 'react';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import ActivityMessage from '@soluto-private/activity-message-web-component';
import {useTheme} from '@anywhere-expert/colors';
import styled from 'styled-components';

const imageLight = require('./iconLight.svg');
const imageDark = require('./iconDark.svg');

const Container = styled.div`
    align-self: center;
    margin: 32px;
    width: 400px;
    svg {
        stroke: 'blue';
    }
    .cobrowse-icon-arrow {
        fill: 'blue';
    }
`;

type CobrowsingApprovalItemContent = {
    state: string;
    customerName?: string;
};

type CobrowsingApprovalItemProps = TimelineItem<CobrowsingApprovalItemContent>;

const CobrowsingApprovalItem: FunctionComponent<CobrowsingApprovalItemProps> = props => {
    const state = props?.content?.state;
    const theme = useTheme();
    const isDarkMode = theme.themeName === 'dark';
    const customerName = props?.content?.customerName ?? 'the customer';

    const [title, text] = useMemo(() => {
        switch (state) {
            case 'requesting':
                return ['Request was sent', `Waiting for ${customerName} to approve`];
            case 'approved':
                return ['Request was approved', `You are now observing ${customerName}'s page`];
            case 'timeout':
                return ['Request has expired', 'Send a new one to start a Co-browsing session'];
            case 'ended':
                return ['Ended successfully', `You are no longer observing ${customerName}’s page`];
            default:
                return ['', ''];
        }
    }, [state, customerName]);

    return (
        <Container>
            <ActivityMessage
                title={title}
                sectionIndicationText={'Co-browsing'}
                text={text}
                image={imageLight}
                imageDarkMode={imageDark}
                isDarkMode={isDarkMode}
            />
        </Container>
    );
};

export default CobrowsingApprovalItem;
