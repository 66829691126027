import React from 'react';
import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import {User} from '@anywhere-expert/auth';
import {ChatBubblesIcon} from '@anywhere-expert/icons';

const AvatarContainer = styled.div`
    width: 100%;
    padding-top: 65px;
    position: absolute;
`;

const AvatarImg = styled.img`
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin: auto;
    display: flex;
`;

const CustomAvatarContainer = styled.div`
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: ${palette.neutral.grey.lighter};
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 75px;

    svg {
        padding-left: 8px;
        width: 95px;
        height: 95px;
    }
`;

const ExpertAvatar = ({expert}: {expert: User}) => {
    const {uid, imageUrl} = expert;

    if (imageUrl) {
        return (
            <AvatarContainer data-test-id="ExpertAvatar" data-expert-id={uid}>
                <AvatarImg src={imageUrl} />
            </AvatarContainer>
        );
    } else {
        return (
            <CustomAvatarContainer data-test-id="ExpertAvatar" data-expert-id={uid}>
                <ChatBubblesIcon color="black" fill="transparent" />
            </CustomAvatarContainer>
        );
    }
};

export default ExpertAvatar;
