import React from 'react';

const OptionsIcon = ({color, ...others}) => (
    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C12 0.89543 12.8954 -1.35705e-07 14 -8.74228e-08C15.1046 -3.91405e-08 16 0.89543 16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2ZM6 2C6 0.89543 6.89543 -3.97973e-07 8 -3.49691e-07C9.10457 -3.01409e-07 10 0.89543 10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2ZM-8.74228e-08 2C-3.91405e-08 0.89543 0.895431 -6.60242e-07 2 -6.11959e-07C3.10457 -5.63677e-07 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 -1.35705e-07 3.10457 -8.74228e-08 2Z"
            fill={color}
        />
    </svg>
);

export default OptionsIcon;
