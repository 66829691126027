import React, {useCallback, useState, useMemo, useRef, useEffect, FunctionComponent} from 'react';
import styled, {css} from 'styled-components';
import {MessageActionsModal} from './';
import CannedMessagesStoreV2 from '../../stores/CannedMessagesStoreV2';
import {useAnalytics} from 'react-shisell';
import {CannedMessage} from '@soluto-private/canned-messages-api-client';
import {useDrag, useDrop, useDragDropManager} from 'react-dnd';
import throttle from 'lodash.throttle';
import {observer} from 'mobx-react';
import {DraggedMessage} from '../../types';
import MessageText from './MessageText';
import {openModal} from '@anywhere-expert/base-ui';
import {DeleteConfirmationModel} from '../DeleteConfirmationModal';
import {AddCannedMessagesModal} from './AddCannedMessagesModal';

const Container = styled.div<{disabled?: boolean; selected?: boolean; isDragging: boolean}>`
    display: flex;
    position: relative;
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-radius: 8px;
    transition: opacity 0.3s;
    width: 100%;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    cursor: ${props => (props.isDragging ? 'all-scroll !important' : 'pointer')};
    border: 1px solid ${({theme}) => theme.colors.contrast.border};
    box-sizing: border-box;
    border-radius: 8px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    ${({disabled}) =>
        disabled &&
        css`
            cursor: default !important;
            opacity: 0.5;
        `};

    ${({theme, selected}) =>
        selected &&
        css`
            cursor: default;
            background: ${theme.colors.contrast.toolboxItemSelected};
        `};

    ${({theme, disabled, selected}) =>
        !disabled &&
        !selected &&
        css`
            &:hover {
                background: ${theme.colors.contrast.queuePanelHover};
            }
        `};
`;

const MessageContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 6px;
    padding-right: 12px;
`;

const CategoryColor = styled.div<{color: string}>`
    display: flex;
    width: 4px;
    border-radius: 2px;
    margin-left: 6px;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: ${({color}) => color};
`;

type Props = {
    categoryId: string;
    categoryName: string;
    id: string;
    message: CannedMessage;
    categoryColor: string;
    isFocused: boolean;
    onSelectMessage: (categoryId: string, messageId: string, message: CannedMessage) => void;
    removeFocus: () => void;
    searchedText: string;
};

const useAnalyticsWithScope = () => {
    const analytics = useAnalytics();

    return analytics.dispatcher.createScoped('Messages');
};

const Divider = styled.div<{color?: string}>`
    height: 4px;
    width: 100%;
    background-color: ${({color, theme}) => color || theme.colors.primary.purple.bright};
    border-radius: 20px;
`;

const Message: FunctionComponent<Props> = observer(
    ({
        id,
        categoryId,
        categoryName,
        message,
        categoryColor,
        onSelectMessage,
        isFocused,
        removeFocus,
        searchedText,
    }: Props) => {
        const dispatcher = useAnalyticsWithScope();
        const ref = useRef<HTMLDivElement>(null);
        const {text, title} = message;

        const [isOptionsSelected, setIsOptionsSelected] = useState(false);
        const [isHover, setIsHover] = useState(false);
        const [isDraggingInProgress, setIsDraggingInProgress] = useState(false);

        const onCancel = useCallback(() => {
            setIsOptionsSelected(false);
        }, []);

        const onClick = useCallback(
            e => {
                dispatcher
                    .withExtras({
                        MessageId: id,
                        FeatureVersion: 'v2',
                        CategoryId: categoryId,
                        CategoryName: categoryName,
                    })
                    .dispatch(e.key === 'Enter' ? 'Enter' : 'Click');
                e.preventDefault();
                onSelectMessage(categoryId, id, message);
                removeFocus();
            },
            [id, message, dispatcher, onSelectMessage, removeFocus]
        );

        const onActionClick = useCallback(e => {
            setIsOptionsSelected(prev => !prev);
            e.stopPropagation();
        }, []);

        const onCancelEdit = useCallback(() => {
            setIsOptionsSelected(false);
        }, []);

        const onEditSubmit = useCallback(
            async (text: string, newCategoryId: string, title?: string) => {
                const {updatedSuccessfully} = await CannedMessagesStoreV2.updateMessage(categoryId, id, {
                    title,
                    text,
                    newCategoryId,
                });
                dispatcher.withExtras({MessageId: id, Text: text, Title: title}).dispatch('Edit');
                setIsOptionsSelected(false);

                return updatedSuccessfully;
            },
            [id, dispatcher]
        );

        const onEdit = useCallback(() => {
            setIsOptionsSelected(false);
            openModal(AddCannedMessagesModal, {
                id: 'add-message-modal',
                contentProps: {
                    text,
                    title,
                    sourceCategoryId: categoryId,
                    onCancel: onCancelEdit,
                    onSubmit: onEditSubmit,
                },
            });
        }, [text, title, onCancelEdit, categoryId, onEditSubmit]);

        const onDelete = useCallback(() => {
            CannedMessagesStoreV2.deleteMessage(categoryId, id);
            dispatcher.withExtras({MessageId: id}).dispatch('Delete');
        }, [id, categoryId, dispatcher]);

        const openDeleteModal = useCallback(() => {
            const text = `Are you sure you want to delete this message?`;
            const title = 'Delete a message';

            openModal(DeleteConfirmationModel, {
                size: 's',
                id: 'delete-confirmation-modal',
                contentProps: {onDelete, title, text},
            });
        }, [onDelete]);

        const onSetDefaultGreetingMessage = useCallback(() => {
            CannedMessagesStoreV2.setDefaultGreetingMessage(id);
            dispatcher.withExtras({MessageId: id}).dispatch('SetDefaultGreeting');
        }, [id, dispatcher]);

        const isDefaultGreetingMessage = useMemo(() => CannedMessagesStoreV2.defaultGreetingMessageId === id, [
            CannedMessagesStoreV2.defaultGreetingMessageId,
            id,
        ]);

        useEffect(() => {
            if (isFocused) {
                ref?.current?.focus();
            }
        }, [isFocused, ref]);

        const onMouseEnter = useCallback(() => {
            setIsHover(true);
        }, []);

        const onMouseLeave = useCallback(() => {
            setIsHover(false);
        }, []);

        const [, dragRef] = useDrag<DraggedMessage, void, {}>({
            item: {type: 'Message', ...message, id, categoryId},
        });

        const dragDropManager = useDragDropManager();
        useEffect(
            () =>
                dragDropManager.getMonitor().subscribeToStateChange(
                    throttle(() => {
                        const monitor = dragDropManager.getMonitor();
                        const isDragging = monitor.isDragging();

                        setIsDraggingInProgress(isDragging);
                    }, 200)
                ),
            [dragDropManager]
        );

        const analytics = useAnalytics();

        const [{isOver}, dropRef] = useDrop<DraggedMessage, Promise<void>, {isOver: boolean}>({
            accept: 'Message',
            collect: monitor => ({
                isOver: !!monitor.isOver({shallow: true}),
            }),
            drop: async (cannedMessage: DraggedMessage) => {
                if (!CannedMessagesStoreV2.areActionsEnabled || cannedMessage.id === id) {
                    return;
                }

                await CannedMessagesStoreV2.moveMessage(cannedMessage, categoryId, message.displayOrder);

                analytics.dispatcher
                    .createScoped('Categories')
                    .withExtras({
                        MessageId: cannedMessage.id,
                        SourceCategoryId: cannedMessage.categoryId,
                        TargetCategoryId: categoryId,
                        TargetCategoryName: categoryName,
                    })
                    .dispatch('DragAndDrop');
            },
        });

        const shouldShowOptionsIcons = isHover || isOptionsSelected;

        dropRef(dragRef(ref));
        return (
            <>
                {isOver && <Divider color={categoryColor} />}
                <Container
                    ref={ref}
                    tabIndex={0}
                    selected={isFocused}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onKeyPress={onClick}
                    data-test-id={`CannedMessages_${categoryId}_${id}`}
                    isDragging={isDraggingInProgress}
                >
                    <CategoryColor color={categoryColor} />
                    <MessageContainer>
                        <MessageText
                            categoryId={categoryId}
                            messageId={id}
                            title={title}
                            text={text}
                            isDefaultGreetingMessage={isDefaultGreetingMessage}
                            searchedText={searchedText}
                        />
                        {CannedMessagesStoreV2.areActionsEnabled && shouldShowOptionsIcons ? (
                            <MessageActionsModal
                                onClick={onActionClick}
                                open={isOptionsSelected}
                                isDefaultGreetingMessage={isDefaultGreetingMessage}
                                onSetDefaultGreetingMessage={onSetDefaultGreetingMessage}
                                onEdit={onEdit}
                                onDelete={openDeleteModal}
                                onCancel={onCancel}
                            />
                        ) : null}
                    </MessageContainer>
                </Container>
            </>
        );
    }
);

export default Message;
