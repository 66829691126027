import React, {useMemo, useRef} from 'react';
import styled from 'styled-components';
import {CoreText, CoreTooltip} from '@anywhere-expert/base-ui';
import {UpdateCategoryPayload} from '@soluto-private/canned-messages-api-client';
import EditCategory from './EditCategory';
import Indicator from './Indicator';
import useIsOverflowing from './useIsOverflowing';

const CategoryTitleText = styled(CoreText)<{isGrayedOut?: boolean}>`
    max-width: 220px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${({isGrayedOut, theme}) => isGrayedOut && theme.colors.neutral.grey.deep};
`;

const CategoryEmojiContainer = styled(CoreText)`
    margin-right: 12px;
    font-size: 16px;
    height: 21px;
    line-height: 24px;
`;

interface Props {
    icon: string;
    title: string;
    isCollapsed: boolean;
    editMode: boolean;
    onEditSubmit: (updates: UpdateCategoryPayload) => Promise<void>;
    shouldShowIndicator?: boolean;
    isGrayedOut?: boolean;
}

const CategoryTitle = ({icon, title, isCollapsed, editMode, onEditSubmit, shouldShowIndicator, isGrayedOut}: Props) => {
    const textWeight = useMemo(() => (!isCollapsed ? 'bold' : 'normal'), [isCollapsed]);
    const ref = useRef();
    const shouldShowTooltip = useIsOverflowing(ref);

    return editMode ? (
        <EditCategory text={title} icon={icon} onEdit={onEditSubmit} />
    ) : (
        <>
            <CategoryEmojiContainer size="l">{icon}</CategoryEmojiContainer>
            <CoreTooltip
                disabled={!shouldShowTooltip}
                text={title}
                position="bottom"
                enterDelay={0}
                enterTouchDelay={0}
            >
                <CategoryTitleText ref={ref} textType="primary" size="m" weight={textWeight} isGrayedOut={isGrayedOut}>
                    {title}
                </CategoryTitleText>
            </CoreTooltip>
            {shouldShowIndicator && <Indicator />}
        </>
    );
};

export default CategoryTitle;
