import {BehaviorSubject} from 'rxjs';

export enum FeedEventType {
    Resolve,
}

export type FeedEvent = {
    type: FeedEventType;
    feedItemId: string;
};

export const feedEvents$ = new BehaviorSubject<FeedEvent | null>(null);
