import React, {useEffect, memo, useMemo} from 'react';
import {HelixArticleItem} from '../types';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import Article from './Article';
import ArticlePreview from './ArticlePreview';
import {AnalyticsDispatcher} from 'shisell';

const ArticlesContainer = styled.div<{isUserSearch: boolean}>`
    margin-top: ${({isUserSearch}) => (isUserSearch ? '16px' : '8px')};
    margin-right: 24px;
`;

const ArticleRender = memo(
    ({
        article,
        articleDispatcher,
        isUserSearch,
        position,
        shouldShowSeparator,
        searchTerms,
    }: {
        article: HelixArticleItem;
        position: number;
        isUserSearch: boolean;
        articleDispatcher: AnalyticsDispatcher;
        shouldShowSeparator: boolean;
        searchTerms: string;
    }) => {
        {
            const dispatcherWithExtras = articleDispatcher.withExtras({
                Type: article.type,
                Id: article.article_id,
                Title: article.title,
                Position: position,
                Rank: article.score,
            });

            return isUserSearch ? (
                <Article
                    {...article}
                    shouldShowSeparator={shouldShowSeparator}
                    dispatcher={dispatcherWithExtras}
                    searchedText={searchTerms}
                />
            ) : (
                <ArticlePreview {...article} dispatcher={dispatcherWithExtras} />
            );
        }
    }
);

const Articles = memo(
    ({
        articles,
        searchTerms,
        isUserSearch,
    }: {
        articles: HelixArticleItem[];
        searchTerms: string;
        isUserSearch: boolean;
    }) => {
        const analytics = useAnalytics();
        const dispatcher = useMemo(() => {
            const extras: {[key: string]: string | number} = {
                SearchTerms: searchTerms,
                Source: isUserSearch ? 'user' : 'initial_state',
                TotalHits: articles.length,
            };

            return analytics.dispatcher.createScoped('Results').withExtras(extras);
        }, [searchTerms, isUserSearch, articles]);

        useEffect(() => {
            dispatcher.dispatch('View');
        }, []);

        const articleDispatcher = useMemo(() => dispatcher.createScoped('Article'), [dispatcher]);

        return (
            <ArticlesContainer
                isUserSearch={isUserSearch}
                data-test-id={isUserSearch ? 'helix-results' : 'helix-preview-results'}
            >
                {articles.map((article, index) => (
                    <ArticleRender
                        key={article.article_id}
                        article={article}
                        position={index + 1}
                        articleDispatcher={articleDispatcher}
                        isUserSearch={isUserSearch}
                        searchTerms={searchTerms}
                        shouldShowSeparator={index !== articles.length - 1}
                    />
                ))}
            </ArticlesContainer>
        );
    }
);

export default Articles;
