import {useSuggestionsCategory} from '@anywhere-expert/canned-messages-v2';
import {useAnalytics} from 'react-shisell';

const useDispatcher = () => {
    const {dispatcher} = useAnalytics();
    const suggestionsCategory = useSuggestionsCategory();

    return (
        suggestionsCategory.current &&
        dispatcher.withExtras({
            SourceCategoryId: suggestionsCategory.current.id,
            SourceCategoryName: suggestionsCategory.current.title,
        })
    );
};
export default useDispatcher;
