import ExpertFeedStore from '@anywhere-expert/expert-feed-store/ExpertFeedStore';
import CannedMessagesNavigationStore from './stores/CannedMessagesNavigationStore';

const defaultCannedMessagesNavigationStore = new CannedMessagesNavigationStore();

export const useCannedMessagesNavigationStore = () => {
    return ExpertFeedStore.selectedSupportSession
        ? ExpertFeedStore.selectedSupportSession.cannedMessagesNavigationStore
        : defaultCannedMessagesNavigationStore;
};
