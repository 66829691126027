import {AttributesByType, Attribute} from '@soluto-private/session-api-types';
import {extractAttribute, extractAttributeFields} from './attributeUtils';

export function isHomeVisitSession(sessionAttributes: AttributesByType) {
    return !!sessionAttributes['home-visit-details'];
}

export const hasSimilarSessions = (attributes: Attribute[]) =>
    attributes.some(
        attribute =>
            attribute.attributeType === 'similar-sessions-v2' &&
            attribute.key === 'similar-sessions-key-v2' &&
            !!attribute.fields &&
            Object.values(attribute.fields).length > 0
    );

export const isNeglectionWarning = (sessionAttributes: AttributesByType): boolean => {
    const fields = extractAttributeFields(sessionAttributes, 'expert-neglected-session', 'neglection');

    return fields.status === 'warn' && fields.startTime !== undefined && fields.endTime !== undefined;
};

export function wasUnassignedByNeglection(sessionAttributes: AttributesByType): boolean {
    const fields = extractAttributeFields(sessionAttributes, 'expert-neglected-session', 'neglection');

    return fields.status === 'unassigned';
}

export function getNeglectionPrevExpertName(sessionAttributes: AttributesByType): string {
    const fields = extractAttributeFields(sessionAttributes, 'expert-neglected-session', 'neglection');

    return fields.prevExpertName;
}

export function isVoiceSession(sessionAttributes: AttributesByType): boolean {
    return !!extractAttribute(sessionAttributes, 'expertise', 'voice');
}
