import {action, computed, Lambda, reaction, observable, makeObservable} from 'mobx';
import {
    getCustomerDetails,
    getProactiveAttribute,
    getQuickReplyAttributes,
    getProactiveSessionAttribute,
    getSessionContext,
    getAmazonPrePurchaseDetails,
} from '../utils/transformers';
import {VideoCameraOptions, ProactiveData, FollowUpData, LastMessage} from '../types';
import {ExpertFeedStore} from '../ExpertFeedStore';
import {skipFailedAssignedSessionsStorage} from '@anywhere-expert/session-local-storage';
import QueueItemCallDetailsModel from './QueueItemCallDetailsModel';
import {QueueItemNotesStore} from './QueueItemNotesStore';
import QueueItemGifsStore from './QueueItemGifsStore';
import {QueueItemAutopilotModel} from './QueueItemAutopilotModel';
import {QueueItemAttribute, QueueItemWithoutAttributes} from '@soluto-private/expert-queue-api-types';
import {getDeviceDetails} from '../utils/transformers';
import {TabId} from '@anywhere-expert/session-sidebar';
import QueueItemSimilarSessionsModel from './QueueItemSimilarSessionsModel';
import {Tag} from '@expert-feed/conversation-tags';
import QueueItem from './QueueItem';
import {extractTags} from '@expert-feed/conversation-tags';
import {setNewUnreadSessionMessage} from '..';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {CannedMessagesNavigationStore} from '@anywhere-expert/canned-messages-v2';
import {HelixStore} from '@anywhere-expert/helix-search';
import {TimelineSearchStore} from '@anywhere-expert/timeline-search';

export default class SupportItem extends QueueItem {
    customerId: string; // it's the same as id
    callDetails: QueueItemCallDetailsModel;
    similarSessions: QueueItemSimilarSessionsModel;
    selectedTabId: TabId | undefined;
    autopilot: QueueItemAutopilotModel;
    notesStore: QueueItemNotesStore;
    gifsStore: QueueItemGifsStore;
    cannedMessagesNavigationStore: CannedMessagesNavigationStore;
    helixStore: HelixStore;
    timelineSearchStore: TimelineSearchStore;
    timelineModel: TimelineModel;
    welcomeMessageSent: boolean;

    private newUnreadMessagesReactionDisposer: Lambda | undefined;
    get sessionContextItem() {
        return getSessionContext(this.sessionAttributes);
    }

    get proactiveSessionData(): ProactiveData | undefined {
        const attr = getProactiveAttribute(this.sessionAttributes);
        if (!attr) return;

        const [lastMessage] = this.lastMessages
            .filter(x => x.senderType === 'customer')
            .sort((a, b) => b.timestamp - a.timestamp);
        if (lastMessage && lastMessage.timestamp > attr.fields?.['timestamp']) {
            return undefined;
        }

        return {footerMessage: attr.fields?.['footerMessage']};
    }

    get isProactiveSession() {
        return !!getProactiveSessionAttribute(this.sessionAttributes);
    }

    get proactiveReason() {
        const attr = getProactiveSessionAttribute(this.sessionAttributes);
        return attr?.key;
    }

    get followUpData(): FollowUpData | undefined {
        const attr = this.sessionAttributes['proactive']?.['follow-up'];

        if (!attr) return;

        if (!attr.fields?.scheduledTime || typeof attr.fields.scheduledTime !== 'number') {
            return;
        }

        if (!attr.fields?.description || typeof attr.fields.description !== 'string') {
            return;
        }

        return {scheduledTime: attr.fields.scheduledTime, description: attr.fields.description};
    }

    get isFollowUpOngoing(): boolean {
        return this.supportItemDetails.attributes.some(
            attribute => attribute.attributeType === 'proactive' && attribute.key === 'follow-up'
        );
    }

    get isDirectToConsumer() {
        return this.supportItemDetails.pool.toLowerCase() === 'soluto';
    }

    isSupport(): this is SupportItem {
        return true;
    }

    get isInStore() {
        const attributes = this.supportItemDetails.attributes;
        return (
            Array.isArray(attributes) &&
            attributes.some(
                attribute =>
                    attribute.attributeType === 'expertise' &&
                    (attribute.key === 'in-store' ||
                        attribute.key === 'in-store-demo' ||
                        attribute.key === 'in-store-training')
            )
        );
    }

    get videoCameraOptions() {
        const videoCameraOptions: VideoCameraOptions[] = [];
        const attributes = this.supportItemDetails && this.supportItemDetails.attributes;

        const sessionDetails =
            Array.isArray(attributes) &&
            attributes.find(
                attribute => attribute.attributeType === 'video' && attribute.key === 'video-camera-options'
            );
        if (!sessionDetails || !sessionDetails.fields) {
            return undefined;
        }
        Object.keys(sessionDetails.fields).forEach(key => {
            videoCameraOptions.push({
                text: key,
                value: sessionDetails.fields![key],
            });
        });
        return videoCameraOptions;
    }

    get isClosed() {
        return this.supportItemDetails.attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'customer_closed' &&
                attribute.fields?.closed
        );
    }

    get isTimedOut() {
        return this.supportItemDetails.attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'session_timed_out' &&
                attribute.fields?.timedOut
        );
    }

    get isCancelled() {
        return this.supportItemDetails.attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'customer_cancelled' &&
                attribute.fields?.cancelled
        );
    }

    get isStationAudioOnly() {
        return this.supportItemDetails.attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'station_audio_only' &&
                attribute.fields?.audioOnly
        );
    }

    get isStationReconnecting() {
        return this.supportItemDetails.attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'station_reconnecting' &&
                attribute.fields?.reconnecting
        );
    }

    get isWaitingForResponse() {
        return this.inFeed && this.unansweredMessagesCount;
    }

    get deviceDetails() {
        return getDeviceDetails(this.sessionAttributes);
    }

    get customerDetails() {
        return getCustomerDetails(this.sessionAttributes);
    }

    get quickReplyOptions() {
        return getQuickReplyAttributes(this.sessionAttributes);
    }

    get isAppleBusinessChat() {
        const attributes = this.supportItemDetails.attributes;

        return attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'source' &&
                attribute.fields?.name === 'Apple Business Chat'
        );
    }

    get isAmazonPrePurchase() {
        return this.supportItemDetails.attributes.some(
            attribute => attribute.attributeType === 'metadata' && attribute.key === 'amazon_pre_purchase'
        );
    }

    get amazonPrePurchaseDetails() {
        return getAmazonPrePurchaseDetails(this.sessionAttributes);
    }

    get isAutopilot() {
        return this.supportItemDetails.attributes.some(attribute => attribute.attributeType === 'autopilot');
    }

    get smartSuggestionAttributes() {
        return this.supportItemDetails.attributes.filter(
            attribute => attribute.attributeType === 'session-smart-suggestions'
        );
    }

    get hasCustomerBeenVerified() {
        const attributes = this.supportItemDetails.attributes;

        return attributes.some(
            attribute =>
                attribute.attributeType === 'metadata' &&
                attribute.key === 'source' &&
                ['MyVerizonQa', 'MyVerizon'].includes(attribute.fields?.name)
        );
    }

    get sessionCustomerParticipants() {
        const lastMessageBySender = this.sessionAttributes['last-message-by-sender'];

        if (!lastMessageBySender) {
            return undefined;
        }
        return Object.keys(lastMessageBySender).filter(
            x => lastMessageBySender[x]?.fields?.['senderType'] === 'customer'
        );
    }

    get didExpertSendMessage() {
        const lastMessageBySender = this.sessionAttributes['last-message-by-sender'];
        const sentMessageAttribute =
            lastMessageBySender && Object.keys(lastMessageBySender).includes(this.store.user.uid);
        return sentMessageAttribute || !!this.timelineModel.sessionExpertMessages.length;
    }

    get lastMessages(): LastMessage[] {
        return this.supportItemDetails.attributes
            .filter(att => att.attributeType === 'last-message-by-sender')
            .map(att => ({
                senderId: att.key,
                text: att.fields!.text,
                senderType: att.fields!.senderType,
                timestamp: new Date(att.fields!.timestamp).getTime() || 0,
                messageId: att.fields!.messageId,
            }));
    }

    get isSelected() {
        return this.store.selectedSessionId === this.sessionId;
    }

    get customTags() {
        return this.tags.filter(tag => tag.tagType === 'customTag');
    }

    get lastMessage() {
        const [lastMessageAttribute] = this.lastMessages.sort(
            (a, b) => (b.timestamp || Number.MIN_VALUE) - (a.timestamp || Number.MIN_VALUE)
        );
        return lastMessageAttribute;
    }

    get unansweredMessagesCount() {
        const fromAttribute =
            (this.supportItemDetails.attributes.find(att => att.attributeType === 'unanswered-messages-count')?.fields
                ?.value as number) ?? 0;

        return this.timelineModel.isLastMessageFromExpert ? 0 : fromAttribute;
    }

    get previewText() {
        const NO_PREVIEW_TEXT = 'No text messages yet...';
        if (this.proactiveSessionData) return this.proactiveSessionData.footerMessage;
        const lastMessageFromTimeline = this.timelineModel?.lastMessageVisibleToExpert?.payload?.value?.text;
        const lastMessageFromAttribute = this.lastMessage?.text;
        return lastMessageFromTimeline ?? lastMessageFromAttribute ?? NO_PREVIEW_TEXT;
    }

    protected prepareTags() {
        /*
           We want to hide the 'Web' tag if we have a clientName tag (for example 'ProTech App')
           We'd still like to show deviceModel tag in any case
       */
        const tags = extractTags(this.supportItemDetails.attributes, this.feedItemAttributes);
        if (this.deviceDetails.platform !== 'web' || !tags.some(t => t.tagType === 'clientName')) {
            const deviceModelTag: Tag = {
                displayText:
                    this.deviceDetails.deviceModel !== 'device' && this.deviceDetails.platform === 'web'
                        ? 'Web'
                        : this.deviceDetails.deviceModel === 'device'
                        ? 'Device'
                        : 'Web',
                tagType: 'deviceModel',
                tagKey: this.deviceDetails.deviceModel,
            };
            tags.push(deviceModelTag);
        }

        return tags;
    }

    constructor(store: ExpertFeedStore, item: QueueItemWithoutAttributes, attributes: QueueItemAttribute[] = []) {
        super(store, item, attributes);

        makeObservable<SupportItem, 'newMessageReaction'>(this, {
            sessionContextItem: computed,
            proactiveSessionData: computed,
            isProactiveSession: computed,
            proactiveReason: computed,
            followUpData: computed,
            isFollowUpOngoing: computed,
            isDirectToConsumer: computed,
            isInStore: computed,
            welcomeMessageSent: observable,
            videoCameraOptions: computed,
            isClosed: computed,
            sentWelcomeMessage: action,
            isTimedOut: computed,
            isCancelled: computed,
            isStationAudioOnly: computed,
            isStationReconnecting: computed,
            isWaitingForResponse: computed,
            deviceDetails: computed,
            customerDetails: computed,
            isAppleBusinessChat: computed,
            isAmazonPrePurchase: computed,
            amazonPrePurchaseDetails: computed,
            isAutopilot: computed,
            smartSuggestionAttributes: computed,
            hasCustomerBeenVerified: computed,
            sessionCustomerParticipants: computed,
            didExpertSendMessage: computed,
            lastMessages: computed,
            isSelected: computed,
            customTags: computed,
            lastMessage: computed,
            quickReplyOptions: computed,
            unansweredMessagesCount: computed,
            newMessageReaction: action,
            previewText: computed,
        });

        this.customerId = item.customerId;

        this.callDetails = new QueueItemCallDetailsModel(this);
        this.similarSessions = new QueueItemSimilarSessionsModel(this);
        this.autopilot = new QueueItemAutopilotModel(this);
        this.notesStore = new QueueItemNotesStore(item.customerId, this);
        this.gifsStore = new QueueItemGifsStore(item.customerId, this);
        this.cannedMessagesNavigationStore = new CannedMessagesNavigationStore();
        this.helixStore = new HelixStore(this);
        this.timelineSearchStore = new TimelineSearchStore(this);

        this.newMessageReaction();
        this.isSkipFailedAssign = !!skipFailedAssignedSessionsStorage.get(item.sessionId);

        this.timelineModel = new TimelineModel(this.id, this);
    }

    private newMessageReaction = () => {
        this.newUnreadMessagesReactionDisposer = reaction(
            () => this.timelineModel.lastMessage,
            (newValue, oldValue) => {
                if (!newValue || oldValue?.messageId === newValue.messageId || !this.isAssignedToMe) return;
                if (newValue.recipientId === this.customerId) {
                    const newCustomerMessage = newValue.payload.value.text;
                    setNewUnreadSessionMessage(
                        this.sessionId,
                        this.customerId,
                        newCustomerMessage,
                        this.queueNaming,
                        this.customerDetails.firstName
                    );
                }
            }
        );
    };

    sentWelcomeMessage = () => (this.welcomeMessageSent = true);

    setSelectedTabId = (selectedTabId: TabId) => (this.selectedTabId = selectedTabId);

    dtor() {
        super.dtor();
        if (this.newUnreadMessagesReactionDisposer) this.newUnreadMessagesReactionDisposer = undefined;
        this.timelineModel.dtor();
    }
}
