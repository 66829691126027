import React, {useCallback, useState, useMemo} from 'react';
import {
    CoreButton,
    CoreDropdown,
    CoreModal,
    CoreInputText,
    CoreListItem,
    CoreList,
    CoreListProps,
    ModalContentComponentProps,
    closeModal,
} from '@anywhere-expert/base-ui';
import CannedMessagesStoreV2 from '@anywhere-expert/canned-messages-v2/src/stores/CannedMessagesStoreV2';
import {RichEditor, RichInputStore} from '@anywhere-expert/rich-input';
import {observer} from 'mobx-react';
import {useAnalytics} from 'react-shisell';
import styled from 'styled-components';
import {CANNED_MESSAGE_SOURCE_MANUAL} from '../../consts';

const closeAddCannedMessage = () => closeModal('add-message-modal');

const InputText = styled(CoreInputText)`
    padding: 12px;
    border-radius: 8px;
    width: 336px;
    height: 40px;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.disabled}`};
`;

const StyledEditor = styled(RichEditor)`
    margin-top: 16px;
    min-height: 160px;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.disabled}`};
`;

const CoreModalStyled = styled.div`
    width: 528px;
`;

const DropdownCategories = styled(CoreDropdown).attrs({contentStyle: {width: '100%'}, childStyle: {width: '100%'}})`
    max-width: 336px;
    border-radius: 8px;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.disabled}`};
    margin-bottom: 8px;

    button {
        border-width: 0;
        border-radius: 8px;
    }
    button:hover {
        border-radius: 8px;
    }
`;

const ListCategories = styled(CoreList)`
    width: 101%;
    margin-right: -1px;
    margin-left: -1px;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.caption}`};
    max-height: 245px;
` as React.ComponentType<CoreListProps & React.HTMLProps<HTMLUListElement>>;

interface Props extends ModalContentComponentProps {
    text: string;
    title?: string;
    sourceCategoryId?: string;
    onCancel?: () => void;
    onSubmit?: (text: string, categoryId: string, title?: string) => Promise<{submittedSuccessfully: boolean}>;
}

export const AddCannedMessagesModal = observer(({text, title, sourceCategoryId, onCancel, onSubmit}: Props) => {
    const [selectedCategory, setSelectedCategory] = useState(sourceCategoryId);
    const [titleText, setTitleText] = useState(title);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [richInputStore] = useState(new RichInputStore(text));
    const {dispatcher} = useAnalytics();
    const isEditMode = useMemo(() => sourceCategoryId && text, [sourceCategoryId, text]);
    const hasChanges = useMemo(
        () =>
            text !== richInputStore.editorMarkdownText || title !== titleText || sourceCategoryId !== selectedCategory,
        [text, title, sourceCategoryId, titleText, selectedCategory, richInputStore.editorMarkdownText]
    );

    const modalAnalytics = useMemo(() => dispatcher.createScoped('AddCannedMessageModal'), [dispatcher]);

    const onTitleUpdated = useCallback(
        ({target}) => {
            setTitleText(target.value);
        },
        [setTitleText]
    );

    const cancel = useCallback(() => {
        onCancel?.();
        closeAddCannedMessage();
        modalAnalytics.withExtras({ActionId: 'Cancel', EditExisting: !!isEditMode}).dispatch('Click');
    }, [onCancel, closeAddCannedMessage, isEditMode, modalAnalytics]);

    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const onSave = useCallback(async () => {
        const textMessage = richInputStore.editorMarkdownText;

        modalAnalytics
            .withExtras({
                ActionId: 'Save',
                Title: titleText,
                Text: textMessage,
                SourceCategoryId: sourceCategoryId,
                TargetCategoryId: selectedCategory,
                TargetCategoryName: title,
                EditExisting: !!isEditMode,
            })
            .dispatch('Click');

        setIsSaveInProgress(true);
        let operationSucceeded = false;

        if (onSubmit) {
            const {submittedSuccessfully} = await onSubmit(textMessage, selectedCategory!, titleText);
            operationSucceeded = submittedSuccessfully;
        } else {
            const {addedSuccessfully} = await CannedMessagesStoreV2.addMessage(selectedCategory!, {
                text: textMessage,
                title: titleText,
                source: CANNED_MESSAGE_SOURCE_MANUAL,
            });

            operationSucceeded = addedSuccessfully;
        }

        setIsSaveInProgress(false);

        if (operationSucceeded) closeAddCannedMessage();
    }, [selectedCategory, titleText, onSubmit, modalAnalytics, isEditMode]);

    const onCategorySelected = useCallback(
        categoryId => {
            setSelectedCategory(categoryId);
            setDropdownOpen(false);
        },
        [setSelectedCategory, setDropdownOpen]
    );

    const headerText = useMemo(() => (isEditMode ? 'Edit Canned Message' : 'Add to Canned messages'), [isEditMode]);
    const toggleDropdown = useCallback(() => setDropdownOpen(open => !open), [setDropdownOpen]);
    const closeDropdown = useCallback(() => setDropdownOpen(false), [setDropdownOpen]);
    const categories = Object.entries(CannedMessagesStoreV2.expertCategories);
    const shouldDisableSaveButton = !selectedCategory || !richInputStore.editorMarkdownText || !hasChanges;
    return (
        <CoreModalStyled data-test-id="CannedMessageForm">
            <CoreModal.Title showCloseButton={true} modalSize="l">
                {headerText}
            </CoreModal.Title>
            <CoreModal.Content>
                <DropdownCategories
                    text={
                        (selectedCategory && CannedMessagesStoreV2.expertCategories[selectedCategory]?.title) ||
                        'choose category'
                    }
                    data-test-id={'Categories_Dropdown'}
                    extend={dropdownOpen}
                    onClick={toggleDropdown}
                    onClickOut={closeDropdown}
                >
                    <ListCategories>
                        {categories.map(([categoryId, category]) => (
                            <CoreListItem
                                text={category.title}
                                key={categoryId}
                                onClick={() => onCategorySelected(categoryId)}
                                data-test-id={`Categories_Dropdown_Option_${categoryId}`}
                            />
                        ))}
                    </ListCategories>
                </DropdownCategories>
                <InputText
                    data-test-id="CannedMessages_AddMessage_Title"
                    size="m"
                    value={titleText}
                    onChange={onTitleUpdated}
                    placeholder="Title (optional)"
                    inputTextType="secondary"
                />
                <StyledEditor
                    richInputStore={richInputStore}
                    placeholder="Type a message"
                    dataTestId="CannedMessageForm_AddMessage_Text"
                />
            </CoreModal.Content>
            <CoreModal.Actions>
                <CoreButton color="default" text="Cancel" onClick={cancel} />
                <CoreButton
                    color="primary"
                    text="Save"
                    data-test-id={'CannedMessageForm_AddMessage_Save'}
                    disabled={shouldDisableSaveButton}
                    onClick={onSave}
                    isInProgress={isSaveInProgress}
                />
            </CoreModal.Actions>
        </CoreModalStyled>
    );
});
