import {v4 as uuid} from 'uuid';
import {getUserProfile} from '@anywhere-expert/auth';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {ScreenshareMessagePayload, SupportedScreenshareLevel} from '../sharedScreenshareDefinitions';
import {ScreenShareTimelineItemUpdater} from './ScreenShareTimelineItemUpdater';

const sendNewScreenshareTimelineItem = async (
    timelineModel: TimelineModel,
    cobrowseSessionId: string,
    screenshareSupportLevel: SupportedScreenshareLevel
): Promise<{messageId: string}> => {
    const userProfile = getUserProfile();

    const screenshareMessagePayload: ScreenshareMessagePayload = {
        cobrowseSessionId,
        state: 'pending',
        screenshareLevel: screenshareSupportLevel,
    };

    const messageId = uuid();

    await timelineModel.createMessage({
        sessionId: timelineModel.supportItem.sessionId,
        payload: {
            type: 'screenshare_item',
            value: screenshareMessagePayload,
        },
        messageId: messageId,
        senderId: userProfile.uid,
        metadata: {
            senderName: userProfile.displayName,
            senderType: 'Technician',
        },
    });

    return {messageId};
};

export async function sendNewScreenshareTimelineItemAndReturnUpdater(
    timelineModel: TimelineModel,
    cobrowseSessionId: string,
    screenshareSupportLevel: SupportedScreenshareLevel
): Promise<ScreenShareTimelineItemUpdater> {
    const {messageId} = await sendNewScreenshareTimelineItem(timelineModel, cobrowseSessionId, screenshareSupportLevel);
    return new ScreenShareTimelineItemUpdater(timelineModel, messageId);
}
