import React from 'react';
import {Redirect} from 'react-router-dom';
import {useUserProfile} from '@anywhere-expert/auth';
import {AppLayout} from '../scenes';
import LoginRequired from './Login/LoginRequired';
import {withUrlQueryParams} from './behaviors/withUrlQueryParams';
import useAccessToLoggedInRoutes from './behaviors/useAccessToLoggedInRoutes';

const LoggedInRoutes = withUrlQueryParams(({location, queryParams}: any) => {
    const {isLoggedIn, loginRequired} = useUserProfile();
    const {loggingInState, asyncLoginInProgress} = useAccessToLoggedInRoutes(isLoggedIn);

    if (loggingInState === 'idle' || asyncLoginInProgress) {
        return (
            <div id="initialLoading" data-test-id="initialLoading">
                Loading...
            </div>
        );
    }

    if (loggingInState === 'failed') {
        return <Redirect to={{pathname: '/login', state: {from: location, queryParams}}} />;
    }

    return (
        <>
            <AppLayout />
            {loginRequired && <LoginRequired />}
        </>
    );
});

export default LoggedInRoutes;
