import React from 'react';
import styled from 'styled-components';
import {CoreIcon, CoreText} from '@anywhere-expert/base-ui';
import {AlertCircleOutlinedIcon} from '@anywhere-expert/icons';

const NotificationContainer = styled.div`
    background: ${({theme}) => theme.colors.neutral.whites.w05};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    max-width: 350px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    margin-top: 8px;
`;

const StyledCoreIcon = styled(CoreIcon)`
    margin-right: 8px;
`;

const StyledNotificationText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.whites.w80};
`;

const Notification = ({children}: {children: JSX.Element}) => (
    <NotificationContainer>
        <StyledCoreIcon icon={AlertCircleOutlinedIcon} size="m" />
        <StyledNotificationText textType="primary" size="s">
            {children}
        </StyledNotificationText>
    </NotificationContainer>
);

export default Notification;
