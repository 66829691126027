import {CategoriesColors} from './consts';
import {Theme} from '@anywhere-expert/colors';

export const getRandomColor = (): string => CategoriesColors[Math.floor(Math.random() * CategoriesColors.length)];

export const getIconColor = (isSelected: boolean, theme: Theme) =>
    isSelected ? theme.colors.neutral.black : theme.colors.neutral.grey.deep;

export const buildMessage = (text: string, displayName: string) =>
    text.replace('{{userProfile.displayName}}', displayName);
