/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {BaseIcon, IconProps} from '@anywhere-expert/icons';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M8.168 2.49a.959.959 0 01.813-.45h1.43c.331 0 .638.17.813.45l.682 1.09a.959.959 0 01.146.509V6.31h1.542c.53 0 .959.429.959.958v.405h1.633c.21 0 .413.069.58.195l2.274 1.728a.958.958 0 01.378.763v5.503c0 .096-.014.19-.042.282l-1.591 5.182a.959.959 0 01-.917.678h-8.23a.959.959 0 01-.886-.595L4.39 13.227a.958.958 0 01-.053-.551l.496-2.503a.959.959 0 01.94-.772H7.34V4.09c0-.18.05-.356.146-.509l.683-1.09zm-.829 8.828H6.56l-.287 1.447 3.008 7.323h6.879l1.341-4.369v-4.883l-.356-.271v1.66h-1.917V9.591h-.675v1.998h-1.917V8.228h-.584v2.952h-1.917V4.364l-.255-.408h-.369l-.255.408v9.18H7.34v-2.226z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
