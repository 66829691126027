import {AssignmentState} from '../types';
import {UpdateWaitingForSessionError} from '@soluto-private/expert-api-types';
import logger from '@anywhere-expert/logging';

export default (responseError: UpdateWaitingForSessionError): AssignmentState => {
    if (responseError === UpdateWaitingForSessionError.limitReached) {
        return AssignmentState.LIMIT_REACH;
    }
    logger.error('received error from expertApi waitingForSession', {extra: {responseError}});
    return AssignmentState.ERROR;
};
