import React, {useCallback} from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import styled from 'styled-components';
import {CoreButton} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {SendIcon} from '@anywhere-expert/icons';
import {MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import {getProductUrlFromSession} from '../../../../utils/helpers';
import {useSendMessage} from '@anywhere-expert/timeline-behaviours';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {useMessagingSalesClickAnalytics} from '../../../../hooks';

export const ButtonLinkContainer = styled.div`
    margin-bottom: 15px;
    margin-top: 15px;

    button {
        font-weight: bold;
    }
`;

type SendProductLinkProps = {
    url: string;
    label: string;
    session: SupportItem;
};

const SendProductLinkButton = ({url, label, session}: SendProductLinkProps) => {
    const theme = useTheme();
    const clickWithExtra = useMessagingSalesClickAnalytics('ProductLink');

    const {customerId, sessionId, pool: partner} = session;
    const sendMessage = useSendMessage(customerId, sessionId, partner);

    const sendProductLink = useCallback(async () => {
        await sendMessage(`${label}: ${url}`);
        clickWithExtra({ActionId: 'ProductLinkSent'});
    }, [url, label, sendMessage, clickWithExtra]);

    const sendButtonText = 'Send Website Link';

    return (
        <CoreButton
            variant="outlined"
            onClick={sendProductLink}
            icon={SendIcon}
            text={sendButtonText}
            colorOverride={theme.colors.neutral.black}
            data-test-id="SendProductLink_button"
        />
    );
};

const SendProductLink = observer(({selectedSession}: SalesSessionProps) => {
    const url = getProductUrlFromSession(selectedSession);
    const productName = useTweekValue(MessagingSalesTweekKeys.ProductName, '');

    if (!url) {
        return null;
    }

    return (
        <ButtonLinkContainer>
            <SendProductLinkButton url={url} label={productName} session={selectedSession} />
        </ButtonLinkContainer>
    );
});

export default SendProductLink;
