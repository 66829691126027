import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import styled from 'styled-components';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import {defaultManualOfferContent} from '../../../../consts';
import {useCurrentOffer, useMessagingSalesClickAnalytics} from '../../../../hooks';
import {MessagingSalesTweekKeys} from '../../../../types';
import {Line} from '../../../styled-components';

const BlubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
`;

const Blub = styled.img`
    height: 150px;
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};
`;

const ManualOfferText = styled(CoreText)`
    margin: 0 15px 15px;
`;

const ManualOfferButton = styled(CoreButton)`
    display: flex;
    margin: auto;
    font-size: 14px;
`;

const ManualOffer = observer(({selectedSession}: SalesSessionProps) => {
    const clickedWithExtras = useMessagingSalesClickAnalytics('ManualOffer');
    const isAutomaticOfferEnabled = useTweekValue(MessagingSalesTweekKeys.IsAutomaticOfferEnabled, false);
    const {text, buttonText} = useTweekValue(MessagingSalesTweekKeys.ManualOfferContent, defaultManualOfferContent);
    const currentOffer = useCurrentOffer(selectedSession.sessionId)!;
    const timelineModel = selectedSession.timelineModel;

    const [isSendingManualOffer, setIsSendingManualOffer] = useState(false);

    const onClick = useCallback(async () => {
        try {
            setIsSendingManualOffer(true);
            await currentOffer.setManualOfferSelection();

            clickedWithExtras({MessagesIntoSession: timelineModel.currentSessionMessages.length});
        } finally {
            setIsSendingManualOffer(false);
        }
    }, [currentOffer, timelineModel, clickedWithExtras]);

    if (!isAutomaticOfferEnabled || currentOffer?.offer.offerType) {
        return null;
    }

    return (
        <div data-test-id="MessagingSales_ManualOfferTab">
            <BlubContainer>
                <Blub src={require('./resources/magnifying-glass.png')} />
            </BlubContainer>
            <ManualOfferText size="m">{text}</ManualOfferText>
            <ManualOfferButton
                data-test-id="MessagingSales_OfferDetection_ManualOfferButton"
                color="primary"
                onClick={onClick}
                text={buttonText}
                isInProgress={isSendingManualOffer}
            />
            <Line />
        </div>
    );
});

export default ManualOffer;
