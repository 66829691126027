import {useState, useEffect} from 'react';
import {useTweekRepository} from 'react-tweek';
import {TweekRepository} from 'tweek-local-cache';

export const useIsTweekReady = (testKey: string) => {
    const repo = useTweekRepository();
    const [lastReadyRepo, setLastReadyRepo] = useState<TweekRepository | null>(null);

    useEffect(
        () => {
            if (!repo) return;
            let didDispose = false;

            const cached = repo.getCached(testKey);
            if (cached && cached.state === 'cached') {
                setLastReadyRepo(repo);
            } else {
                repo.get(testKey).then(() => {
                    if (didDispose) return;
                    setLastReadyRepo(repo);
                });
            }

            return () => {
                didDispose = true;
            };
        },
        [repo]
    );

    let isReady = false;
    if (lastReadyRepo === repo) {
        isReady = true;
    } else if (repo) {
        const cached = repo.getCached(testKey);
        if (cached && cached.state === 'cached') {
            isReady = true;
        }
    }

    return isReady;
};
