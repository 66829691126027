import {getHomeAccessToken, User} from '@anywhere-expert/auth';
import {configuration as config} from '@anywhere-expert/core';
import logger from '@anywhere-expert/logging';
import * as R from 'ramda';
import {prepareKey} from 'react-tweek';
import {BehaviorSubject, Observable} from 'rxjs';
import {useObservable} from 'rxjs-hooks';
import {Context, createTweekClientWithFallback, TweekClientWithFallback} from 'tweek-client';
import {ITweekStore, TweekRepository} from 'tweek-local-cache';
import partitionQueuesAndExpertise from './partitionQueuesAndExpertise';
import {parseTweekErrorResponse} from './tweekErrorParser';

prepareKey('support/_');

let tweekURL = config.tweekBaseUrl;
let tweekURLFallback = config.tweekBaseUrlFallback;

let tweekClient: TweekClientWithFallback;
let tweekTechnicianContext: Context = {};

export const getTweekClient = () => {
    if (!tweekClient) {
        throw new Error('Tweek client must be initilized');
    }

    return tweekClient;
};

export const getTweekTechnicianContext = () => tweekTechnicianContext;

const tweekRepository$ = new BehaviorSubject<TweekRepository | undefined>(undefined);
const setTweekRepo = (repo: TweekRepository) => tweekRepository$.next(repo);
export const getTweekRepo = () => tweekRepository$.getValue();
export const useTweekRepo = () => useObservable(() => tweekRepository$, undefined);
export const tweekRepo$ = tweekRepository$.filter(Boolean) as Observable<TweekRepository>;
export const getTweekValue = <T>(key: string, defaultValue: T) => {
    const repo = getTweekRepo();
    if (!repo) {
        logger.warn('Tweek key requested before repo has been initialized, using default value.', {
            extra: {key, defaultValue},
        });
        return defaultValue;
    }

    const cached = repo.getCached<T>(key);
    return (cached && cached.state === 'cached' && cached.value) || defaultValue;
};

export type TweekParams = {
    store?: ITweekStore;
    expertDetails: User;
    platform: PlatformContext;
};

export type PlatformContext = {
    origin: string;
    appVersion: string;
    deviceOsType: string;
    deviceOsVersion: string;
    deviceModel?: string;
    deviceVendor?: string;
    deviceFormFactor?: string;
    deviceLocale?: string;
    deviceBrowserType?: string;
    deviceBrowserVersion?: string;
};

const toTweekContainsArray = items => items.map(item => `'${item}'`).join(',');

const createTweekTechnicianContext = (
    {email, roles = [], expertise: unparseExpertise = [], groups = [], uid}: User,
    platform
): Context => {
    const [queues, expertise] = partitionQueuesAndExpertise(unparseExpertise);
    return {
        technician: {
            id: email,
            roles: toTweekContainsArray(roles),
            groups: toTweekContainsArray(groups),
            expertise: toTweekContainsArray(expertise),
            expertId: uid,
            queues: toTweekContainsArray(queues),
            platform: platform.origin,
        },
        expert: {
            id: uid,
            email,
            roles,
            queues,
            groups,
            expertise,
        },
        platform,
    };
};

const getAccessToken = () => {
    try {
        const token = getHomeAccessToken();
        if (token !== '') {
            return token;
        }
    } catch (err) {
        logger.warn('Failed to get access token for tweek', {err});
    }
    // throw error so tweek isn't called without a valid token
    throw new Error('Cannot get valid access token for tweek');
};

const createTweekClient = (context: Context = {}) =>
    createTweekClientWithFallback({
        urls: [tweekURL, tweekURLFallback],
        getAuthenticationToken: getAccessToken,
        context: {...tweekTechnicianContext, ...context},
        useLegacyEndpoint: true,
        onError: async response => {
            const err = await parseTweekErrorResponse(response);
            logger.warn('tweek error', {err});
        },
    });

export const createTweekRepository = ({
    client = tweekClient,
    context = {},
}: {
    client?: TweekClientWithFallback;
    context?: Context;
}) => new TweekRepository({client, context: {...getTweekTechnicianContext(), ...context}});

export const initTweek = async ({store, expertDetails, platform}: TweekParams) => {
    logger.debug('Tweek: Initializing ...', {
        extra: {
            tweekURL,
        },
    });

    try {
        tweekTechnicianContext = createTweekTechnicianContext(expertDetails, platform);
        tweekClient = createTweekClient();
        const tweekRepository = createTweekRepository({client: tweekClient});
        setTweekRepo(tweekRepository);
        if (store) {
            await tweekRepository.useStore(store);
        }
        tweekRepository.expire();

        // Wait for tweek values
        await Observable.from(tweekRepository.observeValue('support/_')).first().toPromise();

        logger.debug('Tweek: Finished');
    } catch (err) {
        logger.error('Could not initialize tweek', {err});
        err.message = `Tweek: ${err.message}`;
        throw err;
    }
};

export const refreshTweekKeys = (...keys) => {
    const repo = getTweekRepo();

    if (repo) {
        repo.refresh(keys);
    }
};

export const getFromTweek = async key => {
    const repo = getTweekRepo();

    if (!repo) return;

    try {
        return await repo.getValue(key);
    } catch (e) {
        return;
    }
};

export const getMultipleFromTweek = async (paths: Array<string>) => {
    const values = await Promise.all(paths.map(path => getFromTweek(path)));
    return R.zipObj(paths, values);
};
