import {UserProfileProvider} from '@anywhere-expert/auth';
import {AppThemeProvider} from '@anywhere-expert/colors';
import {bugsnagProvider, LoggingErrorBoundary} from '@anywhere-expert/logging';
import * as React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import LoggedInRoutes from './components/LoggedInRoutes';
import './index.css';
import {Login, MonitorFlow} from './scenes';
import {useEnrichedAnalytics} from './utils';
import WelcomeAnimation from './components/WelcomeAnimation';
import ErrorBoundaryRoute from './components/ErrorBoundaryRoute';
import {configure as mobxConfigure} from 'mobx';
import rxjsconfig from 'recompose/rxjsObservableConfig';
import {initLogger} from './services/logging/webLogger';
import {setObservableConfig} from 'recompose';
import {AnalyticsProvider} from 'react-shisell';

initLogger();
bugsnagProvider.init('latest');
setObservableConfig(rxjsconfig);
mobxConfigure({enforceActions: process.env.NODE_ENV === 'development' ? 'observed' : 'never'});

export const basePath = process.env.BASE_PATH;

const AppRouter = () => {
    const dispatcher = useEnrichedAnalytics();

    return (
        <AnalyticsProvider dispatcher={dispatcher}>
            <Router basename={`/${basePath}`}>
                <Switch>
                    <ErrorBoundaryRoute
                        path="/monitor_flow"
                        component={MonitorFlow}
                        key="monitor_flow"
                        boundaryName="monitor_flow"
                    />
                    <ErrorBoundaryRoute
                        path="/auth_username_password"
                        component={Login}
                        key="auth"
                        boundaryName="auth_username_password"
                    />
                    <ErrorBoundaryRoute path="/login" component={Login} key="login" boundaryName="login" />
                    <ErrorBoundaryRoute path="/auth" component={Login} key="auth" boundaryName="auth" />
                    <ErrorBoundaryRoute path="/error" component={Login} key="login" boundaryName="login_error" />
                    <ErrorBoundaryRoute component={LoggedInRoutes} boundaryName="LoggedInRoutes" />
                </Switch>
            </Router>
        </AnalyticsProvider>
    );
};

const App = () => (
    <LoggingErrorBoundary boundaryName="App">
        <AppThemeProvider>
            <UserProfileProvider>
                <React.Suspense fallback={null}>
                    <AppRouter />
                    <WelcomeAnimation />
                </React.Suspense>
            </UserProfileProvider>
        </AppThemeProvider>
    </LoggingErrorBoundary>
);

export default App;
