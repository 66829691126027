import {CannedMessagesStoreV2} from '@anywhere-expert/canned-messages-v2';
import {resetPrePopulatedData} from '@anywhere-expert/default-input-text';
import logger from '@anywhere-expert/logging';
import {useSendMessage} from '@anywhere-expert/timeline-behaviours';
import {TimelineController} from '@anywhere-expert/timeline-state';
import {UserStorageStore} from '@anywhere-expert/user-storage-store';
import {useCallback, useMemo, useState} from 'react';
import {useTweekValue} from 'react-tweek';
import * as uuid from 'uuid';
import {TimelineInputStore} from '..';
import {useDispatchMessageSentAnalytics} from '../../analytics';

type RichInputInnerProps = {
    sessionId: string;
    partner: string;
    sessionSource?: string;
    sessionSourceVersion?: string;
    timelineId: string;
};

const addPrepopulatedTextToSuggestedCannedMessages = async (
    inputText: string,
    sessionId: string,
    timelineId: string,
    messageId?: string
) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    const addedMessage = await CannedMessagesStoreV2.addMessageSuggestion(
        {
            text: inputText,
            displayOrder: 1,
            expirationDate: expirationDate.toISOString(),
        },
        sessionId,
        timelineId
    );

    if (!addedMessage) {
        return;
    }

    messageId && TimelineController.flashMessage(messageId);
};

export type RichInputState = {
    isSendEnabled: boolean;
    sendComplete: () => void;
    setIsSendDisabled: (isSendDisabled: boolean) => void;
    onPaste: (text: string) => void;
};

export const useRichInputBehaviors = ({
    timelineId,
    sessionId,
    sessionSource,
    sessionSourceVersion,
    partner,
}: RichInputInnerProps): RichInputState => {
    const {editorPlainText} = TimelineInputStore.richInput;
    const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false);
    const [pastedMessage, setPastedMessage] = useState<string | undefined>();
    const isPasteToCannedEnabledInTweek = useTweekValue(
        'support/session/canned_messages/allow_saving_pasted_messages',
        false
    );
    const isPasteToCannedEnabledInUserStorage = UserStorageStore.getValue('isCannedMessagesPastingEnabled', 'enabled');

    const sendMessage = useSendMessage(timelineId, sessionId, partner, sessionSource);
    const dispatchMessageSentAnalytics = useDispatchMessageSentAnalytics({sessionId, timelineId});

    const isSendEnabled = useMemo(() => editorPlainText?.trim() !== '' && !isSendDisabled, [
        editorPlainText,
        isSendDisabled,
    ]);

    const sendComplete = useCallback(async () => {
        const editorMarkdownText = TimelineInputStore.richInput.editorMarkdownText;
        if (!editorPlainText || editorPlainText === '' || !editorMarkdownText || editorMarkdownText === '') return;

        try {
            const messageId = uuid();
            TimelineInputStore.clearTimeline(timelineId);
            await sendMessage(editorMarkdownText, 'text_message', messageId);
            if (isPasteToCannedEnabledInTweek && pastedMessage && isPasteToCannedEnabledInUserStorage === 'enabled') {
                await addPrepopulatedTextToSuggestedCannedMessages(
                    editorMarkdownText,
                    sessionId,
                    timelineId,
                    messageId
                );
            }
            dispatchMessageSentAnalytics(editorMarkdownText, messageId, sessionSource, sessionSourceVersion);
            resetPrePopulatedData(timelineId);
        } catch (err) {
            logger.error('An unexpected error has occurred while trying to send message.', {
                err,
                extra: {sessionId, timelineId},
            });
            TimelineInputStore.setEditorText(timelineId, editorPlainText, false);
        }

        if (pastedMessage) {
            setPastedMessage(undefined);
        }
    }, [editorPlainText, TimelineInputStore.richInput.editorMarkdownText, timelineId]);

    return {
        isSendEnabled,
        sendComplete,
        setIsSendDisabled,
        onPaste: setPastedMessage,
    };
};
