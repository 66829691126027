import React from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import {OfferSelection} from '../../../../types';
import {HorizonEnrollmentState, MessagingSalesTweekKeys} from '@soluto-private/smart-home-anywhere-expert';
import SalesMessageSection from './SalesMessageSection';
import {useCurrentOffer, useEnrollmentFlow} from '../../../../hooks';
import {useInterpolatedConclusions} from '../../../../hooks/useInterpolatedConclusions';
import {TabProps as SalesSessionProps} from '@anywhere-expert/session-sidebar';
import {getOfferRecommendations} from '../../../../utils';

const SalesMessages = observer(({selectedSession}: SalesSessionProps) => {
    const currentOffer = useCurrentOffer(selectedSession.sessionId)!;
    const isEnrollmentFlowEnabled = useEnrollmentFlow(selectedSession.sessionId);

    const {customerId} = selectedSession;

    const conclusions = useInterpolatedConclusions(selectedSession);
    const rebuttals = useTweekValue(MessagingSalesTweekKeys.Rebuttals, []);
    const pitches = useTweekValue(MessagingSalesTweekKeys.Pitches, []);
    const followUps = useTweekValue(MessagingSalesTweekKeys.FollowUps, []);

    const offerRecommendations = getOfferRecommendations(selectedSession).map(m => m.data.offerRecommendation);

    const acceptedTitle = useTweekValue(MessagingSalesTweekKeys.AcceptedFlowTitle, '');
    const declinedTitle = useTweekValue(MessagingSalesTweekKeys.DeclinedFlowTitle, '');
    const notOfferedTitle = useTweekValue(MessagingSalesTweekKeys.NotOfferedFlowTitle, '');
    const followUpsTitle = useTweekValue(MessagingSalesTweekKeys.FollowUpsTitle, '');

    switch (currentOffer.offer.offerSelection) {
        case OfferSelection.OfferAccepted:
            if (isEnrollmentFlowEnabled) {
                if (
                    currentOffer.offer.horizonEnrollmentProgress &&
                    currentOffer.offer.horizonEnrollmentProgress !== HorizonEnrollmentState.PriceShared
                ) {
                    return (
                        <SalesMessageSection
                            customerId={customerId}
                            title={followUpsTitle}
                            messages={followUps}
                            dataTestId="followUps"
                        />
                    );
                } else {
                    return (
                        <SalesMessageSection
                            customerId={customerId}
                            title={declinedTitle}
                            messages={rebuttals}
                            dataTestId="rebuttals"
                        />
                    );
                }
            } else {
                return (
                    <SalesMessageSection
                        customerId={customerId}
                        title={acceptedTitle}
                        messages={conclusions}
                        dataTestId="conclusions"
                    />
                );
            }
        case OfferSelection.OfferDeclined:
            return (
                <SalesMessageSection
                    customerId={customerId}
                    title={declinedTitle}
                    messages={rebuttals}
                    dataTestId="rebuttals"
                />
            );
        case OfferSelection.NotOffered:
            return (
                <SalesMessageSection
                    customerId={customerId}
                    title={notOfferedTitle}
                    messages={[...offerRecommendations, ...pitches]}
                    dataTestId="pitches"
                />
            );
        default:
            return null;
    }
});

export default SalesMessages;
