import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {palette} from '@anywhere-expert/colors';

interface MessageSpinnerProps {
    isCurrentlyInFlight: boolean;
}

interface MessageSpinnerState {
    isVisible: boolean;
    showing: boolean;
}

class MessageSpinner extends React.PureComponent<MessageSpinnerProps, MessageSpinnerState> {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: true,
            showing: props.isCurrentlyInFlight,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: MessageSpinnerProps) {
        if (this.props.isCurrentlyInFlight && !nextProps.isCurrentlyInFlight) {
            this.setState(
                {
                    isVisible: false,
                },
                () => setTimeout(() => this.setState({isVisible: true, showing: false}), 300)
            );
        }
    }

    render() {
        const {showing} = this.state;

        if (!showing) return null;
        return (
            <CircularProgress
                variant="indeterminate"
                size={14}
                thickness={2}
                style={{
                    color: palette.brand.blue,
                    transform: 'scale(0.5)',
                    alignSelf: 'flex-end',
                    bottom: '-1px',
                    marginRight: '2px',
                }}
            />
        );
    }
}

export default MessageSpinner;
