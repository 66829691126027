import {ExpertCannedMessages} from '@soluto-private/canned-messages-api-client';
import {getTweekValue} from '@anywhere-expert/tweek';
import axios from 'axios';
import logger from '@anywhere-expert/logging';
import {getRandomColor, buildMessage} from './utils';
import {PredefinedCategory, PredefinedCannedMessage} from './types';

const headers = {
    headers: {
        'Content-Type': 'application/json',
    },
};

const getPredefinedData = async () => {
    const categoriesLink = getTweekValue<string>('support/session/canned_message/predefined_categories_link', '');
    const messagesLink = getTweekValue<string>('support/session/canned_message/predefined_messages_link', '');

    const [categories, messages] = await Promise.all([
        axios.get(categoriesLink, headers),
        axios.get(messagesLink, headers),
    ]);

    return {
        categories: categories.data || [],
        messages: messages.data || [],
    };
};

export default async (expertId: string, displayName: string): Promise<ExpertCannedMessages> => {
    try {
        const {categories, messages} = await getPredefinedData();

        const mappedCategories = Object.fromEntries(
            categories.map(({name, displayOrder, id: categoryId}: PredefinedCategory) => [
                categoryId,
                {
                    title: name,
                    displayOrder,
                    icon: '🔶',
                    color: getRandomColor(),
                    messages: Object.fromEntries(
                        messages
                            .filter(({categories}) => categories.find(({id}) => id === categoryId))
                            .map(({id, text}: PredefinedCannedMessage) => [
                                id,
                                {
                                    text: buildMessage(text, displayName),
                                    usage: 0,
                                    displayOrder: 0,
                                },
                            ])
                    ),
                },
            ])
        );

        return {
            expertId,
            metadata: {},
            categories: mappedCategories,
        };
    } catch (err) {
        logger.error('failed to get predefined canned messages', {err, extra: {expertId}});

        return {
            expertId,
            metadata: {},
            categories: {},
        };
    }
};
