import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

const noResultsImgSrc = require('./resources/noResults.png');

const Image = styled.img`
    ${({theme}) => theme.themeName === 'dark' && '-webkit-filter: invert(100%);'};
    width: 100px;
    height: 78px;
    margin-bottom: 16px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 112px;
`;

const NoResults = () => (
    <Container data-test-id="no-matching-result">
        <Image src={noResultsImgSrc} />
        <CoreText size="s">No matching result</CoreText>
    </Container>
);

export default NoResults;
