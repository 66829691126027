import {BehaviorSubject} from 'rxjs';

type InputType = {
    [x: string]: BehaviorSubject<{text: string; name: string}>;
};

const prepopulatedTextSubjects: InputType = {};

const getPrePopulatedTextSubject = (id: string) => {
    if (id && !prepopulatedTextSubjects[id]) {
        prepopulatedTextSubjects[id] = new BehaviorSubject<{text: string; name: string}>({text: '', name: ''});
    }
    return prepopulatedTextSubjects[id];
};

export const getPrePopulatedData = (timelineId: string) => getPrePopulatedTextSubject(timelineId).getValue();
export const setPrePopulatedData = (timelineId: string, text: string, name: string) =>
    getPrePopulatedTextSubject(timelineId).next({text, name});
export const resetPrePopulatedData = (timelineId: string) => setPrePopulatedData(timelineId, '', '');
